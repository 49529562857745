/* RESPONSIBLE TEAM: team-help-desk-experience */

export function qualifyRecentSearchToSave(
  currentQuery: string,
  recentSearches: string[],
  timePeriod: number,
  qualifyPeriod: number,
): string[] | undefined {
  let lastSearch = recentSearches.get('lastObject');

  if (lastSearch) {
    // update lastSearch as a user types
    if (
      currentQuery.toLocaleLowerCase().includes(lastSearch.toLocaleLowerCase()) &&
      timePeriod < qualifyPeriod
    ) {
      recentSearches.pop();
    }

    // don't save when a user hits backspace key
    if (
      lastSearch.toLocaleLowerCase().includes(currentQuery.toLocaleLowerCase()) &&
      timePeriod < qualifyPeriod
    ) {
      return undefined;
    }

    // skip if already saved
    if (
      recentSearches.some(
        (recentSearch) => recentSearch.toLocaleLowerCase() === currentQuery.toLocaleLowerCase(),
      )
    ) {
      return undefined;
    }
  }

  recentSearches.pushObject(currentQuery);
  return recentSearches;
}
