/* RESPONSIBLE TEAM: team-frontend-tech */
import moment from 'moment-timezone';

export default function formatTimeZoneToUTC(timezone) {
  return moment
    .tz(timezone)
    .format('[UTC]Z')
    .replace(/0(\d:)/, '$1')
    .replace(':00', '');
}
