/* RESPONSIBLE TEAM: team-frontend-tech */
import { get } from '@ember/object';
export default function (jsonBlocks) {
  let previewBlock = { type: 'paragraph', text: '' };
  for (let i = 0; i < jsonBlocks.length; ++i) {
    let block = jsonBlocks[i];
    let blockText = get(block, 'text');
    if (blockText) {
      previewBlock.text += ` ${blockText.replace(/<br>/, ' ')}`;
    }
    if (previewBlock.text.length > 250) {
      break;
    }
  }
  if (previewBlock.text.length > 250) {
    previewBlock.text = `${previewBlock.text.slice(0, 250)} …`;
  }
  previewBlock.text = previewBlock.text.replace(/^(&nbsp;)*/, '').trim();
  return [previewBlock];
}
