/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/no-classic-classes */
import { bool, or, mapBy, readOnly, equal } from '@ember/object/computed';
import { observer, computed } from '@ember/object';
import ObjectProxy from '@ember/object/proxy';
import { findBy } from '@intercom/pulse/lib/computed-properties';

let ImmutablePlan = ObjectProxy.extend({
  active: true,
  id: readOnly('content.id'),
  name: readOnly('content.name'),
  features: readOnly('content.features'),
  tierId: readOnly('content.tierId'),
  idAsNumber: readOnly('content.idAsNumber'),
  selfServe: readOnly('content.selfServe'),
  inCurrentSubscription: readOnly('content.active'),

  init() {
    this._super(...arguments);
    this.resetState();
  },
  updateActive: observer({
    dependentKeys: ['content.active'],

    fn() {
      this.resetState();
    },

    sync: true,
  }),

  resetState() {
    this.set('active', this.get('content.active'));
  },
});

export default ObjectProxy.extend({
  previousActivePlan: findBy('content.plans', 'active', true),
  plans: [],
  key: readOnly('content.key'),
  planIds: mapBy('content.plans', 'idAsNumber'),
  activePlan: findBy('plans', 'active', true),
  active: bool('activePlan'),
  id: readOnly('content.id'),
  name: readOnly('content.name'),
  pricingMetric: readOnly('content.pricingMetric'),
  perProductPriceMetric: readOnly('content.perProductPriceMetric'),
  price: readOnly('content.price'),
  addon: readOnly('content.addon'),
  trialable: readOnly('content.trialable'),
  hasOnePlan: readOnly('content.hasOnePlan'),
  inactive: readOnly('content.inactive'),
  hasMonthlyActivePeoplePricingTable: readOnly('content.hasMonthlyActivePeoplePricingTable'),
  hasQuarterlyActivePeoplePricingTable: readOnly('content.hasQuarterlyActivePeoplePricingTable'),
  hasPeopleRecordsPricingTable: readOnly('content.hasPeopleRecordsPricingTable'),
  isArticles: readOnly('content.isArticles'),
  originallyActive: readOnly('content.active'),
  isOnTrial: readOnly('content.isOnTrial'),
  daysToTrialExpiration: readOnly('content.daysToTrialExpiration'),

  init() {
    this._super(...arguments);
    let immutablePlans = this.content.plans.map((plan) => {
      return ImmutablePlan.create({ content: plan, product: this });
    });
    this.set('plans', immutablePlans);
  },

  hasHigherPlan: computed('activePlan', function () {
    let currentPlanId = this.get('content.activePlan.idAsNumber');
    let planIds = this.planIds;
    return planIds.get('lastObject') !== currentPlanId;
  }),

  higherPlanId: computed('previousActivePlan', function () {
    let currentPlanId = this.get('previousActivePlan.id');
    let planIds = this.get('content.plans').map((plan) => plan.get('id'));

    if (currentPlanId === planIds.get('lastObject')) {
      return null;
    }

    return planIds[planIds.indexOf(currentPlanId) + 1];
  }),

  hasProPlan: computed('plans', 'activePlan', function () {
    return this.get('content.hasOnePlan') || this.get('activePlan.name') === 'Pro';
  }),

  hasEssentialPlan: equal('activePlan', 'Essential'),

  adding: computed('active', 'content.active', function () {
    return this.active && !this.get('content.active');
  }),

  upgrading: computed('activePlan', 'previousActivePlanIdIfTriallingGrow', function () {
    let planIds = this.planIds;
    let selectedPlanIndex = planIds.indexOf(this.activePlan?.idAsNumber);
    let activeOnProductPlanIndex = planIds.indexOf(this.previousActivePlan?.idAsNumber);

    return this.content.active && selectedPlanIndex > activeOnProductPlanIndex;
  }),

  downgrading: computed('activePlan', 'previousActivePlanIdIfTriallingGrow', function () {
    let planIds = this.planIds;
    let selectedPlanIndex = planIds.indexOf(this.activePlan?.idAsNumber);
    let activeOnProductPlanIndex = planIds.indexOf(this.previousActivePlan?.idAsNumber);

    return this.active && this.content.active && selectedPlanIndex < activeOnProductPlanIndex;
  }),

  removing: computed('active', 'content.active', function () {
    return this.get('content.active') && !this.active;
  }),

  higherPlan: or('upgrading', 'adding'),
  lowerPlan: or('downgrading', 'removing'),
  changedPlan: or('higherPlan', 'lowerPlan'),
  showInSummary: or('changedPlan', 'content.active'),
  updating: or('downgrading', 'upgrading', 'removing', 'adding'),
  newStatus: computed('upgrading', 'downgrading', 'adding', 'removing', function () {
    if (this.upgrading) {
      return 'upgraded';
    }
    if (this.downgrading) {
      return 'downgraded';
    }
    if (this.adding) {
      return 'added';
    }
    if (this.removing) {
      return 'removed';
    }
    return '';
  }),
});
