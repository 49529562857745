/* RESPONSIBLE TEAM: team-reporting */
import { select } from 'd3-selection';
import { scaleLinear, scaleSqrt } from 'd3-scale';
import { min, max, sum, extent } from 'd3-array';
import { BubbleChartAxes } from 'embercom/lib/reporting/bubble-chart/axes';
import { BubbleChartLayout } from 'embercom/lib/reporting/bubble-chart/layout';
import { BubbleChartSatisfaction } from 'embercom/lib/reporting/bubble-chart/satisfaction';
import { renderNodes } from 'embercom/lib/reporting/bubble-chart/nodes';

const SMALLEST_BUBBLE_RADIUS = 15;
const LARGEST_BUBBLE_RADIUS = 125 / 2;
const MARGIN = { top: 40, right: 72, bottom: 60, left: 88 };

export class BubbleChartRenderEngine {
  width;
  height;
  margin;
  element;
  layout;
  axes;
  satisfaction;
  showVolume = true;
  updateTooltip;
  resetTooltip;
  appIdCode;
  data;

  constructor() {
    this.layout = new BubbleChartLayout();
    this.axes = new BubbleChartAxes();
    this.satisfaction = new BubbleChartSatisfaction();
  }

  // SCALES

  // Calculate extremities of dimensions, to help us scale data.
  // Generally, all visualizations are scaled so that the dimensions bring out
  // the range of data we have, rather than being absolute.

  // Unlike CSAT the following ranges don't need to be accessed outside this function.

  get maxX() {
    return max(this.data, function (d) {
      return d.currentResponseTime;
    });
  }

  get maxY() {
    return max(this.data, function (d) {
      return d.currentTimeToClose;
    });
  }

  get minCsat() {
    return min(this.data, function (d) {
      return d.currentPositiveRate;
    });
  }

  get maxCsat() {
    return max(this.data, function (d) {
      return d.currentPositiveRate;
    });
  }

  // Create a color scale, as specified by Design, to color CSAT nicely.
  // initialize the CSAT color scale - this has to be done here, where we have the data.
  get csatIncrement() {
    return (this.maxCsat - this.minCsat) / 5;
  }

  get csatColorScale() {
    return scaleLinear()
      .domain([
        this.minCsat,
        this.minCsat + this.csatIncrement,
        this.minCsat + this.csatIncrement * 2,
        this.minCsat + this.csatIncrement * 3,
        this.minCsat + this.csatIncrement * 4,
      ])
      .range(['#EB9082', '#FBBA6A', '#F8E78C', '#ADEDBD', '#7CDC95']);
  }

  // X axis
  get scaleX() {
    return scaleLinear().domain([0, this.maxX]).range([0, this.width]);
  }

  // Y axis
  get scaleY() {
    return scaleLinear().domain([0, this.maxY]).range([this.height, 0]);
  }

  get rawScaleRadius() {
    let countExtent = extent(this.data, (d) => d.currentCount);
    return scaleSqrt().domain(countExtent).range([SMALLEST_BUBBLE_RADIUS, LARGEST_BUBBLE_RADIUS]);
  }

  // Add a scale for bubble size
  get scaleRadius() {
    // Approximate area of the svg to cover with bubbles, if this is
    // too high the visualisation will be very crowded and bubbles
    // may start overlapping
    let targetAreaToCover = 0.55;

    let percentAreaCovered = this.bubbleTotalVolume / this.displayAreaVolume;
    let areaOverCovered = percentAreaCovered - targetAreaToCover;
    let scaleFactor = 1 - areaOverCovered;

    return (value) => this.rawScaleRadius(value) * scaleFactor;
  }

  get displayAreaVolume() {
    return this.width * this.height;
  }

  // The total scaled volume of all bubbles
  get bubbleTotalVolume() {
    return sum(this.data, (d) => Math.PI * this.rawScaleRadius(d.currentCount) ** 2);
  }

  // Toggles

  toggleLayout(showAxes) {
    this.axes.toggleAxes(showAxes);
    this.renderNodes();
  }

  toggleSatisfaction(showSatisfaction) {
    this.satisfaction.toggleSatisfaction(this.element, showSatisfaction);
  }

  toggleVolume(showVolume) {
    this.showVolume = showVolume;
    this.renderNodes();
  }

  destroy(element) {
    select(element).select('g').remove();
  }

  renderTopLevelGroup(element) {
    select(element).append('g').attr('transform', `translate(${MARGIN.left},${MARGIN.top})`);
  }

  renderStaticParts(element) {
    this.destroy(element);
    this.renderTopLevelGroup(element);
  }

  renderNodes() {
    this.data = this.layout.updateDataNodePositions(
      this.data,
      this.scaleRadius,
      this.scaleX,
      this.scaleY,
      this.showVolume,
      this.axes.axesVisible,
    );
    renderNodes(
      this.data,
      this.element,
      this.updateTooltip,
      this.resetTooltip,
      this.appIdCode,
      this.satisfaction,
    );
  }

  render(width, height, data) {
    if (!this.element) {
      return;
    }
    this.data = data;
    this.width = width - MARGIN.left - MARGIN.right;
    this.height = height - MARGIN.top - MARGIN.bottom;

    this.axes.render(this.width, this.height, this.scaleX, this.scaleY);
    this.satisfaction.init(this.csatColorScale);
    this.layout.init(this.width, this.height);
    this.renderNodes();
  }

  init(data, element, updateTooltip, resetTooltip, appIdCode) {
    this.data = data;
    this.element = element;
    this.updateTooltip = updateTooltip;
    this.resetTooltip = resetTooltip;
    this.appIdCode = appIdCode;
    this.renderStaticParts(element);
    this.satisfaction.init(this.csatColorScale);
    this.axes.init(element);
  }
}
