/* RESPONSIBLE TEAM: team-proactive-support */
import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';
import type Store from '@ember-data/store';

export default class CanSetChecklistsLiveHelper extends Helper {
  @service declare appService: any;
  @service declare store: Store;

  get app() {
    return this.appService.app;
  }

  get messengerSettings() {
    return this.store.peekRecord('messenger-settings/all', this.app.id);
  }

  compute(): boolean {
    return this.messengerSettings?.spaces.hasTasksEnabledForAnyUserType;
  }
}
