/* RESPONSIBLE TEAM: team-frontend-tech */
import HrefTo from 'ember-href-to/href-to';

// Overriden version of ember-href-to initializer
// in order to fix issue where ember-href-to runs at the wrong time
// see https://github.com/intercom/intercom/issues/251022 for more detail

function closestLink(el) {
  if (el.closest) {
    return el.closest('a');
  } else {
    el = el.parentElement;
    while (el && el.tagName !== 'A') {
      el = el.parentElement;
    }
    return el;
  }
}
export default {
  name: 'ember-href-to',
  initialize(applicationInstance) {
    // we only want this to run in the browser, not in fastboot
    if (typeof FastBoot === 'undefined') {
      let hrefToClickHandler = function _hrefToClickHandler(e) {
        let link = e.target.tagName === 'A' ? e.target : closestLink(e.target);
        if (link) {
          let hrefTo = new HrefTo(applicationInstance, e, link);
          // START MODIFICATION
          if (hrefTo.shouldHandle()) {
            if (e.defaultPrevented) {
              applicationInstance
                .lookup('service:log-service')
                // eslint-disable-next-line @intercom/intercom/no-bare-strings
                .log(`ember-href-to skipped as defaultPrevented on ${link.href}`);
              console.info('[ember-href-to] skipping due to default prevented');
              return;
            }
            hrefTo.handle();
          }
          // END MODIFICATION
        }
      };

      document.body.addEventListener('click', hrefToClickHandler);

      // Teardown on app destruction: clean up the event listener to avoid
      // memory leaks.
      applicationInstance.reopen({
        willDestroy() {
          document.body.removeEventListener('click', hrefToClickHandler);
          return this._super(...arguments);
        },
      });
    }
  },
};
