/* RESPONSIBLE TEAM: team-proactive-support */
const SIDEBAR_BLOCKS = [
  'paragraph',
  'button',
  'orderedList',
  'unorderedList',
  'heading',
  'small',
  'subheading',
  'codeBlock',
  'image',
  'social',
  'socialItem',
  'table',
  'spacer',
  'horizontalRule',
];

export default function showComposerSidebar(nodeType) {
  return SIDEBAR_BLOCKS.includes(nodeType);
}
