/* RESPONSIBLE TEAM: team-frontend-tech */
import { isEmpty } from '@ember/utils';
import numberFormatter from 'embercom/lib/number-formatter';

export default function (val) {
  if (isEmpty(val)) {
    return `\u2014`; //em dash
  } else {
    return numberFormatter(val);
  }
}
