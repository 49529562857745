/* RESPONSIBLE TEAM: team-reporting */
import {
  buildFunctionToMapFromDatabaseValueToDisplayName,
  labelMapperFallback,
  formatTimestamp,
  seriesNameForMetric,
} from 'embercom/lib/reporting/custom/view-config-builder-helpers';
import { isEmpty } from '@ember/utils';
import { setOwner } from '@ember/application';
import { inject as service } from '@ember/service';
import { VISUALIZATION_TYPES } from 'embercom/models/reporting/custom/visualization-options';

export const STACKING_TYPES = {
  PERCENT: 'percent',
  NORMAL: 'normal',
};

export default class ViewConfigBuilder {
  @service intl;
  @service appService;

  constructor(renderableChart, owner) {
    this.renderableChart = renderableChart;
    setOwner(this, owner);
  }

  buildViewConfig() {
    if (!this.renderableChart.metricUnit) {
      return;
    }

    let viewConfig = {
      metrics: this.renderableChart.chartSeries?.map((series) => series.metric),
      chartType: this.renderableChart.visualizationType,
      formatUnit: {
        unit: this.renderableChart.metricUnit,
        displayUnit: this.renderableChart.tooltipTranslationKeys || null,
      },
      skipLabelsForLargeSeries: true,
      labelMappingFunction: labelMapperFallback(
        buildFunctionToMapFromDatabaseValueToDisplayName(this.renderableChart.viewBy),
      ),
      legendMappingFunction: this.getLegendMappingFunction(),
      labelStyleOverrides: {},
      shouldEnableHoverState: this.renderableChart.isArea || this.renderableChart.isStacked,
      useDarkTooltips: true,
      colorByPoint: this.renderableChart.reportState?.colorChartsByPoint,
      showDataLabels: this.renderableChart.chartSeries?.any((series) => series.showDataLabels),
      visualizationOptions: this.renderableChart.visualizationOptions,
    };

    if (viewConfig.visualizationOptions?.showRelativeValues) {
      viewConfig.columnChart = { stacking: 'percent' };
      viewConfig.showPercentages = true;
    } else if (this.renderableChart.isStacked) {
      viewConfig.columnChart = { stacking: 'normal' };
      // it only makes sense to show the % of the total for "volume" metrics like "Conversation volume" and "Reply volume"
      viewConfig.showPercentages =
        this.renderableChart.chartSeries.firstObject.aggregation === 'count';
    } else if (this.renderableChart.visualizationType === VISUALIZATION_TYPES.DONUT) {
      viewConfig.showPercentages =
        this.renderableChart.chartSeries.firstObject.metric.supportsRelativeValues;
    }

    if (!isEmpty(this.renderableChart.seriesColors)) {
      viewConfig.seriesColors = this.renderableChart.seriesColors;
    }

    let { chartSeries } = this.renderableChart;
    if (chartSeries?.length >= 1 && isEmpty(this.renderableChart.segmentBy)) {
      viewConfig.legend = chartSeries.reduce((acc, series) => {
        let identifier = series.metricId;
        acc[identifier] = () =>
          seriesNameForMetric(
            series.metric.name,
            series.aggregation,
            this.renderableChart.shouldRenderChrome,
          );
        return acc;
      }, {});
      // We want each bar to be a different color
      viewConfig.colorByPoint = false;
    }
    return viewConfig;
  }

  getLegendMappingFunction() {
    if (this.renderableChart.isSegmentedByTime) {
      return (value) =>
        formatTimestamp(
          this.renderableChart.segmentByTimeInterval || this.renderableChart.dateRange.interval,
          value / 1000,
          this.renderableChart.reportState?.timezone,
        );
    } else if (this.renderableChart.isHorizontalBar && !this.renderableChart.segmentBy) {
      return this.renderableChart.isBrokenDownByTime
        ? (value) =>
            formatTimestamp(
              this.renderableChart.viewByTimeInterval || this.renderableChart.dateRange.interval,
              value / 1000,
              this.renderableChart.reportState?.timezone,
            )
        : labelMapperFallback(
            buildFunctionToMapFromDatabaseValueToDisplayName(this.renderableChart.viewBy),
          );
    } else {
      return labelMapperFallback(
        buildFunctionToMapFromDatabaseValueToDisplayName(this.renderableChart.segmentBy),
      );
    }
  }
}
