/* RESPONSIBLE TEAM: team-reporting */
import { getDefaultSizesBasedOnVizType } from 'embercom/lib/reporting/custom/visualization-type-grid-sizes';
import type { ChartTemplate } from 'embercom/services/reporting-templates';
import type IntlService from 'ember-intl/services/intl';
import { CELL_HEIGHT } from 'embercom/components/reporting/custom/report/chart-grid-layout';

export function handleChartTemplateDragEvent(
  event: Event,
  cellWidth: any,
  chartTemplates: ChartTemplate[],
  intl: IntlService,
) {
  let target = (event.target as HTMLElement)?.closest('.grid-stack-item');
  let id = target?.getAttribute('data-chart-template-id');
  let template = chartTemplates.find((chart: ChartTemplate) => chart.templateId === id);

  if (!template) {
    return;
  }
  let style = document.createElement('style');
  style.id = 'report-template-drag-preview-style';

  let { defaultWidth, defaultHeight } = getDefaultSizesBasedOnVizType(template.visualizationType);
  let width = cellWidth * defaultWidth;
  let height = CELL_HEIGHT * defaultHeight;

  style.textContent = `
      .report-template-drag-preview {
        width: ${width}px !important;
        height: ${height}px !important;
        position: relative;
        display: flex;
        pointer-events: all;
        cursor: grabbing;
      }
    `;

  // delete any existing style with the same name
  let existingStyle = document.getElementById('report-template-drag-preview-style');
  if (existingStyle) {
    existingStyle.remove();
  }

  document.head.appendChild(style);

  let dragPreview = document.createElement('div');
  dragPreview.classList.add('report-template-drag-preview');
  dragPreview.setAttribute('data-test-chart-template-drag-preview', '');

  let innerCard = document.createElement('div');
  innerCard.classList.add('reporting-template-inner-card');

  let img = document.createElement('img');
  img.src = template.imageUrl;
  img.alt = intl.t('reporting.custom-reports.report.placeholder');

  let text = document.createElement('p');
  text.textContent = template.title;

  innerCard.appendChild(text);
  innerCard.appendChild(img);

  dragPreview.appendChild(innerCard);
  document.body.appendChild(dragPreview);

  return dragPreview;
}
