/* RESPONSIBLE TEAM: team-reporting */
import { copy } from 'ember-copy';

export function buildSeriesForPreviousPeriod(singleSeries, range) {
  let result = copy(singleSeries, true);

  // we explicitly set this as ember-copy returns only a basic POJO
  // but we need the metric instance
  result.metric = singleSeries.metric;

  result.time.start = range.comparisonStartMoment.valueOf();
  result.time.end = range.comparisonEndMoment.valueOf();

  result.name += '_previous';
  return result;
}

export const buildDataConfigWithComparisons = (dataConfig, range) => {
  let currentPeriodSeries = dataConfig.series;
  let previousPeriodSeries = currentPeriodSeries.map((series) =>
    buildSeriesForPreviousPeriod(series, range),
  );

  dataConfig.series = [...previousPeriodSeries, ...currentPeriodSeries];
  return dataConfig;
};
