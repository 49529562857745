/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { getOwner, setOwner } from '@ember/application';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import { generateConversations } from 'embercom/lib/inbox2/onboarding/conversations';
import type Conversation from 'embercom/objects/inbox/conversation';
import AdminComment from 'embercom/objects/inbox/renderable/admin-comment';
import type Session from 'embercom/services/session';
import { OnboardingType } from 'embercom/lib/inbox2/onboarding/steps';
import TeamSummary from 'embercom/objects/inbox/team-summary';
import type IntlService from 'embercom/services/intl';
import type ApplicationInstance from '@ember/application/instance';
import { isEmpty } from '@ember/utils';

enum OnboardingConversationsView {
  List = 'List',
  Table = 'Table',
}

export const INBOX2_ONBOARDING_GIFS = [
  'https://gifs.intercomcdn.com/images/acc4116372dcc4b342cb1a00ae657151/tenor.gif',
  'https://gifs.intercomcdn.com/images/43b8ed43e6ac683091db7c8314757923/tenor.gif',
  'https://gifs.intercomcdn.com/images/0dfbf073844739de2560a2ad737e9501/tenor.gif',
  'https://gifs.intercomcdn.com/images/b281caf5d065a5e5042d8883911c2220/tenor.gif',
  'https://gifs.intercomcdn.com/images/a5bd488db448f2244460b9045140a16d/tenor.gif',
  'https://gifs.intercomcdn.com/images/a13bf3c2cc906e94a43b8038ff4264cf/tenor.gif',
  'https://gifs.intercomcdn.com/images/a13bf3c2cc906e94a43b8038ff4264cf/tenor.gif',
  'https://gifs.intercomcdn.com/images/0a691ca180f0d87b730b6850895afdb4/tenor.gif',
];

type CommandKAction = 'gifs' | 'jump-to-inbox';

export default class OnboardingState {
  @service declare session: Session;
  @service declare intl: IntlService;

  readonly type: `${OnboardingType}`;

  @tracked conversations: Conversation[];
  @tracked closedConversations: Conversation[] = [];

  @tracked activeView = OnboardingConversationsView.List;
  @tracked focusedSections: string[] = [];
  @tracked highlightedAction?: string;
  @tracked isInboxListVisible = false;
  @tracked isRightSideBarVisible = true;
  @tracked isPreviewPanelVisible = false;
  @tracked activeConversationId = 1;
  @tracked composerBlocks = new BlocksDocument([]);
  @tracked isComposerFocused = false;
  @tracked isCommandKVisible = false;
  @tracked commandKFocusedActionIdx = 0;
  @tracked commandKActiveAction?: CommandKAction;
  @tracked hideConversationEvents = true;

  get activeConversation() {
    return this.conversations.find((conversation) => conversation.id === this.activeConversationId);
  }

  get hasClosedConversation() {
    return this.closedConversations.length > 0;
  }

  get inbox() {
    return new TeamSummary(
      1,
      this.type === OnboardingType.Default
        ? this.intl.t('inbox.onboarding.conversations-list.help-desk-title.onboarding')
        : this.intl.t('inbox.onboarding.conversations-list.help-desk-title.unassigned'),
      '👋',
    );
  }

  constructor(
    owner: ApplicationInstance,
    type: `${OnboardingType}`,
    conversations: Conversation[] = [],
  ) {
    setOwner(this, owner);

    this.type = type;
    this.conversations = isEmpty(conversations)
      ? generateConversations(owner, 5, this.session.teammate, this.inbox)
      : conversations;
  }

  // Clones an instance of the state class. There's no built in way to clone
  // instances, so this is doing a lot of manual work.
  clone() {
    let state = new OnboardingState(getOwner(this) as any, this.type, [...this.conversations]);
    state.activeView = this.activeView;
    state.focusedSections = [...this.focusedSections];
    state.highlightedAction = this.highlightedAction;
    state.closedConversations = [...this.closedConversations];
    state.isInboxListVisible = this.isInboxListVisible;
    state.isPreviewPanelVisible = this.isPreviewPanelVisible;
    state.isRightSideBarVisible = this.isRightSideBarVisible;
    state.activeConversationId = this.activeConversationId;
    state.composerBlocks = this.composerBlocks;
    state.isComposerFocused = this.isComposerFocused;
    state.isCommandKVisible = this.isCommandKVisible;
    state.commandKActiveAction = this.commandKActiveAction;
    state.commandKFocusedActionIdx = this.commandKFocusedActionIdx;
    state.hideConversationEvents = this.hideConversationEvents;

    return state;
  }

  focusSections(...names: string[]) {
    this.focusedSections = names;
  }

  clearFocus() {
    this.focusedSections = [];
  }

  highlightAction(name: string) {
    this.highlightedAction = name;
  }

  clearHighlight() {
    this.highlightedAction = undefined;
  }

  switchToTableView() {
    this.activeView = OnboardingConversationsView.Table;
  }

  showPreviewPanel() {
    this.isPreviewPanelVisible = true;
  }

  showEvents() {
    this.hideConversationEvents = false;
  }

  toggleInboxList() {
    this.isInboxListVisible = !this.isInboxListVisible;
  }

  closeConversation(conversation?: Conversation) {
    if (!conversation) {
      return;
    }

    this.goToNextConversation();
    this.conversations = this.conversations.filter((c) => conversation.id !== c.id);
    this.closedConversations = [...this.closedConversations, conversation];
  }

  undoLastClosedConversation() {
    let conversation = this.closedConversations.popObject();
    if (!conversation) {
      return;
    }

    this.conversations = [conversation, ...this.conversations];
    this.activeConversationId = conversation.id;
  }

  goToNextConversation() {
    this.activeConversationId += 1;
  }

  goToPreviousConversation() {
    this.activeConversationId -= 1;
  }

  insertGif() {
    this.composerBlocks = new BlocksDocument(this.gifBlocks);
    this.isCommandKVisible = false;
  }

  clearComposer() {
    this.composerBlocks = new BlocksDocument([]);
  }

  addReply() {
    let part = new AdminComment(this.gifBlocks, this.session.teammate, []);
    this.activeConversation?.addPendingPart(part);
  }

  showCommandK() {
    this.isCommandKVisible = true;
  }

  selectCommandKPane(action: CommandKAction) {
    this.commandKActiveAction = action;
  }

  goToNextCommandKAction() {
    this.commandKFocusedActionIdx += 1;
  }

  private get gifBlocks() {
    let [url] = INBOX2_ONBOARDING_GIFS;

    return [
      {
        type: 'image',
        url,
        width: 220,
        height: 220,
        text: 'Hello!',
      },
    ];
  }
}
