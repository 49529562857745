/* RESPONSIBLE TEAM: team-proactive-support */
export default {
  opened: 'opened',
  clicked: 'has_click',
  bounced: 'has_bounce',
  unsubscribed: 'has_unsubscribe',
  replied: 'has_reply',
  responded: 'has_response',
  reactions: 'has_response',
  goal: 'has_goal_success',
  failed: 'has_failed',
  complained: 'has_complained',
  reaction: 'reaction_index',
  collected: 'has_attribute_collector_reply',
  pending: 'pending',
};
