/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-jquery */
import $ from 'jquery';
import ENV from 'embercom/config/environment';

export default {
  name: 'jQueryAjaxPrefilter',
  initialize() {
    let token = this._getCsrfToken();
    let revisionId = this._getRevisionId();

    $.ajaxPrefilter((options, originalOptions, xhr) => {
      //                          ░░▓▓██▒▒
      //                          ██▓▓▒▒██▒▒
      //                        ▓▓▓▓░░░░░░██
      //                      ░░██░░░░░░░░▓▓▓▓
      //                      ██▓▓░░░░░░░░░░██░░
      //                    ▒▒██░░░░████▒▒░░▓▓██
      //                    ██▒▒░░░░████▓▓░░░░██▒▒
      //                  ▒▒██░░░░░░████▓▓░░░░▒▒██
      //                  ██░░░░░░░░████▒▒░░░░░░██▓▓
      //                ▓▓▓▓░░░░░░░░████▒▒░░░░░░░░██
      //              ░░██░░░░░░░░░░████▒▒░░░░░░░░▓▓▓▓
      //              ██▒▒░░░░░░░░░░████░░░░░░░░░░░░██░░
      //            ▒▒██░░░░░░░░░░░░▓▓██░░░░░░░░░░░░▒▒██
      //            ██▒▒░░░░░░░░░░░░▓▓██░░░░░░░░░░░░░░██▒▒
      //          ▓▓██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░▒▒██
      //          ██░░░░░░░░░░░░░░░░▒▒▓▓░░░░░░░░░░░░░░░░▓▓▓▓
      //        ▓▓▓▓░░░░░░░░░░░░░░░░████▓▓░░░░░░░░░░░░░░░░██░░
      //      ░░██░░░░░░░░░░░░░░░░░░████▒▒░░░░░░░░░░░░░░░░▓▓▓▓
      //      ▒▒▓▓░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
      //        ██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░▓▓▓▓
      //        ▒▒██████████████████████████████████████████
      //
      //                           WARNING
      //
      // Warning: Do not send extra headers to the intercom app without first
      // adding them to the "Access-Control-Allow-Headers" list in the
      // intercom_base_controller or parts of the app will break. More context:
      // https://github.com/intercom/intercom/issues/155872
      if (!options.bypassCsrf) {
        xhr.setRequestHeader('X-CSRF-Token', token);
      }

      xhr.setRequestHeader('X-EMBERCOM-REVISION-ID', revisionId);

      if (window.location.search.includes('show_l10n')) {
        xhr.setRequestHeader('X-Hamsterify-Translations', 'true');
      }

      if (ENV.environment === 'test') {
        xhr.setRequestHeader('X-Embercom-Test', 'true');
      }

      let error = options.error;
      options.error = (jqxhr, textStatus, errorThrown) => {
        if (this._shouldRedirectToSignIn(jqxhr.status, options)) {
          document.location.href = `/admins/sign_in?redirect_url=${encodeURIComponent(
            document.location.href,
          )}`;
          return false;
        }
        if (typeof error === 'function') {
          if (errorThrown !== 'abort') {
            return error(jqxhr, textStatus, errorThrown);
          }
        }
      };
    });
  },
  _shouldRedirectToSignIn(status, options) {
    let redirectOnStatuses = [];
    if (options.noRedirectOn401 !== true) {
      redirectOnStatuses.push(401);
    }

    if (options.redirectOn403) {
      redirectOnStatuses.push(403);
    }

    return redirectOnStatuses.includes(status);
  },
  _getCsrfToken() {
    return $('meta[name="csrf-token"]').attr('content');
  },
  _getRevisionId() {
    return $('meta[name="embercom_revision_id"]').attr('content');
  },
};
