/* RESPONSIBLE TEAM: team-help-desk-experience */
import { helper as buildHelper } from '@ember/component/helper';
import { isPresent as emberIsPresent } from '@ember/utils';

export interface IsPresentSignature {
  Args: {
    Positional: unknown[];
  };
  Return: boolean;
}

export function isPresent(params: unknown[]) {
  return emberIsPresent(params[0]);
}

const helper = buildHelper<IsPresentSignature>(isPresent);
export default helper;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'is-present': typeof helper;
  }
}
