/* RESPONSIBLE TEAM: team-frontend-tech */
import { Promise as EmberPromise } from 'rsvp';
let promise = function (fn) {
  fn = fn || (() => {});
  return new EmberPromise(function (resolve) {
    resolve(fn());
  });
};

export default promise;
