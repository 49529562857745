/* RESPONSIBLE TEAM: team-workflows */
import { helper } from '@ember/component/helper';
import { roleNames } from 'embercom/models/data/matching-system/matching-constants';
import toSentence from 'embercom/lib/to-sentence';
import { pluralize } from 'ember-inflector';
import { capitalize } from '@ember/string';

export function audienceTypes(roles) {
  roles = roles || [];
  let names = roles.map((role) => capitalize(pluralize(roleNames[role])));
  return toSentence(names);
}

function audienceTypesHelper([roles]) {
  return audienceTypes(roles);
}

export default helper(audienceTypesHelper);
