/* RESPONSIBLE TEAM: team-reporting */
import percent from 'embercom/lib/percentage-formatter';
import { isArray } from '@ember/array';

/**
 * Replaces `value` with sum of all context bucket values.
 *
 * @param {{context: array, value: number}} signal The input signal values
 * @return {{context: array, value: number}} Transformed signal
 *
 * @example
 *   let signal = Signal.create({
 *     originalContext: [{key: "a", value: 1}, {key: "b", value: 2}],
 *     originalValue: null
 *   })
 *
 *   signal.transform(sumContextToValue) == {
 *     originalContext: [{key: "a", value: 1}, {key: "b", value: 2}],
 *     originalValue: 3
 *   }
 */
export const sumContextToValue = ({ context, value }) => ({
  context,
  value: context ? context.reduce((sum, bucket) => sum + bucket.value, 0) : null,
});

/**
 * Groups input context with list of keys for each category and produces
 * sum of the values for each group
 *
 * @param {{key: string, sumKeys: string[]}[]} categories List of categories to map context on
 * @param {{context: array, value: number}} signal The input signal values
 * @return {{context: array, value: number}} Transformed signal
 *
 * @example
 *   let categories = [{key: "a_and_b", sumKeys: ["a", "b"]}]
 *   let signal = Signal.create({
 *     originalContext: [{key: "a", value: 1}, {key: "b", value: 2}, {key: "c", value: 5}],
 *     originalValue: 8
 *   })
 *
 *   signal.transform(sumBucketsToCategories(categories)) == {
 *     originalContext: [{key: "a_and_b", value: 3}],
 *     originalValue: 3
 *   }
 */
export const sumBucketsToCategories =
  (categories) =>
  ({ context, value }) => {
    let categoriesContext = categories.map((cat) => ({
      key: cat.key,
      value: context.reduce(
        (sum, bucket) => (cat.sumKeys.includes(bucket.key) ? sum + bucket.value : sum),
        0,
      ),
    }));

    return sumContextToValue({
      context: categoriesContext,
    });
  };

/**
 * Swaps value field into context and produces sum of its values as the new top level value
 * This is useful for signals using value_count aggregation
 *
 * @param {{context: array, value: number}} signal The input signal values
 * @return {{context: array, value: number}} Transformed signal
 *
 * @example
 *   let signal = Signal.create({
 *     originalContext: null,
 *     originalValue: [{key: "a", value: 1}, {key: "b", value: 2}]
 *   })
 *
 *   signal.transform(topValuesSignalToContextSignal) == {
 *     originalContext: [{key: "a", value: 1}, {key: "b", value: 2}],
 *     originalValue: 3
 *   }
 */
export const topValuesSignalToContextSignal = ({ context, value }) =>
  sumContextToValue({
    context: value,
  });

/**
 * Prepends string to each context bucket key with colon (:)
 *
 * @param {string} prefix String used to prefix to bucket's key
 * @param {{context: array, value: number}} signal The input signal values
 * @return {{context: array, value: number}} Transformed signal
 *
 * @example
 *   let signal = Signal.create({
 *     originalContext: [{key: "a", value: 1}, {key: "b", value: 2}],
 *     originalValue: 3
 *   })
 *
 *   signal.transform(withPrefix("msg")) == {
 *     originalContext: [{key: "msg:a", value: 1}, {key: "msg:b", value: 2}],
 *     originalValue: 3
 *   }
 */
export const prefixContextKey =
  (prefix) =>
  ({ context, value }) => ({
    context: context.map((bucket) => ({ ...bucket, key: `${prefix}:${bucket.key}` })),
    value,
  });

/**
 * Sorts context list by the context items value ascending
 *
 * @param {{context: array, value: number}} signal The input signal values
 * @return {{context: array, value: number}} Transformed signal
 *
 * @example
 *   let signal = Signal.create({
 *     originalContext: [{key: "a", value: 66}, {key: "b", value: 22}],
 *     originalValue: 88
 *   })
 *
 *   signal.transform(sortContext) == {
 *     originalContext: [{key: "b", value: 22}, {key: "a", value: 66}],
 *     originalValue: 88
 *   }
 */
export const sortContext = ({ context, value }) => ({
  context: context.sortBy('value'),
  value,
});

/**
 * Concatenates contexts of otherSignal to context of transforming signal, sorting it by the context values
 *
 * @param {Signal} otherSignal other signal that will be appended to the transformed signal
 * @param {{context: array, value: number}} signal The input signal values
 * @return {{context: array, value: number}} Transformed signal
 *
 * @example
 *   let signal1 = Signal.create({
 *     originalContext: [{key: "a", value: 11}, {key: "b", value: 55}],
 *     originalValue: 66
 *   })
 *   let signal2 = Signal.create({
 *     originalContext: [{key: "c", value: 22}],
 *     originalValue: 22
 *   })
 *
 *   signal1.transform(concatAndSortSignalWith(signal2)) == {
 *     originalContext: [{key: "a", value: 11}, {key: "c", value: 22}, {key: "b", value: 55}],
 *     originalValue: 88
 *   }
 */
export const concatAndSortSignalWith =
  (otherSignal) =>
  ({ context, value, previous }) => {
    let otherData = otherSignal.contextAndValuePair({ previous });
    let combinedContext = context.concat(otherData.context);

    return sortContext({
      context: combinedContext,
      value: value + otherData.value,
    });
  };

/**
 * Reverses context list
 *
 * @param {{context: array, value: number}} signal The input signal values
 * @return {{context: array, value: number}} Transformed signal
 *
 * @example
 *   let signal = Signal.create({
 *     originalContext: [{key: "a", value: 11}, {key: "b", value: 55}],
 *     originalValue: 66
 *   })
 *
 *   signal.transform(sortContext) == {
 *     originalContext: [{key: "b", value: 55}, {key: "a", value: 11}],
 *     originalValue: 66
 *   }
 */
export const reverse = ({ context, value }) => ({
  context: context.reverse(),
  value,
});

/**
 * Converts signal context values to their respective percentages
 *
 * @param {{context: array, value: number}} signal The input signal values
 * @return {{context: array, value: number}} Transformed signal
 *
 * @example
 *   let signal = Signal.create({
 *     originalContext: [{key: "a", value: 11}, {key: "b", value: 55}],
 *     originalValue: 66
 *   })
 *
 *   signal.transform(asPercentages) == {
 *     originalContext: [{key: "a", value: 17}, {key: "b", value: 83}],
 *     originalValue: 100
 *   }
 */
export const asPercentages = ({ context, value: total }) => {
  let percentagesByKey = context.reduce(
    (stackableData, contextItem) => ({
      ...stackableData,
      [contextItem.key]: percent(total, contextItem.value),
    }),
    {},
  );

  return {
    context: context.map((c) => ({
      ...c,
      value: percentagesByKey[c.key],
    })),
    value: 100,
  };
};

/**
 * Filters context list to buckets where key value is included in keys.
 * Replaces top level value with sum of resulting buckets
 *
 * @param {string[]} keys Keys to be filtered into result
 * @param {{context: array, value: number}} signal The input signal values
 * @return {{context: array, value: number}} Transformed signal
 *
 * @example
 *   let signal = Signal.create({
 *     originalContext: [{key: "a", value: 1}, {key: "b", value: 2}, {key: "c", value: 4}],
 *     originalValue: 7
 *   }
 *
 *   signal.transform(filterContextKeys(["a","c"])) == {
 *     originalContext: [{key: "a", value: 1}, {key: "c", value: 4}],
 *     originalValue: 5
 *   }
 */
export const filterContextKeys =
  (keys) =>
  ({ context, value }) =>
    sumContextToValue({
      context: context.filter((c) => keys.includes(c.key)),
      value,
    });

export const filterOutContextKeys =
  (keys) =>
  ({ context, value }) =>
    sumContextToValue({
      context: context.filter((c) => !keys.includes(c.key)),
      value,
    });

/**
 * Sets context's value to be value of `count`. And replaces top level `value` with sum of counts of context.
 * Useful for signals where you want to report on individual events, rather than their aggregations
 *
 * @param {{context: array, value: number}} signal The input signal values
 * @return {{context: array, value: number}} Transformed signal
 *
 * @example
 *   let signal = Signal.create({
 *     originalContext: [{key: "a", value: 1, count: 4}, {key: "b", value: 2, count: 4}, {key: "c", value: 4, count: 6}],
 *     originalValue: 7
 *   }
 *
 *   signal.transform(useDocCountsAsValue) == {
 *     originalContext: [{key: "a", value: 4, count: 4}, {key: "b", value: 4, count: 4}, {key: "c", value: 6, count: 6],
 *     originalValue: 12
 *   }
 */
export const useDocCountsAsValue = ({ context, value }) => ({
  context: context.map((bucket) => ({
    ...bucket,
    value: isArray(bucket.value)
      ? useDocCountsAsValue({ context: bucket.value }).context
      : bucket.count,
  })),
  value: context.reduce((sum, bucket) => sum + bucket.count, 0),
});

export const stackBucketsToPercents = ({ context, value }) => ({
  context: context.map((item) => ({
    ...item,
    value: percent(value, item.value) || 0,
    count: item.value,
  })),
  value,
});

export const createBucketsForKeys =
  (keys) =>
  ({ context, value }) => {
    let contextKeysMap = context.reduce((acc, data) => {
      acc[data.key] = data;
      return acc;
    }, {});

    return {
      context: keys.map((key) => {
        let { value = 0, count = 0 } = contextKeysMap[key] || {};
        return {
          key: key.toString(),
          value,
          count,
        };
      }),
      value,
    };
  };

export const stackNestedBucketsToPercents = ({ context, value }) => {
  let contextTotals = context
    .map((contextItem) => contextItem.value)
    .map((contextValue = []) =>
      contextValue.reduce((total, valueItem) => total + valueItem.value, 0),
    );

  return {
    context: context.map((contextItem, i) => {
      let total = contextTotals[i];

      return {
        ...contextItem,
        value: contextItem.value.map((item) => ({
          ...item,
          value: percent(total, item.value) || 0,
          count: item.value,
        })),
      };
    }),
    value,
  };
};

export const useAggregateBucketValueAsBucketCount = ({ context, value }) => {
  let contextValues = context.map((contextItem) => contextItem.value);
  let aggregateValues = contextValues.map((values) =>
    values.reduce((aggregate, val) => aggregate + val.value, 0),
  );

  return {
    context: context.map((contextItem, i) => ({
      ...contextItem,
      count: aggregateValues[i],
    })),
    value,
  };
};

export const createAmazingOrGoodValueForNestedBuckets = ({ context, value }) => {
  let contextValues = context.map((contextItem) => contextItem.value);
  let amazingOrGoodKeys = [5, 4];

  let total = contextValues
    .map((contextValue = []) =>
      contextValue.reduce((total, valueItem) => total + valueItem.value, 0),
    )
    .reduce((total, bucketTotal) => total + bucketTotal, 0);

  let amazingOrGoodTotals = contextValues.map((contextValue = []) =>
    contextValue.reduce(
      (bucketTotal, valueItem) =>
        bucketTotal + (amazingOrGoodKeys.includes(valueItem.key) ? valueItem.value : 0),
      0,
    ),
  );

  let amazingOrGoodTotal = amazingOrGoodTotals.reduce(
    (total, bucketTotal) => total + bucketTotal,
    0,
  );

  return {
    context,
    value: {
      percent: percent(total, amazingOrGoodTotal),
      count: amazingOrGoodTotal,
      totalCount: total,
    },
  };
};

export const createPercentBucketsForNestedKeys =
  (statName, keys) =>
  ({ context, value }) => ({
    context: context.map((item) => {
      let [numerator, denominator] = keys.map(
        (key) => (item.value.find((data) => data.key === key) || {}).count || 0,
      );
      return {
        key: item.key,
        value: [{ key: statName, value: Math.round(percent(numerator, denominator)) || 0 }],
      };
    }),
    value,
  });

export const mapContextItemToModelByKey =
  ({ models, valuePath }) =>
  ({ context, value }) => {
    let modelIdsMap = models.reduce((acc, model) => {
      acc[model.id] = model;
      return acc;
    }, {});

    return {
      context: context.reduce((result, item) => {
        let model = modelIdsMap[item.key];

        if (!model) {
          return result;
        }

        result.push({ ...item, [valuePath]: model.get(valuePath) });
        return result;
      }, []),
      value,
    };
  };
