/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* This function resolves all promises and counters Promise.all's fail fast behavior.
   NB: Assumes promises will return objects.
 */
export default function ResolveAllPromises(promises) {
  return promises.map((promise) =>
    promise
      .then((val) => {
        return { value: val, resolved: true };
      })
      .catch((val) => {
        return { value: val, resolved: false };
      }),
  );
}
