/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import EmberObject from '@ember/object';
import { inject as service } from '@ember/service';

export default EmberObject.extend({
  MAX_BACKGROUND_INBOXES: 5,
  intercomEventService: service(),
  _backgroundInboxes: null,

  init() {
    this._super(...arguments);
    this._backgroundInboxes = [];
  },

  changeInbox(newInbox, oldInbox = null) {
    this._disableIfNotBackgroundable(oldInbox);

    if (oldInbox) {
      oldInbox.clearTransitionedConversations();
      oldInbox.disableRealtime();
    }

    newInbox.enableRealtime();

    if (!this._isInBackground(newInbox)) {
      this._trackEvent('reloaded', newInbox);
      newInbox.reload();
    } else {
      this._trackEvent('foregrounded', newInbox);
      newInbox.sync.perform(undefined, true);
    }

    this._addOrMoveToHeadOfList(newInbox);
    this._pruneExtraInboxes();
  },

  forEach(fn) {
    this._backgroundInboxes.reverse().forEach(fn);
  },

  deactivate() {
    this.forEach((inbox) => {
      inbox.clearConversations();
      inbox.disableRealtime();
    });
    this._backgroundInboxes = [];
  },

  // Private
  _disableIfNotBackgroundable(oldInbox) {
    if (oldInbox && !oldInbox.canBeBackgrounded) {
      oldInbox.disableRealtime();
      oldInbox.clearConversations();
    }
  },

  _isInBackground(inbox) {
    return this._backgroundInboxes.includes(inbox);
  },

  _addOrMoveToHeadOfList(newInbox) {
    if (newInbox.canBeBackgrounded) {
      this._remove(newInbox);
      this._addToHead(newInbox);
    }
  },

  _pruneExtraInboxes() {
    while (this._backgroundInboxes.length > this.MAX_BACKGROUND_INBOXES) {
      let inboxToDeactivate = this._backgroundInboxes.pop();
      inboxToDeactivate.disableRealtime();
      inboxToDeactivate.clearConversations();
    }
  },

  _addToHead(inbox) {
    this._backgroundInboxes.unshift(inbox);
  },

  _remove(inbox) {
    this._backgroundInboxes.removeObject(inbox);
  },

  _trackEvent(action, inbox) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object: 'inbox',
      models: [inbox],
    });
  },
});
