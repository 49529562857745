/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
/* eslint-disable @intercom/intercom/no-bare-strings */
import TTLCache from 'embercom/lib/ttl-cache';
import ConversationSorter from 'embercom/lib/inbox/conversation-sorter';
import TransitionInformation from 'embercom/lib/inbox/transition-information';
import { tracked } from '@glimmer/tracking';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';

const TRANSITION_PART_TTL = 60000;

export default class ConversationList {
  @tracked items;
  @tracked sortedItems;
  @tracked sortKey;
  @tracked sortDirection;
  @tracked applySorting;
  @tracked transitionParts = null;
  @tracked transitionedConversations;
  @tracked transitionInformation;

  @tracked fixedSortOrder = [];

  constructor({ applySorting = true, sortKey = 'sortingUpdatedAt', sortDirection = 'desc' }) {
    this.applySorting = applySorting;
    this.sortKey = sortKey;
    this.sortDirection = sortDirection;
    this.items = [];
    this.sortedItems = [];
    this.transitionParts = new TTLCache();
    this.transitionedConversations = [];
    this.transitionInformation = {};
    this.sorter = ConversationSorter.create();
  }

  // using a computed here to memoize this value
  @computed('items.[]', 'transitionedConversations.[]')
  get unTransitionedConversations() {
    return this.items.filter((x) => this.transitionedConversations.indexOf(x) === -1);
  }

  get length() {
    return this.items.length;
  }

  get isEmpty() {
    return this.length === 0;
  }

  addItems(itemsToAdd, skipTransitionPartCheck = false) {
    itemsToAdd.forEach((item) => {
      if (this._canAddConversation(item, skipTransitionPartCheck)) {
        this.items.pushObject(item);
        this.sortedItems.pushObject(item);
        this._removeFromTransitionParts(item);
        this.transitionedConversations.removeObjects(item);
        delete this.transitionInformation[item.id];
      }
    });
    this.items = this.items;
    if (itemsToAdd.length > 0) {
      this.sort();
    }
  }

  removeItems(items) {
    let existingItems = items.filter((item) => this.items.includes(item));
    this.items.removeObjects(existingItems);
    this.sortedItems.removeObjects(existingItems);
    this.transitionedConversations.removeObjects(items);
    items.forEach((item) => {
      delete this.transitionInformation[item.id];
    });
  }

  findById(id) {
    return this.items.findBy('id', id);
  }

  isValidSort() {
    return this.isAttributeSortKey || this.sorter.isValidSort(this.sortKey, this.sortDirection);
  }

  clear() {
    this.items = [];
    this.sortedItems = [];
    this.transitionedConversations = [];
    this.transitionInformation = {};
    this.sorter.reset();
    this.transitionParts = new TTLCache();
  }

  includes(item) {
    return this.items.includes(item);
  }

  markConversationAsTransitioned(conversation) {
    this._addToTransitionParts(conversation);
    this.transitionedConversations.addObject(conversation);
    this.transitionInformation[conversation.id] = new TransitionInformation(conversation);
    this.transitionInformation = this.transitionInformation;
    this.transitionedConversations = this.transitionedConversations;
  }

  markConversationAsNotTransitioned(conversation) {
    this._removeFromTransitionParts(conversation);
    this.transitionedConversations.removeObject(conversation);
    delete this.transitionInformation[conversation.id];
    this.transitionInformation = this.transitionInformation;
    this.transitionedConversations = this.transitionedConversations;
  }

  get isAttributeSortKey() {
    return this.sortKey.split('.').every((key) => key.match(/^\d+$/));
  }

  sort() {
    if (!this.applySorting) {
      return;
    }

    if (this.isAttributeSortKey) {
      // sort by fixed server provided order if we've got an attribute sort key
      let indexed = this.sortedItems.toArray().reduce((o, c) => {
        o[c.id.toString()] = c;
        return o;
      }, {});
      this.sortedItems = this.fixedSortOrder
        .map((id) => indexed[id])
        .compact()
        .concat(this.sortedItems)
        .uniq();
    } else {
      this.sorter.sort(
        this.sortedItems,
        this.transitionedConversations,
        this.sortKey,
        this.sortDirection,
      );
    }
    this.sortedItems = this.sortedItems.slice();
  }

  transitionedConversationsExcluding(conversation) {
    return this.transitionedConversations.without(conversation);
  }

  sortedUntransitionedConversationsIncluding(conversation) {
    let transitionedConversations = this.transitionedConversationsExcluding(conversation);
    return this.sortedItems.filter((item) => !transitionedConversations.includes(item));
  }

  nearestUntransitionedConversation(from) {
    let next = this.nextUntransitioned(from);
    if (next === from) {
      return this.previousUntransitioned(from);
    }
    return next;
  }

  nextUntransitioned(from) {
    let untransitionedConversations = this.sortedUntransitionedConversationsIncluding(from);
    let fromIndex = untransitionedConversations.indexOf(from);

    if (fromIndex < 0) {
      throw new Error('Could not find next untransitioned, target conversation is not in list');
    }
    if (fromIndex === untransitionedConversations.length - 1) {
      return from;
    }
    return untransitionedConversations.objectAt(fromIndex + 1);
  }

  previousUntransitioned(from) {
    let untransitionedConversations = this.sortedUntransitionedConversationsIncluding(from);
    let fromIndex = untransitionedConversations.indexOf(from);

    if (fromIndex < 0) {
      throw new Error('Could not find previous untransitioned, target conversation is not in list');
    }
    if (fromIndex === 0) {
      return from;
    }
    return untransitionedConversations.objectAt(fromIndex - 1);
  }

  _addToTransitionParts(conversation) {
    this.transitionParts.set(
      conversation.get('id'),
      conversation.get('lastActionPart'),
      TRANSITION_PART_TTL,
    );
  }

  _removeFromTransitionParts(conversation) {
    this.transitionParts.remove(conversation.get('id'));
  }

  _findInTransitionParts(conversation) {
    return this.transitionParts.get(conversation.get('id'));
  }

  _canAddConversation(conversation, skipTransitionPartCheck = false) {
    let items = this.items;
    let transitionPart = this._findInTransitionParts(conversation);
    return (
      (skipTransitionPartCheck || isNewerThanTransitionPart(conversation, transitionPart)) &&
      !items.includes(conversation)
    );
  }
}

function isNewerThanTransitionPart(conversation, transitionPart) {
  return (
    !transitionPart ||
    (transitionPart.get('isPersisted') &&
      Number(conversation.get('lastPart.partId')) > Number(transitionPart.get('partId')))
  );
}
