/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
export const INSTALL_TYPE = Object.freeze({
  CODE: 'code',
  THIRD_PARTY: 'thirdParty',
  GOOGLE_TAG_MANAGER: 'googleTagManager',
});

export const AUDIENCE = Object.freeze({
  LOGGED_OUT: 'logged-out',
  LOGGED_IN: 'logged-in',
});

export const INSTALL_METHODS = Object.freeze({
  JAVASCRIPT: {
    text: 'Basic JavaScript',
    value: 'javascript',
  },
  SINGLE_PAGE: {
    text: 'Single-page apps',
    value: 'single-page',
  },
  RAILS: {
    text: 'Rails gem',
    value: 'rails',
  },
  IOS: {
    text: 'iOS',
    value: 'ios',
  },
  ANDROID: {
    text: 'Android',
    value: 'android',
  },
  CORDOVA: {
    text: 'Cordova/PhoneGap',
    value: 'cordova',
  },
  SEGMENT: {
    text: 'Segment',
    value: 'segment',
  },
  WORDPRESS: {
    text: 'Wordpress',
    value: 'wordpress',
  },
  SHOPIFY: {
    text: 'Shopify',
    value: 'shopify',
  },
  GOOGLE_TAG_MANAGER: {
    text: 'Google Tag Manager',
    value: 'google-tag-manager',
  },
});

export const LOGGED_IN_CODE_DROPDOWN_ITEMS = [
  INSTALL_METHODS.JAVASCRIPT,
  INSTALL_METHODS.SINGLE_PAGE,
  INSTALL_METHODS.RAILS,
  INSTALL_METHODS.IOS,
  INSTALL_METHODS.ANDROID,
];

export const LOGGED_OUT_CODE_DROPDOWN_ITEMS = [
  INSTALL_METHODS.JAVASCRIPT,
  INSTALL_METHODS.SINGLE_PAGE,
  INSTALL_METHODS.RAILS,
];

export const THIRD_PARTY_DROPDOWN_ITEMS = [
  INSTALL_METHODS.GOOGLE_TAG_MANAGER,
  INSTALL_METHODS.SEGMENT,
  INSTALL_METHODS.WORDPRESS,
  INSTALL_METHODS.SHOPIFY,
];

export const INSTALL_METHOD_ANALYTICS_NAMES = Object.freeze({
  javascript: 'basic_javascript',
  'single-page': 'single_page_apps',
  rails: 'rails_gem',
  android: 'android',
  ios: 'ios',
  cordova: 'cordova_phonegap',
  segment: 'segment',
  wordpress: 'wordpress',
  shopify: 'shopify',
  'google-tag-manager': 'google_tag_manager',
});

export const EXTRA_USER_ATTRIBUTES = ['Current page URL'];
