/* RESPONSIBLE TEAM: team-reporting */
import {
  forceCenter,
  forceCollide,
  forceManyBody,
  forceSimulation,
  forceX,
  forceY,
} from 'd3-force';
import { selectAll } from 'd3-selection';
import { RADIUS_WITHOUT_VOLUME } from 'embercom/lib/reporting/bubble-chart/nodes';

const BUBBLE_LAYOUT_MARGIN = 8; // in pixels
const BUBBLE_EDGE_PADDING = 3; // pixels to keep bubbles away from the edge
const DEFAULT_VOLUME_OPACITY = 0.85;
const DEFAULT_RESPONSIVENESS_OPACITY = 0.7;

export class BubbleChartLayout {
  width;
  height;
  pointsOrderedByCentrality;
  acceptedCircles = [];
  simulation;

  init(width, height) {
    this.width = width;
    this.height = height;
    this.acceptedCircles = [];
    let centerX = width / 2;
    let centerY = height / 2;

    // Initialize the simulation with a greater forceY than forceX as we have a
    // landscape layout
    let aspectWeight = height / width;

    this.simulation = forceSimulation()
      .force('boundary', this.boundaryForce(width, height, BUBBLE_EDGE_PADDING))
      .force(
        'collide',
        forceCollide((d) => d.r + BUBBLE_LAYOUT_MARGIN)
          .strength(1.5)
          .iterations(3),
      )
      .force('charge', forceManyBody())
      .force('center', forceCenter(centerX, centerY))
      .force(
        'x',
        forceX(centerX).strength((d) => this.weightedCenteringForce(0.2 * aspectWeight, d.r)),
      )
      .force(
        'y',
        forceY(centerY).strength((d) => this.weightedCenteringForce(0.2 * (1 / aspectWeight), d.r)),
      );
  }

  // Make nodes with a larger radius more attracted to the center
  weightedCenteringForce(baseForce, r) {
    let radiusMultiplier = r / 60;
    return baseForce * radiusMultiplier;
  }

  // We want to add larger nodes to the center of the visualisation
  // and smaller ones near the edges so we weight their starting positions
  // We allocate them to a random side to more evenly distribute them
  weightedStartingPosition(radius, range, index) {
    let allocatedSide = index % 2 ? 1 : -1;
    let maxOffset = range / 2;
    let maxRadius = 125 / 2;
    return maxOffset + maxOffset * allocatedSide * (1 - radius / maxRadius);
  }

  updateDataNodePositions(data, scaleRadius, scaleX, scaleY, showVolume, showAxes) {
    if (showAxes) {
      data.forEach((d) => {
        let radius = showVolume ? scaleRadius(d.currentCount) : RADIUS_WITHOUT_VOLUME;
        d.r = radius;
        d.x = scaleX(d.currentResponseTime);
        d.y = scaleY(d.currentTimeToClose);
        // We determines which label is shown depending on whether we're displaying the volume
        d.internalLabelOpacity = showVolume ? 1 : 0;
        d.externalLabelOpacity = showVolume ? 0 : 1;
        d.opacity = DEFAULT_RESPONSIVENESS_OPACITY;
      });
      return data;
    } else {
      // Fetch the existing data attached to the nodes
      let oldData = new Map(
        selectAll('.reporting__bubble-chart__node')
          .data()
          .map((d) => [d.id, d]),
      );

      // Merge old data with the new data to preserve any existing position data.
      // This ensures existing nodes start moving from where they were as opposed
      // to being allocated a new position
      let nodes = data.map((d) => {
        let old = oldData.get(d.id) || {};
        return { ...old, ...d, vx: 0, vy: 0 };
      });

      // Update the radius of each node and add a weighted starting position for new nodes
      // The weighting roughly positions larger nodes closer to the center
      nodes.forEach((d, i) => {
        let radius = scaleRadius(d.currentCount);
        d.r = radius;
        // Use the previous cluster position as a starting point, if we have one
        // This causes the cluster view to be more stable when toggling layout and suggestions
        d.x = d.volumeX || this.weightedStartingPosition(radius, this.width, i);
        d.y = d.volumeY || this.weightedStartingPosition(radius, this.height, i);
      });

      // Run the simulation to calculate the new positions
      this.simulation.nodes(nodes).alpha(0.7).tick(120);

      // Store the updated cluster positions
      nodes.forEach((d) => {
        d.volumeX = d.x;
        d.volumeY = d.y;
        // Only show the internal label with the cluster view
        d.internalLabelOpacity = 1;
        d.externalLabelOpacity = 0;
        d.opacity = DEFAULT_VOLUME_OPACITY;
      });

      return nodes;
    }
  }

  // Return a boundary force function that limits nodes to
  // stay within the rectangle (0,0) -> (width, height)
  // The `padding` keeps bubbles that far away from the rectangle edges
  boundaryForce(width, height, padding) {
    let nodes;

    function force(_alpha) {
      for (let node of nodes) {
        let effectiveRadius = node.r + padding;
        node.x = Math.max(effectiveRadius, Math.min(width - effectiveRadius, node.x));
        node.y = Math.max(effectiveRadius, Math.min(height - effectiveRadius, node.y));
      }
    }

    force.initialize = function (startingNodes) {
      nodes = startingNodes;
    };

    return force;
  }
}
