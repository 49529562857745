/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
export default class TransitionInformation {
  constructor(conversation) {
    this.conversation = conversation;
    this.icon = this._getIcon();
    this.statement = this._getStatement();
    this.by = this._getBy();
    this.target = this._getTarget();
    this.byRule = !!(
      conversation.isLastPartARuleAssignment || conversation.get('lastPart.isAwayModeAssignment')
    );
  }

  _getIcon() {
    return this.conversation.get('lastActionPart.actionIcon');
  }

  _getStatement() {
    if (this.conversation.userIsBlocked) {
      return 'removed this conversation';
    }
    return this.conversation.get('lastActionPart.actionStatement');
  }

  _getBy() {
    let convo = this.conversation;
    if (convo.userIsBlocked) {
      return convo.userBlockedBy;
    }
    if (!convo.get('lastPart.isTimerUnsnoozer')) {
      return convo.get('lastActionPart.creator');
    }
  }

  _getTarget() {
    return this.conversation.get('lastActionPart.actionStatementTarget');
  }
}
