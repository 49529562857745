/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import { equal, reads, readOnly } from '@ember/object/computed';
import EmberObject from '@ember/object';

window.INBOX_REALTIME_LOGGING = false;

export default EmberObject.extend({
  store: null,
  realtimeService: null,
  inboxService: null,

  isClosed: equal('inboxService.inbox.status', 'closed'),
  isOpen: equal('inboxService.inbox.status', 'opened'),
  isSnoozed: equal('inboxService.inbox.status', 'snoozed'),

  appService: readOnly('inboxService.appService'),
  currentAssignee: reads('inboxService.inbox.assignee_identifier'),
  currentAssigneeId: reads('inboxService.inbox.assigneeId'),

  willDestroy() {
    this.disable();
  },

  enable() {
    this.bindEvents();
    this.inboxService.scheduleInboxCountersPoll.perform();
    this.appService.scheduleAppCountersPoll.perform();
  },

  disable() {
    this.unbindEvents();
  },

  bindEvents() {
    let realTimeEventService = this.realtimeService;
    realTimeEventService.on('CountersUpdated', this, 'handleCountersUpdatedEvent');
    realTimeEventService.on('UserDetails', this, 'handleUserDetailsEvent');
  },

  unbindEvents() {
    let realTimeEventService = this.realtimeService;
    realTimeEventService.off('CountersUpdated', this, 'handleCountersUpdatedEvent');
    realTimeEventService.off('UserDetails', this, 'handleUserDetailsEvent');
  },

  handleCountersUpdatedEvent(event) {
    this.inboxService.updateInboxCounters(
      event.counters,
      event.treat_missing_assignees_as_zero,
      event.source,
    );
    this.appService.scheduleAppCountersPoll.perform();
  },

  handleUserDetailsEvent(event) {
    if (event.lead_shares_email_with_user) {
      this.get('inboxService.inbox.conversations')
        .filterBy('main_participant.id', event.id)
        .forEach((conversation) => {
          conversation.set('lead_shares_email_with_user', event.lead_shares_email_with_user);
        });
    }
  },
});
