/* RESPONSIBLE TEAM: team-reporting */
import { formatMinutes } from 'embercom/lib/duration-formatter';

export default class Formatter {
  formatData(data) {
    return data / 60;
  }

  formatCounter(data) {
    return formatMinutes(this.formatData(data));
  }

  formatAxis(data) {
    return formatMinutes(this.formatData(data));
  }

  formatTooltip(data) {
    return formatMinutes(this.formatData(data));
  }
}
