/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';
import type CustomObjects from 'embercom/services/custom-objects-service';

export default class AttributeNameHelper extends Helper<{
  Args: {
    Positional: [string];
  };
  Return: string;
}> {
  @service declare appService: any;
  @service attributeService: any;
  @service declare customObjectsService: CustomObjects;

  get attributes() {
    return this.appAttributes
      .concat(this.attributeService.nonArchivedConversationCustomAttributes)
      .concat(this.customObjectAttributes);
  }

  get appAttributes() {
    return this.appService.app ? this.appService.app.attributes.toArray() : [];
  }

  get customObjectAttributes() {
    return this.customObjectsService.objectTypes
      .compact()
      .map((objectType: any) => {
        if (!objectType.attributeDescriptorsInclStandard) {
          return;
        }
        return objectType.attributeDescriptorsInclStandard.compact().map((attribute: any) => {
          return {
            objectTypeName: objectType.name,
            templatableIdentifier: `custom_object_attribute.${attribute.id || attribute.name}`,
            name: attribute.name,
          };
        });
      })
      .flat()
      .compact();
  }

  compute([path]: [string]): string {
    let suffix = '';

    if (path.startsWith('event.')) {
      suffix = ` ${path.substring(path.lastIndexOf('.') + 1)}`;
      path = path.substring(0, path.lastIndexOf('.'));
    }

    if (path.includes('.related_objects.')) {
      path = `custom_object_attribute.${path.substring(path.lastIndexOf('.') + 1)}`;
    }

    let attribute = this.attributes.findBy('templatableIdentifier', path);
    if (!attribute) {
      return path;
    }

    let attributeName: string = attribute.humanFriendlyName || attribute.name;

    return attributeName + suffix;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'attribute-name': typeof AttributeNameHelper;
  }
}
