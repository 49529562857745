/* RESPONSIBLE TEAM: team-frontend-tech */
/**
 * Get all possible subsets of the given array.
 * For example [a, b] will return [[], [a], [b], [a, b], [a, b, c]]
 *
 * This is not particularly efficient, so do not use it for larges arrays.
 *
 * Implementation source: https://stackoverflow.com/a/47147597/12331237
 */
export function allSubsets(array) {
  return array.reduce(
    (subsets, value) => {
      return subsets.concat(subsets.map((set) => [...set, value]));
    },
    [[]],
  );
}
