/* RESPONSIBLE TEAM: team-frontend-tech */
export default function (start, end) {
  let arrayLength = Math.abs(end - start) + 1;
  if (start < end) {
    return Array(arrayLength)
      .fill()
      .map((_, i) => start + i);
  } else {
    return Array(arrayLength)
      .fill()
      .map((_, i) => start - i);
  }
}
