/* RESPONSIBLE TEAM: team-reporting */
import OAuthProviders from 'embercom/lib/import/oauth-providers';

export function buildAuthorizeUrl(conversationImport) {
  switch (conversationImport.origin) {
    case 'zendesk_chat':
      return buildZendeskChatAuthorizeUrl(conversationImport, OAuthProviders.zendesk_chat);
    case 'zendesk_tickets':
      return buildZendeskTicketsAuthorizeUrl(conversationImport, OAuthProviders.zendesk_tickets);
    default:
      throw new Error('OAuth origin invalid');
  }
}

function buildZendeskChatAuthorizeUrl(conversationImport, oauthProvider) {
  let baseUrl = oauthProvider.authorizeUrl(conversationImport.subdomain);
  let redirectUrl = oauthProvider.redirectUrl;
  let clientId = conversationImport.client_id;
  let subdomain = conversationImport.subdomain;
  let url =
    `${baseUrl}?client_id=${encodeURIComponent(clientId)}` +
    `&redirect_uri=${encodeURIComponent(redirectUrl)}` +
    `&scope=read` +
    `&response_type=code`;

  if (subdomain) {
    url += `&subdomain=${encodeURIComponent(subdomain)}`;
  }

  return url;
}

function buildZendeskTicketsAuthorizeUrl(conversationImport, oauthProvider) {
  let baseUrl = oauthProvider.authorizeUrl(conversationImport.subdomain);
  let redirectUrl = oauthProvider.redirectUrl;
  let clientId = conversationImport.client_id;

  return (
    `${baseUrl}?redirect_uri=${encodeURIComponent(redirectUrl)}` +
    `&client_id=${encodeURIComponent(clientId)}` +
    `&scope=read` +
    `&response_type=code`
  );
}
