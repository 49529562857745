/* RESPONSIBLE TEAM: team-growth-opportunities */

export default {
  setActivePlans(planIds, products, overrideExistingPlans) {
    if (!planIds) {
      return;
    } else if (typeof planIds === 'string') {
      //  Ghost Inspector tests rely on this parsing to set planIds, as QATestApps
      //  do not get marked as early stage applicants.
      planIds = JSON.parse(planIds);
    }
    return products.forEach((product) => {
      let plans = product.get('plans');
      let productPlanIds = plans.map((plan) => plan.get('id'));

      let shouldModifyProductPlans = planIds.any((planId) => productPlanIds.includes(planId));
      if (shouldModifyProductPlans || overrideExistingPlans) {
        plans.forEach((plan) => {
          plan.set('active', planIds.includes(plan.get('id')));
        });
      }
    });
  },

  setActivePlansFromFeatureName(featureName, products) {
    return products.forEach((product) => {
      let standardPlan = product.get('plans.lastObject');
      let standardPlanFeatures = standardPlan.get('features').map((feature) => feature.get('key'));
      if (product.get('hasOnePlan')) {
        if (standardPlanFeatures.includes(featureName)) {
          standardPlan.set('active', true);
        }
      } else {
        let litePlan = product.get('plans.firstObject');
        let litePlanFeatures = litePlan.get('features').map((feature) => feature.get('key'));

        if (standardPlanFeatures.includes(featureName)) {
          standardPlan.set('active', true);
          litePlan.set('active', false);
        } else if (litePlanFeatures.includes(featureName)) {
          litePlan.set('active', true);
          standardPlan.set('active', false);
        }
      }
    });
  },
};
