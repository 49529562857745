/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { A } from '@ember/array';
const RICH_CONTENT_BLOCK_TEXT = {
  image: 'an image',
  attachmentList: 'an attachment',
  video: 'a video',
};

function getTextForRichContentType(type) {
  return RICH_CONTENT_BLOCK_TEXT[type];
}

function isRichContentType(type) {
  return Boolean(getTextForRichContentType(type));
}

function getText(block) {
  if (block.get('type') === 'orderedList') {
    return block
      .get('items')
      .map((item, index) => `${index + 1}. ${item}`)
      .join(', ');
  } else if (block.get('type') === 'unorderedList') {
    return block.get('items').join(', ');
  } else {
    return block.get('text') || block.get('content');
  }
}

export default function (blocks) {
  let summaryText = A(blocks)
    .reject((block) => block.get('type') === 'button')
    .map((block) => getText(block))
    .filter((text) => Boolean(text));

  let strippedSummaryText = summaryText.join(' ').replace(/<br>/g, ' ');
  if (strippedSummaryText.length === 0) {
    let firstRichContentBlock = blocks.filter((block) => isRichContentType(block.get('type')))[0];
    if (firstRichContentBlock) {
      return `Sent you ${getTextForRichContentType(firstRichContentBlock.get('type'))}`;
    }
  }
  return strippedSummaryText;
}
