/* RESPONSIBLE TEAM: team-tickets-1 */
let _getCompactionProperties = (part, memoizedProps) => {
  let partId = part.get('id');
  let memoized = memoizedProps[partId];
  if (memoized) {
    return memoized;
  }
  memoized = part.getProperties(
    'isUserPart',
    'isAdminPart',
    'isNotCompactable',
    'admin',
    'created_at',
    'participant',
  );
  if (partId) {
    memoizedProps[partId] = memoized;
  }
  return memoized;
};

let _getTimestampTime = (timestamp) => (timestamp ? new Date(timestamp).getTime() : undefined);

let _shouldCompactNextPart = (currentPart, nextPart, memoizedCompactionProperties) => {
  let currentPartProps = _getCompactionProperties(currentPart, memoizedCompactionProperties);
  let nextPartProps = _getCompactionProperties(nextPart, memoizedCompactionProperties);
  if (currentPartProps.isUserPart && nextPartProps.isAdminPart) {
    return false;
  }
  if (currentPartProps.isAdminPart && nextPartProps.isUserPart) {
    return false;
  }
  if (currentPartProps.isNotCompactable || nextPartProps.isNotCompactable) {
    return false;
  }
  if (currentPartProps.isAdminPart && nextPartProps.isAdminPart) {
    if (currentPartProps.admin !== nextPartProps.admin) {
      return false;
    }
  }
  if (currentPartProps.isUserPart && nextPartProps.isUserPart) {
    if (currentPartProps.participant !== nextPartProps.participant) {
      return false;
    }
  }
  if (currentPart.get('hasErrored')) {
    return false;
  }
  let currentItemCreatedAtStamp = _getTimestampTime(currentPartProps.created_at);
  let nextItemCreatedAtStamp = _getTimestampTime(nextPartProps.created_at);
  return nextItemCreatedAtStamp - currentItemCreatedAtStamp <= 60 * 1000;
};

export default function compactParts(parts = [], memoizedCompactionProperties = {}) {
  parts.forEach((item, index) => {
    let previousPart = index === 0 ? null : parts[index - 1];
    let nextPart = index === parts.length - 1 ? null : parts[index + 1];
    let shouldCompactNextPart = nextPart
      ? _shouldCompactNextPart(item, nextPart, memoizedCompactionProperties)
      : false;
    let shouldCompactPreviousPart = previousPart
      ? _shouldCompactNextPart(previousPart, item, memoizedCompactionProperties)
      : false;

    if (index === 0) {
      if (shouldCompactNextPart) {
        item.setCompactProperties(true, true, false);
      } else {
        item.setCompactProperties(false, false, false);
      }
    } else if (index === parts.length - 1) {
      if (shouldCompactPreviousPart) {
        item.setCompactProperties(false, true, true);
      } else {
        item.setCompactProperties(false, false, false);
      }
    } else {
      if (shouldCompactNextPart) {
        if (shouldCompactPreviousPart) {
          item.setCompactProperties(false, true, false);
        } else {
          item.setCompactProperties(true, true, false);
        }
      } else if (shouldCompactPreviousPart) {
        item.setCompactProperties(false, true, true);
      } else {
        item.setCompactProperties(false, false, false);
      }

      if (nextPart) {
        nextPart.set('compact', shouldCompactNextPart);
      }
      if (item.get('compact')) {
        if (!previousPart.get('compact')) {
          item.set('isFirstCompactedPart', true);
        }
        if (nextPart && !nextPart.get('compact')) {
          item.set('isLastCompactedPart', true);
        }
      }
    }
  });
  return memoizedCompactionProperties;
}
