/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
import {
  STEP_TYPE_CHAT_MESSAGE,
  STEP_TYPE_MESSENGER_APP,
  STEP_TYPE_TERMINAL,
  STEP_TYPE_FOLLOW_UP_RULES,
  STEP_TYPE_MID_PATH_ACTIONS,
  STEP_TYPE_ANSWER_BOT,
  STEP_TYPE_CUSTOM_OBJECT_SELECTOR,
  STEP_TYPE_CONDITIONAL_BRANCHES,
  STEP_TYPE_WORKFLOW_CONNECTOR,
  CONTROL_TYPE_SUCCESS,
  CONTROL_TYPE_FOLLOW_UP,
  CONTROL_TYPE_FALLBACK,
  CONTROL_TYPE_CONDITIONAL_BRANCH,
  STEP_TYPE_ATTRIBUTE_COLLECTOR,
  STEP_TYPE_TRIGGER_WORKFLOW,
  STEP_TYPE_START_OVER,
} from 'embercom/lib/operator/custom-bots/constants';
import { action } from '@ember/object';
import { isNone } from '@ember/utils';

export default class EditorActions {
  constructor(editor) {
    this.editor = editor;
    this.store = editor.store;
    this.analyticsData = this._analyticsData(editor.contentObject.contentType);
    this.intl = editor.intl;
  }

  get contentObject() {
    return this.editor.contentObject;
  }

  @action
  addPath(newPathIndex) {
    let newStep = this._createNewStep(STEP_TYPE_CHAT_MESSAGE);
    let finalPathStep = this._createNewStep(STEP_TYPE_TERMINAL);
    let newPath = this.store.createFragment('operator/workflows/path', {
      steps: [newStep, finalPathStep],
    });
    this.contentObject.addPath(newPath, newPathIndex);
    this._trackChangedEvent('add-path');
    return newPath;
  }

  @action
  addPathWithTriggerWorkflowAndLinkToControl(workflowId, pathTitle, control) {
    let newStep = this._createNewStep(STEP_TYPE_TRIGGER_WORKFLOW);
    this.setTriggerableWorkflowId(newStep, workflowId);

    let newPathIndex = this._newPathIndexAfterCurrentPath();
    let newPath = this.store.createFragment('operator/workflows/path', {
      title: pathTitle,
      steps: [newStep],
    });
    this.contentObject.addPath(newPath, newPathIndex);
    control.linkToPath(newPath);

    this._trackChangedEvent('add-handover-to-bot-path');
  }

  @action
  addPathAndLinkToControl(control) {
    let newPathIndex = this._newPathIndexAfterCurrentPath();
    let newPath = this.addPath(newPathIndex);
    control.linkToPath(newPath);
  }

  @action
  async selectPath(path) {
    if (this.contentObject.isUploading) {
      let confirmed = await this.editor.intercomConfirmService.confirm({
        body: 'The image or video you added is still uploading. Changing path before the upload finishes will discard it.',
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Discard changes',
        primaryButtonType: 'primary-destructive',
        title: 'Wait for upload to finish?',
      });

      if (confirmed) {
        this.contentObject.setSelectedPath(path);
      }
    } else {
      this.contentObject.setSelectedPath(path);
    }
  }

  @action
  async deletePath(path) {
    let pathIndex = path.index;
    let customBot = this.contentObject;
    let confirmed = await this.editor.intercomConfirmService.confirm(
      this._buildDeleteOptionsForPath(path),
    );
    if (!confirmed) {
      return;
    }
    customBot.removePath(path);
    this._trackChangedEvent('remove-path');
    this._removeStartOverControlsInDeletedPath(path);
    this._removeDeletedPathFromAnyControls(path);
    this._removeDeletedPathFromIncomingControls(path);
    if (path !== this.contentObject.selectedPath) {
      return;
    }
    let paths = customBot.get('paths');
    let pathToSelect = paths.objectAt(pathIndex) || paths.objectAt(pathIndex - 1);
    this.contentObject.setSelectedPath(pathToSelect);
  }

  @action
  endPathWith(type) {
    this._removeLastPlaceholderStep();
    let indexForLastStep = this.contentObject.selectedPath.steps.length;
    this._createStepAndAddToPath(indexForLastStep, type);
    this._trackChangedEvent(`end-path-with-${type}`);
  }

  @action
  addQuickReply(step) {
    let newControl = this.store.createFragment('operator/workflows/control', {
      attributeIdentifier: step.attributeIdentifier,
      path: this.contentObject.selectedPath,
    });
    step.controls.pushObject(newControl);
    this._trackChangedEvent('add-quick-reply');
  }

  @action
  addStartOverButton(step) {
    let newControl = this.store.createFragment('operator/workflows/control', {
      attributeIdentifier: step.attributeIdentifier,
      path: this.contentObject.selectedPath,
      buttonLabel: this.intl.t(
        'operator.custom-bot.editor.start-over.default-start-over-button-label',
      ),
      isStartOver: true,
    });
    step.controls.pushObject(newControl);
    let newStep = this._createNewStep(STEP_TYPE_START_OVER);
    let newPath = this.store.createFragment('operator/workflows/path', {
      steps: [newStep],
      isStartOverPath: true,
      title: this.intl.t('operator.custom-bot.editor.start-over.default-start-over-path-title'),
    });
    this.contentObject.addPath(newPath);
    newControl.linkToPath(newPath);
    this._trackChangedEvent('add-start-over-button');
  }

  @action
  addConditionalBranch(step) {
    let newControl = this._buildConditionalBranch();
    step.controls.insertAt(step.controls.length - 1, newControl);
    this._trackChangedEvent('add-conditional-branch');
  }

  @action
  reorderQuickReplies(step, reorderedQuickReplies) {
    step.set('controls', reorderedQuickReplies);
    this._trackChangedEvent('reorder-quick-replies');
  }

  @action
  linkPathToControl(control, path) {
    control.linkToPath(path);
  }

  @action
  unlinkPathToControl(control) {
    control.unlinkToPath();
  }

  @action
  deleteQuickReply(step, control) {
    this._deleteBranchingControl(step, control);
    if (control.isStartOver && control.nextPath?.isStartOverPath) {
      this._deleteStartOverPath(control.nextPath);
    }
  }

  @action
  deleteConditionalBranch(step, control) {
    if (control !== step.controls.lastObject) {
      this._deleteBranchingControl(step, control);
    }
  }

  @action
  resetEndState() {
    let lastStep = this.contentObject.selectedPath.steps.lastObject;
    if (lastStep.canBranch) {
      this._updateIncomingPathLinks(lastStep);
    }
    this._deleteStepFromSelectedPath(lastStep);
    this.addStepToSelectedPath(STEP_TYPE_TERMINAL);
    this._trackChangedEvent('remove-end-step');
  }

  @action
  addStepToSelectedPath(type, args) {
    let stepsWithoutFinalStep = this.contentObject.selectedPath.steps.filter(
      (step) => !step.get('isEndStateStep'),
    );
    let indexToAddStepTo = stepsWithoutFinalStep.length;
    let newStep = this._createStepAndAddToPath(indexToAddStepTo, type, args);
    this._trackChangedEvent(`add-${type}-step`);
    return newStep;
  }

  @action
  addMidFlowActionStepToSelectedPath(identifier, actionData) {
    let newStep = this.addStepToSelectedPath(STEP_TYPE_MID_PATH_ACTIONS);
    this._addMidFlowActionToStep(newStep, identifier, actionData);
  }

  @action
  deleteStepFromSelectedPath(step) {
    this._deleteStepFromSelectedPath(step);
  }

  @action
  reorderSteps(reorderedSteps) {
    let endStateStep = this.contentObject.selectedPath.endStateStep;
    if (endStateStep) {
      reorderedSteps = reorderedSteps.concat([endStateStep]);
    }
    this.contentObject.selectedPath.steps = reorderedSteps;
    this._trackChangedEvent('reorder-steps');
  }

  @action
  reorderPaths(reorderedPaths) {
    this.contentObject.reorderPaths(reorderedPaths);
    this._trackChangedEvent('reorder-paths');
  }

  @action
  setTriggerableWorkflowId(step, workflowId) {
    step.triggerableWorkflowId = workflowId;
  }

  _analyticsData(object) {
    let place = `${object}s`;
    return { object, place };
  }

  _addMidFlowActionToStep(step, identifier, actionData = {}) {
    step.simpleActionRule.actions.createFragment({
      type: identifier,
      actionData,
      justAdded: true,
    });
  }

  _updateIncomingPathLinks(step) {
    step.controls.forEach((control) => control.nextPath?.incomingControls?.removeObject(control));
  }

  _ensureNewStepIsReachable(path, index) {
    let previousStep = path.get('steps').objectAt(index - 1);
    if (!previousStep || previousStep.get('controls.length') > 0) {
      return;
    }

    previousStep.get('controls').createFragment();
  }

  _createStepAndAddToPath(index, type, args) {
    let newStep = this._createNewStep(type, args);
    let selectedPath = this.contentObject.selectedPath;
    this._addStepToPath(selectedPath, index, newStep);
    this._ensureNewStepIsReachable(selectedPath, index);
    return newStep;
  }

  _addStepToPath(path, index, step) {
    if (isNone(path)) {
      return;
    }
    path.get('steps').insertAt(index, step);
  }

  _createNewStep(type, args) {
    return this.store.createFragment('operator/workflows/step', {
      type,
      blocks: this._buildBlocksForStep(type, args),
      controls: this._buildControlsForStep(type),
      simpleActionRule: this._buildSimpleActionRuleForStep(type),
      stepData: this._buildStepData(type, args),
      justAdded: true,
    });
  }

  _buildBlocksForStep(type, text = '') {
    if (![STEP_TYPE_CHAT_MESSAGE, STEP_TYPE_ANSWER_BOT].includes(type)) {
      return [];
    }
    return [this.store.createFragment('common/blocks/paragraph', { type: 'paragraph', text })];
  }

  _buildControlsForStep(type) {
    let controls = [];

    if (
      [
        STEP_TYPE_TERMINAL,
        STEP_TYPE_FOLLOW_UP_RULES,
        STEP_TYPE_ANSWER_BOT,
        STEP_TYPE_TRIGGER_WORKFLOW,
        STEP_TYPE_START_OVER,
      ].includes(type)
    ) {
      return controls;
    }

    if (type === STEP_TYPE_CUSTOM_OBJECT_SELECTOR) {
      return this._buildCustomObjectSelectorControls();
    }

    if (type === STEP_TYPE_WORKFLOW_CONNECTOR) {
      return this._buildWorkflowConnectorControls();
    }

    if (type === STEP_TYPE_CONDITIONAL_BRANCHES) {
      controls.push(this._buildConditionalBranch());
    }

    let control = this.store.createFragment('operator/workflows/control', {
      path: this.contentObject.selectedPath,
    });
    controls.push(control);

    return controls;
  }

  _buildSimpleActionRuleForStep(type) {
    if (
      (type === STEP_TYPE_TERMINAL && this.contentObject.allowsActionsInTerminalStep) ||
      [STEP_TYPE_FOLLOW_UP_RULES, STEP_TYPE_MID_PATH_ACTIONS, STEP_TYPE_ANSWER_BOT].includes(type)
    ) {
      return this.store.createFragment('rules/rule');
    }

    return null;
  }

  _buildStepData(type, payload = {}) {
    if (type === STEP_TYPE_MESSENGER_APP) {
      payload = {
        cardCreationParams: payload.cardCreationParams,
        previewCanvas: payload.cardJSON.canvas,
        previewMessengerCardUri: payload.cardJSON.uri,
      };
    }

    if (type === STEP_TYPE_CUSTOM_OBJECT_SELECTOR) {
      payload = {
        customObjectSelectorParams: {
          object_type_for_button_generation: undefined,
          attribute_object_type_for_button_generation: undefined,
          relationship_attribute_for_button_generation_id: undefined,
          attribute_object_type_for_user_selection: undefined,
          relationship_attribute_for_user_selection_id: undefined,
          object_type_display_attribute_id: undefined,
        },
      };
    }

    if (type === STEP_TYPE_TERMINAL && this._isAnswerWorkflow()) {
      payload = {
        endingType: 'show_standard_replies',
      };
    }

    if (type === STEP_TYPE_WORKFLOW_CONNECTOR) {
      payload = {
        actionId: payload.action_id,
      };
    }

    if (type === STEP_TYPE_ATTRIBUTE_COLLECTOR) {
      payload = {
        attributeCollectionOverwritable: true,
        attributeCollectionDisabled: false,
      };
    }

    return this.store.createFragment('operator/workflows/step-data', payload);
  }

  _removeLastPlaceholderStep() {
    let lastStep = this.contentObject.selectedPath.steps.lastObject;
    if (lastStep.canBranch) {
      this._updateIncomingPathLinks(lastStep);
    }
    this._deleteStepFromSelectedPath(lastStep);
  }

  _deleteStepFromSelectedPath(step) {
    this._deleteStartOverPathInStep(step);
    this.contentObject.selectedPath.steps.removeObject(step);
    this._trackChangedEvent('delete-step');
  }

  _buildDeleteOptionsForPath(path) {
    return {
      body: `When the path "${path.title}" is deleted any buttons that go to it will no longer go anywhere.`,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Delete',
      primaryButtonType: 'primary-destructive',
      title: 'Delete this path?',
    };
  }

  _removeDeletedPathFromAnyControls(deletedPath) {
    deletedPath.incomingControls.forEach((control) => {
      control.set('nextPath', undefined);
    });
  }

  _removeDeletedPathFromIncomingControls(deletedPath) {
    deletedPath.endStateStep?.branchingControls?.forEach((control) => {
      control.nextPath?.incomingControls?.removeObject(control);
    });
  }

  _removeStartOverControlsInDeletedPath(deletedPath) {
    deletedPath.endStateStep?.controls
      ?.filter((control) => control.isStartOver)
      .forEach((control) => {
        this.deleteQuickReply(deletedPath.endStateStep, control);
      });
  }

  _getNextPathsForPath(path) {
    let lastStep = path.endStateStep;
    return lastStep.branchingControls
      .map((branchingControl) => branchingControl.nextPath)
      .filter((path) => {
        return path !== undefined && !path.isStartOverPath;
      });
  }

  _deleteBranchingControl(step, control) {
    control.nextPath?.incomingControls?.removeObject(control);
    step.controls.removeObject(control);
    if (
      step.hasNoControls &&
      (this.contentObject.selectedPath.index > 0 || this.contentObject.isButton)
    ) {
      step.type = 'terminal';
    }
    let controlType = step.isReplyButtons ? 'quick-reply' : 'conditional-branch';
    this._trackChangedEvent(`delete-${controlType}`);
  }

  _deleteStartOverPathInStep(step) {
    step.controls
      .filter((control) => control.isStartOver)
      .forEach((control) => this.deleteQuickReply(step, control));
  }

  _deleteStartOverPath(path) {
    let pathIndex = path.index;
    let customBot = this.contentObject;
    customBot.removePath(path);
    this._trackChangedEvent('remove-start-over-button');
    this._removeDeletedPathFromAnyControls(path);
    this._removeDeletedPathFromIncomingControls(path);
    if (path !== this.contentObject.selectedPath) {
      return;
    }
    let paths = customBot.get('paths');
    let pathToSelect = paths.objectAt(pathIndex) || paths.objectAt(pathIndex - 1);
    this.contentObject.setSelectedPath(pathToSelect);
  }

  _buildConditionalBranch() {
    return this.store.createFragment('operator/workflows/control', {
      type: CONTROL_TYPE_CONDITIONAL_BRANCH,
      path: this.contentObject.selectedPath,
      predicateGroup: { predicates: [] },
    });
  }

  _buildCustomObjectSelectorControls() {
    let followUpControl = this.store.createFragment('operator/workflows/control', {
      type: CONTROL_TYPE_FOLLOW_UP,
      path: this.contentObject.selectedPath,
    });
    let fallbackControl = this.store.createFragment('operator/workflows/control', {
      type: CONTROL_TYPE_FALLBACK,
      path: this.contentObject.selectedPath,
      buttonLabel: this.editor.intl.t(
        'operator.workflows.custom-object-selector.editor.none-above',
      ),
    });

    return [followUpControl, fallbackControl];
  }

  _buildWorkflowConnectorControls() {
    let successControl = this.store.createFragment('operator/workflows/control', {
      type: CONTROL_TYPE_SUCCESS,
      path: this.contentObject.selectedPath,
    });
    let fallbackControl = this.store.createFragment('operator/workflows/control', {
      type: CONTROL_TYPE_FALLBACK,
      path: this.contentObject.selectedPath,
    });

    return [successControl, fallbackControl];
  }

  _trackChangedEvent(changeType) {
    this.editor.intercomEventService.trackAnalyticsEvent({
      action: 'change',
      object: this.analyticsData.object,
      place: this.analyticsData.place,
      models: [this.contentObject],
      change_type: changeType,
      target: this.contentObject.target,
      state: this.contentObject.state,
    });
  }

  _isAnswerWorkflow() {
    return this.contentObject.contentType === 'answer';
  }

  _isGenericTriageWorkflow() {
    return this.contentObject.contentType === 'generic-triage';
  }

  _newPathIndexAfterCurrentPath() {
    let indexAfterCurrentPath =
      this.contentObject.paths.indexOf(this.contentObject.selectedPath) + 1;
    return this._getNextPathsForPath(this.contentObject.selectedPath).reduce(
      (currentLatestIndex, path) => {
        let potentialIndex = this.contentObject.paths.indexOf(path) + 1;
        if (potentialIndex > currentLatestIndex) {
          return potentialIndex;
        }
        return currentLatestIndex;
      },
      indexAfterCurrentPath,
    );
  }
}
