/* RESPONSIBLE TEAM: team-proactive-support */
import {
  BaseConfig,
  INPUT_RULE_CODE_BLOCK,
  INPUT_RULE_EMOJI,
  INPUT_RULE_INLINE_CODE,
  INPUT_RULE_ORDERED_LIST,
  INPUT_RULE_UNORDERED_LIST,
  INPUT_RULE_MARKDOWN_ANCHOR,
} from '@intercom/embercom-prosemirror-composer';
import { EMOJI_TYPEAHEAD } from '@intercom/embercom-prosemirror-composer';
import EmbercomFileUploader from 'embercom/lib/articles/embercom-file-uploader';
import { INLINE_CONTROL_ALIGNMENT } from '@intercom/embercom-prosemirror-composer/lib/config/composer-config';
import IfElseStatementBlockEditorComposerConfig from 'embercom/objects/composer/config/email-body-if-else-statement-block-editor';

export default class EmailComposerConfig extends BaseConfig {
  autoFocus = false;
  customStylesConfig = {
    allowCustomStyles: true,
    formattingSidebarClass: 'email-composer-formatting-side-bar',
    disableButtonAutoFontColor: false, // modified in prosemirror-body.js at runtime depending on what template is used
  };
  allowImplicitMarginParagraphs = true;
  allowEmptyParagraphs = true;
  allowTextAlignment = true;
  allowedBlocks = [
    'paragraph',
    'ifElseStatement',
    'image',
    'orderedList',
    'unorderedList',
    'heading',
    'subheading',
    'codeBlock',
    'button',
    'html',
    'table',
    'spacer',
    'social',
  ];
  inserters = {
    emojiInserter: 'composer/inserters/emoji-inserter',
    ifElseStatementInserter: {
      name: 'composer/if-else-statement-block-editor',
    },
    hideButtonEditor: true,
    showSocialLinks: false,
  };
  experimental = {
    hideInsertersOnMouseOut: true,
  };
  hideFromBlockFormatter = [INLINE_CONTROL_ALIGNMENT];
  hideFromTextFormatter = [INLINE_CONTROL_ALIGNMENT];
  inputRules = [
    INPUT_RULE_CODE_BLOCK,
    INPUT_RULE_EMOJI,
    INPUT_RULE_ORDERED_LIST,
    INPUT_RULE_UNORDERED_LIST,
    INPUT_RULE_INLINE_CODE,
    INPUT_RULE_MARKDOWN_ANCHOR,
  ];
  placeholder = '';
  textDirection = 'ltr';
  tools = [
    { name: 'text-formatter' },
    { name: 'template-inserter' },
    { name: 'anchor-editor', options: { showHelpLinkHeader: true } },
    { name: 'button-editor', options: { showHelpLinkHeader: true } },
    { name: 'fallback-editor' },
    { name: 'html/edit-button' },
    { name: 'if-else-statement-editor' },
    { name: 'table-editor', options: { maxColumns: 4 } },
    { name: 'image-editor', options: { supportAltAttributeEditing: true } },
    { name: 'media-inserter', options: { showTextInserter: true, alignment: 'centered' } },
  ];
  tables = {
    containers: true,
    responsive: true,
    preventContainerDeletion: true,
    improvedPaste: true,
  };
  typeaheads = [EMOJI_TYPEAHEAD];
  updateImages = true;
  upload = {
    allowedImageFileTypes: ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'],
    allowedAttachmentTypes: ['*'],
    uploader: EmbercomFileUploader,
    attrs: { policyUrl: '' },
  };

  constructor({ app, eventService, resolver, usingBlocksTemplate }) {
    super();
    this.analytics = {
      trackAnalyticsEvent: (eventName, attrs) => {
        eventService.trackAnalyticsEvent({
          action: eventName,
          object: 'email',
          place: 'composer',
          ...attrs,
        });
      },
    };

    this.customStylesConfig.disableButtonAutoFontColor = usingBlocksTemplate;
    this.tools.find((tool) => {
      return tool.name === 'table-editor';
    }).options.enableTableNodeSelection = usingBlocksTemplate;

    this.resizeImages = true;

    this.allowedBlocks.push('small');

    if (usingBlocksTemplate) {
      this.renderDivider = true;
      this.allowedBlocks.push('horizontalRule');
    }

    this.inserters.ifElseStatementInserter.options = {
      composerConfig: new IfElseStatementBlockEditorComposerConfig(app, true),
    };
    this.inserters.showSpacerInserter = usingBlocksTemplate;
    this.inserters.showSocialLinks = usingBlocksTemplate;
    this.templating = { picker: 'common/attribute-picker', resolver };
    this.upload.attrs.policyUrl = `/apps/${app.id}/uploads`;
  }
}
