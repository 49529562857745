/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import toBackendPermissionKey from 'embercom/lib/settings/permissions/to-backend-permission-key';
import isActionPermission from 'embercom/lib/settings/permissions/is-action-permission';
import extractPermissionKeySections from 'embercom/lib/settings/permissions/extract-permission-key-sections';
import containerLookup from 'embercom/lib/container-lookup';

const permissionLabelTranslationHelper = (permissionName) => {
  if (isActionPermission(permissionName)) {
    let [actionParent, actionKey] = extractPermissionKeySections(permissionName);

    let translatedParentTitle = containerLookup('service:intl').t(
      `settings.permissions.${actionParent.replaceAll('_', '-')}-permission-title`,
    );
    let translatedActionTitle = containerLookup('service:intl').t(
      `settings.teammates.edit.${actionKey}-header`,
    );
    return `${translatedParentTitle}: ${translatedActionTitle}`;
  } else {
    // We receive the translation key from the backend for each permission as part of the permissions hierarchy.
    // If this is available to us we use it, otherwise we fall back to the old way of generating the translation key.
    let backendPermissionKey = toBackendPermissionKey(permissionName);

    let backendPermission = containerLookup('service:permissionsHierarchyService')
      ?.permissionsHierarchy[backendPermissionKey];

    if (backendPermission) {
      return containerLookup('service:intl').t(`settings.permissions.${backendPermission.title}`);
    }

    let permissionTitle = `${toBackendPermissionKey(permissionName).replaceAll(
      '_',
      '-',
    )}-permission-title`;
    return containerLookup('service:intl').t(`settings.permissions.${permissionTitle}`);
  }
};

export default permissionLabelTranslationHelper;
