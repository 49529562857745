/* RESPONSIBLE TEAM: team-phone */
import Helper from '@ember/component/helper';

export default class permissionKeyForFrontEnd extends Helper<{
  Args: {
    Positional: [string];
  };
  Return: string;
}> {
  compute([permissionKey]: [any]) {
    return `can_${permissionKey}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'permission-key-for-front-end': typeof permissionKeyForFrontEnd;
  }
}
