/* RESPONSIBLE TEAM: team-frontend-tech */
// Put text in a template and figure out how wide it is

// - container: A DOM node to render the template in
// - template: The template to use,
//    - Template must have one single root node
//    - Placeholders are specified with %@
//    - Placeholders must not have any sibling text
import LRUCache from './lru-cache';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

let MeasureHtml = function (container, template, enableCache, cacheSize) {
  this.container = container;
  this.template = template;
  this.contentNodes = [];

  if (enableCache) {
    this.cache = new LRUCache(cacheSize || 200);
  }

  this.setup();
};

MeasureHtml.prototype.measure = function (data) {
  let width;
  let key = data.join();
  let cached = this.cache && this.cache.get(key);
  if (cached) {
    return cached;
  }

  this.addTemplateNodeToDom();
  this.loadDataIntoContentNodes(data);
  width = this.templateNode.clientWidth;
  if (this.cache) {
    this.cache.put(key, width);
  }
  return width;
};

MeasureHtml.prototype.clear = function () {
  this.removeTemplateNodeFromDom();
};

MeasureHtml.prototype.setup = function () {
  this.createTemplateNode(this.template);
  this.addTemplateNodeToDom();
  this.collectContentNodes();
};

MeasureHtml.prototype.destroy = function () {
  this.removeTemplateNodeFromDom();
  this.container = null;
  this.contentNodes = [];
};

MeasureHtml.prototype.collectContentNodes = function () {
  this.walk(
    this.templateNode,
    function (node) {
      if (node.nodeType === Node.TEXT_NODE && node.textContent === '%@') {
        this.contentNodes.push(node);
      }
    }.bind(this),
  );
};

MeasureHtml.prototype.checkCache = function (data) {
  if (this.cache) {
    let key = data.join();
    if (this.cache[key]) {
      return this.cache[key];
    }
  }
  return false;
};

MeasureHtml.prototype.loadDataIntoContentNodes = function (data) {
  for (let i = 0; i < data.length; i++) {
    this.contentNodes[i].textContent = data[i];
  }
};

MeasureHtml.prototype.createTemplateNode = function (template) {
  let e = document.createElement('div');
  e.innerHTML = sanitizeHtml(template);
  this.templateNode = e.firstChild;
};

MeasureHtml.prototype.addTemplateNodeToDom = function () {
  if (!this.templateNodeInDom) {
    this.container.appendChild(this.templateNode);
    this.templateNodeInDom = true;
  }
};

MeasureHtml.prototype.removeTemplateNodeFromDom = function () {
  if (this.templateNodeInDom) {
    this.container.removeChild(this.templateNode);
    this.templateNodeInDom = false;
  }
};

MeasureHtml.prototype.walk = function (node, func) {
  func(node);
  node = node.firstChild;
  while (node) {
    this.walk(node, func);
    node = node.nextSibling;
  }
};

export default MeasureHtml;
