/* RESPONSIBLE TEAM: team-proactive-support */
import Inflector from 'ember-inflector';

const inflector = Inflector.inflector;

inflector.irregular('person', 'people');
inflector.irregular('this', 'these');
inflector.irregular('has', 'have');
inflector.uncountable('closed');
inflector.uncountable('logs-settings');
inflector.uncountable('dkim-settings');
inflector.uncountable('custom-bounce-settings');
inflector.uncountable('brand-settings');
inflector.uncountable('sender-email-address-settings');
// Meet Ember Inspector's expectation of an export
export default {};
