/* RESPONSIBLE TEAM: team-frontend-tech */
import DefaultPredicateManager from 'embercom/lib/common/predicates/default-predicate-manager';

const USER_TYPES = {
  user_role: 'user',
  contact_role: 'lead',
  visitor_role: 'visitor',
};

export default DefaultPredicateManager.extend({
  getDefaultValue() {
    return 'user_role';
  },

  getUserType() {
    let userType = USER_TYPES[this.get('predicate.value')];
    if (userType || this.predicate.comparison === 'known') {
      return this._typeForRole(userType);
    }
    throw new Error('not a valid user type');
  },

  _typeForRole(role) {
    switch (this.get('predicate.comparison')) {
      case 'ne':
        return `non_${role}`;
      case 'eq':
        return role;
      case 'known':
        return 'everyone';
      default:
        throw new Error(`not a valid user comparison`);
    }
  },
});
