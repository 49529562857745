/* RESPONSIBLE TEAM: team-reporting */
import Helper from '@ember/component/helper';
import { throttle, cancel } from '@ember/runloop';
import ENV from 'embercom/config/environment';

export default class OnWindowThrottledResizeHelper extends Helper {
  compute([
    onResize,
    throttleDelay = ENV.APP._50MS,
    triggerOnLeadingEdge = true,
    fireOnInitialRender = true,
  ]) {
    if (this.eventHandler) {
      window.removeEventListener('resize', this.eventHandler);
    }
    this.eventHandler = () => {
      this.throttleTimer = throttle(
        onResize,
        {
          width: document.body.clientWidth,
          height: document.body.clientHeight,
        },
        throttleDelay,
        triggerOnLeadingEdge,
      );
    };

    window.addEventListener('resize', this.eventHandler);

    if (fireOnInitialRender) {
      this.eventHandler();
    }
  }

  willDestroy() {
    cancel(this.throttleTimer);
    window.removeEventListener('resize', this.eventHandler);
    super.willDestroy();
  }
}
