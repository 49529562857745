/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import permissionLabelTranslationHelper from 'embercom/helpers/permission-label-translation-helper';

export default class PermissionLabelTranslation extends Helper {
  @service permissionsHierarchyService;
  @service intl;

  compute([permissionName]) {
    return permissionLabelTranslationHelper(permissionName);
  }
}
