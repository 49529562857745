/* RESPONSIBLE TEAM: team-frontend-tech */
const hasNestedPredicates = function (predicates) {
  return predicates.any(
    (topLevelPredicate) =>
      topLevelPredicate.predicates &&
      topLevelPredicate.predicates.any(
        (secondLevelPredicate) =>
          secondLevelPredicate.predicates && secondLevelPredicate.predicates.length > 0,
      ),
  );
};

export { hasNestedPredicates };
