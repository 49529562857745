/* RESPONSIBLE TEAM: team-proactive-support */
import ajax from 'embercom/lib/ajax';

export function fetchSyncingState(appId) {
  return ajax({
    url: '/ember/unsubscribe_integration.json',
    type: 'GET',
    data: {
      app_id: appId,
    },
  });
}

export function enableSyncing(appId, accessToken, integrateWith, listId) {
  let params = {
    app_id: appId,
    enabled: true,
    access_token: accessToken,
    integrate_with: integrateWith,
  };

  if (listId) {
    params.list_id = listId;
  }

  return ajax({
    url: '/ember/unsubscribe_integration/enable',
    type: 'POST',
    data: JSON.stringify(params),
  });
}

export function cancelSyncing(appId) {
  return ajax({
    url: '/ember/unsubscribe_integration/disable',
    type: 'POST',
    data: JSON.stringify({
      app_id: appId,
    }),
  });
}
