/* RESPONSIBLE TEAM: team-reporting */
import { isEmpty } from 'underscore';
import { isPresent } from '@ember/utils';
import { buildAxesForDataConfig } from 'embercom/lib/reporting/custom/data-config-builder-helpers';

// There is similiar file in intercom codebase that should be reflected 1:1 in terms of how it works.
// We use file in the ruby codebase to translate data config for sharing reports.
// You can find this file over here:
// https://github.com/intercom/intercom/blob/master/app/services/reporting_service/flexible/data_config_translator.rb
export default class DataRequestBuilder {
  constructor({ dataInputConfiguration, renderableChart }) {
    this.dataInputConfiguration = dataInputConfiguration;
    this.renderableChart = renderableChart;
  }

  convert() {
    // do we need to validate configuration here??? let's skip it for now

    // convert xAxis into the outer group
    let xGroup = this.convertAxis(this.dataInputConfiguration.xAxis);

    let yGroup = this.dataInputConfiguration.yAxis
      ? this.convertAxis(this.dataInputConfiguration.yAxis)
      : {};

    // convert global filters (cloning them since the definition is the same)
    let globalFilters =
      this.dataInputConfiguration.filter && Object.assign(this.dataInputConfiguration.filter, {});

    // convert series into aggregations; extract filters and name
    let seriesData = this.dataInputConfiguration.series.map((s) => this.convertSeries(s));

    // converting each series into a separate data request object
    let dataRequests = seriesData.map((s) => {
      let { viewByGroup, segmentByGroup } = this.buildGroups(xGroup, yGroup, s);
      return this.convertSeriesIntoDataRequest({
        name: s.name,
        time: s.time,
        aggregation: s.aggregation,
        filters: s.filters,
        source: s.source,
        globalFilters,
        xGroup: { ...viewByGroup },
        yGroup: { ...segmentByGroup },
      });
    });

    return dataRequests;
  }

  buildGroups(x, y, series) {
    let viewByGroup = JSON.parse(JSON.stringify(x));
    let segmentByGroup = JSON.parse(JSON.stringify(y));
    if (isPresent(this.renderableChart)) {
      let { xAxis, yAxis } = buildAxesForDataConfig(
        this.renderableChart,
        series.metric,
        series.applyLimitOnServer,
      );
      viewByGroup = this.convertAxis(xAxis);
      segmentByGroup = segmentByGroup ? this.convertAxis(yAxis) : {};
    } else {
      if (viewByGroup.data?.property === 'time' && series.time?.property) {
        viewByGroup.data.property = series.time.property;
      }
      if (segmentByGroup.data?.property === 'time' && series.time?.property) {
        segmentByGroup.data.property = series.time.property;
      }
    }
    return { viewByGroup, segmentByGroup };
  }

  // --------------------------------------------------------------------------------
  // --- AXIS PARSERS ---
  AXIS_PARSER_FUNCTIONS = {
    temporal: this.temporalAxisParser,
    nominal: this.nominalAxisParser,
  };

  temporalAxisParser(axis) {
    return {
      type: 'time',
      data: Object.assign(axis.data, {}),
    };
  }

  nominalAxisParser(axis) {
    return {
      type: 'term',
      data: Object.assign(axis.data, {}),
    };
  }

  convertAxis(axis) {
    if (!axis) {
      return {};
    }

    return this.AXIS_PARSER_FUNCTIONS[axis.type](axis);
  }
  // --------------------------------------------------------------------------------
  // SERIES PARSERS

  SERIES_PARSER_FUNCTIONS = {
    mean: this.genericSeriesParser,
    max: this.genericSeriesParser,
    min: this.genericSeriesParser,
    sum: this.genericSeriesParser,
    percentile: this.percentileSeriesParser,
    median: this.medianSeriesParser,
    count: this.countSeriesParser,
    cardinality: this.cardinalitySeriesParser,
    range: this.rangeSeriesParser,
    scripted: this.genericSeriesParser,
  };

  percentileSeriesParser(series) {
    return {
      type: 'percentile',
      data: {
        property: series.data.property,
        value: series.data.percentileValue,
      },
    };
  }

  medianSeriesParser(series) {
    return {
      type: 'percentile',
      data: {
        property: series.data.property,
        value: 50,
      },
    };
  }

  genericSeriesParser(series) {
    // mean, max, min definitions are the same
    return {
      type: series.type,
      data: Object.assign(series.data, {}),
    };
  }

  countSeriesParser(series) {
    return {
      type: 'count',
      data: {
        property: series.data.property,
      },
    };
  }

  cardinalitySeriesParser(series) {
    return {
      type: 'cardinality',
      data: {
        property: series.data.property,
      },
    };
  }

  rangeSeriesParser(series) {
    return {
      type: 'range',
      data: {
        property: series.data.property,
        ranges: series.data.ranges,
      },
    };
  }

  convertSeries(series) {
    let name = series.name;
    let filters = series.filter;
    let time = series.time;
    let aggregation = this.SERIES_PARSER_FUNCTIONS[series.type](series);
    let source = series.source;
    let metric = series.metric;
    let applyLimitOnServer = series.applyLimitOnServer;

    return {
      name,
      time,
      filters,
      aggregation,
      source,
      metric,
      applyLimitOnServer,
    };
  }
  // --------------------------------------------------------------------------------
  // FINAL PARSERS

  mergeGroupAndAggregations(group, aggregation) {
    group.aggregations = [aggregation];
    return group;
  }

  mergeFilters(globalFilters, filters) {
    // for now, filters are merged with AND boolean operator
    if (isEmpty(globalFilters)) {
      return filters;
    } else if (isEmpty(filters)) {
      return globalFilters;
    } else {
      return {
        type: 'and',
        filters: [globalFilters, filters],
      };
    }
  }

  convertSeriesIntoDataRequest(seriesPayload) {
    this.validateSeriesPayload(seriesPayload);
    let finalFilter = this.mergeFilters(seriesPayload.globalFilters, seriesPayload.filters);

    let basePayload = {
      name: seriesPayload.name,
      source: seriesPayload.source,
    };

    if (finalFilter) {
      basePayload.filter = finalFilter;
    }

    let hasXGroup = Object.keys(seriesPayload.xGroup).length !== 0;
    let hasYGroup = Object.keys(seriesPayload.yGroup).length !== 0;
    let payload = { ...basePayload };

    if (hasYGroup) {
      payload = {
        ...payload,
        groups: [this.mergeGroupAndAggregations(seriesPayload.yGroup, seriesPayload.aggregation)],
      };
    }
    if (hasXGroup) {
      // In case this is a nested group
      if (payload.groups?.length > 0) {
        seriesPayload.xGroup.groups = payload.groups;
        payload = {
          ...payload,
          groups: [seriesPayload.xGroup],
        };
      } else {
        payload = {
          ...payload,
          groups: [this.mergeGroupAndAggregations(seriesPayload.xGroup, seriesPayload.aggregation)],
        };
      }
    }

    if (!hasXGroup && !hasYGroup) {
      payload = { ...payload, aggregations: [seriesPayload.aggregation] };
    }

    if (seriesPayload.time) {
      payload = { ...payload, time: seriesPayload.time };
    }

    return payload;
  }

  validateSeriesPayload(seriesPayload) {
    if (!seriesPayload.source) {
      throw new Error(
        `No source specified for series ${seriesPayload.name}. Source must be specified within each series.`,
      );
    }
  }
}
