/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
function getPreposition(isRemoving) {
  return isRemoving ? 'from' : 'to';
}

export function messageMoveSuccess(model, params) {
  return `Successfully moved your message ${getPreposition(params.isRemoving)} '${params.name}'`;
}

export function messageMoveFailure(model, params) {
  return `Couldn't move your message ${getPreposition(params.isRemoving)} '${
    params.name
  }'. Send us a message and we'll do our best to help out.`;
}
