/* RESPONSIBLE TEAM: team-proactive-support */
export const MESSAGE_VOLUME_TIMELINE = {
  name: 'default_query',
  document_type: 'events',
  range_field: 'event.created_at',
  aggregation_type: 'value_count',
  aggregation_field: 'conversation.id',
};

export const AGGREGATE_BY_MESSAGE_ID = {
  name: 'default_query',
  document_type: 'events',
  range_field: 'event.created_at',
  aggregation_type: 'value_count',
  aggregation_field: 'conversation.message_id',
};

export const USER_VOLUME_TIMELINE = {
  name: 'default_query',
  document_type: 'events',
  range_field: 'event.created_at',
  aggregation_type: 'value_count',
  aggregation_field: 'conversation.id',
};
