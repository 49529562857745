/* RESPONSIBLE TEAM: team-reporting */
import { isEmpty } from '@ember/utils';
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';
import Admin from 'embercom/models/admin';
import CustomAttributes from 'embercom/lib/reporting/flexible/custom-attributes';
import { isObject } from 'underscore';
import { tracked } from '@glimmer/tracking';
import { invert } from 'underscore';

//defines the query params configuration for a list of filters
export function filterQueryParams(filterTypes) {
  let queryParams = {};
  filterTypes.forEach((filterType) => (queryParams[filterType] = { refreshModel: true }));
  return queryParams;
}

function parseQueryParamValue(filterType, value) {
  switch (filterType) {
    case 'customAttributes':
      return new CustomAttributes(value);
    case 'source':
      if (value === 'any' || isEmpty(value)) {
        return [];
      } else {
        return value.split(',');
      }
    default:
      return isEmpty(value) ? [] : value.split(',');
  }
}

//sets properties on the route, based on the query params values
export function setRouteFilterProperties(route, queryParams, filterTypes) {
  //set generic filters
  let routeProperties = {};
  filterTypes.forEach((filterType) => {
    let { [filterType]: queryParamValue } = queryParams;
    routeProperties[filterType] = parseQueryParamValue(filterType, queryParamValue);
  });
  route.setProperties(routeProperties);

  //When navigating from other report the teammateId may be set to a team so we assign it to teamParticipated instead
  if (
    !isEmpty(route.teammateId) &&
    route.teammateId[0] !== REPORTING_FILTER_SELECT_ALL &&
    Admin.peekAndMaybeLoad(route.store, route.teammateId[0]).get('isTeam')
  ) {
    route.set('teamsParticipated', route.teammateId);
    route.set('teammateId', []);
  }

  //setup teamOrTeammate to the team members or to an individual teammate
  let teamOrTeammateId = route.teamsParticipated?.[0] || route.teammateId?.[0];
  if (teamOrTeammateId && teamOrTeammateId !== REPORTING_FILTER_SELECT_ALL) {
    let teamOrTeammateParticipated = Admin.peekAndMaybeLoad(route.store, teamOrTeammateId);
    route.set('teamOrTeammate', teamOrTeammateParticipated);
  }

  //setup tags
  if (route.scopingTagIds?.length) {
    route.set('tag', route.store.peekRecord('tag', route.scopingTagIds[0]));
  } else {
    route.set('tag', undefined);
  }
}

//defines the properties to be passed from the route to the controller
export function setControllerFilterProperties(route, controller, filterTypes) {
  let properties = {};
  filterTypes.forEach((filterType) => {
    properties[filterType] = route.get(filterType);
  });

  controller.setProperties(properties);
  controller.setProperties({
    filters: route.filters(),
    rawFilters: rawFilters(route, filterTypes),
    teamOrTeammate: route.teamOrTeammate,
  });
}

export function getFilterValues(filterType, filterProperty) {
  switch (filterType) {
    case 'customAttributes':
      return filterProperty?.toFilterValues();
    default:
      return filterProperty?.flat();
  }
}

function rawFilters(route, filters) {
  let parsedFilters = {};
  for (let filter of filters) {
    let filterProperty = route.get(filter);
    let filterValues = getFilterValues(filter, filterProperty);
    if (filterValues && Object.keys(filterValues).length && filterProperty) {
      parsedFilters[filter] = filterValues;
    }
  }
  return parsedFilters;
}

export function updateCombinedField({ parsedFilters, name, value }) {
  if (parsedFilters[name].type && parsedFilters[name].type === 'combined-same-filter-field') {
    parsedFilters[name].values.push(value);
    return parsedFilters;
  } else {
    return {
      type: 'combined-same-filter-field',
      values: [parsedFilters[name], value],
    };
  }
}

//fetches the values of each filter and translates them into the ES filter format
export function parsedESFilters(route, filterESMappings, options) {
  options = options || { supportSameFieldFilters: true };

  let parsedFilters = {};
  Object.entries(filterESMappings).forEach(([filterType, fieldES]) => {
    let filterProperty = route.get(filterType);
    let filterValues = getFilterValues(filterType, filterProperty);
    let hadAnyValue = filterValues && Object.keys(filterValues).length > 0;

    //For teams participated we must get a list of team members
    if (
      filterType === 'teamsParticipated' &&
      filterValues?.length &&
      filterValues[0] !== REPORTING_FILTER_SELECT_ALL
    ) {
      filterValues = filterValues
        .map((teamId) => Admin.peekAndMaybeLoad(route.store, teamId)?.get('member_ids'))
        .flat();
    }

    //TODO: remove this hack once we get rid of AppTeam
    if (
      ['teammateId', 'teamsParticipated'].includes(filterType) &&
      route.get('teamOrTeammate.isAppTeam')
    ) {
      filterValues = [];
    }

    if (filterValues && hadAnyValue && fieldES) {
      if (!isEmpty(parsedFilters[fieldES]) && options.supportSameFieldFilters) {
        parsedFilters[fieldES] = updateCombinedField({
          parsedFilters,
          name: fieldES,
          value: filterValues,
        });
      } else {
        parsedFilters[fieldES] = filterValues;
      }
    }
  });
  return parsedFilters;
}

function transformSelectAllFilters(filters, fieldES, categoryValues) {
  // Add the filter to exists_fields if it is a select all filter & doesn't already exist
  if ('exists_fields' in filters) {
    let fieldExists = filters['exists_fields'].find((filter) => filter === fieldES);
    if (!fieldExists) {
      filters['exists_fields'].push(fieldES);
    }
  } else {
    filters['exists_fields'] = [fieldES];
  }

  // If all of the values were SELECT_ALL, categoryValues is empty so we can remove the filter
  // Otherwise, just set the values for the filter as the category values
  if (isEmpty(categoryValues)) {
    delete filters[fieldES];
  } else {
    filters[fieldES] = categoryValues;
  }
  return filters;
}

export function parsedESFiltersForSignal(route, filterESMappings, options) {
  let parsedFilters = parsedESFilters(route, filterESMappings, options);
  Object.entries(parsedFilters).forEach(([fieldES, values]) => {
    let categoryValues;

    if (fieldES === 'custom_attributes') {
      categoryValues = values;
    } else if (values.type === 'combined-same-filter-field') {
      categoryValues = values.values.filter((value) => value !== REPORTING_FILTER_SELECT_ALL);
    } else {
      categoryValues = values.filter((value) => value !== REPORTING_FILTER_SELECT_ALL);
    }

    if (categoryValues.length !== values.length) {
      parsedFilters = transformSelectAllFilters(parsedFilters, fieldES, categoryValues);
    }
  });
  return parsedFilters;
}

function buildFilter(property, values) {
  if (values[0] === REPORTING_FILTER_SELECT_ALL) {
    return { type: 'exists', data: { property } };
  } else {
    return { type: 'category', data: { property, values } };
  }
}

function mergeFilters(property, values) {
  let categoryValues = values.values.filter((value) => value !== REPORTING_FILTER_SELECT_ALL);
  if (isEmpty(categoryValues)) {
    // If all values are SELECT_ALLs, we should just return an exists filter
    return [{ type: 'exists', data: { property } }];
  } else {
    return [
      {
        type: 'category',
        data: {
          property,
          values: categoryValues.flatMap((v) => parseInt(v, 10)),
        },
      },
    ];
  }
}

export function buildFilters(property, values, options = { mergeCombined: false }) {
  if (property === 'custom_attributes') {
    return _customAttributeFiltersInFlexibleFormat(values);
  } else if (isObject(values) && values.type === 'combined-same-filter-field') {
    if (options.mergeCombined) {
      return mergeFilters(property, values);
    } else {
      return values.values.map((value) => buildFilter(property, value));
    }
  } else {
    return [buildFilter(property, values)];
  }
}

export class FilterSet {
  @tracked teammatesAssigned = [];
  @tracked teamsAssigned = [];
  @tracked continents = [];
  @tracked countries = [];
  @tracked channels = [];
  @tracked startedByUser = [];
  @tracked teammatesParticipated = [];
  @tracked teamsParticipated = [];
  @tracked scopingTagIds = [];
  @tracked topics = [];
  @tracked customAttributes = new CustomAttributes();
}

export class TicketFilterSet {
  @tracked teammatesAssigned = [];
  @tracked teamsAssigned = [];
  @tracked continents = [];
  @tracked countries = [];
  @tracked ticket_type_ids = [];
}

const FILTER_KEY_TO_PROPERTY_MAPPINGS = {
  teammatesParticipated: 'admin_participant_ids',
  teamsParticipated: '$admin_participant_ids', // this is consistent with custom reports, see https://github.com/intercom/embercom/blob/1730a0eb85b717bb1781d413b65e4ae6fcde05b3/app/lib/reporting/custom/filter-helpers.js#L216
  scopingTagIds: 'conversation_tag_ids',
  teammatesAssigned: 'admin_assignee_id',
  channels: 'channel_type',
  continents: 'user_location.continent_code',
  countries: 'user_location.country_name',
  startedByUser: 'conversation_started_by_user',
  teamsAssigned: 'team_assignee_id',
  customAttributes: 'custom_attributes',
  topics: 'topic',
  ticket_type_ids: 'ticket_type_id',
};
const PROPERTY_TO_FILTER_KEY_MAPPINGS = invert(FILTER_KEY_TO_PROPERTY_MAPPINGS);

export function convertFilterPredicatesToFilterSet(filterPredicates, filterSet) {
  if (!filterPredicates || !filterPredicates.type) {
    return;
  }
  if (filterPredicates.type === 'and') {
    filterPredicates.filters.forEach((filter) =>
      convertFilterPredicatesToFilterSet(filter, filterSet),
    );
  } else if (filterPredicates.data instanceof Array) {
    filterSet.customAttributes = new CustomAttributes();
    let id = filterPredicates.data[0].values[0];
    let values =
      filterPredicates.type === 'exists' || filterPredicates.type === 'not_exists'
        ? []
        : filterPredicates.data[1].values;
    filterSet.customAttributes.updateValues(id, values, filterPredicates.type);
  } else {
    let filterType = PROPERTY_TO_FILTER_KEY_MAPPINGS[filterPredicates.data.property];
    filterSet[filterType] = filterPredicates.data.values;
  }
}

function filterIsPresent(filter) {
  return (
    filter.type === 'exists' || filter.type === 'not_exists' || filter?.data?.values.length > 0
  );
}

export function getFilterPredicatesFromFilterSet(filterSet) {
  // Convert filter fields to array following flexible reporting format
  let filters = Object.keys(Object.getPrototypeOf(filterSet))
    .flatMap((filterKey) => {
      let values = getFilterValues(filterKey, filterSet[filterKey]);
      let property = FILTER_KEY_TO_PROPERTY_MAPPINGS[filterKey];
      return buildFilters(property, values);
    })
    .filter((filter) => filterIsPresent(filter));

  if (filters.length === 0) {
    return {};
  } else if (filters.length === 1) {
    return filters[0];
  } else {
    return {
      type: 'and',
      filters,
    };
  }
}

function _customAttributeFiltersInFlexibleFormat(customAttributes) {
  return Object.entries(customAttributes).map(([id, { values, operator, type, field }]) => {
    switch (operator) {
      case 'category':
      case 'not_in_category':
        return {
          type: operator,
          data: { property: `${field}#${id}`, values },
        };
      case 'exists':
      case 'not_exists':
        return {
          type: operator,
          data: { property: `${field}#${id}` },
        };
    }
  });
}

function transformFiltersToFlexibleFormat(property, values) {
  if (property === 'custom_attributes') {
    return _customAttributeFiltersInFlexibleFormat(values);
  } else {
    return { data: { property, values }, type: 'category' };
  }
}

export function getFlexibleFiltersFromRoute(route, filterMappings) {
  let parsedFilters = parsedESFilters(route, filterMappings);
  let filters = Object.keys(parsedFilters).flatMap((filterKey) =>
    transformFiltersToFlexibleFormat(filterKey, parsedFilters[filterKey]),
  );

  return {
    type: 'and',
    filters: filters || [],
  };
}
