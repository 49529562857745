/* RESPONSIBLE TEAM: team-growth-opportunities */
import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

export default class SignupPriceFormatter extends Helper {
  @service intl;

  compute([amountInCent, isPrecisionZero, roundUp]) {
    if (isNaN(amountInCent)) {
      throw Error(`amountInCent to display is not a number: ${amountInCent}`);
    }

    let amount = amountInCent / 100;
    amount = roundUp ? Math.ceil(amount) : amount;
    amountInCent = amount * 100;

    return this.intl.formatNumber(amount, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: calculatePrecision(amountInCent, isPrecisionZero),
    });
  }
}

function calculatePrecision(amountInCent, isPrecisionZero) {
  if (isPrecisionZero) {
    return 0;
  }

  return amountInCent % 100 === 0 ? 0 : 2;
}
