/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import RESTAdapter from '@ember-data/adapter/rest';
import { computed } from '@ember/object';
import EmbercomBootDataModule from 'embercom/lib/embercom-boot-data';

export default RESTAdapter.extend({
  isEmbercom: computed(function () {
    return !EmbercomBootDataModule.isBootedInPublicPage();
  }),

  host: computed('isEmbercom', function () {
    // If we're booting in the normal mode (which we will be, unless its the public app store or meeting sch),
    // we should return undefined here. That will leave the default behavior of Ember Data to decide the host.
    // If we're booted in public_app_store mode, we should use the api_host from the boot data (if present).
    // Note: this may not always be present, so we return undefined (to use the default behavior) if not.
    let apiHostFromBootData = EmbercomBootDataModule.publicPageApiHost();
    if (!this.isEmbercom && apiHostFromBootData) {
      return apiHostFromBootData;
    }
    return undefined;
  }),

  namespace: EmbercomBootDataModule.getApiNamespace(),
});
