/* RESPONSIBLE TEAM: team-tickets-1 */
import Formatters, { units } from 'embercom/lib/reporting/flexible/formatters';

export default class BreakdownBuilder {
  static displayNames = {
    csat: {
      rating_index_total: '😀 or 🤩',
      rating_index_1: '😠',
      rating_index_2: '🙁',
      rating_index_3: '😐',
      rating_index_4: '😃',
      rating_index_5: '🤩',
    },
  };

  constructor({ buckets, metricType }) {
    this.metricType = metricType;
    this.buckets = buckets;
  }

  isPercentageBreakdown() {
    return ['avg_wait_time', 'avg_time_to_subsequent_response', 'csat'].includes(this.metricType);
  }

  withDisplayNames(buckets) {
    return buckets.map((bucket) => ({
      displayName: BreakdownBuilder.displayNames[this.metricType]?.[bucket.name] || bucket.name,
      ...bucket,
    }));
  }

  withDisplayValues(buckets) {
    return buckets.map((bucket) => ({
      displayValue: this.formattedValue(bucket),
      ...bucket,
    }));
  }

  formattedValue(bucket) {
    switch (this.metricType) {
      case 'avg_wait_time':
      case 'avg_time_to_subsequent_response':
        return bucket.name === 'All' ? this.formatTime(bucket.value) : bucket.value;
      case 'csat':
        return bucket.name === 'rating_index_total' ? `${Math.round(bucket.value)}%` : bucket.value;
      default:
        return bucket.value;
    }
  }

  getTimeFormatter() {
    if (!this.timeFormatter) {
      this.timeFormatter = new Formatters[units.seconds]();
    }
    return this.timeFormatter;
  }

  formatTime(value) {
    return value ? this.getTimeFormatter().formatCounter(value) : null;
  }

  withPercentages(buckets) {
    let total = buckets.reduce((sum, { value }) => sum + value, 0);

    return buckets.map((bucket) => ({
      percentage: (bucket.percentage = total === 0 ? 0 : Math.round((bucket.value / total) * 100)),
      ...bucket,
    }));
  }

  withBucketsFiltering(buckets) {
    // Excluding 🤩'and 😃 ratings from csat metric buckets
    return this.metricType === 'csat'
      ? buckets.filter(
          (bucket) => bucket.name !== 'rating_index_5' && bucket.name !== 'rating_index_4',
        )
      : buckets;
  }

  build() {
    let buckets = this.withDisplayNames(this.buckets);
    buckets = this.withDisplayValues(buckets);

    let isPercentageBreakdown = this.isPercentageBreakdown();
    if (isPercentageBreakdown) {
      // excluding first bucket from percentage calculation
      buckets = buckets.slice(0, 1).concat(this.withPercentages(buckets.slice(1)));
    }

    buckets = this.withBucketsFiltering(buckets);

    return {
      buckets,
      isPercentageBreakdown,
    };
  }
}
