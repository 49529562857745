/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { tracked } from '@glimmer/tracking';
import { getEmberDataStore } from 'embercom/lib/container-lookup';
import Admin from 'embercom/models/admin';

class Report {
  @tracked id;
  @tracked name;
  @tracked description;
  @tracked charts;
  @tracked updatedAt;
  @tracked createdById;

  constructor(id, name, description, charts, createdById, updatedAt) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.charts = charts;
    this.createdById = createdById;
    this.updatedAt = updatedAt;
  }

  get createdByAdmin() {
    if (this.createdById) {
      return Admin.peekAndMaybeLoad(getEmberDataStore(), this.createdById);
    } else {
      return null;
    }
  }

  get createdByAdminSortableName() {
    return this.createdByAdmin ? this.createdByAdmin.name : 'Intercom';
  }
}

const DEFAULT_REPORTS = [
  new Report(
    '1',
    'My Report',
    'A report for tracking useful metrics about our team',
    [],
    null,
    new Date(),
  ),
  new Report(
    '2',
    'Another Report',
    'A report for tracking useful metrics about our team',
    [],
    '1',
    new Date(2020, 7, 3, 9, 24, 0),
  ),
];

export function getReports() {
  return DEFAULT_REPORTS;
}

export function getReport(id) {
  return getReports().find((r) => r.id === id);
}

export function getChart(reportId, chartId) {
  return getReport(reportId).charts.find((c) => c.id === chartId);
}

export function addChart(reportId, chartConfig) {
  let charts = getReport(reportId).charts;
  let id = generateId(charts.map((c) => c.id));
  charts.pushObject({ id, config: chartConfig });
}

function generateId(existingIds) {
  let id = 0;

  do {
    id = Math.floor(Math.random() * 100) + 1;
  } while (existingIds.includes(id));

  return id.toString();
}

export function updateChart(reportId, chartId, config) {
  let report = getReport(reportId);
  report.charts = report.charts.map((c) => {
    if (c.id === chartId) {
      return { id: chartId, config };
    } else {
      return c;
    }
  });
}

export function removeChart(reportId, chartId, config) {
  let report = getReport(reportId);
  report.charts = report.charts.filter((c) => c.id !== chartId);
}
