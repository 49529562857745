/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import EmberRouter from '@ember/routing/router';
import config from './../../config/environment';
import EmbercomBootDataModule from 'embercom/lib/embercom-boot-data';
import { setSentryTagsFromTransition } from 'embercom/lib/sentry';
import { timeout } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import ENV from 'embercom/config/environment';

const IS_EMBERCOM = !EmbercomBootDataModule.isBootedInPublicPage();

export default class EmbercomRouter extends EmberRouter {
  rootURL = IS_EMBERCOM ? config.rootURL : EmbercomBootDataModule.publicPageRootUrl();
  location = config.locationType;

  @service appService;

  @task({ keepLatest: true }) *ping() {
    window.Intercom('update');

    yield timeout(ENV.APP._5000MS);
  }

  init() {
    super.init(...arguments);
    this.on('routeWillChange', (transition) => {
      setSentryTagsFromTransition(transition);
    });

    this.on('routeDidChange', (transition) => {
      setSentryTagsFromTransition(transition);
      if (transition && transition.from?.name !== transition.to?.name) {
        this.ping.perform();
      }
    });
  }

  static map() {
    if (IS_EMBERCOM) {
      super.map(...arguments);
    } else if (EmbercomBootDataModule.isBootedInPublicAppStoreMode()) {
      super.map(function () {
        this.route('appstore', { path: '/' }, function () {
          this.route('app-package', { path: 'apps/:id' });
          this.route('not-found', { path: '*path' });
        });
      });
    } else if (EmbercomBootDataModule.isBootedInMeetingScheduler()) {
      super.map(function () {
        this.route('meeting-scheduler', { path: ':id' });
        this.route('meeting-scheduler', { path: ':id/:duration' });
      });
    }
  }
}
