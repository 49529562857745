/* RESPONSIBLE TEAM: team-tickets-1 */
import Signal from 'embercom/models/reporting/signal';

const setupLineChartSignal = ([firstSignal, ...restSignals]) =>
  Signal.create({
    ...firstSignal,
    originalContext: [firstSignal, ...restSignals].map(({ originalContext = [], name }, key) => ({
      key,
      name,
      count: originalContext.length,
      value: originalContext,
    })),
  });

export default setupLineChartSignal;
