/* RESPONSIBLE TEAM: team-proactive-support */
import { helper } from '@ember/component/helper';

interface Args {
  elementId: string;
  exportFilename: string;
  exportAnalytic: Function;
  linkElement: HTMLElement | null;
}

export function tableToCSV([
  { elementId, exportFilename, exportAnalytic, linkElement = document.createElement('a') },
]: [Args]) {
  linkElement!.setAttribute(
    'href',
    `data:text/csv;charset=utf-8,${encodeURIComponent(_tableToCsvString(elementId))}`,
  );
  linkElement!.setAttribute('download', _exportFilename(exportFilename));
  linkElement!.onclick = () => exportAnalytic();
  document.body.appendChild(linkElement!);
  linkElement!.click();
  _clearAttributes(linkElement!);
  return linkElement;
}

function _clearAttributes(element: HTMLElement) {
  element.removeAttribute('href');
  element.removeAttribute('download');
  element.onclick = null;
}

function _exportFilename(exportFilename: string) {
  return `${exportFilename}_${new Date().toLocaleString().replace(/, /i, '_')}.csv`;
}

function _formatCsvCellValue(text: string, emptyCellText = ''): string {
  function _removeNewLines(text: string) {
    return text.replace(/(\r\n|\n|\r)/gm, '');
  }

  function _removeDoubleSpaces(text: string) {
    return text.replace(/(\s\s)/g, ' ');
  }

  function _escapeQuotes(text: string) {
    return text.replace(/"/g, '""');
  }

  function _clearEmptyCells(text: string) {
    return text.replace(/^[\u2010-\u2015]|-$/, emptyCellText);
  }

  return _clearEmptyCells(_escapeQuotes(_removeDoubleSpaces(_removeNewLines(text))));
}

function _cellToString(cell: HTMLElement) {
  let cellString = '';
  let multiCells = Array.from(cell.querySelectorAll(`.outbound__table__content-cell`));
  if (multiCells && multiCells.length > 1) {
    let formattedMultiCells = multiCells.map((cell: HTMLElement) => {
      return _formatCsvCellValue(cell.innerText, '-');
    });
    cellString = formattedMultiCells.join(' / ');
  } else {
    cellString = _formatCsvCellValue(cell.innerText);
  }
  return `"${cellString}"`;
}

function _tableToCsvString(elementId: string) {
  let separator = ',';
  let rows = Array.from(document.getElementById(elementId)!.querySelectorAll(`tr`));
  let stringRows = <string[]>[];
  for (let row of rows) {
    // eslint-disable-next-line @intercom/intercom/no-bare-strings
    let columnElements = Array.from(row.querySelectorAll(`td, th`));
    let columnsData = columnElements.map(_cellToString);
    stringRows.push(columnsData.join(separator));
  }
  return stringRows.join('\n');
}

export default helper(tableToCSV);
export const exportedForTesting = {
  _formatCsvCellValue,
  _cellToString,
};
