/* RESPONSIBLE TEAM: team-frontend-tech */
import { helper as buildHelper } from '@ember/component/helper';

export function lowercase(params: [string?]) {
  let text = params[0] || '';
  return text.toLowerCase();
}

let helper = buildHelper<{
  Args: {
    Positional: [string];
  };
  Return: string;
}>(lowercase);

export default helper;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    lowercase: typeof helper;
  }
}
