/* RESPONSIBLE TEAM: team-frontend-tech */
import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

export default class CaptureRenderStartFn extends Helper {
  @service frontendStatsService;

  compute(_params, { subject, ...options }) {
    return () => this.frontendStatsService.startInteractionTime(subject, options);
  }
}
