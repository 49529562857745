/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-observers */
import Helper from '@ember/component/helper';
import AdminObjectVisibility from 'embercom/components/mixins/admin-object-visibility';
import { inject as service } from '@ember/service';
import { observer } from '@ember/object';

export default class BaseVisibilityHelper extends Helper.extend(AdminObjectVisibility) {
  @service appService;

  get admin() {
    return this.appService.app.currentAdmin;
  }

  // this is one of those rare occasions when an observer is the right thing to use
  // https://www.emberjs.com/api/ember/3.4/classes/Helper/methods/recompute?anchor=recompute
  _onNewAttributeVisibilitySettings = observer({
    dependentKeys: ['admin.visible_tag_ids.[]', 'admin.visible_segment_ids.[]'],

    fn() {
      this.recompute();
    },

    sync: false,
  });
}
