/* RESPONSIBLE TEAM: team-reporting */
class SignalFlexibleResponseForGroup {
  group = {};

  constructor(group) {
    this.group = group;
  }

  getGroupValueByKey(groupKey, isGroupedByTime) {
    let keys = isGroupedByTime ? this.groupKeys.map((key) => parseInt(key, 10)) : this.groupKeys;
    let index = keys.indexOf(groupKey);
    if (index >= 0) {
      let value = this.groupValues.get(index);
      return {
        value,
        index,
        key: groupKey,
      };
    }
    return undefined;
  }

  get groupValues() {
    if (this.group) {
      return this.group.aggregations[0].values;
    }
    return [];
  }

  get groupKeys() {
    if (this.group) {
      return this.group.values;
    }
    return [];
  }
}

export default class SignalFlexibleResponse {
  response = {};

  constructor(signalFlexibleResponse) {
    this.response = signalFlexibleResponse;
  }

  forGroup(group) {
    return new SignalFlexibleResponseForGroup(group);
  }

  isSegmented() {
    return this.response.groups[0].values[0]?.groups;
  }

  isSingleResponse() {
    return this.response.aggregations?.length > 0 && this.response.groups?.length === 0;
  }

  get outerKeys() {
    return this.response.groups[0].values.map((group) => group.value);
  }

  get groups() {
    if (this.isSegmented()) {
      return this.response.groups[0].values;
    }
    return this.response.groups;
  }

  get nonSegmentedGroup() {
    return this.response.groups[0];
  }

  getSegmentedGroupFromOuter(outerGroup) {
    return outerGroup.groups[0];
  }

  getSegmentedGroupByKey(key) {
    let index = this.outerKeys.indexOf(key);
    if (index >= 0) {
      return this.groups.get(index);
    }
    return undefined;
  }

  getAggregationForSingleResult() {
    return this.response.aggregations[0].values[0];
  }

  getAggregationNameForSingleResult() {
    return this.response.aggregations[0].name;
  }
}
