/* RESPONSIBLE TEAM: team-reporting */
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';
import { setOwner } from '@ember/application';

export const DATE_WITHOUT_TIME = 'YYYY-MM-DD';
export const CSV_EMPTY_LINE = [[]];
const ISO_FORMAT_WITHOUT_TZ = 'YYYY-MM-DD HH:mm:ss';

export default class ExportHelper {
  @service intl;
  @service appService;

  constructor(owner) {
    setOwner(this, owner);
  }

  get timezone() {
    return this.appService.app.timezone;
  }

  csvHeaders(range) {
    let headers = [
      this.intl.t('reporting.custom-reports.chart.csv-headers.export-date'),
      this.intl.t('reporting.custom-reports.chart.csv-headers.timezone'),
      this.intl.t('reporting.custom-reports.chart.csv-headers.from'),
      this.intl.t('reporting.custom-reports.chart.csv-headers.to'),
    ];
    let now = this.dateFormatter(moment());
    let from = this.dateFormatter(range.startMoment);
    let to = this.dateFormatter(range.endMoment);
    return [headers, [now, this.timezone, from, to]];
  }

  dateFormatter(moment, format) {
    return moment.tz(this.timezone).format(format || ISO_FORMAT_WITHOUT_TZ);
  }
}
