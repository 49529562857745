/* RESPONSIBLE TEAM: team-phone */
import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

interface Signature {
  Args: {
    Positional: [string];
  };
  Return: boolean;
}

export default class PermissionsHierarchyHasFeatureFlagEnabled extends Helper<Signature> {
  @service declare permissionsHierarchyService: $TSFixMe;
  @service declare appService: $TSFixMe;

  compute([permissionKey]: [string]): boolean {
    let featureFlag =
      this.permissionsHierarchyService.permissionsHierarchy[permissionKey]['feature_flag'];

    return isEmpty(featureFlag) || this.appService.app.canUseFeature(featureFlag);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'permissions-hierarchy-has-feature-flag-enabled': typeof PermissionsHierarchyHasFeatureFlagEnabled;
  }
}
