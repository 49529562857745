/* RESPONSIBLE TEAM: team-messenger */
import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';

export default class TrackAnalyticsEvent extends Helper {
  @service MessengerSettingsService;

  compute(_positionalParams, namedParams) {
    return () => {
      this.MessengerSettingsService.trackAnalyticsEvent(namedParams);
    };
  }
}
