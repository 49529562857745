/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'embercom/services/intl';
import type OnboardingState from 'embercom/lib/inbox2/onboarding/state';
import { type StepId } from './steps';
import type Session from 'embercom/services/session';

export type KeyHandler = {
  code: string;
  display: string;
  handler?: (state: OnboardingState) => void;
};

export default abstract class OnboardingStep {
  @service declare intl: IntlService;
  @service declare session: Session;

  abstract id: StepId;
  abstract keys: KeyHandler[];

  // When a step is completed, we may want to display a final message before
  // transitioning to the next state.
  abstract hasTransitionState: boolean;

  @tracked activeKeyIdx = 0;
  @tracked isComplete = false;

  get heading() {
    let key = `inbox.onboarding.help-desk-steps.${this.id}`;
    if (this.isComplete) {
      key += '.completed';
    }

    return this.intl.t(`${key}.heading`);
  }

  get description() {
    let key = `inbox.onboarding.help-desk-steps.${this.id}`;
    if (this.isComplete) {
      key += '.completed';
    }

    if (!this.intl.exists(`${key}.description`)) {
      return '';
    }

    return this.intl.t(`${key}.description`);
  }

  get activeKey() {
    return this.keys[this.activeKeyIdx];
  }

  get isLastKey() {
    return this.activeKeyIdx === this.keys.length - 1;
  }

  handleKeys(state: OnboardingState, nextStep: () => unknown) {
    if (this.isComplete) {
      return;
    }

    if (this.activeKey.handler) {
      this.activeKey.handler(state);
    }

    if (this.isLastKey) {
      nextStep();
    } else {
      this.nextKey();
    }
  }

  enter(_: OnboardingState) {}

  complete() {
    this.isComplete = true;
  }

  handleAllKeys(state: OnboardingState) {
    this.keys.forEach((key) => {
      key.handler?.(state);
    });
  }

  protected nextKey() {
    if (this.activeKeyIdx < this.keys.length - 1) {
      this.activeKeyIdx += 1;
    }
  }
}
