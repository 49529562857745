/* RESPONSIBLE TEAM: team-reporting */
export const PERSON_IDENTIFIED_EVENT_TYPE = {
  'event.type': ['person_identified'],
};

export const GROUP_BY_USER_ROLE = {
  grouping: 'current.user.role',
  interval: 1,
};

export const USER_AND_LEAD_CREATED_EVENT_TYPE = {
  'event.type': ['lead_created', 'user_created'],
};

export const USER_CREATED_EVENT_TYPE = {
  'event.type': ['user_created'],
};

export const FILTER_STARTED_CONVERSATIONS = {
  exists_fields: ['current.conversation.started_at'],
};

export const NEW_CONVERSATIONS_EVENT_TYPE = {
  'event.type': ['conversation_created'],
};

export const GROUP_BY_USER_FIRST_CONVERSATION_MESSAGE_TYPE = {
  grouping: 'user.first_conversation.message_type',
  interval: 1,
  missing: 'other',
};

export const GROUP_BY_CONVERSATION_MESSAGE_TYPE = {
  grouping: 'conversation.message_type',
  interval: 1,
  missing: null,
};

export const GROUP_BY_CONVERSATION_MESSAGE_ID = {
  grouping: 'conversation.message_id',
  interval: 1,
  missing: null,
};

export const GROUP_BY_USER_ID = {
  grouping: 'user.id',
  interval: 1,
  missing: null,
};

export const FILTER_INBOUND_CONVERSATION = {
  'conversation.message_type': ['UserMessage', 'BotIntroMessage'],
};

export const FILTER_AUTO_MESSAGES_CONVERSATION = {
  'conversation.message_type': ['VisitorAutoMessage', 'AutoMessage', 'CampaignMessage'],
};

export const FILTER_OUTBOUND_CONVERSATION = {
  'conversation.message_type': [
    'TriggeredMessage',
    'BotAutoMessage',
    'VisitorAutoMessage',
    'AutoMessage',
    'CampaignMessage',
    'ManualMessage',
  ],
};

export const FILTER_CUSTOM_BOT_CONVERSATION = {
  'conversation.message_type': ['BotAutoMessage', 'TriggeredMessage'],
};

export const FILTER_CUSTOM_BOT_AND_AUTO_MESSAGES_CONVERSATION = {
  'conversation.message_type': [
    'BotAutoMessage',
    'TriggeredMessage',
    'VisitorAutoMessage',
    'AutoMessage',
    'CampaignMessage',
  ],
};
