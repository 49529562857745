/* RESPONSIBLE TEAM: team-product-guidance */
/* eslint-disable ember/no-classic-classes */
// @ts-check
import EmberObject from '@ember/object';

/**
 * @typedef {object} ScriptDataBaseType
 *
 * @property {boolean} hasTransitionalScript
 * @property {string | null} scriptKey
 * @property {string | null} transitionalScriptKey
 */
/** @type ScriptDataBaseType */
const ScriptDataBase = {
  hasTransitionalScript: false,
  scriptKey: null,
  transitionalScriptKey: null,
};

/** @typedef {InstanceType<typeof ScriptData>} ScriptDataInstanceType */
const ScriptData = EmberObject.extend(ScriptDataBase);

export default {
  welcomeOnboardAndEngage: ScriptData.create({
    scriptKey: 'welcome_onboard_and_engage',
  }),
  welcomeCaptureAndConvert: ScriptData.create({
    scriptKey: 'welcome_capture_and_convert',
  }),
  welcomeSupportAndRetain: ScriptData.create({
    scriptKey: 'welcome_support_and_retain',
  }),
  welcomeAll: ScriptData.create({
    scriptKey: 'welcome_all',
  }),
  buildOnTheBasics: ScriptData.create({
    hasTransitionalScript: true,
    scriptKey: 'build_on_the_basics',
    transitionalScriptKey: 'build_on_the_basics_transitional_script',
  }),
  allStepsFinished: ScriptData.create({
    hasTransitionalScript: true,
    scriptKey: 'all_steps_finished',
    transitionalScriptKey: 'all_steps_finished_transitional_script',
  }),
  testApp: ScriptData.create({
    scriptKey: 'test_app',
  }),
  masterIntercom: ScriptData.create({
    scriptKey: 'master_intercom',
  }),
  convertVisitorsToLeads: ScriptData.create({
    hasTransitionalScript: true,
    scriptKey: 'convert_visitors_to_leads',
    transitionalScriptKey: 'convert_visitors_to_leads_transitional_script',
  }),
  supportYourCustomers: ScriptData.create({
    scriptKey: 'support_your_customers',
  }),
};
