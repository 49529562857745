/* RESPONSIBLE TEAM: team-messenger */
import { get } from '@ember/object';

let QUERY_PARAMS_ES_MAPPINGS = {
  teamsParticipated: 'admin_participant_ids', // Reverse of https://github.com/intercom/embercom/blob/master/app/lib/reporting/flexible/filter-helpers.js#L189
};

export function reverseFilterMappings(controller) {
  let filterMappings = {};
  if (controller) {
    filterMappings = Object.keys(QUERY_PARAMS_ES_MAPPINGS).reduce((acc, filterName) => {
      let filter = get(controller, filterName);
      if (filter?.length) {
        acc[QUERY_PARAMS_ES_MAPPINGS[filterName]] = filter;
      }
      return acc;
    }, {});
  }
  return filterMappings;
}
