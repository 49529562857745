/* RESPONSIBLE TEAM: team-frontend-tech */

import ENV from 'embercom/config/environment';
import type Route from '@ember/routing/route';
import { getOwner } from '@ember/application';

class FakeController {}

type ReactRoute = Route & { reactEnabled(): boolean };
type ReactRouteConstuctor = new (...args: unknown[]) => ReactRoute;

export const ReactRoute = (superclass: ReactRouteConstuctor) =>
  class extends superclass {
    reactEnabled(): boolean {
      if (ENV.environment === 'test') {
        return false;
      }
      // @ts-expect-error
      if (getOwner(this).lookup('service:react').didBootFail) {
        return false;
      }
      let app = super.modelFor('apps.app') as $TSFixMe;
      if (!app?.enableReactIframe) {
        return false;
      }

      return super.reactEnabled();
    }

    buildRouteInfoMetadata() {
      return { react: this.reactEnabled() };
    }

    model(params: any, transition: any) {
      if (!this.reactEnabled()) {
        return super.model(params, transition);
      }
      return null;
    }

    setupController(controller: any, model: any, transition: any) {
      if (!this.reactEnabled()) {
        return super.setupController(controller, model, transition);
      }
    }

    renderTemplate() {
      let metadata = this.buildRouteInfoMetadata();

      if (metadata.react) {
        let controller = new FakeController();

        // @ts-expect-error - Private API
        super.render('react-route', { controller });
      } else {
        // @ts-expect-error - Private API
        return super.renderTemplate(...arguments);
      }
    }
  };
