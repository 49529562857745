/* RESPONSIBLE TEAM: team-frontend-tech */
import DefaultPredicateManager from 'embercom/lib/common/predicates/default-predicate-manager';
import { ternary } from '@intercom/pulse/lib/computed-properties';

export default DefaultPredicateManager.extend({
  goalComparison: ternary('predicate.isUserEventPredicate', 'triggered', 'increase'),
  initializeDefaultValue() {
    this.set('predicate.value', this.getDefaultValue());
  },

  getDefaultValue() {
    if (this.get('predicate.hasNoValue') && this.get('predicate.isValueBasedComparison')) {
      return '';
    } else {
      return null;
    }
  },
});
