/* RESPONSIBLE TEAM: team-frontend-tech */
import { helper as buildHelper } from '@ember/component/helper';
import fullRelativeTimeAgo from 'embercom/lib/full-relative-time-ago';

export default buildHelper(function (params, namedParams) {
  if (namedParams && namedParams.fallback && !params[0]) {
    return namedParams.fallback;
  }
  return fullRelativeTimeAgo(params[0]);
});
