/* RESPONSIBLE TEAM: team-frontend-tech */
import { reads } from '@ember/object/computed';
import DefaultPredicateManager from 'embercom/lib/common/predicates/default-predicate-manager';

export default DefaultPredicateManager.extend({
  isValid: reads('predicate.hasAttributeComparisonAndType'),
  defaultComparison: 'true',

  getDefaultValue() {
    return null;
  },
});
