/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { select, selectAll } from 'd3-selection';
import { renderText, renderExternalText } from 'embercom/lib/reporting/bubble-chart/text';
import { partition } from 'underscore';

const MIN_RADIUS_FOR_TEXT = 56 / 2;
const NON_VOLUME_LABEL_FONT_SIZE = 8;
const EXTERNAL_LABEL_MAX_WIDTH = 80;

const BUBBLE_CLASS = 'reporting__bubble-chart__bubble';
const INTERNAL_LABEL_CLASS = 'reporting__bubble-chart__label';
const EXTERNAL_LABEL_CLASS = 'reporting__bubble-chart__external-label';

export const RADIUS_WITHOUT_VOLUME = 5;

function addBubble(selection) {
  selection
    .append('circle')
    .attr('class', (d) =>
      d.accepted ? `${BUBBLE_CLASS} o__accepted` : `${BUBBLE_CLASS} o__suggested`,
    )
    .attr('r', 10)
    .attr('data-test-bubble-chart-bubble', (d) => (d.accepted ? 'accepted' : 'suggested'))
    .attr('data-test-bubble-chart-bubble-id', (d) => d.id);
}

function addLabel(selection) {
  // Label inside the bubble, used when the cluster size defines the bubble size (controlled by the user)
  selection
    .append('text')
    .attr('pointer-events', 'none')
    .attr('data-test-bubble-chart-bubble-label', (d) => (d.accepted ? 'accepted' : 'suggested'))
    .attr('class', (d) =>
      d.accepted ? `${INTERNAL_LABEL_CLASS} o__accepted` : `${INTERNAL_LABEL_CLASS} o__suggested`,
    )
    .style('font-size', '10px')
    .attr('dy', '.35em')
    .attr('text-anchor', 'middle');

  // Label outside of  the bubble, used when the cluster size is fixed, regardless of the cluster size (controlled by the user)
  let labelLineHeight = NON_VOLUME_LABEL_FONT_SIZE + 2;
  selection
    .append('text')
    .attr('pointer-events', 'none')
    .attr('data-test-bubble-chart-bubble-external-label', (d) =>
      d.accepted ? 'accepted' : 'suggested',
    )
    .attr('data-test-topic-id', (d) => d.id)
    .attr('class', EXTERNAL_LABEL_CLASS)
    .style('font-size', `${NON_VOLUME_LABEL_FONT_SIZE}px`)
    .attr('text-anchor', 'left')
    .attr('x', 0)
    .attr('y', RADIUS_WITHOUT_VOLUME)
    .attr('opacity', 0)
    .each(function (d) {
      renderExternalText(
        this,
        d.name,
        RADIUS_WITHOUT_VOLUME,
        labelLineHeight,
        EXTERNAL_LABEL_MAX_WIDTH,
      );
    });
}

function markOverlappingLabelsAsHidden(selection) {
  // Sets the hidden property to 'true' for each data node
  // that has an external label that overlaps with either another
  // label or a bubble. If two labels overlap we only hide one of them.

  // First we generate bounding boxes for all bubbles and labels
  let boundingBoxes = [];
  selection.selectAll(`.${BUBBLE_CLASS}, .${EXTERNAL_LABEL_CLASS}`).each(function (d) {
    let { x, y, width, height } = this.getBoundingClientRect();
    boundingBoxes.push({
      x,
      y,
      x2: x + width,
      y2: y + height,
      d,
      isLabel: this.classList.contains(EXTERNAL_LABEL_CLASS),
    });
  });

  // Next we find collisions between labels and bubbles, the label should
  // always be hidden in these cases. Then we find collisions with other
  // non-hidden labels.
  let [labelBoundingBoxes, bubbleBoundingBoxes] = partition(boundingBoxes, (bbox) => bbox.isLabel);
  markOverlappingBoxesAsHidden(labelBoundingBoxes, bubbleBoundingBoxes);

  let visibleLabelBoundingBoxes = labelBoundingBoxes.filter((bbox) => !bbox.d.hideExternalLabel);
  markOverlappingBoxesAsHidden(visibleLabelBoundingBoxes, visibleLabelBoundingBoxes);
}

function markOverlappingBoxesAsHidden(boxesToUpdate, boxesToCollide) {
  for (let box of boxesToUpdate) {
    for (let otherBox of boxesToCollide) {
      if (isVisible(otherBox) && otherBox.d.id !== box.d.id && boxesCollide(box, otherBox)) {
        box.d.hideExternalLabel = true;
        break;
      }
    }
  }
}

function isVisible(box) {
  return !box.isLabel || (box.isLabel && !box.d.hideExternalLabel);
}

// 2D box intersection check, each box is represented as {x, y, x2, y2, ...}
// where the box extends from <x,y> -> <x2,y2>
function boxesCollide(boxA, boxB) {
  return boxA.x < boxB.x2 && boxA.x2 > boxB.x && boxA.y < boxB.y2 && boxA.y2 > boxB.y;
}

export function renderNodes(data, element, updateTooltip, resetTooltip, appIdCode, satisfaction) {
  let textVisibility = function (d) {
    d.r >= MIN_RADIUS_FOR_TEXT ? 'visible' : 'hidden';
  };

  select(element)
    .select('g')
    .selectAll('.reporting__bubble-chart__node')
    .data(data, (d) => d.id)
    .join(
      (enter) =>
        enter
          .append('a')
          .attr('transform', (d) => `translate(${d.x},${d.y})`)
          .attr('opacity', 0)
          .attr('href', (d) => d.url)
          .attr('target', '_blank')
          .attr('rel', 'noopener noreferrer')
          .attr('class', 'reporting__bubble-chart__node')
          .attr('pointer-events', 'all')
          .attr('data-test-bubble-id', (d) => d.id)
          .attr('data-test-bubble-chart-node', (d) => (d.accepted ? 'accepted' : 'suggested'))
          .classed('cursor-default', (d) => !d.url)
          .on('click', (d) => d.onClick())
          .on('mouseover', function (d) {
            select(this).select('circle').style('opacity', 1);
            updateTooltip(d, this);
          })
          .on('mouseleave', function () {
            select(this).select('circle').style('opacity', null);
            selectAll('.reporting__bubble-chart__node').sort(function (a, b) {
              return b.currentCount - a.currentCount;
            });
            resetTooltip();
          })
          .call(addBubble)
          .call(addLabel),
      (update) => update,
      (exit) =>
        exit.transition('fade-out-removed-nodes').duration(500).style('opacity', 0).remove(),
    )
    .call((selection) => {
      // Update the label inside the bubble, its' visibility and text wrapping as
      // the bubble size may have changed
      selection
        .select(`.${INTERNAL_LABEL_CLASS}`)
        .attr('visibility', textVisibility)
        .each(function (d) {
          renderText(this, d.name, d.r);
        })
        .transition('fade-in-out-internal-labels')
        .duration(500)
        .style('opacity', (d) => d.internalLabelOpacity);
    })
    .call((selection) => {
      // Update the CSAT coloring if the satifaction is being shown.
      // The coloring is dependent on the data and may have changed
      // if nodes were added, updated, or removed
      if (satisfaction.showSatisfaction) {
        satisfaction.updateCsatColoring(selection, selection.selectAll(`.${EXTERNAL_LABEL_CLASS}`));
      }
    })
    .call((selection) =>
      selection
        .select(`.${BUBBLE_CLASS}`)
        .transition('resize-bubbles')
        .duration(800)
        .attr('r', (d) => d.r),
    )
    .call(async function (selection) {
      // Hide the external labels them since we don't know which ones will be visible.
      selection.select(`.${EXTERNAL_LABEL_CLASS}`).style('opacity', 0);

      // Move the nodes
      let bubblesTransition = selection
        .each((d) => (d.hideExternalLabel = false))
        .transition('move-and-fade-in-nodes')
        .duration(800)
        .attr('transform', (d) => `translate(${d.x},${d.y})`)
        .attr('opacity', 1)
        .selectAll('circle')
        .attr('opacity', (d) => d.opacity);

      try {
        await bubblesTransition.end();

        // Once bubbles have stopped moving, check for overlapping external labels
        markOverlappingLabelsAsHidden(selection);

        // And then fade them in
        selection
          .select(`.${EXTERNAL_LABEL_CLASS}`)
          .transition('fade-in-out-overlapping-labels')
          .duration(150)
          .style('opacity', (d) => (d.hideExternalLabel ? 0 : d.externalLabelOpacity));
      } catch (_e) {
        /*
        For some reason "end" raises with the value of the object

        The documentation https://github.com/d3/d3-transition#transition_end says
        "Returns a promise that resolves when every selected element finishes transitioning.
        If any element’s transition is cancelled or interrupted, the promise rejects."

        I think it's possible that we cancel the transition because we accidentally run it
        multiple times.
        */
      }
    });
}
