/* RESPONSIBLE TEAM: team-frontend-tech */
//from: https://github.com/shipshapecode/ember-math-helpers
//license: https://github.com/shipshapecode/ember-math-helpers/blob/master/LICENSE.md

import { helper } from '@ember/component/helper';

export function mod(params) {
  return params.reduce((a, b) => Number(a) % Number(b));
}

export default helper(mod);
