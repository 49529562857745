/* RESPONSIBLE TEAM: team-reporting */
import DefaultHighchartConfig from 'embercom/lib/reporting/flexible/default-highchart-config';
import Tooltip from 'embercom/lib/reporting/flexible/tooltip';
import { copy } from 'ember-copy';
import PALETTE from '@intercom/pulse/lib/palette';

const config = {
  title: '',
  credits: false,
  chart: {
    type: 'heatmap',
    marginTop: 20,
    marginBottom: 100,
    plotBorderWidth: 0,
  },
  colorAxis: {
    min: 0,
  },
  plotOptions: {
    series: {
      dataLabels: {
        enabled: true,
        style: {
          textOutline: 'none',
        },
      },
    },
  },

  legend: {
    align: 'center',
    layout: 'horizontal',
    verticalAlign: 'bottom',
    y: 20,
    symbolHeight: 25,
  },
  tooltip: new Tooltip(),
};

export default class FlexibleDefaultChartConfig extends DefaultHighchartConfig {
  constructor(timezone) {
    super();
    this.config = copy(config, true);
    this.setTimezone(timezone);
  }
  setXAxis(xAxis) {
    this.config.xAxis = xAxis;
  }

  setYAxis(yAxis) {
    this.config.yAxis = yAxis;
  }

  setColors(colors) {
    this.config.colors = colors;
    this.config.colorAxis = { ...this.config.colorAxis, ...colors[0] };
  }

  setTickPositioner(tickPositioner) {
    this.config.colorAxis.tickPositioner = tickPositioner;
  }

  setDataLabelFormatter(formatter) {
    this.config.plotOptions.series.dataLabels.formatter = formatter;
  }
  setLegendFormatter(formatter) {
    this.config.colorAxis.labels = {
      formatter,
    };
  }

  useDarkTooltip() {
    this.config.tooltip.backgroundColor = PALETTE.black;
    this.config.tooltip.borderColor = PALETTE.black;
    this.config.tooltip.style.color = PALETTE['gray-light'];
  }
}
