/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
// const INBOUND_OUTBOUND = {
//   name: 'Type',
//   key: 'type',
// multiple: false,
// tense: 'present',
// field: 'conversation.message_type',
//   options: [
//     { name: 'Inbound', value: 'UserMessage' },
//     {
//       name: 'Outbound',
//       value:
//         'AutoMessage',
//         'BotAutoMessage',
//         'ManualMessage',
//         'CampaignMessage',
//         'VisitorAutoMessage',
//       ],
//     },
//   ],
//   icon: 'inbox',
// };

const CONVERSATION_STATE = {
  name: 'Status',
  key: 'state',
  multiple: false,
  tense: 'present',
  field: 'current.conversation.state',
  options: [
    { name: 'Open', value: 'open' },
    { name: 'Closed', value: 'closed' },
    { name: 'Snoozed', value: 'snoozed' },
  ],
  icon: 'close-conversation',
};

const RATING = {
  name: 'Conversation rating',
  key: 'conversationRating',
  multiple: false,
  tense: 'present',
  field: 'current.conversation.rating',
  options: [
    { name: '🤩 Great', value: '5' },
    { name: '😃 Good', value: '4' },
    { name: '😐 Ok', value: '3' },
    { name: '🙁 Bad', value: '2' },
    { name: '😠 Terrible', value: '1' },
  ],
  icon: 'lwr-happy',
};

const MESSAGE_TYPE = {
  name: 'Conversation source',
  key: 'messageType',
  multiple: false,
  tense: 'present',
  field: 'conversation.message_type',
  options: [
    { name: 'Replied to ongoing message', value: 'AutoMessage' },
    { name: 'New incoming message', value: 'UserMessage' },
    { name: 'Reply to bot intro', value: 'BotIntroMessage' },
    { name: 'Reply to custom bot', value: 'BotAutoMessage' },
    { name: 'Reply to email', value: 'CampaignMessage' },
    { name: 'Replied to one-off message', value: 'ManualMessage' },
  ],
  icon: 'message',
};

export const CONVERSATION_STARTED_BY = {
  name: 'Started by',
  key: 'messageType',
  multiple: false,
  tense: 'none',
  field: 'conversation_started_by_user',
  options: [
    { name: 'Customer', value: ['true'] },
    {
      name: 'Reply to Bot/Message',
      value: ['false'],
    },
  ],
  icon: 'conversation',
};

const CHANNEL = {
  name: 'Channel',
  key: 'channel',
  multiple: false,
  tense: 'present',
  field: 'conversation.channel',
  options: [
    { name: 'Unknown', value: 'Unknown' },
    { name: 'Desktop', value: 'Desktop' },
    { name: 'Mobile', value: 'Mobile' },
    { name: 'Email', value: 'Email' },
    { name: 'Twitter', value: 'Twitter' },
    { name: 'Android App', value: 'Android App' },
    { name: 'iOS App', value: 'iOS App' },
    { name: 'Facebook Messenger', value: 'Facebook Messenger' },
    { name: 'Facebook', value: 'Facebook' },
    { name: 'SMS', value: 'SMS' },
  ],
  icon: 'computer',
};

// const HISTORIC_ROLE = {
//   name: 'Original user role',
//   key: 'originalUserRole',
//   multiple: false,
//   tense: 'present',
//   field: 'user.role',
//   options: [
//     { name: 'Lead', value: 'lead' },
//     { name: 'User', value: 'user' },
//     { name: 'Visitor', value: 'visitor' },
//   ],
//   icon: 'person',
// };

const CURRENT_ROLE = {
  name: 'Customer role',
  key: 'userRole',
  multiple: false,
  tense: 'present',
  field: 'current.user.role',
  options: [
    { name: 'Lead', value: 'lead' },
    { name: 'User', value: 'user' },
    { name: 'Visitor', value: 'visitor' },
  ],
  icon: 'person',
};

const HAS_ANSWER_BOT = {
  name: 'Included Resolution Bot?',
  key: 'hasAnswerBot',
  multiple: false,
  tense: 'present',
  field: 'current.conversation.has_answer_bot',
  options: [
    { name: 'True', value: 'true' },
    { name: 'False', value: 'false' },
  ],
  icon: 'command',
};

const HAS_CUSTOM_BOT = {
  name: 'Included Custom Bots?',
  key: 'hasCustomBot',
  multiple: false,
  tense: 'present',
  field: 'current.conversation.has_custom_bot',
  options: [
    { name: 'True', value: 'true' },
    { name: 'False', value: 'false' },
  ],
  icon: 'command',
};

export const CONVERSATION_TAG = (tags) => {
  let options = tags.map((tag) => ({ name: tag.name, value: tag.id }));
  return {
    name: 'Conversation tag',
    key: 'tags',
    multiple: false,
    tense: 'present',
    field: 'conversation_tag_ids',
    options,
    icon: 'tag',
  };
};

export const ORIGINAL_TEAMMATE_ID = (teammates) => ({
  name: 'First teammate assigned',
  key: 'originalTeammateId',
  multiple: false,
  tense: 'past',
  field: 'conversation.assignee_id',
  icon: 'owner',
  options: teammates.map((teammate) => ({ name: teammate.name, value: teammate.id })),
});

export const ORIGINAL_INBOX_ID = (teams) => ({
  name: 'First team assigned',
  key: 'originalInbox',
  multiple: false,
  tense: 'past',
  field: 'conversation.assignee_id',
  icon: 'inbox',
  options: teams.map((team) => ({ name: team.name, value: team.id })),
});

export const CURRENT_TEAMMATE_ID = (teammates) => ({
  name: 'Teammate currently assigned',
  key: 'currentTeammateId',
  multiple: false,
  tense: 'present',
  field: 'current.conversation.assignee_id',
  icon: 'owner',
  options: teammates.map((teammate) => ({ name: teammate.name, value: teammate.id })),
});

export const CURRENT_INBOX_ID = (teams) => ({
  name: 'Current team inbox',
  key: 'currentInboxId',
  multiple: false,
  tense: 'present',
  field: 'current.conversation.assignee_id',
  icon: 'inbox',
  options: teams.map((team) => ({ name: team.name, value: team.id })),
});

export const USER_TAG_IDS = (tags) => ({
  key: 'userTags',
  name: 'User tag',
  multiple: true,
  tense: 'present',
  field: 'user.manual_tag_ids',
  options: tags.map((tag) => ({ name: tag.name, value: tag.id })),
  icon: 'tag',
});

export const TEAMMATES_PARTICIPATED = (teammates) => ({
  name: 'Teammate',
  key: 'teammateParticipated',
  multiple: false,
  tense: 'present',
  field: 'admin_participant_ids',
  icon: 'person',
  options: teammates.map((teammate) => ({ name: teammate.name, value: teammate.id })),
});

export const TEAM_PARTICIPATED = (teams) => ({
  name: 'Team participated',
  key: 'teamParticipated',
  multiple: false,
  tense: 'present',
  field: 'admin_participant_ids',
  icon: 'multiple-people',
  options: teams.map((team) => ({ name: team.name, value: team.member_ids || [] })),
});

// const USER_EMAIL_DOMAIN = {
// multiple: false,
// tense: 'present',
// field: 'current.user.email_domain',
//   options: [],
// };

// const USER_COMPANY = {
// multiple: false,
// tense: 'present',
// field: 'current.user.company_id',
//   options: [],
// };

// const MESSAGE = {
// multiple: false,
// tense: 'present',
// field: 'conversation.message_id',
//   options: [],
// };

// const URL = {
// multiple: false,
// tense: 'present',
// field: 'conversation.url',
//   options: [],
// };

// const CLOSED_COUNT = {
// multiple: false,
// tense: 'present',
// field: 'current.conversation.closedCount',
//   options: [],
// };

export default [
  // INBOUND_OUTBOUND,
  MESSAGE_TYPE,
  RATING,
  // HISTORIC_ROLE,
  CURRENT_ROLE,
  CONVERSATION_STATE,
  HAS_ANSWER_BOT,
  HAS_CUSTOM_BOT,
  CHANNEL,
];
