/* RESPONSIBLE TEAM: team-frontend-tech */
import { helper as buildHelper } from '@ember/component/helper';
import dateAndTimeFormats from 'embercom/lib/date-and-time-formats';
import moment from 'moment-timezone';

let dateHelper = function (params, options) {
  return moment(params[0]).format(dateAndTimeFormats.date);
};

export default buildHelper(dateHelper);
