/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import { PEOPLE_REACHED_PLANS, SERVICES_PLANS } from '../billing';

export function isPeopleReachedPlan(planId: string) {
  return PEOPLE_REACHED_PLANS.includes(planId);
}

export function isServicesPlan(planId: string) {
  return SERVICES_PLANS.includes(planId);
}
