/* RESPONSIBLE TEAM: team-product-guidance */
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'embercom/services/intl';
import type OnboardingState from 'embercom/lib/inbox2/guide-library/state';
import { type StepId } from './steps';

export type ClickHandler = {
  keyCode?: string;
  selector: GLInboxOnboardingSelectors;
  handler?: (state: OnboardingState) => void;
};

export enum GLInboxOnboardingSelectors {
  CommandK = '.js-command-k-trigger',
  UseMacro = '[data-onboarding-command-k-option="1"]',
  InsertMacro = '[data-onboarding-macro-target]',
  AIAssist = '.js-ai-assist-trigger',
  unknown = 'unknown',
  makeFriendlyAIassist = '[data-onboarding-ai-assist-option="4"]',
  SendReply = '.js-send-trigger',
}

export default abstract class GLInboxOnboardingStep {
  @service declare intl: IntlService;

  abstract id: StepId;
  abstract click: ClickHandler;

  // When a step is completed, we may want to display a final message before
  // transitioning to the next state.
  abstract hasTransitionState: boolean;

  @tracked isComplete = false;

  get heading() {
    let key = `onboarding.guide-library.inbox-tour.steps.${this.id}`;
    if (this.isComplete) {
      key += '.completed';
    }

    return this.intl.t(`${key}.heading`, { htmlSafe: true });
  }

  get description() {
    let key = `onboarding.guide-library.inbox-tour.steps.${this.id}`;
    if (this.isComplete) {
      key += '.completed';
    }

    if (!this.intl.exists(`${key}.description`)) {
      return '';
    }

    return this.intl.t(`${key}.description`, { htmlSafe: true });
  }

  private handleClick(state: OnboardingState, nextStep: () => unknown) {
    if (this.isComplete) {
      return;
    }

    if (this.click.handler) {
      this.click.handler(state);
    }

    nextStep?.();
  }

  enter(_: OnboardingState) {}

  clickBinder(currentState: OnboardingState, nextStep: () => unknown) {
    let element: Element | null = document.querySelector(this.click.selector);

    if (!element) {
      return;
    }

    element.addEventListener('click', () => {
      this.handleClick(currentState, nextStep);
    });
  }

  complete() {
    this.isComplete = true;
  }

  handleKeys(state: OnboardingState, nextStep: () => unknown) {
    if (this.isComplete) {
      return;
    }

    if (this.click.handler) {
      this.click.handler(state);
    }

    nextStep();
  }
}
