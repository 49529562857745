/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
export default function readableProducts(products) {
  if (!products || products.length === 0) {
    return;
  }
  let productNames = products.map((product) => product.get('name'));
  if (productNames.length === 1) {
    return productNames[0];
  }
  if (productNames.length === 2) {
    return productNames.join(' and ');
  }
  let endOfSentence = [`and ${productNames[productNames.length - 1]}`];
  return productNames
    .slice(0, -1)
    .reduce((sentence, productName) => {
      sentence.unshift(productName);
      return sentence;
    }, endOfSentence)
    .join(', ');
}
