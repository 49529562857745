/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
// There is similiar file in intercom codebase that should be reflected 1:1 in terms of how it works.
// We use file in the ruby codebase to translate data config for sharing reports.
// You can find this file over here:
// https://github.com/intercom/intercom/blob/master/app/services/reporting_service/flexible/data_config_translator.rb

/* eslint-disable no-continue */
import { isEmpty } from 'underscore';
import moment from 'moment-timezone';
import { getApp } from 'embercom/lib/container-lookup';
import { isPresent } from '@ember/utils';
import { buildAxesForDataConfig } from 'embercom/lib/reporting/custom/data-config-builder-helpers';
import { clone, some, omit } from 'underscore';
import { buildFilters } from 'embercom/lib/reporting/flexible/filter-helpers';

export function convert(dataInputConfiguration, renderableChart) {
  let dataRequests = [];

  for (let row of dataInputConfiguration.rows) {
    let columns = [];

    // this means this row has its own set of columns
    if (dataInputConfiguration.columns[row.name]) {
      columns = dataInputConfiguration.columns[row.name];
    } else {
      columns = dataInputConfiguration.columns['common'];
    }

    for (let column of columns) {
      // skipping generating data requests for rows/columns combinations for rows that define their own front-end-only
      // row value calculations
      if (row.columnCustomFunctions) {
        let allFunctions = row.columnCustomFunctions;
        if (allFunctions.find((f) => f.columnName === column.name)) {
          continue;
        }
      }

      if (isExtendedColumn(column)) {
        for (let innerColumn of column.columns) {
          let dataRequest = buildDataRequest(row, innerColumn, renderableChart);
          dataRequests.push(dataRequest);
        }
      } else {
        let dataRequest = buildDataRequest(row, column, renderableChart);
        dataRequests.push(dataRequest);
      }
    }
  }

  return dataRequests;
}

export function updateDataConfig({ dataConfig, filters = {}, timeRange }) {
  let columnTypes = ['common'];
  let rowsWithIgnoreColumns = {};

  // if there's any rows with ignore filter requirements
  if (some(dataConfig.rows, (r) => r.ignoreFilters)) {
    rowsWithIgnoreColumns = dataConfig.rows
      .filter((r) => r.ignoreFilters)
      .reduce((acc, r) => {
        acc[r.name] = r.ignoreFilters;
        return acc;
      }, {});

    columnTypes = columnTypes.concat(Object.keys(rowsWithIgnoreColumns));
  }

  let updatedDataConfig = { ...dataConfig, columns: {} };

  // filling all the columns with filters taking into account all filter ignores from rows
  columnTypes.forEach((ct) => {
    updatedDataConfig.columns[ct] = dataConfig.columns.map((col) => {
      // shallow copy of original column data config
      let newCol = clone(col);

      // update time
      if (newCol.isPreviousPeriod) {
        newCol.time.start = timeRange.comparisonStartMoment.valueOf();
        newCol.time.end = timeRange.comparisonEndMoment.valueOf();
      } else {
        newCol.time.start = timeRange.startMoment.valueOf();
        newCol.time.end = timeRange.endMoment.valueOf();
      }

      // update filters
      let allFilters = [];

      allFilters = [
        ...getColumnFilterList(newCol.filter),
        ...getGlobalFilters(filters, newCol, rowsWithIgnoreColumns[ct]),
      ];

      allFilters = allFilters.filter((f) => isPresent(f));
      newCol.filter = transformFilterList(allFilters);

      return newCol;
    });
  });

  return updatedDataConfig;
}

function transformFilterList(list) {
  if (list.length === 0) {
    return;
  } else {
    return { type: 'and', filters: list };
  }
}

function getColumnFilterList(filter) {
  if (!filter) {
    return [];
  }

  return filter.filters || [filter];
}

function getGlobalFilters(globalFilters, col, rowIgnores) {
  // this means we have a row for which we need to exclude a filter from the global filters
  if (rowIgnores && rowIgnores.columns.includes(col.name)) {
    rowIgnores.filters.forEach((f) => {
      globalFilters = omit(globalFilters, f);
    });
  }

  let filterAlias = col.filterAlias;
  let filtersList = [];

  for (let [key, value] of Object.entries(globalFilters)) {
    if (value !== undefined) {
      if (filterAlias?.[key]) {
        key = filterAlias[key];
      }
      let filters = buildFilters(key, value, { mergeCombined: true });
      filtersList.push(...filters);
    }
  }
  return filtersList;
}

const ROW_PARSER_FUNCTIONS = {
  nominal: (row, col, renderableChart) => groupRowParser(renderableChart, row, col, 'term'),
  term: (row, col, renderableChart) => groupRowParser(renderableChart, row, col, 'term'),
  filter: filterRowParser,
  temporal: (row, col, renderableChart) => groupRowParser(renderableChart, row, col, 'time'),
  allRecords: (row, col, renderableChart) =>
    renderableChart?.segmentBy
      ? groupRowParser(
          renderableChart,
          row,
          col,
          renderableChart.isSegmentedByTime ? 'time' : 'term',
        )
      : allRecordsRowParser(row, col),
};

const TYPE_MAPPER = {
  term: 'term',
  nominal: 'term',
  temporal: 'time',
};

// Query periods > 2 weeks uses a lower precision threshold for cardinality aggregation
// to avoid creating excess load on the ES cluster
const HIGH_PRECISION_LIMIT = moment.duration(2, 'weeks');

const DYNAMIC_PRECISION_SOURCES = [
  'reporting_conversation_part',
  'teammate_reply',
  'consolidated_conversation_part',
];

const SERIES_PARSER_FUNCTIONS = {
  mean: genericSeriesParser,
  max: genericSeriesParser,
  min: genericSeriesParser,
  sum: genericSeriesParser,
  percentile: percentileSeriesParser,
  median: medianSeriesParser,
  count: countSeriesParser,
  cardinality: cardinalitySeriesParser,
  range: rangeSeriesParser,
  scripted: genericSeriesParser,
};

function isExtendedColumn(column) {
  return column.hasOwnProperty('columns');
}

function canSetPrecision(column) {
  return (
    column.aggregation &&
    column.aggregation.type === 'cardinality' &&
    DYNAMIC_PRECISION_SOURCES.includes(column.source)
  );
}

function buildDataRequest(row, column, renderableChart) {
  let app = getApp();
  if (app.teammateTableDynamicQueries) {
    if (canSetPrecision(column)) {
      addPrecisionThreshold(column);
    }
  }
  return ROW_PARSER_FUNCTIONS[row.type](row, column, renderableChart);
}

function groupRowParser(renderableChart, row, column, type) {
  let isSummaryRow = row.type === 'allRecords';
  let { xAxis, yAxis } = buildGroups(row, row.nestedGroupData, column, renderableChart);

  let groupData = isSummaryRow ? { ...yAxis.data } : { ...xAxis.data };

  if (column.groupAlias && column.groupAlias[xAxis.data.property]) {
    groupData.property = column.groupAlias[xAxis.data.property];
  }
  if (column.excludeMissingGroup) {
    groupData.exclude_missing_group = true;
  }

  let parsedRequest = {
    name: column.name,
    source: column.source,
    groups: [
      {
        type,
        data: { ...groupData },
        name: isSummaryRow ? 0 : row.name,
      },
    ],
    time: { ...column.time },
  };

  if (!isEmpty(column.filter)) {
    parsedRequest.filter = column.filter;
  }

  if (isSummaryRow) {
    parsedRequest.groups[0].aggregations = [
      {
        ...SERIES_PARSER_FUNCTIONS[column.aggregation.type](column.aggregation),
      },
    ];
  } else if (yAxis) {
    parsedRequest.groups[0].groups = [
      {
        type: TYPE_MAPPER[yAxis.type],
        data: { ...yAxis.data },
        aggregations: [
          {
            ...SERIES_PARSER_FUNCTIONS[column.aggregation.type](column.aggregation),
          },
        ],
      },
    ];
  } else {
    parsedRequest.groups[0].aggregations = [
      {
        ...SERIES_PARSER_FUNCTIONS[column.aggregation.type](column.aggregation),
      },
    ];
  }

  return parsedRequest;
}

function buildGroups(x, y, series, renderableChart) {
  let xAxis = x;
  let yAxis = y;
  if (isPresent(renderableChart)) {
    ({ xAxis, yAxis } = buildAxesForDataConfig(
      renderableChart,
      series.metric,
      series.applyLimitOnServer,
    ));
  }
  return { xAxis, yAxis };
}

function filterRowParser(row, column) {
  let parsedRequest = {
    name: column.name,
    source: column.source,
    filter: { ...mergeFilters(row.data, column.filter) },
    aggregations: [
      {
        name: row.name,
        ...SERIES_PARSER_FUNCTIONS[column.aggregation.type](column.aggregation),
      },
    ],
    time: { ...column.time },
  };

  return parsedRequest;
}

function allRecordsRowParser(row, column) {
  let parsedRequest = {
    name: column.name,
    source: column.source,
    aggregations: [
      {
        name: row.name,
        ...SERIES_PARSER_FUNCTIONS[column.aggregation.type](column.aggregation),
      },
    ],
    time: { ...column.time },
  };

  if (!isEmpty(column.filter)) {
    parsedRequest.filter = column.filter;
  }

  return parsedRequest;
}

function mergeFilters(globalFilters, filters) {
  // for now, filters are merged with AND boolean operator
  if (isEmpty(globalFilters)) {
    return filters;
  } else if (isEmpty(filters)) {
    return globalFilters;
  } else {
    return {
      type: 'and',
      filters: [globalFilters, filters],
    };
  }
}

function percentileSeriesParser(series) {
  return {
    type: 'percentile',
    data: {
      property: series.data.property,
      value: series.value,
    },
  };
}

function medianSeriesParser(series) {
  return {
    type: 'percentile',
    data: {
      property: series.data.property,
      value: 50,
    },
  };
}

function genericSeriesParser(series) {
  // mean, max, min, sum definitions are the same
  return {
    type: series.type,
    data: Object.assign(series.data, {}),
  };
}

function countSeriesParser(series) {
  return {
    type: 'count',
    data: {
      property: series.data.property,
    },
  };
}

function cardinalitySeriesParser(series) {
  let parsedData = {
    type: 'cardinality',
    data: {
      property: series.data.property,
    },
  };

  let app = getApp();
  if (app.teammateTableDynamicQueries) {
    parsedData.data.precision_threshold = series.precision_threshold;
  }

  return parsedData;
}

function rangeSeriesParser(series) {
  return {
    type: 'range',
    data: {
      property: series.data.property,
      ranges: series.data.ranges,
    },
  };
}

function addPrecisionThreshold(column) {
  if (column.time.end - column.time.start > HIGH_PRECISION_LIMIT) {
    column.aggregation.precision_threshold = 3000;
  }
}
