/* RESPONSIBLE TEAM: team-proactive-support */
import { helper as buildHelper } from '@ember/component/helper';
import toSentence from 'embercom/lib/to-sentence';

function formatArrayToSentence(params) {
  let items = params[0] || [];
  let conjunction = params[1] || 'and';
  return toSentence(items, conjunction);
}

export default buildHelper(formatArrayToSentence);
