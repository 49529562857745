/* RESPONSIBLE TEAM: team-frontend-tech */
export default (firstArray, secondArray) => {
  return firstArray.reduce((newArray, item) => {
    if (firstArray.includes(item) && secondArray.includes(item)) {
      newArray.push(item);
    }

    return newArray;
  }, []);
};
