/* RESPONSIBLE TEAM: team-channels */
import { isEmpty } from '@ember/utils';
//TODO: POC - Move to embercom-core
let emailRegex =
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

function _indexOfAtSymbol(email) {
  return email.lastIndexOf('@');
}

export function isValidEmail(email) {
  return isEmpty(email) ? false : emailRegex.test(email.toLowerCase());
}

export function getUserName(email) {
  if (isValidEmail(email)) {
    return email.substring(0, _indexOfAtSymbol(email)).toLowerCase();
  } else {
    return '';
  }
}

export function getDomainName(email) {
  if (isValidEmail(email)) {
    return email.substring(_indexOfAtSymbol(email) + 1).toLowerCase();
  } else {
    return '';
  }
}
