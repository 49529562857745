/* RESPONSIBLE TEAM: team-frontend-tech */
// compareVersion('1.2.3', '1.1')
// Only compares versions containing digits. DOES NOT work for versions like "1.12.3-rc1"

function _normalizeSingleValue(value) {
  return !value ? 0 : parseInt(value, 10);
}

// Normalize version into a format which is [major, minor, patch]
function _normalizeValues(version) {
  let normalizedVersion = [0, 0, 0];
  version.split('.').forEach((value, i) => (normalizedVersion[i] = _normalizeSingleValue(value)));
  return normalizedVersion;
}

export default function (versionToCheck, versionToTestAgainst) {
  let versionToCheckNormalized = _normalizeValues(versionToCheck);
  let versionToTestAgainstNormalized = _normalizeValues(versionToTestAgainst);

  for (let i = 0, length = 3; i < length; i++) {
    let isLastItem = i === length - 1;
    let currentCheckValue = versionToCheckNormalized[i];
    let currentTestAgainstValue = versionToTestAgainstNormalized[i];
    if (currentCheckValue === currentTestAgainstValue && isLastItem) {
      return 0;
    } else if (currentCheckValue !== currentTestAgainstValue) {
      return currentCheckValue < currentTestAgainstValue ? -1 : 1;
    }
    if (isLastItem) {
      return 0;
    }
  }
}
