/* RESPONSIBLE TEAM: team-help-desk-experience */

declare global {
  interface Window {
    chrome: {
      runtime: {
        sendMessage: (extensionId: string, message: any) => Promise<any>;
      };
    };
  }
}

export default async function fetchActivities() {
  if (window.chrome?.runtime) {
    return await window.chrome.runtime.sendMessage('ecnibmcjkfoebefddoggdhcbheafmakm', {
      type: 'getActivities',
    });
  }
}
