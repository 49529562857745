/* RESPONSIBLE TEAM: team-reporting */
import { tracked } from '@glimmer/tracking';

export default class ReportMode {
  @tracked isEditMode;
  @tracked isSidePanelOpen;

  constructor(isEditMode: boolean, isSidePanelOpen: boolean) {
    this.isEditMode = isEditMode;
    this.isSidePanelOpen = isSidePanelOpen;
  }
}
