/* RESPONSIBLE TEAM: team-messenger */
import { isNone } from '@ember/utils';
import storage from 'embercom/vendor/intercom/storage';

export default {
  // Visible for testing
  windowLocation() {
    return window.location.href;
  },

  isEnabled() {
    if (
      isNone(window.__embercom_boot_data) ||
      isNone(window.__embercom_boot_data.model_cache_fingerprints) ||
      window.__embercom_boot_data?.cache_disabled
    ) {
      return false;
    }
    return !this.windowLocation().includes('disablecache=true');
  },

  adminModelFingerprint() {
    if (this.isEnabled()) {
      return window.__embercom_boot_data?.model_cache_fingerprints?.admin;
    } else {
      return 'admin';
    }
  },

  appModelFingerprint() {
    if (this.isEnabled()) {
      return window.__embercom_boot_data?.model_cache_fingerprints?.app;
    } else {
      return 'app';
    }
  },

  isDisabled() {
    return !this.isEnabled();
  },

  shouldClearCache() {
    return this.windowLocation().includes('clearcache=true');
  },

  loggingEnabled() {
    return Boolean(storage.get('modelCacheLogging'));
  },
};
