/* RESPONSIBLE TEAM: team-customer-data-platform */
import TextNode from './text-node';
import EntityNode from './entity-node';
import { captureException } from 'embercom/lib/sentry';

class EventTokenizer {
  constructor(template, references) {
    if (typeof template !== 'string') {
      throw new Error('template needs to be string');
    }
    this.template = template;
    this.references = references;
    this.tokens = [];
    this.cursor = 0;
    this.state = 'text';
    this.buffer = '';
  }

  tokenize() {
    for (this.cursor = 0; this.cursor < this.template.length; this.cursor++) {
      this[this.state]();
    }
    if (this.buffer.length > 0) {
      this.tokens.push(new TextNode(this.buffer));
    }
    return this.tokens;
  }

  pushToken(token) {
    this.tokens.push(token);
    this.buffer = '';
  }

  addCurrentCharacterToBuffer() {
    this.buffer += this.currentChar();
  }

  createEntityNode() {
    if (this.references[this.buffer] === undefined) {
      console.error(
        `Could not find entity token with key '${this.buffer}' in event references ${JSON.stringify(
          this.references,
        )}`,
      );
      captureException(new Error('Could not find entity token in event references'), {
        fingerprint: ['entity', 'token', 'event', 'references'],
        extra: {
          key: this.buffer,
          template: this.template,
          references: JSON.stringify(this.references),
        },
      });

      return new EntityNode({ id: '', label: '', type: 'text', reference: this.buffer });
    }
    return new EntityNode({ ...this.references[this.buffer], reference: this.buffer });
  }

  text() {
    if (this.currentChar() === '[') {
      this.state = 'maybeEnteringEntity';
    } else {
      this.addCurrentCharacterToBuffer();
    }
  }

  maybeEnteringEntity() {
    if (this.currentChar() === '[') {
      this.state = 'inEntity';
      if (this.buffer.length > 0) {
        this.pushToken(new TextNode(this.buffer));
      }
    } else {
      this.buffer += '[';
      this.addCurrentCharacterToBuffer();
      this.state = 'text';
    }
  }

  inEntity() {
    if (this.currentChar() === ']') {
      this.state = 'maybeLeavingEntity';
    } else {
      this.addCurrentCharacterToBuffer();
    }
  }

  maybeLeavingEntity() {
    if (this.currentChar() === ']') {
      this.state = 'text';
      if (this.buffer.length > 0) {
        this.pushToken(this.createEntityNode());
      }
    } else {
      this.addCurrentCharacterToBuffer();
    }
  }

  currentChar() {
    return this.template[this.cursor];
  }
}

export default EventTokenizer;
