/* RESPONSIBLE TEAM: team-frontend-tech */
import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
/* eslint-disable ember/no-observers */
import { observer } from '@ember/object';
import type IntlService from 'embercom/services/intl';

export default class FormatAbbreviatedHelper extends Helper {
  @service declare intl: IntlService;

  // this is one of those rare occasions when an observer is the right thing to use
  // https://www.emberjs.com/api/ember/3.4/classes/Helper/methods/recompute?anchor=recompute
  _onNewLocale = observer('intl.primaryLocale', () => this.recompute());

  compute([date]: [Date]): string {
    let locale = this.intl.primaryLocale;
    let abbreviatedLocaleName = `${locale}-abbreviated-relative-time`;
    return moment(date).locale(abbreviatedLocaleName).fromNow();
  }
}
