/* RESPONSIBLE TEAM: team-frontend-tech */
import { PRODUCT_KEYS } from 'embercom/lib/billing';

// WARNING: Only use for URLs/Routes, otherwise use product IDs
//
// While most situations have access to an app model, some do not (invites).
// This mapping is to allow us to generate links based on product IDs when we
// do not have access to product models.
export default function productKeyFromId(id) {
  return PRODUCT_KEYS[id];
}
