/* RESPONSIBLE TEAM: team-help-desk-experience */
import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import { type HotkeysMap } from 'embercom/services/inbox-hotkeys';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import { type HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';

export default class HotkeyFor extends Helper<{
  Args: {
    Positional: [id?: `${HotkeyID}`];
  };
  Return: HotkeysMap[HotkeyID] | { display: string[] };
}> {
  @service declare inboxHotkeys: InboxHotkeys;

  compute([id]: [`${HotkeyID}`]) {
    return this.inboxHotkeys.hotkeysMap[id] ?? { display: [] };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'hotkey-for': typeof HotkeyFor;
  }
}
