/* RESPONSIBLE TEAM: team-reporting */
import { officeHoursField } from './office-hours-field';

export const CONVERSATIONS_VOLUME = () => ({
  name: 'default_query',
  debug_name: 'volume',
  document_type: 'events',
  range_field: 'conversation.created_at',
  aggregation_type: 'value_count',
  aggregation_field: 'conversation.id',
  filters: {
    'event.type': 'conversation_created',
    exists_fields: ['current.conversation.started_at'],
  },
});

export const RESPONSE_TIME = (withinOfficeHours) => ({
  name: 'default_query',
  debug_name: 'median_response_time',
  document_type: 'events',
  range_field: 'conversation.created_at',
  aggregation_type: 'percentiles',
  aggregation_field: officeHoursField('comment.response_time', withinOfficeHours),
  additional_aggregation_parameters: { percents: [50] },
  filters: {
    'event.type': 'conversation_teammate_reply',
    exists_fields: ['current.conversation.started_at'],
  },
  unit: 'minute',
});

export const FIRST_RESPONSE_TIME = (withinOfficeHours) => ({
  name: 'default_query',
  debug_name: 'first_response_time',
  document_type: 'events',
  range_field: 'conversation.created_at',
  aggregation_type: 'percentiles',
  aggregation_field: officeHoursField(
    'current.conversation.first_response_time',
    withinOfficeHours,
  ),
  additional_aggregation_parameters: { percents: [50] },
  filters: {
    'event.type': 'conversation_created',
    exists_fields: ['current.conversation.started_at'],
  },
  unit: 'minute',
});

export const TIME_TO_CLOSE = (withinOfficeHours) => ({
  name: 'default_query',
  debug_name: 'time_to_last_close',
  document_type: 'events',
  range_field: 'conversation.created_at',
  aggregation_type: 'percentiles',
  aggregation_field: officeHoursField('current.conversation.time_to_last_close', withinOfficeHours),
  additional_aggregation_parameters: { percents: [50] },
  filters: {
    'event.type': 'conversation_created',
    exists_fields: ['current.conversation.started_at'],
  },
  unit: 'minute',
});

export default {
  CONVERSATIONS_VOLUME,
  RESPONSE_TIME,
  FIRST_RESPONSE_TIME,
  TIME_TO_CLOSE,
};
