/* RESPONSIBLE TEAM: team-frontend-tech */
// navigate a transition's routeInfos list return all route params
export default function getTransitionParams(transition) {
  let params = {};

  transition.routeInfos.forEach((routeInfo) => {
    params[routeInfo.name] = routeInfo.params;
  });

  return params;
}
