/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-jquery */
import { helper as buildHelper } from '@ember/component/helper';
import { assert } from '@ember/debug';
import $ from 'jquery';
import { icInterfaceIcon } from '@intercom/pulse/helpers/ic-interface-icon';

let allowedOptionsHash = function (value, options) {
  let hash = {};
  if (typeof options !== 'undefined') {
    ['descending', 'sortOrder'].forEach(function (property) {
      hash[property] = options[property];
    });
  }
  return hash;
};

let iconName = function (optionsHash) {
  let descending = optionsHash['descending'] || optionsHash['sortOrder'] === 'descending';
  if (descending) {
    return 'thin-down-arrow';
  } else {
    return 'thin-up-arrow';
  }
};

export function sortIconPattern(params, options) {
  let defaultOptions = {};
  let value = params[0];
  let optionsHash = $.extend(defaultOptions, allowedOptionsHash(value, options));

  assert(
    'The sortOrder parameter, if set, can only be set to "ascending" or "descending".',
    (function () {
      let sortOrder = optionsHash['sortOrder'];
      return (
        typeof sortOrder === 'undefined' || ['ascending', 'descending'].indexOf(sortOrder) !== -1
      );
    })(),
  );
  assert(
    'The descending parameter, if set, must be either true or false.',
    (function () {
      let descending = optionsHash['descending'];
      return typeof descending === 'undefined' || typeof descending === 'boolean';
    })(),
  );
  assert(
    'You must provide either sortOrder or descending parameters.',
    (function () {
      return (
        typeof optionsHash['sortOrder'] !== 'undefined' ||
        typeof optionsHash['descending'] !== 'undefined'
      );
    })(),
  );
  assert(
    'You cannot provide both sortOrder and descending parameters.',
    (function () {
      return (
        typeof optionsHash['sortOrder'] === 'undefined' ||
        typeof optionsHash['descending'] === 'undefined'
      );
    })(),
  );

  return icInterfaceIcon([iconName(optionsHash)], optionsHash);
}

export default buildHelper(sortIconPattern);
