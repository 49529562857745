/* RESPONSIBLE TEAM: team-frontend-tech */
import { getOwner } from '@ember/application';
import ENV from 'embercom/config/environment';

export default function (target) {
  let owner = getOwner(target);

  let appRoute = owner.lookup('route:apps/app');
  let appId = appRoute?.get('app.id');

  if (appId) {
    return appId;
  }

  let sessionService = owner.lookup('service:session');

  if (sessionService?.isWorkspaceLoaded) {
    return sessionService.workspace.id;
  }

  // Needed for use with ember-data-factory-guy because when using mockFindRecord,
  // mockCreate etc before a call to `visit`, the app route won't have been set up yet
  if (ENV.environment === 'test') {
    return 'tx2p130c';
  }

  return undefined;
}
