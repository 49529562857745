/* RESPONSIBLE TEAM: team-frontend-tech */
export function moveElementInOrderedList(list, element, newIndex, orderPropertyName = 'order') {
  let siblings = list.without(element);
  siblings
    .sortBy(orderPropertyName)
    .forEach((sibling, index) =>
      sibling.set(orderPropertyName, index >= newIndex ? index + 1 : index),
    );
  element.set(orderPropertyName, newIndex);
}
