/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import inflector from 'embercom/lib/inflector';

export function messageStopSuccess() {
  return `Your message has been stopped`;
}

export function messageStopFailure() {
  return `We couldn't stop your message. Send us a message and we'll do our best to help out.`;
}

export function messageStopOptions(message) {
  let title = `Stop this manual message`;
  let confirmButtonText = `Stop the message`;
  let remainingSendCount = message.get('remainingSendCount');
  let userLabel = inflector(message.get('userTypeLabel'), remainingSendCount);
  let body = `If you stop the message now, ${remainingSendCount} ${userLabel} won't see it because they haven't used your app since you sent the message. This can't be undone.`;
  return { title, body, confirmButtonText };
}
