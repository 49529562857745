/* RESPONSIBLE TEAM: team-tickets-1 */
import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

export default class InboxCapacityHelper extends Helper {
  @service appService;

  compute([inbox, workspaceCapacity]) {
    if (
      this.appService.app.canSeeTeammateWorkloadPage &&
      inbox.admin.workload_management_settings?.assignment_limit
    ) {
      return inbox.admin.workload_management_settings.assignment_limit;
    } else {
      return workspaceCapacity;
    }
  }
}
