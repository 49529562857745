/* RESPONSIBLE TEAM: team-frontend-tech */
// A tempory replacement for the built-in `capitalize` helper
// while we await resolution of https://github.com/emberjs/ember.js/issues/17864

import { helper } from '@ember/component/helper';
import simpleCapitalize from 'embercom/lib/simple-capitalize';

export default helper(function ([text]) {
  return simpleCapitalize(text);
});
