/* RESPONSIBLE TEAM: team-channels */
import Helper from '@ember/component/helper';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { type StateChange } from 'embercom/models/data/inbox/state-change-classes';
import { StateChangeType } from 'embercom/models/data/inbox/state-change-enums';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type ChannelSwitched from 'embercom/objects/inbox/renderable/channel-switched';

const IconMap: Partial<Record<RenderableType, string>> = {
  [RenderableType.Assignment]: 'assignment',
  [RenderableType.QuickReplyOptions]: 'chat-bubble',
  [RenderableType.AttributeCollector]: 'info',
  [RenderableType.AttributeCollected]: 'reply',
  [RenderableType.ConversationAttributeUpdated]: 'transfer',
  [RenderableType.TitleChanged]: 'edit',
  [RenderableType.ConversationSlaAppliedByWorkflow]: 'sla',
  [RenderableType.ConversationSlaAppliedByRule]: 'sla',
  [RenderableType.ConversationSlaRemoved]: 'sla',
  [RenderableType.PriorityChangedByWorkflow]: 'star',
  [RenderableType.PriorityChanged]: 'star',
  [RenderableType.PriorityChangedByRule]: 'star',
  [RenderableType.ConversationSlaTargetMissed]: 'sla',
  [RenderableType.ParticipantAdded]: 'add-participant',
  [RenderableType.ParticipantRemoved]: 'remove-participant',
  [RenderableType.ParticipantBlocked]: 'spam',
  [RenderableType.UnengagedInboundBotIntroduction]: 'chat-bubble',
  [RenderableType.ConversationRatingChanged]: 'ab-test',
  [RenderableType.ConversationRatingRemarkAdded]: 'ab-test',
  [RenderableType.AttachmentsDropped]: 'attachment',
  [RenderableType.PhoneCall]: 'phone',
};

export default class LookupPartIcon extends Helper {
  compute(params: [RenderablePart]) {
    let [part] = params;

    if (part.renderableData.renderableType === RenderableType.ChannelSwitched) {
      let channelSwitched = part.renderableData as ChannelSwitched;

      switch (channelSwitched.channel) {
        case 'phone_switch':
          return 'phone';
        default:
          return 'whatsapp';
      }
    }

    if (part.renderableData.renderableType === RenderableType.StateChange) {
      let stateChange = part.renderableData as StateChange;

      switch (stateChange.change) {
        case StateChangeType.Closed:
          return 'check';
        case StateChangeType.NotPriority:
          return 'priority';
        case StateChangeType.Opened:
          return 'reopen-conversation';
        case StateChangeType.Snoozed:
          return 'clock-15';
        case StateChangeType.Unsnoozed:
          return 'clock-15';
      }
    }

    return IconMap[part.renderableType];
  }
}
