/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
export function variationActivateSuccess(variation) {
  return `Version ${variation.get('label')} of your message is now live.`;
}

export function variationActivateFailure(variation) {
  return `We couldn't set Version ${variation.get(
    'label',
  )} live. Send us a message and we'll do our best to help out.`;
}

export function variationActivateOptions(variation) {
  return {
    title: `Set version live`,
    body: `You are about to set Version ${variation.get('label')} live.`,
    confirmButtonText: `Set Version ${variation.get('label')} live`,
  };
}
