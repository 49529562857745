/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { timeout } from 'ember-concurrency';
import { isObject } from 'underscore';
import ENV from 'embercom/config/environment';
import { requestHeaders } from 'embercom/lib/ajax';
import { buildWaiter } from '@ember/test-waiters';
import { lookupFixture } from 'embercom/vendor/ic-ajax';

let waiter = buildWaiter('request-waiter');

// priority isn't supported in the RequestInit type yet
// https://github.com/microsoft/TypeScript/issues/54472
export interface RequestInitWithPriority extends RequestInit {
  priority?: 'high' | 'low' | 'auto';
}

export class ResponseError extends Error {
  response: Response;

  constructor(response: Response) {
    super(`Failed request to ${response.url} with status ${response.status}`);
    this.response = response;
  }
}

let numRequestsInFlight = 0;
export function getNumRequestsInFlight() {
  return numRequestsInFlight;
}

export async function putRequest(
  info: RequestInfo | URL,
  body: object | undefined = undefined,
  init: RequestInitWithPriority = {},
) {
  init.method = 'PUT';
  if (body) {
    init.body = JSON.stringify(body);
  }
  return request(info, init);
}

export async function postRequest(
  info: RequestInfo | URL,
  body: object | undefined = undefined,
  init: RequestInitWithPriority = {},
) {
  init.method = 'POST';
  if (body) {
    init.body = JSON.stringify(body);
  }
  return request(info, init);
}

export async function deleteRequest(
  info: RequestInfo | URL,
  body: object | undefined = undefined,
  init: RequestInitWithPriority = {},
) {
  init.method = 'DELETE';
  if (body) {
    init.body = JSON.stringify(body);
  }
  return request(info, init);
}

export async function request(info: RequestInfo | URL, init: RequestInitWithPriority = {}) {
  // todo: fix this generally
  if (ENV.environment === 'test') {
    let fixture: any;
    if (typeof info === 'string' || info instanceof URL) {
      fixture = lookupFixture(info);
    } else {
      fixture = lookupFixture(info.url);
    }

    if (fixture) {
      return fixture;
    }
  }

  init.cache = 'no-store';
  if (!init.headers) {
    init.headers = {
      ...requestHeaders(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }

  let response;
  let token = waiter.beginAsync();

  try {
    numRequestsInFlight += 1;
    response = await fetch(info, init);
  } finally {
    waiter.endAsync(token);
    numRequestsInFlight -= 1;
  }

  if (!response.ok) {
    throw new ResponseError(response);
  }

  return response;
}

export function buildParams(appId: string, ...args: object[]): URLSearchParams {
  let params = new URLSearchParams({ app_id: appId });
  args.forEach((options) => appendToParams(params, options));
  return params;
}

function appendToParams(params: URLSearchParams, options: object): void {
  let key: any;
  let value: any;
  for ([key, value] of Object.entries(options)) {
    if (isObject(value) && !Array.isArray(value)) {
      Object.keys(value).forEach((item: any) => {
        params.append(`${key}[${item}]`, value[item]?.toString());
      });
    } else if (Array.isArray(value)) {
      value.forEach((item: any) => {
        params.append(`${key}[]`, item?.toString());
      });
    } else if (value !== undefined) {
      params.append(key, value.toString());
    }
  }
}

function isFailedToFetchError(err: unknown) {
  return err instanceof TypeError && err.message === 'Failed to fetch';
}

function isRetryableStatusCode(err: unknown) {
  return (
    err instanceof ResponseError &&
    [408, 429, 500, 502, 503, 504, 599].includes(err.response.status)
  );
}

export async function requestWithRetries(
  promise: () => Promise<unknown>,
  {
    retries,
    backoffDelay,
    maxBackoffDelay,
    onFailedRequest,
    signal,
  }: {
    retries: number;
    backoffDelay: number;
    maxBackoffDelay: number;
    onFailedRequest: undefined | (() => void);
    signal?: AbortSignal;
  },
) {
  if (ENV.environment === 'test' && retries === Infinity) {
    // don't infinite loop in tests
    retries = 5;
  }
  let failedCount = 0;

  //eslint-disable-next-line no-constant-condition
  while (true) {
    try {
      if (signal?.aborted) {
        return;
      }

      return await promise();
    } catch (err) {
      onFailedRequest?.();
      if (!(isRetryableStatusCode(err) || isFailedToFetchError(err))) {
        throw err;
      }

      failedCount += 1;

      if (failedCount > retries) {
        throw err;
      }

      if (ENV.environment !== 'test') {
        // don't sleep in tests
        await timeout(Math.min(failedCount * backoffDelay, maxBackoffDelay));
      }
    }
  }
}
