/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import moment from 'moment-timezone';

let currentLocale = moment.locale();

moment.locale('en-abbreviated-relative-time', {
  relativeTime: {
    future: '%s',
    past: '%s',
    s: 'Now',
    ss: '%ds',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1mth',
    MM: '%dmth',
    y: '1y',
    yy: '%dy',
  },
});

moment.locale('ga-abbreviated-relative-time', {
  relativeTime: {
    future: '%s',
    past: '%s',
    s: 'Anois',
    ss: '%ds',
    m: '1nó',
    mm: '%dnó',
    h: '1u',
    hh: '%du',
    d: '1lá',
    dd: '%dlá',
    M: '1mhí',
    MM: '%dmhí',
    y: '1b',
    yy: '%db',
  },
});

moment.locale('en-abbreviated-relative-time-ago', {
  relativeTime: {
    future: '%s',
    past: '%s',
    s: 'Just now',
    ss: '%ds ago',
    m: '1m ago',
    mm: '%dm ago',
    h: '1h ago',
    hh: '%dh ago',
    d: '1d ago',
    dd: '%dd ago',
    M: '1mth ago',
    MM: '%dmth ago',
    y: '1y ago',
    yy: '%dy ago',
  },
});

moment.locale('en-full-relative-time', {
  relativeTime: {
    future: '%s',
    past: '%s',
    s: 'Just now',
    ss: '%d seconds',
    m: '1 minute',
    mm: '%d minutes',
    h: '1 hour',
    hh: '%d hours',
    d: '1 day',
    dd: '%d days',
    M: '1 month',
    MM: '%d months',
    y: '1 year',
    yy: '%d years',
  },
});

moment.locale('en-full-relative-time-ago', {
  relativeTime: {
    future: '%s',
    past: '%s',
    s: 'Just now',
    ss: '%d seconds ago',
    m: '1 minute ago',
    mm: '%d minutes ago',
    h: '1 hour ago',
    hh: '%d hours ago',
    d: '1 day ago',
    dd: '%d days ago',
    M: '1 month ago',
    MM: '%d months ago',
    y: '1 year ago',
    yy: '%d years ago',
  },
});

moment.locale('en-bracketed-relative-time-ago', {
  relativeTime: {
    future: '%s',
    past: '%s',
    s: 'Just now',
    m: 'Just now',
    mm: (minuteValue) => {
      if (minuteValue < 5) {
        return 'Just now';
      }
      if (minuteValue < 10) {
        return '5m ago';
      }
      if (minuteValue < 15) {
        return '10m ago';
      }
      if (minuteValue < 30) {
        return '15m ago';
      }
      if (minuteValue < 60) {
        return '30m ago';
      }
    },
    h: '1h ago',
    hh: '%dh ago',
    d: '1d ago',
    dd: '%dd ago',
    M: '1m ago',
    MM: '%dm ago',
    y: '1y ago',
    yy: '%dy ago',
  },
});

moment.locale(currentLocale);

let relativeTime = function (dateString, localeName, clampToPresent = true) {
  let dateMoment = moment(dateString);
  dateMoment = clampToPresent ? moment.min(dateMoment, moment()) : dateMoment;
  return dateMoment.locale(localeName).fromNow();
};

export default relativeTime;
