/* RESPONSIBLE TEAM: team-frontend-tech */
import { computed } from '@ember/object';
import getBlocksInfo from 'embercom/lib/composer/get-blocks-info';

export default {
  hasSignificantBlocks(blocksKey, significantChars = 60) {
    return computed(blocksKey, function () {
      let blocks = this.get(blocksKey);
      if (!blocks) {
        return false;
      }

      let blocksInfo = getBlocksInfo(blocks);
      if (blocksInfo.characterCount > significantChars) {
        return true;
      }
      return (
        blocksInfo.hasImage || blocksInfo.hasVideo || blocksInfo.hasButton || blocksInfo.hasHtml
      );
    });
  },
};
