/* RESPONSIBLE TEAM: team-help-desk-experience */
import Helper from '@ember/component/helper';
import { Channel } from 'embercom/models/data/inbox/channels';

const IconMap: Partial<Record<Channel, string>> = {
  [Channel.Desktop]: 'messenger',
  [Channel.Email]: 'email',
  [Channel.Twitter]: 'twitter',
  [Channel.Facebook]: 'facebook',
  [Channel.FacebookMessenger]: 'facebook',
  [Channel.Whatsapp]: 'whatsapp',
  [Channel.Instagram]: 'instagram',
  [Channel.SMS]: 'sms',
  [Channel.IOS]: 'ios',
  [Channel.Android]: 'android',
};

export interface Signature {
  Args: {
    Positional: [Channel];
  };
  Return: string | undefined;
}

export default class LookupIb2ChannelIcon extends Helper<Signature> {
  compute(params: [Channel]): string | undefined {
    let [channel] = params;

    let iconName = IconMap[channel];
    if (iconName) {
      return `inbox2/channels/${iconName}`;
    }

    return undefined;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'lookup-ib2-channel-icon': typeof LookupIb2ChannelIcon;
  }
}
