/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { BaseConfig } from '@intercom/embercom-prosemirror-composer';

export default class PromptComposerConfig extends BaseConfig {
  placeholder = 'Write a prompt';
  autoFocus = true;
  tools = [];
  allowedBlocks = ['paragraph'];
  allowedInline = [];
  inputRules = [];
  singleBlockMode = true;
}
