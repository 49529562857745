/* RESPONSIBLE TEAM: team-proactive-support */
import { helper } from '@ember/component/helper';
import { pluralize } from 'ember-inflector';
import {
  humanReadableObjectNamesKeys,
  humanReadableObjectNames,
} from 'embercom/models/data/matching-system/matching-constants';

export function pluralHumanReadableObjectName(contentTypeId, intl) {
  if (intl) {
    return intl.t(humanReadableObjectNamesKeys[contentTypeId], { count: 2 });
  } else {
    return pluralize(2, humanReadableObjectNames[contentTypeId], { withoutCount: true });
  }
}

export default helper(pluralHumanReadableObjectName);
