/* RESPONSIBLE TEAM: team-frontend-tech */
import { getOwner } from '@ember/application';
import { get } from '@ember/object';
import Helper from '@ember/component/helper';
import type ApplicationInstance from '@ember/application/instance';
import type CopilotApi from 'embercom/services/copilot-api';
import type Session from 'embercom/services/session';
import type SidebarManager from 'embercom/services/sidebar-manager';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';

type ServiceMapping = {
  copilotApi: CopilotApi;
  session: Session;
  sidebarManager: SidebarManager;
  inboxSidebarService: InboxSidebarService;
};

type ServiceNames = keyof ServiceMapping;

interface Signature<T extends string> {
  Args: {
    Positional: [T];
  };
  Return: T extends ServiceNames ? ServiceMapping[T] : any;
}

export default class ServiceHelper<T extends string> extends Helper<Signature<T>> {
  compute([servicePath]: [T]) {
    let owner = getOwner(this) as ApplicationInstance;

    let parts = servicePath.split('.');
    let serviceName = parts.shift();
    let service = owner.lookup(`service:${serviceName}`);

    if (parts.length > 0) {
      let path = parts.join('.');
      return get(service, path);
    } else {
      return service;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    service: typeof ServiceHelper;
  }
}
