/* RESPONSIBLE TEAM: team-proactive-support */
export const DEFAULT_BODY_BACKGROUND = '#f3f3f3';
export const DEFAULT_CONTAINER_BACKGROUND = '#ffffff';
export const DEFAULT_TEMPLATE_FONT_COLOR = '#222222';
export const DEFAULT_H1_FONT_SIZE = 32;
export const DEFAULT_H2_FONT_SIZE = 25;
export const DEFAULT_PARAGRAPH_FONT_SIZE = 16;
export const DEFAULT_SMALL_FONT_SIZE = 12;
export const DEFAULT_BODY_BACKGROUND_RGB = 'rgb(243, 243, 243)';
export const DEFAULT_CONTAINER_BACKGROUND_RGB = 'rgb(255, 255, 255)';
export const DEFAULT_TEMPLATE_FONT_COLOR_RBG = 'rgb(34, 34, 34)';
export const DEFAULT_LINK_FONT_COLOR = '#2199e8';
