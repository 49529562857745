/* RESPONSIBLE TEAM: team-product-guidance */
import { getOwner, setOwner } from '@ember/application';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import { generateConversations } from 'embercom/lib/inbox2/guide-library/conversations';
import type Conversation from 'embercom/objects/inbox/conversation';
import AdminComment from 'embercom/objects/inbox/renderable/admin-comment';
import type Session from 'embercom/services/session';
import { OnboardingType } from 'embercom/lib/inbox2/onboarding/steps';
import TeamSummary from 'embercom/objects/inbox/team-summary';
import type IntlService from 'embercom/services/intl';
import type ApplicationInstance from '@ember/application/instance';
import { isEmpty } from '@ember/utils';
import { MacroAction } from 'embercom/objects/inbox/macro';
/* eslint-disable @intercom/intercom/no-bare-strings */

type CommandKAction = 'gifs' | 'jump-to-inbox' | 'use-macro';

export default class OnboardingState {
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare intercomEventService: any;

  readonly type: OnboardingType;

  @tracked conversations: Conversation[];
  @tracked closedConversations: Conversation[] = [];

  @tracked activeView = 'List';
  @tracked focusedSections: string[] = [];
  @tracked highlightedAction?: string;
  @tracked isInboxListVisible = false;
  @tracked isRightSideBarVisible = true;
  @tracked isPreviewPanelVisible = false;
  @tracked activeConversationId = 1;
  @tracked composerBlocks = new BlocksDocument([]);
  @tracked isComposerFocused = false;
  @tracked isCommandKVisible = false;
  @tracked isAIAssistVisible = false;
  @tracked isUseMacrosVisible = false;
  @tracked commandKFocusedActionIdx = 0;
  @tracked aiAssistFocusedActionIdx = 0;
  @tracked commandKActiveAction?: CommandKAction;
  @tracked hideConversationEvents = true;
  @tracked macroActions?: MacroAction[] = undefined;

  get activeConversation() {
    return this.conversations.find((conversation) => conversation.id === this.activeConversationId);
  }

  get hasClosedConversation() {
    return this.closedConversations.length > 0;
  }

  get isCurrentConversationClosed() {
    return this.closedConversations.some(
      (conversation: Conversation) => conversation.id === this.activeConversationId,
    );
  }

  get inbox() {
    return new TeamSummary(
      1,
      this.type === OnboardingType.Default
        ? this.intl.t(
            'onboarding.guide-library.inbox-tour.conversations-list.inbox-title.onboarding',
          )
        : this.intl.t(
            'onboarding.guide-library.inbox-tour.conversations-list.inbox-title.unassigned',
          ),
      '👋',
    );
  }

  constructor(
    owner: ApplicationInstance,
    type: OnboardingType,
    conversations: Conversation[] = [],
  ) {
    setOwner(this, owner);

    this.type = type;
    this.conversations = isEmpty(conversations)
      ? generateConversations(owner, 3, this.session.teammate, this.inbox)
      : conversations;
  }

  // Clones an instance of the state class. There's no built in way to clone
  // instances, so this is doing a lot of manual work.
  clone() {
    let state = new OnboardingState(getOwner(this) as any, this.type, [...this.conversations]);
    state.focusedSections = [...this.focusedSections];
    state.activeView = this.activeView;
    state.highlightedAction = this.highlightedAction;
    state.closedConversations = [...this.closedConversations];
    state.isInboxListVisible = this.isInboxListVisible;
    state.isPreviewPanelVisible = this.isPreviewPanelVisible;
    state.isRightSideBarVisible = this.isRightSideBarVisible;
    state.activeConversationId = this.activeConversationId;
    state.composerBlocks = this.composerBlocks;
    state.isComposerFocused = this.isComposerFocused;
    state.isCommandKVisible = this.isCommandKVisible;
    state.isAIAssistVisible = this.isAIAssistVisible;
    state.commandKActiveAction = this.commandKActiveAction;
    state.commandKFocusedActionIdx = this.commandKFocusedActionIdx;
    state.aiAssistFocusedActionIdx = this.aiAssistFocusedActionIdx;
    state.hideConversationEvents = this.hideConversationEvents;
    state.macroActions = this.macroActions;

    return state;
  }

  focusSections(...names: string[]) {
    this.focusedSections = names;
  }

  clearFocus() {
    this.focusedSections = [];
  }

  highlightAction(name: string) {
    this.highlightedAction = name;
  }

  clearHighlight() {
    this.highlightedAction = undefined;
  }

  showPreviewPanel() {
    this.isPreviewPanelVisible = true;
  }

  showEvents() {
    this.hideConversationEvents = false;
  }

  toggleInboxList() {
    this.isInboxListVisible = !this.isInboxListVisible;
  }

  closeConversation(conversation?: Conversation) {
    if (!conversation) {
      return;
    }

    this.goToNextConversation();
    this.conversations = this.conversations.filter((c) => conversation.id !== c.id);
    this.closedConversations = [...this.closedConversations, conversation];
  }

  softCloseConversation(conversation?: Conversation) {
    if (!conversation) {
      return;
    }

    this.closedConversations = [...this.closedConversations, conversation];
  }

  undoLastClosedConversation() {
    let conversation = this.closedConversations.popObject();
    if (!conversation) {
      return;
    }

    this.conversations = [conversation, ...this.conversations];
    this.activeConversationId = conversation.id;
  }

  goToNextConversation() {
    this.activeConversationId += 1;
  }

  goToPreviousConversation() {
    this.activeConversationId -= 1;
  }

  insertAIReply() {
    this.composerBlocks = new BlocksDocument(this.aiBlocks);
    this.isCommandKVisible = false;
  }

  insertAIModifiedReply() {
    this.composerBlocks = new BlocksDocument(this.aiModifiedBlocks);
    this.isCommandKVisible = false;
  }

  clearComposer() {
    this.composerBlocks = new BlocksDocument([]);
  }

  addAIReply() {
    let part = new AdminComment(this.aiModifiedBlocks, this.session.teammate, []);
    this.activeConversation?.addPendingPart(part);
  }

  showCommandK() {
    this.isCommandKVisible = true;
  }

  hideCommandK() {
    this.isCommandKVisible = false;
  }

  showUseMacro() {
    this.isUseMacrosVisible = true;
  }

  hideUseMacro() {
    this.isUseMacrosVisible = false;
  }

  showAIAssist() {
    this.isAIAssistVisible = true;
  }

  hideAIAssist() {
    this.isAIAssistVisible = false;
  }

  showMacroActions() {
    this.macroActions = [
      new MacroAction(
        'add-tag-to-conversation',
        { tag_summary: { id: 1, name: 'Feature request' } },
        false,
      ),
    ];
  }

  hideMacroActions() {
    this.macroActions = undefined;
  }

  selectCommandKPane(action: CommandKAction) {
    this.commandKActiveAction = action;
  }

  goToNextCommandKAction() {
    this.commandKFocusedActionIdx += 1;
  }

  triggerAnalytics(action: string, object: string, _section: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      place: 'ai_helpdesk_wizard.inbox_tour',
      context: 'onboarding_guide_library.wizard.ai_helpdesk',
      section: `wizard_step_${_section}`,
    });
  }

  private get aiBlocks() {
    return [
      {
        type: 'paragraph',
        text: this.intl.t('onboarding.guide-library.inbox-tour.replies.normal'),
      },
    ];
  }

  private get aiModifiedBlocks() {
    return [
      {
        type: 'paragraph',
        text: this.intl.t('onboarding.guide-library.inbox-tour.replies.ai-modified'),
      },
    ];
  }
}
