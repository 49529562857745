/* RESPONSIBLE TEAM: team-proactive-support */
import { premadeTemplates } from 'embercom/models/data/outbound/constants';
import {
  emailTemplateTypes,
  premadeTemplateIDMapping,
} from 'embercom/models/data/outbound/constants';
import { isPresent } from '@ember/utils';

const createdAt = 'createdAt';
export default function filterTemplates({
  templates = [],
  selectedTemplateId = null,
  selectedTemplateIdName = null,
}) {
  let templateId = isPresent(selectedTemplateId) ? Number(selectedTemplateId) : null;

  if (premadeTemplates.official !== templateId) {
    // The official template is deprecated so should only be shown in the list if it is already selected.
    templates = templates.filter((template) => !template.isOfficial);
  }

  templates = templates.filter((template) => {
    let isLegacySelectedByOutboundTemplateId =
      template.outboundTemplateId === templateId && template.isLegacy;
    // The selectedTemplateIdName (plain, company,announcement, personal ...) is from
    // app/controllers/apps/app/outbound/settings/email-templates/index.js
    // this is the default template selected from the workspace
    let isLegacySelectedByIdName = template.id === selectedTemplateIdName && template.isLegacy;
    return !template.isLegacy || isLegacySelectedByOutboundTemplateId || isLegacySelectedByIdName;
  });

  return templates;
}

export function substituteValues(html, options = {}) {
  Object.keys(options).forEach(function (key) {
    // Allow optional whitespace before or after the key.
    let regex = `\{\{\\s?${key}\\s?\}\}`;
    // The 'i' flag here means that the regex should be matched without
    // case sensitivity.
    html = html.replace(new RegExp(regex, 'i'), options[key]);
  });
  return html;
}

export async function getTemplate(templateType, templateId, store) {
  let template;
  let templateIdForSearch =
    Number(templateType) === emailTemplateTypes.premade
      ? premadeTemplateIDMapping[templateId]
      : templateId;

  template = await store.peekRecord('email-template', templateIdForSearch);
  if (!template) {
    template = await store.findRecord('email-template', templateIdForSearch);
  }

  return template;
}

export function getTemplateTitle(templateType, templateId, store) {
  let template;

  if (Number(templateType) === emailTemplateTypes.custom) {
    template = store.peekRecord('email-template-titles', templateId);
  } else {
    template = store.peekRecord('email-template-titles', premadeTemplateIDMapping[templateId]);
  }

  return template;
}

export function isSwitchingFromBlockTemplateToLegacy(oldEmailTemplate, newEmailTemplate) {
  if (
    oldEmailTemplate.isBlockTemplate &&
    newEmailTemplate.isPremade &&
    !newEmailTemplate.isBlockTemplate
  ) {
    return true;
  } else if (oldEmailTemplate.isBlockTemplate && newEmailTemplate.isCustom) {
    return !newEmailTemplate.isBlockTemplate;
  }
  return false;
}

export function sortAndFilterCustomTemplates(emailTemplates) {
  let sortedCustomTemplates = emailTemplates.sortBy(createdAt).reverse();
  return sortedCustomTemplates.filter((template) => template.isCustom && !template.isDeleted);
}

export function defaultContentBlock(app) {
  return [
    {
      text: app.commentPlaceholder,
      align: 'left',
      type: 'paragraph',
    },
  ];
}
