/* RESPONSIBLE TEAM: team-proactive-support */
import {
  BaseConfig,
  EMOJI_TYPEAHEAD,
  INPUT_RULE_CODE_BLOCK,
  INPUT_RULE_EMOJI,
  INPUT_RULE_INLINE_CODE,
  INPUT_RULE_MARKDOWN_ANCHOR,
  INPUT_RULE_ORDERED_LIST,
  INPUT_RULE_UNORDERED_LIST,
} from '@intercom/embercom-prosemirror-composer';
import EmbercomFileUploader from 'embercom/lib/articles/embercom-file-uploader';

export default class EmailTemplateComposerConfig extends BaseConfig {
  autoFocus = false;
  customStylesConfig = {
    allowCustomStyles: true,
    formattingSidebarClass: 'email-composer-formatting-side-bar',
    disableButtonAutoFontColor: true,
  };
  allowImplicitMarginParagraphs = true;
  allowEmptyParagraphs = true;
  allowTextAlignment = true;
  renderDivider = true;
  resizeImages = true;
  allowedBlocks = [
    'placeholder',
    'paragraph',
    'image',
    'orderedList',
    'unorderedList',
    'heading',
    'subheading',
    'small',
    'button',
    'html',
    'table',
    'spacer',
    'social',
    'codeBlock',
    'horizontalRule',
  ];
  experimental = {
    hideInsertersOnMouseOut: true,
    deleteEmptyParagraphs: true,
  };
  inputRules = [
    INPUT_RULE_CODE_BLOCK,
    INPUT_RULE_EMOJI,
    INPUT_RULE_ORDERED_LIST,
    INPUT_RULE_UNORDERED_LIST,
    INPUT_RULE_INLINE_CODE,
    INPUT_RULE_MARKDOWN_ANCHOR,
  ];
  inserters = {
    showSocialLinks: true,
    showSpacerInserter: true,
    hideButtonEditor: true,
  };
  tools = [
    { name: 'text-formatter' },
    { name: 'template-inserter' },
    { name: 'anchor-editor', options: { showHelpLinkHeader: true } },
    { name: 'button-editor', options: { showHelpLinkHeader: true } },
    { name: 'image-editor', options: { supportAltAttributeEditing: true } },
    { name: 'fallback-editor' },
    { name: 'html/edit-button' },
    { name: 'selected-section' },
    { name: 'placeholder-inserter', options: { showTextInserter: true } },
    { name: 'media-inserter', options: { showTextInserter: true, alignment: 'centered' } },
    { name: 'table-editor', options: { maxColumns: 4, enableTableNodeSelection: true } },
  ];
  typeaheads = [EMOJI_TYPEAHEAD];
  updateImages = true;
  upload = {
    allowedImageFileTypes: ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'],
    uploader: EmbercomFileUploader,
    attrs: { policyUrl: '' },
  };
  tables = {
    responsive: true,
    containers: true,
    preventContainerDeletion: true,
  };

  constructor(app, eventService, resolver, placeholder) {
    super();
    this.analytics = {
      trackAnalyticsEvent: (eventName, attrs) => {
        eventService.trackAnalyticsEvent({
          action: eventName,
          object: 'email_template',
          place: 'composer',
          ...attrs,
        });
      },
    };
    this.placeholder = placeholder;
    this.templating = { picker: 'common/attribute-picker', resolver };
    this.upload.attrs.policyUrl = `/apps/${app.id}/uploads`;
  }
}
