/* RESPONSIBLE TEAM: team-proactive-support */
import moment from 'moment-timezone';
import { EMAIL_LOW_ENGAGEMENT_THRESHOLDS } from 'embercom/lib/outbound/constants';
import { failureListCategories as failureIndex } from 'embercom/models/data/stats-system/stats-constants';

export default function emailLowEngagementData(wentLiveAt, sentCount, openCount, subaggregations) {
  let lowEngagementStats = {};
  let _findFailureCount = (key) => subaggregations?.findBy('value', key)?.count || 0;

  if (!sentCount || sentCount < 1000) {
    return lowEngagementStats;
  }

  if (!subaggregations || !Object.keys(subaggregations).length) {
    subaggregations = [];
  }

  // don't calculate open rate if email is set to live within the last 24 hours
  if (moment(wentLiveAt).add(24, 'hours') < moment()) {
    let openRate = openCount / sentCount;

    if (openRate < EMAIL_LOW_ENGAGEMENT_THRESHOLDS.OPEN_RATE) {
      lowEngagementStats.openRate = {
        actual: openRate,
        recommended: EMAIL_LOW_ENGAGEMENT_THRESHOLDS.OPEN_RATE,
      };
    }
  }

  let spamRate = _findFailureCount(failureIndex.spamComplaint) / sentCount;
  let bounceRate = _findFailureCount(failureIndex.hardBounce) / sentCount;
  let unsubscribeRate = _findFailureCount(failureIndex.unsubscribe) / sentCount;

  if (spamRate > EMAIL_LOW_ENGAGEMENT_THRESHOLDS.SPAM_RATE) {
    lowEngagementStats.spamRate = {
      actual: spamRate,
      recommended: EMAIL_LOW_ENGAGEMENT_THRESHOLDS.SPAM_RATE,
    };
  }

  if (bounceRate > EMAIL_LOW_ENGAGEMENT_THRESHOLDS.BOUNCE_RATE) {
    lowEngagementStats.bounceRate = {
      actual: bounceRate,
      recommended: EMAIL_LOW_ENGAGEMENT_THRESHOLDS.BOUNCE_RATE,
    };
  }

  if (unsubscribeRate > EMAIL_LOW_ENGAGEMENT_THRESHOLDS.UNSUBSCRIBE_RATE) {
    lowEngagementStats.unsubscribeRate = {
      actual: unsubscribeRate,
      recommended: EMAIL_LOW_ENGAGEMENT_THRESHOLDS.UNSUBSCRIBE_RATE,
    };
  }

  return lowEngagementStats;
}
