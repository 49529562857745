/* RESPONSIBLE TEAM: team-frontend-tech */
import { helper as buildHelper } from '@ember/component/helper';
import dateAndTimeFormats from 'embercom/lib/date-and-time-formats';
import moment from 'moment-timezone';

function getDate(options) {
  if (Em.testing && !options.date) {
    return moment('1970-01-01'); // Provide a fixed time value for Percy acceptance tests
  }
  return options.date;
}

function dateFormat(params, options) {
  let date = getDate(options);
  let format = dateAndTimeFormats[options.format];
  return moment(date).format(format);
}

export default buildHelper(dateFormat);
