/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import OnboardingStep, { type KeyHandler } from 'embercom/lib/inbox2/onboarding/step';
import type OnboardingState from 'embercom/lib/inbox2/onboarding/state';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';
import type ApplicationInstance from '@ember/application/instance';
import { inject as service } from '@ember/service';

export enum StepId {
  Start = 'start',
  ShowInboxList = 'show-inbox-list',
  RightSideBarIntro = 'right-side-bar-intro',
  ToggleRightSideBar = 'toggle-right-side-bar',
  ConversationListNavigation = 'conversation-list',
  ReplyToConversation = 'reply-to-conversation',
  SendGifOpenCommandK = 'send-gif-cmd-k',
  SendGifFindAction = 'send-gif-find-action',
  SendGifPickGif = 'send-gif-pick-gif',
  SendReply = 'send-reply',
  CloseConversation = 'close-conversation',
  UndoCloseConversation = 'undo-close',
  SwitchLayout = 'switch-layout',
  TableLayoutIntro = 'table-layout-intro',
  GoToRealInbox = 'go-to-real-inbox',
  ShowConversationEvents = 'show-conversation-events',
  NewConversationsIntro = 'new-conversations-intro',
  FinishOnboardingAndNext = 'finish-onboarding',
}
export class Start extends OnboardingStep {
  id = StepId.Start;
  hasTransitionState = false;

  keys = [
    {
      code: 'Enter',
      display: 'Enter',
    },
  ];
}

function getHotKeysMap(ref: any) {
  // This method is the equivalent of declaring a service like below and getting the hotKeysMap out of it
  // @service declare inboxHotkeys: InboxHotkeys
  // We cannot declare a service like above for the classes below as Ember starts complaining that
  // it cannot instantaite those classes without a container
  return ref.lookup('service:inbox-hotkeys').hotkeysMap;
}

export class ShowInboxList extends OnboardingStep {
  @service declare inboxHotKeys: InboxHotkeys;
  readonly keys: KeyHandler[];

  constructor(owner: ApplicationInstance) {
    super();
    this.keys = [
      {
        code: getHotKeysMap(owner)[HotkeyID.ToggleLeftNav].keys,
        display: getHotKeysMap(owner)[HotkeyID.ToggleLeftNav].display[0],
        handler(state: OnboardingState) {
          state.toggleInboxList();
          state.highlightAction('hide-inbox-list');
          state.focusSections('inbox-list');
        },
      },
      {
        code: getHotKeysMap(owner)[HotkeyID.ToggleLeftNav].keys,
        display: getHotKeysMap(owner)[HotkeyID.ToggleLeftNav].display[0],
        handler(state: OnboardingState) {
          state.toggleInboxList();
          state.clearFocus();
        },
      },
    ];
  }

  id = StepId.ShowInboxList;
  hasTransitionState = true;

  enter(state: OnboardingState) {
    state.highlightAction('show-inbox-list');
  }
}

export class RightSideBarIntro extends OnboardingStep {
  id = StepId.RightSideBarIntro;
  hasTransitionState = false;

  enter(state: OnboardingState) {
    state.focusSections('conversation-details-sidebar');
  }

  keys = [
    {
      code: 'Enter',
      display: 'Enter',
    },
  ];
}

export class ToggleRightSideBar extends OnboardingStep {
  readonly keys: KeyHandler[];

  constructor(owner: ApplicationInstance) {
    super();
    this.keys = [
      {
        code: getHotKeysMap(owner)[HotkeyID.ToggleConversationDetailsSidebar].keys,
        display: getHotKeysMap(owner)[HotkeyID.ToggleConversationDetailsSidebar].display[0],
        handler(state: OnboardingState) {
          state.isRightSideBarVisible = false;
        },
      },
      {
        code: getHotKeysMap(owner)[HotkeyID.ToggleConversationDetailsSidebar].keys,
        display: getHotKeysMap(owner)[HotkeyID.ToggleConversationDetailsSidebar].display[0],
        handler(state: OnboardingState) {
          state.isRightSideBarVisible = true;
          state.clearHighlight();
        },
      },
    ];
  }

  id = StepId.ToggleRightSideBar;
  hasTransitionState = true;

  enter(state: OnboardingState) {
    state.highlightAction('hide-right-sidebar');
  }
}

export class ConversationListNavigation extends OnboardingStep {
  id = StepId.ConversationListNavigation;
  hasTransitionState = true;

  readonly keys: KeyHandler[];

  constructor(owner: ApplicationInstance) {
    super();
    this.keys = [
      {
        code: getHotKeysMap(owner)[HotkeyID.NavigateUp].keys,
        display: getHotKeysMap(owner)[HotkeyID.NavigateUp].display[0],
        handler(state: OnboardingState) {
          state.goToNextConversation();
        },
      },
      {
        code: getHotKeysMap(owner)[HotkeyID.NavigateUp].keys,
        display: getHotKeysMap(owner)[HotkeyID.NavigateUp].display[0],
        handler(state: OnboardingState) {
          state.goToNextConversation();
        },
      },
      {
        code: getHotKeysMap(owner)[HotkeyID.NavigateDown].keys,
        display: getHotKeysMap(owner)[HotkeyID.NavigateDown].display[0],
        handler(state: OnboardingState) {
          state.goToPreviousConversation();
        },
      },
      {
        code: getHotKeysMap(owner)[HotkeyID.NavigateDown].keys,
        display: getHotKeysMap(owner)[HotkeyID.NavigateDown].display[0],
        handler(state: OnboardingState) {
          state.goToPreviousConversation();
        },
      },
    ];
  }

  enter(state: OnboardingState) {
    state.focusSections('conversation-list');
  }
}

export class ShowConversationEvents extends OnboardingStep {
  id = StepId.ShowConversationEvents;
  hasTransitionState = false;

  readonly keys: KeyHandler[];

  constructor(owner: ApplicationInstance) {
    super();
    this.keys = [
      {
        code: getHotKeysMap(owner)[HotkeyID.ToggleEvents].keys,
        display: getHotKeysMap(owner)[HotkeyID.ToggleEvents].display.join('+'),
        handler(state: OnboardingState) {
          state.showEvents();
        },
      },
    ];
  }

  enter(state: OnboardingState) {
    state.focusSections('conversation-header', 'conversation-stream');
  }
}

export class ReplyToConversation extends OnboardingStep {
  id = StepId.ReplyToConversation;
  hasTransitionState = false;

  enter(state: OnboardingState) {
    state.focusSections('conversation-header', 'conversation-stream', 'conversation-composer');
  }

  keys = [
    {
      code: 'Enter',
      display: 'Enter',
      handler(state: OnboardingState) {
        state.isComposerFocused = true;
      },
    },
  ];
}

export class SendGifOpenCommandK extends OnboardingStep {
  id = StepId.SendGifOpenCommandK;
  hasTransitionState = false;

  readonly keys: KeyHandler[];

  constructor(owner: ApplicationInstance) {
    super();
    this.keys = [
      {
        code: getHotKeysMap(owner)[HotkeyID.CommandK].keys,
        display: getHotKeysMap(owner)[HotkeyID.CommandK].display.join('+'),
        handler(state: OnboardingState) {
          state.isComposerFocused = false;
          state.showCommandK();
        },
      },
    ];
  }

  enter(state: OnboardingState) {
    state.highlightAction('cmd-k-trigger');
  }
}

export class SendGifFindAction extends OnboardingStep {
  id = StepId.SendGifFindAction;
  hasTransitionState = false;

  enter(state: OnboardingState) {
    state.focusSections('command-k');
  }

  keys = [
    {
      code: 'ArrowDown',
      display: '↓',
      handler(state: OnboardingState) {
        state.goToNextCommandKAction();
      },
    },
    {
      code: 'ArrowDown',
      display: '↓',
      handler(state: OnboardingState) {
        state.goToNextCommandKAction();
      },
    },
    {
      code: 'Enter',
      display: 'Enter',
      handler(state: OnboardingState) {
        state.selectCommandKPane('gifs');
      },
    },
  ];
}

export class SendGifPickGif extends OnboardingStep {
  id = StepId.SendGifPickGif;
  hasTransitionState = false;

  keys = [
    {
      code: 'Enter',
      display: 'Enter',
      handler(state: OnboardingState) {
        state.isComposerFocused = true;
        state.clearHighlight();
        state.insertGif();
      },
    },
  ];
}

export class SendReply extends OnboardingStep {
  id = StepId.SendReply;
  hasTransitionState = true;

  readonly keys: KeyHandler[];

  constructor(owner: ApplicationInstance) {
    super();
    this.keys = [
      {
        code: getHotKeysMap(owner)[HotkeyID.Send].keys,
        display: getHotKeysMap(owner)[HotkeyID.Send].display.join('+'),
        handler(state: OnboardingState) {
          state.addReply();
          state.clearComposer();
          state.isComposerFocused = false;
        },
      },
    ];
  }

  enter(state: OnboardingState) {
    state.focusSections('conversation-header', 'conversation-stream', 'conversation-composer');
  }
}

export class CloseConversation extends OnboardingStep {
  id = StepId.CloseConversation;
  hasTransitionState = false;

  readonly keys: KeyHandler[];

  constructor(owner: ApplicationInstance) {
    super();
    this.keys = [
      {
        code: getHotKeysMap(owner)[HotkeyID.Close].keys,
        display: getHotKeysMap(owner)[HotkeyID.Close].display.join('+'),
        handler(state: OnboardingState) {
          state.closeConversation(state.activeConversation);
        },
      },
    ];
  }
}

export class UndoCloseConversation extends OnboardingStep {
  id = StepId.UndoCloseConversation;
  hasTransitionState = true;

  readonly keys: KeyHandler[];

  constructor(owner: ApplicationInstance) {
    super();
    this.keys = [
      {
        code: getHotKeysMap(owner)[HotkeyID.Undo].keys,
        display: getHotKeysMap(owner)[HotkeyID.Undo].display.join('+'),
        handler(state: OnboardingState) {
          state.undoLastClosedConversation();
        },
      },
    ];
  }
}

export class SwitchLayout extends OnboardingStep {
  id = StepId.SwitchLayout;
  hasTransitionState = true;

  readonly keys: KeyHandler[];

  constructor(owner: ApplicationInstance) {
    super();
    this.keys = [
      {
        code: getHotKeysMap(owner)[HotkeyID.SwitchView].keys,
        display: getHotKeysMap(owner)[HotkeyID.SwitchView].display[0],
        handler(state: OnboardingState) {
          state.switchToTableView();
        },
      },
    ];
  }

  enter(state: OnboardingState) {
    state.highlightAction('view-switcher');
    state.focusSections('view-switcher');
  }
}

export class TableLayoutIntro extends OnboardingStep {
  id = StepId.TableLayoutIntro;
  hasTransitionState = false;

  keys = [
    {
      code: 'Enter',
      display: 'Enter',
      handler(state: OnboardingState) {
        state.showPreviewPanel();
      },
    },
  ];
}

export class GoToRealInbox extends OnboardingStep {
  id = StepId.GoToRealInbox;
  hasTransitionState = false;

  readonly keys: KeyHandler[];

  constructor(owner: ApplicationInstance) {
    super();
    this.keys = [
      {
        code: getHotKeysMap(owner)[HotkeyID.JumpToAction].keys,
        display: getHotKeysMap(owner)[HotkeyID.JumpToAction].display[0],
        handler(state: OnboardingState) {
          state.selectCommandKPane('jump-to-inbox');
          state.showCommandK();
        },
      },
      {
        code: 'Enter',
        display: 'Enter',
      },
    ];
  }
}

export class FinishOnboardingAndNext extends OnboardingStep {
  id = StepId.FinishOnboardingAndNext;
  hasTransitionState = false;

  keys = [];
}

export class NewConversationsIntro extends OnboardingStep {
  id = StepId.NewConversationsIntro;
  hasTransitionState = false;

  enter(state: OnboardingState) {
    state.focusSections('conversation-list');
  }

  keys = [
    {
      code: 'Enter',
      display: 'Enter',
    },
  ];
}

export const DefaultSteps = [
  Start,
  ShowConversationEvents,
  ShowInboxList,
  RightSideBarIntro,
  ToggleRightSideBar,
  ConversationListNavigation,
  ReplyToConversation,
  SendGifOpenCommandK,
  SendGifFindAction,
  SendGifPickGif,
  SendReply,
  CloseConversation,
  UndoCloseConversation,
  SwitchLayout,
  TableLayoutIntro,
  GoToRealInbox,
];

export const ShortSteps = [
  Start,
  NewConversationsIntro,
  RightSideBarIntro,
  ReplyToConversation,
  SendGifOpenCommandK,
  SendGifFindAction,
  SendGifPickGif,
  SendReply,
  SwitchLayout,
  TableLayoutIntro,
  FinishOnboardingAndNext,
];

export enum OnboardingType {
  Default = 'default',
  Short = 'short',
}

export function getActiveStepKey(type: OnboardingType, appId: string) {
  return `onboarding-step:${type}:${appId}`;
}
