/* RESPONSIBLE TEAM: team-proactive-support */

import { type CreateTypes as CanvasConfettiObject } from 'canvas-confetti';

// For checklists we'll have multiple types of confetti animations
// This file will house the settings for each of them

// While using the interconfetti library it will also fire the confetti

export const simpleConfettiShoot = (confettiObj: CanvasConfettiObject) => {
  confettiObj({
    particleCount: 100,
    spread: 50,
    origin: { y: 0.3 },
    ticks: 800,
    gravity: 2,
    scalar: 1.3,
  });
};
