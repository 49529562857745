/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import InviteSendingDenied from 'embercom/components/notifications/invite-sending-denied';
export const APP_NOT_APPROVED_FOR_EMAILS = 'App not approved to send email';

export const SEND_FAIL_STRING =
  'There was a problem sending the invite, please contact customer support.';

export const RESEND_FAIL_STRING = 'There was a problem resending the invite, please try again';

export const DELETE_FAIL_STRING = 'There was a problem deleting the invite, please try again';

export function notifySendDenialError(notificationService) {
  notificationService.notifyErrorWithModelAndComponent({}, InviteSendingDenied, 7000);
}
