/* RESPONSIBLE TEAM: team-customer-data-platform */
export const MANUAL = 'manual';
export const EVENT_BASED = 'event-based';

// data_import states
export const PREPARING = 'preparing';
export const COMPLETED = 'completed';
export const PROCESSING = 'processing';
export const COMPLETED_WITH_ERRORS = 'completed_with_errors';
export const CANCELLED = 'cancelled';

// states only for UI
export const STOPPING = 'stopping';
