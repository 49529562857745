/* RESPONSIBLE TEAM: team-help-desk-experience */

import { helper as buildHelper } from '@ember/component/helper';
import { type EmberKeyboardEvent } from 'embercom/lib/inbox2/types';

function stopAllPropagation([handler]: [(event: Event) => void]) {
  return function (event: Event, emberKeyboardEvent?: EmberKeyboardEvent) {
    event.stopPropagation();
    emberKeyboardEvent?.stopPropagation();

    handler(event);
  };
}

let helper = buildHelper<{
  Args: {
    Positional: [(event: Event) => void];
  };
  Return: (event: Event, emberKeyboardEvent?: { stopPropagation: () => void }) => void;
}>(stopAllPropagation);
export default helper;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'stop-all-propagation': typeof helper;
  }
}
