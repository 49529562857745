/* RESPONSIBLE TEAM: team-phone */
import Helper from '@ember/component/helper';

export interface GetValueSignature {
  Args: {
    Positional: [map: Map<any, any>, key: any];
  };
  Return: any;
}

export default class GetValue extends Helper<GetValueSignature> {
  compute([map, key]: [Map<any, any>, any]): any {
    return map.get(key);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'get-value': typeof GetValue;
  }
}
