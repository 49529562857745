/* RESPONSIBLE TEAM: team-workflows */
import { Resource } from 'ember-resources';
import ajax from 'embercom/lib/ajax';
import { registerDestructor } from '@ember/destroyable';
import { inject as service } from '@ember/service';
import { states } from 'embercom/models/data/matching-system/matching-constants';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';

export default class NoTriggerCustomBotWorkflowsResource extends Resource {
  @service appService;
  @service intl;

  @tracked workflows = [];

  constructor(owner, args, previous) {
    super(owner, args, previous);

    registerDestructor(this, () => {
      this.fetch.cancelAll();
    });

    let { currentBotId } = args.named;
    this.currentBotId = currentBotId;

    if (previous && previous.currentBotId === currentBotId) {
      this.workflows = previous.workflows;
    }
  }

  @task({ drop: true })
  *fetch() {
    let triggerableWorkflows = [];
    let noTriggerBots = yield ajax({
      url: `/ember/operator_custom_bots/${this.currentBotId}/no_trigger_bots`,
      type: 'GET',
      data: {
        app_id: this.appService.app.id,
      },
    });

    noTriggerBots.forEach((bot) => {
      triggerableWorkflows.push({
        title: bot.title,
        workflowId: bot.operator_workflow_instance_id,
        botId: bot.id,
        state: bot.state,
      });
    });

    // sort by bot state in the order live, paused, draft
    let liveWorkflows = triggerableWorkflows.filter((bot) => bot.state === states.live);
    let pausedWorkflows = triggerableWorkflows.filter((bot) => bot.state === states.paused);
    let draftWorkflows = triggerableWorkflows.filter((bot) => bot.state === states.draft);
    this.workflows = [...liveWorkflows, ...pausedWorkflows, ...draftWorkflows];
  }

  get isLoading() {
    return this.fetch.isRunning;
  }
}
