/* RESPONSIBLE TEAM: team-frontend-tech */
import { helper as buildHelper } from '@ember/component/helper';
import { capitalize } from '@ember/string';

function sentenceCase(params: [string?]) {
  let text = params[0] || '';
  return capitalize(text.toLowerCase());
}

let helper = buildHelper<{
  Args: {
    Positional: [string];
  };
  Return: string;
}>(sentenceCase);

export default helper;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'sentence-case': typeof helper;
  }
}
