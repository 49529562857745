/* RESPONSIBLE TEAM: team-proactive-support */
import { isEmpty, isNone } from '@ember/utils';

export default class ImplicitPredicatesGenerator {
  static generate(rulesetLinks) {
    // The logic here should be kept in sync with the backend's ImplicitPredicatesGenerator
    let rulesetLinksWithPredicates = this._getRulesetLinksWithPredicates(rulesetLinks);

    if (rulesetLinksWithPredicates.any((link) => isNone(link.implicitPredicates))) {
      return null;
    }

    let implicitPredicates = this._getUniqueImplicitPredicates(rulesetLinksWithPredicates);

    if (isEmpty(implicitPredicates.flat())) {
      return [];
    }

    if (implicitPredicates.length === 1) {
      return implicitPredicates[0];
    }

    if (implicitPredicates.any((p) => p.length > 1)) {
      let andPredicates = implicitPredicates.map((p) => this._wrapInPredicate(p, 'and'));
      return this._wrapInPredicate(andPredicates, 'or');
    }

    return this._wrapInPredicate(implicitPredicates, 'or');
  }

  static _getRulesetLinksWithPredicates(rulesetLinks) {
    return rulesetLinks.filter((link) => link && !link.isControlGroup && link.object);
  }

  static _getUniqueImplicitPredicates(rulesetLinks) {
    return rulesetLinks.reduce((uniqueImplicitPredicates, rulesetLink) => {
      let implicitPredicates = rulesetLink.implicitPredicates;
      let isDuplicate = uniqueImplicitPredicates.any((uniquePredicates) =>
        _.isEqual(implicitPredicates, uniquePredicates),
      );
      if (!isDuplicate) {
        uniqueImplicitPredicates.push(implicitPredicates);
      }
      return uniqueImplicitPredicates;
    }, []);
  }

  static _wrapInPredicate(predicates, predicateType) {
    return [
      {
        type: predicateType,
        predicates: predicates.flat(),
      },
    ];
  }
}
