/* RESPONSIBLE TEAM: team-reporting */
import { timeYear } from 'd3-time';

export const makeDateFormatter =
  ({ stride = 1, isRangeHours, isRangeDays }) =>
  (date, index) => {
    if (index && index % stride !== 0) {
      return '';
    } else if (isRangeHours) {
      return 'timeInHour';
    } else if (isRangeDays) {
      return 'dateWithMonth';
    } else if (timeYear(date) < date) {
      return 'onlyMonth';
    } else {
      return 'onlyYear';
    }
  };

export default function (date, timezone, isRangeHours, isRangeDays) {
  return makeDateFormatter({ timezone, isRangeHours, isRangeDays })(date);
}
