/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import { and } from '@ember/object/computed';
import EmberObject from '@ember/object';
import { ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import FiltersToText from 'embercom/lib/filters-to-text';
import PredicateFilterMap from 'embercom/models/data/predicate-filter-map';
import GoalsPredicateFilterMap from 'embercom/models/data/goals-predicate-filter-map';

const NULL_VALUE_COMPARISONS = ['known', 'unknown', 'increase', 'decrease', 'triggered'];

export default EmberObject.extend({
  isValid: ternaryToProperty(
    'predicate.isNotValueBasedComparison',
    'isValidForNoValue',
    'isValidForValue',
  ),
  isValidForValue: and('predicate.hasAttributeComparisonAndType', 'predicate.hasValue'),
  isValidForNoValue: and('predicate.hasAttributeComparisonAndType', 'predicate.hasNoValue'),
  defaultComparison: 'eq',
  goalComparison: '',

  initializeDefaultValue() {
    if (this.get('predicate.hasNoValue')) {
      this.set('predicate.value', this.getDefaultValue());
    }
  },

  changeComparison(comparison) {
    this.setProperties({
      'predicate.comparison': comparison,
      'predicate.value': this._intializeValueForComparison(comparison),
    });
  },

  changeValue(value) {
    this.set('predicate.value', value);
  },

  getDefaultValue() {
    return '';
  },

  getDefaultComparison(context) {
    if (context && context.isGoalPredicate && this.goalComparison) {
      return this.goalComparison;
    } else {
      return this.defaultComparison;
    }
  },

  getDescription() {
    return this._getDescription(PredicateFilterMap, false);
  },

  getGoalDescription() {
    return this._getDescription(GoalsPredicateFilterMap, true);
  },

  _getDescription(customFilterMap, showNoEventPrefix) {
    let descriptionPrefix = this.get('predicate.info')
      ? this.get('predicate.info.name')
      : this.get('predicate.attributeIdentifier');
    return {
      text: `${descriptionPrefix} ${FiltersToText.getPredicateDescription(
        this.predicate,
        customFilterMap,
        showNoEventPrefix,
      )}`,
      icon: this.get('predicate.info.icon'),
    };
  },

  _intializeValueForComparison(comparison) {
    if (NULL_VALUE_COMPARISONS.includes(comparison)) {
      return null;
    } else if (this.get('predicate.hasNoValue')) {
      return '';
    } else {
      return this.get('predicate.value');
    }
  },

  getUserType() {
    throw new Error(`getUserType is not a valid function for this predicate manager`);
  },
});
