/* RESPONSIBLE TEAM: team-messenger */
export const DEFAULT_MESSENGER_COLORS = [
  '#2392EC',
  '#546270',
  '#F06192',
  '#FF6666',
  '#3F51B5',
  '#0AA89A',
  '#00ACC1',
];
