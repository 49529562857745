/* RESPONSIBLE TEAM: team-frontend-tech */

import { getOwner } from '@ember/application';

export default function containerLookup(key) {
  return getContainer()?.lookup(key);
}

export function getContainer() {
  return getApplication()?.__container__;
}

export function getRouter() {
  return containerLookup('router:main');
}

export function getRouterService(context) {
  return getOwner(context).lookup('service:router');
}

export function getEmberDataStore() {
  return containerLookup('service:store');
}

export function getApp() {
  return containerLookup('service:appService')?.app;
}

export function getAttributeService() {
  return containerLookup('service:attributeService');
}

export function getIntlService() {
  return containerLookup('service:intl');
}

export function getSelfServeTrialsService() {
  return containerLookup('service:selfServeTrialsService');
}

export function getCardlessTrialService() {
  return containerLookup('service:cardlessTrialService');
}

export function getCustomerService() {
  return containerLookup('service:customerService');
}

export function getIntercomConfirmService() {
  return containerLookup('service:intercomConfirmService');
}

export function getTicketStateService() {
  return containerLookup('service:ticketStateService');
}

function getApplication() {
  return window.EmberApplicationInstance;
}
