/* RESPONSIBLE TEAM: team-ml */

import { type BlockList } from '@intercom/interblocks.ts';
import generateUUID from 'embercom/lib/uuid-generator';
import { tracked } from '@glimmer/tracking';
import { TrackedArray } from 'tracked-built-ins';

export type PlaygroundTestRunWireFormat = Omit<KeysToSnakeCase<PlaygroundTestRun>, 'questions'> & {
  questions: PlaygroundTestQuestionWireFormat[];
};

type PlaygroundTestQuestionWireFormat = Omit<KeysToSnakeCase<PlaygroundTestQuestion>, 'sources'> & {
  sources: KeysToSnakeCase<PlaygroundTestQuestionSource>[];
};

export enum Status {
  Pending = 'pending',
  Running = 'running',
  Completed = 'completed',
  Failed = 'failed',
}

class PlaygroundTestQuestionSource {
  constructor(
    public entityId: number,
    public entityType: number,
    public entityData: {
      title: string;
    },
  ) {}

  get title() {
    return this.entityData.title;
  }
}

export class PlaygroundTestQuestion {
  @tracked questionText: string;
  @tracked status: Status;

  id: number | undefined;
  responseText: BlockList = [];
  sources: PlaygroundTestQuestionSource[] = [];
  answerType?:
    | 'inline_answer'
    | 'clarification'
    | 'bot_reply'
    | 'inline_answer_with_disambiguation';

  constructor(
    id: number | undefined,
    questionText: string,
    responseText?: BlockList,
    sources?: PlaygroundTestQuestionSource[],
    status?: Status,
    answerType?: PlaygroundTestQuestion['answerType'],
  ) {
    this.id = id;
    this.questionText = questionText;
    this.status = status ?? Status.Pending;
    this.responseText = responseText ?? [];
    this.sources = sources ?? [];
    this.answerType = answerType;
  }

  static deserialize(data: PlaygroundTestQuestionWireFormat): PlaygroundTestQuestion {
    return new PlaygroundTestQuestion(
      Number(data.id),
      data.question_text,
      data.response_text,
      data.sources?.map(
        (s) => new PlaygroundTestQuestionSource(s.entity_id, s.entity_type, s.entity_data),
      ),
      data.status,
      data.answer_type,
    );
  }

  get hasSources() {
    return this.sources?.length > 0;
  }

  get isNotDirectAnswer() {
    return this.answerType !== 'inline_answer';
  }

  run() {
    this.status = Status.Running;
  }
}

export class PlaygroundTestRun {
  @tracked status: Status;
  questions = new TrackedArray<PlaygroundTestQuestion>([]);

  id: number | undefined;
  adminId: number | undefined;
  clientAssignedUuid: string;
  createdAt: string;
  updatedAt: string;
  questionLimit: number;

  constructor(
    id: number | undefined,
    adminId: number | undefined,
    clientAssignedUuid: string,
    status: Status,
    createdAt: string,
    updatedAt: string,
    questions: PlaygroundTestQuestion[],
  ) {
    this.id = id;
    this.adminId = adminId;
    this.clientAssignedUuid = clientAssignedUuid;
    this.status = status;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.questions = new TrackedArray(questions);
    this.questionLimit = 50;
  }

  get isPending() {
    return this.status === Status.Pending;
  }

  get isRunning() {
    return this.status === Status.Running;
  }

  get isCompleted() {
    return this.status === Status.Completed;
  }

  get isFailed() {
    return this.status === Status.Failed;
  }

  get isReviewing() {
    return this.isCompleted || this.isFailed;
  }

  get hasQuestions() {
    return this.questions.length > 0;
  }

  get hasPendingQuestions() {
    return this.questions.some((q) => q.status === Status.Pending);
  }

  get pendingQuestions() {
    return this.questions.filter((q) => q.status === Status.Pending);
  }

  get hasReachedQuestionLimit(): boolean {
    return this.questions.length >= this.questionLimit;
  }

  get remainingQuestionQuota(): number {
    if (this.hasReachedQuestionLimit) {
      return 0;
    }

    return this.questionLimit - this.questions.length;
  }

  addQuestions(questions: PlaygroundTestQuestion[]) {
    this.questions.push(...questions);
  }

  removeQuestion(questionText: string) {
    this.questions = new TrackedArray(
      this.questions.filter((q) => q.questionText !== questionText),
    );
  }

  setRunningStatus(questions?: PlaygroundTestQuestion[]) {
    this.status = Status.Running;
    questions?.forEach((q) => q.run());
  }

  hasQuestion(questionText: string) {
    return this.questions.some((q) => q.questionText === questionText);
  }

  setQuestionState(id: number, status: Status) {
    let question = this.questions.find((q) => q.id === id);

    if (question) {
      question.status = status;
    }
  }

  static deserialize(data: PlaygroundTestRunWireFormat): PlaygroundTestRun {
    return new PlaygroundTestRun(
      data.id,
      data.admin_id,
      data.client_assigned_uuid,
      data.status,
      data.created_at,
      data.updated_at,
      data.questions.map((q) => PlaygroundTestQuestion.deserialize(q)),
    );
  }

  static build(): PlaygroundTestRun {
    let clientAssignedUuid = generateUUID();

    return new PlaygroundTestRun(
      undefined,
      undefined,
      clientAssignedUuid,
      Status.Pending,
      new Date().toISOString(),
      new Date().toISOString(),
      [],
    );
  }
}
