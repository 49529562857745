/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import EmberObject from '@ember/object';
import ajax from 'embercom/lib/ajax';
import { task } from 'ember-concurrency';

export const VerificationKeys = Object.freeze({
  ANY_PING: Symbol('ANY_PING'),
  LOGGED_OUT: Symbol('LOGGED_OUT'),
  LOGGED_IN: Symbol('LOGGED_IN'),
});

let MessengerVerification = EmberObject.extend({
  appId: null,

  verifyAnyPing(source) {
    return ajax({
      url: `/ember/messenger_install_verification/verify_any_ping`,
      type: 'GET',
      contentType: 'application/json',
      data: {
        app_id: this.appId,
        source,
      },
    });
  },

  verifyLoggedOutInstall(source) {
    return ajax({
      url: `/ember/messenger_install_verification/verify_logged_out_install`,
      type: 'GET',
      contentType: 'application/json',
      data: {
        app_id: this.appId,
        source,
      },
    });
  },

  verifyLoggedInInstall(source) {
    return ajax({
      url: `/ember/messenger_install_verification/verify_logged_in_install`,
      type: 'GET',
      contentType: 'application/json',
      data: {
        app_id: this.appId,
        source,
      },
    });
  },

  convertUserSource(source) {
    if (source === 'single-page') {
      return 'single_page';
    } else if (source === 'google-tag-manager') {
      return 'google_tag_manager';
    } else {
      return source;
    }
  },

  checkInstall: task(function* (verificationKey, userSourceKey) {
    userSourceKey = this.convertUserSource(userSourceKey);

    switch (verificationKey) {
      case VerificationKeys.ANY_PING:
        return yield this.verifyAnyPing(userSourceKey);
      case VerificationKeys.LOGGED_OUT:
        return yield this.verifyLoggedOutInstall(userSourceKey);
      case VerificationKeys.LOGGED_IN:
        return yield this.verifyLoggedInInstall(userSourceKey);
      default:
        return yield this.verifyAnyPing(userSourceKey);
    }
  }),
});

export default MessengerVerification;
