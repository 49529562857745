/* RESPONSIBLE TEAM: team-help-desk-experience */

// Overrides media helper from ember-responsive in order
// to unregister the mediaChanged event handler when the
// helper is torn down.
// We'll upstream this back to the addon if this works out

import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

export default class Media extends Helper {
  @service media;

  constructor() {
    super(...arguments);

    this._handler = this.recompute.bind(this);
    this.media.on('mediaChanged', this._handler);
  }

  willDestroy() {
    super.willDestroy();

    this.media.off('mediaChanged', this._handler);
    this._handler = undefined;
  }

  compute = ([prop]) => this.media[prop];
}
