/* RESPONSIBLE TEAM: team-frontend-tech */

import { getOwnConfig, macroCondition } from '@embroider/macros';

export function initialize(appInstance) {
  if (macroCondition(getOwnConfig().liveReloadIndicatorEnabled)) {
    class LivereloadIndicatorPlugin {
      divTag = null;
      styleTag = null;

      reload(path) {
        if (path === 'please-show-livereload-indicator') {
          let css = `
          .livereload-indicator-dbf72c1 {
              width: 100vw;
              height: 5px;
              z-index: 999999;
              position: fixed;
              top: 0;
              left: 0;
              background: repeating-linear-gradient(-75deg, #f2a417, #f2a417 10px, #141617 10px, #141617 20px);
              background-repeat:repeat-x;
              background-size: 400% 100%;
              animation: AnimationName 100s linear infinite;
          }

          @keyframes AnimationName {
              0%{background-position:100% 50%}
              100%{background-position:-33% 50%}
          }
          `;
          let styleTag = document.createElement('style');
          styleTag.appendChild(document.createTextNode(css));

          let divTag = document.createElement('div');
          divTag.classList.add('livereload-indicator-dbf72c1');

          document.body.insertBefore(styleTag, document.body.firstChild);
          document.body.insertBefore(divTag, document.body.firstChild);

          this.divTag = divTag;
          this.styleTag = styleTag;

          return true;
        } else {
          if (this.divTag) {
            this.divTag.remove();
          }

          if (this.styleTag) {
            this.styleTag.remove();
          }

          return false;
        }
      }

      analyze() {
        return {
          disable: false,
        };
      }
    }

    LivereloadIndicatorPlugin.identifier = 'livereload-indicator';
    LivereloadIndicatorPlugin.version = '1.0'; // Just following the example, this might not be even used

    window.LiveReload &&
      window.LiveReload.addPlugin &&
      window.LiveReload.addPlugin(LivereloadIndicatorPlugin);
  } else {
    //NOOP
  }
}

export default {
  initialize,
};
