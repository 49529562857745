/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import pluralize from 'embercom/lib/inflector';

export function controlGroupEndSuccess() {
  return `Control group test ended`;
}

export function controlGroupEndFailure() {
  return `We couldn't end the control group test. Send us a message and we'll do our best to help out.`;
}

export function controlGroupEndOptions(controlGroup) {
  let sentCount = controlGroup.get('stats.sentCount');
  let pluralizedPerson = pluralize('person', sentCount);
  let pluralizedWas = pluralize('was', sentCount);

  let displaySentCount = controlGroup.get('stats.displaySentCount');
  let audienceExplanation =
    sentCount === 0
      ? ''
      : ` The ${displaySentCount} ${pluralizedPerson} who ${pluralizedWas} in your control group will not receive the message.`;
  return {
    title: `End control group test`,
    body: `Going forward, your message will send to 100% of your selected audience.${audienceExplanation}`,
    confirmButtonText: `End test`,
  };
}
