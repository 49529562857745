/* RESPONSIBLE TEAM: team-reporting */
import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';

export default class HasPermissionHelper extends Helper {
  @service permissionsService;

  compute([permissionName]) {
    return Boolean(this.permissionsService.currentAdminCan(permissionName));
  }
}
