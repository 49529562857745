/* RESPONSIBLE TEAM: team-help-desk-experience */
import { helper } from '@ember/component/helper';

function UnlessIsTyping(cb: [(params: KeyboardEvent) => void]) {
  return (params: KeyboardEvent) => {
    let target = params.target as Element;
    let invalidTagNames = ['input', 'textarea'];

    if (
      invalidTagNames.includes(target.tagName?.toLowerCase()) ||
      (target.getAttribute && target.getAttribute('contentEditable') === 'true')
    ) {
      return;
    }
    cb[0]?.(params);
  };
}

let unlessIsTypingHelper = helper<{
  Args: {
    Positional: [(params: KeyboardEvent) => void];
  };
  Return: (params: KeyboardEvent) => any;
}>(UnlessIsTyping);
export default unlessIsTypingHelper;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'unless-is-typing': typeof unlessIsTypingHelper;
  }
}
