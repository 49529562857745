/* RESPONSIBLE TEAM: team-frontend-tech */
import ConversationLoader from 'embercom/lib/inbox/conversation-loader';
import BackgroundInboxList from 'embercom/lib/inbox/background-inbox-list';
import RealtimeInboxUpdater from 'embercom/lib/inbox/realtime-inbox-updater';

export default {
  name: 'inbox',
  initialize(application) {
    application.register('customClass:inbox/conversationLoader', ConversationLoader, {
      singleton: false,
    });
    application.register('customClass:inbox/backgroundInboxList', BackgroundInboxList, {
      singleton: false,
    });
    application.register('customClass:inbox/realtimeinboxupdater', RealtimeInboxUpdater, {
      singleton: false,
    });
  },
};
