/* RESPONSIBLE TEAM: team-proactive-support */
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { getOwner } from '@ember/application';
import { post } from 'embercom/lib/ajax';

async function loadFinProfile(context) {
  let owner = getOwner(context);
  let appService = owner.lookup('service:app-service');
  let outboundHomeService = owner.lookup('service:outbound-home-service');
  let store = owner.lookup('service:store');
  let intl = owner.lookup('service:intl');

  let content;
  content = await outboundHomeService.contentSearchWithFilters({
    object_types: [
      objectTypes.customBot,
      objectTypes.inboundCustomBot,
      objectTypes.buttonCustomBot,
      objectTypes.triggerableCustomBot,
    ],
    app_id: appService.app.id,
    statistics: [],
    sort_by: 'priority',
    sort_direction: 'asc',
    additionalSearchableData: {
      has_fin: true,
    },
  });

  let ruleset;
  if (content.contentWrappers.length > 0) {
    ruleset = await store.findRecord(
      'matching-system/ruleset',
      content.contentWrappers[0].get('contentWrapperId'),
    );
  } else {
    ruleset = await post('/ember/operator_workflows/create_fin_workflow', {
      app_id: appService.app.id,
      handover_type: 'close_conversation',
      parts: [
        {
          blocks: [
            {
              type: 'paragraph',
              text: intl.t(
                'onboarding.home.steps.route-conversation-to-human.close-the-conversation-default-message',
              ),
              class: 'no-margin',
            },
          ],
        },
      ],
      role_predicate_group: {
        predicates: [{ attribute: 'role', type: 'role', comparison: 'in', value: ['user_role'] }],
      },
    });

    ruleset = store.push(store.normalize('matching-system/ruleset', ruleset));
  }

  return ruleset;
}

export default {
  loadFinProfile,
};
