/* RESPONSIBLE TEAM: team-help-desk-experience */
import Helper from '@ember/component/helper';
import { Channel } from 'embercom/models/data/inbox/channels';

const satisfies =
  <T>() =>
  <U extends T>(u: U) =>
    u;

const AllChannels = [
  Channel.Unknown,
  Channel.Desktop,
  Channel.Android,
  Channel.IOS,
  Channel.Email,
  Channel.Twitter,
  Channel.Facebook,
  Channel.FacebookMessenger,
  Channel.Whatsapp,
  Channel.Instagram,
  Channel.PhoneSwitch,
  Channel.SMS,
  Channel.PhoneCall,
];

const SupportedTypeMap = satisfies<Record<string, Channel[]>>()({
  emoji: AllChannels,
  gif: [
    Channel.Unknown,
    Channel.Desktop,
    Channel.Android,
    Channel.IOS,
    Channel.Email,
    Channel.PhoneSwitch,
    Channel.PhoneCall,
  ],
  macro: AllChannels,
  whatsappTemplate: [Channel.Whatsapp],
  knowledgeBase: AllChannels,
  article: [
    Channel.Unknown,
    Channel.Desktop,
    Channel.Android,
    Channel.IOS,
    Channel.Email,
    Channel.PhoneSwitch,
    Channel.PhoneCall,
  ],
  attachment: [
    Channel.Unknown,
    Channel.Desktop,
    Channel.Android,
    Channel.IOS,
    Channel.Email,
    Channel.Whatsapp,
    Channel.PhoneSwitch,
    Channel.PhoneCall,
  ],
  image: [
    Channel.Unknown,
    Channel.Desktop,
    Channel.Android,
    Channel.IOS,
    Channel.Email,
    Channel.Facebook,
    Channel.Instagram,
    Channel.Whatsapp,
    Channel.PhoneSwitch,
    Channel.PhoneCall,
  ],
  app: [
    Channel.Unknown,
    Channel.Desktop,
    Channel.Android,
    Channel.IOS,
    Channel.PhoneSwitch,
    Channel.PhoneCall,
    Channel.Email,
  ],
  aiAssist: AllChannels,
  copilotSuggestions: AllChannels,
  finAiAgent: [
    Channel.Unknown,
    Channel.Desktop,
    Channel.Android,
    Channel.IOS,
    Channel.Email,
    Channel.Twitter,
    Channel.Facebook,
    Channel.FacebookMessenger,
    Channel.Whatsapp,
    Channel.Instagram,
    Channel.PhoneSwitch,
    Channel.SMS,
  ],
});

export type SupportedType = keyof typeof SupportedTypeMap;

export const AllowedImageFileTypesWhatsapp = ['image/png', 'image/jpeg', 'image/jpg'];

export function lookupChannelSupportedType(
  channel: Channel | undefined,
  type: SupportedType,
): boolean {
  if (!channel) {
    return true;
  }

  let supportedTypeChannels: Channel[] = SupportedTypeMap[type];
  return supportedTypeChannels.includes(channel) ?? true;
}

export default class LookupChannelSupportedType extends Helper<{
  Args: {
    Positional: [SupportedType | string, Channel];
  };
  Return: boolean;
}> {
  compute(params: [SupportedType | string, Channel]) {
    let [contentType, channel] = params;
    let isSupportedType = contentType in SupportedTypeMap;

    if (channel !== undefined && isSupportedType) {
      let supportedTypeChannels: Channel[] = SupportedTypeMap[contentType as SupportedType];
      return supportedTypeChannels.includes(channel);
    }

    return true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'lookup-channel-supported-type': typeof LookupChannelSupportedType;
  }
}
