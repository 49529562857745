/* RESPONSIBLE TEAM: team-proactive-support */
import { isEmpty } from '@ember/utils';

const EXCLUDED_STAT_KEYS = ['id', 'click_tracking_links', 'message_variation_id'];

const REAL_TIME_TRANSLATION_MAPPING = {
  attribute_collector_replies_rate: 'attribute_collector_reply_percentage',
  attribute_collector_replies: 'attribute_collector_reply_count',
  bounces_rate: 'bounce_percentage',
  bounces: 'bounce_count',
  clicks_rate: 'click_percentage',
  clicks: 'click_count',
  complaints_rate: 'complained_percentage',
  complaints: 'complained_count',
  conversations: 'sent_count',
  goals_rate: 'goal_success_percentage',
  goals: 'goal_count',
  happy_rate: 'happy_percentage',
  happy: 'happy_count',
  last_sent: 'last_sent_on',
  neutral_rate: 'neutral_percentage',
  neutral: 'neutral_count',
  opens_rate: 'open_percentage',
  opens: 'open_count',
  reaction_0_rate: 'reaction_0_percentage',
  reaction_0: 'reaction_0_count',
  reaction_1_rate: 'reaction_1_percentage',
  reaction_1: 'reaction_1_count',
  reaction_2_rate: 'reaction_2_percentage',
  reaction_2: 'reaction_2_count',
  reaction_3_rate: 'reaction_3_percentage',
  reaction_3: 'reaction_3_count',
  reaction_4_rate: 'reaction_4_percentage',
  reaction_4: 'reaction_4_count',
  replies_rate: 'reply_percentage',
  replies: 'reply_count',
  responses_rate: 'response_percentage',
  responses: 'response_count',
  sad_rate: 'sad_percentage',
  sad: 'sad_count',
  soft_bounces: 'soft_bounce_count',
  thumbs_down_rate: 'thumbs_down_percentage',
  thumbs_down: 'thumbs_down_count',
  thumbs_up_rate: 'thumbs_up_percentage',
  thumbs_up: 'thumbs_up_count',
  unsubscribes_rate: 'unsubscribe_percentage',
  unsubscribes: 'unsubscribe_count',
  views: 'view_count',
};

export default {
  translateRealTimeRateStatValue(rateStat, realTimeStats) {
    return realTimeStats.hasOwnProperty(rateStat)
      ? realTimeStats[rateStat] * 100
      : realTimeStats[REAL_TIME_TRANSLATION_MAPPING[rateStat]];
  },
  translateRealTimeCountStatValue(countStat, realTimeStats) {
    return realTimeStats.hasOwnProperty(countStat)
      ? realTimeStats[countStat]
      : realTimeStats[REAL_TIME_TRANSLATION_MAPPING[countStat]];
  },
  translateRealTimeStats(realTimeStats) {
    let realtimeStatsKeys = Object.keys(realTimeStats);
    let translatedStats = {};
    realtimeStatsKeys.forEach((statKey) => {
      let translatedKey = REAL_TIME_TRANSLATION_MAPPING[statKey];
      let valueForStat = realTimeStats[statKey];

      if (!EXCLUDED_STAT_KEYS.includes(statKey)) {
        if (isEmpty(translatedKey)) {
          translatedStats[statKey] = valueForStat;
        } else if (isEmpty(valueForStat)) {
          translatedStats[translatedKey] = valueForStat;
        } else {
          translatedStats[translatedKey] = parseFloat(valueForStat);
          if (translatedKey.endsWith('percentage')) {
            let multipliedVal = translatedStats[translatedKey] * 100;
            // The second parseFloat removes trailing zeros after moving to fixed precision
            let rounded_percentage = parseFloat(multipliedVal.toFixed(2));
            translatedStats[translatedKey] = rounded_percentage;
          }
        }
      }
    });
    return translatedStats;
  },
};
