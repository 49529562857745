/* RESPONSIBLE TEAM: team-proactive-support */

import { getTemplate, defaultContentBlock } from 'embercom/lib/outbound/email-template-helper';
import {
  emailTemplateTypes,
  premadeTemplates,
  senderTypes,
} from 'embercom/models/data/outbound/constants';
import { isPresent } from '@ember/utils';
import EmailContentHandler from 'embercom/lib/outbound/email-content-handler';

const localizedEmailContentId = 'localizedEmailContentIdForEmailCreation';
const emailId = 'localizedEmailContentIdForEmailCreation';

export default class EmailPreviewBuilder {
  contentHandler;

  constructor(store, app) {
    this.store = store;
    this.app = app;
    this.contentHandler = new EmailContentHandler(this.store);
  }

  _createModel(modelName, id) {
    let payload = {};
    payload[modelName] = { id };
    return this.store.createRecord(modelName, payload);
  }

  _getDefaultTemplateIdAndType() {
    let defaultEmailTemplateId = this.app.default_email_template_id;
    if (isPresent(premadeTemplates[defaultEmailTemplateId])) {
      return {
        defaultTemplateId: premadeTemplates[defaultEmailTemplateId],
        defaultTemplateType: emailTemplateTypes.premade,
      };
    }
    return {
      defaultTemplateId: defaultEmailTemplateId,
      defaultTemplateType: emailTemplateTypes.custom,
    };
  }

  // A non persisted email used to display potential changes to the user
  async createPreviewEmail(existingLocalizedEmailContent) {
    let previewEmail = this._createModel('emails/email', emailId);
    let localizedEmailContent = await this.createLocalizedEmailContent(
      existingLocalizedEmailContent,
      existingLocalizedEmailContent?.blocks,
    );
    previewEmail.localizedEmailContents.pushObject(localizedEmailContent);
    previewEmail.showUnsubscribeLink = true;

    if (localizedEmailContent) {
      previewEmail.linkFontWeight = localizedEmailContent.email.content.linkFontWeight;
      previewEmail.linkFontColor = localizedEmailContent.email.content.linkFontColor;
      previewEmail.linkFontStyle = localizedEmailContent.email.content.linkFontStyle;
      previewEmail.contentBackgroundColor =
        localizedEmailContent.email.content.contentBackgroundColor;
      previewEmail.bodyBackgroundColor = localizedEmailContent.email.content.bodyBackgroundColor;
    }

    return previewEmail;
  }

  async createLocalizedEmailContent(localizedEmailContent, existingContentBlocks = null) {
    let previewEmailContent = this._createModel(
      'emails/localized-email-content',
      localizedEmailContentId,
    );

    let { defaultTemplateId, defaultTemplateType } = this._getDefaultTemplateIdAndType();
    previewEmailContent.emailTemplateId = isPresent(localizedEmailContent?.emailTemplateId)
      ? Number(localizedEmailContent.emailTemplateId)
      : defaultTemplateId;
    previewEmailContent.emailTemplateType = isPresent(localizedEmailContent?.emailTemplateType)
      ? Number(localizedEmailContent.emailTemplateType)
      : defaultTemplateType;
    previewEmailContent.senderId = localizedEmailContent?.senderId || this.app.currentAdmin.id;
    previewEmailContent.senderType = localizedEmailContent?.senderType || senderTypes.admin;

    let template = await getTemplate(
      previewEmailContent.emailTemplateType,
      previewEmailContent.emailTemplateId,
      this.store,
    );

    if (existingContentBlocks) {
      previewEmailContent.blocks = this.contentHandler.getBlocksForTemplate(
        existingContentBlocks,
        template,
      );
    } else if (!template.isPremade && template.contentBlocks && template.contentBlocks.length > 0) {
      previewEmailContent.blocks = this.contentHandler.createFragments(template.contentBlocks);
    } else {
      previewEmailContent.blocks = this.contentHandler.getBlocksForTemplate(
        defaultContentBlock(this.app),
        template,
      );
    }
    return previewEmailContent;
  }
}
