/* RESPONSIBLE TEAM: team-reporting */
import moment from 'moment-timezone';

const MAX_RANGE_DAYS = {
  hour: 12 * 7,
  day: 365,
};

const MAX_RANGE_DAYS_FOR_TABLE = {
  hour: 7,
  day: 365,
};

export function closestSafeIntervalFor(interval, days, chartType) {
  let safeInterval = interval;
  if (!isWithinSafeLimit(safeInterval, days, chartType)) {
    if (safeInterval === 'hour') {
      safeInterval = 'day';
    } else if (safeInterval === 'day') {
      safeInterval = 'week';
    }
  }

  return safeInterval;
}

export function rangeInDays(startMoment, endMoment) {
  return Math.round(moment.duration(endMoment - startMoment).asDays());
}

export function isWithinSafeLimit(interval, days, chartType) {
  if (chartType === 'tabular') {
    return days <= MAX_RANGE_DAYS_FOR_TABLE[interval];
  } else {
    return days <= MAX_RANGE_DAYS[interval];
  }
}
