/* RESPONSIBLE TEAM: team-frontend-tech */
import * as jsonlint from '@prantlf/jsonlint';

async function maybeImportCodeMirror() {
  if (!window.CodeMirror) {
    let CodeMirror = (await import('codemirror/lib/codemirror.js')).default;
    window.CodeMirror = CodeMirror;

    await Promise.all([
      import('codemirror/mode/htmlmixed/htmlmixed.js'),
      import('codemirror/mode/xml/xml.js'),
      import('codemirror/mode/css/css.js'),
      import('codemirror/mode/javascript/javascript.js'),
      import('codemirror/addon/lint/lint.js'),
    ]);

    CodeMirror.registerHelper('lint', 'json', function (text) {
      let found = [];
      try {
        jsonlint.parse(text);
      } catch (e) {
        let column = e.location.start.column;
        let line = e.location.start.line - 1;
        let errorMessage = e.message.split(/\r?\n/).pop();
        found.push({
          from: CodeMirror.Pos(line, column),
          to: CodeMirror.Pos(line, column),
          message: errorMessage,
        });
      }
      return found;
    });
  }
}

export { maybeImportCodeMirror };
