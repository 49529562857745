/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
export default Object.freeze({
  any: { label: 'one or a combination of user ID and email', value: 'any' },
  user_id: { label: 'only user ID', value: 'user_id' },
  email: { label: 'only email', value: 'email' },
  user_id_and_email: { label: 'both user ID and email', value: 'user_id_and_email' },
});
