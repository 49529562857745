/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { select } from 'd3-selection';
import { axisBottom, axisLeft } from 'd3-axis';
import 'd3-transition';
import { formatSeconds } from 'embercom/lib/duration-formatter';

export class BubbleChartAxes {
  axesElements = [];
  axesVisible = false;
  topLevelGroup;

  init(element) {
    this.topLevelGroup = select(element).select('g');
  }

  render(width, height, scaleX, scaleY) {
    let axesOpacity = this.axesVisible ? 1 : 0;

    let xAxis = axisBottom(scaleX)
      .ticks(10)
      .tickSize(0)
      .tickPadding(16)
      .tickFormat(function (d) {
        return formatSeconds(d);
      });
    let yAxis = axisLeft(scaleY)
      .ticks(4)
      .tickSize(-width)
      .tickPadding(16)
      .tickFormat(function (d) {
        return formatSeconds(d);
      });

    this.axesElements.forEach((element) => element.remove());
    this.axesElements = [
      this.topLevelGroup
        .append('g')
        .attr('transform', `translate(0,${height})`)
        .call(xAxis)
        .attr('class', 'reporting__bubble-chart__axis')
        .style('opacity', axesOpacity)
        .attr('data-test-bubble-chart-bottom-axis', 'true')
        .lower(),
      // Add X axis label:
      this.topLevelGroup
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('x', width / 2)
        .attr('y', height + 56)
        .text('Median first response time'.toUpperCase())
        .attr('class', 'reporting__bubble-chart__axis-label')
        .style('opacity', axesOpacity)
        .attr('data-test-bubble-chart-bottom-axis-label', 'true')
        .lower(),
      this.topLevelGroup
        .append('g')
        .call(yAxis)
        .attr('class', 'reporting__bubble-chart__axis')
        .style('opacity', axesOpacity)
        .attr('data-test-bubble-chart-left-axis', 'true')
        .lower(),
      // Add Y axis label:
      this.topLevelGroup
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('x', -height / 2 - 84)
        .attr('y', -72)
        .attr('transform', 'rotate(-90)')
        .text('Median time to close'.toUpperCase())
        .attr('text-anchor', 'start')
        .attr('class', 'reporting__bubble-chart__axis-label')
        .style('opacity', axesOpacity)
        .attr('data-test-bubble-chart-left-axis-label', 'true')
        .lower(),
    ];
    // Remove bottom axis first tick
    this.axesElements[0].select('.tick:nth-child(2)').remove();
  }

  toggleAxes(showAxes) {
    this.axesVisible = showAxes;
    if (showAxes) {
      // Fade in the axes
      this.axesElements.forEach((e) => {
        e.transition().delay(250).duration(500).style('opacity', 1);
      });
    } else {
      // Fade away axes
      this.axesElements.forEach((e) => {
        e.transition().duration(500).style('opacity', 0);
      });
    }
  }
}
