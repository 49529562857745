/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
export default function (list, oxford = true, quoted = true) {
  let formattedList = list.reduce((formattedList, item, index, list) => {
    if (list.length > 1 && index === list.length - 1) {
      return formattedList.concat(quoted ? `and "${item}"` : `and ${item}`);
    } else {
      let formattedItem = '';
      if (list.length === 1) {
        formattedItem = quoted ? `"${item}"` : `${item}`;
      } else if (list.length === 2 || (!oxford && index === list.length - 2)) {
        formattedItem = quoted ? `"${item}" ` : `${item} `;
      } else {
        formattedItem = quoted ? `"${item}", ` : `${item}, `;
      }
      return formattedList.concat(formattedItem);
    }
  }, []);

  return formattedList.join('');
}
