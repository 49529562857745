/* RESPONSIBLE TEAM: team-frontend-tech */
import Helper from '@ember/component/helper';
import { startSurvey } from 'embercom/lib/intercom-widget-helper';

export default class StartSurveyHelper extends Helper<{
  Args: {
    Positional: [number];
  };
  Return: () => void;
}> {
  compute([surveyId]: [number]) {
    return () => {
      startSurvey(surveyId);
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'start-survey': typeof StartSurveyHelper;
  }
}
