/* RESPONSIBLE TEAM: team-help-desk-experience */
import { helper } from '@ember/component/helper';
import { assert } from '@ember/debug';

type HandlerFn = (event: MouseEvent) => unknown;

export interface SimpleClickSignature {
  Args: {
    Positional: [HandlerFn];
  };
  Return: (event: MouseEvent) => void;
}

// Only execute a click action if it's "simple" and the user doesn't
// also have alt/meta/ctrl also pressed, so that we don't preventDefault
// etc if the user really wants to open the link in a new tab/window
//
// <a
//  {{on "click" (simple-click (prevent-default this.doSomething))}}>
//  href=...
export function simpleClick([handler]: [HandlerFn]) {
  assert(
    `Expected '${handler}' to be a function, if present.`,
    !handler || typeof handler === 'function',
  );

  return function (event: MouseEvent) {
    if (event.altKey || event.metaKey || event.ctrlKey) {
      return;
    }

    if (handler) {
      handler(event);
    }
  };
}

const SimpleClickHelper = helper<SimpleClickSignature>(simpleClick);
export default SimpleClickHelper;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'simple-click': typeof SimpleClickHelper;
  }
}
