/* RESPONSIBLE TEAM: team-reporting */
import moment from 'moment-timezone';
// Note: can be extracted into subclass per range, and might fit into "axis" or a "formatter" class (formatTooltipDate fn)
export default class {
  constructor(interval) {
    this.interval = interval;
  }

  datePart(date, timezone, endDate = undefined) {
    let format = this.isIntervalHourly || this.isIntervalDaily ? 'MMM D, ddd' : 'MMM D';
    let datePart = moment.tz(date, timezone).format(format);

    if (this.isIntervalDaily) {
      return datePart;
    }
    if (this.isIntervalHourly) {
      return `${datePart} ${moment.tz(date, timezone).format('HH:mm')}`;
    }

    if (endDate) {
      return `${datePart} - ${moment.tz(endDate, timezone).format(format)}`;
    }
    if (this.isIntervalWeekly) {
      return `${datePart} - ${moment.tz(date, timezone).add(6, 'days').format(format)}`;
    }

    if (this.isIntervalMonthly) {
      return `${datePart} - ${moment.tz(date, timezone).endOf('month').format(format)}`;
    }

    return datePart;
  }

  get isIntervalHourly() {
    return this.interval === 'hour';
  }

  get isIntervalDaily() {
    return this.interval === 'day';
  }

  get isIntervalWeekly() {
    return this.interval === 'week';
  }

  get isIntervalMonthly() {
    return this.interval === 'month';
  }
}
