/* RESPONSIBLE TEAM: team-tickets-1 */
import SyncedList from 'embercom/lib/inbox/synced-list';
import { debounce, cancel } from '@ember/runloop';
import ENV from 'embercom/config/environment';

export default SyncedList.extend({
  pollInterval: ENV.APP._30000MS,
  isPolling: false,

  async sync() {
    if (this.isPolling) {
      this._pollDebounce = debounce(this, this.poll, this.pollInterval);
    }

    await this._super();
  },

  startPoll() {
    if (!this.isPolling) {
      this.set('isPolling', true);
      this._pollDebounce = debounce(this, this.poll, this.pollInterval);
    }
  },

  stopPoll() {
    this.set('isPolling', false);
    cancel(this._pollDebounce);
  },

  willDestroy() {
    this.stopPoll();
    return this._super();
  },

  poll() {
    this.sync().catch((err) => {
      if (err.name === 'TaskCancelation') {
        return; // Ignore cancellation errors, can happen when the conversation is unloaded
      }

      throw err;
    });
  },
});
