/* RESPONSIBLE TEAM: team-knowledge-interop */

import { inject as service } from '@ember/service';
import { post } from 'embercom/lib/ajax';
import { type EntityType } from 'embercom/models/data/entity-types';
import {
  type RoleType,
  type MatchBehavior,
  type State,
  type Statistic,
} from 'embercom/models/data/matching-system/matching-constants';
import { type SenderType } from 'embercom/models/data/outbound/types';
import { type Status } from 'embercom/models/data/content-service/content-review-statuses';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import { type ContentWrapperType } from 'embercom/models/content-service/content-wrapper';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';
import { setOwner } from '@ember/application';
import { type SortDirection } from 'embercom/lib/knowledge-hub/constants';

type SearchPredicate = {
  attribute?: string;
  comparison?: string;
  value?: any;
  type: string;
};

export interface ContentServiceSearchResponse<TContentWrapper = ContentWrapper> {
  contentWrappers: Array<TContentWrapper>;
  lastPageHit: boolean;
  totalPages: number;
  totalCount: number;
  pageFrom: number;
  perPage: number;
}

export interface ContentSearchParams {
  app_id: string;
  object_types: Array<EntityType>;
  title?: string;
  content_terms?: string;
  sender_type?: SenderType;
  sender_id?: string | number;
  sender_ids?: Array<string | number>;
  audience_types?: Array<RoleType>;
  states?: Array<State>;
  locales?: Array<string>;
  tag_ids?: Array<string | number>;
  tag_operator?: 'in' | 'nin' | 'IN' | 'NIN';
  match_behavior?: MatchBehavior;
  match_behaviors?: Array<MatchBehavior>;
  series_id?: string | number;
  hide_series?: boolean;
  any_tag_ids?: Array<string | number>;
  subscription_type_id?: number;
  per_page?: number;
  page_from?: number;
  sort_by?: string;
  sort_direction?: SortDirection;
  sort_by_content_type_first?: EntityType;
  statistics?: Array<Statistic>;
  wildcard_title_search?: boolean;
  additional_searchable_data?: Record<string, any>;
  predicates?: Array<SearchPredicate>;
  content_wrapper_types?: Array<ContentWrapperType>;
  content_review_statuses?: Array<Status>;
  folder_ids?: string[];
  folder_entity_type?: EntityType;
  last_updated_by_ids?: Array<string | number>;
  created_by_ids?: Array<string | number>;
  folder_filter_option?: FolderFilterOption;
  needs_review?: SyncIssuesFilterOptions;
  copilot_state?: number;
  chatbot_state?: number;
  add_highlight?: boolean;
  collection_ids?: Array<string | number>;
}

export default class ContentServiceSearchAPI {
  constructor(owner: unknown) {
    setOwner(this, owner);
  }

  @service declare notificationsService: any;
  @service declare store: Store;
  @service declare intl: IntlService;

  async contentSearch<TContentWrapper = ContentWrapper>(
    searchParams: ContentSearchParams,
    _contentWrapperModel = 'content-service/content-wrapper',
  ): Promise<ContentServiceSearchResponse<TContentWrapper>> {
    let response = (await this.search(searchParams)) as {
      content_wrappers: Array<Record<string, any>>;
      page_from: number;
      per_page: number;
      total_count: number;
      total_pages: number;
      last_page_hit: boolean;
    };

    return {
      contentWrappers: this.generateContentWrapperModels<TContentWrapper>(
        response.content_wrappers,
        _contentWrapperModel,
      ),
      pageFrom: response.page_from,
      perPage: response.per_page,
      totalCount: response.total_count,
      totalPages: response.total_pages,
      lastPageHit: response.last_page_hit,
    };
  }

  private generateContentWrapperModels<TContentWrapper>(
    objects: Array<any> = [],
    _contentWrapperModel: string,
  ): Array<TContentWrapper> {
    return objects.map((contentWrapper) => {
      let normalizedObject = this.store.normalize(_contentWrapperModel, contentWrapper);
      return this.store.push(normalizedObject) as TContentWrapper;
    });
  }

  private search(params: ContentSearchParams) {
    return post('/ember/content_service/contents/search', params).catch(() => {
      this.notificationsService.notifyError(this.intl.t('content-service.search-error'));
      return {};
    });
  }
}

// Matches ContentService::Constants::KnowledgeHub::FolderFilterOptions in intercom
export enum FolderFilterOption {
  Any = 'any',
  None = 'none',
}

// Matches ContentService::Constants::KnowledgeHub::SyncIssuesFilterOptions in intercom
export enum SyncIssuesFilterOptions {
  HasSyncIssues = 'has_sync_issues',
  NoSyncIssues = 'no_sync_issues',
}
