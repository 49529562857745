/* RESPONSIBLE TEAM: team-reporting */
import { helper } from '@ember/component/helper';
import generateUUID from 'embercom/lib/uuid-generator';

function generateId([toAppend]: [string | undefined]) {
  let id = generateUUID();
  if (toAppend === undefined) {
    return id;
  } else {
    return `${id}_${toAppend}`;
  }
}

export default helper(generateId);
