/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
// @ts-nocheck
import {
  EMOJI_TYPEAHEAD,
  type ComposerConfig,
  type InitialSelection,
  type TextDirection,
} from '@intercom/embercom-prosemirror-composer';
import EmbercomFileUploader from '../../articles/embercom-file-uploader';
import { type CanvasNetworkClient } from '@intercom/interblocks.ts';
import {
  INPUT_RULE_CODE_BLOCK,
  INPUT_RULE_ORDERED_LIST,
  INPUT_RULE_UNORDERED_LIST,
  INPUT_RULE_INLINE_CODE,
  INPUT_RULE_EMOJI,
} from '@intercom/embercom-prosemirror-composer';

import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
import { triggerCharacter } from '@intercom/embercom-prosemirror-composer/lib/composer/typeahead/trigger-character';
import { EmbercomCanvasNetworkClient } from 'embercom/objects/composer/embercom-canvas-network-client';

const SAVED_REPLY_TYPEAHEAD = {
  name: 'saved_reply',
  matcher: triggerCharacter('#', { allowSpaces: true }),
  component: 'conversation/composer/saved-reply-typeahead',
  attrs: {},
  // eslint-disable-next-line no-undef
} as TypeaheadConfig;
export default class SavedRepliesComposerConfig implements ComposerConfig {
  upload;
  attributes;
  templating;

  debug = false;
  renderOverlaysInPlace = false;
  autoFocus = false;

  tools = [
    { name: 'text-formatter' },
    { name: 'anchor-editor' },
    { name: 'template-inserter' },
    { name: 'fallback-editor' },
  ];
  typeaheads = [EMOJI_TYPEAHEAD];

  textDirection = 'auto' as TextDirection;
  placeholder = 'Write a reply (optional)';
  initialSelectionLocation = 'start' as InitialSelection;
  canvasNetworkClient: CanvasNetworkClient;

  allowedBlocks = [
    'paragraph',
    'image',
    'orderedList',
    'unorderedList',
    'heading',
    'subheading',
    'codeBlock',
  ];

  allowedInline = ['bold', 'italic', 'anchor', 'code'];

  inputRules = [
    INPUT_RULE_CODE_BLOCK,
    INPUT_RULE_INLINE_CODE,
    INPUT_RULE_ORDERED_LIST,
    INPUT_RULE_UNORDERED_LIST,
    INPUT_RULE_EMOJI,
  ];

  constructor(app: any, macro: any, allowUploads: boolean, autoFocus: boolean) {
    this.configureUploads(app.id, allowUploads);
    this.canvasNetworkClient = new EmbercomCanvasNetworkClient(app.id);
    this.allowedBlocks.push('messengerCard');
    this.autoFocus = autoFocus;
    this.attributes = app.allowedAttributes;
    this.typeaheads.push(
      Object.assign({}, SAVED_REPLY_TYPEAHEAD, {
        attrs: { conversation: null, macro, activePane: 'comment', bulkInsert: true },
      }),
    );

    this.templating = {
      picker: 'common/attribute-picker',
      resolver: new AttributeInfoResolver({ attributes: this.attributes }),
    };
  }

  private configureUploads(appId: string, allowUploads: boolean) {
    if (allowUploads) {
      this.upload = {
        allowedImageFileTypes: ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'],
        allowedAttachmentTypes: ['*'],
        uploader: EmbercomFileUploader,
        attrs: { policyUrl: `/apps/${appId}/uploads` },
      };
    }
  }
}
