/* RESPONSIBLE TEAM: team-growth-opportunities */
export default function parseTextToJson(text) {
  if (!text) {
    return;
  }
  if (typeof text === 'string') {
    return parseTextToJson(JSON.parse(text));
  }

  return text;
}
