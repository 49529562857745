/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
export default {
  fullDateAndTime: 'MMM D, YYYY [at] h:mmA z',
  fullDateAndTimeWithoutTimezone: 'MMM D, YYYY [at] h:mmA',
  fullDateAndTimeWithoutTimezoneWithDayName: 'dddd MMM D, YYYY [at] h:mmA',
  fullDateAndTimeWithoutTimezoneAndYear: 'MMM D, [at] h:mmA',
  dateAndTimeWithoutYearWithShortDayName: 'h:mmA on ddd, MMM D',
  date: 'MMM D, YYYY', // tip: when moving to formatDate, the equivalent options are { day: 'numeric', month: 'short', year: 'numeric' }
  monthDayYear: 'L',
  dateHumanized: 'MMMM Do, YYYY',
  dateHumanizedWithoutYear: 'MMMM Do',
  time: 'h:mmA z',
  timeWithoutTimezone: 'h:mmA',
  dateWithDayWithoutYear: 'ddd MMM D',
  dateWithoutYear: 'MMM D',
  monthAndYear: 'MMM YYYY',
  emailTemplateRenderDateFormat: 'Do MMM YYYY',
  timeOnDay: 'h:mm A [on] MMMM D',
  dayOfWeek: 'ddd',
  dateWithStops: 'YYYY.MM.DD',
};
