/* RESPONSIBLE TEAM: team-frontend-tech */
export function initialize(applicationInstance) {
  window.EmberApplicationInstance = applicationInstance;

  applicationInstance.reopen({
    willDestroy() {
      window.EmberApplicationInstance = null;
      return this._super(...arguments);
    },
  });
}

export default {
  name: 'export-global-application-instance',
  initialize,
};
