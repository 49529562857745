/* RESPONSIBLE TEAM: team-help-desk-experience */
import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import type IntlService from 'embercom/services/intl';

enum RelativeDateUnit {
  Second = 'second',
  Minute = 'minute',
  Hour = 'hour',
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

export default class FormatRelativeTimestamp extends Helper {
  @service declare intl: IntlService;

  compute([timestamp, kwargs]: [Date, { now?: Date; displaySeconds?: boolean } | undefined]):
    | string
    | undefined {
    /**
     * Calculates a best fit unit and delta value to display the timestamp time delta from now by
     * finding the most significant unit of time with an abs(moment#diff) > a per-unit threshold.
     *
     * If delta < 45 seconds then we display "Now".
     *
     * We round to 0 dp in the delta unit, so a delta of 1.49 minutes becomes "1 min", a delta of
     * 1.5 minutes becomes "2 min" etc.
     *
     * The now param is only exposed for testing purposes.
     */
    let smallestUnit =
      kwargs?.displaySeconds ?? false ? RelativeDateUnit.Second : RelativeDateUnit.Minute;
    let currentTime = moment(kwargs?.now).tz(moment.tz.guess());
    let otherTime = moment(timestamp).tz(moment.tz.guess());
    for (let unit of [
      RelativeDateUnit.Year,
      RelativeDateUnit.Month,
      RelativeDateUnit.Week,
      RelativeDateUnit.Day,
      RelativeDateUnit.Hour,
      RelativeDateUnit.Minute,
      RelativeDateUnit.Second,
    ]) {
      let timeDelta = Math.abs(otherTime.diff(currentTime, unit, true));
      if (timeDelta >= unitThreshold(unit)) {
        let roundedDelta = Math.round(timeDelta);
        if (isEnglish(this.intl.primaryLocale)) {
          return englishUnitString(roundedDelta, unit);
        }
        return this.intl.formatNumber(roundedDelta, {
          style: 'unit',
          unitDisplay: 'short',
          unit,
        });
      } else if (unit === smallestUnit) {
        return this.intl.t('inbox.common.timestamp-now');
      }
    }
    return undefined;
  }
}

function englishUnitString(value: number, unit: RelativeDateUnit): string {
  switch (unit) {
    case RelativeDateUnit.Second:
      return `${value}s`;
    case RelativeDateUnit.Minute:
      return `${value}min`;
    case RelativeDateUnit.Hour:
      return `${value}h`;
    case RelativeDateUnit.Day:
      return `${value}d`;
    case RelativeDateUnit.Week:
      return `${value}w`;
    case RelativeDateUnit.Month:
      return `${value}mo`;
    case RelativeDateUnit.Year:
      return `${value}y`;
  }
}

function isEnglish(locale: string): boolean {
  return locale.startsWith('en');
}

function unitThreshold(unit: RelativeDateUnit): number {
  /**
   * These rounding thresholds are broadly similar to those used by moment JS:
   * https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/02-fromnow/
   * except we don't normalise to the same units moment js uses for thresholds, here
   * we are just using fractions of the delta unit for simplicity.
   *
   * They dictate the minimum required delta values for the display of each unit.
   * I.E. the delta won't be displayed in weeks unless it is at least 6 days.
   *      the delta won't be displayed in seconds unless it is at least 10 seconds.
   *      etc.
   */
  switch (unit) {
    case RelativeDateUnit.Week:
      return 6 / 7; // 6 days
    case RelativeDateUnit.Hour:
      return 45 / 60; // 45 minutes
    case RelativeDateUnit.Month:
      return 25 / 31; // 25 days (approx.)
    case RelativeDateUnit.Day:
      return 22 / 24; // 22 hours
    case RelativeDateUnit.Year:
      return 320 / 365; // 320 days (approx.)
    case RelativeDateUnit.Minute:
      return 45 / 60; // 45 seconds
    case RelativeDateUnit.Second:
      return 10; // 10 seconds
  }
}
