/* RESPONSIBLE TEAM: team-frontend-tech */
let numericFormatter = function (value, precision, useFixedPrecision) {
  precision = precision || 0;

  if (!value) {
    return precision ? `0.${new Array(precision).fill('0').join('')}` : '0';
  }

  let power = Math.pow(10, precision);
  let number = useFixedPrecision ? value.toFixed(precision) : Math.round(value * power) / power;
  let tokens = number.toString().split('.');
  let integerPart = tokens[0].split('').reduceRight(function (
    previousValue,
    currentValue,
    index,
    array,
  ) {
    if (currentValue === '-' && index === 0) {
      return currentValue + previousValue;
    }
    let position = array.length - index - 1;
    return currentValue + (position && position % 3 === 0 ? ',' : '') + previousValue;
  }, '');
  let fractionalPart = tokens[1] ? `.${tokens[1]}` : '';
  return integerPart + fractionalPart;
};

export default numericFormatter;
