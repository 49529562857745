/* RESPONSIBLE TEAM: team-phone */
import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

interface Signature {
  Args: {
    Positional: [string, string?];
  };
  Return: any;
}

export default class PermissionsHierarchy extends Helper<Signature> {
  @service declare permissionsHierarchyService: $TSFixMe;

  compute([permissionKey, object]: [string, string?]): any {
    if (isEmpty(object)) {
      return this.permissionsHierarchyService.permissionsHierarchy[permissionKey];
    } else {
      return this.permissionsHierarchyService.permissionsHierarchy[permissionKey][object!];
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'permissions-hierarchy': typeof PermissionsHierarchy;
  }
}
