/* RESPONSIBLE TEAM: team-frontend-tech */
import FormatNumberHelper from 'ember-intl/helpers/format-number';

export default class CustomFormatNumberHelper extends FormatNumberHelper {
  compute([value, positionalOptions], namedOptions) {
    let options = positionalOptions
      ? Object.assign({}, positionalOptions, namedOptions)
      : namedOptions;

    return this.format(value, options);
  }
}
