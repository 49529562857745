/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
/* eslint-disable @intercom/intercom/no-bare-strings */
import moment from 'moment-timezone';
import ajax from 'embercom/lib/ajax';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { chunk } from 'ember-composable-helpers/helpers/chunk';
import PollingSyncedList from 'embercom/lib/inbox/polling-synced-list';
import { findBy, isAny } from '@intercom/pulse/lib/computed-properties';
import conversationPartCompacter from 'embercom/helpers/conversation-part-compacter';
import containerLookup, { getEmberDataStore } from 'embercom/lib/container-lookup';

// Calculated by:
// - Max URL Length: 2000 (https://stackoverflow.com/q/417142/10879496)
// - Base: "https://app.intercom.com/ember/conversation_parts.json?".length == 54
// - Per Id: "ids%5B%5D=comment-XXXXXXXXXXX-XXXXXXXXX&".length == 40
//
//    N = (Max URL length - Base) / Per Id
//    N = (2000 - 54) / 40
//    N = 48.65 = 45 (rounded down to allow for margin of error)
//
export const MAX_CONVERSATION_PART_IDS_PER_REQUEST = 45;

// The age a part can be before we unload it from the store. The reason for
// the timeout before unloading is because we could potentially unload a part
// that is still required by an async operation. This is a temporary workaround
// until we fix the conversation memory problem.
export const PART_UNLOAD_AGE_IN_MINUTES = 3;

// Threshold for URL with an expires query param about to expire (5 minutes in ms).
const URL_ABOUT_TO_EXPIRE_THRESHOLD = 300;

export default PollingSyncedList.extend({
  pageSize: 25,
  sortKey: ['isNew:asc', 'timestamp:asc', 'id:asc'],

  appService: computed(function () {
    return containerLookup('service:appService');
  }),
  app: readOnly('appService.app'),
  conversation: null,
  parts: readOnly('items'),
  sortedParts: readOnly('sortedItems'),
  sortedPartsReversed: computed('sortedParts.[]', function () {
    return this.sortedParts.slice().reverse();
  }),

  hasInitialMessagePart: isAny('sortedParts', 'isInitialMessage'),
  lastPart: readOnly('sortedParts.lastObject'),
  lastUserPart: findBy('sortedPartsReversed', 'isUserPart'),
  lastAssignmentPart: findBy('sortedPartsReversed', 'isAssignment'),
  lastActionPart: findBy('sortedPartsReversed', 'isAction'),
  lastStateChangePart: findBy('sortedPartsReversed', 'isStateChange'),
  lastPriorityChangePart: findBy('sortedPartsReversed', 'isPriorityChanger'),
  lastPartWithContentExcludingOperator: computed(
    'sortedPartsReversed.@each.{hasAnyContent,isNote,isOperatorPart}',
    function () {
      return this.sortedPartsReversed.find(
        (part) => part.hasAnyContent && (part.isNote || !part.isOperatorPart),
      );
    },
  ),

  isLoadingUntilPart: false,

  async fetchInitialItems(windowSize) {
    let remoteState = await this.fetchRemoteState(windowSize);
    let addedItemsIds = this.diffState(this.getCurrentState(), remoteState).addedItemsIds;
    return await this.loadItemsFromNetwork(addedItemsIds, true);
  },

  async fetchRemoteState(windowSize) {
    return await ajax({
      url: '/ember/conversation_parts/sync_data.json',
      type: 'GET',
      data: {
        window_size: windowSize,
        app_id: this.conversation.app_id,
        conversation_id: this.conversation.id,
      },
    });
  },

  async fetchItemsById(ids) {
    return this.loadItemsFromNetwork(ids, false);
  },

  async loadItemsFromNetwork(ids, fromUserInteraction) {
    let responses = await Promise.all(
      chunk(MAX_CONVERSATION_PART_IDS_PER_REQUEST, ids).map((ids) =>
        ajax({
          url: '/ember/conversation_parts.json',
          type: 'GET',
          data: {
            from_user_interaction: fromUserInteraction,
            ids,
            app_id: this.conversation.app_id,
          },
        }),
      ),
    );
    let store = getEmberDataStore();
    let data = responses.flatMap((data) => data.conversation_parts);
    store.pushPayload({ 'conversation-parts': data });
    return data.map((d) => {
      return store.peekRecord('conversation-part', d.id);
    });
  },

  async sortBy() {
    throw new Error('ConversationStreamSyncedList does not support custom sorting');
  },

  async loadMoreUntilPart(partId) {
    if (this.sortedParts.findBy('id', partId) || this.hasInitialMessagePart) {
      this.set('isLoadingUntilPart', false);
      return;
    }

    this.set('isLoadingUntilPart', true);
    await this.loadMore();
    await this.loadMoreUntilPart(partId);
  },

  diffState(localState, remoteState) {
    let { addedItemsIds, updatedItemsIds, removedItemsIds } = this._super(localState, remoteState);

    let expiringItemsIds = localState
      .filter((localTuple) => this.partHasAboutToExpireUpload(localTuple[0]))
      .map((localTuple) => localTuple[0]);

    updatedItemsIds = updatedItemsIds.concat(expiringItemsIds).uniq();

    return {
      addedItemsIds,
      updatedItemsIds,
      removedItemsIds,
    };
  },

  partHasAboutToExpireUpload(partId) {
    let part = this.parts?.find((part) => part.id === partId);

    if (part?.hasUploads) {
      let uploadURLs = part.uploads.map((upload) => upload.url);

      part.blocks
        .filter((block) => block.type === 'image')
        .forEach((block) => uploadURLs.push(block.url));

      part.blocks
        .filter((block) => block.type === 'attachmentList')
        .forEach((block) =>
          block.attachments.forEach((attachment) => uploadURLs.push(attachment.url)),
        );

      return uploadURLs.some((url) => aboutToExpire(url));
    }
    return false;
  },

  addItems(addedItems) {
    // Special case: if the amount of items the list wants to add is equivalent to the window size, we
    // assume the list is in a bad state and remove *all* items. The rationale behind this is that if the
    // gap in the list (most recent part in the local list to the oldest part returned from the remote) between
    // syncs is so great that there is no intersection, we should defensively clear out the list to ensure
    // we are in a correct state. This case can happen when a user goes offline.
    if (this.items.length > 0 && addedItems.length >= this.windowSize) {
      this.clear();
    }

    // Handle the race condition where we create a conversation part but syncing returns the part
    // faster than the create conversation part request does. In this case, we remove the locally
    // created part model instance from the list in favor of the part model instance returned from syncing.
    let clientAssignedUUIDs = addedItems.map(getClientAssignedUUID).filter((id) => id);
    let staleParts = this.parts.filter((part) =>
      clientAssignedUUIDs.includes(getClientAssignedUUID(part)),
    );

    if (staleParts.length) {
      this.parts.removeObjects(staleParts);
    }

    addedItems.forEach((part) => part.set('shouldFadeIn', true));
    this.parts.forEach((part) =>
      part.setProperties({
        isImmediateSend: false,
        shouldFadeIn: false,
      }),
    );

    this._super(addedItems);
    this.compactParts();
    this.updatePartsSeenState();

    let newerParts = addedItems.filter((part) => part.created_at > this.conversation.updated_at);
    this.updateConversation(newerParts);

    // We always want to sync the window size or more
    this.set('windowSize', Math.max(this.pageSize, this.items.length));
  },

  updateItems() {
    // noop, part models are updated in place by Ember Model so the list has no work to do to update items
  },

  removeItems() {
    // noop, we never want to remove parts during syncing from the list since parts cannot
    // be deleted or reordered. It will force the window (windowSize) to always continue to grow.
  },

  addParts(parts) {
    this.addItems(parts);
  },

  removePart(part) {
    this.parts.removeObject(part);
    this.updateConversation();
  },

  unload() {
    this.sortedParts.forEach((part) => {
      part.unloadRecord();
    });

    this.clear();
  },

  compactParts() {
    let sortedParts = this.sortedParts;
    let memoizedProperties = this._memoizedCompactionProperties;
    this.set(
      '_memoizedCompactionProperties',
      conversationPartCompacter(sortedParts, memoizedProperties),
    );
  },

  updateConversation(newerParts = []) {
    // Conversation side effects from new or removed parts
    this.updateConversationMetaParts();
    this.updateConversationTimerUnsnoozeBadge();
    this.updateConversationParticipants(newerParts);
    this.updateConversationTimestamps();
  },

  updateConversationMetaParts() {
    this.conversation.setProperties(
      compactObject({
        lastPart: this.lastPart,
        lastActionPart: this.lastActionPart,
        lastPartWithContentExcludingOperator: this.lastPartWithContentExcludingOperator,
      }),
    );
  },

  updateConversationTimerUnsnoozeBadge() {
    if (!this.get('lastPart.isTimerUnsnoozer') && this.conversation.last_snoozed_at) {
      this.conversation.set('last_snoozed_at', null);
    }
  },

  updateConversationParticipants(newerParts) {
    if (newerParts.any((part) => part.isParticipantAdded || part.isParticipantRemoved)) {
      this.conversation.reload();
    }
  },

  updateConversationTimestamps() {
    this.conversation._setUpdatedAt();
    this.conversation._setSortingUpdatedAt();
  },

  updatePartsSeenState() {
    if (this.lastUserPart) {
      this.sortedParts.forEach((part) => {
        if (
          part.is_after_last_user_comment &&
          moment(part.created_at).isBefore(this.lastUserPart.created_at)
        ) {
          part.set('is_after_last_user_comment', false);
        }
      });
    }
  },

  willDestroy() {
    this.unload();
    return this._super();
  },
});

function getClientAssignedUUID(item) {
  return item.get('client_assigned_uuid');
}

function compactObject(obj) {
  return Object.keys(obj).reduce((next, key) => {
    if (obj[key]) {
      next[key] = obj[key];
    }

    return next;
  }, {});
}

function aboutToExpire(url) {
  let parsedURL = new URL(url);
  let expires = parsedURL.searchParams.get('expires');
  return expires ? Math.floor(Date.now() / 1000) + URL_ABOUT_TO_EXPIRE_THRESHOLD >= expires : false;
}
