/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import ENV from 'embercom/config/environment';
import { REALTIME_EVENT_NAMES } from 'embercom/lib/inbox/constants';
import EmberObject from '@ember/object';
import { inject as service } from '@ember/service';
import { cancel, debounce, later } from '@ember/runloop';
import { notImplemented } from '@intercom/pulse/lib/computed-properties';

window.INBOX_REALTIME_LOGGING = false;

export default EmberObject.extend({
  appService: service(),
  realtimeService: service('realTimeEventService'),
  store: service(),
  inbox: notImplemented('inbox is required'),
  isRealtimeEnabled: false,

  willDestroy() {
    this.disable();
  },

  enable() {
    this.set('isRealtimeEnabled', true);

    REALTIME_EVENT_NAMES.forEach((eventName) => {
      this.realtimeService.on(eventName, this, '_handleInboxEvent');
    });

    this.realtimeService.on('UserBlocked', this, '_handleUserBlocked');

    this.schedulePoll();
  },

  disable() {
    this.set('isRealtimeEnabled', false);
    this._cancelPollTimer();

    REALTIME_EVENT_NAMES.forEach((eventName) => {
      this.realtimeService.off(eventName, this, '_handleInboxEvent');
    });

    this.realtimeService.off('UserBlocked', this, '_handleUserBlocked');
  },

  trackUpdatesToConversations(conversations) {
    this.realtimeService.subscribeTopics(this._topicIdentifiers(conversations));
  },

  stopTrackingUpdatesToConversations(conversations) {
    this.realtimeService.unsubscribeTopics(this._topicIdentifiers(conversations));
  },

  _handleUserBlocked(event) {
    this.store.findRecord('admin', event.adminId, { backgroundReload: false }).then((admin) => {
      this.inbox.conversationList.items
        .filterBy('main_participant.id', event.userId)
        .forEach((conversation) => {
          conversation.set('userIsBlocked', true);
          conversation.set('userBlockedBy', admin);
          this.inbox.conversationList.markConversationAsTransitioned(conversation);
        });
    });
  },

  _handleInboxEvent(event) {
    if (!this._isEventForCurrentInbox(event)) {
      return;
    }

    this.schedulePoll();
    debounce(this.inbox.sync, 'perform', ENV.APP._2000MS);
  },

  _isEventForCurrentInbox(event) {
    let inbox = this.inbox;

    if (!inbox) {
      return false;
    }

    if (inbox.isMentionsInbox && event.eventName === 'MentionCreated') {
      return true;
    }

    if (this.appService.app?.canUseElasticsearchInboxes) {
      if (event.eventName === 'ThreadIngested' && !inbox.isMentionsInbox) {
        return true;
      } else {
        // we don't care about any other types of events for ES powered inboxes
        return false;
      }
    }

    if (inbox.isAllInbox && event.eventName !== 'ThreadIngested') {
      return true;
    }

    let assigneeId = Number(inbox.assigneeId);
    if (Number(event.assigneeId) === assigneeId || Number(event.assigneeIdWas) === assigneeId) {
      return true;
    }

    if (inbox.isInboxView && event.eventName === 'ThreadIngested') {
      return true;
    }

    return false;
  },

  _topicIdentifiers(conversations) {
    return conversations.map((conversation) => {
      return `conversation/${conversation.get('id')}`;
    });
  },

  schedulePoll() {
    // This nasty environment check is needed because otherwise tests will pause forever on this timer
    if (!this.isRealtimeEnabled || (ENV.environment === 'test' && !this.forcePollingInTest)) {
      return;
    }
    this._cancelPollTimer();
    if (!this.isDestroying) {
      this.set(
        'pollingTimer',
        later(null, () => this.inbox.sync.perform(undefined, false), ENV.APP._15000MS),
      );
    }
  },

  _cancelPollTimer() {
    cancel(this.pollingTimer);
  },
});
