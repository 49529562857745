/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
const preferredTicksNumber = 2;
const desiredMaxNumberOfTicks = 5;

function getIntervalByScale(min, max, intervals) {
  let bestInterval = null;
  let diff = max - min;
  intervals.forEach(({ value }) => {
    let potentialNumberOfTicks = Math.floor(diff / value);
    if (
      potentialNumberOfTicks <= desiredMaxNumberOfTicks &&
      potentialNumberOfTicks >= preferredTicksNumber
    ) {
      bestInterval = value;
      return;
    }
  });
  return bestInterval;
}

class ValueAxis {
  intervals = [
    { description: '1 unit', value: 1 },
    { description: '2 units', value: 2 },
    { description: '4 units', value: 4 },
    { description: '5 units', value: 5 },
    { description: '8 units', value: 8 },
    { description: '10 units', value: 10 },
    { description: '50 units', value: 50 },
    { description: '100 units', value: 100 },
    { description: '500 units', value: 500 },
    { description: '1000 units', value: 1000 },
    { description: '5000 units', value: 5000 },
    { description: '10000 units', value: 10000 },
    { description: '50000 units', value: 50000 },
    { description: '100000 units', value: 100000 },
    { description: '500000 units', value: 500000 },
    { description: '1000000 units', value: 1000000 },
    { description: '5000000 units', value: 5000000 },
    { description: '10000000 units', value: 10000000 },
  ];

  interval(min, max) {
    return getIntervalByScale(min, max, this.intervals);
  }
}

class SecondAxis {
  intervals = [
    { description: '1 second', value: 1 },
    { description: '2 seconds', value: 2 },
    { description: '5 seconds', value: 5 },
    { description: '10 seconds', value: 10 },
    { description: '30 seconds', value: 30 },
    { description: '1 minute', value: 60 },
    { description: '5 minutes', value: 60 * 5 },
    { description: '15 minutes', value: 60 * 15 },
    { description: '30 minutes', value: 60 * 30 },
    { description: '45 minutes', value: 60 * 45 },
    { description: '1 hour', value: 60 * 60 },
    { description: '1 hour 30 minutes', value: 60 * 90 },
    { description: '3 hours', value: 60 * 60 * 3 },
    { description: '5 hours', value: 60 * 60 * 5 },
    { description: '10 hours', value: 60 * 60 * 10 },
    { description: '1 day', value: 60 * 60 * 24 },
    { description: '2 days', value: 60 * 60 * 24 * 2 },
    { description: '5 days', value: 60 * 60 * 24 * 5 },
  ];

  interval(min, max) {
    return getIntervalByScale(min, max, this.intervals);
  }
}

class HourAxis {
  labels = Array.from(Array(24), (d, i) => i);
}

class DayAxis {
  labels = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
}

class PercentAxis {
  interval(_min, _max) {
    return;
  }
}

export default class Axis {
  static hour = HourAxis;
  static day = DayAxis;
  static seconds = SecondAxis;
  static value = ValueAxis;
  static valueSingle = ValueAxis;
  static percent = PercentAxis;
  static valuePerHour = ValueAxis;
}
