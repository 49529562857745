/* RESPONSIBLE TEAM: team-proactive-support */
export default function emailStyles({ template, email = undefined }) {
  return {
    contentBackgroundColor:
      email?.contentBackgroundColor || template?.css?.containerBackgroundColor,
    bodyBackgroundColor: email?.bodyBackgroundColor || template?.css?.bodyBackgroundColor,
    linkFontWeight: email?.linkFontWeight,
    linkFontStyle: email?.linkFontStyle,
    linkFontColor: email?.linkFontColor,
    templateLinkFontColor: template?.css?.templateLinkFontColor,
    templateLinkFontWeight: template?.css?.templateLinkFontWeight,
    templateLinkFontStyle: template?.css?.templateLinkFontStyle,
    templateFontColor: template?.css?.templateFontColor,
    templateFontFamily: template?.css?.templateFontFamily,
    h1_font_size: template?.css?.h1_font_size,
    h2_font_size: template?.css?.h2_font_size,
    paragraph_font_size: template?.css?.paragraph_font_size,
    small_font_size: template?.css?.small_font_size,
  };
}
