/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import EmberObject, { computed } from '@ember/object';
import { reads } from '@ember/object/computed';
import TimesByHalfHour from 'embercom/models/data/times-by-half-hour';
import { MONDAY, FRIDAY, SATURDAY, SUNDAY, DAYS } from 'embercom/models/settings/time-period';
import { capitalize } from '@ember/string';
import { titleCase } from 'embercom/helpers/title-case';
import { groupBy } from 'embercom/helpers/group-by';
import { uniqBy } from 'embercom/helpers/uniq-by';
import { DAY_IN_MINUTES } from 'embercom/components/matching-system/matching-timetable-editor';

const NINE_AM = 9 * 60;
const FIVE_PM = 17 * 60;

const IntervalGroup = EmberObject.extend({
  init() {
    this._super(...arguments);
    let startMinute = this.timeIntervals.firstObject.adjustedStartMinute;
    let endMinute = this.timeIntervals.firstObject.adjustedEndMinute;
    this.setProperties({
      startMinute,
      endMinute: startMinute >= endMinute ? undefined : endMinute,
    });
  },
  label: computed('timeIntervals.[]', function () {
    return `${titleCase(
      this.period,
    )} ${mapMinuteToText(this.startMinute)} – ${mapMinuteToText(this.endMinute)}`;
  }),
  untranslatedLabel: computed('timeIntervals.[]', function () {
    return {
      period: this.period,
      time: `${mapMinuteToText(this.startMinute)} – ${mapMinuteToText(this.endMinute)}`,
    };
  }),
  startDay: reads('timeIntervals.firstObject.startDay'),
  endDay: reads('timeIntervals.lastObject.endDay'),
  period: computed('startDay', 'endDay', function () {
    let { startDay, endDay } = this;
    let length = this.timeIntervals.length;
    if (startDay === endDay && startDay >= MONDAY && startDay <= SUNDAY) {
      return DAYS[startDay];
    } else if (startDay === SATURDAY && endDay === SUNDAY) {
      return 'weekends';
    } else if (startDay >= MONDAY && endDay <= FRIDAY && length === 5) {
      return 'weekdays';
    } else if (startDay === MONDAY && endDay === SUNDAY && length === 7) {
      return 'everyday';
    } else if (length - 1 === endDay - startDay) {
      return `${capitalize(DAYS[startDay])} - ${capitalize(DAYS[endDay])}`;
    } else {
      return this.timeIntervals
        .map((interval) => `${capitalize(DAYS[interval.startDay])}`)
        .join(', ');
    }
  }),
});

function mapMinuteToText(minute) {
  let result = TimesByHalfHour.find((time) => time.value === minute);
  return result.text.replace(/:00|\s/g, '');
}

function groupIntervalsByTypeAndStartAndEndTime(intervals) {
  let groups = groupBy(intervals, (item) => {
    return [item.adjustedStartMinute, item.adjustedEndMinute];
  });
  return groups.map((group) => uniqBy(group, (item) => [item.startMinute, item.endMinute]));
}

export function createIntervalGroup(groupedTimeIntervals) {
  let sortedIntervals = groupedTimeIntervals.sortBy('startMinute');
  return IntervalGroup.create({
    originalContent: sortedIntervals,
    timeIntervals: sortedIntervals,
  });
}

export function createIntervalGroupsFromIntervals(timeIntervals) {
  let groupedIntervals = groupIntervalsByTypeAndStartAndEndTime(timeIntervals);
  return groupedIntervals.map((group) => createIntervalGroup(group));
}

export function createDefaultIntervalGroup(store) {
  let weekdays = [];
  for (let day = MONDAY; day <= FRIDAY; day++) {
    let interval = store.createFragment('matching-system/timetable/time-interval', {
      startMinute: NINE_AM + day * DAY_IN_MINUTES,
      endMinute: FIVE_PM + day * DAY_IN_MINUTES,
    });
    weekdays.pushObject(interval);
  }
  return createIntervalGroup(weekdays);
}
