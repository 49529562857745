/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
export function variationDeleteSuccess(variation) {
  return `Version ${variation.get('label')} deleted`;
}

export function variationDeleteFailure(variation) {
  return `We couldn't delete version ${variation.get(
    'label',
  )}. Send us a message and we'll do our best to help out.`;
}

export function variationDeleteOptions(variation) {
  return {
    title: `Delete version ${variation.get('label')}`,
    body: `By deleting version ${variation.get(
      'label',
    )} you will also delete all the statistics associated with this version. Users who received this version will not get this message again. Are you sure you want to delete version ${variation.get(
      'label',
    )}?`,
    confirmButtonText: 'Delete',
    primaryButtonType: 'primary-destructive',
  };
}
