/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import { pluralize } from 'ember-inflector';
import Highcharts from 'highcharts';
import { getIntlService } from 'embercom/lib/container-lookup';

export default class ValueFormatter {
  constructor(displayUnitOrTooltipTranslationKeys) {
    this.intl = getIntlService();
    if (displayUnitOrTooltipTranslationKeys) {
      if (typeof displayUnitOrTooltipTranslationKeys === 'string') {
        this.displayUnit = displayUnitOrTooltipTranslationKeys;
      } else {
        this.tooltipTranslationKeys = displayUnitOrTooltipTranslationKeys;
      }
    }
  }

  formatData(data, precision = 1) {
    return data === null ? null : Number(data.toFixed(precision));
  }

  formatCounter(data, precision = 1) {
    let value = this.formatData(data, precision);
    return this.formatValue(value);
  }

  formatAxis(data) {
    // if the tick value is a multiple of 1000, we don't wont to show any
    // digits after the decimal point, otherwise, we want to show a single digit.
    // For example, for 1322, we're goign to show 1.3k
    let precision = data % 1000 === 0 ? 0 : 1;

    if (data >= 1000000) {
      return `${Highcharts.numberFormat(data / 1000000, precision)}M`;
    } else if (data >= 1000) {
      return `${Highcharts.numberFormat(data / 1000, precision)}K`;
    } else {
      return data;
    }
  }

  formatPriceFromCents(data) {
    return this.intl.formatNumber(data / 100, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 4,
    });
  }

  formatTooltip(data, override, metricId = null) {
    let value = this.formatData(data);
    let formattedData = this.formatValue(value);
    if (override || this.displayUnit) {
      return pluralize(formattedData, override || this.displayUnit);
    }
    if (this.tooltipTranslationKeys && this.intl.exists(this.tooltipTranslationKeys[metricId])) {
      return this.intl.t(this.tooltipTranslationKeys[metricId], { value: formattedData });
    }
    return formattedData;
  }

  formatValue(value) {
    if (value === null) {
      return value;
    }
    return this.intl.formatNumber(value);
  }
}
