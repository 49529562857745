/* RESPONSIBLE TEAM: team-help-desk-experience */
import { type Attachment, type AttachmentList, type Block } from '@intercom/interblocks.ts';
import type Conversation from 'embercom/objects/inbox/conversation';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import { type Image as BlockImage } from '@intercom/interblocks.ts';
import { captureException } from 'embercom/lib/sentry';

type RenderablePartWithBlocks = RenderablePart & { renderableData: { blocks?: Block[] } };

type SecondsSinceEpoch = number;

export function updateExpiringImages(
  localConversation: Conversation,
  remoteConversation: Conversation,
  timeNow?: Date,
): void {
  /**
   * We always get back newly-signed image URLs from the back-end which busts the browser
   * cache unecessarily if the previous images haven't expired yet.
   *
   * This function loops through and retains the signed image URLs for each block from
   * localConversation if they haven't expired yet and are the same image. If not, we go
   * with the image from remoteConversation.
   *
   * This function mutates the image urls on the remoteConversation's blocks in place.
   *
   * timeNow is exposed for testing purposes.
   */
  let previousPartsById: Record<string, RenderablePartWithBlocks> = Object.assign(
    {},
    ...localConversation.committedParts.map((part) => ({
      [`${part.renderableType}-${part.entityType}-${part.id}`]: part,
    })),
    ...localConversation.pendingParts.map((part) => ({
      [part.clientAssignedUuid!]: part,
    })),
  );
  remoteConversation.renderableParts.forEach((remotePart: RenderablePartWithBlocks) => {
    let previousPart =
      previousPartsById[`${remotePart.renderableType}-${remotePart.entityType}-${remotePart.id}`];
    if (!previousPart && remotePart.clientAssignedUuid) {
      previousPart = previousPartsById[remotePart.clientAssignedUuid];
    }
    if (!previousPart) {
      return;
    }
    updateImagesForPart(previousPart, remotePart, timeNow);
  });
}

function updateImagesForPart(
  localPart: RenderablePartWithBlocks,
  remotePart: RenderablePartWithBlocks,
  timeNow?: Date,
) {
  // Since blocks don't have ids we can either assume that they are stable & immutable within a part
  // ie that localPart.blocks[0] == remotePart.blocks[0], localPart.blocks.length == remotePart.blocks.length etc.
  // or we can just update the images within a part in a way that is block-agnostic by using the images themselves
  // as an identifier.
  // To do this we extract images from all blocks within the local part to a mapping of image-path: signed-url.
  // Attachments are initially uploaded to one host and then served from a different host on subsequent reload.
  // Therefore we need to key the map with just the image path without the URL origin.
  // When processing the remotePart blocks, we can then just key into this map using the image paths we encounter
  // there to do a lookup of the previously signed image url.
  let previousImagesByUnsignedUrl: Record<string, BlockImage['url'] | Attachment['url']> =
    Object.assign(
      {},
      ...(localPart.renderableData.blocks ?? [])
        .filter((block: Block) => block.type === 'image')
        .filter((block: BlockImage) => block.url !== '')
        .map((block: BlockImage) => ({
          [extractImagePath(block.url)]: block.url,
        })),
      ...(localPart.renderableData.blocks ?? [])
        .filter((block: Block) => block.type === 'attachmentList')
        .flatMap((block: AttachmentList) => block.attachments)
        .filter((attachment) => attachment.contentType.startsWith('image'))
        .map((attachment: Attachment) => ({
          [extractImagePath(attachment.url)]: attachment.url,
        })),
    );

  remotePart.renderableData.blocks?.forEach((block) => {
    if (block.type === 'image') {
      block = block as BlockImage;
      if (block.url !== '') {
        block.url = pickBestImage(
          previousImagesByUnsignedUrl[extractImagePath(block.url)],
          block.url,
          timeNow,
        );
      }
    } else if (block.type === 'attachmentList') {
      block = block as AttachmentList;
      block.attachments.forEach((attachment) => {
        if (attachment.contentType.startsWith('image')) {
          attachment.url = pickBestImage(
            previousImagesByUnsignedUrl[extractImagePath(attachment.url)],
            attachment.url,
            timeNow,
          );
        }
      });
    }
  });
}

function pickBestImage(localUrl: string | undefined, remoteUrl: string, timeNow?: Date): string {
  // If the local conversation does not have the same underlying image as in the newer version of the conversation
  // for the same part then fallback to the newer image. This shouldn't actually happen if blocks within part groups are immutable,
  // but we handle it anyway.
  if (!localUrl) {
    captureException(new Error('Image url mismatch encountered'));
    return remoteUrl;
  }
  let previousParams = new URLSearchParams(new URL(localUrl).search);
  let previousExpiry = previousParams.get('expires');

  if (previousExpiry) {
    let previousExpiryTime: SecondsSinceEpoch = Number(previousExpiry);
    let currentTime = secondsSinceEpoch(timeNow || new Date());

    if (previousExpiryTime >= currentTime) {
      return localUrl;
    }
    return remoteUrl;
  }
  return localUrl;
}

function extractImagePath(url: string) {
  return stripOrigin(stripQueryParams(url));
}

function stripOrigin(url: string) {
  return url.replace(new URL(url).origin, '');
}

function stripQueryParams(url: string) {
  return url.split('?')[0];
}

function secondsSinceEpoch(date: Date): SecondsSinceEpoch {
  return Math.floor(date.getTime() / 1000);
}
