/* RESPONSIBLE TEAM: team-frontend-tech */
import DatePredicateManager from 'embercom/lib/common/predicates/date-predicate-manager';
import TagPredicateManager from 'embercom/lib/common/predicates/tag-predicate-manager';
import NumericTagPredicateManager from 'embercom/lib/common/predicates/numeric-tag-predicate-manager';
import OptionalTagPredicateManager from 'embercom/lib/common/predicates/optional-tag-predicate-manager';
import BooleanPredicateManager from 'embercom/lib/common/predicates/boolean-predicate-manager';
import AnonymousPredicateManager from 'embercom/lib/common/predicates/anonymous-predicate-manager';
import RolePredicateManager from 'embercom/lib/common/predicates/role-predicate-manager';
import LogicalPredicateManager from 'embercom/lib/common/predicates/logical-predicate-manager';
import NumericPredicateManager from 'embercom/lib/common/predicates/numeric-predicate-manager';
import StringPredicateManager from 'embercom/lib/common/predicates/string-predicate-manager';
import ContentEventPredicateManager from 'embercom/lib/common/predicates/content-event-predicate-manager';
import UrlVisitPredicateManager from 'embercom/lib/common/predicates/url-visit-predicate-manager';
import OverMessagingProtectionManager from 'embercom/lib/common/predicates/over-messaging-protection-predicate-manager';
import EmailDisengagementManager from 'embercom/lib/common/predicates/email-disengagement-manager';
import ArraySizeManager from 'embercom/lib/common/predicates/array-size-manager';

export const DataManagers = {
  tag: TagPredicateManager,
  numericTag: NumericTagPredicateManager,
  optionalTag: OptionalTagPredicateManager,
  select: OptionalTagPredicateManager,
  string: StringPredicateManager,
  numeric: NumericPredicateManager,
  date: DatePredicateManager,
  boolean: BooleanPredicateManager,
  anonymous: AnonymousPredicateManager,
  role: RolePredicateManager,
  logical: LogicalPredicateManager,
  contentEvent: ContentEventPredicateManager,
  overMessagingProtection: OverMessagingProtectionManager,
  urlVisit: UrlVisitPredicateManager,
  emailDisengagement: EmailDisengagementManager,
  arraySize: ArraySizeManager,
};
