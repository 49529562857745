/* RESPONSIBLE TEAM: team-proactive-support */
import { assert } from '@ember/debug';
import { hash } from 'rsvp';
import {
  controlGroupDeleteFailure,
  controlGroupDeleteOptions,
  controlGroupDeleteSuccess,
} from './actions/control-group-delete';
import {
  controlGroupEndFailure,
  controlGroupEndOptions,
  controlGroupEndSuccess,
} from './actions/control-group-end';
import {
  messageActivateFailure,
  messageActivateOptions,
  messageActivateSuccess,
} from './actions/message-activate';
import {
  messageControlGroupCreateFailure,
  messageControlGroupCreateSuccess,
} from './actions/message-control-group-create';
import { messageDeactivateFailure, messageDeactivateSuccess } from './actions/message-deactivate';
import {
  messageDeleteFailure,
  messageDeleteOptions,
  messageDeleteSuccess,
} from './actions/message-delete';
import { messageDuplicateFailure, messageDuplicateSuccess } from './actions/message-duplicate';
import { messageMoveFailure, messageMoveSuccess } from './actions/message-move';
import {
  messageRemoveScheduleFailure,
  messageRemoveScheduleSuccess,
} from './actions/message-remove-schedule';
import { messageSaveFailure, messageSaveOptions, messageSaveSuccess } from './actions/message-save';
import { messageScheduleFailure, messageScheduleSuccess } from './actions/message-schedule';
import {
  messageStartABTestFailure,
  messageStartABTestSuccess,
} from './actions/message-start-ab-test-helpers';
import { messageStopFailure, messageStopOptions, messageStopSuccess } from './actions/message-stop';
import {
  variationActivateFailure,
  variationActivateOptions,
  variationActivateSuccess,
} from './actions/variation-activate';
import {
  variationDeactivateFailure,
  variationDeactivateSuccess,
} from './actions/variation-deactivate';
import {
  variationDeleteFailure,
  variationDeleteOptions,
  variationDeleteSuccess,
} from './actions/variation-delete';
import { variationGenerateHtmlPreviewFailure } from './actions/variation-generate-html-preview';
/*
HOW DO I ADD A NEW API CALL AND USE THIS LIB?

Add the api call as a promise to the message or variation model.
E.g. `activate` or `schedule` etc.

Add a new task to the controller and call the `_restTask` with the name of the action,
the model you're calling the action on, and the name of the promise you're calling
(in case it's different from the action name).

For example
```javascript
schedule: task(function * () {
  let message = this.get('message');
  this.get('_restTask').perform({ action: 'schedule', model: message, promise: 'schedule' }, { sampleParam: 'test' });
}).group('messageActions')
```

You'll also need to add this action to the UI Text generator.
To do this you'll add a new set of generator functions to `lib/messages/ui/actions`, e.g. `schedule-message`.

This should expose three functions:
* `scheduleMessageSuccess` - The text we show in `notifyConfirmation`.
* `scheduleMessageFailure` - The text we show in `notifyError`.
* `scheduleMessageOptions` - The confirmation options we use for `intercomConfirm`. If a confirmation is not required, we should explicitly return a hash with that specified: `{ notRequired: true }`

Import these functions into `lib/messages/ui/text-generator` and add them to the relevant case statement, e.g:

```javascript
 let getSuccessMessageForMessageAction = function(action, message, params) {
   switch (action) {
     case 'activate': return messageActivateSuccess(message, params);
     case 'deactivate': return messageDeactivateSuccess(message, params);
     case 'schedule': return messageScheduleSuccess(message, params);
   }
 };
```

Then write some specs to ensure your messages are correct!
*/

/****** Message Action Generators ******/

let getSuccessMessageForMessageAction = function (action, message, params) {
  switch (action) {
    case 'activate':
      return messageActivateSuccess(message, params);
    case 'deactivate':
      return messageDeactivateSuccess(message, params);
    case 'delete':
      return messageDeleteSuccess(message, params);
    case 'move':
      return messageMoveSuccess(message, params);
    case 'stop':
      return messageStopSuccess(message, params);
    case 'duplicate':
      return messageDuplicateSuccess(message, params);
    case 'schedule':
      return messageScheduleSuccess(message, params);
    case 'removeSchedule':
      return messageRemoveScheduleSuccess(message, params);
    case 'save':
      return messageSaveSuccess(message, params);
    case 'autoSave':
      return null;
    case 'startABTest':
      return messageStartABTestSuccess(message, params);
    case 'createControlGroup':
      return messageControlGroupCreateSuccess(message, params);
    case 'changeSubclassType':
      return null;
  }
};

let getFailureMessageForMessageAction = function (action, message, params) {
  switch (action) {
    case 'activate':
      return messageActivateFailure(message, params);
    case 'deactivate':
      return messageDeactivateFailure(message, params);
    case 'delete':
      return messageDeleteFailure(message, params);
    case 'move':
      return messageMoveFailure(message, params);
    case 'stop':
      return messageStopFailure(message, params);
    case 'duplicate':
      return messageDuplicateFailure(message, params);
    case 'schedule':
      return messageScheduleFailure(message, params);
    case 'removeSchedule':
      return messageRemoveScheduleFailure(message, params);
    case 'save':
      return messageSaveFailure(message, params);
    case 'autoSave':
      return messageSaveFailure(message, params);
    case 'startABTest':
      return messageStartABTestFailure(message, params);
    case 'createControlGroup':
      return messageControlGroupCreateFailure(message, params);
    case 'changeSubclassType':
      return null;
  }
};

let getConfirmOptionsForMessageAction = function (action, message, params) {
  switch (action) {
    case 'activate':
      return messageActivateOptions(message, params);
    case 'deactivate':
      return { notRequired: true };
    case 'delete':
      return messageDeleteOptions(message, params);
    case 'move':
      return { notRequired: true };
    case 'stop':
      return messageStopOptions(message, params);
    case 'duplicate':
      return { notRequired: true };
    case 'schedule':
      return { notRequired: true };
    case 'removeSchedule':
      return { notRequired: true };
    case 'save':
      return messageSaveOptions(message, params);
    case 'autoSave':
      return messageSaveOptions(message, params);
    case 'startABTest':
      return { notRequired: true };
    case 'createControlGroup':
      return { notRequired: true };
    case 'changeSubclassType':
      return { notRequired: true };
  }
};

/****** Variation Action Generators ******/

let getSuccessMessageForVariationAction = function (action, variation, params) {
  switch (action) {
    case 'activate':
      return variationActivateSuccess(variation, params);
    case 'deactivate':
      return variationDeactivateSuccess(variation, params);
    case 'delete':
      return variationDeleteSuccess(variation, params);
    case 'generateHtmlPreview':
      return null;
  }
};

let getFailureMessageForVariationAction = function (action, variation, params) {
  switch (action) {
    case 'activate':
      return variationActivateFailure(variation, params);
    case 'deactivate':
      return variationDeactivateFailure(variation, params);
    case 'delete':
      return variationDeleteFailure(variation, params);
    case 'generateHtmlPreview':
      return variationGenerateHtmlPreviewFailure(variation, params);
  }
};

let getConfirmOptionsForVariationAction = function (action, variation, params) {
  switch (action) {
    case 'activate':
      return variationActivateOptions(variation, params);
    case 'deactivate':
      return { notRequired: true };
    case 'delete':
      return variationDeleteOptions(variation, params);
    case 'generateHtmlPreview':
      return { notRequired: true };
  }
};

let getSuccessMessageForControlGroupAction = function (action, variation, params) {
  switch (action) {
    case 'delete':
      return controlGroupDeleteSuccess();
    case 'end':
      return controlGroupEndSuccess();
  }
};

let getFailureMessageForControlGroupAction = function (action) {
  switch (action) {
    case 'delete':
      return controlGroupDeleteFailure();
    case 'end':
      return controlGroupEndFailure();
  }
};

let getConfirmOptionsForControlGroupAction = function (action, controlGroup) {
  switch (action) {
    case 'delete':
      return controlGroupDeleteOptions();
    case 'end':
      return controlGroupEndOptions(controlGroup);
  }
};

let generateForMessage = function (action, message, params) {
  return hash({
    success: getSuccessMessageForMessageAction(action, message, params),
    failure: getFailureMessageForMessageAction(action, message, params),
    confirm: getConfirmOptionsForMessageAction(action, message, params),
  });
};

let generateForVariation = function (action, variation, params) {
  return hash({
    success: getSuccessMessageForVariationAction(action, variation, params),
    failure: getFailureMessageForVariationAction(action, variation, params),
    confirm: getConfirmOptionsForVariationAction(action, variation, params),
  });
};

let generateForControlGroup = function (action, controlGroup) {
  return hash({
    success: getSuccessMessageForControlGroupAction(action),
    failure: getFailureMessageForControlGroupAction(action),
    confirm: getConfirmOptionsForControlGroupAction(action, controlGroup),
  });
};

export default function (action, model, params) {
  if (typeof model === 'undefined') {
    throw new Error('Message or Variation must be passed in to generate messages.');
  }
  // `model` is either a message or a variation's message, see https://github.com/intercom/intercom/issues/63344
  // or a control group
  let modelName = model.constructor.modelName || model.content.constructor.modelName;
  switch (modelName) {
    case 'message':
      return generateForMessage(action, model, params);
    case 'message-variation':
      return generateForVariation(action, model, params);
    case 'messages/control-group':
      return generateForControlGroup(action, model);
  }
  assert(`Invalid model name received${modelName}`);
}
