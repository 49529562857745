/* RESPONSIBLE TEAM: team-reporting */
const config = {
  hideDelay: 50,
  snap: 10,
  shared: false,
  outside: true,
  enabled: true,
  borderWidth: 1,
  backgroundColor: '#ffffff',
  borderColor: '#e5e5e5',
  borderRadius: 10,
  useHTML: true,
  padding: 0,
  style: {
    fontFamily:
      'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    fontSize: '14px',
    color: '#222222',
  },
  valueDecimals: 0,
};

export default class TooltipConfig {
  constructor(options) {
    return { ...config, ...options };
  }
}
