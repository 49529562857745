/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';
import type Session from 'embercom/services/session';
import type ConversationTableEntry from 'embercom/objects/inbox/conversation-table-entry';
import type Conversation from 'embercom/objects/inbox/conversation';
import type AdminPermissions from 'embercom/services/admin-permissions';

export default class CanReassignConversation extends Helper<{
  Args: {
    Positional: [Conversation | ConversationTableEntry];
  };
  Return: boolean;
}> {
  @service declare session: Session;
  @service declare adminPermissions: AdminPermissions;

  compute([conversation]: [Conversation | ConversationTableEntry]) {
    if (!conversation) {
      return false;
    }

    let assigneeIsMe = conversation.adminAssignee?.id === this.session.teammate.id;

    if (this.adminPermissions.canReassignConversations) {
      return true;
    } else {
      if (conversation.adminAssignee) {
        return assigneeIsMe || conversation.adminAssignee.isUnassignedAssignee;
      }
      return true;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'can-reassign-conversation': typeof CanReassignConversation;
  }
}
