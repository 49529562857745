/* RESPONSIBLE TEAM: team-frontend-tech */
export default {
  name: 'intercomPreboot',
  initialize() {
    let i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    window.Intercom = i;
  },
};
