/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { helper as buildHelper } from '@ember/component/helper';
import moment from 'moment-timezone';

// unlike `timezoneFallbackMappings` in the intl service, this is a hardcoded
// mapping from old names to new names for specific timezones which have
// manual value maps elsewhere in the intercom and embercom repos for
// compatibility reasons. This allows us to display the new name without
// needing to update all dependencies (eg. moment) here to understand the
// mapping
const timezoneNameOverrides = {
  'Europe/Kiev': 'Europe/Kyiv',
};

let formatTimezone = function (params) {
  let timezone = params[0];
  let timezoneStr = function (timezoneString) {
    if (timezoneNameOverrides[timezoneString]) {
      timezoneString = timezoneNameOverrides[timezoneString];
    }
    return timezoneString.slice(timezoneString.lastIndexOf('/') + 1).replace(/_/g, ' ');
  };

  // Timezone to GMT+X
  let utcOffsetStr = function (timezone) {
    return moment
      .tz(timezone)
      .format('[GMT]Z')
      .replace(/0(\d:)/, '$1')
      .replace(':00', '');
  };

  return `${timezoneStr(timezone)} time (${utcOffsetStr(timezone)})`;
};

export default buildHelper(formatTimezone);
