/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
// NOTE: The order here matters, timezones should be logically ordered by GMT offset and then name
export default [
  {
    name: 'American Samoa',
    description: '(GMT-11:00) American Samoa',
    value: 'Pacific/Pago_Pago',
  },
  {
    name: 'International Date Line West',
    description: '(GMT-11:00) International Date Line West',
    value: 'Etc/GMT+12',
  },
  { name: 'Midway Island', description: '(GMT-11:00) Midway Island', value: 'Pacific/Midway' },
  { name: 'Hawaii', description: '(GMT-10:00) Hawaii', value: 'Pacific/Honolulu' },
  {
    name: 'Pacific/Honolulu',
    description: '(GMT-10:00) Pacific/Honolulu',
    value: 'Pacific/Honolulu',
  },
  { name: 'Alaska', description: '(GMT-09:00) Alaska', value: 'America/Juneau' },
  {
    name: 'America/Los_Angeles',
    description: '(GMT-08:00) America/Los_Angeles',
    value: 'America/Los_Angeles',
  },
  {
    name: 'America/Vancouver',
    description: '(GMT-08:00) America/Vancouver',
    value: 'America/Vancouver',
  },
  {
    name: 'Pacific Time (US & Canada)',
    description: '(GMT-08:00) Pacific Time (US & Canada)',
    value: 'America/Los_Angeles',
  },
  { name: 'Tijuana', description: '(GMT-08:00) Tijuana', value: 'America/Tijuana' },
  { name: 'Arizona', description: '(GMT-07:00) Arizona', value: 'America/Phoenix' },
  { name: 'Chihuahua', description: '(GMT-07:00) Chihuahua', value: 'America/Chihuahua' },
  { name: 'Mazatlan', description: '(GMT-07:00) Mazatlan', value: 'America/Mazatlan' },
  {
    name: 'Mountain Time (US & Canada)',
    description: '(GMT-07:00) Mountain Time (US & Canada)',
    value: 'America/Denver',
  },
  {
    name: 'America/Chicago',
    description: '(GMT-06:00) America/Chicago',
    value: 'America/Chicago',
  },
  {
    name: 'Central America',
    description: '(GMT-06:00) Central America',
    value: 'America/Guatemala',
  },
  {
    name: 'Central Time (US & Canada)',
    description: '(GMT-06:00) Central Time (US & Canada)',
    value: 'America/Chicago',
  },
  {
    name: 'Guadalajara',
    description: '(GMT-06:00) Guadalajara',
    value: 'America/Mexico_City',
  },
  {
    name: 'Mexico City',
    description: '(GMT-06:00) Mexico City',
    value: 'America/Mexico_City',
  },
  { name: 'Monterrey', description: '(GMT-06:00) Monterrey', value: 'America/Monterrey' },
  { name: 'Saskatchewan', description: '(GMT-06:00) Saskatchewan', value: 'America/Regina' },
  {
    name: 'America/Jamaica',
    description: '(GMT-05:00) America/Jamaica',
    value: 'America/Jamaica',
  },
  {
    name: 'America/Montreal',
    description: '(GMT-05:00) America/Montreal',
    value: 'America/Montreal',
  },
  {
    name: 'America/New_York',
    description: '(GMT-05:00) America/New_York',
    value: 'America/New_York',
  },
  {
    name: 'America/Toronto',
    description: '(GMT-05:00) America/Toronto',
    value: 'America/Toronto',
  },
  { name: 'Bogota', description: '(GMT-05:00) Bogota', value: 'America/Bogota' },
  {
    name: 'Eastern Time (US & Canada)',
    description: '(GMT-05:00) Eastern Time (US & Canada)',
    value: 'America/New_York',
  },
  {
    name: 'Indiana (East)',
    description: '(GMT-05:00) Indiana (East)',
    value: 'America/Indiana/Indianapolis',
  },
  { name: 'Lima', description: '(GMT-05:00) Lima', value: 'America/Lima' },
  { name: 'Quito', description: '(GMT-05:00) Quito', value: 'America/Lima' },
  { name: 'Caracas', description: '(GMT-04:30) Caracas', value: 'America/Caracas' },
  {
    name: 'Atlantic Time (Canada)',
    description: '(GMT-04:00) Atlantic Time (Canada)',
    value: 'America/Halifax',
  },
  { name: 'Detroit', description: '(GMT-04:00) Detroit', value: 'America/Detroit' },
  { name: 'Georgetown', description: '(GMT-04:00) Georgetown', value: 'America/Guyana' },
  { name: 'La Paz', description: '(GMT-04:00) La Paz', value: 'America/La_Paz' },
  {
    name: 'America/Santo_Domingo',
    description: '(GMT-04:00) America/Santo_Domingo',
    value: 'America/Santo_Domingo',
  },
  { name: 'Newfoundland', description: '(GMT-03:30) Newfoundland', value: 'America/St_Johns' },
  {
    name: 'America/Argentina/Buenos_Aires',
    description: '(GMT-03:00) America/Argentina/Buenos_Aires',
    value: 'America/Argentina/Buenos_Aires',
  },
  {
    name: 'America/Sao_Paulo',
    description: '(GMT-03:00) America/Sao_Paulo',
    value: 'America/Sao_Paulo',
  },
  { name: 'Brasilia', description: '(GMT-03:00) Brasilia', value: 'America/Sao_Paulo' },
  {
    name: 'Buenos Aires',
    description: '(GMT-03:00) Buenos Aires',
    value: 'America/Argentina/Buenos_Aires',
  },
  {
    name: 'America/Argentina/Mendoza',
    description: '(GMT-03:00) America/Argentina/Mendoza',
    value: 'America/Argentina/Mendoza',
  },
  { name: 'Greenland', description: '(GMT-03:00) Greenland', value: 'America/Godthab' },
  { name: 'Montevideo', description: '(GMT-03:00) Montevideo', value: 'America/Montevideo' },
  { name: 'Santiago', description: '(GMT-03:00) Santiago', value: 'America/Santiago' },
  {
    name: 'Mid-Atlantic',
    description: '(GMT-02:00) Mid-Atlantic',
    value: 'Atlantic/South_Georgia',
  },
  { name: 'Azores', description: '(GMT-01:00) Azores', value: 'Atlantic/Azores' },
  {
    name: 'Cape Verde Is.',
    description: '(GMT-01:00) Cape Verde Is.',
    value: 'Atlantic/Cape_Verde',
  },
  {
    name: 'Africa/Casablanca',
    description: '(GMT+00:00) Africa/Casablanca',
    value: 'Africa/Casablanca',
  },
  {
    name: 'Africa/Abidjan',
    description: '(GMT+00:00) Africa/Abidjan',
    value: 'Africa/Abidjan',
  },
  { name: 'Casablanca', description: '(GMT+00:00) Casablanca', value: 'Africa/Casablanca' },
  { name: 'Dublin', description: '(GMT+00:00) Dublin', value: 'Europe/Dublin' },
  { name: 'Edinburgh', description: '(GMT+00:00) Edinburgh', value: 'Europe/London' },
  { name: 'Europe/Dublin', description: '(GMT+00:00) Europe/Dublin', value: 'Europe/Dublin' },
  { name: 'Europe/Lisbon', description: '(GMT+00:00) Europe/Lisbon', value: 'Europe/Lisbon' },
  { name: 'Europe/London', description: '(GMT+00:00) Europe/London', value: 'Europe/London' },
  { name: 'Lisbon', description: '(GMT+00:00) Lisbon', value: 'Europe/Lisbon' },
  { name: 'London', description: '(GMT+00:00) London', value: 'Europe/London' },
  { name: 'Monrovia', description: '(GMT+00:00) Monrovia', value: 'Africa/Monrovia' },
  { name: 'UTC', description: '(GMT+00:00) UTC', value: 'Etc/UTC' },
  {
    name: 'Africa/Algiers',
    description: '(GMT+01:00) Africa/Algiers',
    value: 'Africa/Algiers',
  },
  { name: 'Africa/Lagos', description: '(GMT+01:00) Africa/Lagos', value: 'Africa/Lagos' },
  { name: 'Amsterdam', description: '(GMT+01:00) Amsterdam', value: 'Europe/Amsterdam' },
  { name: 'Belgrade', description: '(GMT+01:00) Belgrade', value: 'Europe/Belgrade' },
  { name: 'Berlin', description: '(GMT+01:00) Berlin', value: 'Europe/Berlin' },
  { name: 'Bern', description: '(GMT+01:00) Bern', value: 'Europe/Zurich' },
  { name: 'Bratislava', description: '(GMT+01:00) Bratislava', value: 'Europe/Bratislava' },
  { name: 'Brussels', description: '(GMT+01:00) Brussels', value: 'Europe/Brussels' },
  { name: 'Budapest', description: '(GMT+01:00) Budapest', value: 'Europe/Budapest' },
  { name: 'Copenhagen', description: '(GMT+01:00) Copenhagen', value: 'Europe/Copenhagen' },
  {
    name: 'Europe/Amsterdam',
    description: '(GMT+01:00) Europe/Amsterdam',
    value: 'Europe/Amsterdam',
  },
  {
    name: 'Europe/Belgrade',
    description: '(GMT+01:00) Europe/Belgrade',
    value: 'Europe/Belgrade',
  },
  { name: 'Europe/Berlin', description: '(GMT+01:00) Europe/Berlin', value: 'Europe/Berlin' },
  {
    name: 'Europe/Brussels',
    description: '(GMT+01:00) Europe/Brussels',
    value: 'Europe/Brussels',
  },
  { name: 'Europe/Madrid', description: '(GMT+01:00) Europe/Madrid', value: 'Europe/Madrid' },
  { name: 'Europe/Oslo', description: '(GMT+01:00) Europe/Oslo', value: 'Europe/Oslo' },
  { name: 'Europe/Paris', description: '(GMT+01:00) Europe/Paris', value: 'Europe/Paris' },
  { name: 'Europe/Rome', description: '(GMT+01:00) Europe/Rome', value: 'Europe/Rome' },
  {
    name: 'Europe/Stockholm',
    description: '(GMT+01:00) Europe/Stockholm',
    value: 'Europe/Stockholm',
  },
  { name: 'Europe/Warsaw', description: '(GMT+01:00) Europe/Warsaw', value: 'Europe/Warsaw' },
  { name: 'Ljubljana', description: '(GMT+01:00) Ljubljana', value: 'Europe/Ljubljana' },
  { name: 'Madrid', description: '(GMT+01:00) Madrid', value: 'Europe/Madrid' },
  { name: 'Paris', description: '(GMT+01:00) Paris', value: 'Europe/Paris' },
  { name: 'Prague', description: '(GMT+01:00) Prague', value: 'Europe/Prague' },
  { name: 'Rome', description: '(GMT+01:00) Rome', value: 'Europe/Rome' },
  { name: 'Sarajevo', description: '(GMT+01:00) Sarajevo', value: 'Europe/Sarajevo' },
  { name: 'Skopje', description: '(GMT+01:00) Skopje', value: 'Europe/Skopje' },
  { name: 'Stockholm', description: '(GMT+01:00) Stockholm', value: 'Europe/Stockholm' },
  { name: 'Vienna', description: '(GMT+01:00) Vienna', value: 'Europe/Vienna' },
  { name: 'Warsaw', description: '(GMT+01:00) Warsaw', value: 'Europe/Warsaw' },
  {
    name: 'West Central Africa',
    description: '(GMT+01:00) West Central Africa',
    value: 'Africa/Algiers',
  },
  { name: 'Zagreb', description: '(GMT+01:00) Zagreb', value: 'Europe/Zagreb' },
  {
    name: 'Africa/Johannesburg',
    description: '(GMT+02:00) Africa/Johannesburg',
    value: 'Africa/Johannesburg',
  },
  { name: 'Athens', description: '(GMT+02:00) Athens', value: 'Europe/Athens' },
  { name: 'Bucharest', description: '(GMT+02:00) Bucharest', value: 'Europe/Bucharest' },
  { name: 'Cairo', description: '(GMT+02:00) Cairo', value: 'Africa/Cairo' },
  { name: 'Europe/Athens', description: '(GMT+02:00) Europe/Athens', value: 'Europe/Athens' },
  {
    name: 'Europe/Helsinki',
    description: '(GMT+02:00) Europe/Helsinki',
    value: 'Europe/Helsinki',
  },
  { name: 'Europe/Sofia', description: '(GMT+02:00) Europe/Sofia', value: 'Europe/Sofia' },
  {
    name: 'Europe/Tallinn',
    description: '(GMT+02:00) Europe/Tallinn',
    value: 'Europe/Tallinn',
  },
  { name: 'Harare', description: '(GMT+02:00) Harare', value: 'Africa/Harare' },
  { name: 'Helsinki', description: '(GMT+02:00) Helsinki', value: 'Europe/Helsinki' },
  { name: 'Jerusalem', description: '(GMT+02:00) Jerusalem', value: 'Asia/Jerusalem' },
  { name: 'Kaliningrad', description: '(GMT+02:00) Kaliningrad', value: 'Europe/Kaliningrad' },
  { name: 'Kiev', description: '(GMT+02:00) Europe/Kyiv', value: 'Europe/Kiev' },
  { name: 'Pretoria', description: '(GMT+02:00) Pretoria', value: 'Africa/Johannesburg' },
  { name: 'Riga', description: '(GMT+02:00) Riga', value: 'Europe/Riga' },
  { name: 'Sofia', description: '(GMT+02:00) Sofia', value: 'Europe/Sofia' },
  { name: 'Tallinn', description: '(GMT+02:00) Tallinn', value: 'Europe/Tallinn' },
  { name: 'Vilnius', description: '(GMT+02:00) Vilnius', value: 'Europe/Vilnius' },
  {
    name: 'Africa/Nairobi',
    description: '(GMT+03:00) Africa/Nairobi',
    value: 'Africa/Nairobi',
  },
  { name: 'Asia/Istanbul', description: '(GMT+03:00) Asia/Istanbul', value: 'Asia/Istanbul' },
  { name: 'Asia/Qatar', description: '(GMT+03:00) Asia/Qatar', value: 'Asia/Qatar' },
  { name: 'Asia/Riyadh', description: '(GMT+03:00) Asia/Riyadh', value: 'Asia/Riyadh' },
  { name: 'Baghdad', description: '(GMT+03:00) Baghdad', value: 'Asia/Baghdad' },
  { name: 'Europe/Moscow', description: '(GMT+03:00) Europe/Moscow', value: 'Europe/Moscow' },
  { name: 'Istanbul', description: '(GMT+03:00) Istanbul', value: 'Europe/Istanbul' },
  { name: 'Kuwait', description: '(GMT+03:00) Kuwait', value: 'Asia/Kuwait' },
  { name: 'Minsk', description: '(GMT+03:00) Minsk', value: 'Europe/Minsk' },
  { name: 'Moscow', description: '(GMT+03:00) Moscow', value: 'Europe/Moscow' },
  { name: 'Nairobi', description: '(GMT+03:00) Nairobi', value: 'Africa/Nairobi' },
  { name: 'Riyadh', description: '(GMT+03:00) Riyadh', value: 'Asia/Riyadh' },
  {
    name: 'St. Petersburg',
    description: '(GMT+03:00) St. Petersburg',
    value: 'Europe/Moscow',
  },
  { name: 'Volgograd', description: '(GMT+03:00) Volgograd', value: 'Europe/Volgograd' },
  { name: 'Tehran', description: '(GMT+03:30) Tehran', value: 'Asia/Tehran' },
  { name: 'Abu Dhabi', description: '(GMT+04:00) Abu Dhabi', value: 'Asia/Muscat' },
  { name: 'Baku', description: '(GMT+04:00) Baku', value: 'Asia/Baku' },
  { name: 'Dubai', description: '(GMT+04:00) Dubai', value: 'Asia/Dubai' },
  { name: 'Muscat', description: '(GMT+04:00) Muscat', value: 'Asia/Muscat' },
  { name: 'Samara', description: '(GMT+04:00) Samara', value: 'Europe/Samara' },
  { name: 'Tbilisi', description: '(GMT+04:00) Tbilisi', value: 'Asia/Tbilisi' },
  { name: 'Yerevan', description: '(GMT+04:00) Yerevan', value: 'Asia/Yerevan' },
  { name: 'Kabul', description: '(GMT+04:30) Kabul', value: 'Asia/Kabul' },
  { name: 'Almaty', description: '(GMT+05:00) Almaty', value: 'Asia/Almaty' },
  { name: 'Asia/Karachi', description: '(GMT+05:00) Asia/Karachi', value: 'Asia/Karachi' },
  { name: 'Astana', description: '(GMT+05:00) Astana', value: 'Asia/Almaty' },
  {
    name: 'Ekaterinburg',
    description: '(GMT+05:00) Ekaterinburg',
    value: 'Asia/Yekaterinburg',
  },
  { name: 'Islamabad', description: '(GMT+05:00) Islamabad', value: 'Asia/Karachi' },
  { name: 'Karachi', description: '(GMT+05:00) Karachi', value: 'Asia/Karachi' },
  { name: 'Tashkent', description: '(GMT+05:00) Tashkent', value: 'Asia/Tashkent' },
  { name: 'Asia/Calcutta', description: '(GMT+05:30) Asia/Calcutta', value: 'Asia/Calcutta' },
  { name: 'Chennai', description: '(GMT+05:30) Chennai', value: 'Asia/Kolkata' },
  { name: 'Kolkata', description: '(GMT+05:30) Kolkata', value: 'Asia/Kolkata' },
  { name: 'Mumbai', description: '(GMT+05:30) Mumbai', value: 'Asia/Kolkata' },
  { name: 'New Delhi', description: '(GMT+05:30) New Delhi', value: 'Asia/Kolkata' },
  {
    name: 'Sri Jayawardenepura',
    description: '(GMT+05:30) Sri Jayawardenepura',
    value: 'Asia/Colombo',
  },
  { name: 'Kathmandu', description: '(GMT+05:45) Kathmandu', value: 'Asia/Kathmandu' },
  { name: 'Dhaka', description: '(GMT+06:00) Dhaka', value: 'Asia/Dhaka' },
  { name: 'Urumqi', description: '(GMT+06:00) Urumqi', value: 'Asia/Urumqi' },
  { name: 'Rangoon', description: '(GMT+06:30) Rangoon', value: 'Asia/Rangoon' },
  {
    name: 'Asia/Phnom_Penh',
    description: '(GMT+07:00) Asia/Phnom_Penh',
    value: 'Asia/Phnom_Penh',
  },
  { name: 'Bangkok', description: '(GMT+07:00) Bangkok', value: 'Asia/Bangkok' },
  { name: 'Hanoi', description: '(GMT+07:00) Hanoi', value: 'Asia/Bangkok' },
  { name: 'Jakarta', description: '(GMT+07:00) Jakarta', value: 'Asia/Jakarta' },
  { name: 'Krasnoyarsk', description: '(GMT+07:00) Krasnoyarsk', value: 'Asia/Krasnoyarsk' },
  { name: 'Novosibirsk', description: '(GMT+07:00) Novosibirsk', value: 'Asia/Novosibirsk' },
  {
    name: 'Asia/Chongqing',
    description: '(GMT+08:00) Asia/Chongqing',
    value: 'Asia/Chongqing',
  },
  {
    name: 'Asia/Kuala_Lumpur',
    description: '(GMT+08:00) Asia/Kuala_Lumpur',
    value: 'Asia/Kuala_Lumpur',
  },
  { name: 'Asia/Manila', description: '(GMT+08:00) Asia/Manila', value: 'Asia/Manila' },
  {
    name: 'Asia/Singapore',
    description: '(GMT+08:00) Asia/Singapore',
    value: 'Asia/Singapore',
  },
  { name: 'Asia/Taipei', description: '(GMT+08:00) Asia/Taipei', value: 'Asia/Taipei' },
  { name: 'Beijing', description: '(GMT+08:00) Beijing', value: 'Asia/Shanghai' },
  { name: 'Chongqing', description: '(GMT+08:00) Chongqing', value: 'Asia/Chongqing' },
  { name: 'Hong Kong', description: '(GMT+08:00) Hong Kong', value: 'Asia/Hong_Kong' },
  { name: 'Irkutsk', description: '(GMT+08:00) Irkutsk', value: 'Asia/Irkutsk' },
  {
    name: 'Kuala Lumpur',
    description: '(GMT+08:00) Kuala Lumpur',
    value: 'Asia/Kuala_Lumpur',
  },
  { name: 'Perth', description: '(GMT+08:00) Perth', value: 'Australia/Perth' },
  { name: 'Singapore', description: '(GMT+08:00) Singapore', value: 'Asia/Singapore' },
  { name: 'Taipei', description: '(GMT+08:00) Taipei', value: 'Asia/Taipei' },
  { name: 'Ulaanbaatar', description: '(GMT+08:00) Ulaanbaatar', value: 'Asia/Ulaanbaatar' },
  { name: 'Asia/Seoul', description: '(GMT+09:00) Asia/Seoul', value: 'Asia/Seoul' },
  { name: 'Osaka', description: '(GMT+09:00) Osaka', value: 'Asia/Tokyo' },
  { name: 'Sapporo', description: '(GMT+09:00) Sapporo', value: 'Asia/Tokyo' },
  { name: 'Seoul', description: '(GMT+09:00) Seoul', value: 'Asia/Seoul' },
  { name: 'Tokyo', description: '(GMT+09:00) Tokyo', value: 'Asia/Tokyo' },
  { name: 'Yakutsk', description: '(GMT+09:00) Yakutsk', value: 'Asia/Yakutsk' },
  { name: 'Adelaide', description: '(GMT+09:30) Adelaide', value: 'Australia/Adelaide' },
  { name: 'Darwin', description: '(GMT+09:30) Darwin', value: 'Australia/Darwin' },
  {
    name: 'Australia/Canberra',
    description: '(GMT+10:00) Australia/Canberra',
    value: 'Australia/Canberra',
  },
  { name: 'Australia/NSW', description: '(GMT+10:00) Australia/NSW', value: 'Australia/NSW' },
  {
    name: 'Australia/Victoria',
    description: '(GMT+10:00) Australia/Victoria',
    value: 'Australia/Victoria',
  },
  { name: 'Brisbane', description: '(GMT+10:00) Brisbane', value: 'Australia/Brisbane' },
  { name: 'Canberra', description: '(GMT+10:00) Canberra', value: 'Australia/Melbourne' },
  { name: 'Guam', description: '(GMT+10:00) Guam', value: 'Pacific/Guam' },
  { name: 'Hobart', description: '(GMT+10:00) Hobart', value: 'Australia/Hobart' },
  { name: 'Magadan', description: '(GMT+10:00) Magadan', value: 'Asia/Magadan' },
  { name: 'Melbourne', description: '(GMT+10:00) Melbourne', value: 'Australia/Melbourne' },
  {
    name: 'Port Moresby',
    description: '(GMT+10:00) Port Moresby',
    value: 'Pacific/Port_Moresby',
  },
  { name: 'Sydney', description: '(GMT+10:00) Sydney', value: 'Australia/Sydney' },
  { name: 'Vladivostok', description: '(GMT+10:00) Vladivostok', value: 'Asia/Vladivostok' },
  { name: 'New Caledonia', description: '(GMT+11:00) New Caledonia', value: 'Pacific/Noumea' },
  {
    name: 'Solomon Is.',
    description: '(GMT+11:00) Solomon Is.',
    value: 'Pacific/Guadalcanal',
  },
  {
    name: 'Srednekolymsk',
    description: '(GMT+11:00) Srednekolymsk',
    value: 'Asia/Srednekolymsk',
  },
  { name: 'Auckland', description: '(GMT+12:00) Auckland', value: 'Pacific/Auckland' },
  { name: 'Fiji', description: '(GMT+12:00) Fiji', value: 'Pacific/Fiji' },
  { name: 'Kamchatka', description: '(GMT+12:00) Kamchatka', value: 'Asia/Kamchatka' },
  { name: 'Marshall Is.', description: '(GMT+12:00) Marshall Is.', value: 'Pacific/Majuro' },
  {
    name: 'Pacific/Auckland',
    description: '(GMT+12:00) Pacific/Auckland',
    value: 'Pacific/Auckland',
  },
  { name: 'Wellington', description: '(GMT+12:00) Wellington', value: 'Pacific/Auckland' },
  { name: 'Chatham Is.', description: '(GMT+12:45) Chatham Is.', value: 'Pacific/Chatham' },
  { name: "Nuku'alofa", description: "(GMT+13:00) Nuku'alofa", value: 'Pacific/Tongatapu' },
  { name: 'Samoa', description: '(GMT+13:00) Samoa', value: 'Pacific/Apia' },
  { name: 'Tokelau Is.', description: '(GMT+13:00) Tokelau Is.', value: 'Pacific/Fakaofo' },
];
