/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import EmberObject from '@ember/object';
import { sort } from '@ember/object/computed';
import { task, taskGroup } from 'ember-concurrency';

export default EmberObject.extend({
  pageSize: 10,
  idKey: 'id',
  updateKey: 'updated_at',
  sortKey: ['updated_at:desc'],

  // This is overridden by the Conversation model when it creates ConversationStreamSyncedList
  // to tie this task group with the conversation tasks.
  // Cancelling this group, or any tasks in the group, will also cancel all conversation
  // tasks. See: https://github.com/intercom/intercom/issues/150738
  syncingTaskGroup: taskGroup().enqueue(),

  windowSize: 0,
  items: null,
  sortedItems: sort('items', 'sortKey'),

  isLoadingMore: false,

  init() {
    this._super(...arguments);
    this.set('items', []);
  },

  // Abstract

  async fetchRemoteState(/* windowSize */) {
    throw new Error('Not implemented');
  },

  async fetchItemsById(/* ids */) {
    throw new Error('Not implemented');
  },

  // Public

  async sync() {
    if (this.windowSize === 0) {
      return await this.loadMore();
    }

    // Consolidate the Promise implementation to native
    return new Promise((resolve, reject) => {
      this.update.perform().then(resolve, reject);
    });
  },

  async loadMore(pageSize = this.pageSize) {
    this.incrementProperty('windowSize', pageSize);
    this.set('isLoadingMore', true);
    await this.sync();
    this.set('isLoadingMore', false);
  },

  clear() {
    this.set('items', []);
    this.set('windowSize', 0);
  },

  async refresh() {
    this.clear();
    await this.sync();
  },

  async sortBy(sortKey) {
    this.set('sortKey', sortKey);
    await this.refresh();
  },

  // Protected

  update: task(function* () {
    if (this.items.length === 0 && this.fetchInitialItems) {
      return this.addItems(yield this.fetchInitialItems(this.windowSize));
    }

    let remoteState = yield this.fetchRemoteState(this.windowSize);
    let localState = this.getCurrentState();
    let { addedItemsIds, updatedItemsIds, removedItemsIds } = this.diffState(
      localState,
      remoteState,
    );

    let addedAndUpdatedItems = yield this.fetchItemsById(addedItemsIds.concat(updatedItemsIds));
    let addedItems = addedAndUpdatedItems.filter((item) =>
      addedItemsIds.includes(this.getItemId(item)),
    );
    let updatedItems = addedAndUpdatedItems.filter((item) =>
      updatedItemsIds.includes(this.getItemId(item)),
    );
    let removedItems = this.getItemsById(removedItemsIds);

    if (updatedItems.length) {
      this.updateItems(updatedItems);
    }

    if (addedItems.length) {
      this.addItems(addedItems);
    }

    if (removedItemsIds.length) {
      this.removeItems(removedItems);
    }
  }).group('syncingTaskGroup'),

  getCurrentState() {
    return this.items.map((item) => [this.getItemId(item), this.getItemLastUpdate(item)]);
  },

  diffState(localState, remoteState) {
    let removedItemsIds = localState.filter(
      ([localId]) => !remoteState.find(([remoteId]) => remoteId === localId),
    );
    let addedItemsIds = remoteState.filter(
      ([remoteId]) => !localState.find(([localId]) => localId === remoteId),
    );
    let updatedItemsIds = remoteState.filter((remoteTuple) => {
      let localTuple = localState.find(([localId]) => localId === remoteTuple[0]);

      return localTuple && remoteTuple[1] > localTuple[1];
    });

    return {
      addedItemsIds: pluckIds(addedItemsIds),
      removedItemsIds: pluckIds(removedItemsIds),
      updatedItemsIds: pluckIds(updatedItemsIds),
    };
  },

  addItems(items) {
    this.items.addObjects(items);
  },

  updateItems(items) {
    this.set(
      'items',
      this.items
        .filter((existingItem) => !items.find((item) => item.get('id') === existingItem.get('id')))
        .concat(items),
    );
  },

  removeItems(items) {
    this.items.removeObjects(items);
  },

  getItemsById(ids) {
    return this.items.filter((item) => ids.includes(this.getItemId(item)));
  },

  getItemId(item) {
    return item.get(this.idKey);
  },

  getItemLastUpdate(item) {
    return item.get(this.updateKey);
  },

  willDestroy() {
    this.clear();
    return this._super();
  },
});

function pluckIds(tuples) {
  return tuples.map(([id]) => id);
}
