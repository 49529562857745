/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-jquery */
import $ from 'jquery';
import { Promise as EmberPromise } from 'rsvp';
import ENV from 'embercom/config/environment';

let imageSize = function (src) {
  return new EmberPromise(function (resolve, reject) {
    if (ENV.environment === 'test') {
      resolve({ width: 101, height: 101 });
    } else {
      $('<img/>')
        .attr('src', src)
        .on('error', reject)
        .on('load', function () {
          resolve({ width: this.width, height: this.height });
        });
    }
  });
};

export default imageSize;
