/* RESPONSIBLE TEAM: team-workflows */

import { helper } from '@ember/component/helper';

export function isStatsDisabled([contentFragment, statisticColumn]) {
  if (contentFragment?.contentData?.parallel) {
    return ['engagedPercent'].includes(statisticColumn.valuePath);
  }

  return false;
}

export default helper(isStatsDisabled);
