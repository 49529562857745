/* RESPONSIBLE TEAM: team-frontend-tech */
import { helper as buildHelper } from '@ember/component/helper';

const createdVia = {
  shown_automatically: 0,
  inserted: 2,
  shareable_url: 3,
  help_center: 4,
  triggered_from_code: 7,
  triggered_from_email: 8,
};

export function formatCreatedVia([value]) {
  if (typeof value === 'string') {
    return createdVia[value];
  } else {
    return value;
  }
}

export default buildHelper(formatCreatedVia);
