/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/use-brace-expansion */
import { computed } from '@ember/object';
import DefaultPredicateManager from 'embercom/lib/common/predicates/default-predicate-manager';

export default DefaultPredicateManager.extend({
  isValid: computed(
    'predicate.hasAttributeComparisonAndType',
    'predicate.isKnownOrUnknownComparison',
    'predicate.hasNoValue',
    'predicate.hasValue',
    'value',
    function () {
      let validForNoValue =
        this.get('predicate.isKnownOrUnknownComparison') && this.get('predicate.hasNoValue');
      let validForValue =
        !this.get('predicate.isKnownOrUnknownComparison') && this.get('predicate.hasValue');
      return (
        this.get('predicate.hasAttributeComparisonAndType') && (validForValue || validForNoValue)
      );
    },
  ),

  initializeDefaultValue() {
    if (this.get('predicate.hasNoValue') && !this.get('predicate.isKnownOrUnknownComparison')) {
      this.set('predicate.value', this.getDefaultValue());
    }
  },

  getDefaultValue() {
    return this.get('predicate.optionsForType.firstObject.value');
  },

  changeComparison(comparison) {
    if (this._shouldClearPredicateValueForComparison(comparison)) {
      this._setComparisonAndValue(comparison, null);
    } else if (this._shouldInitializePredicateValueForComparison(comparison)) {
      this._setComparisonAndValue(comparison, this.getDefaultValue());
    } else {
      this.set('predicate.comparison', comparison);
    }
  },

  _shouldClearPredicateValueForComparison(comparison) {
    return (comparison === 'known' || comparison === 'unknown') && this.get('predicate.hasValue');
  },

  _shouldInitializePredicateValueForComparison(comparison) {
    return comparison !== 'known' && comparison !== 'unknown' && this.get('predicate.hasNoValue');
  },

  _setComparisonAndValue(comparison, value) {
    this.setProperties({
      'predicate.value': value,
      'predicate.comparison': comparison,
    });
  },
});
