/* RESPONSIBLE TEAM: team-help-desk-experience */
export default function isActiveElementAnInput() {
  let { tagName, contentEditable } = document.activeElement as HTMLElement;

  return (
    tagName?.toLowerCase() === 'input' ||
    tagName?.toLowerCase() === 'textarea' ||
    contentEditable === 'true'
  );
}
