/* RESPONSIBLE TEAM: team-frontend-tech */
export function initialize(applicationInstance) {
  let userIdleService = applicationInstance.lookup('service:userIdleService');
  userIdleService.registerListeners();
}

export default {
  name: 'user-idle',
  initialize,
};
