/* RESPONSIBLE TEAM: team-growth-opportunities */
/* eslint-disable no-restricted-imports */
import { setOwner } from '@ember/application';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

export default class ConversionModalPlanGroups {
  @service declare intl: IntlService;

  automationPlanGroup: any;
  supportChannelPlanGroup: any;
  ticketingPlanGroup: any;
  conversationManagementPlanGroup: any;
  helpCenterPlanGroup: any;
  botsPlanGroup: any;
  proactiveMessagingChannelsPlanGroup: any;
  messageConfigurationPlanGroup: any;
  messengerCustomizationPlanGroup: any;
  reportingPlanGroup: any;
  dataPlatformPlanGroup: any;
  appsAndIntegrationsPlanGroup: any;
  securityAndPermissionsPlanGroup: any;
  supportAndServicesPlanGroup: any;

  planGroups: any[];

  constructor(owner: any) {
    setOwner(this, owner);
    this.automationPlanGroup = {
      groupName: this.intl.t('modal.cardless-conversion-modal.automation.group-name'),
      features: [
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.automation.fin_ai_for_customer_service.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
          showTextIcon: true,
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.automation.power_fin_with_support_content_from_any_source.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.automation.hand_off_conversations_to_your_support_team.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.automation.conversation_routing_bot.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.automation.bot_rules_(bot_targeting).feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.automation.article_suggestions_bot.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.automation.workflows_basics.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.automation.workflows_advanced_visual_builder.feature-name',
          ),
          starterIconName: '',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.automation.custom_bots_(inbound).feature-name',
          ),
          starterIconName: '',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t('modal.cardless-conversion-modal.automation.apps_in_bots.feature-name'),
          starterIconName: '',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.automation.custom_answers_for_fin.feature-name',
          ),
          starterIconName: '',
          proIconName: 'new',
          premiumIconName: 'new',
          addonName: this.intl.t(
            'modal.cardless-conversion-modal.automation.custom_answers_for_fin.addon-name',
          ),
          addonNameWithFinIcon: true,
        },
      ],
    };
    this.supportChannelPlanGroup = {
      groupName: this.intl.t('modal.cardless-conversion-modal.support_channels.group-name'),
      features: [
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.support_channels.intercom_messenger.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.support_channels.1:1_direct_messages.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t('modal.cardless-conversion-modal.support_channels.email.feature-name'),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.support_channels.facebook_integration.feature-name',
          ),
          starterIconName: '',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.support_channels.instagram_integration.feature-name',
          ),
          starterIconName: '',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.support_channels.whatsapp_integration.feature-name',
          ),
          starterIconName: 'new',
          proIconName: 'new',
          premiumIconName: 'new',
          addonName: this.intl.t(
            'modal.cardless-conversion-modal.support_channels.whatsapp_integration.addon-name',
          ),
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.support_channels.switch_phone_deflection_to_messenger.feature-name',
          ),
          starterIconName: 'new',
          proIconName: 'new',
          premiumIconName: 'new',
          addonName: this.intl.t(
            'modal.cardless-conversion-modal.support_channels.switch_phone_deflection_to_messenger.addon-name',
          ),
        },
      ],
    };
    this.ticketingPlanGroup = {
      groupName: this.intl.t('modal.cardless-conversion-modal.ticketing.group-name'),
      features: [
        {
          name: this.intl.t('modal.cardless-conversion-modal.ticketing.tickets.feature-name'),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.ticketing.conversation_data_attributes.feature-name',
          ),
          starterIconName: '',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.ticketing.conversation_data_routing_rules.feature-name',
          ),
          starterIconName: '',
          proIconName: 'following',
          premiumIconName: 'following',
        },
      ],
    };
    this.conversationManagementPlanGroup = {
      groupName: this.intl.t('modal.cardless-conversion-modal.conversation_management.group-name'),
      features: [
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.conversation_management.inbox.feature-name',
          ),
          starterText: this.intl.t(
            'modal.cardless-conversion-modal.conversation_management.inbox.single',
          ),
          proText: this.intl.t(
            'modal.cardless-conversion-modal.conversation_management.inbox.multiple',
          ),
          premiumText: this.intl.t(
            'modal.cardless-conversion-modal.conversation_management.inbox.multiple',
          ),
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.conversation_management.messenger_visibility.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.conversation_management.conditional_messenger_visibility.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.conversation_management.disable_inbound_conversations.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.conversation_management.require_email_to_chat.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.conversation_management.conversation_tags.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.conversation_management.inbox_views.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.conversation_management.office_hours.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.conversation_management.assign_&_snooze_conversations.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.conversation_management.conversation_ratings.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.conversation_management.macros.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.conversation_management.conversation_tag_rules.feature-name',
          ),
          starterIconName: '',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.conversation_management.assignment_rules.feature-name',
          ),
          starterIconName: '',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.conversation_management.priority_rules.feature-name',
          ),
          starterIconName: '',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.conversation_management.inbound_chat_rules.feature-name',
          ),
          starterIconName: '',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.conversation_management.team_macros.feature-name',
          ),
          starterIconName: '',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.conversation_management.round_robin_assignments.feature-name',
          ),
          starterIconName: '',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.conversation_management.turn_off_gifs/attachments.feature-name',
          ),
          starterIconName: '',
          proIconName: '',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.conversation_management.team_level_office_hours.feature-name',
          ),
          starterIconName: '',
          proIconName: '',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.conversation_management.team_reply_time.feature-name',
          ),
          starterIconName: '',
          proIconName: '',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.conversation_management.teammate_inactivity.feature-name',
          ),
          starterIconName: '',
          proIconName: '',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.conversation_management.sla_rules.feature-name',
          ),
          starterIconName: '',
          proIconName: '',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.conversation_management.workload_management.feature-name',
          ),
          starterIconName: '',
          proIconName: '',
          premiumIconName: 'following',
        },
      ],
    };
    this.helpCenterPlanGroup = {
      groupName: this.intl.t('modal.cardless-conversion-modal.help_center.group-name'),
      features: [
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.help_center.public_help_center.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.help_center.unlimited_articles_&_collections.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.help_center.article_reactions_&_feedback.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.help_center.custom_help_center_branding.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.help_center.custom_help_center_domain.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.help_center.article_reporting_and_insights.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.help_center.articles_in_conversations.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.help_center.mulilingual_help_center.feature-name',
          ),
          starterIconName: '',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.help_center.private_help_center.feature-name',
          ),
          starterIconName: '',
          proIconName: 'following',
          premiumIconName: 'following',
        },
      ],
    };
    this.proactiveMessagingChannelsPlanGroup = {
      groupName: this.intl.t(
        'modal.cardless-conversion-modal.proactive_messaging_channels.group-name',
      ),
      features: [
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.proactive_messaging_channels.intercom_messenger_in_app.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'new',
          premiumIconName: 'new',
          addonName: this.intl.t('modal.cardless-conversion-modal.proactive_support.addon-name'),
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.proactive_messaging_channels.posts.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'new',
          premiumIconName: 'new',
          addonName: this.intl.t('modal.cardless-conversion-modal.proactive_support.addon-name'),
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.proactive_messaging_channels.website_messages.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'new',
          premiumIconName: 'new',
          addonName: this.intl.t('modal.cardless-conversion-modal.proactive_support.addon-name'),
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.proactive_messaging_channels.push_notifications.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'new',
          premiumIconName: 'new',
          addonName: this.intl.t('modal.cardless-conversion-modal.proactive_support.addon-name'),
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.proactive_messaging_channels.message_goals.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'new',
          premiumIconName: 'new',
          addonName: this.intl.t('modal.cardless-conversion-modal.proactive_support.addon-name'),
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.proactive_messaging_channels.message_views.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'new',
          premiumIconName: 'new',
          addonName: this.intl.t('modal.cardless-conversion-modal.proactive_support.addon-name'),
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.proactive_messaging_channels.banner_messages.feature-name',
          ),
          starterIconName: '',
          proIconName: 'new',
          premiumIconName: 'new',
          addonName: this.intl.t('modal.cardless-conversion-modal.proactive_support.addon-name'),
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.proactive_messaging_channels.surveys.feature-name',
          ),
          starterIconName: 'new',
          proIconName: 'new',
          premiumIconName: 'new',
          addonName: this.intl.t(
            'modal.cardless-conversion-modal.proactive_messaging_channels.surveys.addon-name',
          ),
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.proactive_messaging_channels.product_tours.feature-name',
          ),
          starterIconName: 'new',
          proIconName: 'new',
          premiumIconName: 'new',
          addonName: this.intl.t(
            'modal.cardless-conversion-modal.proactive_messaging_channels.product_tours.addon-name',
          ),
        },
      ],
    };
    this.messageConfigurationPlanGroup = {
      groupName: this.intl.t('modal.cardless-conversion-modal.message_configuration.group-name'),
      features: [
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.message_configuration.dynamic_audience_targeting.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'new',
          premiumIconName: 'new',
          addonName: this.intl.t('modal.cardless-conversion-modal.proactive_support.addon-name'),
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.message_configuration.static_audience_targeting.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'new',
          premiumIconName: 'new',
          addonName: this.intl.t('modal.cardless-conversion-modal.proactive_support.addon-name'),
        },
      ],
    };
    this.messengerCustomizationPlanGroup = {
      groupName: this.intl.t('modal.cardless-conversion-modal.messenger_customization.group-name'),
      features: [
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.messenger_customization.customize_messengers_apperance.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.messenger_customization.unbranded_messenger.feature-name',
          ),
          starterIconName: '',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.messenger_customization.customize_launcher_logo.feature-name',
          ),
          starterIconName: '',
          proIconName: '',
          premiumIconName: 'following',
        },
      ],
    };
    this.reportingPlanGroup = {
      groupName: this.intl.t('modal.cardless-conversion-modal.reporting.group-name'),
      features: [
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.reporting.conversation_volume_report.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t('modal.cardless-conversion-modal.reporting.csv_export.feature-name'),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.reporting.reporting_drill_downs.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.reporting.conversations_dashboard.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.reporting.report_sharing.feature-name',
          ),
          starterIconName: '',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.reporting.teams_dashboard.feature-name',
          ),
          starterIconName: '',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.reporting.effectiveness_dashboard.feature-name',
          ),
          starterIconName: '',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.reporting.flexible_metric_aggreations.feature-name',
          ),
          starterIconName: '',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.reporting.conversation_topics.feature-name',
          ),
          starterIconName: '',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.reporting.custom_reports.feature-name',
          ),
          starterIconName: '',
          proIconName: '',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.reporting.real_time_monitoring.feature-name',
          ),
          starterIconName: '',
          proIconName: '',
          premiumIconName: 'following',
        },
      ],
    };
    this.dataPlatformPlanGroup = {
      groupName: this.intl.t('modal.cardless-conversion-modal.data_platform.group-name'),
      features: [
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.data_platform.people_&_company_list.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.data_platform.standard_&_custom_data_attributes.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.data_platform.custom_events.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.data_platform.user_&_company_segments.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.data_platform.test_workspaces.feature-name',
          ),
          starterIconName: '',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.data_platform.custom_api_rate.feature-name',
          ),
          starterIconName: '',
          proIconName: 'new',
          premiumIconName: 'new',
          addonName: this.intl.t(
            'modal.cardless-conversion-modal.data_platform.custom_api_rate.addon-name',
          ),
        },
      ],
    };
    this.appsAndIntegrationsPlanGroup = {
      groupName: this.intl.t('modal.cardless-conversion-modal.apps_&_integrations.group-name'),
      features: [
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.apps_&_integrations.app_store.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.apps_&_integrations.slack_integration.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.apps_&_integrations.messenger_app_targeting.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.apps_&_integrations.pipedrive_integrations.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.apps_&_integrations.hubspot_integration.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.apps_&_integrations.github_integration.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.apps_&_integrations.zendesk_support_integration.feature-name',
          ),
          starterIconName: '',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.apps_&_integrations.salesforce_integration.feature-name',
          ),
          starterIconName: '',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.apps_&_integrations.salesforce_sandbox_support.feature-name',
          ),
          starterIconName: '',
          proIconName: '',
          premiumIconName: 'following',
        },
      ],
    };
    this.securityAndPermissionsPlanGroup = {
      groupName: this.intl.t('modal.cardless-conversion-modal.security_&_permissions.group-name'),
      features: [
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.security_&_permissions.2_factor_authentication.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.security_&_permissions.basic_permissions.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.security_&_permissions.activity_logs.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.security_&_permissions.manual_conversation_restrictions.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.security_&_permissions.google_sso.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.security_&_permissions.role_based_conversation_restrictions.feature-name',
          ),
          starterIconName: '',
          proIconName: 'new',
          premiumIconName: 'new',
          addonName: this.intl.t(
            'modal.cardless-conversion-modal.advanced_workspace_management.addon-name',
          ),
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.security_&_permissions.custom_role_permissions.feature-name',
          ),
          starterIconName: '',
          proIconName: 'new',
          premiumIconName: 'new',
          addonName: this.intl.t(
            'modal.cardless-conversion-modal.advanced_workspace_management.addon-name',
          ),
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.security_&_permissions.third_party_sso_identity_management.feature-name',
          ),
          starterIconName: '',
          proIconName: 'new',
          premiumIconName: 'new',
          addonName: this.intl.t(
            'modal.cardless-conversion-modal.advanced_workspace_management.addon-name',
          ),
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.security_&_permissions.hipaa_support.feature-name',
          ),
          starterIconName: '',
          proIconName: 'new',
          premiumIconName: 'new',
          addonName: this.intl.t(
            'modal.cardless-conversion-modal.advanced_workspace_management.addon-name',
          ),
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.security_&_permissions.multibrand_messenger.feature-name',
          ),
          starterIconName: '',
          proIconName: 'new',
          premiumIconName: 'new',
          addonName: this.intl.t(
            'modal.cardless-conversion-modal.advanced_workspace_management.addon-name',
          ),
        },
      ],
    };
    this.supportAndServicesPlanGroup = {
      groupName: this.intl.t('modal.cardless-conversion-modal.support_&_services.group-name'),
      features: [
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.support_&_services.training_webinars.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
        {
          name: this.intl.t(
            'modal.cardless-conversion-modal.support_&_services.email_&_chat_support.feature-name',
          ),
          starterIconName: 'following',
          proIconName: 'following',
          premiumIconName: 'following',
        },
      ],
    };

    this.planGroups = [
      this.automationPlanGroup,
      this.supportChannelPlanGroup,
      this.ticketingPlanGroup,
      this.conversationManagementPlanGroup,
      this.helpCenterPlanGroup,
      this.proactiveMessagingChannelsPlanGroup,
      this.messageConfigurationPlanGroup,
      this.messengerCustomizationPlanGroup,
      this.reportingPlanGroup,
      this.dataPlatformPlanGroup,
      this.appsAndIntegrationsPlanGroup,
      this.securityAndPermissionsPlanGroup,
      this.supportAndServicesPlanGroup,
    ];
  }
}
