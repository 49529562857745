/* RESPONSIBLE TEAM: team-reporting */
import percent from 'embercom/lib/percentage-formatter';
import { copy } from 'ember-copy';
import { isPresent, isEmpty } from '@ember/utils';
import { SECONDS_IN } from 'embercom/lib/duration-formatter';
import SignalFlexibleResponse from 'embercom/lib/reporting/signal-flexible-response';
import {
  SHOW_TIME_COMPARISON,
  VISUALIZATION_TYPES,
} from 'embercom/models/reporting/custom/visualization-options';

const BUCKETS_THRESHOLD = 10_000;
const RATIO_PERCENTAGE_TYPES = ['ratio', 'percentage'];

function shouldPreProcessResponse(responses, renderableChart) {
  if (responses.includes(undefined) || responses.length < 2) {
    return false;
  } else if (!isPresent(renderableChart) || isEmpty(renderableChart.chartSeries)) {
    return false;
  } else if (
    renderableChart.visualizationType === VISUALIZATION_TYPES.HORIZONTAL_BAR_WITH_COUNTER
  ) {
    return false;
  }

  return renderableChart.metrics.some((metric) => RATIO_PERCENTAGE_TYPES.includes(metric.type));
}

export function processRatioPercentageMetricResponses(
  rawResponses,
  renderableChart,
  useTableRequestNaming = false,
) {
  if (!shouldPreProcessResponse(rawResponses, renderableChart)) {
    return rawResponses;
  }

  return getNewResponses(rawResponses, renderableChart, useTableRequestNaming);
}

function getNewResponses(responses, renderableChart, useTableRequestNaming) {
  if (renderableChart?.showTableSummaryRow) {
    let rowResponses = responses.slice(0, responses.length / 2);
    let summaryRowResponses = responses.slice(responses.length / 2);
    let results = processResults(rowResponses, renderableChart, useTableRequestNaming);
    let summaryRowResults = processResults(
      summaryRowResponses,
      renderableChart,
      useTableRequestNaming,
      true,
    );
    return [...results, ...summaryRowResults].flat().compact();
  }

  return processResults(responses, renderableChart, useTableRequestNaming);
}

export function requestNameFor(metricIndex, metric, useTableRequestNaming) {
  return useTableRequestNaming ? `${metricIndex}-col-metric-${metric.id}` : metric.id;
}

function processResults(responses, renderableChart, useTableRequestNaming, forSummaryRow) {
  let responseIndex = 0;
  let isTimeComparison =
    renderableChart.showTimeComparison && renderableChart.supportsFeature(SHOW_TIME_COMPARISON);
  // with time comparison enabled, for each percentage/ratio metric we need to process the current and
  // previous period response at the same time, so we split the responses in half and process them in pairs
  // all previous period responses precede all current period responses in the array
  // this is because we build the data config for comparisons in this order in comparison-data-config.js
  // for example, for a chart with the following 2 metrics: [A, B] and time comparison enabled
  // the order of the responses will be [A1, B1, A2, B2]
  // where A1 and B1 are the responses for the previous period and A2 and B2 are the responses for the current period
  let previousPeriodResponses = isTimeComparison ? responses.slice(0, responses.length / 2) : [];
  let currentPeriodResponses = isTimeComparison ? responses.slice(responses.length / 2) : responses;

  let previousPeriodResults = [];

  let currentPeriodResults = renderableChart.metrics.map((metric, index) => {
    let requestName = requestNameFor(index, metric, useTableRequestNaming);
    if (!RATIO_PERCENTAGE_TYPES.includes(metric.type)) {
      if (isTimeComparison) {
        previousPeriodResults.push(previousPeriodResponses[responseIndex]);
      }
      return currentPeriodResponses[responseIndex++];
    }

    let numeratorPreviousResponse = isTimeComparison
      ? new SignalFlexibleResponse(previousPeriodResponses[responseIndex])
      : undefined;
    let numeratorResponse = new SignalFlexibleResponse(currentPeriodResponses[responseIndex++]);
    let denominatorPreviousResponse = isTimeComparison
      ? new SignalFlexibleResponse(previousPeriodResponses[responseIndex])
      : undefined;
    let denominatorResponse = new SignalFlexibleResponse(currentPeriodResponses[responseIndex++]);

    let newBuckets = [];
    let newResponse;
    if (numeratorResponse.isSingleResponse()) {
      let result = getAggregationResult(
        metric,
        numeratorResponse.getAggregationForSingleResult(),
        denominatorResponse.getAggregationForSingleResult(),
      );
      newResponse = generateAggregationResponse(
        requestName,
        numeratorResponse.getAggregationNameForSingleResult(),
        result,
      );

      let previousResponse;
      if (isTimeComparison) {
        let previousResult = getAggregationResult(
          metric,
          numeratorPreviousResponse.getAggregationForSingleResult(),
          denominatorPreviousResponse.getAggregationForSingleResult(),
        );
        previousResponse = generateAggregationResponse(
          requestName,
          numeratorPreviousResponse.getAggregationNameForSingleResult(),
          previousResult,
        );
        previousResponse.name = `${previousResponse.name}-previous`;
        newResponse.previousPeriod = previousResponse;
        previousPeriodResults.push(previousResponse);
      }
    } else if (numeratorResponse.isSegmented() || denominatorResponse.isSegmented()) {
      // SegmentBy case
      newBuckets = processSegmentedResponse(
        metric,
        numeratorResponse,
        denominatorResponse,
        renderableChart,
      );
      newResponse = generateSegmentedResponse(newBuckets, requestName);
    } else {
      // ViewBy case
      let limit = renderableChart.viewByDisplayLimit;
      // we won't apply the limit if it is a multimetric chart and we aren't processing the first one (which should be limited)
      if (forSummaryRow || (renderableChart.isMultimetric && index > 0)) {
        limit = BUCKETS_THRESHOLD;
      }
      newBuckets = transformResponse(
        numeratorResponse.forGroup(numeratorResponse.nonSegmentedGroup),
        denominatorResponse.forGroup(denominatorResponse.nonSegmentedGroup),
        metric,
        limit,
        forSummaryRow ? renderableChart.isSegmentedByTime : renderableChart.isBrokenDownByTime,
      );
      newResponse = generateSingleResponse(newBuckets, requestName);

      let previousResponse;
      if (isTimeComparison) {
        let previousBuckets = transformResponse(
          numeratorPreviousResponse.forGroup(numeratorPreviousResponse.nonSegmentedGroup),
          denominatorPreviousResponse.forGroup(denominatorPreviousResponse.nonSegmentedGroup),
          metric,
          renderableChart.viewByDisplayLimit,
          renderableChart.isBrokenDownByTime,
        );
        previousResponse = generateSingleResponse(previousBuckets, requestName);
        previousResponse.name = `${previousResponse.name}-previous`;
        newResponse.previousPeriod = previousResponse;
        previousPeriodResults.push(previousResponse);
      }
    }
    return newResponse;
  });

  return [...previousPeriodResults, ...currentPeriodResults].flat().compact();
}

function processSegmentedResponse(metric, numeratorResponse, denominatorResponse, renderableChart) {
  let numeratorOuterGroups = numeratorResponse.groups;
  let outerBuckets = [];
  let innerKeys = [];
  numeratorOuterGroups.forEach((numeratorOuterGroup, index) => {
    if (index < BUCKETS_THRESHOLD) {
      let key = numeratorOuterGroup.value;
      // For some ratio metrics time keys are expressed in strings, we parse them to int before processing
      let numeratorOuterKey = renderableChart.isBrokenDownByTime ? parseInt(key, 10) : key;
      let denominatorOuterGroup = denominatorResponse.getSegmentedGroupByKey(numeratorOuterKey);
      if (denominatorOuterGroup) {
        let innerBuckets = transformResponse(
          numeratorResponse.forGroup(
            numeratorResponse.getSegmentedGroupFromOuter(numeratorOuterGroup),
          ),
          denominatorResponse.forGroup(
            numeratorResponse.getSegmentedGroupFromOuter(denominatorOuterGroup),
          ),
          metric,
          renderableChart.segmentByDisplayLimit,
          renderableChart.isSegmentedByTime,
          innerKeys,
          numeratorOuterKey,
        );

        let innerGroupValueSum = innerBuckets.reduce((sum, bucket) => {
          let value = bucket.value || 0;
          return sum + value;
        }, 0);
        //add it in the outerBuckets
        outerBuckets.push(
          segmentedBucketMapping(numeratorOuterKey, innerGroupValueSum, innerBuckets),
        );

        //pick the innerKeys for the other inner groups
        if (
          !renderableChart.isSegmentedByTime &&
          (isEmpty(innerKeys) || innerKeys.length < renderableChart.segmentByDisplayLimit)
        ) {
          // get unique list of keys
          let set = new Set([...innerKeys, ...innerBuckets.map((result) => result.innerKey)]);
          innerKeys = Array.from(set);
        }
      }
    }
  });
  //order and limit outer buckets
  if (!renderableChart.isBrokenDownByTime) {
    outerBuckets = sortBySumAndSlice(outerBuckets, renderableChart.viewByDisplayLimit);
  }

  return outerBuckets;
}

function transformResponse(
  numeratorGroup,
  denominatorGroup,
  metric,
  limit,
  isGroupedByTime = false,
  innerKeys = [],
  outerGroupKey = undefined,
) {
  let newBuckets = [];
  let denominatorKeys = denominatorGroup.groupKeys;
  if (isEmpty(innerKeys)) {
    // first bucket, we calculate the new values, sort the result and slice until the limit
    newBuckets = getTransformedBuckets(
      numeratorGroup,
      denominatorGroup,
      metric,
      denominatorKeys,
      isGroupedByTime,
      outerGroupKey,
    );
    if (!isGroupedByTime) {
      newBuckets = sortByValueAndSlice(newBuckets, 0, limit);
    }
  } else {
    // subsequent buckets
    // we process the existent keys firsts, don't need to order them
    let existentKeys = getTransformedBuckets(
      numeratorGroup,
      denominatorGroup,
      metric,
      innerKeys,
      isGroupedByTime,
      outerGroupKey,
    );
    let extraKeys = [];
    // verify if we need to pull more keys in the inner group
    if (innerKeys.length < limit) {
      // remove already used keys from numerator
      let filteredKeys = denominatorKeys.filter((key) => !innerKeys.includes(key));
      // get the more groups
      extraKeys = getTransformedBuckets(
        numeratorGroup,
        denominatorGroup,
        metric,
        filteredKeys,
        isGroupedByTime,
        outerGroupKey,
      );
      if (!isGroupedByTime) {
        extraKeys = sortByValueAndSlice(extraKeys, 0, limit - innerKeys.length);
      }
    }
    newBuckets = [...existentKeys, ...extraKeys];
  }

  return newBuckets;
}

function getTransformedBuckets(
  numeratorGroup,
  denominatorGroup,
  metric,
  processingKeys,
  isGroupedByTime,
  outerGroupKey = undefined,
) {
  let newBuckets = [];
  processingKeys.forEach((processingKey, index) => {
    if (index < BUCKETS_THRESHOLD) {
      // For some ratio metrics time keys are expressed in strings, we parse them to int before processing
      let key = isGroupedByTime ? parseInt(processingKey, 10) : processingKey;

      let denominatorGroupValue = denominatorGroup.getGroupValueByKey(key, isGroupedByTime);
      let denominatorValue = isGroupValueValid(denominatorGroupValue)
        ? denominatorGroupValue.value
        : undefined;
      let numeratorGroupValue = numeratorGroup.getGroupValueByKey(key, isGroupedByTime);
      let numeratorValue = getValidNumeratorValue(numeratorGroupValue, denominatorValue, metric);
      if (isGroupedByTime || shouldProcessBucketValue(numeratorValue, denominatorValue, metric)) {
        let result =
          metric.type === 'ratio'
            ? calculateRatio(numeratorValue, denominatorValue, metric.unit)
            : calculatePercentage(numeratorValue, denominatorValue);
        if (isPresent(outerGroupKey)) {
          newBuckets.push(
            bucketMapping(
              result?.numeratorValue,
              result?.denominatorValue,
              result?.value,
              outerGroupKey,
              key,
            ),
          );
        } else {
          newBuckets.push(
            bucketMapping(result?.numeratorValue, result?.denominatorValue, result?.value, key),
          );
        }
      }
    }
  });
  return newBuckets;
}

function shouldProcessBucketValue(numeratorValue, denominatorValue, metric) {
  return isPresent(denominatorValue);
}

function isGroupValueValid(groupValue) {
  return isPresent(groupValue) && groupValue.index >= 0;
}

function sortByValueAndSlice(newBuckets, index, limit) {
  let result = copy(newBuckets, true);
  result.sort((el1, el2) => el2.value - el1.value);

  return result.slice(index, limit);
}

function sortBySumAndSlice(newBuckets, limit) {
  let result = copy(newBuckets, true);
  result.sort((el1, el2) => el2.innerValueSum - el1.innerValueSum);

  return result.slice(0, limit);
}

function getAggregationResult(metric, numeratorValue, denominatorValue) {
  return metric.type === 'ratio'
    ? calculateRatio(numeratorValue, denominatorValue, metric.unit)
    : calculatePercentage(numeratorValue, denominatorValue);
}

function generateAggregationResponse(name, aggregationName, result) {
  return {
    name,
    groups: [],
    aggregations: [
      {
        name: aggregationName,
        values: [result?.value],
        processedValues: [result],
      },
    ],
  };
}

function generateSingleResponse(newBuckets, name) {
  let singleResponse = {
    name,
    groups: [
      {
        values: [],
        name: '0',
        type: 'time',
        aggregations: [
          {
            name: '0',
            values: [],
          },
        ],
      },
    ],
    aggregations: [],
  };
  singleResponse.groups[0].values = newBuckets.map((result) => result.key);
  singleResponse.groups[0].aggregations[0].values = newBuckets.map((result) => result.value);
  singleResponse.groups[0].aggregations[0].processedValues = newBuckets;
  return singleResponse;
}

function generateSegmentedResponse(newBuckets, name) {
  let segmentedResponse = {
    name,
    groups: [
      {
        values: [],
        name: '0',
        type: 'time',
      },
    ],
    aggregations: [],
  };
  let values = newBuckets.map((bucket) => {
    return {
      value: bucket.outerKey,
      groups: [
        {
          aggregations: [
            {
              name: '0',
              values: bucket.innerBuckets.map((result) => result.value),
              processedValues: bucket.innerBuckets,
            },
          ],
          name: '0',
          type: 'time',
          values: bucket.innerBuckets.map((result) => result.innerKey),
        },
      ],
    };
  });
  segmentedResponse.groups[0].values = values;

  // if there are no values, we need to add an empty aggregation
  // this is consistent with the response structure for non ratio/percentage metrics
  if (isEmpty(values)) {
    segmentedResponse.groups[0].aggregations = [
      {
        values: [],
      },
    ];
  }
  return segmentedResponse;
}

function calculateRatio(numeratorValue, denominatorValue, metricUnit) {
  if (isEmpty(denominatorValue) || denominatorValue === 0) {
    return {
      value: undefined,
      numeratorValue: isEmpty(numeratorValue) ? undefined : numeratorValue,
      denominatorValue: isEmpty(denominatorValue) ? undefined : 0,
    };
  }

  let denominator;
  if (metricUnit === 'valuePerHour') {
    denominator = denominatorValue / SECONDS_IN.hour;
  } else {
    denominator = denominatorValue;
  }
  let result = numeratorValue ? numeratorValue / denominator : 0;
  return {
    value: result,
    numeratorValue,
    denominatorValue,
  };
}

function calculatePercentage(numeratorValue, denominatorValue) {
  let result = percent(denominatorValue, numeratorValue);
  return {
    value: result,
    numeratorValue,
    denominatorValue,
  };
}

function getValidNumeratorValue(numeratorGroupValue, denominatorValue, metric) {
  if (
    metric.type === 'percentage' &&
    isEmpty(numeratorGroupValue?.value) &&
    !isEmpty(denominatorValue) &&
    parseFloat(denominatorValue) > 0
  ) {
    return 0;
  }
  return isGroupValueValid(numeratorGroupValue) ? numeratorGroupValue.value : undefined;
}

function bucketMapping(
  numeratorValue,
  denominatorValue,
  operationValue,
  outerKey,
  innerKey = undefined,
) {
  return {
    key: outerKey,
    ...(innerKey && { innerKey }),
    denominatorValue,
    numeratorValue,
    value: operationValue,
  };
}

function segmentedBucketMapping(outerKey, innerValueSum, innerBuckets) {
  return {
    outerKey,
    innerValueSum,
    innerBuckets,
  };
}
