/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-jquery */
import $ from 'jquery';
export function postNewTab(url, params = {}) {
  let form = generateForm(url, params);
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}

export function generateForm(url, params) {
  let form = document.createElement('form');
  form.setAttribute('target', '_blank');
  form.setAttribute('method', 'post');
  form.setAttribute('action', url);
  let input = document.createElement('input');
  input.type = 'hidden';
  input.name = 'authenticity_token';
  input.value = $('meta[name="csrf-token"]').attr('content');
  form.appendChild(input);

  Object.keys(params).forEach((key) => {
    let input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = params[key];
    form.appendChild(input);
  });
  return form;
}

export default {
  postNewTab,
  generateForm,
};
