/* RESPONSIBLE TEAM: team-help-desk-experience */

// Overrides href-to helper from ember-href-to in order
// to unregister the routeDidChange event handler when the
// helper is torn down. This may not technically be a memory
// leak as metaFor is a weakMap but it stil shows up a lot in
// memory heap dumps and so good to remove the noise
//
// https://github.com/intercom/embercom/pull/76216

import Helper from '@ember/component/helper';
import { getOwner } from '@ember/application';

export function hrefTo(routing, params) {
  return routing.generateURL(...getParamsForGenerateURL(params));
}

function getParamsForGenerateURL(params) {
  params = params.slice(); // create a copy
  let targetRouteName = params.shift(); // the first param is always the target route name
  let lastParam = params[params.length - 1]; // the last param might be queryParams
  let queryParams;
  if (lastParam && lastParam.isQueryParams) {
    queryParams = params.pop().values;
  } else {
    queryParams = {};
  }
  let models = params; // the remainder are the models
  return [targetRouteName, models, queryParams];
}

export default class HrefToHelper extends Helper {
  get routing() {
    return getOwner(this).lookup('service:-routing');
  }

  get router() {
    return getOwner(this).lookup('service:router');
  }

  init() {
    super.init();

    if (this.router && this.router.on) {
      // skip if the router service is mocked
      this._handler = this.recompute.bind(this); // MODIFICATION - TODO UPSTREAM
      this.router.on('routeDidChange', this._handler);
    }
  }

  compute(params, namedArgs) {
    if (namedArgs.params) {
      return hrefTo(this.routing, namedArgs.params);
    } else {
      return hrefTo(this.routing, params);
    }
  }

  // START MODIFICATION - TODO UPSTREAM
  willDestroy() {
    super.willDestroy();

    // skip if the router service is mocked
    if (this._handler && this.router && this.router.off) {
      this.router.off('routeDidChange', this._handler);
      this._handler = undefined;
    }
  }
  // END MODIFICATION
}
