import { Metric } from 'embercom/models/data/pricing/metric-types';

/* RESPONSIBLE TEAM: team-pricing-and-packaging */
export const SURVEYS_PRICING_METRIC = Metric.monthly_surveys_sent;
export const SWITCH_PRICING_METRIC = Metric.monthly_calls_deflected;

// SMS metrics
export const ACTIVE_PHONE_NUMBERS_COUNT_GROUP = 'active_phone_numbers_count';
export const SMS_SEGMENTS_SENT_RECEIVED_GROUP = 'sms_segments_sent_received_monthly';

export const SMS_PHONE_NUMBER_COUNT_METRICS = [
  Metric.active_phone_numbers_count_us,
  Metric.active_phone_numbers_count_uk,
  Metric.active_phone_numbers_count_ca,
  Metric.active_phone_numbers_count_au,
];

export const SMS_SEGMENTS_SENT_RECEIVED_METRICS = [
  Metric.sms_segments_sent_received_monthly_us,
  Metric.sms_segments_sent_received_monthly_uk,
  Metric.sms_segments_sent_received_monthly_ca,
  Metric.sms_segments_sent_received_monthly_au,
];

export const SMS_METRICS = [
  ...SMS_PHONE_NUMBER_COUNT_METRICS,
  ...SMS_SEGMENTS_SENT_RECEIVED_METRICS,
];

export const PRICING_METRIC_GROUPS = [
  ACTIVE_PHONE_NUMBERS_COUNT_GROUP,
  SMS_SEGMENTS_SENT_RECEIVED_GROUP,
];

export const SALES_LED_ONLY_SMS_METRICS = [
  Metric.active_phone_numbers_count_au,
  Metric.sms_segments_sent_received_monthly_au,
  Metric.sms_segments_sent_received_in_shifted_billing_cycle_au,
];

export const PRICING_5_X_AI_HELPDESK_GROUP_KEY = 'ai_helpdesk';
export const PRICING_5_X_AI_BOT_GROUP_KEY = 'ai_bot';
export const PRICING_5_X_SMS_GROUP_KEY = 'sms';
export const PRICING_5_X_WHATSAPP_GROUP_KEY = 'whatsapp';
export const PRICING_5_X_EMAIL_GROUP_KEY = 'email';
export const PRICING_5_X_CALLING_GROUP_KEY = 'calling';

export const PRICING_5_X_GROUPS = {
  [PRICING_5_X_AI_HELPDESK_GROUP_KEY]: [Metric.core_seat_count],
  [PRICING_5_X_AI_BOT_GROUP_KEY]: [Metric.resolutions_with_custom_answers],
  [PRICING_5_X_SMS_GROUP_KEY]: [
    Metric.sms_segments_sent_received_monthly_us,
    Metric.sms_segments_sent_received_monthly_uk,
    Metric.sms_segments_sent_received_monthly_ca,
    Metric.sms_segments_sent_received_monthly_au,
    Metric.sms_segments_sent_received_in_shifted_billing_cycle_us,
    Metric.sms_segments_sent_received_in_shifted_billing_cycle_uk,
    Metric.sms_segments_sent_received_in_shifted_billing_cycle_ca,
    Metric.sms_segments_sent_received_in_shifted_billing_cycle_au,
  ],
  [PRICING_5_X_WHATSAPP_GROUP_KEY]: [Metric.whatsapp_inbound, Metric.whatsapp_outbound],
  [PRICING_5_X_EMAIL_GROUP_KEY]: [Metric.emails_sent],
  [PRICING_5_X_CALLING_GROUP_KEY]: [Metric.calling],
};
