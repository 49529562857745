/* RESPONSIBLE TEAM: team-frontend-tech */
import ApplicationSerializer from 'embercom/serializers/application';

export default {
  name: 'FragmentSerializer',
  after: 'fragmentTransform',
  initialize(application) {
    application.register('serializer:-fragment', ApplicationSerializer);
  },
};
