/* RESPONSIBLE TEAM: team-reporting */
import ChartConfig from 'embercom/lib/reporting/flexible/default-line-chart-config';
import Formatters from 'embercom/lib/reporting/flexible/formatters';
import IntervalFormatter from 'embercom/lib/reporting/flexible/interval-formatter';
import Axis from 'embercom/lib/reporting/flexible/axis';
import moment from 'moment-timezone';
import PALETTE from '@intercom/pulse/lib/palette';

export default class LinechartBuilder {
  constructor({
    range,
    viewConfig,
    xAxisType,
    seriesColors = [
      PALETTE['vis-azure-50'],
      PALETTE['vis-yellow-50'],
      PALETTE['vis-mint-50'],
      PALETTE['vis-salmon-50'],
      PALETTE['vis-slateblue-50'],
      PALETTE['vis-olive-50'],
      PALETTE['vis-pink-50'],
      PALETTE['vis-turquoise-50'],
      PALETTE['vis-orange-50'],
      PALETTE['vis-blue-50'],
    ],
    width = '',
    chartData = [{ data: [] }],
  }) {
    this.range = range;
    this.seriesColors = seriesColors;
    this.xAxisType = xAxisType;
    this.width = width;
    this.chartData = chartData;
    this.viewConfig = viewConfig;
    this.formatter = new Formatters[this.viewConfig.formatUnit.unit](
      this.viewConfig.formatUnit.displayUnit,
    );
    this.yAxisTicker = new Axis[this.viewConfig.formatUnit.unit]();
  }

  get intervalFormatter() {
    return new IntervalFormatter(this.range.interval);
  }
  buildTheme() {
    let config = new ChartConfig(this.range.timezone);

    config.setChartType('line');
    config.setXAxisType(this.isXAxisTemporal ? 'datetime' : 'category');

    if (this.isXAxisTemporal) {
      config.setXAxisTickInterval(this.xAxisTickInterval);
    }
    // Using formatData make it work, but I think it cries out the need to split formatter for axis and tooltip
    config.setYAxisFormatter(({ value }) => this.formatter.formatAxis(value));

    config.setYAxisTickInterval(this.yAxisTickInterval);
    config.setTooltipFormatter(this.tooltipFormatter);

    config.setColors(this.seriesColors);

    return config.config;
  }

  get tooltipFormatter() {
    let config = this;
    let isXAxisTemporal = this.isXAxisTemporal;
    let groupingTransformation = this.viewConfig.grouping?.tooltipTransformation;

    return function () {
      let timezone = this.series.chart.options.time.timezone;
      let formattedData = config.formatter.formatTooltip(
        this.y,
        this.series.options.tooltipDisplayUnit,
      );
      let valuePart = `<strong>${formattedData}</strong><br/>`;
      let datePart;

      if (groupingTransformation) {
        datePart = groupingTransformation(this.point, this.series);
      } else if (isXAxisTemporal) {
        datePart = config.intervalFormatter.datePart(this.point.x, timezone);
      } else {
        datePart = this.point.x;
      }

      let cssClass = 'reporting__highcharts-tooltip';
      return `<div class='${cssClass}'>${valuePart}(${datePart})</div>`;
    };
  }

  get xAxisTickInterval() {
    if (this.isIntervalHourly) {
      return 3600 * 1000;
    } else if (this.isIntervalDaily) {
      return 24 * 3600 * 1000;
    } else if (this.isIntervalWeekly) {
      return 24 * 3600 * 1000 * 7;
    } else {
      return 24 * 3600 * 1000 * 30;
    }
  }

  get yAxisTickInterval() {
    let maxValue = 0;
    let minValue = 0;

    this.chartData.forEach((graphData) => {
      graphData.data.forEach((timestampWithValue) => {
        if (timestampWithValue[1] > maxValue) {
          maxValue = Math.round(timestampWithValue[1]);
        }

        if (timestampWithValue[1] < minValue) {
          minValue = Math.round(timestampWithValue[1]);
        }
      });
    });

    let diff = maxValue - minValue;
    if (diff === 0) {
      return 5; // Used to split the yAxis.softMax option of Highcharts into even ticks when we don't have data.
    }
    return this.yAxisTicker.interval(minValue, maxValue);
  }

  get xAxisFormatter() {
    let config = this;

    return function () {
      let currentLabel = moment.tz(this.value, this.chart.options.time.timezone);
      let format = 'MMM D';
      let maxLabelsForSmallWidths = 8;

      if (config.isIntervalHourly) {
        if (config.width === 'small' && parseInt(currentLabel.format('H'), 10) % 3 !== 0) {
          return '';
        } else {
          format = 'hA';
        }
      }

      if (
        config.isIntervalDaily &&
        config.width === 'small' &&
        config.range.endMoment.diff(config.range.startMoment) >
          maxLabelsForSmallWidths * 1000 * 60 * 60 * 24
      ) {
        if (config.range.startMoment.diff(currentLabel, 'days') % 2 !== 0) {
          return '';
        }
      }

      if (
        config.isIntervalWeekly &&
        config.range.endMoment.diff(config.range.startMoment) >
          maxLabelsForSmallWidths * 1000 * 60 * 60 * 24 * 7
      ) {
        let firstLabel = config.range.startMoment.startOf('isoWeek');

        if (config.width === 'small' && firstLabel.diff(currentLabel, 'days') % 14 !== 0) {
          return '';
        }
      }

      if (config.isIntervalMonthly) {
        if (config.width === 'small' && parseInt(currentLabel.format('M'), 10) % 2 !== 1) {
          return '';
        } else {
          format = 'MMM';
        }
      }

      return currentLabel.format(format);
    };
  }

  get isIntervalHourly() {
    return this.range.interval === 'hour';
  }

  get isIntervalDaily() {
    return this.range.interval === 'day';
  }

  get isIntervalWeekly() {
    return this.range.interval === 'week';
  }

  get isIntervalMonthly() {
    return this.range.interval === 'month';
  }

  get isXAxisTemporal() {
    return this.xAxisType === 'temporal';
  }
}
