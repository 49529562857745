/* RESPONSIBLE TEAM: team-frontend-tech */
import { later, schedule } from '@ember/runloop';
let Run = {};

Run.later = function (context, func, wait) {
  later(
    context,
    function () {
      if (
        !(context.get?.('isDestroyed') || context.get?.('isDestroying')) ||
        !(context.isDestroyed || context.isDestroying)
      ) {
        func.call(context);
      }
    },
    wait,
  );
};

Run.schedule = function (hook, context, func) {
  schedule(hook, context, function () {
    if (
      !(context.get?.('isDestroyed') || context.get?.('isDestroying')) ||
      !(context.isDestroyed || context.isDestroying)
    ) {
      func.call(context);
    }
  });
};

export default Run;
