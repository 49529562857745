/* RESPONSIBLE TEAM: team-reporting */
import ChartConfig from 'embercom/lib/reporting/flexible/default-heatmap-chart-config';
import Formatters, { units } from 'embercom/lib/reporting/flexible/formatters';
import IntervalFormatter from 'embercom/lib/reporting/flexible/interval-formatter';
import Axis from 'embercom/lib/reporting/flexible/axis';
import { buildColorsForHeatmap } from 'embercom/lib/reporting/custom/view-config-builder-helpers';
import { formatRoundedTime } from 'embercom/lib/duration-formatter';

export default class HeatmapBuilder {
  constructor({ range, seriesColors = undefined, width = '', viewConfig, app }) {
    this.range = range;
    this.seriesColors = seriesColors;
    this.width = width;
    this.viewConfig = viewConfig;
    this.formatter = new Formatters[this.viewConfig.formatUnit.unit](
      this.viewConfig.formatUnit?.displayUnit,
    );
    this.xAxis = new Axis[units.hour]();
    this.yAxis = new Axis[units.day]();
    this.app = app;
  }

  get intervalFormatter() {
    let interval = this.range.isSingleDay ? 'day' : 'week';
    return new IntervalFormatter(interval);
  }

  buildTheme() {
    let config = new ChartConfig(this.range.timezone);
    let formatter = this.formatter;

    let labelFormatter = (value) => {
      if (value === null) {
        return '-';
      }

      let roundedValue =
        this.viewConfig.formatUnit?.unit === units.percent ? Math.round(value) : value;
      let formatted;

      if (
        this.viewConfig.formatUnit?.unit === units.seconds ||
        this.viewConfig.formatUnit?.displayUnit === units.minute
      ) {
        formatted = formatRoundedTime(value);
        if (formatted.length > 4) {
          formatted = formatted.slice(0, 4);
        }
      } else if (roundedValue >= 1000000) {
        formatted = Math.round(roundedValue / 1000000)
          .toString()
          .concat('M');
      } else if (roundedValue >= 10000) {
        formatted = Math.round(roundedValue / 1000)
          .toString()
          .concat('k');
      } else {
        formatted = formatter.formatCounter(roundedValue);
      }

      return formatted;
    };

    config.setChartType('heatmap');
    config.setXAxis({
      categories: this.xAxis.labels,
      max: 23,
    });

    config.setColors(buildColorsForHeatmap(this.viewConfig.metrics?.[0]?.id));

    config.setTickPositioner(function () {
      return [this.min, this.max];
    });

    config.setYAxis({
      categories: this.yAxis.labels,
      title: null,
      reversed: true,
    });

    config.setLegendFormatter(function () {
      return formatter.formatCounter(this.value);
    });

    if (this.viewConfig.showDataLabels || this.viewConfig.showDataLabels === undefined) {
      config.config.plotOptions.series.dataLabels = this.dataLabels;
      config.setDataLabelFormatter(function () {
        return labelFormatter(this.point.value);
      });
    } else {
      config.config.plotOptions.series.dataLabels = { enabled: false };
    }

    if (this.viewConfig.useDarkTooltips) {
      config.setTooltipFormatter(this.customTooltipFormatter);
      config.useDarkTooltip();
    } else {
      config.setTooltipFormatter(this.tooltipFormatter);
    }

    if (!this.viewConfig.heightType) {
      config.config.chart.height = null;
    }

    return config.config;
  }

  get dataLabels() {
    return {
      enabled: true,
      style: {
        textOutline: 'none',
      },
    };
  }

  get tooltipFormatter() {
    let config = this;
    return function () {
      let cssClass = 'reporting__highcharts-tooltip';
      let valuePart = `<strong>${config.formatter.formatTooltip(this.point.value)}</strong>`;
      if (this.point.value === null) {
        return;
      }
      return `<div class='${cssClass}'>${valuePart}</div>`;
    };
  }

  get customTooltipFormatter() {
    let config = this;

    return function () {
      let hour = this.point.x;
      let day = config.yAxis.labels[this.point.y];
      let descriptionPart = `${day} at ${hour}:00`;

      let valuePart =
        this.point.value === null ? '-' : config.formatter.formatTooltip(this.point.value);
      let cssClass = 'reporting__highcharts-tooltip';
      return `<div class='${cssClass}'><strong>${valuePart}</strong><br/>(${descriptionPart})</div>`;
    };
  }
}
