/* RESPONSIBLE TEAM: team-messenger */
export default function animateAddScreen(timelineMode) {
  try {
    setTimeout(() => {
      if (timelineMode) {
        animateThumbnailControls();
        animateTimeline();
      } else {
        animateThumbnailControls();
      }
    });
  } catch (e) {
    console.error('Failed to animate add screen');
  }
}

function animateThumbnailControls() {
  animateComponent(
    '.carousel__animation__thumbnail-new-screen',
    '.carousel__animation__thumbnail-control',
  );
}

function animateTimeline() {
  animateComponent(
    '.carousel__animation__add-new-screen',
    '.carousel__animation__screen',
    scrollComponentToView,
  );
}

function animateComponent(addScreenClass, allScreensClass, callback) {
  let addScreenComponent = document.querySelector(addScreenClass);
  let allScreens = document.querySelectorAll(allScreensClass);
  let lastScreen = allScreens[allScreens.length - 1];
  lastScreen.classList.add('o__animate');
  addScreenComponent.classList.add('o__animate');
  addScreenComponent.addEventListener('animationend', () => {
    addScreenComponent.classList.remove('o__animate');
    lastScreen.classList.remove('o__animate');
    if (callback) {
      callback();
    }
  });
}

function scrollComponentToView() {
  let addScreenComponent = document.querySelector('.carousel__animation__add-new-screen');
  addScreenComponent.scrollIntoViewIfNeeded();
}
