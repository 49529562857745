/* RESPONSIBLE TEAM: team-proactive-support */
import { helper } from '@ember/component/helper';
import { isEmpty } from '@ember/utils';

export default helper(function (params) {
  let count = params[0];
  if (isEmpty(count)) {
    return `\u2014`; //em dash
  } else {
    return count;
  }
});
