/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-jquery */
import $ from 'jquery';
import ENV from 'embercom/config/environment';

function closestLink(element) {
  if (element.closest) {
    return element.closest('a');
  } else {
    element = element.parentElement;
    while (element && element.tagName !== 'A') {
      element = element.parentElement;
    }
    return element;
  }
}

function parentComponentElement(element) {
  element = element.parentElement;
  while (element && !element.className.split(' ').includes('ember-view')) {
    element = element.parentElement;
  }
  return element;
}

export function initialize(application) {
  let linkToAnalyticsClickHandler = function (element) {
    let link = element.target.tagName === 'A' ? element.target : closestLink(element.target);
    if (link && link.dataset.hasOwnProperty('trackAnalyticsEventObject')) {
      let controller = application.lookup('controller:application'); //looking up the event service on a controller as our mock service is `service:mockintercomEventService`, not `service:intercomEventService`
      let eventService = controller.intercomEventService;

      let event = {
        action: 'clicked',
        object: link.dataset.trackAnalyticsEventObject,
      };

      if (link.dataset.hasOwnProperty('trackAnalyticsEventDataPropertyName')) {
        let componentElement = parentComponentElement(link);

        if (componentElement) {
          let component = application.lookup('-view-registry:main')[componentElement.id];
          let eventDataPropertyName = link.dataset.trackAnalyticsEventDataPropertyName;

          if (component && eventDataPropertyName) {
            let data = component.get(eventDataPropertyName) || {};
            for (let key in data) {
              if (data.hasOwnProperty(key)) {
                event[key] = data[key];
              }
            }
          }
        }
      }

      eventService.trackAnalyticsEvent(event);
    }
  };

  let $applicationRoot = $(ENV.APP.rootElement);
  $applicationRoot.on('click.link-to-analytics', linkToAnalyticsClickHandler);

  application.reopen({
    willDestroy() {
      $applicationRoot.off('click.link-to-analytics');
      return this._super(...arguments);
    },
  });
}

export default {
  name: 'link-analytics-events',
  initialize,
};
