/* RESPONSIBLE TEAM: team-growth-opportunities */
import type { PricingMetricRange } from 'embercom/models/quote';

export interface FeatureDivider {
  type: 'divider';
  nameKey: string;
  isDescription?: boolean;
  tooltipKey?: string;
  priceRange?: {
    fromPrice: string;
    toPrice: string;
  };
}

export interface FeatureText {
  type: 'text';
  textKey: string;
  expandable?: boolean;
  tooltipKey?: string;
  yearlyBillingSelected?: boolean;
  price?: string;
  discountPrice?: string;
  priceRange?: {
    fromPrice: string;
    toPrice: string;
  };
  usage?: string;
}

interface IPricesArgs {
  proactiveSupportPrice: string;
  phonePrice: string;
  finMetricPrice: string;
  copilotPrice: string;
  copilotDiscountPrice: string;
  whatsAppMetricPrice: PricingMetricRange;
  smsMetricPrice: PricingMetricRange;
  emailMetricPrice: PricingMetricRange;
  proactiveSupportMetricPrice: PricingMetricRange;
  proactiveSupportBaseUsage: string;
  yearlyBillingSelected?: boolean;
}

export interface FeatureIcon {
  type: 'icon';
  name: string;
  color?: string;
  expandable?: boolean;
}

export interface Feature {
  type: 'feature';
  nameKey: string;
  forEssentialPlan?: FeatureText | FeatureIcon;
  forAdvancedPlan?: FeatureText | FeatureIcon;
  forExpertPlan?: FeatureText | FeatureIcon;
  badgeKey?: string;
  tooltipKey?: string;
  descriptionKey?: string;
  subFeatures?: (Feature | FeatureDivider)[];
  titleTooltipKey?: string;
  priceRange?: {
    fromPrice: string;
    toPrice: string;
  };
}

export interface PlanGroup {
  icon: string;
  nameKey: string;
  titleTooltipKey?: string;
  descriptionKey: string;
  features: (Feature | FeatureDivider)[];
}

const followingIcon: FeatureIcon = {
  type: 'icon',
  name: 'following',
  color: 'blue',
};

const checkIcon: FeatureIcon = {
  type: 'icon',
  name: 'check',
  color: 'black',
};

// key plan groups
const proactiveSupportPlusSubFeatures = (proactiveSupportMetricPrice: PricingMetricRange) => {
  return [
    {
      type: 'divider',
      nameKey: 'proactive_support.billed_by_messages_sent.name',
      tooltipKey: 'proactive_support.billed_by_messages_sent.tooltip',
    },
    {
      type: 'feature',
      nameKey: 'proactive_support.billed_by_messages_sent.product_tours',
      titleTooltipKey: 'proactive_support.billed_by_messages_sent.product_tours_tooltip',
      forEssentialPlan: checkIcon,
      forAdvancedPlan: checkIcon,
      forExpertPlan: checkIcon,
    },
    {
      type: 'feature',
      nameKey: 'proactive_support.billed_by_messages_sent.surveys',
      titleTooltipKey: 'proactive_support.billed_by_messages_sent.surveys_tooltip',
      forEssentialPlan: checkIcon,
      forAdvancedPlan: checkIcon,
      forExpertPlan: checkIcon,
    },
    {
      type: 'feature',
      nameKey: 'proactive_support.billed_by_messages_sent.in_app_posts',
      titleTooltipKey: 'proactive_support.billed_by_messages_sent.in_product_messages_tooltip',
      forEssentialPlan: checkIcon,
      forAdvancedPlan: checkIcon,
      forExpertPlan: checkIcon,
    },
    {
      type: 'feature',
      nameKey: 'proactive_support.billed_by_messages_sent.mobile_push_messages',
      titleTooltipKey: 'proactive_support.billed_by_messages_sent.mobile_push_messages_tooltip',
      forEssentialPlan: checkIcon,
      forAdvancedPlan: checkIcon,
      forExpertPlan: checkIcon,
    },
    {
      type: 'feature',
      nameKey: 'proactive_support.billed_by_messages_sent.mobile_carousels',
      titleTooltipKey: 'proactive_support.billed_by_messages_sent.mobile_carousels_tooltip',
      forEssentialPlan: checkIcon,
      forAdvancedPlan: checkIcon,
      forExpertPlan: checkIcon,
    },
    {
      type: 'divider',
      nameKey: 'proactive_support.free_to_use.name',
    },
    {
      type: 'feature',
      nameKey: 'proactive_support.free_to_use.checklists',
      titleTooltipKey: 'proactive_support.free_to_use.checklists_tooltip',
      forEssentialPlan: checkIcon,
      forAdvancedPlan: checkIcon,
      forExpertPlan: checkIcon,
    },
    {
      type: 'feature',
      nameKey: 'proactive_support.free_to_use.news',
      titleTooltipKey: 'proactive_support.free_to_use.news_tooltip',
      forEssentialPlan: checkIcon,
      forAdvancedPlan: checkIcon,
      forExpertPlan: checkIcon,
    },
    {
      type: 'feature',
      nameKey: 'proactive_support.free_to_use.webhooks',
      titleTooltipKey: 'proactive_support.free_to_use.webhooks_tooltip',
      forEssentialPlan: checkIcon,
      forAdvancedPlan: checkIcon,
      forExpertPlan: checkIcon,
    },
    {
      type: 'divider',
      nameKey: 'proactive_support.message_optimisation.name',
    },
    {
      type: 'feature',
      nameKey: 'proactive_support.free_to_use.series_campaign_builder',
      titleTooltipKey: 'proactive_support.free_to_use.series_campaign_builder_tooltip',
      forEssentialPlan: checkIcon,
      forAdvancedPlan: checkIcon,
      forExpertPlan: checkIcon,
    },
    {
      type: 'feature',
      nameKey: 'proactive_support.message_optimisation.a_b_testing',
      titleTooltipKey: 'proactive_support.message_optimisation.a_b_testing_tooltip',
      forEssentialPlan: checkIcon,
      forAdvancedPlan: checkIcon,
      forExpertPlan: checkIcon,
    },
    {
      type: 'feature',
      nameKey: 'proactive_support.message_optimisation.message_targeting',
      titleTooltipKey: 'proactive_support.message_optimisation.message_targeting_tooltip',
      forEssentialPlan: checkIcon,
      forAdvancedPlan: checkIcon,
      forExpertPlan: checkIcon,
    },
    {
      type: 'feature',
      nameKey: 'proactive_support.message_optimisation.scheduled_messages',
      titleTooltipKey: 'proactive_support.message_optimisation.scheduled_messages_tooltip',
      forEssentialPlan: checkIcon,
      forAdvancedPlan: checkIcon,
      forExpertPlan: checkIcon,
    },
    {
      type: 'feature',
      nameKey: 'proactive_support.message_optimisation.event_triggered_messages',
      titleTooltipKey: 'proactive_support.message_optimisation.event_triggered_messages_tooltip',
      forEssentialPlan: checkIcon,
      forAdvancedPlan: checkIcon,
      forExpertPlan: checkIcon,
    },
    {
      type: 'feature',
      nameKey: 'proactive_support.message_optimisation.message_tags',
      titleTooltipKey: 'proactive_support.message_optimisation.message_tags_tooltip',
      forEssentialPlan: checkIcon,
      forAdvancedPlan: checkIcon,
      forExpertPlan: checkIcon,
    },
    {
      type: 'feature',
      nameKey: 'proactive_support.message_optimisation.message_goals',
      titleTooltipKey: 'proactive_support.message_optimisation.message_goals_tooltip',
      forEssentialPlan: checkIcon,
      forAdvancedPlan: checkIcon,
      forExpertPlan: checkIcon,
    },
    {
      type: 'feature',
      nameKey: 'proactive_support.message_optimisation.message_localization',
      titleTooltipKey: 'proactive_support.message_optimisation.message_localization_tooltip',
      forEssentialPlan: checkIcon,
      forAdvancedPlan: checkIcon,
      forExpertPlan: checkIcon,
    },
    {
      type: 'feature',
      nameKey: 'proactive_support.message_optimisation.inbound_messaging',
      titleTooltipKey: 'proactive_support.message_optimisation.inbound_messaging_tooltip',
      forEssentialPlan: checkIcon,
      forAdvancedPlan: checkIcon,
      forExpertPlan: checkIcon,
    },
    {
      type: 'feature',
      nameKey: 'proactive_support.message_optimisation.disable_inbound_messages',
      titleTooltipKey: 'proactive_support.message_optimisation.disable_inbound_messages_tooltip',
      forEssentialPlan: checkIcon,
      forAdvancedPlan: checkIcon,
      forExpertPlan: checkIcon,
    },
    {
      type: 'feature',
      nameKey: 'proactive_support.message_optimisation.leads_email_messaging',
      titleTooltipKey: 'proactive_support.message_optimisation.leads_email_messaging_tooltip',
      forEssentialPlan: checkIcon,
      forAdvancedPlan: checkIcon,
      forExpertPlan: checkIcon,
    },
    {
      type: 'divider',
      isDescription: true,
      nameKey: 'proactive_support.message_optimisation.add_to_any_plan',
      tooltipKey: 'proactive_support.message_optimisation.add_to_any_plan_tooltip',
      priceRange: {
        fromPrice: proactiveSupportMetricPrice?.from,
        toPrice: proactiveSupportMetricPrice?.to,
      },
    },
  ];
};

const aiHelpDeskKeyPlanGroup: PlanGroup = {
  icon: 'inbox',
  nameKey: 'ai_help_desk.group-name',
  descriptionKey: 'ai_help_desk.description',
  features: [
    {
      type: 'feature',
      nameKey: 'ai_help_desk.inbox.shared_inbox',
      titleTooltipKey: 'ai_help_desk.inbox.shared_inbox_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.inbox.inbox_views',
      titleTooltipKey: 'ai_help_desk.inbox.inbox_views_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.inbox.fin_ai_assist',
      titleTooltipKey: 'ai_help_desk.inbox.fin_ai_assist_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.automation.basic_chatbots_and_automation',
      titleTooltipKey: 'platform.automation.basic_chatbots_and_automation_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.tickets.ticketing_system',
      titleTooltipKey: 'ai_help_desk.tickets.ticketing_system_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.help_center.public_help_center',
      titleTooltipKey: 'ai_help_desk.help_center.public_help_center_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.help_center.unlimited_articles_&_collections',
      titleTooltipKey: 'ai_help_desk.help_center.unlimited_articles_&_collections_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.help_center.help_center_customization',
      titleTooltipKey: 'ai_help_desk.help_center.help_center_customization_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.inbox.multiple_team_inboxes',
      titleTooltipKey: 'ai_help_desk.inbox.multiple_team_inboxes_tooltip',
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.inbox.fin_ai_summarize',
      titleTooltipKey: 'ai_help_desk.inbox.fin_ai_summarize_tooltip',
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.automation.workflows_for_advanced_automation',
      titleTooltipKey: 'platform.automation.workflows_for_advanced_automation_tooltip',
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.help_center.mutilingual_help_center',
      titleTooltipKey: 'ai_help_desk.help_center.mutilingual_help_center_tooltip',
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.inbox.workload_management',
      titleTooltipKey: 'ai_help_desk.inbox.workload_management_tooltip',
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.help_center.multi_help_center',
      titleTooltipKey: 'ai_help_desk.help_center.multi_help_center_tooltip',
      forExpertPlan: followingIcon,
    },
  ],
};

const aiCopilotPlanGroup = (
  copilotPrice: string,
  copilotDiscountPrice: string,
  yearlyBillingSelected: boolean,
): PlanGroup => {
  return {
    icon: 'fin',
    nameKey: 'ai_copilot.group-name',
    descriptionKey: 'ai_copilot.description',
    features: [
      {
        type: 'feature',
        nameKey: 'ai_copilot.included_usage',
        titleTooltipKey: 'ai_copilot.included_usage_comparison_modal_tooltip',
        forAdvancedPlan: {
          type: 'text',
          textKey: 'ai_copilot.included_usage_description',
          tooltipKey: 'ai_copilot.included_usage_tooltip',
        },
      },
      {
        type: 'feature',
        nameKey: 'ai_copilot.unlimited_usage',
        titleTooltipKey: 'ai_copilot.unlimited_usage_comparison_modal_tooltip',
        forAdvancedPlan: {
          yearlyBillingSelected,
          type: 'text',
          textKey: yearlyBillingSelected
            ? 'ai_copilot.upgrade_to_unlimited_annual'
            : 'ai_copilot.upgrade_to_unlimited',
          price: copilotPrice,
          discountPrice: copilotDiscountPrice,
        },
      },
      {
        type: 'feature',
        nameKey: 'ai_copilot.conversation_history',
        titleTooltipKey: 'ai_copilot.conversation_history_comparison_modal_tooltip',
        forEssentialPlan: followingIcon,
        forAdvancedPlan: followingIcon,
        forExpertPlan: followingIcon,
      },
      {
        type: 'feature',
        nameKey: 'ai_copilot.external_internal_ressource',
        titleTooltipKey: 'ai_copilot.external_internal_ressource_comparison_modal_tooltip',
        forEssentialPlan: followingIcon,
        forAdvancedPlan: followingIcon,
        forExpertPlan: followingIcon,
      },
      {
        type: 'feature',
        nameKey: 'ai_copilot.reporting',
        titleTooltipKey: 'ai_copilot.reporting_comparison_modal_tooltip',
        forEssentialPlan: followingIcon,
        forAdvancedPlan: followingIcon,
        forExpertPlan: followingIcon,
      },
      {
        type: 'feature',
        nameKey: 'ai_copilot.multilingual',
        titleTooltipKey: 'ai_copilot.multilingual_comparison_modal_tooltip',
        forEssentialPlan: followingIcon,
        forAdvancedPlan: followingIcon,
        forExpertPlan: followingIcon,
      },
    ],
  };
};

const aiBotKeyPlanGroup = (finMetricPrice: string) => {
  return {
    icon: 'fin',
    nameKey: 'ai_bots.group-name',
    descriptionKey: 'ai_bots.description',
    features: [
      {
        type: 'feature',
        nameKey: 'ai_bots.fin_ai_bot',
        titleTooltipKey: 'ai_bots.fin_ai_bot_tooltip',
        forAdvancedPlan: {
          type: 'text',
          textKey: 'ai_bots.fin_resolution_price',
          tooltipKey: 'ai_bots.fin_resolution_price_tooltip',
          price: finMetricPrice,
        },
      },
      {
        type: 'feature',
        nameKey: 'ai_bots.fin_ai_answers',
        titleTooltipKey: 'ai_bots.fin_ai_answers_tooltip',
        forEssentialPlan: followingIcon,
        forAdvancedPlan: followingIcon,
        forExpertPlan: followingIcon,
      },
      {
        type: 'feature',
        nameKey: 'ai_bots.fin_custom_answers',
        titleTooltipKey: 'ai_bots.fin_custom_answers_tooltip',
        forEssentialPlan: followingIcon,
        forAdvancedPlan: followingIcon,
        forExpertPlan: followingIcon,
      },
      {
        type: 'feature',
        nameKey: 'ai_bots.multiple_content_sources',
        titleTooltipKey: 'ai_bots.multiple_content_sources_tooltip',
        forEssentialPlan: followingIcon,
        forAdvancedPlan: followingIcon,
        forExpertPlan: followingIcon,
      },
      {
        type: 'feature',
        nameKey: 'ai_bots.content_targeting',
        titleTooltipKey: 'ai_bots.content_targeting_tooltip',
        forEssentialPlan: followingIcon,
        forAdvancedPlan: followingIcon,
        forExpertPlan: followingIcon,
      },
      {
        type: 'feature',
        nameKey: 'ai_bots.workflows_for_fin',
        titleTooltipKey: 'ai_bots.workflows_for_fin_tooltip',
        forAdvancedPlan: followingIcon,
        forExpertPlan: followingIcon,
      },
    ],
  };
};

const proactiveSupportKeyPlanGroup = (
  proactiveSupportPrice: string,
  proactiveSupportMetricPrice: PricingMetricRange,
  proactiveSupportBaseUsage: string,
) => {
  return {
    icon: 'outbound-filled',
    nameKey: 'proactive_support.group-name',
    descriptionKey: 'proactive_support.description',
    features: [
      {
        type: 'feature',
        nameKey: 'proactive_support.in_app_chats',
        titleTooltipKey: 'proactive_support.in_app_chats_tooltip',
        forEssentialPlan: followingIcon,
        forAdvancedPlan: followingIcon,
        forExpertPlan: followingIcon,
      },
      {
        type: 'feature',
        nameKey: 'proactive_support.banner_messages',
        titleTooltipKey: 'proactive_support.banner_messages_tooltip',
        forEssentialPlan: followingIcon,
        forAdvancedPlan: followingIcon,
        forExpertPlan: followingIcon,
      },
      {
        type: 'feature',
        nameKey: 'proactive_support.tooltips',
        titleTooltipKey: 'proactive_support.tooltips_tooltip',
        forEssentialPlan: followingIcon,
        forAdvancedPlan: followingIcon,
        forExpertPlan: followingIcon,
      },
      {
        type: 'feature',
        nameKey: 'proactive_support.proactive_support_plus',
        titleTooltipKey: 'proactive_support.proactive_support_plus_tooltip',
        badgeKey: 'proactive_support.badge',
        descriptionKey: 'proactive_support.proactive_support_plus_description',
        forAdvancedPlan: {
          type: 'text',
          expandable: true,
          textKey: 'proactive_support.proactive_support_plus_price',
          price: proactiveSupportPrice,
          usage: proactiveSupportBaseUsage,
        },
        subFeatures: proactiveSupportPlusSubFeatures(proactiveSupportMetricPrice),
      },
    ],
  };
};

const channelsSupportKeyPlanGroup = (
  phonePrice: string,
  whatsAppMetricPrice: PricingMetricRange,
  smsMetricPrice: PricingMetricRange,
  emailMetricPrice: PricingMetricRange,
) => {
  return {
    icon: 'multiplatform',
    nameKey: 'channels.group-name',
    descriptionKey: 'channels.description',
    features: [
      {
        type: 'feature',
        nameKey: 'channels.live_chat_through_messenger',
        titleTooltipKey: 'channels.live_chat_through_messenger_tooltip',
        forEssentialPlan: followingIcon,
        forAdvancedPlan: followingIcon,
        forExpertPlan: followingIcon,
      },
      {
        type: 'feature',
        nameKey: 'channels.email',
        titleTooltipKey: 'channels.email_tooltip',
        forEssentialPlan: followingIcon,
        forAdvancedPlan: followingIcon,
        forExpertPlan: followingIcon,
      },
      {
        type: 'feature',
        nameKey: 'channels.social_channels',
        titleTooltipKey: 'channels.social_channels_tooltip',
        forAdvancedPlan: followingIcon,
        forExpertPlan: followingIcon,
      },
      {
        type: 'feature',
        nameKey: 'channels.email_campaigns',
        titleTooltipKey: 'channels.email_campaigns_tooltip',
        forAdvancedPlan: {
          type: 'text',
          textKey: 'channels.email_campaigns_price',
          tooltipKey: 'channels.email_campaigns_price_tooltip',
          priceRange: {
            fromPrice: emailMetricPrice?.from,
            toPrice: emailMetricPrice?.to,
          },
        },
      },
      {
        type: 'feature',
        nameKey: 'channels.sms',
        titleTooltipKey: 'channels.sms_tooltip',
        forAdvancedPlan: {
          type: 'text',
          textKey: 'channels.sms_price',
          tooltipKey: 'channels.sms_price_tooltip',
          priceRange: {
            fromPrice: smsMetricPrice?.from,
            toPrice: smsMetricPrice?.to,
          },
        },
      },
      {
        type: 'feature',
        nameKey: 'channels.whatsapp',
        titleTooltipKey: 'channels.whatsapp_tooltip',
        forAdvancedPlan: {
          type: 'text',
          textKey: 'channels.whatsapp_price',
          tooltipKey: 'channels.whatsapp_price_tooltip',
          priceRange: {
            fromPrice: whatsAppMetricPrice?.from,
            toPrice: whatsAppMetricPrice?.to,
          },
        },
      },
      {
        type: 'feature',
        nameKey: 'channels.phone',
        titleTooltipKey: 'channels.phone_tooltip',
        descriptionKey: 'channels.phone_description',
        forAdvancedPlan: {
          type: 'text',
          textKey: 'channels.phone_price',
          price: phonePrice,
        },
      },
    ],
  };
};

const platformsKeyPlanGroup: PlanGroup = {
  icon: 'webhook',
  nameKey: 'platform.group-name',
  descriptionKey: 'platform.description',
  features: [
    {
      type: 'feature',
      nameKey: 'platform.custom_objects',
      titleTooltipKey: 'platform.custom_objects_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.custom_events',
      titleTooltipKey: 'platform.custom_events_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.reporting.pre_built_reports',
      titleTooltipKey: 'platform.reporting.pre_built_reports_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.reporting.custom_reports',
      titleTooltipKey: 'platform.reporting.custom_reports_tooltip',
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.custom_roles',
      titleTooltipKey: 'platform.custom_roles_tooltip',
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.sso_&_identity_management',
      titleTooltipKey: 'platform.sso_&_identity_management_tooltip',
      forExpertPlan: followingIcon,
    },
  ],
};

// all plan groups
const aiHelpDeskPlanGroup: PlanGroup = {
  icon: 'inbox',
  nameKey: 'ai_help_desk.group-name',
  descriptionKey: 'ai_help_desk.description',
  features: [
    {
      type: 'divider',
      nameKey: 'ai_help_desk.inbox.name',
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.inbox.shared_inbox',
      titleTooltipKey: 'ai_help_desk.inbox.shared_inbox_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.inbox.inbox_views',
      titleTooltipKey: 'ai_help_desk.inbox.inbox_views_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.inbox.fin_ai_assist',
      titleTooltipKey: 'ai_help_desk.inbox.fin_ai_assist_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.inbox.macros',
      titleTooltipKey: 'ai_help_desk.inbox.macros_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.inbox.assign_&_snooze_conversations',
      titleTooltipKey: 'ai_help_desk.inbox.assign_&_snooze_conversations_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.inbox.office_hours',
      titleTooltipKey: 'ai_help_desk.inbox.office_hours_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.automation.basic_chatbots_and_automation',
      titleTooltipKey: 'platform.automation.basic_chatbots_and_automation_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.inbox.conversation_data_attributes',
      titleTooltipKey: 'ai_help_desk.inbox.conversation_data_attributes_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.inbox.conversation_ratings',
      titleTooltipKey: 'ai_help_desk.inbox.conversation_ratings_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.automation.advanded_qualification',
      titleTooltipKey: 'platform.automation.advanded_qualification_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.inbox.multiple_team_inboxes',
      titleTooltipKey: 'ai_help_desk.inbox.multiple_team_inboxes_tooltip',
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.inbox.fin_ai_summarize',
      titleTooltipKey: 'ai_help_desk.inbox.fin_ai_summarize_tooltip',
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.automation.workflows_for_advanced_automation',
      titleTooltipKey: 'platform.automation.workflows_for_advanced_automation_tooltip',
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.inbox.round_robin_assignment',
      titleTooltipKey: 'ai_help_desk.inbox.round_robin_assignment_tooltip',
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.reporting.conversation_topics',
      titleTooltipKey: 'platform.reporting.conversation_topics_tooltip',
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.automation.teammate_inactivity',
      titleTooltipKey: 'platform.automation.teammate_inactivity_tooltip',
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.inbox.workload_management',
      titleTooltipKey: 'ai_help_desk.inbox.workload_management_tooltip',
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.inbox.team_office_hours',
      titleTooltipKey: 'ai_help_desk.inbox.team_office_hours_tooltip',
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.automation.service_level_agreements',
      titleTooltipKey: 'platform.automation.service_level_agreements_tooltip',
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.help_center.account_ownership',
      titleTooltipKey: 'ai_help_desk.help_center.account_ownership_tooltip',
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.inbox.life_seats.name',
      titleTooltipKey: 'ai_help_desk.inbox.life_seats_tooltip',
      forAdvancedPlan: {
        type: 'text',
        textKey: 'ai_help_desk.inbox.life_seats.20_incldued',
      },
      forExpertPlan: {
        type: 'text',
        textKey: 'ai_help_desk.inbox.life_seats.50_incldued',
      },
    },
    {
      type: 'divider',
      nameKey: 'ai_help_desk.tickets.name',
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.tickets.ticketing_system',
      titleTooltipKey: 'ai_help_desk.tickets.ticketing_system_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.tickets.ticket_forms',
      titleTooltipKey: 'ai_help_desk.tickets.ticket_forms_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.tickets.ticket_data_attributes',
      titleTooltipKey: 'ai_help_desk.tickets.ticket_data_attributes_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.tickets.side_conversations',
      titleTooltipKey: 'ai_help_desk.tickets.side_conversations_tooltip',
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.tickets.ticket_portal',
      titleTooltipKey: 'ai_help_desk.tickets.ticket_portal_tooltip',
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'divider',
      nameKey: 'ai_help_desk.help_center.name',
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.help_center.public_help_center',
      titleTooltipKey: 'ai_help_desk.help_center.public_help_center_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.help_center.unlimited_articles_&_collections',
      titleTooltipKey: 'ai_help_desk.help_center.unlimited_articles_&_collections_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.help_center.help_center_customization',
      titleTooltipKey: 'ai_help_desk.help_center.help_center_customization_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.help_center.article_suggestions',
      titleTooltipKey: 'ai_help_desk.help_center.article_suggestions_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.help_center.mutilingual_help_center',
      titleTooltipKey: 'ai_help_desk.help_center.mutilingual_help_center_tooltip',
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.help_center.private_help_center',
      titleTooltipKey: 'ai_help_desk.help_center.private_help_center_tooltip',
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.help_center.unbranded_help_center',
      titleTooltipKey: 'ai_help_desk.help_center.unbranded_help_center_tooltip',
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.help_center.multi_help_center',
      titleTooltipKey: 'ai_help_desk.help_center.multi_help_center_tooltip',
      forExpertPlan: followingIcon,
    },
  ],
};

const aiBotPlanGroup = (finMetricPrice: string) => {
  return {
    icon: 'fin',
    nameKey: 'ai_bots.group-name',
    descriptionKey: 'ai_bots.description',
    features: [
      {
        type: 'feature',
        nameKey: 'ai_bots.fin_ai_bot',
        titleTooltipKey: 'ai_bots.fin_ai_bot_tooltip',
        forAdvancedPlan: {
          type: 'text',
          textKey: 'ai_bots.fin_resolution_price',
          tooltipKey: 'ai_bots.fin_resolution_price_tooltip',
          price: finMetricPrice,
        },
      },
      {
        type: 'feature',
        nameKey: 'ai_bots.fin_ai_answers',
        titleTooltipKey: 'ai_bots.fin_ai_answers_tooltip',
        forEssentialPlan: followingIcon,
        forAdvancedPlan: followingIcon,
        forExpertPlan: followingIcon,
      },
      {
        type: 'feature',
        nameKey: 'ai_bots.fin_custom_answers',
        titleTooltipKey: 'ai_bots.fin_custom_answers_tooltip',
        forEssentialPlan: followingIcon,
        forAdvancedPlan: followingIcon,
        forExpertPlan: followingIcon,
      },
      {
        type: 'feature',
        nameKey: 'ai_bots.multiple_content_sources',
        titleTooltipKey: 'ai_bots.multiple_content_sources_tooltip',
        forEssentialPlan: followingIcon,
        forAdvancedPlan: followingIcon,
        forExpertPlan: followingIcon,
      },
      {
        type: 'feature',
        nameKey: 'ai_bots.conversation_snippets',
        titleTooltipKey: 'ai_bots.conversation_snippets_tooltip',
        forEssentialPlan: followingIcon,
        forAdvancedPlan: followingIcon,
        forExpertPlan: followingIcon,
      },
      {
        type: 'feature',
        nameKey: 'ai_bots.snippets',
        titleTooltipKey: 'ai_bots.snippets_tooltip',
        forEssentialPlan: followingIcon,
        forAdvancedPlan: followingIcon,
        forExpertPlan: followingIcon,
      },
      {
        type: 'feature',
        nameKey: 'ai_bots.content_targeting',
        titleTooltipKey: 'ai_bots.content_targeting_tooltip',
        forEssentialPlan: followingIcon,
        forAdvancedPlan: followingIcon,
        forExpertPlan: followingIcon,
      },
      {
        type: 'feature',
        nameKey: 'ai_bots.targeting_&_scheduling',
        titleTooltipKey: 'ai_bots.targeting_&_scheduling_tooltip',
        forEssentialPlan: followingIcon,
        forAdvancedPlan: followingIcon,
        forExpertPlan: followingIcon,
      },
      {
        type: 'feature',
        nameKey: 'ai_bots.fin_conversation_monitoring',
        titleTooltipKey: 'ai_bots.fin_conversation_monitoring_tooltip',
        forEssentialPlan: followingIcon,
        forAdvancedPlan: followingIcon,
        forExpertPlan: followingIcon,
      },
      {
        type: 'feature',
        nameKey: 'ai_bots.usage_limits_and_notifications',
        titleTooltipKey: 'ai_bots.usage_limits_and_notifications_tooltip',
        forEssentialPlan: followingIcon,
        forAdvancedPlan: followingIcon,
        forExpertPlan: followingIcon,
      },
      {
        type: 'feature',
        nameKey: 'ai_bots.fin_reports',
        titleTooltipKey: 'ai_bots.fin_reports_tooltip',
        forEssentialPlan: followingIcon,
        forAdvancedPlan: followingIcon,
        forExpertPlan: followingIcon,
      },
    ],
  };
};

const proactiveSupportPlanGroup = (
  proactiveSupportPrice: string,
  proactiveSupportMetricPrice: PricingMetricRange,
  proactiveSupportBaseUsage: string,
) => {
  return {
    icon: 'outbound-filled',
    nameKey: 'proactive_support.group-name',
    descriptionKey: 'proactive_support.description',
    features: [
      {
        type: 'feature',
        nameKey: 'proactive_support.in_app_chats',
        titleTooltipKey: 'proactive_support.in_app_chats_tooltip',
        forEssentialPlan: followingIcon,
        forAdvancedPlan: followingIcon,
        forExpertPlan: followingIcon,
      },
      {
        type: 'feature',
        nameKey: 'proactive_support.banner_messages',
        titleTooltipKey: 'proactive_support.banner_messages_tooltip',
        forEssentialPlan: followingIcon,
        forAdvancedPlan: followingIcon,
        forExpertPlan: followingIcon,
      },
      {
        type: 'feature',
        nameKey: 'proactive_support.tooltips',
        titleTooltipKey: 'proactive_support.tooltips_tooltip',
        forEssentialPlan: followingIcon,
        forAdvancedPlan: followingIcon,
        forExpertPlan: followingIcon,
      },
      {
        type: 'feature',
        nameKey: 'proactive_support.proactive_support_plus',
        titleTooltipKey: 'proactive_support.proactive_support_plus_tooltip',
        badgeKey: 'proactive_support.badge',
        descriptionKey: 'proactive_support.proactive_support_plus_description',
        forAdvancedPlan: {
          type: 'text',
          expandable: true,
          textKey: 'proactive_support.proactive_support_plus_price',
          price: proactiveSupportPrice,
          usage: proactiveSupportBaseUsage,
        },
        subFeatures: proactiveSupportPlusSubFeatures(proactiveSupportMetricPrice),
      },
    ],
  };
};

const channelsSupportPlanGroup = (
  phonePrice: string,
  whatsAppMetricPrice: PricingMetricRange,
  smsMetricPrice: PricingMetricRange,
  emailMetricPrice: PricingMetricRange,
) => {
  return {
    icon: 'multiplatform',
    nameKey: 'channels.group-name',
    descriptionKey: 'channels.description',
    features: [
      {
        type: 'feature',
        nameKey: 'channels.live_chat_through_messenger',
        titleTooltipKey: 'channels.live_chat_through_messenger_tooltip',
        forEssentialPlan: followingIcon,
        forAdvancedPlan: followingIcon,
        forExpertPlan: followingIcon,
      },
      {
        type: 'feature',
        nameKey: 'channels.email',
        titleTooltipKey: 'channels.email_tooltip',
        forEssentialPlan: followingIcon,
        forAdvancedPlan: followingIcon,
        forExpertPlan: followingIcon,
      },
      {
        type: 'feature',
        nameKey: 'channels.social_channels',
        titleTooltipKey: 'channels.social_channels_tooltip',
        forAdvancedPlan: followingIcon,
        forExpertPlan: followingIcon,
      },
      {
        type: 'feature',
        nameKey: 'channels.email_campaigns',
        titleTooltipKey: 'channels.email_campaigns_tooltip',
        forAdvancedPlan: {
          type: 'text',
          textKey: 'channels.email_campaigns_price',
          tooltipKey: 'channels.email_campaigns_price_tooltip',
          priceRange: {
            fromPrice: emailMetricPrice?.from,
            toPrice: emailMetricPrice?.to,
          },
        },
      },
      {
        type: 'feature',
        nameKey: 'channels.sms',
        titleTooltipKey: 'channels.sms_tooltip',
        forAdvancedPlan: {
          type: 'text',
          textKey: 'channels.sms_price',
          tooltipKey: 'channels.sms_price_tooltip',
          priceRange: {
            fromPrice: smsMetricPrice?.from,
            toPrice: smsMetricPrice?.to,
          },
        },
      },
      {
        type: 'feature',
        nameKey: 'channels.whatsapp',
        titleTooltipKey: 'channels.whatsapp_tooltip',
        forAdvancedPlan: {
          type: 'text',
          textKey: 'channels.whatsapp_price',
          tooltipKey: 'channels.whatsapp_price_tooltip',
          priceRange: {
            fromPrice: whatsAppMetricPrice?.from,
            toPrice: whatsAppMetricPrice?.to,
          },
        },
      },
      {
        type: 'feature',
        nameKey: 'channels.phone',
        titleTooltipKey: 'channels.phone_tooltip',
        forAdvancedPlan: {
          type: 'text',
          textKey: 'channels.phone_price',
          price: phonePrice,
        },
      },
    ],
  };
};

const platformsPlanGroup: PlanGroup = {
  icon: 'webhook',
  nameKey: 'platform.group-name',
  descriptionKey: 'platform.description',
  features: [
    {
      type: 'feature',
      nameKey: 'platform.custom_objects',
      titleTooltipKey: 'platform.custom_objects_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.custom_events',
      titleTooltipKey: 'platform.custom_events_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.data_export',
      titleTooltipKey: 'platform.data_export_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.app_permissions',
      titleTooltipKey: 'platform.app_permissions_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.user_&_company_segments',
      titleTooltipKey: 'platform.user_&_company_segments_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.custom_actions',
      titleTooltipKey: 'platform.custom_actions_tooltip',
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.workspace_sandbox',
      titleTooltipKey: 'platform.workspace_sandbox_tooltip',
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'ai_help_desk.inbox.switch_phone_defletion',
      titleTooltipKey: 'ai_help_desk.inbox.switch_phone_defletion_tooltip',
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.extended_api_limit',
      titleTooltipKey: 'platform.extended_api_limit_tooltip',
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.custom_roles',
      titleTooltipKey: 'platform.custom_roles_tooltip',
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.sso_&_identity_management',
      titleTooltipKey: 'platform.sso_&_identity_management_tooltip',
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.hipaa_support',
      titleTooltipKey: 'platform.hipaa_support_tooltip',
      forExpertPlan: followingIcon,
    },
    {
      type: 'divider',
      nameKey: 'platform.apps_and_integration.name',
    },
    {
      type: 'feature',
      nameKey: 'platform.apps_and_integration.hubspot_integration',
      titleTooltipKey: 'platform.apps_and_integration.hubspot_integration_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.apps_and_integration.pipedrive_integration',
      titleTooltipKey: 'platform.apps_and_integration.pipedrive_integration_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.apps_and_integration.github_integration',
      titleTooltipKey: 'platform.apps_and_integration.github_integration_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.apps_and_integration.unsubscribe_sync',
      titleTooltipKey: 'platform.apps_and_integration.unsubscribe_sync_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.apps_and_integration.social_integrations',
      titleTooltipKey: 'platform.apps_and_integration.social_integrations_tooltip',
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.apps_and_integration.slack_integration',
      titleTooltipKey: 'platform.apps_and_integration.slack_integration_tooltip',
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.apps_and_integration.salesforce_integration',
      titleTooltipKey: 'platform.apps_and_integration.salesforce_integration_tooltip',
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.apps_and_integration.marketo_integration',
      titleTooltipKey: 'platform.apps_and_integration.marketo_integration_tooltip',
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.apps_and_integration.zendesk_support_integration',
      titleTooltipKey: 'platform.apps_and_integration.zendesk_support_integration_tooltip',
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.apps_and_integration.clearbit_integration',
      titleTooltipKey: 'platform.apps_and_integration.clearbit_integration_tooltip',
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'divider',
      nameKey: 'platform.messenger.name',
    },
    {
      type: 'feature',
      nameKey: 'platform.messenger.messenger',
      titleTooltipKey: 'platform.messenger.messenger_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.messenger.hide_messenger',
      titleTooltipKey: 'platform.messenger.hide_messenger_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.messenger.messenger_app_targeting',
      titleTooltipKey: 'platform.messenger.messenger_app_targeting_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.messenger.require_email_to_chat',
      titleTooltipKey: 'platform.messenger.require_email_to_chat_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.messenger.welcome_messages',
      titleTooltipKey: 'platform.messenger.welcome_messages_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.messenger.customize_launcher_logo',
      titleTooltipKey: 'platform.messenger.customize_launcher_logo_tooltip',
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.messenger.multibrand_messenger',
      titleTooltipKey: 'platform.messenger.multibrand_messenger_tooltip',
      forExpertPlan: followingIcon,
    },
    {
      type: 'divider',
      nameKey: 'platform.reporting.name',
    },
    {
      type: 'feature',
      nameKey: 'platform.reporting.pre_built_reports',
      titleTooltipKey: 'platform.reporting.pre_built_reports_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.reporting.team_performance_reports',
      titleTooltipKey: 'platform.reporting.team_performance_reports_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.reporting.effectiveness_report',
      titleTooltipKey: 'platform.reporting.effectiveness_report_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.reporting.reporting_drill_downs',
      titleTooltipKey: 'platform.reporting.reporting_drill_downs_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.reporting.customizable_charts',
      titleTooltipKey: 'platform.reporting.customizable_charts_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.reporting.export_conversion_data',
      titleTooltipKey: 'platform.reporting.export_conversion_data_tooltip',
      forEssentialPlan: followingIcon,
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.reporting.custom_reports',
      titleTooltipKey: 'platform.reporting.custom_reports_tooltip',
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.reporting.sales_reports',
      titleTooltipKey: 'platform.reporting.sales_reports_tooltip',
      forAdvancedPlan: followingIcon,
      forExpertPlan: followingIcon,
    },
    {
      type: 'feature',
      nameKey: 'platform.reporting.real_time_dashboard',
      titleTooltipKey: 'platform.reporting.real_time_dashboard_tooltip',
      forExpertPlan: followingIcon,
    },
  ],
};

export const keyPlanGroups = (
  prices: IPricesArgs,
  includeCopilot: boolean,
  yearlyBillingSelected: boolean,
) => {
  let groups = [
    aiHelpDeskKeyPlanGroup,
    aiBotKeyPlanGroup(prices.finMetricPrice),
    proactiveSupportKeyPlanGroup(
      prices.proactiveSupportPrice,
      prices.proactiveSupportMetricPrice,
      prices.proactiveSupportBaseUsage,
    ),
    channelsSupportKeyPlanGroup(
      prices.phonePrice,
      prices.whatsAppMetricPrice,
      prices.smsMetricPrice,
      prices.emailMetricPrice,
    ),
    platformsKeyPlanGroup,
  ];
  if (includeCopilot) {
    groups.insertAt(
      1,
      aiCopilotPlanGroup(prices.copilotPrice, prices.copilotDiscountPrice, yearlyBillingSelected),
    );
  }
  return groups;
};

export const allPlanGroups = (
  prices: IPricesArgs,
  includeCopilot: boolean,
  yearlyBillingSelected: boolean,
) => {
  let groups = [
    aiHelpDeskPlanGroup,
    aiBotPlanGroup(prices.finMetricPrice),
    proactiveSupportPlanGroup(
      prices.proactiveSupportPrice,
      prices.proactiveSupportMetricPrice,
      prices.proactiveSupportBaseUsage,
    ),
    channelsSupportPlanGroup(
      prices.phonePrice,
      prices.whatsAppMetricPrice,
      prices.smsMetricPrice,
      prices.emailMetricPrice,
    ),
    platformsPlanGroup,
  ];
  if (includeCopilot) {
    groups.insertAt(
      1,
      aiCopilotPlanGroup(prices.copilotPrice, prices.copilotDiscountPrice, yearlyBillingSelected),
    );
  }
  return groups;
};
