/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-new-mixins */
import { computed } from '@ember/object';
import Mixin from '@ember/object/mixin';
export default Mixin.create({
  isIntercomradesDomain: computed('document.domain', function () {
    return document.domain.includes('intercomrade');
  }),
});
