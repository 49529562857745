/* RESPONSIBLE TEAM: team-reporting */
// eslint-disable-next-line no-restricted-imports
import containerLookup from 'embercom/lib/container-lookup';
import { debounce } from '@ember/runloop';
import ENV from 'embercom/config/environment';

const selectorForChartElement = '[data-report-chart-card]';

function getIndexOfChart(elementWithinChart) {
  try {
    let chartElement = elementWithinChart.closest(selectorForChartElement);
    let allChartElements = Array.from(document.querySelectorAll(selectorForChartElement));
    return allChartElements.indexOf(chartElement);
  } catch (error) {
    // we should continue when we can't determine the index of the chart, for whatever reason
    console.error(error);
  }
}

function trackAnalyticsEventForChartWithoutDebouncing(elementWithinChart, eventMetadata) {
  try {
    let intercomEventService = containerLookup('service:intercomEventService');
    let defaultMetadata = {
      object: 'chart',
      chartIndex: getIndexOfChart(elementWithinChart),
    };
    intercomEventService.trackAnalyticsEvent(Object.assign(defaultMetadata, eventMetadata));
  } catch (error) {
    // we should continue when we can't track an analytics event, for whatever reason
    console.error(error);
  }
}

export function trackAnalyticsEventForChart(elementWithinChart, eventMetadata) {
  debounce(
    this,
    trackAnalyticsEventForChartWithoutDebouncing,
    elementWithinChart,
    eventMetadata,
    ENV.APP._300MS,
  );
}
