/* RESPONSIBLE TEAM: team-product-exploration */
import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';
import type Session from 'embercom/services/session';

export function doesAppOrWorkspaceHaveOptIn(
  appService?: {
    app: {
      hasOptedInForProductIa: boolean;
    };
  },
  session?: Session,
) {
  let app = appService?.app;

  if (app) {
    return app.hasOptedInForProductIa;
  } else if (session?.isWorkspaceLoaded) {
    return session.hasOptedInForProductIa;
  } else {
    return false;
  }
}

export default class HasProductIaOptInHelper extends Helper {
  @service declare appService: any;
  @service declare session: Session;

  compute() {
    return doesAppOrWorkspaceHaveOptIn(this.appService, this.session);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'has-product-ia-opt-in': typeof HasProductIaOptInHelper;
  }
}
