/* RESPONSIBLE TEAM: team-proactive-support */
import StatsParameters from 'embercom/objects/stats-system/stats-parameters';
import {
  humanReadableObjectNames,
  statsSystemObjectNames,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import moment from 'moment-timezone';
import Range from 'embercom/models/reporting/range';
import { isPresent } from '@ember/utils';

// Limit the maximum start date to 2 years ago as Elastic Search only retains results for up to 2 years
const TWO_YEARS_AGO = moment().subtract(2, 'years').add(1, 'day').toDate();

export function makeStatsParametersForRulesetLink(
  ruleset,
  rulesetLink = null,
  range = null,
  timezone = null,
) {
  rulesetLink = rulesetLink || ruleset.rulesetLinks.firstObject;

  return new StatsParameters({
    contentId: rulesetLink?.objectId,
    contentType: rulesetLink?.objectType,
    contentObject: rulesetLink?.object,
    goal: ruleset.goal,
    uiObjectName: rulesetLink?.humanReadableObjectName,
    shortObjectName: rulesetLink?.statsSystemObjectName,
    startDate: startDate(rulesetLink?.wentLiveAt),
    range:
      range ||
      Range.createFromParamsWithAddedRanges(
        formattedStartDate(rulesetLink?.wentLiveAt),
        null,
        timezone,
        createExtraRangeOption(rulesetLink?.wentLiveAt, timezone),
      ),
  });
}

export function makeStatsParametersForSeries(series, range = null, timezone = null) {
  return new StatsParameters({
    contentId: series?.id,
    contentType: objectTypes.series,
    contentObject: series,
    goal: series?.goal,
    uiObjectName: humanReadableObjectNames[objectTypes.series],
    shortObjectName: statsSystemObjectNames[objectTypes.series],
    startDate: startDate(series?.wentLiveAt),
    range:
      range ||
      Range.createFromParamsWithAddedRanges(
        formattedStartDate(series?.wentLiveAt),
        null,
        timezone,
        createExtraRangeOption(series?.wentLiveAt, timezone),
      ),
  });
}

export function makeStatsParametersForSelectionSet(
  selectionSet,
  objectType,
  range = null,
  timezone = null,
) {
  return new StatsParameters({
    contentId: selectionSet.object.id,
    contentType: objectType,
    contentObject: selectionSet.object,
    uiObjectName: humanReadableObjectNames[objectType],
    shortObjectName: statsSystemObjectNames[objectType],
    startDate: startDate(selectionSet?.createdAt),
    range:
      range ||
      Range.createFromParamsWithAddedRanges(
        formattedStartDate(selectionSet?.createdAt),
        null,
        timezone,
        createExtraRangeOption(selectionSet?.createdAt, timezone),
      ),
  });
}

export function makeStatsParametersForTooltip(
  tooltip,
  ruleset,
  rulesetLink,
  range = null,
  timezone = null,
) {
  rulesetLink = rulesetLink || ruleset.rulesetLinks.firstObject;
  return new StatsParameters({
    contentId: tooltip?.id,
    contentType: objectTypes.tooltip,
    contentObject: tooltip,
    goal: ruleset.goal,
    uiObjectName: humanReadableObjectNames[objectTypes.tooltip],
    shortObjectName: statsSystemObjectNames[objectTypes.tooltip],
    startDate: startDate(rulesetLink?.wentLiveAt),
    range:
      range ||
      Range.createFromParamsWithAddedRanges(
        formattedStartDate(rulesetLink?.wentLiveAt),
        null,
        timezone,
        createExtraRangeOption(rulesetLink?.wentLiveAt, timezone),
      ),
  });
}

export function makeStatsParametersForArticle(
  article,
  range = null,
  timezone = null,
  localizedContentId = null,
  helpCenterId = null,
) {
  return {
    contentId: article.id,
    contentType: objectTypes.article,
    uiObjectName: humanReadableObjectNames[objectTypes.article],
    shortObjectName: statsSystemObjectNames[objectTypes.article],
    startDate: startDate(article.createdAt),
    rangeField: 'event.created_at',
    contentObject: {
      reactionSet: [
        { unicode_emoticon: '\uD83D\uDE03' },
        { unicode_emoticon: '\uD83D\uDE10' },
        { unicode_emoticon: '\uD83D\uDE1E' },
      ],
    },
    range: range || Range.createFromParams(formattedStartDate(article.createdAt), null, timezone),
    localizedContentId,
    helpCenterId,
  };
}

export function _makeStatsParametersForArticle(
  article,
  range,
  timezone,
  localizedContentId,
  helpCenterId,
) {
  return new StatsParameters({
    contentId: article.id,
    contentType: objectTypes.article,
    uiObjectName: humanReadableObjectNames[objectTypes.article],
    shortObjectName: statsSystemObjectNames[objectTypes.article],
    startDate: startDate(article.createdAt),
    rangeField: 'event.created_at',
    contentObject: {
      reactionSet: [
        { unicode_emoticon: '\uD83D\uDE03' },
        { unicode_emoticon: '\uD83D\uDE10' },
        { unicode_emoticon: '\uD83D\uDE1E' },
      ],
    },
    range: range || Range.createFromParams(formattedStartDate(article.createdAt), null, timezone),
    localizedContentId,
    helpCenterId,
  });
}

export function makeStatsParametersForTriggerableBot(
  ruleset,
  rulesetLink = null,
  range = null,
  timezone = null,
  targetChannels = null,
) {
  rulesetLink = rulesetLink || ruleset.rulesetLinks.firstObject;
  return {
    contentId: rulesetLink?.objectId,
    contentType: rulesetLink?.objectType,
    contentObject: rulesetLink?.object,
    goal: ruleset.goal,
    uiObjectName: rulesetLink?.humanReadableObjectName,
    shortObjectName: rulesetLink?.statsSystemObjectName,
    startDate: startDate(rulesetLink?.wentLiveAt),
    range:
      range ||
      Range.createFromParamsWithAddedRanges(
        formattedStartDate(rulesetLink?.wentLiveAt),
        null,
        timezone,
        createExtraRangeOption(rulesetLink?.wentLiveAt, timezone),
      ),
    targetChannels,
  };
}

export function makeStatsParametersForChecklistStep(
  step,
  ruleset,
  rulesetLink,
  range = null,
  timezone = null,
) {
  rulesetLink = rulesetLink || ruleset.rulesetLinks.firstObject;
  return new StatsParameters({
    contentId: step?.id,
    contentType: objectTypes.checklistStep,
    contentObject: step,
    goal: ruleset.goal,
    uiObjectName: humanReadableObjectNames[objectTypes.checklistStep],
    shortObjectName: statsSystemObjectNames[objectTypes.checklistStep],
    startDate: startDate(rulesetLink?.wentLiveAt),
    range:
      range ||
      Range.createFromParamsWithAddedRanges(
        formattedStartDate(rulesetLink?.wentLiveAt),
        null,
        timezone,
        createExtraRangeOption(rulesetLink?.wentLiveAt, timezone),
      ),
  });
}

export function makeStatsParametersForEdgeSplit(ruleset, edgeSplit) {
  let rulesetLink = ruleset.rulesetLinks.firstObject;
  return new StatsParameters({
    contentId: edgeSplit.id,
    contentType: objectTypes.edgeSplit,
    contentObject: edgeSplit,
    goal: ruleset.goal,
    uiObjectName: rulesetLink?.humanReadableObjectName,
    shortObjectName: rulesetLink?.statsSystemObjectName,
    startDate: startDate(rulesetLink?.wentLiveAt),
  });
}

export function makeStatsParametersForSupportContent(content, range = null, timezone = null) {
  return new StatsParameters({
    contentId: Number(content.id),
    contentType: content.entityType,
    contentObject: content,
    uiObjectName: humanReadableObjectNames[content.entityType],
    shortObjectName: statsSystemObjectNames[content.entityType],
    startDate: startDate(content.createdAt),
    goal: null,
    range:
      range ||
      Range.createFromParamsWithAddedRanges(
        formattedStartDate(content.createdAt),
        null,
        timezone,
        createExtraRangeOption(content.createdAt, timezone),
      ),
  });
}

function startDate(wentLiveAt) {
  return wentLiveAt > TWO_YEARS_AGO ? wentLiveAt : TWO_YEARS_AGO;
}

export function formattedStartDate(wentLiveAt) {
  return moment(startDate(wentLiveAt)).format();
}

export function createExtraRangeOption(wentLiveAt, timezone) {
  if (isPresent(wentLiveAt)) {
    if (wentLiveAtDateIslessthanTwoYearsAgo(wentLiveAt)) {
      return createRangeOption('reporting.range.since-went-live', wentLiveAt, timezone);
    } else {
      return createRangeOption('reporting.range.past-2-years', TWO_YEARS_AGO, timezone);
    }
  } else {
    return {};
  }
}

function wentLiveAtDateIslessthanTwoYearsAgo(wentLiveAt) {
  return wentLiveAt > TWO_YEARS_AGO;
}

function createRangeOption(label, startOfRange, timezone) {
  let today = moment.tz(timezone).endOf('day');
  let sinceStartofRange = today.diff(moment.tz(startOfRange, timezone), 'days') + 1;
  return { [label]: [sinceStartofRange, 1] };
}
