/* RESPONSIBLE TEAM: team-frontend-tech */
import { readOnly } from '@ember/object/computed';
import DefaultPredicateManager from 'embercom/lib/common/predicates/default-predicate-manager';
import FiltersToText from 'embercom/lib/filters-to-text';

const numberOfDaysSinceLastContact = '1';

export default DefaultPredicateManager.extend({
  isValid: readOnly('predicate.hasAttributeComparisonAndType'),
  defaultComparison: 'true',

  getDefaultValue() {
    return numberOfDaysSinceLastContact;
  },

  _getDescription(customFilterMap, showNoEventPrefix) {
    let descriptionPrefix = this.get('predicate.info')
      ? this.get('predicate.info.name').toLowerCase()
      : this.get('predicate.attributeIdentifier');
    return {
      text: `${descriptionPrefix} ${FiltersToText.getComparator(this.predicate, customFilterMap)}`,
      icon: this.get('predicate.info.icon'),
    };
  },
});
