/* RESPONSIBLE TEAM: team-proactive-support */
export const GSM7_CHAR_COUNT_PER_SINGLE_SEGMENT = 160;

// 6 bytes of each segment in a multi segment GSM-7 encoded SMS are reserved as a user data header.
// This is used to tell the client how the message should be re-assembled.
// It reduces the available characters per segment to 153 characters.
export const GSM7_CHAR_COUNT_PER_MULTI_SEGMENT = 153;

// Any messages containing characters not in this list will be encoded as UCS-2 by Twilio
export const GSM7_ALPHABET = [
  '@',
  '£',
  '$',
  '¥',
  'è',
  'é',
  'ù',
  'ì',
  'ò',
  'Ç',
  '\n',
  'Ø',
  'ø',
  '\r',
  'Å',
  'å',
  'Δ',
  '_',
  'Φ',
  'Γ',
  'Λ',
  'Ω',
  'Π',
  'Ψ',
  'Σ',
  'Θ',
  'Ξ',
  'Æ',
  'æ',
  'ß',
  'É',
  ' ',
  '!',
  '"',
  '#',
  '¤',
  '%',
  '&',
  "'",
  '(',
  ')',
  '*',
  '+',
  ',',
  '-',
  '.',
  '/',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  ':',
  ';',
  '<',
  '=',
  '>',
  '?',
  '¡',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  'Ä',
  'Ö',
  'Ñ',
  'Ü',
  '§',
  '¿',
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
  'ä',
  'ö',
  'ñ',
  'ü',
  'à',
  '\f',
  '^',
  '{',
  '}',
  '\\',
  '[',
  '~',
  ']',
  '|',
  '€',
];

// These count as 2 characters in GSM-7 encoded messages
const GSM7_EXTENDED_ALPHABET = ['|', '^', '€', '{', '}', '[', ']', '~', '\\'];

const UCS2_CHAR_COUNT_PER_SINGLE_SEGMENT = 70;

// 6 bytes of each segment in a multi segment UCS-2 encoded SMS are reserved as a user data header.
// This is used to tell the client how the message should be re-assembled.
// It reduces the available characters per segment to 67 characters.
const UCS2_CHAR_COUNT_PER_MULTI_SEGMENT = 67;

// Anything outside of this set counts as 2 characters in UCS-2 encoded messages
const UNICODE_BASIC_PLANE = new Array(65535).fill(0).map((_, index) => index);

const character_count_for_gsm7 = (sms_body) => {
  if (!sms_body || sms_body.length === 0) {
    return 0;
  }

  return sms_body
    .split('')
    .map((char) => (GSM7_EXTENDED_ALPHABET.includes(char) ? 2 : 1))
    .reduce((previousValue, currentValue) => previousValue + currentValue);
};

const character_count_for_ucs2 = (sms_body) => {
  if (!sms_body || sms_body.length === 0) {
    return 0;
  }

  return sms_body
    .split('')
    .map((char) => char.codePointAt())
    .map((codepoint) => (UNICODE_BASIC_PLANE.includes(codepoint) ? 1 : 2))
    .reduce((previousValue, currentValue) => previousValue + currentValue);
};

const segment_count_for_gsm7 = (sms_body) => {
  let char_count = character_count_for_gsm7(sms_body);
  if (char_count <= GSM7_CHAR_COUNT_PER_SINGLE_SEGMENT) {
    return 1;
  }

  return Math.ceil(char_count / GSM7_CHAR_COUNT_PER_MULTI_SEGMENT);
};

const segment_count_for_ucs2 = (sms_body) => {
  let char_count = character_count_for_ucs2(sms_body);
  if (char_count <= UCS2_CHAR_COUNT_PER_SINGLE_SEGMENT) {
    return 1;
  }

  return Math.ceil(char_count / UCS2_CHAR_COUNT_PER_MULTI_SEGMENT);
};

const contains_only_gsm7_chars = (sms_body) => {
  return sms_body.split('').every((char) => GSM7_ALPHABET.includes(char));
};

const get_encoding = (sms_body) => {
  return contains_only_gsm7_chars(sms_body) ? 'GSM-7' : 'UCS-2';
};

export function getCharCount(sms_body) {
  if (get_encoding(sms_body) === 'GSM-7') {
    return character_count_for_gsm7(sms_body);
  }

  return character_count_for_ucs2(sms_body);
}

export function getSegmentCount(sms_body) {
  if (get_encoding(sms_body) === 'GSM-7') {
    return segment_count_for_gsm7(sms_body);
  }

  return segment_count_for_ucs2(sms_body);
}
