/* RESPONSIBLE TEAM: team-frontend-tech */
export function groupBy(array, callback) {
  let groups = {};
  array.forEach((item) => {
    let group = callback(item);
    groups[group] = groups[group] || [];
    groups[group].push(item);
  });
  return Object.keys(groups).map((group) => {
    return groups[group];
  });
}
