/* RESPONSIBLE TEAM: team-reporting */
import { select } from 'd3-selection';

// Break a text string into substrings, each of at most maxLengthChars,
// respecting word (non-space) boundaries.
function chunkStringRespectingWords(str, maxLengthChars) {
  let parts = str.trim().split(' ');
  let chunks = [];
  let chunkToAppendTo = 0;

  for (let nextWord of parts) {
    // If the word won't fit on a line, return early
    if (nextWord.length > maxLengthChars) {
      return chunks;
    } else {
      if (
        chunks[chunkToAppendTo] &&
        chunks[chunkToAppendTo].length + nextWord.length > maxLengthChars
      ) {
        chunkToAppendTo += 1;
      }

      chunks[chunkToAppendTo] = chunks[chunkToAppendTo]
        ? `${chunks[chunkToAppendTo]} ${nextWord}`
        : nextWord;
    }
  }

  return chunks;
}

export function renderText(element, label, bubbleRadius) {
  let bubbleName = label.trim().toUpperCase();

  // Get the text that's already on the element, that we want to wrap
  select(element).text(bubbleName);
  // Calculate its render length, as is. We'll use this as an approximation to
  // *guess* where to wrap it. (Unfortunately, we cant work in terms of character length,
  // as we aren't using a fixed width font.)
  // But we'll use character length to approximately, and error-pronely, wrap it.
  // Its either that or repeatedly render to an off-screen context?
  let unwrappedTextLength = element.getComputedTextLength();
  let approxCharWidth = unwrappedTextLength / bubbleName.length;

  // Make the original text element empty, as we're going to use tspans to hold the
  // wrapped text.
  select(element).text('');

  let fontSize = 10;
  // Use a smaller font size when lines are wrapped
  if (unwrappedTextLength > bubbleRadius * 2.6) {
    fontSize = 8;
  }

  // Calculate approximately how many characters can be shown on a line
  let maxLineWidth = bubbleRadius * 1.5;
  let approxMaxCharsPerLine = maxLineWidth / approxCharWidth;
  // Generate a max of 3 substrings to display
  let chunksToRender = chunkStringRespectingWords(bubbleName, approxMaxCharsPerLine).slice(0, 3);

  let lineHeight = fontSize + 2;

  // Vertically position the rows inside the text element, so as to keep lineHeight px between any 2 rows,
  // as well as to vertically center the rows inside the bubble
  chunksToRender.forEach((chunk, i) => {
    let yOffset = fontSize - (lineHeight / 2) * chunksToRender.length;
    select(element)
      .append('tspan')
      .attr('x', 0)
      .attr('y', 0)
      .attr('dx', 0)
      .attr('dy', yOffset + lineHeight * i)
      .style('font-size', `${fontSize}px`)
      .text(chunk);
  });
}

export function renderExternalText(element, label, bubbleRadius, lineHeight, maxWidth) {
  let labelText = label.trim().toUpperCase();

  // Set the text on the element, that we want to wrap
  select(element).text(labelText);
  // Calculate its render length, as is. We'll use this as an approximation to
  // *guess* where to wrap it.
  let unwrappedTextLength = element.getComputedTextLength();
  let approxCharWidth = (1.2 * unwrappedTextLength) / labelText.length;

  // Make the original text element empty, as we're going to use tspans to hold the
  // wrapped text.
  select(element).text('');

  // Calculate approximately how many characters can be shown on a line
  let approxMaxCharsPerLine = maxWidth / approxCharWidth;
  // Generate substrings to display
  let chunksToRender = chunkStringRespectingWords(labelText, approxMaxCharsPerLine);

  // Vertically position the rows inside the text element, so as to keep lineHeight px between any 2 rows
  chunksToRender.forEach((chunk) => {
    select(element)
      .append('tspan')
      .text(chunk)
      .attr('x', 0)
      .attr('dy', lineHeight)
      .attr('dx', function () {
        return -bubbleRadius / 2 - this.getComputedTextLength() / 2;
      });
  });
}
