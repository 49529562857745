/* RESPONSIBLE TEAM: team-frontend-tech */
import { helper } from '@ember/component/helper';
import logLegacyComposerError from 'embercom/lib/log-legacy-composer-error';

export function legacyComposerErrorLogger(area) {
  return function (error) {
    logLegacyComposerError(area, error);
  };
}

export default helper(legacyComposerErrorLogger);
