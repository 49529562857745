/* RESPONSIBLE TEAM: team-help-desk-experience */
import Helper from '@ember/component/helper';
import fade from 'ember-animated/transitions/fade';
import BasicListAnimation from 'embercom/animations/basic-list-animation';

type ValidAnimation = 'basic-list-animation' | 'fade';

interface LookupAnimationSignature {
  Args: {
    Positional: [ValidAnimation];
  };
  Return: typeof fade | typeof BasicListAnimation | undefined;
}

export default class LookupAnimation extends Helper<LookupAnimationSignature> {
  compute(params: [ValidAnimation]) {
    let [animation] = params;

    switch (animation) {
      case 'basic-list-animation':
        return BasicListAnimation;
      case 'fade':
        return fade;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'lookup-animation': typeof LookupAnimation;
  }
}
