/* RESPONSIBLE TEAM: team-help-desk-experience */
import { helper as buildHelper } from '@ember/component/helper';
export function max(params) {
  if (Array.isArray(params[0])) {
    return Math.max(...params[0]);
  } else {
    return Math.max(...params);
  }
}

export default buildHelper(max);
