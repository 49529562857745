/* RESPONSIBLE TEAM: team-reporting */
export default function (current, previous) {
  return current.map((val) => {
    let prev = previous.filter((row) => row.key === val.key).pop();
    prev = prev ? prev.value : { closed_won: 0 };
    return {
      key: val.key,
      ...val.value,
      prev_closed_won: prev.closed_won,
    };
  });
}
