/* RESPONSIBLE TEAM: team-frontend-tech */
import { isEmpty } from '@ember/utils';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

export function htmlUnescape(string) {
  let temporaryElement = document.createElement('textarea');
  temporaryElement.innerHTML = sanitizeHtml(string);
  return temporaryElement.value;
}

export function htmlToTextContent(html) {
  if (isEmpty(html)) {
    return '';
  } else {
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.documentElement.textContent.trim();
  }
}
