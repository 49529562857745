/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Helper from '@ember/component/helper';
import {
  VBP2_SEAT_LABELS,
  PRICING_5_X_SEAT_LABELS,
  ALL_SEAT_LABELS,
  type SeatType,
} from 'embercom/lib/settings/seats/constants';
import { getRequiredSeatTypesForPermission } from 'embercom/lib/seats-permissions-map';
import { inject as service } from '@ember/service';

export default class seatDependentPermissionTooltipTextForSeats extends Helper<{
  Args: {
    Positional: [string];
  };
  Return: string;
}> {
  @service permissionsHierarchyService: $TSFixMe;
  @service intl: $TSFixMe;
  @service appService: $TSFixMe;

  compute([permissionKey]: [any]) {
    let requiredSeatTypes = getRequiredSeatTypesForPermission(
      permissionKey,
      this.permissionsHierarchyService.seatPermissionMap,
    );

    if (this.appService.app.onPricing5) {
      requiredSeatTypes = requiredSeatTypes.filter(
        (seatType: SeatType) => PRICING_5_X_SEAT_LABELS[seatType],
      );
    } else {
      requiredSeatTypes = requiredSeatTypes.filter(
        (seatType: SeatType) => VBP2_SEAT_LABELS[seatType],
      );
    }

    let requiredSeatLabels = requiredSeatTypes.map(
      (seatType: SeatType) => ALL_SEAT_LABELS[seatType],
    );

    return this.intl.formatList(requiredSeatLabels, { type: 'disjunction' });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'seat-dependent-permission-tooltip-text-for-seats': typeof seatDependentPermissionTooltipTextForSeats;
  }
}
