/* RESPONSIBLE TEAM: team-frontend-tech */
let PointerPositioner = function (caretOffset) {
  this.caretOffset = caretOffset || 0;
};

PointerPositioner.prototype.standardLocations = [
  'above',
  'aboveWithOffset',
  'below',
  'belowWithOffset',
  'right',
  'rightWithOffset',
  'left',
  'leftWithOffset',
];

/**
  Get a fixed position location to position a pointer element

  @param {string} target a selector or domNode for the element you want to point at
  @param {string} pointer a selector for the element you want to do the pointing
  @param {string} container (optional) a selector for the bounding box, if not specified will default to viewport
  @param {string} location (optional) either a spcific location or an array of locations in order of preference

  @return {hash} Returns a hash with the following keys
    left: The left coordinates of the pointer element
    top:  The top coordinates of the pointer element
    horizontalOffset: The amount the tooltip is offset from the center of the target (negative is left)
    verticalOffset: The amount the tooltip is offset from the center of the target (negative is above)
**/
PointerPositioner.prototype.getPosition = function (target, pointer, container, targetLocations) {
  targetLocations = targetLocations || this.standardLocations;
  this._setBoundingRects(target, pointer, container);
  this._sanityCheckBoundingRects();
  targetLocations = Array.isArray(targetLocations) ? targetLocations.slice() : [targetLocations];
  return this._findFittingLocation(targetLocations);
};

PointerPositioner.prototype._findFittingLocation = function (locations) {
  let position;
  for (let i = 0; i < locations.length; i++) {
    position = this[`_${locations[i]}`]();
    if (this._isInsideContainer(position)) {
      return position;
    }
  }
  throw new Error('Could not find a valid location');
};

PointerPositioner.prototype._setBoundingRects = function (target, pointer, container) {
  this.targetRect = this._getBoundingClientRect(target);
  this.pointerRect = this._getBoundingClientRect(pointer);
  if (container) {
    try {
      this.containerRect = this._getBoundingClientRect(container);
    } catch (e) {
      this.containerRect = this._getBoundingClientRectForViewport();
    }
  } else {
    this.containerRect = this._getBoundingClientRectForViewport();
  }
};

PointerPositioner.prototype._sanityCheckBoundingRects = function () {
  if (
    this.pointerRect.width > this.containerRect.width ||
    this.pointerRect.height > this.containerRect.height
  ) {
    throw new Error('Pointer must be smaller than container');
  }
  if (
    this.containerRect.width > this.containerRect.width ||
    this.pointerRect.height > this.containerRect.height
  ) {
    throw new Error('Target must be smaller than container');
  }
};

PointerPositioner.prototype._above = function () {
  return {
    top: this.targetRect.top - (this.pointerRect.height + this.caretOffset),
    left: this._targetHorizontalCenter() - this.pointerRect.width / 2,
    horizontalOffset: 0,
    verticalOffset: 0,
    location: 'above',
  };
};

PointerPositioner.prototype._aboveWithOffset = function () {
  let position = this._above();
  this._horizontalOffset(position);
  return position;
};

PointerPositioner.prototype._below = function () {
  return {
    top: this.targetRect.bottom + this.caretOffset,
    left: this._targetHorizontalCenter() - this.pointerRect.width / 2,
    horizontalOffset: 0,
    verticalOffset: 0,
    location: 'below',
  };
};

PointerPositioner.prototype._belowWithOffset = function () {
  let position = this._below();
  this._horizontalOffset(position);
  return position;
};

PointerPositioner.prototype._right = function () {
  return {
    top: this._targetVerticalCenter() - this.pointerRect.height / 2,
    left: this.targetRect.right + this.caretOffset,
    horizontalOffset: 0,
    verticalOffset: 0,
    location: 'right',
  };
};

PointerPositioner.prototype._rightWithOffset = function () {
  let position = this._right();
  this._verticalOffset(position);
  return position;
};

PointerPositioner.prototype._left = function () {
  return {
    top: this._targetVerticalCenter() - this.pointerRect.height / 2,
    left: this.targetRect.left - (this.pointerRect.width + this.caretOffset),
    horizontalOffset: 0,
    verticalOffset: 0,
    location: 'left',
  };
};

PointerPositioner.prototype._leftWithOffset = function () {
  let position = this._left();
  this._verticalOffset(position);
  return position;
};

PointerPositioner.prototype._getBoundingClientRect = function (selectorOrDomNode) {
  if (typeof selectorOrDomNode.getBoundingClientRect === 'function') {
    return selectorOrDomNode.getBoundingClientRect();
  }
  let nodeList = document.querySelectorAll(selectorOrDomNode);
  if (nodeList.length === 0) {
    throw new Error(`Could not find element ${selectorOrDomNode}`);
  }
  if (nodeList.length > 1) {
    throw new Error(`Ambiguous selector ${selectorOrDomNode}`);
  }
  return nodeList[0].getBoundingClientRect();
};

PointerPositioner.prototype._getBoundingClientRectForViewport = function () {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
    left: 0,
    right: window.innerWidth,
    top: 0,
    bottom: window.innerHeight,
  };
};

PointerPositioner.prototype._isInsideContainer = function (position) {
  return (
    this._isVerticallyInsideContainer(position) && this._isHorizontallyInsideContainer(position)
  );
};

PointerPositioner.prototype._isVerticallyInsideContainer = function (position) {
  return this._isTopInsideContainer(position) && this._isBottomInsideContainer(position);
};

PointerPositioner.prototype._isHorizontallyInsideContainer = function (position) {
  return this._isLeftInsideContainer(position) && this._isRightInsideContainer(position);
};

PointerPositioner.prototype._isTopInsideContainer = function (position) {
  return position.top >= this.containerRect.top;
};

PointerPositioner.prototype._isBottomInsideContainer = function (position) {
  return this.pointerRect.height + position.top <= this.containerRect.bottom;
};

PointerPositioner.prototype._isLeftInsideContainer = function (position) {
  return position.left >= this.containerRect.left;
};

PointerPositioner.prototype._isRightInsideContainer = function (position) {
  return this.pointerRect.width + position.left <= this.containerRect.right;
};

PointerPositioner.prototype._targetHorizontalCenter = function () {
  return this.targetRect.left + this.targetRect.width / 2;
};

PointerPositioner.prototype._targetVerticalCenter = function () {
  return this.targetRect.top + this.targetRect.height / 2;
};

PointerPositioner.prototype._horizontalOffset = function (position) {
  let offset = 0;
  if (!this._isLeftInsideContainer(position)) {
    offset = this.containerRect.left - position.left;
  } else if (!this._isRightInsideContainer(position)) {
    offset = this.containerRect.right - (position.left + this.pointerRect.width);
  }
  position.left += offset;
  position.horizontalOffset = offset;
};

PointerPositioner.prototype._verticalOffset = function (position) {
  let offset = 0;
  if (!this._isTopInsideContainer(position)) {
    offset = this.containerRect.top - position.top;
  } else if (!this._isBottomInsideContainer(position)) {
    offset = this.containerRect.bottom - (position.top + this.pointerRect.height);
  }
  position.top += offset;
  position.verticalOffset = offset;
};

export default PointerPositioner;
