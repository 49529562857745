/* RESPONSIBLE TEAM: team-pricing-and-packaging */

export enum PricingModelIdentifier {
  CoreWithAddOns = 'core_with_add_ons',
  CoreWithAddOnsFeb2021 = 'core_with_add_ons_feb2021',
  NoBaseHigh = 'no_base_high',
  NoBaseLow = 'no_base_low',
  PerProductPricing = 'per_product_pricing',
  PerProductPricingHigh = 'per_product_pricing_high',
  PerProductPricingPremiumMar2019 = 'per_product_pricing_premium_mar2019',
  PerProductPricingWithSeats = 'per_product_pricing_with_seats',
  PremiumGrandfathered = 'premium_grandfathered',
  PremiumProducts = 'premium_products',
  SharedBaseHigh = 'shared_base_high',
  SharedBaseLow = 'shared_base_low',
  VBP2_0 = 'value_based_pricing_mar2021',
  VBP2_0_EARLY_STAGE = 'value_based_pricing_mar2021_early_stage',
  VBP2_1 = 'value_based_pricing_feb2022',
  VBP2_2 = 'value_based_pricing_2_2_internal',
  PRICING_5_0 = 'pricing5_0',
  PRICING_5_1 = 'pricing5_1',
  PRICING_5_0_EARLY_STAGE = 'pricing5_0_early_stage',
  PRICING_5_0_EARLY_STAGE_FREE = 'pricing5_0_early_stage_free',
  PRICING_5_1_EARLY_STAGE = 'pricing5_1_early_stage',
  PRICING_5_1_EARLY_STAGE_FREE = 'pricing5_1_early_stage_free',
  PRICING_5_1_COPILOT_EARLY_STAGE = 'pricing5_1_copilot_early_stage',
  PRICING_5_1_COPILOT_EARLY_STAGE_FREE = 'pricing5_1_copilot_early_stage_free',
  PRICING_5_EARLY_STAGE_YEAR_2 = 'pricing5_year2_early_stage',
  PRICING_5_EARLY_STAGE_YEAR_3 = 'pricing5_year3_early_stage',

  // Temporary for testing Fin pre release
  PPP1_1 = 'per_product_pricing_premium_v1_1_internal',
  VBP1_3 = 'value_based_pricing_1_3_internal',
}

export type PricingModelIdentifiers = `${PricingModelIdentifier}`;
