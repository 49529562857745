/* RESPONSIBLE TEAM: team-messenger */
import { isPresent } from '@ember/utils';
import compareVersions from 'embercom/lib/compare-versions';

export function meetsAndroidSdkRequirements(app, minAndroidVersion) {
  let androidSdkVersion = app.android_sdk_version;
  return (
    isPresent(androidSdkVersion) && compareVersions(androidSdkVersion, minAndroidVersion) !== -1
  );
}

export function meetsIosSdkRequirements(app, minIosVersion) {
  let iosSdkVersion = app.ios_sdk_version;
  return isPresent(iosSdkVersion) && compareVersions(iosSdkVersion, minIosVersion) !== -1;
}

export function hasMobileSdkInstalled(app) {
  let androidSdkVersion = app.android_sdk_version;
  let iosSdkVersion = app.ios_sdk_version;
  return isPresent(androidSdkVersion) || isPresent(iosSdkVersion);
}

export function hasNoMobileSdkInstalled(app) {
  return !hasMobileSdkInstalled(app);
}

export function hasOldMobileSdk(app, minSdkVersion) {
  return (
    !meetsAndroidSdkRequirements(app, minSdkVersion) && !meetsIosSdkRequirements(app, minSdkVersion)
  );
}
