/* RESPONSIBLE TEAM: team-help-desk-experience */
import Helper from '@ember/component/helper';
import PredicateGroup, {
  type NestedPredicate,
} from 'embercom/objects/inbox/search/predicate-group';

interface TagIdToSearchParamSignature {
  Args: {
    Positional: [number];
  };
}

export default class TagIdToSearchParam extends Helper<TagIdToSearchParamSignature> {
  compute(params: [number]): string {
    let [tagId] = params;
    if (tagId) {
      let predicateGroup = PredicateGroup.from('');
      let predicate: NestedPredicate['predicates'][number] = {
        attribute: 'conversation.tag_ids',
        comparison: 'in',
        type: 'manual_tag',
        value: tagId.toString(),
      };
      predicateGroup.add({
        identifier: 'tags',
        type: 'and',
        predicates: [predicate],
      });
      return predicateGroup.toB64() ?? '';
    }
    return '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'tag-id-to-search-param': typeof TagIdToSearchParam;
  }
}
