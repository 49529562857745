/* RESPONSIBLE TEAM: team-tickets-1 */
import moment from 'moment-timezone';

export function dateRangeBucketFormatter(intl, timezone, date, interval, rangeStart, rangeEnd) {
  let endDate = moment(date).add(1, interval).subtract(1, 'second');
  let endWithinRange = moment.min(endDate, moment(rangeEnd));
  let startWithinRange = moment.max(moment(date), moment(rangeStart));

  return `${intl.formatDate(startWithinRange, {
    format: 'dateWithMonth',
    timeZone: timezone,
  })} - ${intl.formatDate(endWithinRange, {
    format: 'dateWithMonth',
    timeZone: timezone,
  })}`;
}

export function isBucketGreaterThanDay(bucketInterval) {
  let intervalsGreaterThanDay = ['week', 'month'];
  return intervalsGreaterThanDay.includes(bucketInterval);
}
