/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
export function messageDeleteSuccess() {
  return `The message was deleted`;
}

export function messageDeleteFailure() {
  return `We couldn't delete this message. Send us a message and we'll do our best to help out.`;
}

export function messageDeleteOptions(message) {
  let body;
  let confirmButtonText = 'Delete';
  let title = 'Delete this message?';
  let primaryButtonType = 'primary-destructive';
  if (message.get('isDraft')) {
    body = 'Are you sure you want to delete this draft message? This is permanent.';
  } else {
    body =
      'This deletes the message from Intercom and the Messenger. Any conversations it started will still be visible in the Inbox to teammates and included in reports.';
  }
  return { title, body, confirmButtonText, primaryButtonType };
}
