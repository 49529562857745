/* RESPONSIBLE TEAM: team-reporting */
import { copy } from 'ember-copy';
import { inject as service } from '@ember/service';
import { setOwner } from '@ember/application';

export default class ExportColumns {
  @service intl;

  constructor(owner) {
    setOwner(this, owner);
  }

  getColumnData(app) {
    let data = copy(this.columnData, true);
    // remove the columns where the workspace doesn't have the relevant feature
    for (let section of data) {
      section.columns = section.columns.filter(
        (column) => column.featureKey === undefined || app.canUseFeature(column.featureKey),
      );
    }
    return data;
  }

  getColumnNames(app) {
    let namesFromData = this.getColumnData(app).flatMap((section) =>
      section.columns.map((column) => column.name),
    );
    return ['conversation_id', 'conversation_url', ...namesFromData];
  }

  get columnData() {
    return [
      {
        sectionTitle: this.intl.t('app.lib.reporting.export-columns.conversation.title'),
        sectionSubtitle: this.intl.t('app.lib.reporting.export-columns.conversation.subtitle'),
        sectionName: 'conversation',
        sectionIcon: 'conversation',
        columns: [
          {
            name: 'title',
            label: this.intl.t('app.lib.reporting.export-columns.conversation.columns.title.label'),
          },
          {
            name: 'conversation_status',
            label: this.intl.t(
              'app.lib.reporting.export-columns.conversation.columns.conversation-status.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.export-columns.conversation.columns.conversation-status.hint',
            ),
          },
          {
            name: 'conversation_tags',
            label: this.intl.t(
              'app.lib.reporting.export-columns.conversation.columns.conversation-tags.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.export-columns.conversation.columns.conversation-tags.hint',
            ),
          },
          {
            name: 'created_at',
            label: this.intl.t(
              'app.lib.reporting.export-columns.conversation.columns.created-at.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.export-columns.conversation.columns.created-at.hint',
            ),
          },
          {
            name: 'last_updated_at',
            label: this.intl.t(
              'app.lib.reporting.export-columns.conversation.columns.last-updated-at.label',
            ),
          },
          {
            name: 'last_closed_at',
            label: this.intl.t(
              'app.lib.reporting.export-columns.conversation.columns.last-closed-at.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.export-columns.conversation.columns.last-closed-at.hint',
            ),
          },
          {
            name: 'time_zone',
            label: this.intl.t(
              'app.lib.reporting.export-columns.conversation.columns.time-zone.label',
            ),
          },
          {
            name: 'message_direction',
            label: this.intl.t(
              'app.lib.reporting.export-columns.conversation.columns.message-direction.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.export-columns.conversation.columns.message-direction.hint',
            ),
          },
          {
            name: 'reopened',
            label: this.intl.t(
              'app.lib.reporting.export-columns.conversation.columns.reopened.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.export-columns.conversation.columns.reopened.hint',
            ),
          },
          {
            name: 'closed',
            label: this.intl.t(
              'app.lib.reporting.export-columns.conversation.columns.closed.label',
            ),
            hint: this.intl.t('app.lib.reporting.export-columns.conversation.columns.closed.hint'),
          },
          {
            name: 'rating_index',
            label: this.intl.t(
              'app.lib.reporting.export-columns.conversation.columns.rating-index.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.export-columns.conversation.columns.rating-index.hint',
            ),
          },
          {
            name: 'rating_requested',
            label: this.intl.t(
              'app.lib.reporting.export-columns.conversation.columns.rating-requested.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.export-columns.conversation.columns.rating-requested.hint',
            ),
          },
          {
            name: 'rating_updated_at',
            label: this.intl.t(
              'app.lib.reporting.export-columns.conversation.columns.rating-updated-at.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.export-columns.conversation.columns.rating-updated-at.hint',
            ),
          },
          {
            name: 'rating_remark',
            label: this.intl.t(
              'app.lib.reporting.export-columns.conversation.columns.rating-remark.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.export-columns.conversation.columns.rating-remark.hint',
            ),
          },
          {
            name: 'conversation_topics',
            label: this.intl.t(
              'app.lib.reporting.export-columns.conversation.columns.conversation-topics.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.export-columns.conversation.columns.conversation-topics.hint',
            ),
            featureKey: 'conversational_insights',
          },
          {
            name: 'conversation_custom_data_attributes',
            label: this.intl.t(
              'app.lib.reporting.export-columns.conversation.columns.conversation-custom-data-attributes.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.export-columns.conversation.columns.conversation-custom-data-attributes.hint',
            ),
          },
        ],
      },
      {
        sectionTitle: this.intl.t('app.lib.reporting.export-columns.customer-information.title'),
        sectionSubtitle: this.intl.t(
          'app.lib.reporting.export-columns.customer-information.subtitle',
        ),
        sectionName: 'customer',
        sectionIcon: 'lead-qualification',
        columns: [
          {
            name: 'company_ids',
            label: this.intl.t(
              'app.lib.reporting.export-columns.customer-information.columns.company-ids.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.export-columns.customer-information.columns.company-ids.hint',
            ),
          },
          {
            name: 'company_names',
            label: this.intl.t(
              'app.lib.reporting.export-columns.customer-information.columns.company-names.label',
            ),
          },
          {
            name: 'company_tags',
            label: this.intl.t(
              'app.lib.reporting.export-columns.customer-information.columns.company-tags.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.export-columns.customer-information.columns.company-tags.hint',
            ),
          },
          {
            name: 'user_email',
            label: this.intl.t(
              'app.lib.reporting.export-columns.customer-information.columns.user-email.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.export-columns.customer-information.columns.user-email.hint',
            ),
          },
          {
            name: 'user_location',
            label: this.intl.t(
              'app.lib.reporting.export-columns.customer-information.columns.user-location.label',
            ),
          },
          {
            name: 'user_name',
            label: this.intl.t(
              'app.lib.reporting.export-columns.customer-information.columns.user-name.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.export-columns.customer-information.columns.user-name.hint',
            ),
          },
          {
            name: 'user_type',
            label: this.intl.t(
              'app.lib.reporting.export-columns.customer-information.columns.user-type.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.export-columns.customer-information.columns.user-type.hint',
            ),
          },
          {
            name: 'participated_user_ids',
            label: this.intl.t(
              'app.lib.reporting.export-columns.customer-information.columns.participated-user-ids.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.export-columns.customer-information.columns.participated-user-ids.hint',
            ),
          },
          {
            name: 'participated_names',
            label: this.intl.t(
              'app.lib.reporting.export-columns.customer-information.columns.participated-names.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.export-columns.customer-information.columns.participated-names.hint',
            ),
          },
          {
            name: 'participated_emails',
            label: this.intl.t(
              'app.lib.reporting.export-columns.customer-information.columns.participated-emails.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.export-columns.customer-information.columns.participated-emails.hint',
            ),
          },
          {
            name: 'user_tags',
            label: this.intl.t(
              'app.lib.reporting.export-columns.customer-information.columns.user-tags.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.export-columns.customer-information.columns.user-tags.hint',
            ),
          },
          {
            name: 'user_id',
            label: this.intl.t(
              'app.lib.reporting.export-columns.customer-information.columns.user-id.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.export-columns.customer-information.columns.user-id.hint',
            ),
          },
        ],
      },
      {
        sectionTitle: this.intl.t('app.lib.reporting.export-columns.teammate.title'),
        sectionSubtitle: this.intl.t('app.lib.reporting.export-columns.teammate.subtitle'),
        sectionName: 'teammate',
        sectionIcon: 'team',
        columns: [
          {
            name: 'assignee_id',
            label: this.intl.t(
              'app.lib.reporting.export-columns.teammate.columns.assignee-id.label',
            ),
            hint: this.intl.t('app.lib.reporting.export-columns.teammate.columns.assignee-id.hint'),
          },
          {
            name: 'assignee_name',
            label: this.intl.t(
              'app.lib.reporting.export-columns.teammate.columns.assignee-name.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.export-columns.teammate.columns.assignee-name.hint',
            ),
          },
          {
            name: 'team_assignee_id',
            label: this.intl.t(
              'app.lib.reporting.export-columns.teammate.columns.team-assignee-id.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.export-columns.teammate.columns.team-assignee-id.hint',
            ),
          },
          {
            name: 'team_assignee_name',
            label: this.intl.t(
              'app.lib.reporting.export-columns.teammate.columns.team-assignee-name.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.export-columns.teammate.columns.team-assignee-name.hint',
            ),
          },
          {
            name: 'closed_by_id',
            label: this.intl.t(
              'app.lib.reporting.export-columns.teammate.columns.closed-by-id.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.export-columns.teammate.columns.closed-by-id.hint',
            ),
          },
          {
            name: 'closed_by_name',
            label: this.intl.t(
              'app.lib.reporting.export-columns.teammate.columns.closed-by-name.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.export-columns.teammate.columns.closed-by-name.hint',
            ),
          },
          {
            name: 'teammates_participated',
            label: this.intl.t(
              'app.lib.reporting.export-columns.teammate.columns.teammates-participated.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.export-columns.teammate.columns.teammates-participated.hint',
            ),
          },
          {
            name: 'initial_response_time',
            label: this.intl.t(
              'app.lib.reporting.export-columns.teammate.columns.initial-response-time.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.export-columns.teammate.columns.initial-response-time.hint',
            ),
          },
          {
            name: 'time_to_last_close',
            label: this.intl.t(
              'app.lib.reporting.export-columns.teammate.columns.time-to-last-close.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.export-columns.teammate.columns.time-to-last-close.hint',
            ),
          },
          {
            name: 'teammate_replies',
            label: this.intl.t(
              'app.lib.reporting.export-columns.teammate.columns.teammate-replies.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.export-columns.teammate.columns.teammate-replies.hint',
            ),
          },
          {
            name: 'teammate_rated_id',
            label: this.intl.t(
              'app.lib.reporting.export-columns.conversation.columns.teammate-rated-id.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.export-columns.conversation.columns.teammate-rated-id.hint',
            ),
          },
          {
            name: 'teammate_rated_name',
            label: this.intl.t(
              'app.lib.reporting.export-columns.conversation.columns.teammate-rated-name.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.export-columns.conversation.columns.teammate-rated-name.hint',
            ),
          },
        ],
      },
      {
        sectionTitle: this.intl.t('app.lib.reporting.export-columns.channel.title'),
        sectionSubtitle: this.intl.t('app.lib.reporting.export-columns.channel.subtitle'),
        sectionName: 'channel',
        sectionIcon: 'notification-inapp',
        columns: [
          {
            name: 'channel',
            label: this.intl.t('app.lib.reporting.export-columns.channel.columns.channel.label'),
            hint: this.intl.t('app.lib.reporting.export-columns.channel.columns.channel.hint'),
          },
          {
            name: 'source',
            label: this.intl.t('app.lib.reporting.export-columns.channel.columns.source.label'),
            hint: this.intl.t('app.lib.reporting.export-columns.channel.columns.source.hint'),
          },
        ],
      },
    ];
  }
}
