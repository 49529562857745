/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { helper } from '@ember/component/helper';
import {
  states,
  objectTypes,
  matchBehaviors,
} from 'embercom/models/data/matching-system/matching-constants';
import { isNone } from '@ember/utils';

const standardStateName = (state: number, matchBehavior = {}) => {
  switch (state) {
    case states.draft:
      return 'Draft';
    case states.live:
      if (matchBehavior === matchBehaviors.static) {
        return 'Sent';
      }
      return 'Live';
    case states.paused:
    case states.suspended:
      if (matchBehavior === matchBehaviors.static) {
        return 'Sent';
      }
      return 'Paused';
    case states.scheduled:
      return 'Scheduled';
    default:
      return 'Draft';
  }
};

const stateNameForTour = (state: number, tour: Record<string, any> = {}) => {
  if (state === states.live || tour.insertable) {
    return 'Live';
  } else {
    return standardStateName(state);
  }
};

const stateNameForControlGroup = (state: number) => {
  if (state === states.ended) {
    return 'Ended';
  } else {
    return standardStateName(state);
  }
};

export function stateName(_positional: any, namedArgs: NamedArgs) {
  let objectType = namedArgs.objectType;
  let state = namedArgs.state;
  let content = namedArgs.content;
  let matchBehavior = namedArgs.matchBehavior;

  if (isNone(objectType)) {
    throw new Error(`No object type passed to ruleset state helper`);
  }

  switch (objectType) {
    case objectTypes.banner:
    case objectTypes.legacyMessageInApp:
    case objectTypes.legacyMessageEmail:
    case objectTypes.legacyMessagePush:
    case objectTypes.sms:
    case objectTypes.push:
    case objectTypes.email:
    case objectTypes.chat:
    case objectTypes.post:
    case objectTypes.survey:
    case objectTypes.carousel:
    case objectTypes.customBot:
    case objectTypes.inboundCustomBot:
    case objectTypes.buttonCustomBot:
    case objectTypes.condition:
    case objectTypes.wait:
    case objectTypes.tagger:
    case objectTypes.splitter:
    case objectTypes.resolutionBotBehavior:
    case objectTypes.outboundWebhook:
    case objectTypes.tooltipGroup:
    case objectTypes.newsItem:
    case objectTypes.newsfeed:
    case objectTypes.triggerableCustomBot:
    case objectTypes.checklist:
    case objectTypes.whatsapp:
      return standardStateName(state, matchBehavior);
    case objectTypes.tour:
      return stateNameForTour(state, content);
    case objectTypes.legacyControlGroup:
    case objectTypes.controlGroup:
      return stateNameForControlGroup(state);
    case objectTypes.series:
      return standardStateName(state);
    case objectTypes.aiContentSegment:
      return '';
    default:
      throw new Error(`No states available for [${objectType}]`);
  }
}

type NamedArgs = {
  objectType: number;
  state: any;
  content?: Record<string, any>;
  matchBehavior?: number;
};

export interface StateNameSignature {
  Args: {
    Named: NamedArgs;
  };
  Return: string;
}

const stateNameHelper = helper(stateName);

export default stateNameHelper;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'state-name': typeof stateNameHelper;
  }
}
