/* RESPONSIBLE TEAM: team-reporting */
import ChartDataResourceCompatible from 'embercom/lib/reporting/chart-data-resource-compatible';
import DataConfigBuilder from 'embercom/lib/reporting/custom/data-config-builder';
import ViewConfigBuilder from 'embercom/lib/reporting/custom/view-config-builder';
import HighchartsDataBuilder from 'embercom/lib/reporting/flexible/highcharts-data-builder';
import { getOwner } from '@ember/application';

export default class ChartDataResource extends ChartDataResourceCompatible {
  constructor(owner, args) {
    let { renderableChart, onError } = args.named;

    let dataConfig = new DataConfigBuilder(renderableChart).buildDataConfig();
    super(owner, { dataConfig, onError, renderableChart });

    this.viewConfig = new ViewConfigBuilder(renderableChart, owner).buildViewConfig();
  }

  get chartData() {
    if (!this.rawChartData) {
      return [];
    }

    if (this.renderableChart.stacked) {
      // stacked charts
      return new HighchartsDataBuilder(
        getOwner(this),
        this.dataConfig,
        this.viewConfig,
      ).forStackedChartResponse(this.rawChartData);
    }

    return this.rawChartData.map((dataResponse) => {
      if (this.renderableChart.viewBy === null) {
        // simple count
        return {
          name: dataResponse.name,
          data: [dataResponse.aggregations[0].values[0]],
          requestName: dataResponse.name,
        };
      } else if (this.renderableChart.stacked === false) {
        // bar/line chart
        let group = dataResponse.groups[0];
        let aggregation = group.aggregations[0];

        return group.values.map((gv, index) => {
          let x = gv;
          let y = aggregation.values[index];
          let name = group?.names?.get(index);
          return [x, y, name];
        });
      }
    });
  }
}
