/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
import numberFormatter from 'embercom/lib/number-formatter';
import userLabel from '../utility/user-label';

function checkForWarning(message) {
  if (message.isMobilePush) {
    let app = message.app;
    let hasProductionCerts = app.iosSdkApps.every((sdk) => sdk.productionCert);

    if (!hasProductionCerts) {
      return `You are not using a Production iOS push notification certificate for ${app.name}. This message may not send a push notification.`;
    }
  }

  return '';
}

let optionsForManualMessage = function (message) {
  return message.fetchServerRecipientCount().then((count) => {
    if (message.get('isManualMessage') && message.get('selectionState.hasNoUserAddedPredicates')) {
      return {
        title: `Send to all your ${userLabel(message)}?`,
        warning: checkForWarning(message),
        body: `You haven't added any audience rules, so this message will send to all ${count} ${userLabel(
          message,
          { count },
        )} in your app.`,
        confirmButtonText: `Send to all ${numberFormatter(count)} ${userLabel(message, { count })}`,
        cancelButtonText: 'Go back',
      };
    } else {
      return {
        title: `Sending the message`,
        warning: checkForWarning(message),
        body: `You are about to send this message to ${numberFormatter(count)} ${userLabel(
          message,
          { count },
        )}. Do you wish to continue?`,
        confirmButtonText: `Send the message`,
      };
    }
  });
};

let optionsForAutoMessage = function (message) {
  if (message.get('isDraft')) {
    return message.fetchServerRecipientCount().then((count) => {
      if (message.get('hasControlGroup')) {
        return {
          title: `Set this message live`,
          warning: checkForWarning(message), // TODO update bodyComponentVersion also...
          bodyComponentName:
            'message-editor/confirmation-copy/control-group-auto-message-activate-confirm-text',
          confirmContext: { count },
          confirmButtonText: `Set the message live`,
        };
      } else {
        return {
          title: `Setting the message live`,
          warning: checkForWarning(message),
          body: `You are about to make this message live and send to ${numberFormatter(
            count,
          )} ${userLabel(message, {
            count,
          })} who match the message filters. We'll also send to ${userLabel(
            message,
          )} who enter the audience. Do you wish to continue?`,
          confirmButtonText: `Set the message live`,
        };
      }
    });
  } else {
    return {
      title: `Setting the message live`,
      warning: checkForWarning(message),
      body: `You are about to make this message live and send to ${userLabel(
        message,
      )} who match the message filters. ${userLabel(message, {
        capitalize: true,
      })} who received this message previously will not receive it again. Do you wish to continue?`,
      confirmButtonText: `Set the message live`,
    };
  }
};

let optionsForVisitorAutoMessage = function (message) {
  if (message.get('hasControlGroup')) {
    return {
      title: `Set this message live`,
      warning: checkForWarning(message),
      bodyComponentName:
        'message-editor/confirmation-copy/control-group-visitor-auto-message-activate-confirm-text',
      confirmButtonText: `Set the message live`,
    };
  } else {
    return {
      title: `Setting the message live`,
      warning: checkForWarning(message),
      body: `You are about to make this message live and send to ${userLabel(
        message,
      )} who match the message filters. Do you wish to continue?`,
      confirmButtonText: `Set the message live`,
    };
  }
};

export function messageActivateSuccess(message) {
  if (message.get('isManualMessage')) {
    return `The message is sending`;
  } else {
    return `The message was set live`;
  }
}

export function messageActivateFailure(message) {
  if (message.get('isManualMessage')) {
    return `We couldn't send this message. Send us a message and we'll do our best to help out.`;
  } else {
    return `We couldn't set this message live. Send us a message and we'll do our best to help out.`;
  }
}

export function messageActivateOptions(message) {
  if (message.get('isAutoMessage')) {
    return optionsForAutoMessage(message);
  } else if (message.get('isVisitorAutoMessage')) {
    return optionsForVisitorAutoMessage(message);
  } else if (message.get('isManualMessage')) {
    return optionsForManualMessage(message);
  } else {
    throw new Error(
      `Cannot generate activation options for unknown message type: [${message.get('classType')}]`,
    );
  }
}
