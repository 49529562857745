/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
export function messageDeactivateSuccess(message) {
  if (message.get('isManualMessage')) {
    return `The message was stopped.`;
  } else {
    return `The message was paused.`;
  }
}

export function messageDeactivateFailure(message) {
  if (message.get('isManualMessage')) {
    return `We couldn't stop this message. Send us a message and we'll do our best to help out.`;
  } else {
    return `We couldn't pause this message. Send us a message and we'll do our best to help out.`;
  }
}
