/* RESPONSIBLE TEAM: team-phone */
import Helper from '@ember/component/helper';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export default class FormatPhoneNumber extends Helper<{
  Args: {
    Positional: [string];
  };
  Return: string;
}> {
  compute([phoneNumber]: [string]): string {
    let parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
    if (parsedPhoneNumber) {
      return parsedPhoneNumber.formatInternational();
    } else {
      return phoneNumber;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'format-phone-number': typeof FormatPhoneNumber;
  }
}
