/* RESPONSIBLE TEAM: team-frontend-tech */
import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

export default class TrackEventHelper extends Helper {
  @service intercomEventService;

  compute([name, metadata = {}]) {
    return () => {
      this.intercomEventService.trackEvent(name, metadata);
    };
  }
}
