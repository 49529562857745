/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import safeWindowOpen from 'embercom/lib/safe-window-open';
import containerLookup from 'embercom/lib/container-lookup';

export const impersonateInRegion = (region, impersonatedAdminId, impersonatedAdminEmail) => {
  let searchParams = new URLSearchParams({
    impersonatedAdminId,
    impersonatedAdminEmail,
  });
  let regionService = containerLookup('service:regionService');
  safeWindowOpen(`${regionService.impersonationUrl(region)}?${searchParams.toString()}`, '_blank');
};
