/* RESPONSIBLE TEAM: team-phone */
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import parsePhoneNumber from 'libphonenumber-js';

export interface Country {
  code: string;
  nameTranslationKey: string;
  dial_code: string;
  asset_url: string;
}

const countryFlagAssetUrl = (countryCode: string) =>
  assetUrl(`/assets/svgs/flags/${countryCode}.svg`);

const phoneCodes: Record<string, Country> = {
  AF: {
    code: 'AF',
    nameTranslationKey: 'app.lib.phone-codes.afghanistan',
    dial_code: '+93',
    asset_url: countryFlagAssetUrl('af'),
  },
  AL: {
    code: 'AL',
    nameTranslationKey: 'app.lib.phone-codes.albania',
    dial_code: '+355',
    asset_url: countryFlagAssetUrl('al'),
  },
  DZ: {
    code: 'DZ',
    nameTranslationKey: 'app.lib.phone-codes.algeria',
    dial_code: '+213',
    asset_url: countryFlagAssetUrl('dz'),
  },
  AS: {
    code: 'AS',
    nameTranslationKey: 'app.lib.phone-codes.american_samoa',
    dial_code: '+1684',
    asset_url: countryFlagAssetUrl('as'),
  },
  AD: {
    code: 'AD',
    nameTranslationKey: 'app.lib.phone-codes.andorra',
    dial_code: '+376',
    asset_url: countryFlagAssetUrl('ad'),
  },
  AO: {
    code: 'AO',
    nameTranslationKey: 'app.lib.phone-codes.angola',
    dial_code: '+244',
    asset_url: countryFlagAssetUrl('ao'),
  },
  AI: {
    code: 'AI',
    nameTranslationKey: 'app.lib.phone-codes.anguilla',
    dial_code: '+1264',
    asset_url: countryFlagAssetUrl('ai'),
  },
  AG: {
    code: 'AG',
    nameTranslationKey: 'app.lib.phone-codes.antigua_and_barbuda',
    dial_code: '+1268',
    asset_url: countryFlagAssetUrl('ag'),
  },
  AR: {
    code: 'AR',
    nameTranslationKey: 'app.lib.phone-codes.argentina',
    dial_code: '+54',
    asset_url: countryFlagAssetUrl('ar'),
  },
  AM: {
    code: 'AM',
    nameTranslationKey: 'app.lib.phone-codes.armenia',
    dial_code: '+374',
    asset_url: countryFlagAssetUrl('am'),
  },
  AW: {
    code: 'AW',
    nameTranslationKey: 'app.lib.phone-codes.aruba',
    dial_code: '+297',
    asset_url: countryFlagAssetUrl('aw'),
  },
  AU: {
    code: 'AU',
    nameTranslationKey: 'app.lib.phone-codes.australia',
    dial_code: '+61',
    asset_url: countryFlagAssetUrl('au'),
  },
  AT: {
    code: 'AT',
    nameTranslationKey: 'app.lib.phone-codes.austria',
    dial_code: '+43',
    asset_url: countryFlagAssetUrl('at'),
  },
  AZ: {
    code: 'AZ',
    nameTranslationKey: 'app.lib.phone-codes.azerbaijan',
    dial_code: '+994',
    asset_url: countryFlagAssetUrl('az'),
  },
  BS: {
    code: 'BS',
    nameTranslationKey: 'app.lib.phone-codes.bahamas',
    dial_code: '+1242',
    asset_url: countryFlagAssetUrl('bs'),
  },
  BH: {
    code: 'BH',
    nameTranslationKey: 'app.lib.phone-codes.bahrain',
    dial_code: '+973',
    asset_url: countryFlagAssetUrl('bh'),
  },
  BB: {
    code: 'BB',
    nameTranslationKey: 'app.lib.phone-codes.barbados',
    dial_code: '+1246',
    asset_url: countryFlagAssetUrl('bb'),
  },
  BY: {
    code: 'BY',
    nameTranslationKey: 'app.lib.phone-codes.belarus',
    dial_code: '+375',
    asset_url: countryFlagAssetUrl('by'),
  },
  BE: {
    code: 'BE',
    nameTranslationKey: 'app.lib.phone-codes.belgium',
    dial_code: '+32',
    asset_url: countryFlagAssetUrl('be'),
  },
  BZ: {
    code: 'BZ',
    nameTranslationKey: 'app.lib.phone-codes.belize',
    dial_code: '+501',
    asset_url: countryFlagAssetUrl('bz'),
  },
  BD: {
    code: 'BD',
    nameTranslationKey: 'app.lib.phone-codes.bangladesh',
    dial_code: '+880',
    asset_url: countryFlagAssetUrl('bd'),
  },
  BJ: {
    code: 'BJ',
    nameTranslationKey: 'app.lib.phone-codes.benin',
    dial_code: '+229',
    asset_url: countryFlagAssetUrl('bj'),
  },
  BM: {
    code: 'BM',
    nameTranslationKey: 'app.lib.phone-codes.bermuda',
    dial_code: '+1441',
    asset_url: countryFlagAssetUrl('bm'),
  },
  BT: {
    code: 'BT',
    nameTranslationKey: 'app.lib.phone-codes.bhutan',
    dial_code: '+975',
    asset_url: countryFlagAssetUrl('bt'),
  },
  BO: {
    code: 'BO',
    nameTranslationKey: 'app.lib.phone-codes.bolivia_plurinational_state_of_bolivia',
    dial_code: '+591',
    asset_url: countryFlagAssetUrl('bo'),
  },
  BA: {
    code: 'BA',
    nameTranslationKey: 'app.lib.phone-codes.bosnia_and_herzegovina',
    dial_code: '+387',
    asset_url: countryFlagAssetUrl('ba'),
  },
  BW: {
    code: 'BW',
    nameTranslationKey: 'app.lib.phone-codes.botswana',
    dial_code: '+267',
    asset_url: countryFlagAssetUrl('bw'),
  },
  BR: {
    code: 'BR',
    nameTranslationKey: 'app.lib.phone-codes.brazil',
    dial_code: '+55',
    asset_url: countryFlagAssetUrl('br'),
  },
  BN: {
    code: 'BN',
    nameTranslationKey: 'app.lib.phone-codes.brunei_darussalam',
    dial_code: '+673',
    asset_url: countryFlagAssetUrl('bn'),
  },
  BG: {
    code: 'BG',
    nameTranslationKey: 'app.lib.phone-codes.bulgaria',
    dial_code: '+359',
    asset_url: countryFlagAssetUrl('bg'),
  },
  BF: {
    code: 'BF',
    nameTranslationKey: 'app.lib.phone-codes.burkina_faso',
    dial_code: '+226',
    asset_url: countryFlagAssetUrl('bf'),
  },
  BI: {
    code: 'BI',
    nameTranslationKey: 'app.lib.phone-codes.burundi',
    dial_code: '+257',
    asset_url: countryFlagAssetUrl('bi'),
  },
  KH: {
    code: 'KH',
    nameTranslationKey: 'app.lib.phone-codes.cambodia',
    dial_code: '+855',
    asset_url: countryFlagAssetUrl('kh'),
  },
  CM: {
    code: 'CM',
    nameTranslationKey: 'app.lib.phone-codes.cameroon',
    dial_code: '+237',
    asset_url: countryFlagAssetUrl('cm'),
  },
  CA: {
    code: 'CA',
    nameTranslationKey: 'app.lib.phone-codes.canada',
    dial_code: '+1',
    asset_url: countryFlagAssetUrl('ca'),
  },
  CV: {
    code: 'CV',
    nameTranslationKey: 'app.lib.phone-codes.cape_verde',
    dial_code: '+238',
    asset_url: countryFlagAssetUrl('cv'),
  },
  KY: {
    code: 'KY',
    nameTranslationKey: 'app.lib.phone-codes.cayman_islands',
    dial_code: '+ 345',
    asset_url: countryFlagAssetUrl('ky'),
  },
  CF: {
    code: 'CF',
    nameTranslationKey: 'app.lib.phone-codes.central_african_republic',
    dial_code: '+236',
    asset_url: countryFlagAssetUrl('cf'),
  },
  TD: {
    code: 'TD',
    nameTranslationKey: 'app.lib.phone-codes.chad',
    dial_code: '+235',
    asset_url: countryFlagAssetUrl('td'),
  },
  CL: {
    code: 'CL',
    nameTranslationKey: 'app.lib.phone-codes.chile',
    dial_code: '+56',
    asset_url: countryFlagAssetUrl('cl'),
  },
  CN: {
    code: 'CN',
    nameTranslationKey: 'app.lib.phone-codes.china',
    dial_code: '+86',
    asset_url: countryFlagAssetUrl('cn'),
  },
  CX: {
    code: 'CX',
    nameTranslationKey: 'app.lib.phone-codes.christmas_island',
    dial_code: '+61',
    asset_url: countryFlagAssetUrl('cx'),
  },
  CC: {
    code: 'CC',
    nameTranslationKey: 'app.lib.phone-codes.cocos_keeling_islands',
    dial_code: '+61',
    asset_url: countryFlagAssetUrl('cc'),
  },
  CO: {
    code: 'CO',
    nameTranslationKey: 'app.lib.phone-codes.colombia',
    dial_code: '+57',
    asset_url: countryFlagAssetUrl('co'),
  },
  KM: {
    code: 'KM',
    nameTranslationKey: 'app.lib.phone-codes.comoros',
    dial_code: '+269',
    asset_url: countryFlagAssetUrl('km'),
  },
  CG: {
    code: 'CG',
    nameTranslationKey: 'app.lib.phone-codes.congo',
    dial_code: '+242',
    asset_url: countryFlagAssetUrl('cg'),
  },
  CD: {
    code: 'CD',
    nameTranslationKey: 'app.lib.phone-codes.congo_democratic_republic_of_the_congo',
    dial_code: '+243',
    asset_url: countryFlagAssetUrl('cd'),
  },
  CK: {
    code: 'CK',
    nameTranslationKey: 'app.lib.phone-codes.cook_islands',
    dial_code: '+682',
    asset_url: countryFlagAssetUrl('ck'),
  },
  CR: {
    code: 'CR',
    nameTranslationKey: 'app.lib.phone-codes.costa_rica',
    dial_code: '+506',
    asset_url: countryFlagAssetUrl('cr'),
  },
  CI: {
    code: 'CI',
    nameTranslationKey: 'app.lib.phone-codes.cote_d_ivoire',
    dial_code: '+225',
    asset_url: countryFlagAssetUrl('ci'),
  },
  HR: {
    code: 'HR',
    nameTranslationKey: 'app.lib.phone-codes.croatia',
    dial_code: '+385',
    asset_url: countryFlagAssetUrl('hr'),
  },
  CY: {
    code: 'CY',
    nameTranslationKey: 'app.lib.phone-codes.cyprus',
    dial_code: '+357',
    asset_url: countryFlagAssetUrl('cy'),
  },
  CZ: {
    code: 'CZ',
    nameTranslationKey: 'app.lib.phone-codes.czech_republic',
    dial_code: '+420',
    asset_url: countryFlagAssetUrl('cz'),
  },
  DK: {
    code: 'DK',
    nameTranslationKey: 'app.lib.phone-codes.denmark',
    dial_code: '+45',
    asset_url: countryFlagAssetUrl('dk'),
  },
  DJ: {
    code: 'DJ',
    nameTranslationKey: 'app.lib.phone-codes.djibouti',
    dial_code: '+253',
    asset_url: countryFlagAssetUrl('dj'),
  },
  DM: {
    code: 'DM',
    nameTranslationKey: 'app.lib.phone-codes.dominica',
    dial_code: '+1767',
    asset_url: countryFlagAssetUrl('dm'),
  },
  DO: {
    code: 'DO',
    nameTranslationKey: 'app.lib.phone-codes.dominican_republic',
    dial_code: '+1849',
    asset_url: countryFlagAssetUrl('do'),
  },
  EC: {
    code: 'EC',
    nameTranslationKey: 'app.lib.phone-codes.ecuador',
    dial_code: '+593',
    asset_url: countryFlagAssetUrl('ec'),
  },
  EG: {
    code: 'EG',
    nameTranslationKey: 'app.lib.phone-codes.egypt',
    dial_code: '+20',
    asset_url: countryFlagAssetUrl('eg'),
  },
  SV: {
    code: 'SV',
    nameTranslationKey: 'app.lib.phone-codes.el_salvador',
    dial_code: '+503',
    asset_url: countryFlagAssetUrl('sv'),
  },
  GQ: {
    code: 'GQ',
    nameTranslationKey: 'app.lib.phone-codes.equatorial_guinea',
    dial_code: '+240',
    asset_url: countryFlagAssetUrl('gq'),
  },
  ER: {
    code: 'ER',
    nameTranslationKey: 'app.lib.phone-codes.eritrea',
    dial_code: '+291',
    asset_url: countryFlagAssetUrl('er'),
  },
  EE: {
    code: 'EE',
    nameTranslationKey: 'app.lib.phone-codes.estonia',
    dial_code: '+372',
    asset_url: countryFlagAssetUrl('ee'),
  },
  ET: {
    code: 'ET',
    nameTranslationKey: 'app.lib.phone-codes.ethiopia',
    dial_code: '+251',
    asset_url: countryFlagAssetUrl('et'),
  },
  FK: {
    code: 'FK',
    nameTranslationKey: 'app.lib.phone-codes.falkland_islands_malvinas',
    dial_code: '+500',
    asset_url: countryFlagAssetUrl('fk'),
  },
  FO: {
    code: 'FO',
    nameTranslationKey: 'app.lib.phone-codes.faroe_islands',
    dial_code: '+298',
    asset_url: countryFlagAssetUrl('fo'),
  },
  FJ: {
    code: 'FJ',
    nameTranslationKey: 'app.lib.phone-codes.fiji',
    dial_code: '+679',
    asset_url: countryFlagAssetUrl('fj'),
  },
  FI: {
    code: 'FI',
    nameTranslationKey: 'app.lib.phone-codes.finland',
    dial_code: '+358',
    asset_url: countryFlagAssetUrl('fi'),
  },
  FR: {
    code: 'FR',
    nameTranslationKey: 'app.lib.phone-codes.france',
    dial_code: '+33',
    asset_url: countryFlagAssetUrl('fr'),
  },
  GF: {
    code: 'GF',
    nameTranslationKey: 'app.lib.phone-codes.french_guiana',
    dial_code: '+594',
    asset_url: countryFlagAssetUrl('gf'),
  },
  PF: {
    code: 'PF',
    nameTranslationKey: 'app.lib.phone-codes.french_polynesia',
    dial_code: '+689',
    asset_url: countryFlagAssetUrl('pf'),
  },
  GA: {
    code: 'GA',
    nameTranslationKey: 'app.lib.phone-codes.gabon',
    dial_code: '+241',
    asset_url: countryFlagAssetUrl('ga'),
  },
  GM: {
    code: 'GM',
    nameTranslationKey: 'app.lib.phone-codes.gambia',
    dial_code: '+220',
    asset_url: countryFlagAssetUrl('gm'),
  },
  GE: {
    code: 'GE',
    nameTranslationKey: 'app.lib.phone-codes.georgia',
    dial_code: '+995',
    asset_url: countryFlagAssetUrl('ge'),
  },
  DE: {
    code: 'DE',
    nameTranslationKey: 'app.lib.phone-codes.germany',
    dial_code: '+49',
    asset_url: countryFlagAssetUrl('de'),
  },
  GH: {
    code: 'GH',
    nameTranslationKey: 'app.lib.phone-codes.ghana',
    dial_code: '+233',
    asset_url: countryFlagAssetUrl('gh'),
  },
  GI: {
    code: 'GI',
    nameTranslationKey: 'app.lib.phone-codes.gibraltar',
    dial_code: '+350',
    asset_url: countryFlagAssetUrl('gi'),
  },
  GR: {
    code: 'GR',
    nameTranslationKey: 'app.lib.phone-codes.greece',
    dial_code: '+30',
    asset_url: countryFlagAssetUrl('gr'),
  },
  GL: {
    code: 'GL',
    nameTranslationKey: 'app.lib.phone-codes.greenland',
    dial_code: '+299',
    asset_url: countryFlagAssetUrl('gl'),
  },
  GD: {
    code: 'GD',
    nameTranslationKey: 'app.lib.phone-codes.grenada',
    dial_code: '+1473',
    asset_url: countryFlagAssetUrl('gd'),
  },
  GG: {
    code: 'GG',
    nameTranslationKey: 'app.lib.phone-codes.guernsey',
    dial_code: '+44',
    asset_url: countryFlagAssetUrl('gg'),
  },
  GU: {
    code: 'GU',
    nameTranslationKey: 'app.lib.phone-codes.guam',
    dial_code: '+1671',
    asset_url: countryFlagAssetUrl('gu'),
  },
  GT: {
    code: 'GT',
    nameTranslationKey: 'app.lib.phone-codes.guatemala',
    dial_code: '+502',
    asset_url: countryFlagAssetUrl('gt'),
  },
  GN: {
    code: 'GN',
    nameTranslationKey: 'app.lib.phone-codes.guinea',
    dial_code: '+224',
    asset_url: countryFlagAssetUrl('gn'),
  },
  GW: {
    code: 'GW',
    nameTranslationKey: 'app.lib.phone-codes.guinea_bissau',
    dial_code: '+245',
    asset_url: countryFlagAssetUrl('gw'),
  },
  GY: {
    code: 'GY',
    nameTranslationKey: 'app.lib.phone-codes.guyana',
    dial_code: '+592',
    asset_url: countryFlagAssetUrl('gy'),
  },
  HT: {
    code: 'HT',
    nameTranslationKey: 'app.lib.phone-codes.haiti',
    dial_code: '+509',
    asset_url: countryFlagAssetUrl('ht'),
  },
  VA: {
    code: 'VA',
    nameTranslationKey: 'app.lib.phone-codes.holy_see_vatican_city_state)',
    dial_code: '+379',
    asset_url: countryFlagAssetUrl('va'),
  },
  HN: {
    code: 'HN',
    nameTranslationKey: 'app.lib.phone-codes.honduras',
    dial_code: '+504',
    asset_url: countryFlagAssetUrl('hn'),
  },
  HK: {
    code: 'HK',
    nameTranslationKey: 'app.lib.phone-codes.hong_kong',
    dial_code: '+852',
    asset_url: countryFlagAssetUrl('hk'),
  },
  HU: {
    code: 'HU',
    nameTranslationKey: 'app.lib.phone-codes.hungary',
    dial_code: '+36',
    asset_url: countryFlagAssetUrl('hu'),
  },
  IS: {
    code: 'IS',
    nameTranslationKey: 'app.lib.phone-codes.iceland',
    dial_code: '+354',
    asset_url: countryFlagAssetUrl('is'),
  },
  IN: {
    code: 'IN',
    nameTranslationKey: 'app.lib.phone-codes.india',
    dial_code: '+91',
    asset_url: countryFlagAssetUrl('in'),
  },
  ID: {
    code: 'ID',
    nameTranslationKey: 'app.lib.phone-codes.indonesia',
    dial_code: '+62',
    asset_url: countryFlagAssetUrl('id'),
  },
  IQ: {
    code: 'IQ',
    nameTranslationKey: 'app.lib.phone-codes.iraq',
    dial_code: '+964',
    asset_url: countryFlagAssetUrl('iq'),
  },
  IE: {
    code: 'IE',
    nameTranslationKey: 'app.lib.phone-codes.ireland',
    dial_code: '+353',
    asset_url: countryFlagAssetUrl('ie'),
  },
  IM: {
    code: 'IM',
    nameTranslationKey: 'app.lib.phone-codes.isle of Man',
    dial_code: '+44',
    asset_url: countryFlagAssetUrl('im'),
  },
  IL: {
    code: 'IL',
    nameTranslationKey: 'app.lib.phone-codes.israel',
    dial_code: '+972',
    asset_url: countryFlagAssetUrl('il'),
  },
  IT: {
    code: 'IT',
    nameTranslationKey: 'app.lib.phone-codes.italy',
    dial_code: '+39',
    asset_url: countryFlagAssetUrl('it'),
  },
  JM: {
    code: 'JM',
    nameTranslationKey: 'app.lib.phone-codes.jamaica',
    dial_code: '+1876',
    asset_url: countryFlagAssetUrl('jm'),
  },
  JP: {
    code: 'JP',
    nameTranslationKey: 'app.lib.phone-codes.japan',
    dial_code: '+81',
    asset_url: countryFlagAssetUrl('jp'),
  },
  JE: {
    code: 'JE',
    nameTranslationKey: 'app.lib.phone-codes.jersey',
    dial_code: '+44',
    asset_url: countryFlagAssetUrl('je'),
  },
  JO: {
    code: 'JO',
    nameTranslationKey: 'app.lib.phone-codes.jordan',
    dial_code: '+962',
    asset_url: countryFlagAssetUrl('jo'),
  },
  KZ: {
    code: 'KZ',
    nameTranslationKey: 'app.lib.phone-codes.kazakhstan',
    dial_code: '+7',
    asset_url: countryFlagAssetUrl('kz'),
  },
  KE: {
    code: 'KE',
    nameTranslationKey: 'app.lib.phone-codes.kenya',
    dial_code: '+254',
    asset_url: countryFlagAssetUrl('ke'),
  },
  KI: {
    code: 'KI',
    nameTranslationKey: 'app.lib.phone-codes.kiribati',
    dial_code: '+686',
    asset_url: countryFlagAssetUrl('ki'),
  },
  KO: {
    code: 'KO',
    nameTranslationKey: 'app.lib.phone-codes.kosovo',
    dial_code: '+383',
    asset_url: countryFlagAssetUrl('xk'),
  },
  KR: {
    code: 'KR',
    nameTranslationKey: 'app.lib.phone-codes.korea_republic_of_south_korea',
    dial_code: '+82',
    asset_url: countryFlagAssetUrl('kr'),
  },
  KW: {
    code: 'KW',
    nameTranslationKey: 'app.lib.phone-codes.kuwait',
    dial_code: '+965',
    asset_url: countryFlagAssetUrl('kw'),
  },
  KG: {
    code: 'KG',
    nameTranslationKey: 'app.lib.phone-codes.kyrgyzstan',
    dial_code: '+996',
    asset_url: countryFlagAssetUrl('kg'),
  },
  LA: {
    code: 'LA',
    nameTranslationKey: 'app.lib.phone-codes.laos',
    dial_code: '+856',
    asset_url: countryFlagAssetUrl('la'),
  },
  LV: {
    code: 'LV',
    nameTranslationKey: 'app.lib.phone-codes.latvia',
    dial_code: '+371',
    asset_url: countryFlagAssetUrl('lv'),
  },
  LB: {
    code: 'LB',
    nameTranslationKey: 'app.lib.phone-codes.lebanon',
    dial_code: '+961',
    asset_url: countryFlagAssetUrl('lb'),
  },
  LS: {
    code: 'LS',
    nameTranslationKey: 'app.lib.phone-codes.lesotho',
    dial_code: '+266',
    asset_url: countryFlagAssetUrl('ls'),
  },
  LR: {
    code: 'LR',
    nameTranslationKey: 'app.lib.phone-codes.liberia',
    dial_code: '+231',
    asset_url: countryFlagAssetUrl('lr'),
  },
  LY: {
    code: 'LY',
    nameTranslationKey: 'app.lib.phone-codes.libyan_arab_jamahiriya',
    dial_code: '+218',
    asset_url: countryFlagAssetUrl('ly'),
  },
  LI: {
    code: 'LI',
    nameTranslationKey: 'app.lib.phone-codes.liechtenstein',
    dial_code: '+423',
    asset_url: countryFlagAssetUrl('li'),
  },
  LT: {
    code: 'LT',
    nameTranslationKey: 'app.lib.phone-codes.lithuania',
    dial_code: '+370',
    asset_url: countryFlagAssetUrl('lt'),
  },
  LU: {
    code: 'LU',
    nameTranslationKey: 'app.lib.phone-codes.luxembourg',
    dial_code: '+352',
    asset_url: countryFlagAssetUrl('lu'),
  },
  MO: {
    code: 'MO',
    nameTranslationKey: 'app.lib.phone-codes.macao',
    dial_code: '+853',
    asset_url: countryFlagAssetUrl('mo'),
  },
  MK: {
    code: 'MK',
    nameTranslationKey: 'app.lib.phone-codes.macedonia',
    dial_code: '+389',
    asset_url: countryFlagAssetUrl('mk'),
  },
  MG: {
    code: 'MG',
    nameTranslationKey: 'app.lib.phone-codes.madagascar',
    dial_code: '+261',
    asset_url: countryFlagAssetUrl('mg'),
  },
  MW: {
    code: 'MW',
    nameTranslationKey: 'app.lib.phone-codes.malawi',
    dial_code: '+265',
    asset_url: countryFlagAssetUrl('mw'),
  },
  MY: {
    code: 'MY',
    nameTranslationKey: 'app.lib.phone-codes.malaysia',
    dial_code: '+60',
    asset_url: countryFlagAssetUrl('my'),
  },
  MV: {
    code: 'MV',
    nameTranslationKey: 'app.lib.phone-codes.maldives',
    dial_code: '+960',
    asset_url: countryFlagAssetUrl('mv'),
  },
  ML: {
    code: 'ML',
    nameTranslationKey: 'app.lib.phone-codes.mali',
    dial_code: '+223',
    asset_url: countryFlagAssetUrl('ml'),
  },
  MT: {
    code: 'MT',
    nameTranslationKey: 'app.lib.phone-codes.malta',
    dial_code: '+356',
    asset_url: countryFlagAssetUrl('mt'),
  },
  MH: {
    code: 'MH',
    nameTranslationKey: 'app.lib.phone-codes.marshall_islands',
    dial_code: '+692',
    asset_url: countryFlagAssetUrl('mh'),
  },
  MR: {
    code: 'MR',
    nameTranslationKey: 'app.lib.phone-codes.mauritania',
    dial_code: '+222',
    asset_url: countryFlagAssetUrl('mr'),
  },
  MU: {
    code: 'MU',
    nameTranslationKey: 'app.lib.phone-codes.mauritius',
    dial_code: '+230',
    asset_url: countryFlagAssetUrl('mu'),
  },
  MX: {
    code: 'MX',
    nameTranslationKey: 'app.lib.phone-codes.mexico',
    dial_code: '+52',
    asset_url: countryFlagAssetUrl('mx'),
  },
  FM: {
    code: 'FM',
    nameTranslationKey: 'app.lib.phone-codes.micronesia_federated_states_of_micronesia',
    dial_code: '+691',
    asset_url: countryFlagAssetUrl('fm'),
  },
  MD: {
    code: 'MD',
    nameTranslationKey: 'app.lib.phone-codes.moldova',
    dial_code: '+373',
    asset_url: countryFlagAssetUrl('md'),
  },
  MC: {
    code: 'MC',
    nameTranslationKey: 'app.lib.phone-codes.monaco',
    dial_code: '+377',
    asset_url: countryFlagAssetUrl('mc'),
  },
  MN: {
    code: 'MN',
    nameTranslationKey: 'app.lib.phone-codes.mongolia',
    dial_code: '+976',
    asset_url: countryFlagAssetUrl('mn'),
  },
  ME: {
    code: 'ME',
    nameTranslationKey: 'app.lib.phone-codes.montenegro',
    dial_code: '+382',
    asset_url: countryFlagAssetUrl('me'),
  },
  MS: {
    code: 'MS',
    nameTranslationKey: 'app.lib.phone-codes.montserrat',
    dial_code: '+1664',
    asset_url: countryFlagAssetUrl('ms'),
  },
  MA: {
    code: 'MA',
    nameTranslationKey: 'app.lib.phone-codes.morocco',
    dial_code: '+212',
    asset_url: countryFlagAssetUrl('ma'),
  },
  MZ: {
    code: 'MZ',
    nameTranslationKey: 'app.lib.phone-codes.mozambique',
    dial_code: '+258',
    asset_url: countryFlagAssetUrl('mz'),
  },
  MM: {
    code: 'MM',
    nameTranslationKey: 'app.lib.phone-codes.myanmar',
    dial_code: '+95',
    asset_url: countryFlagAssetUrl('mm'),
  },
  NA: {
    code: 'NA',
    nameTranslationKey: 'app.lib.phone-codes.namibia',
    dial_code: '+264',
    asset_url: countryFlagAssetUrl('na'),
  },
  NR: {
    code: 'NR',
    nameTranslationKey: 'app.lib.phone-codes.nauru',
    dial_code: '+674',
    asset_url: countryFlagAssetUrl('nr'),
  },
  NP: {
    code: 'NP',
    nameTranslationKey: 'app.lib.phone-codes.nepal',
    dial_code: '+977',
    asset_url: countryFlagAssetUrl('np'),
  },
  NL: {
    code: 'NL',
    nameTranslationKey: 'app.lib.phone-codes.netherlands',
    dial_code: '+31',
    asset_url: countryFlagAssetUrl('nl'),
  },
  AN: {
    code: 'AN',
    nameTranslationKey: 'app.lib.phone-codes.netherlands_antilles',
    dial_code: '+599',
    asset_url: countryFlagAssetUrl('an'),
  },
  NC: {
    code: 'NC',
    nameTranslationKey: 'app.lib.phone-codes.new_caledonia',
    dial_code: '+687',
    asset_url: countryFlagAssetUrl('nc'),
  },
  NZ: {
    code: 'NZ',
    nameTranslationKey: 'app.lib.phone-codes.new_zealand',
    dial_code: '+64',
    asset_url: countryFlagAssetUrl('nz'),
  },
  NI: {
    code: 'NI',
    nameTranslationKey: 'app.lib.phone-codes.nicaragua',
    dial_code: '+505',
    asset_url: countryFlagAssetUrl('ni'),
  },
  NE: {
    code: 'NE',
    nameTranslationKey: 'app.lib.phone-codes.niger',
    dial_code: '+227',
    asset_url: countryFlagAssetUrl('ne'),
  },
  NG: {
    code: 'NG',
    nameTranslationKey: 'app.lib.phone-codes.nigeria',
    dial_code: '+234',
    asset_url: countryFlagAssetUrl('ng'),
  },
  NU: {
    code: 'NU',
    nameTranslationKey: 'app.lib.phone-codes.niue',
    dial_code: '+683',
    asset_url: countryFlagAssetUrl('nu'),
  },
  NF: {
    code: 'NF',
    nameTranslationKey: 'app.lib.phone-codes.norfolk_island',
    dial_code: '+672',
    asset_url: countryFlagAssetUrl('nf'),
  },
  NO: {
    code: 'NO',
    nameTranslationKey: 'app.lib.phone-codes.norway',
    dial_code: '+47',
    asset_url: countryFlagAssetUrl('no'),
  },
  OM: {
    code: 'OM',
    nameTranslationKey: 'app.lib.phone-codes.oman',
    dial_code: '+968',
    asset_url: countryFlagAssetUrl('om'),
  },
  PK: {
    code: 'PK',
    nameTranslationKey: 'app.lib.phone-codes.pakistan',
    dial_code: '+92',
    asset_url: countryFlagAssetUrl('pk'),
  },
  PW: {
    code: 'PW',
    nameTranslationKey: 'app.lib.phone-codes.palau',
    dial_code: '+680',
    asset_url: countryFlagAssetUrl('pw'),
  },
  PS: {
    code: 'PS',
    nameTranslationKey: 'app.lib.phone-codes.palestinian_territory_occupied',
    dial_code: '+970',
    asset_url: countryFlagAssetUrl('ps'),
  },
  PA: {
    code: 'PA',
    nameTranslationKey: 'app.lib.phone-codes.panama',
    dial_code: '+507',
    asset_url: countryFlagAssetUrl('pa'),
  },
  PG: {
    code: 'PG',
    nameTranslationKey: 'app.lib.phone-codes.papua_new_guinea',
    dial_code: '+675',
    asset_url: countryFlagAssetUrl('pg'),
  },
  PY: {
    code: 'PY',
    nameTranslationKey: 'app.lib.phone-codes.paraguay',
    dial_code: '+595',
    asset_url: countryFlagAssetUrl('py'),
  },
  PE: {
    code: 'PE',
    nameTranslationKey: 'app.lib.phone-codes.peru',
    dial_code: '+51',
    asset_url: countryFlagAssetUrl('pe'),
  },
  PH: {
    code: 'PH',
    nameTranslationKey: 'app.lib.phone-codes.philippines',
    dial_code: '+63',
    asset_url: countryFlagAssetUrl('ph'),
  },
  PL: {
    code: 'PL',
    nameTranslationKey: 'app.lib.phone-codes.poland',
    dial_code: '+48',
    asset_url: countryFlagAssetUrl('pl'),
  },
  PT: {
    code: 'PT',
    nameTranslationKey: 'app.lib.phone-codes.portugal',
    dial_code: '+351',
    asset_url: countryFlagAssetUrl('pt'),
  },
  PR: {
    code: 'PR',
    nameTranslationKey: 'app.lib.phone-codes.puerto_rico',
    dial_code: '+1939',
    asset_url: countryFlagAssetUrl('pr'),
  },
  QA: {
    code: 'QA',
    nameTranslationKey: 'app.lib.phone-codes.qatar',
    dial_code: '+974',
    asset_url: countryFlagAssetUrl('qa'),
  },
  RO: {
    code: 'RO',
    nameTranslationKey: 'app.lib.phone-codes.romania',
    dial_code: '+40',
    asset_url: countryFlagAssetUrl('ro'),
  },
  RU: {
    code: 'RU',
    nameTranslationKey: 'app.lib.phone-codes.russia',
    dial_code: '+7',
    asset_url: countryFlagAssetUrl('ru'),
  },
  RW: {
    code: 'RW',
    nameTranslationKey: 'app.lib.phone-codes.rwanda',
    dial_code: '+250',
    asset_url: countryFlagAssetUrl('rw'),
  },
  KN: {
    code: 'KN',
    nameTranslationKey: 'app.lib.phone-codes.saint_kitts_and_nevis',
    dial_code: '+1869',
    asset_url: countryFlagAssetUrl('kn'),
  },
  LC: {
    code: 'LC',
    nameTranslationKey: 'app.lib.phone-codes.saint_lucia',
    dial_code: '+1758',
    asset_url: countryFlagAssetUrl('lc'),
  },
  VC: {
    code: 'VC',
    nameTranslationKey: 'app.lib.phone-codes.saint_vincent_and_the_grenadines',
    dial_code: '+1784',
    asset_url: countryFlagAssetUrl('vc'),
  },
  WS: {
    code: 'WS',
    nameTranslationKey: 'app.lib.phone-codes.samoa',
    dial_code: '+685',
    asset_url: countryFlagAssetUrl('ws'),
  },
  SM: {
    code: 'SM',
    nameTranslationKey: 'app.lib.phone-codes.san_marino',
    dial_code: '+378',
    asset_url: countryFlagAssetUrl('sm'),
  },
  ST: {
    code: 'ST',
    nameTranslationKey: 'app.lib.phone-codes.sao_tome_and_principe',
    dial_code: '+239',
    asset_url: countryFlagAssetUrl('st'),
  },
  SA: {
    code: 'SA',
    nameTranslationKey: 'app.lib.phone-codes.saudi_arabia',
    dial_code: '+966',
    asset_url: countryFlagAssetUrl('sa'),
  },
  SN: {
    code: 'SN',
    nameTranslationKey: 'app.lib.phone-codes.senegal',
    dial_code: '+221',
    asset_url: countryFlagAssetUrl('sn'),
  },
  RS: {
    code: 'RS',
    nameTranslationKey: 'app.lib.phone-codes.serbia',
    dial_code: '+381',
    asset_url: countryFlagAssetUrl('rs'),
  },
  SC: {
    code: 'SC',
    nameTranslationKey: 'app.lib.phone-codes.seychelles',
    dial_code: '+248',
    asset_url: countryFlagAssetUrl('sc'),
  },
  SL: {
    code: 'SL',
    nameTranslationKey: 'app.lib.phone-codes.sierra_leone',
    dial_code: '+232',
    asset_url: countryFlagAssetUrl('sl'),
  },
  SG: {
    code: 'SG',
    nameTranslationKey: 'app.lib.phone-codes.singapore',
    dial_code: '+65',
    asset_url: countryFlagAssetUrl('sg'),
  },
  SK: {
    code: 'SK',
    nameTranslationKey: 'app.lib.phone-codes.slovakia',
    dial_code: '+421',
    asset_url: countryFlagAssetUrl('sk'),
  },
  SI: {
    code: 'SI',
    nameTranslationKey: 'app.lib.phone-codes.slovenia',
    dial_code: '+386',
    asset_url: countryFlagAssetUrl('si'),
  },
  SB: {
    code: 'SB',
    nameTranslationKey: 'app.lib.phone-codes.solomon_islands',
    dial_code: '+677',
    asset_url: countryFlagAssetUrl('sb'),
  },
  SO: {
    code: 'SO',
    nameTranslationKey: 'app.lib.phone-codes.somalia',
    dial_code: '+252',
    asset_url: countryFlagAssetUrl('so'),
  },
  ZA: {
    code: 'ZA',
    nameTranslationKey: 'app.lib.phone-codes.south_africa',
    dial_code: '+27',
    asset_url: countryFlagAssetUrl('za'),
  },
  SS: {
    code: 'SS',
    nameTranslationKey: 'app.lib.phone-codes.south_sudan',
    dial_code: '+211',
    asset_url: countryFlagAssetUrl('ss'),
  },
  ES: {
    code: 'ES',
    nameTranslationKey: 'app.lib.phone-codes.spain',
    dial_code: '+34',
    asset_url: countryFlagAssetUrl('es'),
  },
  LK: {
    code: 'LK',
    nameTranslationKey: 'app.lib.phone-codes.sri_lanka',
    dial_code: '+94',
    asset_url: countryFlagAssetUrl('lk'),
  },
  SR: {
    code: 'SR',
    nameTranslationKey: 'app.lib.phone-codes.suriname',
    dial_code: '+597',
    asset_url: countryFlagAssetUrl('sr'),
  },
  SZ: {
    code: 'SZ',
    nameTranslationKey: 'app.lib.phone-codes.swaziland',
    dial_code: '+268',
    asset_url: countryFlagAssetUrl('sz'),
  },
  SE: {
    code: 'SE',
    nameTranslationKey: 'app.lib.phone-codes.sweden',
    dial_code: '+46',
    asset_url: countryFlagAssetUrl('se'),
  },
  CH: {
    code: 'CH',
    nameTranslationKey: 'app.lib.phone-codes.switzerland',
    dial_code: '+41',
    asset_url: countryFlagAssetUrl('ch'),
  },
  TW: {
    code: 'TW',
    nameTranslationKey: 'app.lib.phone-codes.taiwan',
    dial_code: '+886',
    asset_url: countryFlagAssetUrl('tw'),
  },
  TJ: {
    code: 'TJ',
    nameTranslationKey: 'app.lib.phone-codes.tajikistan',
    dial_code: '+992',
    asset_url: countryFlagAssetUrl('tj'),
  },
  TZ: {
    code: 'TZ',
    nameTranslationKey: 'app.lib.phone-codes.tanzania_united_republic_of_tanzania',
    dial_code: '+255',
    asset_url: countryFlagAssetUrl('tz'),
  },
  TH: {
    code: 'TH',
    nameTranslationKey: 'app.lib.phone-codes.thailand',
    dial_code: '+66',
    asset_url: countryFlagAssetUrl('th'),
  },
  TL: {
    code: 'TL',
    nameTranslationKey: 'app.lib.phone-codes.timor_leste',
    dial_code: '+670',
    asset_url: countryFlagAssetUrl('tl'),
  },
  TG: {
    code: 'TG',
    nameTranslationKey: 'app.lib.phone-codes.togo',
    dial_code: '+228',
    asset_url: countryFlagAssetUrl('tg'),
  },
  TK: {
    code: 'TK',
    nameTranslationKey: 'app.lib.phone-codes.tokelau',
    dial_code: '+690',
    asset_url: countryFlagAssetUrl('tk'),
  },
  TO: {
    code: 'TO',
    nameTranslationKey: 'app.lib.phone-codes.tonga',
    dial_code: '+676',
    asset_url: countryFlagAssetUrl('to'),
  },
  TT: {
    code: 'TT',
    nameTranslationKey: 'app.lib.phone-codes.trinidad_and_tobago',
    dial_code: '+1868',
    asset_url: countryFlagAssetUrl('tt'),
  },
  TN: {
    code: 'TN',
    nameTranslationKey: 'app.lib.phone-codes.tunisia',
    dial_code: '+216',
    asset_url: countryFlagAssetUrl('tn'),
  },
  TR: {
    code: 'TR',
    nameTranslationKey: 'app.lib.phone-codes.turkey',
    dial_code: '+90',
    asset_url: countryFlagAssetUrl('tr'),
  },
  TM: {
    code: 'TM',
    nameTranslationKey: 'app.lib.phone-codes.turkmenistan',
    dial_code: '+993',
    asset_url: countryFlagAssetUrl('tm'),
  },
  TC: {
    code: 'TC',
    nameTranslationKey: 'app.lib.phone-codes.turks_and_caicos_islands',
    dial_code: '+1649',
    asset_url: countryFlagAssetUrl('tc'),
  },
  TV: {
    code: 'TV',
    nameTranslationKey: 'app.lib.phone-codes.tuvalu',
    dial_code: '+688',
    asset_url: countryFlagAssetUrl('tv'),
  },
  UG: {
    code: 'UG',
    nameTranslationKey: 'app.lib.phone-codes.uganda',
    dial_code: '+256',
    asset_url: countryFlagAssetUrl('ug'),
  },
  UA: {
    code: 'UA',
    nameTranslationKey: 'app.lib.phone-codes.ukraine',
    dial_code: '+380',
    asset_url: countryFlagAssetUrl('ua'),
  },
  AE: {
    code: 'AE',
    nameTranslationKey: 'app.lib.phone-codes.united_arab_emirates',
    dial_code: '+971',
    asset_url: countryFlagAssetUrl('ae'),
  },
  GB: {
    code: 'GB',
    nameTranslationKey: 'app.lib.phone-codes.united_kingdom',
    dial_code: '+44',
    asset_url: countryFlagAssetUrl('gb'),
  },
  US: {
    code: 'US',
    nameTranslationKey: 'app.lib.phone-codes.united_states',
    dial_code: '+1',
    asset_url: countryFlagAssetUrl('us'),
  },
  UY: {
    code: 'UY',
    nameTranslationKey: 'app.lib.phone-codes.uruguay',
    dial_code: '+598',
    asset_url: countryFlagAssetUrl('uy'),
  },
  UZ: {
    code: 'UZ',
    nameTranslationKey: 'app.lib.phone-codes.uzbekistan',
    dial_code: '+998',
    asset_url: countryFlagAssetUrl('uz'),
  },
  VU: {
    code: 'VU',
    nameTranslationKey: 'app.lib.phone-codes.vanuatu',
    dial_code: '+678',
    asset_url: countryFlagAssetUrl('vu'),
  },
  VE: {
    code: 'VE',
    nameTranslationKey: 'app.lib.phone-codes.venezuela_bolivarian_republic_of_venezuela',
    dial_code: '+58',
    asset_url: countryFlagAssetUrl('ve'),
  },
  VN: {
    code: 'VN',
    nameTranslationKey: 'app.lib.phone-codes.vietnam',
    dial_code: '+84',
    asset_url: countryFlagAssetUrl('vn'),
  },
  VG: {
    code: 'VG',
    nameTranslationKey: 'app.lib.phone-codes.virgin_islands_british',
    dial_code: '+1284',
    asset_url: countryFlagAssetUrl('vg'),
  },
  WF: {
    code: 'WF',
    nameTranslationKey: 'app.lib.phone-codes.wallis_and_futuna',
    dial_code: '+681',
    asset_url: countryFlagAssetUrl('wf'),
  },
  YE: {
    code: 'YE',
    nameTranslationKey: 'app.lib.phone-codes.yemen',
    dial_code: '+967',
    asset_url: countryFlagAssetUrl('ye'),
  },
  ZM: {
    code: 'ZM',
    nameTranslationKey: 'app.lib.phone-codes.zambia',
    dial_code: '+260',
    asset_url: countryFlagAssetUrl('zm'),
  },
  ZW: {
    code: 'ZW',
    nameTranslationKey: 'app.lib.phone-codes.zimbabwe',
    dial_code: '+263',
    asset_url: countryFlagAssetUrl('zw'),
  },
};

export default phoneCodes;

export type CountryCode = keyof typeof phoneCodes;

export const UNKNOWN_COUNTRY: Country = {
  code: 'unknown',
  nameTranslationKey: 'app.lib.phone-codes.unknown',
  dial_code: '',
  asset_url: '',
};

/**
 * Parses a phone number and returns the country for that number.
 * This uses phone lib, and therefore handles overlapping codes (+1 for both USA and Canada)
 * Certain country codes (e.g. +590 for Guadeloupe) aren't yet supported by the array.
 * These codes will return `UNKNOWN_COUNTRY`.
 *
 * @param phoneNumber
 */
export function countryFromPhoneNumber(phoneNumber: string): Country {
  let countryCode = parsePhoneNumber(phoneNumber)?.country as CountryCode;
  return phoneCodes[countryCode] || UNKNOWN_COUNTRY;
}

/**
 * Takes an incomplete phone number string and returns the country that phone number belongs to, or null.
 * If you are trying to match against a complete phone number, use countryFromPhoneNumber.
 * Matches based on the longest matching dial code prefix.
 *
 * @param incompleteNumber
 */
export function countryFromIncompleteNumber(incompleteNumber: string): Country | null {
  return Object.values(phoneCodes).reduce(
    (bestMatch, country) => {
      if (incompleteNumber.startsWith(country.dial_code)) {
        if (!bestMatch || country.dial_code.length >= bestMatch.dial_code.length) {
          return country;
        }
      }
      return bestMatch;
    },
    null as Country | null,
  );
}
