/* RESPONSIBLE TEAM: team-purchase-experience */
import { helper } from '@ember/component/helper';
import {
  VBP2_SEAT_LABELS,
  SUPPORT_SEAT_TYPE,
  PROACTIVE_SUPPORT_SEAT_TYPE,
  ENGAGE_SEAT_TYPE,
  CONVERT_SEAT_TYPE,
} from 'embercom/lib/settings/seats/constants';

const A = 'a';
const AN = 'an';

export default helper(function seatLabelArticle([seatLabel, _]) {
  switch (seatLabel) {
    case VBP2_SEAT_LABELS[SUPPORT_SEAT_TYPE]:
    case VBP2_SEAT_LABELS[PROACTIVE_SUPPORT_SEAT_TYPE]:
    case VBP2_SEAT_LABELS[CONVERT_SEAT_TYPE]:
      return A;
    case VBP2_SEAT_LABELS[ENGAGE_SEAT_TYPE]:
      return AN;
    default:
      throw Error('Provided seat label is unknown');
  }
});
