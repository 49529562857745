/* RESPONSIBLE TEAM: team-ai-agent */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */

import type {
  Block,
  BlockList,
  Html,
  Image,
  List,
  MessengerCardBlock,
} from '@intercom/interblocks.ts';
import { type SUPPORTED_LANGUAGES } from 'embercom/lib/ai-assist';

// Sets up prompts for the `open-ai-{key}` Cmd-K actions in the OpenAI demo. Currently only paragraph blocks.

export type PromptPrefix =
  | `translate-${(typeof SUPPORTED_LANGUAGES)[number]}`
  | 'friendly'
  | 'shorten'
  | 'formal'
  | 'expand'
  | 'rephrase'
  | 'auto-correct';

export class PromptData {
  promptKey: PromptPrefix;
  inputVariables: { [key: string]: string };
  constructor(promptKey: PromptPrefix, inputVariables: { [key: string]: string }) {
    this.promptKey = promptKey;
    this.inputVariables = inputVariables;
  }
}

let blocksToText = (blocks: BlockList): string => {
  return blocks
    .filter((block) => block.type !== 'image')
    .map(blockToText)
    .filter((block) => block !== null)
    .join('<br>');
};

let blocksToTextWithImages = (blocks: BlockList): string => {
  return blocks
    .map(blockToText)
    .filter((block) => block !== null)
    .join('<br>');
};

function blockToText(block: Block): string | null {
  switch (block.type) {
    case 'messengerCard':
      block = block as MessengerCardBlock;
      return `[${block.text}]`;
    case 'html':
      block = block as Html;
      if (block.content === '') {
        return null;
      }
      return block.content;

    case 'unorderedList':
    case 'orderedList':
      block = block as List;
      if (block.items.length === 0) {
        return null;
      }
      return block.items.map((item, index) => `${index + 1}. ${item}`).join(' ');

    case 'image':
      block = block as Image;
      return imageBlockToText(block);
    case 'paragraph':
      // unlike other text-like blocks, we want to preserve paragraphs with text: ''
      // because they correspond to the user deliberately leaving an empty line.
      return block.text;
    default:
      if ('text' in block && textIsString(block.text)) {
        if (block.text === '') {
          return null;
        }
        return block.text;
      } else {
        return null;
      }
  }
}

function textToBlocks(text: string, imageBlocks: Record<string, Image>): BlockList {
  // the string can contain a mix of <br> and \n so this splits by both of those.
  let lines = text.split('<br>').flatMap((line) => line.split('\n'));

  return lines.flatMap<Block>((line) => {
    let isImageBlock = /(\[Image: .+\])/.test(line);
    if (isImageBlock) {
      // get the block from the map using its stringified form
      let block = imageBlocks[line.trim()];
      // if it doesn't exist, omit the image
      if (block === undefined) {
        return [];
      }
      return [block];
    }
    // otherwise it's paragraph – that's the only other kind we support
    return [{ type: 'paragraph', text: line, class: 'no-margin' }];
  });
}

function calculateBlockListLength(blocks: BlockList): number {
  let total = 0;
  for (let i = 0; i < blocks.length; i += 1) {
    let block = blocks[i];
    if (block.type === 'paragraph') {
      total += block.text.length;
    }

    // (we don't increment the total if it's an image block –
    // it doesn't seem to count)

    let isLastBlock = i === blocks.length - 1;
    if (isLastBlock === false) {
      total += 2; // newlines seem to count as 2 chars
    }
  }
  return total;
}

function textIsString(text: unknown): text is string {
  return typeof text === 'string';
}

function imageBlockToText(block: Image) {
  return `[Image: ${block.url}]`;
}

function imageBlockMapping(blocks: BlockList): Record<string, Image> {
  let imageBlocks: Record<string, Image> = {};
  blocks
    .filter((block) => block.type === 'image')
    .forEach((block) => {
      block = block as Image;
      imageBlocks[imageBlockToText(block)] = block;
    });
  return imageBlocks;
}

let getOpenAIPrompt = (
  promptKey: PromptPrefix,
  composerBlocks: BlockList,
  keepImages?: boolean,
) => {
  let blocksText;
  if (keepImages) {
    blocksText = blocksToTextWithImages(composerBlocks);
  } else {
    blocksText = blocksToText(composerBlocks);
  }
  return new PromptData(promptKey, { text: blocksText });
};

export {
  blocksToText,
  blocksToTextWithImages,
  imageBlockMapping,
  getOpenAIPrompt,
  textToBlocks,
  calculateBlockListLength,
};
