/* RESPONSIBLE TEAM: team-messenger */
import storage from 'embercom/vendor/intercom/storage';
import { BASE_LANG } from 'embercom/services/intl';

const RTL_LOCALES = ['ar', 'he'];

export function getLocale() {
  try {
    if (storage.get('locale').key) {
      return storage.get('locale').key;
    } else {
      return BASE_LANG;
    }
  } catch (e) {
    return BASE_LANG;
  }
}

export function isDefaultLocale() {
  return getLocale() === BASE_LANG;
}

export function isRtlLocale() {
  return RTL_LOCALES.includes(getLocale());
}
