/* RESPONSIBLE TEAM: team-frontend-tech */
import { helper as buildHelper } from '@ember/component/helper';
import moment from 'moment-timezone';

/**
 * Template helper to display human readable relative times.
 *
 * ```
 *  {{time-from-now-helper post.created_at}}
 * ```
 */

/**
 * @method time-from-now-helper
 * @param {Integer, String} Timestamp (UNIX or otherwise)
 */
export default buildHelper(function (params) {
  let value = params[0];

  if (typeof value === 'number') {
    return moment.unix(value).fromNow();
  } else {
    return moment(value).fromNow();
  }
});
