/* RESPONSIBLE TEAM: team-reporting */
/**
 * Append _ooo to field name if withinOfficeHours is true
 */
export const officeHoursField = (field, withinOfficeHours, insightsPowerfulReportingEnabled) => {
  let oldOfficeHoursFieldName = '_ooo';
  let powerfulOfficeHoursFieldName = '_in_office_hours';

  let withinOfficeHoursField = withinOfficeHours ? `${field}${oldOfficeHoursFieldName}` : field;

  if (withinOfficeHours && insightsPowerfulReportingEnabled) {
    withinOfficeHoursField = withinOfficeHoursField.replace(
      oldOfficeHoursFieldName,
      powerfulOfficeHoursFieldName,
    );
  }

  return withinOfficeHoursField;
};

export const botInboxAndOfficeHoursField = (
  field,
  withinOfficeHours,
  excludeBotInbox,
  insightsPowerfulReportingEnabled,
) => {
  let updatedField = excludeBotInbox ? `${field}_excluding_bot_inbox` : field;
  return officeHoursField(updatedField, withinOfficeHours, insightsPowerfulReportingEnabled);
};

export const timeTimeToCloseField = (excludeBotInbox) =>
  excludeBotInbox ? 'time_to_close' : 'time_to_close_from_initial_user_part';
