/* RESPONSIBLE TEAM: team-frontend-tech */
import Velocity from 'velocity';

export default {
  name: 'velocity',
  initialize(application) {
    // Velocity is lazily initialized by liquid-fire. This can be surprising and confusing
    // so we explicitly initialize it here
    Velocity(
      document.getElementById('dummy'),
      { opacity: 0.5 },
      { duration: 1000, promiseRejectEmpty: false },
    );
  },
};
