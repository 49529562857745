/* RESPONSIBLE TEAM: team-tickets-1 */
// @ts-nocheck
import { BaseConfig, EMOJI_TYPEAHEAD } from '@intercom/embercom-prosemirror-composer';
import EmbercomFileUploader from '../articles/embercom-file-uploader';
import { SAVED_REPLY_TYPEAHEAD } from './composer-config';

type Pane = 'note' | 'reply';

export default class InboxComposerConfigWhatsapp extends BaseConfig {
  upload;
  tools = [];
  allowedBlocks = ['paragraph', 'image'];
  inputRules = [];
  allowedInline = [];
  typeaheads = [EMOJI_TYPEAHEAD];
  placeholder = '';

  constructor(app: any, placeholder: string, conversation: any, activePane: Pane, macro: any) {
    super();
    this.configureUploads(app);
    this.placeholder = placeholder;
    this.typeaheads.push(
      Object.assign({}, SAVED_REPLY_TYPEAHEAD, {
        attrs: { conversation, macro, activePane, bulkInsert: false },
      }),
    );
  }

  private configureUploads(app: any) {
    this.upload = {
      allowedImageFileTypes: ['image/png', 'image/jpeg', 'image/jpg'],
      allowedAttachmentTypes: ['*'],
      uploader: EmbercomFileUploader,
      attrs: { policyUrl: `/apps/${app.id}/uploads` },
    };
  }
}
