/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { camelize } from '@ember/string';
import {
  VALUE_BASED_PRICING_FEB2022_STARTER_SOLUTION_ID,
  getSolutionIdInActivePricingModel,
} from 'embercom/lib/billing';

export function setPricingParamsIfEmpty(controller, transition) {
  let referrer = document.referrer;
  setSolutionIfEmpty(controller, transition, referrer);
  setQueryParamIfEmpty(controller, transition, referrer, 'email');
  setQueryParamIfEmpty(controller, transition, referrer, 'first_name');
  setQueryParamIfEmpty(controller, transition, referrer, 'last_name');
  setQueryParamIfEmpty(controller, transition, referrer, 'email_submission_id');
}

export function setSolutionIfEmpty(controller, transition) {
  let solutionId =
    transition.to.queryParams.solution_id || VALUE_BASED_PRICING_FEB2022_STARTER_SOLUTION_ID;
  controller.set('solutionId', getSolutionIdInActivePricingModel(solutionId));
}

export function setQueryParamIfEmpty(controller, transition, referrer, key) {
  let param = transition.to.queryParams[key] || cleanParam(referrer.split(`${key}=`)[1]);
  if (param) {
    controller.set(camelize(key), decodeURIComponent(param));
  }
}

function cleanParam(param) {
  if (param) {
    return param.split('&')[0];
  }
  return param;
}
