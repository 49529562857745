/* RESPONSIBLE TEAM: team-frontend-tech */
import EmberError from '@ember/error';
import { helper as buildHelper } from '@ember/component/helper';
const indexIncrementRawHelper = function (value, incrementBy) {
  let increment = typeof incrementBy === 'number' ? incrementBy : 1;
  return value + increment;
};

export { indexIncrementRawHelper };
export default buildHelper(function (options) {
  if (arguments.length > 1) {
    throw new EmberError('[indexIncrementHelper] You must use named params for helpers');
  }
  return indexIncrementRawHelper(options.hash.value, options.hash.incrementBy);
});
