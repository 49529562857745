/* RESPONSIBLE TEAM: team-reporting */
import { copy } from 'ember-copy';
import percent from 'embercom/lib/percentage-formatter';
import { isEmpty, isPresent } from '@ember/utils';
import { SECONDS_IN } from 'embercom/lib/duration-formatter';
import {
  TEAM_TEAMMATE_GROUPING_MULTIMETRIC,
  TERMS_GROUPING_MULTIMETRIC,
} from 'embercom/models/reporting/custom/visualization-options';

export function calculatePercentageDataResponse({ numeratorData, denominatorData }) {
  let numeratorValues = numeratorData.groups[0].aggregations[0].values;
  let denominatorValues = denominatorData.groups[0].aggregations[0].values;
  let numeratorKeys = numeratorData.groups[0].values;
  let denominatorKeys = denominatorData.groups[0].values;
  // numerator and denominator results are not coming in the same order (the array indices don't match) we need to find the numerator key
  // in the denominator result and then use that index to create the response.
  let percentages = numeratorKeys
    .map((numeratorKey, index) => {
      let denominatorIndex = denominatorKeys.indexOf(numeratorKey);
      if (denominatorIndex >= 0) {
        let denominatorValue = denominatorValues.get(denominatorIndex);
        return percent(denominatorValue, numeratorValues[index]) || 0;
      }
      return null;
    })
    .compact();

  let percentagesData = copy(numeratorData, true);
  percentagesData.groups[0].names = denominatorData.groups[0].names;
  percentagesData.groups[0].aggregations[0].values = percentages;

  return percentagesData;
}

export function calculateSegmentedPercentageData({ numeratorData, denominatorData }) {
  let result = {};

  Object.keys(denominatorData).forEach((key) => {
    let numeratorValues = numeratorData[key]?.data;
    let denominatorValues = denominatorData[key]?.data;

    if (!numeratorValues || !denominatorValues) {
      return;
    }

    // numerator and denominator results are not coming in the same order (the array indices don't match) we need to find the numerator key
    // in the denominator result and then use that index to create the response.
    let percentages = denominatorValues
      .map((denominator) => {
        let result = null;
        let numerator = numeratorValues.find((v) => v[0] === denominator[0]);
        if (!isEmpty(numerator)) {
          result = [denominator[0], percent(denominator[1], numerator[1]), denominator[2]];
        }
        return result;
      })
      .compact();

    let denominator = copy(denominatorData[key], true);
    result[key] = denominator;
    result[key].data = percentages;
  });

  return result;
}

export function calculateSegmentedRatioData({ numeratorData, denominatorData, unit }) {
  let result = {};

  Object.keys(denominatorData).forEach((key) => {
    let numeratorValues = numeratorData[key]?.data;
    let denominatorValues = denominatorData[key]?.data;

    if (!numeratorValues || !denominatorValues) {
      return;
    }

    let percentages = denominatorValues
      .map((denominator, index) => {
        let value = 0;

        // numerator and denominator results are not coming in the same order (the array indices don't match) we need to find the numerator key
        // in the denominator result and then use that index to create the response.
        let numerator = numeratorValues.find((v) => v[0] === denominator[0]);
        if (!isEmpty(numerator)) {
          if (denominator[1] === 0 || isEmpty(denominator[1]) || isEmpty(numerator[1])) {
            value = 0;
          } else {
            value = numerator[1] / denominator[1];
          }

          if (unit === 'valuePerHour') {
            value *= SECONDS_IN.hour;
          }
        }

        return [denominator[0], value, denominator[2]];
      })
      .compact();

    let denominator = copy(denominatorData[key], true);
    result[key] = denominator;
    result[key].data = percentages;
  });

  return result;
}

export function shouldAllowZeroValues(metric, aggregation) {
  return (
    ['ratio', 'percentage'].includes(metric?.type) ||
    !['count', 'cardinality', 'sum'].includes(aggregation)
  );
}

export function shouldConvertNullsToZeros(aggregation, metric = null) {
  // we don't want to convert nulls to zeros for percentage and ratio metrics, as nulls are meaningful in this context (i.e. nulls are not equivalent to 0)
  // e.g. the SLA miss rate in a week where there are no conversations with an SLA is null
  //      conversely, the SLA miss rate in a week where there was 1 conversation with an SLA and 0 misses is 0%
  // we do want to convert nulls to zeros for count, cardinality & sum aggregations as applying these aggregations to null data should return 0
  // e.g. the count/sum/cardinality of conversations in a week where there are no conversations is 0
  return (
    !['ratio', 'percentage'].includes(metric?.type) &&
    ['count', 'cardinality', 'sum'].includes(aggregation)
  );
}

export function getFirstSeriesBucketKeys(renderableChart, rawResponses) {
  if (
    isPresent(renderableChart) &&
    renderableChart.isMultimetric &&
    !renderableChart.isBrokenDownByTime &&
    (renderableChart.supportsFeature(TEAM_TEAMMATE_GROUPING_MULTIMETRIC) ||
      renderableChart.supportsFeature(TERMS_GROUPING_MULTIMETRIC)) &&
    !isEmpty(rawResponses)
  ) {
    return rawResponses[0].groups[0].values;
  }
  return [];
}
