/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import { Metric } from 'embercom/models/data/pricing/metric-types';
import { VISUALIZATION_TYPES } from 'embercom/models/reporting/custom/visualization-options';

export function mapUsageReminderType(selectedMetric: Metric): Metric {
  switch (selectedMetric) {
    case Metric.sms_segments_sent_received_in_shifted_billing_cycle_au:
      return Metric.mapped_sms_segments_sent_received_in_shifted_billing_cycle_au;
    case Metric.sms_segments_sent_received_in_shifted_billing_cycle_ca:
      return Metric.mapped_sms_segments_sent_received_in_shifted_billing_cycle_ca;
    case Metric.sms_segments_sent_received_in_shifted_billing_cycle_uk:
      return Metric.mapped_sms_segments_sent_received_in_shifted_billing_cycle_uk;
    case Metric.sms_segments_sent_received_in_shifted_billing_cycle_us:
      return Metric.mapped_sms_segments_sent_received_in_shifted_billing_cycle_us;
    case Metric.resolutions_with_custom_answers:
      return Metric.mapped_resolutions_with_custom_answers;
    default:
      return selectedMetric;
  }
}

export const SMS_ALL_REGIONS = 'sms_all_regions';
export const METRIC_CHART_TYPE_MAP = {
  [Metric.core_seat_count]: VISUALIZATION_TYPES.BAR,
  [Metric.copilot_seat_count]: VISUALIZATION_TYPES.BAR,
  [Metric.emails_sent]: VISUALIZATION_TYPES.LINE,
  [Metric.messages_sent]: VISUALIZATION_TYPES.LINE,
  [SMS_ALL_REGIONS]: VISUALIZATION_TYPES.LINE,
  [Metric.sms_segments_sent_received_in_shifted_billing_cycle_au]: VISUALIZATION_TYPES.LINE,
  [Metric.sms_segments_sent_received_in_shifted_billing_cycle_us]: VISUALIZATION_TYPES.LINE,
  [Metric.sms_segments_sent_received_in_shifted_billing_cycle_ca]: VISUALIZATION_TYPES.LINE,
  [Metric.sms_segments_sent_received_in_shifted_billing_cycle_uk]: VISUALIZATION_TYPES.LINE,
  [Metric.whatsapp_inbound]: VISUALIZATION_TYPES.LINE,
  [Metric.whatsapp_outbound]: VISUALIZATION_TYPES.LINE,
  [Metric.resolutions_with_custom_answers]: VISUALIZATION_TYPES.LINE,
  [Metric.calling]: VISUALIZATION_TYPES.LINE,
};

export const SMS_METRICS = [
  Metric.sms_segments_sent_received_in_shifted_billing_cycle_ca,
  Metric.sms_segments_sent_received_in_shifted_billing_cycle_us,
  Metric.sms_segments_sent_received_in_shifted_billing_cycle_au,
  Metric.sms_segments_sent_received_in_shifted_billing_cycle_uk,
];

export const METRICS_SUPPORTING_USAGE_ALERTS = [
  Metric.emails_sent,
  Metric.messages_sent,
  Metric.whatsapp_inbound,
  Metric.whatsapp_outbound,
  Metric.resolutions_with_custom_answers,
  Metric.calling,
  ...SMS_METRICS,
];

export const METRICS_SUPPORTING_USAGE_LIMITS: [{ metric: Metric; tableName: string }] = [
  { metric: Metric.resolutions_with_custom_answers, tableName: 'fin-usage-limit' },
];
