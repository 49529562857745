/* RESPONSIBLE TEAM: team-frontend-tech */
import { helper } from '@ember/component/helper';

export function characterIndex([index]) {
  //taken from https://codereview.stackexchange.com/a/16129
  index += 1;

  let baseChar = 'A'.charCodeAt(0);
  let characters = '';

  do {
    index -= 1;
    characters = String.fromCharCode(baseChar + (index % 26)) + characters;
    index = Math.floor(index / 26);
  } while (index > 0);

  return characters;
}

export default helper(characterIndex);
