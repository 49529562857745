/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-jquery */
import $ from 'jquery';
import ajax from 'embercom/lib/ajax';

let nonconcurrentAjax = function (target, options) {
  if (target.xhr && target.xhr.readyState !== 4) {
    target.xhr.abort();
  }
  return ajax(
    $.extend(
      {
        beforeSend(xhr) {
          target.xhr = xhr;
        },
      },
      options,
    ),
  );
};

export default nonconcurrentAjax;
