/* RESPONSIBLE TEAM: team-help-desk-experience */
import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';

export function appendResizeParamToImageUrl(url: string, resolution: string): string | undefined {
  if (!url) {
    return;
  }

  let urlObject = new URL(url);
  urlObject.searchParams.set('resize', resolution);
  return urlObject.href;
}

export default class AppendResizeParamToImageUrl extends Helper {
  @service declare session: Session;

  compute([url, resolution]: [string, string]) {
    return appendResizeParamToImageUrl(url, resolution);
  }
}
