/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import {
  botInboxAndOfficeHoursField,
  timeTimeToCloseField,
} from 'embercom/lib/reporting/office-hours-field';
import { CONVERSATION_RATING_IDS } from 'predicates/models/data/conversation-ratings';
import { LAST_ASSIGNMENT_TO_CLOSE_TIME } from 'embercom/lib/reporting/flexible/constants';

function conversationRangeField() {
  return 'first_user_conversation_part_created_at';
}

export const LEADS_CREATED_FILTERS = {
  channel_type: ['Desktop'],
};

export const CLOSED_CONVERSATIONS = {
  name: 'default_query',
  debug_name: 'closed_conversations',
  document_type: 'conversation_closed',
  range_field: 'comment_created_at',
  aggregation_type: 'value_count',
  aggregation_field: 'conversation_id',
};

export const NEW_CONVERSATIONS = {
  name: 'default_query',
  debug_name: 'new_conversations',
  document_type: 'conversation_with_message_thread',
  range_field: 'first_user_conversation_part_created_at',
  aggregation_type: 'value_count',
  aggregation_field: 'conversation_id',
  filters: {
    conversation_started_by_user: [true],
  },
};

export const NEW_CONVERSATIONS_BREAKDOWN = {
  name: 'default_query',
  debug_name: 'new_conversations_breakdown',
  document_type: 'conversation',
  range_field: 'first_user_conversation_part_created_at',
  aggregation_type: 'value_count',
  aggregation_field: 'conversation_id',
  aggregations: [{ grouping: 'conversation_started_by_user', interval: 1 }],
};

export const ALL_CONVERSATIONS = {
  name: 'default_query',
  debug_name: 'all_conversations',
  document_type: 'conversation',
  range_field: 'first_user_conversation_part_created_at',
  aggregation_type: 'value_count',
  aggregation_field: 'conversation_id',
};

export const ALL_CONVERSATIONS_RB = {
  name: 'default_query',
  debug_name: 'all_conversations_rb',
  document_type: 'conversation_with_message_thread',
  range_field: 'first_user_conversation_part_created_at',
  aggregation_type: 'value_count',
  aggregation_field: 'conversation_id',
};

export const MEDIAN_TIME_TO_CLOSE = ({ withinOfficeHours, excludeBotInbox = false }) => ({
  name: 'default_query',
  debug_name: 'median_time_to_close',
  document_type: 'conversation_closed',
  range_field: 'first_user_conversation_part_created_at',
  aggregation_type: 'percentiles',
  aggregation_field: botInboxAndOfficeHoursField(
    timeTimeToCloseField(excludeBotInbox),
    withinOfficeHours,
    excludeBotInbox,
  ),
  additional_aggregation_parameters: { percents: [50] },
});

export const CONVERSATION_RATINGS = {
  name: 'default_query',
  debug_name: 'conversation_ratings',
  document_type: 'conversation_rating',
  range_field: 'rating_updated_at',
  aggregation_type: 'value_count',
  aggregation_field: 'conversation_id',
  keys_are_dates: false,
  nested_keys_are_dates: false,
  filters: {
    rating_index: [
      CONVERSATION_RATING_IDS.AMAZING,
      CONVERSATION_RATING_IDS.GREAT,
      CONVERSATION_RATING_IDS.OK,
      CONVERSATION_RATING_IDS.BAD,
      CONVERSATION_RATING_IDS.TERRIBLE,
    ],
  },
};

export const LEADS_CREATED = {
  name: 'default_query',
  debug_name: 'leads_created',
  document_type: 'lead_created',
  range_field: 'visitor_to_lead_at',
  aggregation_type: 'value_count',
  aggregation_field: 'user_id',
};

export const MEDIAN_FIRST_RESPONSE_TIME = {
  name: 'default_query',
  debug_name: 'median_first_response_time',
  document_type: 'initial_admin_reply',
  range_field: 'conversation_created_at',
  aggregation_type: 'percentiles',
  aggregation_field: 'response_time',
  additional_aggregation_parameters: { percents: [50] },
};

export const MEDIAN_FIRST_RESPONSE_TIME_NEW = ({ withinOfficeHours, excludeBotInbox = false }) => ({
  name: 'default_query',
  debug_name: 'median_first_response_time_new',
  document_type: 'initial_admin_reply',
  range_field: conversationRangeField(),
  aggregation_type: 'percentiles',
  aggregation_field: botInboxAndOfficeHoursField(
    'response_time',
    withinOfficeHours,
    excludeBotInbox,
  ),
  additional_aggregation_parameters: { percents: [50] },
});

export const MEETING_BOOKED = {
  name: 'default_query',
  debug_name: 'meeting_booked',
  document_type: 'meeting_booked',
  range_field: 'meeting_booked_at',
  aggregation_type: 'value_count',
  aggregation_field: 'teammate_participant_id',
};

export const NEW_LEADS = {
  name: 'default_query',
  debug_name: 'new_leads',
  document_type: 'lead_created',
  range_field: 'visitor_to_lead_at',
  aggregation_type: 'value_count',
  aggregation_field: 'user_id',
};

export const RESOLUTION_BOT_CONTENT_SENT = {
  name: 'default_query',
  debug_name: 'resolution_bot_content_sent',
  document_type: 'resolution_bot_content_sent',
  range_field: 'first_user_conversation_part_created_at',
  aggregation_type: 'cardinality',
  aggregation_field: 'conversation_id',
};

export const RESOLUTION_BOT_CONVERSATION_COVERAGE = {
  name: 'default_query',
  debug_name: 'resolution_bot_conversation_coverage',
  document_type: 'conversation_with_message_thread',
  range_field: 'first_user_conversation_part_created_at',
  aggregation_type: 'value_count',
  aggregation_field: 'conversation_id',
  keys_are_dates: false,
  aggregations: [{ grouping: 'resolution_bot_conversation_coverage', interval: 1 }],
};

export const RESOLUTION_BOT_CONTENT_BREAKDOWN = {
  name: 'default_query',
  debug_name: 'resolution_bot_content_breakdown',
  document_type: 'resolution_bot_content_sent',
  range_field: 'first_user_conversation_part_created_at',
  aggregation_type: 'value_count',
  aggregation_field: 'conversation_id',
  keys_are_dates: false,
  aggregations: [{ grouping: 'content_type', interval: 1 }],
};

export const SALESFORCE_LEAD_CREATED = {
  name: 'default_query',
  debug_name: 'salesforce_lead_created',
  document_type: 'salesforce_lead_created',
  range_field: 'lead_created_at',
  aggregation_type: 'value_count',
  aggregation_field: 'user_id',
};

export const OPERATOR_CONVERSATIONS_ASSIGNED = {
  name: 'default_query',
  debug_name: 'operator_conversations_assigned',
  document_type: 'operator_conversation_assigned',
  range_field: 'conversation_assigned_at',
  aggregation_type: 'cardinality',
  aggregation_field: 'conversation_id',
};

export const OPERATOR_CONVERSATIONS_COMPLETED = {
  name: 'default_query',
  debug_name: 'operator_conversations_completed',
  document_type: 'operator_conversation_completed',
  range_field: 'conversation_created_at',
  aggregation_type: 'cardinality',
  aggregation_field: 'conversation_id',
};

export const OPERATOR_CONVERSATION_ENGAGED = {
  name: 'default_query',
  debug_name: 'operator_conversation_engaged',
  document_type: 'operator_conversation_engaged',
  range_field: 'conversation_created_at',
  aggregation_type: 'cardinality',
  aggregation_field: 'conversation_id',
};

export const OPERATOR_CONVERSATION_SENT = {
  name: 'default_query',
  debug_name: 'operator_conversation_sent',
  document_type: 'operator_conversation_sent',
  range_field: 'conversation_created_at',
  aggregation_type: 'cardinality',
  aggregation_field: 'conversation_id',
};

export const OPERATOR_CUSTOM_BOTS_COMPLETED = {
  name: 'default_query',
  debug_name: 'operator_custom_bots_completed',
  document_type: 'events',
  range_field: 'stat_receipt.created_at',
  aggregation_type: 'cardinality',
  aggregation_field: 'stat_receipt.id',
};

export const OPERATOR_CUSTOM_BOTS_ENGAGED = {
  name: 'default_query',
  debug_name: 'operator_custom_bots_engaged',
  document_type: 'events',
  range_field: 'stat_receipt.created_at',
  aggregation_type: 'cardinality',
  aggregation_field: 'stat_receipt.id',
};

export const OPERATOR_CUSTOM_BOTS_SENT = {
  name: 'default_query',
  debug_name: 'operator_custom_bots_sent',
  document_type: 'events',
  range_field: 'stat_receipt.created_at',
  aggregation_type: 'cardinality',
  aggregation_field: 'stat_receipt.id',
};

export const OPERATOR_CUSTOM_BOTS_BUSIEST_PERIODS = {
  name: 'default_query',
  debug_name: 'operator_custom_bots_busiest_periods',
  document_type: 'events',
  range_field: 'stat_receipt.created_at',
  aggregation_type: 'cardinality',
  aggregation_field: 'conversation.id',
};

export const SLA_STATUS_BY_CONVERSATION = {
  name: 'default_query',
  debug_name: 'sla_status_by_conversation_id',
  document_type: 'conversation_sla_status_log',
  range_field: 'started_at',
  aggregation_type: 'cardinality',
  aggregation_field: 'conversation_id',
  aggregations: [],
  keys_are_dates: false,
  nested_keys_are_dates: false,
};

export const SLA_STATUS_BY_TRIGGERING_COMMENT = {
  name: 'default_query',
  debug_name: 'sla_status_by_triggering_comment',
  document_type: 'conversation_sla_status_log',
  range_field: 'started_at',
  aggregation_type: 'cardinality',
  aggregation_field: 'conversation_triggering_comment_id',
  aggregations: [],
  keys_are_dates: false,
  nested_keys_are_dates: false,
};

export const SLA_STATUS_BY_TRIGGERING_COMMENT_AT_BREACH = {
  name: 'default_query',
  debug_name: 'sla_status_by_triggering_comment_at_breach',
  document_type: 'conversation_sla_status_log',
  range_field: 'missed_at',
  aggregation_type: 'cardinality',
  aggregation_field: 'conversation_triggering_comment_id',
  aggregations: [],
  keys_are_dates: false,
  nested_keys_are_dates: false,
};

export const SLA_CONVERSATION_WAIT_TIME = {
  name: 'default_query',
  document_type: 'conversation_sla_status_log',
  debug_name: 'sla_status_conversation_wait_time',
  range_field: 'started_at',
  aggregation_type: 'avg',
  aggregation_field: 'breach_time',
  aggregations: [],
  keys_are_dates: false,
  nested_keys_are_dates: false,
  filters: {
    sla_statuses: ['fixed'],
  },
};

export const CONVERSATIONS_EXPLORER = {
  name: 'default_query',
  debug_name: 'conversations_explorer',
  document_type: 'events',
  range_field: 'conversation.created_at',
  aggregation_type: 'cardinality',
  aggregation_field: 'conversation.id',
};

export const SEGMENTS_EVENTS_COUNT = {
  name: 'default_query',
  debug_name: 'segment_events',
  document_type: 'events',
  range_field: 'event.created_at',
  aggregation_type: 'cardinality',
  aggregation_field: '_id',
  ALL_SEGMENT_EVENTS: {
    'event.type': ['entered_segment', 'exited_segment'],
  },
  ENTERED_EVENTS: {
    'event.type': ['entered_segment'],
  },
  EXITED_EVENTS: {
    'event.type': ['exited_segment'],
  },
};

export const SEGMENTS_MOST_ACTIVE = {
  name: 'default_query',
  debug_name: 'segment_most_active',
  document_type: 'events',
  range_field: 'event.created_at',
  aggregation_type: 'cardinality',
  aggregation_field: 'user.id',
  additional_aggregation_parameters: { size: 10000 },
  ALL_SEGMENT_EVENTS: {
    'event.type': ['entered_segment', 'exited_segment'],
  },
  ENTERED_EVENTS: {
    'event.type': ['entered_segment'],
  },
  EXITED_EVENTS: {
    'event.type': ['exited_segment'],
  },
};

export const SEGMENT_EVENTS_BY_USER = {
  name: 'default_query',
  debug_name: 'segment_events_by_unique_user_id',
  document_type: 'events',
  range_field: 'event.created_at',
  aggregation_type: 'cardinality',
  aggregation_field: 'user.id',
  additional_aggregation_parameters: { size: 10000 },
};

export const NEW_PEOPLE_BY_USER = {
  name: 'default_query',
  document_type: 'events',
  range_field: 'event.created_at',
  aggregation_type: 'value_count',
  aggregation_field: 'user.id',
};

export const NEW_CONVERSATIONS_EVENTS = {
  name: 'default_query',
  document_type: 'events',
  range_field: 'event.created_at',
  aggregation_type: 'value_count',
  aggregation_field: 'conversation.id',
};

export const APP_PACKAGE_INSTALL_STATUS_CHANGED_EVENTS = {
  name: 'default_query',
  debug_name: 'app_package_install_status_changed_events',
  document_type: 'events',
  range_field: 'event.created_at',
  aggregation_type: 'value_count',
  aggregation_field: 'app_package_install.id',
  filters: {
    'event.type': 'app_package_install_status_changed',
  },
};

export const FIRST_ASSIGNMENT_TO_CLOSE_TIME = ({ withinOfficeHours }) => ({
  name: 'default_query',
  debug_name: 'first_assignment_to_close_time_buckets',
  document_type: 'conversation_with_message_thread',
  range_field: 'first_user_conversation_part_created_at',
  aggregation_type: 'value_count',
  aggregation_field: botInboxAndOfficeHoursField(LAST_ASSIGNMENT_TO_CLOSE_TIME, withinOfficeHours),
});
