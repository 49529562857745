/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
const PRICING_MODELS = [
  {
    name: 'Free',
    description: 'Your app is entirely free to use without a paid account or subscription.',
    value: 'free',
    pricingPageRequired: false,
  },
  {
    name: 'Trial for free',
    description:
      'Your app is free for a limited trial period. After the trial ends, customers need to create a paid account to continue using your app. Select this if you offer a free trial, but expect customers to upgrade or pay for your app.',
    value: 'free_trial_available',
    pricingPageRequired: true,
  },
  {
    name: 'Some features free',
    description:
      'Your app is free for some features. To access all features, customers need to create a paid account. Select this if you operate a free or "freemium" plan, but expect customers to upgrade or pay for your app.',
    value: 'free_option_available',
    pricingPageRequired: true,
  },
  {
    name: 'Paid app',
    description:
      "Your app requires a paid account. Select this if customers can't use your app without paying for it.",
    value: 'paid',
    pricingPageRequired: true,
  },
];

const getPricingModels = () => {
  return PRICING_MODELS;
};

const getPricingModelValues = () => {
  return PRICING_MODELS.map((pricingModel) => pricingModel.value);
};

const getPricingModelData = (key) => {
  return PRICING_MODELS.find((pricingModel) => pricingModel.value === key);
};

export { getPricingModels, getPricingModelValues, getPricingModelData };
