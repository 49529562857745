/* RESPONSIBLE TEAM: team-reporting */
import { difference } from 'underscore';

export const DETRACTORS_VALUES = ['0', '1', '2', '3', '4', '5', '6'];
export const PASSIVES_VALUES = ['7', '8'];
export const PROMOTERS_VALUES = ['9', '10'];

export const FILTERS = {
  completedFilter: {
    type: 'category',
    data: {
      property: 'state.survey_progress.status',
      values: [1],
    },
  },
  respondedFilter: {
    type: 'category',
    data: {
      property: 'stats.type',
      values: ['answer'],
    },
  },
  passivesFilter: {
    type: 'category',
    data: {
      property: 'stat_answer.response.raw_response',
      values: PASSIVES_VALUES,
    },
  },
  detractorsFilter: {
    type: 'category',
    data: {
      property: 'stat_answer.response.raw_response',
      values: DETRACTORS_VALUES,
    },
  },
  promotersFilter: {
    type: 'category',
    data: {
      property: 'stat_answer.response.raw_response',
      values: PROMOTERS_VALUES,
    },
  },
};

export const defaultChartDefinition = () => {
  return {
    id: null,
    title: null,
    visualization_type: 'counter',
    object: 'survey_receipt',
    metric_property: 'receipt.id',
    metric_function: 'count',
    date_range: {
      property: 'responded_at',
      selection: 'past_12_weeks',
      start: null,
      end: null,
    },
    chart_series: [
      {
        metric_id: 'surveys.responded',
      },
    ],
    stacked: false,
    view_by: null,
    view_by_time_interval: null,
    view_by_display_limit: 10,
    view_by_display_other: false,
  };
};

const defaultFilters = (surveyId) => {
  return [
    {
      type: 'category',
      data: {
        property: 'receipt.content_id',
        values: [surveyId],
      },
    },
  ];
};

const nestedQuestionIdFilter = (questionId) => {
  return {
    type: 'exists',
    data: {
      property: `stats.question#${questionId}`,
    },
  };
};

function buildFilters(surveyId, filters) {
  return { type: 'and', filters: [...defaultFilters(surveyId), ...filters] };
}

export const sentChartFilters = (surveyId) => {
  return { type: 'and', filters: defaultFilters(surveyId) };
};

export const respondedChartFilters = (surveyId) => {
  return buildFilters(surveyId, [FILTERS.respondedFilter]);
};

export const tableChartFilters = (surveyId, questionId) => {
  return buildFilters(surveyId, [nestedQuestionIdFilter(questionId)]);
};

export const roundTick = (tick, roundBy = 5) => {
  let val = tick - (tick % roundBy);

  if (tick % roundBy === 0) {
    return val;
  }

  return val + roundBy;
};

export const findTickInverval = (answerCount, maxTickCount = 25) => {
  let roundBy = 1;
  let answerCountBreakPoint = 50;

  if (answerCount >= answerCountBreakPoint) {
    roundBy = 5;
  }

  let roundedCount =
    answerCount % maxTickCount === 0
      ? answerCount
      : answerCount - (answerCount % maxTickCount) + maxTickCount;
  let tickInterval = roundedCount / maxTickCount;

  if (answerCount > answerCountBreakPoint) {
    tickInterval = roundTick(tickInterval, roundBy);
  }

  return tickInterval;
};

export function populateEmptyRatingsWithZero(availableRatings, dataResponses) {
  let missingRatings = difference(availableRatings, dataResponses.groups[0].values);

  dataResponses.groups[0].values.push(...missingRatings);
  dataResponses.groups[0].names.push(...missingRatings);
  dataResponses.groups[0].aggregations[0].values.push(...missingRatings.map(() => 0));

  return dataResponses;
}

export const NPS_DETRACTORS_RATING_THRESHOLD = 6;
export const NPS_PASSIVES_RATING_THRESHOLD = 8;
export const NPS_PROMOTERS_RATING_THRESHOLD = 10;

export const ANSWER_TO_QUESTION_IDENTIFIER = 'stats.question#';
