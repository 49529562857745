/* RESPONSIBLE TEAM: team-tickets-1 */
import { get } from '@ember/object';
import { isPresent } from '@ember/utils';
export default function (response) {
  if (typeof response !== 'object') {
    return null;
  } else if (isPresent(response.jqXHR) && isPresent(response.jqXHR.responseJSON?.errors)) {
    if (Array.isArray(response.jqXHR.responseJSON?.errors)) {
      return get(response, 'jqXHR.responseJSON.errors.firstObject');
    } else {
      return get(response, 'jqXHR.responseJSON.errors');
    }
  } else if (
    isPresent(response.jqXHR) &&
    isPresent(response.jqXHR.responseJSON?.error) &&
    typeof response.jqXHR.responseJSON.error === 'string'
  ) {
    return { status: response.jqXHR.status, message: response.jqXHR.responseJSON.error };
  } else if (isPresent(response.responseJSON)) {
    return get(response, 'responseJSON.errors.firstObject');
  } else if (isPresent(get(response, 'jqXHR.status'))) {
    return { status: response.jqXHR.status };
  } else {
    return get(response, 'errors.firstObject');
  }
}
