/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import containerLookup, { getIntlService } from 'embercom/lib/container-lookup';
import { getSeatInfo, getNumberOfSeatsInUse } from 'embercom/lib/admins/seat-info';
import { htmlSafe } from '@ember/template';

async function getInboxSeatInfo(
  numberOfSeatsRequired,
  editFlow = false,
  withInviteReload = true,
  isCopilotSeatAdded = false,
  copilotData = {},
) {
  let numberOfSeatsInUseInfo = await getNumberOfSeatsInUse(
    (model) => model.hasInboxAccess,
    withInviteReload,
  );
  let numberOfSeatsInUse = numberOfSeatsInUseInfo.totalNumberOfSeatsInUse;
  let seatsInUseByInvites = numberOfSeatsInUseInfo.seatsInUseByInvites;
  if (editFlow) {
    // if we are editing a teammate or invite's permission, the seatInfo.numberOfSeatsInUse includes the to-be-added seat
    // leading to an off-by-1 error, see https://github.com/intercom/intercom/issues/218312
    numberOfSeatsInUse -= 1;
  }
  return getSeatInfo({
    numberOfSeatsRequired,
    numberOfSeatsInUse,
    seatsInUseByInvites,
    isCopilotSeatAdded,
    copilotData,
  });
}

async function getSubscriptionChangeMessage(
  numberOfSeatsRequired,
  editFlow = false,
  withInviteReload = true,
  isCopilotSeatAdded = false,
  copilotData = {},
) {
  let seatInfo = await getInboxSeatInfo(
    numberOfSeatsRequired,
    editFlow,
    withInviteReload,
    isCopilotSeatAdded,
    copilotData,
  );
  if (seatInfo.pricePerSeat === 0) {
    return null;
  }

  let message;
  let intlService = getIntlService();
  if (numberOfSeatsRequired === 0 && isCopilotSeatAdded) {
    message = intlService.t('settings.teammates.edit.add-copilot-seat-modal.body', {
      seatPrice: seatInfo.formattedChangeInSeatCost,
      htmlSafe,
    });
    return message;
  }

  if (seatInfo.annuallyContractedWithOveragesEnabled && seatInfo.numberOfChargeableSeatsAfter > 0) {
    message = intlService.t(
      'settings.teammates.edit.confirm-subscription-changes.overages-enabled-additional-chargable-seats-message',
      {
        additionalChargableSeats: seatInfo.changeInNumberOfChargeableSeats,
        changeInSeatCost: seatInfo.formattedChangeInSeatCost,
        displayCopilotText: isCopilotSeatAdded,
      },
    );

    if (seatInfo.seatsInUseByInvites) {
      let numberOfAdminsWithSeats = seatInfo.numberOfSeatsInUse - seatInfo.seatsInUseByInvites;
      message += ` ${intlService.t(
        'settings.teammates.edit.confirm-subscription-changes.projected-change-message',
        {
          numberOfAdminsWithSeats,
          seatsInUseByInvites: seatInfo.seatsInUseByInvites,
        },
      )}`;
    }
    return message;
  } else if (seatInfo.numberOfSeatsAvailable > 0) {
    let numberOfChargeableSeatsChanged = seatInfo.changeInNumberOfChargeableSeats > 0;
    message =
      numberOfSeatsRequired <= seatInfo.numberOfSeatsAvailable
        ? intlService.t(
            'settings.teammates.edit.confirm-subscription-changes.available-seats-more-than-required-message',
            {
              numberOfSeatsAvailable: seatInfo.numberOfSeatsAvailable,
              displayCopilotText: isCopilotSeatAdded && !numberOfChargeableSeatsChanged,
            },
          )
        : intlService.t(
            'settings.teammates.edit.confirm-subscription-changes.available-seats-less-than-required-message',
            {
              numberOfSeatsAvailable: seatInfo.numberOfSeatsAvailable,
              displayCopilotText: isCopilotSeatAdded && !numberOfChargeableSeatsChanged,
            },
          );
    if (numberOfChargeableSeatsChanged) {
      message += ` ${intlService.t(
        'settings.teammates.edit.confirm-subscription-changes.more-seats-required-than-available-message',
        {
          additionalChargableSeats: seatInfo.changeInNumberOfChargeableSeats,
          changeInSeatCost: seatInfo.formattedChangeInSeatCost,
          displayCopilotText: isCopilotSeatAdded,
        },
      )}`;
    }
  } else {
    message = intlService.t(
      'settings.teammates.edit.confirm-subscription-changes.no-available-seats-message',
      {
        numberOfSeatsRequired,
        changeInSeatCost: seatInfo.formattedChangeInSeatCost,
        displayCopilotText: isCopilotSeatAdded,
      },
    );
  }
  return message;
}

async function confirmSubscriptionChanges(
  numberOfSeatsRequired = 1,
  showConfirmModal = (options) => {},
  options = {},
  withInviteReload = true,
  isCopilotSeatAdded = false,
  copilotData = {},
) {
  let appService = containerLookup('service:appService');
  if (
    !appService.app.canUsePerProductPricingFlow ||
    (numberOfSeatsRequired === 0 && !isCopilotSeatAdded)
  ) {
    return true;
  }
  let copilotPriceData = copilotData;
  if (isCopilotSeatAdded && !copilotPriceData.price) {
    let copilotDataService = containerLookup('service:copilotDataService');
    await copilotDataService.loadCopilotPrice();
    copilotPriceData = copilotDataService.copilotData;
  }
  let subscriptionChangeMessage = await getSubscriptionChangeMessage(
    numberOfSeatsRequired,
    options.editFlow,
    withInviteReload,
    isCopilotSeatAdded,
    copilotPriceData,
  );
  if (!subscriptionChangeMessage) {
    return true;
  }

  let intlService = getIntlService();
  let modalTitle = intlService.t(
    numberOfSeatsRequired === 0 && isCopilotSeatAdded
      ? 'settings.teammates.edit.add-copilot-seat-modal.title'
      : 'settings.teammates.edit.confirm-subscription-changes.add-inbox-seats',
  );
  return await showConfirmModal({
    title: modalTitle,
    confirmButtonText:
      options.confirmationButtonLabel ||
      intlService.t('settings.teammates.edit.confirm-subscription-changes.invite-and-fill-seats'),
    body: subscriptionChangeMessage,
  });
}

export { confirmSubscriptionChanges, getInboxSeatInfo };
