/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-jquery */
import $ from 'jquery';
//NOTE: GJ: it would be nice to create a Blocks object model which would encapsulate this logic

let getBlocksInfo = function (blocks) {
  let info = {
    characterCount: 0,
    hasImage: false,
    hasVideo: false,
    hasButton: false,
    hasHtml: false,
  };

  for (let i = 0; i < blocks.length; i++) {
    let block = blocks[i];
    if (block.type === 'paragraph' || block.type === 'heading' || block.type === 'subheading') {
      info.characterCount += $.trim(block.text).length;
    }

    if (block.type === 'image') {
      info.hasImage = true;
    }

    if (block.type === 'video') {
      info.hasVideo = true;
    }

    if (block.type === 'button') {
      info.hasButton = true;
    }

    if (block.type === 'html') {
      info.hasHtml = true;
    }
  }

  return info;
};

export default getBlocksInfo;
