/* RESPONSIBLE TEAM: team-help-desk-experience */

const DOMAIN_PATTERN_REGEX = /^(\*\.)?([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/i;

export function domainPatternIsValid(domainPattern: string): boolean {
  return DOMAIN_PATTERN_REGEX.test(domainPattern);
}

export function extractDomainPatterns(text: string): string[] {
  return text
    .split(/[,\n]/)
    .map((domainPattern) => domainPattern.trim())
    .filter(Boolean);
}

export function extractInvalidDomainPatterns(text: string): string[] {
  return extractDomainPatterns(text).filter(
    (domainPattern) => !domainPatternIsValid(domainPattern),
  );
}
