/* RESPONSIBLE TEAM: team-frontend-tech */
import { isEmpty } from '@ember/utils';
import numberFormatter from 'embercom/lib/number-formatter';

export const REPORTS_WHOLE_NUMBER_PRECISION = 0;
export const REPORTS_PRECISION_DEFAULT = 1;
export const REPORTS_PRECISION_DETAILED = 2;

/**
 * Returns an unrounded percentage, or undefined if
 *  a) either of the arguments are not numbers
 *  b) the divisor is 0
 * @param {number} divisor
 * @param {number} dividend
 *
 * @returns {number}
 */
export default function percent(divisor, dividend) {
  if (!isNumber(divisor) || !isNumber(dividend) || parseFloat(divisor) === 0) {
    return undefined;
  }
  let divisorNumber = parseFloat(divisor);
  let dividendNumber = parseFloat(dividend);
  return (dividendNumber / divisorNumber) * 100;
}

/**
 * Returns a string with a % appended, formatted to 0 or 1 points of precision.
 * If the percentage is less than 0.5, at least one point of precision will be mantained (e.g. '0.4%').
 * @param {number} val
 * @param {number} decimalPlaces
 *
 * @returns {string}
 */
export function toPercentString(val, decimalPlaces = 0) {
  if (typeof val === 'string') {
    val = parseFloat(val);
  }
  if (!isNumber(val)) {
    return `\u2014`; //em dash
  } else {
    return `${numberFormatter(formatNumber(val, decimalPlaces))}%`;
  }
}

/**
 * Used by handlebars templates to calculate and format percentages.
 * @param {array} params
 * @param {number} decimalPlaces
 *
 * @returns {string}
 */
export function percentStringHelper(params, decimalPlaces) {
  if (params.length === 1) {
    return toPercentString(params[0], decimalPlaces);
  } else {
    return toPercentString(percent(params[0], params[1]), decimalPlaces);
  }
}

function formatNumber(number, decimalPlaces) {
  if (number <= 0.5 && number >= -0.5 && decimalPlaces === 0) {
    //For values less than half a percent we will have 1 place precision
    //parseFloat allows us to drop insignificant trailing zeros
    return parseFloat(number.toFixed(1));
  } else {
    return parseFloat(number.toFixed(decimalPlaces));
  }
}

function isNumber(n) {
  return !isEmpty(n) && !isNaN(parseFloat(n)) && isFinite(n);
}
