/* RESPONSIBLE TEAM: team-frontend-tech */
const PRIMARY_NAV_OVERFLOW_SELECTOR = '[data-intercom-target="primary-nav-overflow-menu-opener"]';
const MOUSEENTER_EVENT = new MouseEvent('mouseenter', { bubbles: true });

function openOverflowMenu() {
  let overflowOpener = document.querySelector(PRIMARY_NAV_OVERFLOW_SELECTOR);

  if (!overflowOpener) {
    return;
  }

  overflowOpener.dispatchEvent(MOUSEENTER_EVENT);
}

export function openOverflowMenuIfLinkNotVisible(intercomTargetName) {
  if (document.querySelector(`[data-intercom-target="${intercomTargetName}"]`)) {
    return;
  }

  openOverflowMenu();
}
