/* RESPONSIBLE TEAM: team-frontend-tech */
import { readOnly } from '@ember/object/computed';
import DefaultPredicateManager from 'embercom/lib/common/predicates/default-predicate-manager';

export default DefaultPredicateManager.extend({
  isValid: readOnly('predicate.hasAttributeComparisonAndType'),

  getDefaultValue() {
    return null;
  },

  getDefaultComparison() {
    return 'known';
  },

  getUserType() {
    if (this.get('predicate.comparison') === 'false') {
      return 'user';
    } else if (this.get('predicate.comparison') === 'true') {
      return 'lead';
    } else {
      throw new Error('not a valid user type');
    }
  },
});
