/* RESPONSIBLE TEAM: team-reporting */
export const ADMIN_STATUS_CHANGE_SOURCE = 'admin_status_change';
export const DEFAULT_SHARED_SEGMENTATION = 'channel_type';
export const DEFAULT_ADMIN_STATUS_CHANGE_SEGMENTATION = 'admin_id';
export const FIRST_RESPONSE_TIME = 'first_response_time';
export const TIME_GROUP_PROPERTY_IDENTIFIER = 'time';
const CALL_SOURCES = ['call', 'call_teammate_stats_call', 'call_team_stats'];
const DEFAULT_CALL_SEGMENTATION = 'call_type';

const COPILOT_PROMPT_RESPONSE_PAIR_SOURCES = 'copilot_prompt_response_pair';
const DEFAULT_COPILOT_PROMPT_RESPONSE_PAIR_SEGMENTATION = 'admin_id';

export function getDefaultSegmentByPropertyBySource(source) {
  if (source === ADMIN_STATUS_CHANGE_SOURCE) {
    return DEFAULT_ADMIN_STATUS_CHANGE_SEGMENTATION;
  } else if (CALL_SOURCES.includes(source)) {
    return DEFAULT_CALL_SEGMENTATION;
  } else if (source === COPILOT_PROMPT_RESPONSE_PAIR_SOURCES) {
    return DEFAULT_COPILOT_PROMPT_RESPONSE_PAIR_SEGMENTATION;
  }
  return DEFAULT_SHARED_SEGMENTATION;
}
