/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-jquery */
/* eslint-disable jquery-unsafe/no-html */
import { Promise as EmberPromise } from 'rsvp';
import $ from 'jquery';
import intermoji from '@intercom/intermoji';
import { captureException } from 'embercom/lib/sentry';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

let _hasNativeEmojiSupport;
let emoji = {};

emoji.emojify = function (text, renderAsciiEmoji = true) {
  // Giving control over whether to render a standalone ascii emoji or not
  // because it calls sanitizeHtml which was causing performance issues
  // see https://github.com/intercom/intercom/issues/284346#issuecomment-1548470293
  if (renderAsciiEmoji && this.isAsciiSticker(text)) {
    let ascii = this.Html2Text(text);
    text = text.replace(ascii, intermoji.unicodeFromAscii(ascii));
  }

  return intermoji.splitOnUnicodeEmojis(text).reduce((prev, item) => {
    try {
      if (!this.hasNativeSupport() || intermoji.isUglyNativeEmoji(item)) {
        prev += intermoji.fallbackImage(16, item);
      } else {
        prev += item;
      }
    } catch (e) {
      prev += item;
      captureException(e, {
        fingerprint: ['emoji', 'emojify'],
        extra: {
          text,
          item,
        },
      });
    }
    return prev;
  }, '');
};

emoji.hasNativeSupport = function () {
  if (_hasNativeEmojiSupport === undefined) {
    _hasNativeEmojiSupport = intermoji.hasNativeSupport(document);
  }
  return _hasNativeEmojiSupport;
};

emoji.Html2Text = function (text) {
  return $('<div>').html(sanitizeHtml(text).string).text().trim();
};

emoji.isUnicodeSticker = function (text) {
  return intermoji.isSupportedUnicode(this.Html2Text(text));
};

emoji.isAsciiSticker = function (text) {
  return intermoji.isSupportedAscii(this.Html2Text(text));
};

emoji.isSticker = function (text) {
  return this.isUnicodeSticker(text) || this.isAsciiSticker(text);
};

emoji.stickerUri = function (text) {
  if (!this.isSticker(text)) {
    return null;
  }

  let emoji = this.Html2Text(text);
  if (this.isAsciiSticker(emoji)) {
    emoji = intermoji.unicodeFromAscii(emoji);
  }
  return intermoji.twemojiSVGUri(emoji);
};

emoji.preloadSticker = function (text) {
  return new EmberPromise((resolve) => {
    let img = new Image();
    let stickerUri = this.stickerUri(text);
    $(img).one('load error', function () {
      $(this).off('load error');
      resolve();
    });
    img.src = stickerUri;
  });
};

export default emoji;

export function generateSafeEmoji(emoji) {
  return sanitizeHtml(
    intermoji.fallbackImage(16, intermoji.getEmojiFromSupportedTwemoji(emoji), 'emoji__image'),
  );
}
