/* RESPONSIBLE TEAM: team-help-desk-experience */
// @ts-nocheck
import {
  BlocksDocument,
  type ComposerPublicAPI,
  type ComposerConfig,
} from '@intercom/embercom-prosemirror-composer';
import SavedRepliesComposerConfig from 'embercom/lib/inbox/saved-replies/composer-config';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { schedule } from '@ember/runloop';
import normalizeBRTags from '../composer-helpers/normalize-br-tags';

export class ProsemirrorComposerWrapper {
  autoFocus = false;

  @tracked blocksDoc: BlocksDocument;
  @tracked api: ComposerPublicAPI;
  @tracked macro: any;

  @tracked paneComponent: any;
  @tracked config: ComposerConfig;

  constructor(paneComponent, macro, autoFocus) {
    this.blocksDoc = new BlocksDocument();
    this.paneComponent = paneComponent;
    this.autoFocus = autoFocus;
    this.setConfig();
    this.macro = macro;
  }

  @action
  setConfig() {
    this.config = new SavedRepliesComposerConfig(
      this.paneComponent.app,
      this.attachmentsEnabled,
      this.autoFocus,
    );
  }

  getBlocks() {
    let blocksDoc = this.blocksDoc;
    blocksDoc = normalizeBRTags(blocksDoc);
    return blocksDoc.blocks;
  }

  sync() {
    //noop new composer is always in sync
  }

  focus() {
    this.api.composer.commands.focus();
  }

  removeFocus() {
    (document.activeElement as HTMLElement).blur();
  }

  load(blocks) {
    this.blocksDoc = new BlocksDocument(blocks);
    schedule('afterRender', () => {
      if (this.api) {
        this.paneComponent.composerHasContent = this.api.composer.state.hasContent;
      }
    });
  }

  loadAndFocus(blocks) {
    this.blocksDoc = new BlocksDocument(blocks);
    schedule('afterRender', () => {
      if (this.api) {
        this.api.composer.commands.focus();
        this.paneComponent.composerHasContent = this.api.composer.state.hasContent;
      }
    });
  }

  createBlock(type, args) {
    let block = Object.assign({}, { type }, args);
    this.api.composer.commands.insertBlock(block);
  }

  uploadImage(files: Array<File>) {
    files.reverse().forEach((file) => {
      this.api.composer.commands.insertImageFile(file);
      this.api.composer.commands.collapseSelection();
    });
  }

  uploadAttachment(files: Array<File>) {
    files.forEach((file) => {
      this.api.composer.commands.addAttachment(file);
    });
  }

  insertBlocks(blocks) {
    this.api.composer.commands.insertBlocks(blocks);
  }

  paste(text, type) {
    if (type === 'plain') {
      this.api.composer.commands.insertText(text);
    }
  }

  @action
  onReady(api: ComposerPublicAPI) {
    this.api = api;
    this.paneComponent.composerHasContent = this.api.composer.state.hasContent;
  }

  @action
  onChange(blocksDoc: BlocksDocument) {
    this.blocksDoc = blocksDoc;
    this.paneComponent.composerHasContent = this.api.composer.state.hasContent;
    if (this.paneComponent.contentsChanged) {
      this.paneComponent.contentsChanged();
    }
  }

  @action
  uploadingStateChanged() {
    this.paneComponent.composerIsUploading = this.api.composer.state.isUploading;
  }

  send(command, ...args) {
    try {
      this[command](...args);
    } catch (e) {
      console.error(`Error running command ${command}: ${e.message}`);
    }
  }

  private get attachmentsEnabled() {
    return this.paneComponent.app.teammateConversationAttachmentsEnabled;
  }
}
