/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
// Map 'models/messages/summary-conversation' PROPERTY to HEADER_NAME
const COLUMNS = {
  'user.email': 'Email',
  sentAt: 'Sent',
  bouncedAt: 'Bounced',
  openedAt: 'Opened',
  clickedAt: 'Clicked',
  linksClickedCount: 'Links Clicked',
  collectedAt: 'Collected',
  repliedAt: 'Replied',
  complainedAt: 'Marked spam',
  unsubscribedAt: 'Unsubscribed',
  hitGoalAt: 'Hit Goal',
  respondedAt: 'Responded',
  respondedWith: 'Responded With',
};

function Row(...properties) {
  this.headers = [];
  this.columns = [];

  properties.forEach((modelProperty) => {
    let headerName = COLUMNS[modelProperty];
    // Verify property integrity
    if (typeof headerName !== 'undefined') {
      this.columns.push(modelProperty);
      this.headers.push(headerName);
    }
  });
}

const STATS_MAP = {
  bounced: new Row('user.email', 'sentAt', 'bouncedAt'),
  complained: new Row('sentAt', 'openedAt', 'hitGoalAt', 'clickedAt', 'repliedAt', 'complainedAt'),
  unsubscribed: new Row(
    'sentAt',
    'openedAt',
    'hitGoalAt',
    'clickedAt',
    'repliedAt',
    'unsubscribedAt',
  ),
  failed: new Row(
    'sentAt',
    'openedAt',
    'hitGoalAt',
    'clickedAt',
    'repliedAt',
    'unsubscribedAt',
    'bouncedAt',
    'complainedAt',
  ),
  goal: new Row('sentAt', 'openedAt', 'hitGoalAt', 'clickedAt', 'repliedAt'),
  collected: new Row('sentAt', 'openedAt', 'hitGoalAt', 'clickedAt', 'collectedAt', 'repliedAt'),
  clicked: new Row('sentAt', 'openedAt', 'hitGoalAt', 'clickedAt', 'repliedAt'),
};

const VARIATIONS_MAP = {
  reactions: new Row(
    'sentAt',
    'openedAt',
    'hitGoalAt',
    'clickedAt',
    'respondedAt',
    'respondedWith',
  ),
  push: new Row('sentAt', 'openedAt'),
};

const STANDARD_ROW = new Row('sentAt', 'openedAt', 'hitGoalAt', 'clickedAt', 'repliedAt');

export default {
  getHeadersForStat(statistic) {
    return STATS_MAP[statistic] ? STATS_MAP[statistic].headers : null;
  },
  getColumnsForStat(statistic) {
    return STATS_MAP[statistic] ? STATS_MAP[statistic].columns : null;
  },
  getHeadersForStatWithBetterClickDataFF(statistic) {
    if (statistic === 'clicked') {
      return new Row(
        'sentAt',
        'openedAt',
        'hitGoalAt',
        'clickedAt',
        'linksClickedCount',
        'repliedAt',
      ).headers;
    }
    return STATS_MAP[statistic] ? STATS_MAP[statistic].headers : null;
  },
  getColumnsForStatWithBetterClickDataFF(statistic) {
    if (statistic === 'clicked') {
      return new Row(
        'sentAt',
        'openedAt',
        'hitGoalAt',
        'clickedAt',
        'linksClickedCount',
        'repliedAt',
      ).columns;
    }
    return STATS_MAP[statistic] ? STATS_MAP[statistic].columns : null;
  },
  getHeadersForVariation(variation) {
    return VARIATIONS_MAP[variation] ? VARIATIONS_MAP[variation].headers : null;
  },
  getColumnsForVariation(variation) {
    return VARIATIONS_MAP[variation] ? VARIATIONS_MAP[variation].columns : null;
  },
  getStandardHeaders() {
    return STANDARD_ROW.headers;
  },
  getStandardColumns() {
    return STANDARD_ROW.columns;
  },
  getHeadersForControlGroup(statistic) {
    if (statistic === 'goal') {
      return ['Joined', COLUMNS.hitGoalAt];
    }
    return ['Joined'];
  },
  getColumnsForControlGroup(statistic) {
    if (statistic === 'goal') {
      return ['sentAt', 'hitGoalAt'];
    }
    return ['sentAt'];
  },
  filterGoalHeaderFromHeaders(headers) {
    return headers.filter((header) => header !== COLUMNS.hitGoalAt);
  },
  filterGoalColumnFromColumns(columns) {
    return columns.filter((column) => column !== `hitGoalAt`);
  },
};
