/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import { getIntlService } from 'embercom/lib/container-lookup';

let numberFormatter = function (value) {
  if (!value) {
    return '0';
  }
  if (value < 1000) {
    return value.toString();
  }
  let parts = value.toString().split('.');
  parts[0] = getIntlService().formatNumber(parts[0]);
  return parts.join('.');
};

export default numberFormatter;

export function truncatedNumberFormatter(value) {
  if (value > 999999) {
    return `${Math.round(Math.floor(value / 1000000))}M+`;
  } else if (value > 9999) {
    return `${Math.round(Math.floor(value / 1000))}K+`;
  }
  return numberFormatter(value);
}

export function reportingTruncatedNumberFormatter(value) {
  if (value >= 1000000000) {
    return `${(value / 1000000000).toFixed(1).replace(/\.0$/, '')}G`;
  } else if (value >= 1000000) {
    return `${(value / 1000000).toFixed(1).replace(/\.0$/, '')}M`;
  } else if (value >= 1000) {
    return `${(value / 1000).toFixed(1).replace(/\.0$/, '')}k`;
  }
  return value;
}
