/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
// @ts-nocheck
import {
  EMOJI_TYPEAHEAD,
  type ComposerConfig,
  type InitialSelection,
  type TextDirection,
} from '@intercom/embercom-prosemirror-composer';
import EmbercomFileUploader from '../../articles/embercom-file-uploader';
import { type CanvasNetworkClient } from '@intercom/interblocks.ts';
import {
  INPUT_RULE_CODE_BLOCK,
  INPUT_RULE_ORDERED_LIST,
  INPUT_RULE_UNORDERED_LIST,
  INPUT_RULE_INLINE_CODE,
  INPUT_RULE_EMOJI,
} from '@intercom/embercom-prosemirror-composer';

import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { triggerCharacter } from '@intercom/embercom-prosemirror-composer/lib/composer/typeahead/trigger-character';
import { EmbercomCanvasNetworkClient } from 'embercom/objects/composer/embercom-canvas-network-client';
export default class SavedRepliesComposerConfig implements ComposerConfig {
  upload;
  attributes;
  templating;

  debug = false;
  renderOverlaysInPlace = false;
  autoFocus = false;

  tools = [
    { name: 'text-formatter' },
    { name: 'anchor-editor' },
    { name: 'template-inserter' },
    { name: 'fallback-editor' },
    { name: 'image-editor', options: { supportAltAttributeEditing: false } },
  ];
  typeaheads = [EMOJI_TYPEAHEAD];

  textDirection = 'auto' as TextDirection;
  placeholder = 'Write your saved reply...';
  initialSelectionLocation = 'start' as InitialSelection;
  canvasNetworkClient: CanvasNetworkClient;

  allowedBlocks = [
    'paragraph',
    'image',
    'orderedList',
    'unorderedList',
    'heading',
    'subheading',
    'codeBlock',
  ];

  allowedInline = ['bold', 'italic', 'anchor', 'code'];

  inputRules = [
    INPUT_RULE_CODE_BLOCK,
    INPUT_RULE_INLINE_CODE,
    INPUT_RULE_ORDERED_LIST,
    INPUT_RULE_UNORDERED_LIST,
    INPUT_RULE_EMOJI,
  ];

  constructor(app: any, allowUploads: boolean, autoFocus = false) {
    this.configureUploads(app.id, allowUploads);
    this.canvasNetworkClient = new EmbercomCanvasNetworkClient(app.id);
    this.allowedBlocks.push('messengerCard');
    this.autoFocus = autoFocus;
    this.attributes = app.allowedAttributes;

    this.templating = {
      picker: 'common/attribute-picker',
      resolver: new AttributeInfoResolver({ attributes: this.attributes }),
    };

    // TODO placeholder in editor
    if (app.canUseMacros) {
      this.placeholder = 'Add a response (optional)';
    }
  }

  private configureUploads(appId: string, allowUploads: boolean) {
    if (allowUploads) {
      this.upload = {
        allowedImageFileTypes: ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'],
        allowedAttachmentTypes: ['*'],
        uploader: EmbercomFileUploader,
        attrs: { policyUrl: `/apps/${appId}/uploads` },
      };
    }
  }
}
