/* RESPONSIBLE TEAM: team-help-desk-experience */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Helper from '@ember/component/helper';
import platform from 'embercom/lib/browser-platform';

export default class ModifierKey extends Helper {
  compute(): string {
    return getModifierKeyLabel();
  }
}

export function getModifierKeyLabel(): string {
  return platform.isMac ? '⌘' : 'Ctrl';
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'modifier-key': typeof ModifierKey;
  }
}
