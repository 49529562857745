/* RESPONSIBLE TEAM: team-help-desk-experience */
import { helper as buildHelper } from '@ember/component/helper';

const KB = Math.pow(10, 3);
const MB = Math.pow(10, 6);
const GB = Math.pow(10, 9);

export function humanReadableFileSize([bytes]: [number?]) {
  if (bytes === undefined) {
    return '0.00 KB';
  }

  if (bytes >= GB) {
    return `${(bytes / GB).toFixed(2)} GB`;
  }

  if (bytes >= MB) {
    return `${(bytes / MB).toFixed(2)} MB`;
  }

  return `${(Math.abs(bytes) / KB).toFixed(2)} KB`;
}

const helper = buildHelper<{
  Args: {
    Positional: [bytes?: number];
  };
  Return: string;
}>(humanReadableFileSize);

export default helper;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'human-readable-file-size': typeof helper;
  }
}
