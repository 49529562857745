/* RESPONSIBLE TEAM: team-reporting */
import { select } from 'd3-selection';
import 'd3-transition';
import PALETTE from '@intercom/pulse/lib/palette';
import AppColor from 'embercom/lib/color';

const WHITE = 'rgb(255, 255, 255)';

function darken(cssRgbColor) {
  let regex = /^rgb\((\d+), (\d+), (\d+)\)$/;
  let matches = regex.exec(cssRgbColor);
  let appColor = new AppColor();
  let r = Number(matches[1]) / 255;
  let g = Number(matches[2]) / 255;
  let b = Number(matches[3]) / 255;
  let hex = appColor.RGB_TO_HEX(r, g, b);
  return appColor.darken(hex, 7);
}

export class BubbleChartSatisfaction {
  csatColorScale;
  showSatisfaction = false;

  animateColorChange(selection, labelFillColor, bubbleFillColor, bubbleStrokeColor) {
    selection
      .select('.reporting__bubble-chart__label')
      .transition()
      .duration(500)
      .style('fill', labelFillColor);

    selection
      .select('.reporting__bubble-chart__bubble')
      .transition()
      .duration(500)
      .style('fill', bubbleFillColor)
      .style('stroke', bubbleStrokeColor);
  }

  // Show CSAT by coloring bubbles appropriately.
  turnCSATon(nodes, externalLabels) {
    let csatColorScale = this.csatColorScale;
    let animateColorChange = this.animateColorChange;

    nodes.each(function (d) {
      let positiveRate = d.currentPositiveRate;
      let bubbleFillColor = positiveRate !== null ? csatColorScale(positiveRate) : WHITE;
      let bubbleStrokeColor = darken(bubbleFillColor);

      animateColorChange(select(this), PALETTE.black, bubbleFillColor, bubbleStrokeColor);
    });

    externalLabels.classed('o__with-csat', true);
  }

  // Return bubbles to original color.
  turnCSAToff(nodes, externalLabels) {
    nodes.call(this.animateColorChange, null, null, null);
    externalLabels.classed('o__with-csat', false);
  }

  updateCsatColoring(nodes, externalLabels) {
    if (this.showSatisfaction) {
      this.turnCSATon(nodes, externalLabels);
    } else {
      this.turnCSAToff(nodes, externalLabels);
    }
  }

  toggleSatisfaction(element, showSatisfaction) {
    this.showSatisfaction = showSatisfaction;
    let nodes = select(element).selectAll('.reporting__bubble-chart__node');
    let externalLabels = select(element).selectAll('.reporting__bubble-chart__external-label');

    this.updateCsatColoring(nodes, externalLabels);
  }

  init(csatColorScale) {
    this.csatColorScale = csatColorScale;
  }
}
