/* RESPONSIBLE TEAM: team-frontend-tech */
export function initialize(applicationInstance) {
  let windowVisibilityEventsService = applicationInstance.lookup(
    'service:windowVisibilityEventsService',
  );
  windowVisibilityEventsService.registerListeners();
}

export default {
  name: 'window-visibility-events',
  initialize,
};
