/* RESPONSIBLE TEAM: team-workflows */
import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import Delays from 'embercom/lib/workflows/helpers/delays';

export default class FormatInactivityDuration extends Helper {
  @service declare intl: IntlService;

  compute(params: any /*, named: any*/) {
    let durationInSeconds = params[0];
    let delays = new Delays();
    let durationValue = delays.getDelayValue(durationInSeconds);
    let durationUnit = delays.getDelayUnit(durationInSeconds);
    return this.intl.t(`operator.custom-bot.trigger.inactivity-duration.summary.${durationUnit}`, {
      count: durationValue,
    });
  }
}
