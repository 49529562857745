/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import moment from 'moment-timezone';
import dateAndTimeFormats from 'embercom/lib/date-and-time-formats';

export function messageScheduleSuccess(message, params) {
  let formattedDateAndTime = moment
    .tz(params.send_at, message.get('app.timezone'))
    .format(dateAndTimeFormats.fullDateAndTimeWithoutTimezoneWithDayName);
  return `Your message is scheduled to send at ${formattedDateAndTime}.`;
}

export function messageScheduleFailure() {
  return `We couldn't schedule the message. Send us a message and we'll do our best to help out.`;
}
