/* RESPONSIBLE TEAM: team-frontend-tech */
function getAppType() {
  return window.__embercom_boot_data?.app_type || '';
}

export function isBootedInPublicAppStoreMode() {
  let appType = getAppType();
  return appType === 'public_app_store';
}

export function isBootedInPublicPage() {
  let appType = getAppType();
  return appType === 'public_app_store' || appType === 'public_meeting_scheduler';
}

export function isBootedInMeetingScheduler() {
  let appType = getAppType();
  return appType === 'public_meeting_scheduler';
}

export function publicPageRootUrl() {
  return window.__embercom_boot_data?.public_page_config?.root_url;
}

export function publicPageApiHost() {
  return window.__embercom_boot_data?.public_page_config?.api_host;
}

export function getApiHost() {
  return isBootedInPublicPage() ? publicPageApiHost() : '';
}

export function getApiUrl() {
  return `${getApiHost()}/${getApiNamespace()}`;
}

export function getApiNamespace() {
  return isBootedInPublicPage() ? 'ember/public' : 'ember';
}

export default {
  getApiHost,
  getApiNamespace,
  getApiUrl,
  isBootedInMeetingScheduler,
  isBootedInPublicAppStoreMode,
  isBootedInPublicPage,
  publicPageApiHost,
  publicPageRootUrl,
};
