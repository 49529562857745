/* RESPONSIBLE TEAM: team-frontend-tech */
import DefaultPredicateManager from 'embercom/lib/common/predicates/default-predicate-manager';

export default DefaultPredicateManager.extend({
  defaultComparison: 'in',
  goalComparison: 'in',
  getDefaultValue() {
    return this.get('predicate.optionsForType.firstObject.value');
  },
});
