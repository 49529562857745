/* RESPONSIBLE TEAM: team-help-desk-experience */
import { helper as buildHelper } from '@ember/component/helper';

function replace([word, toReplace, replaceWith]: [string, string, string]) {
  return word?.replace(toReplace, replaceWith);
}

let helper = buildHelper<{
  Args: {
    Positional: [string, string, string];
  };
  Return: string;
}>(replace);
export default helper;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    replace: typeof helper;
  }
}
