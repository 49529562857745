/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { helper } from '@ember/component/helper';

const ALLOWED_TYPES = {
  CONJUNCTION: 'conjunction',
  DISJUNCTION: 'disjunction',
  UNIT: 'unit',
};

// a better way to implement this logic is using formatList API of ember-intl
// unfortunately formatList function is not supported by ember-intl version we currently use.
export function formatList([words], { type = ALLOWED_TYPES.CONJUNCTION }) {
  if (!Object.values(ALLOWED_TYPES).includes(type)) {
    throw new Error(`Unexpected type: ${type} for formatList helper`);
  }

  if (words.length < 2) {
    return words.join();
  }

  switch (type) {
    case ALLOWED_TYPES.CONJUNCTION:
      return _formatWordsWithConjunction(words);
    case ALLOWED_TYPES.DISJUNCTION:
      return `${words.slice(0, -1).join(', ')} or ${words[words.length - 1]}`;
    case ALLOWED_TYPES.UNIT:
      return words.join(', ');
  }
}

function _formatWordsWithConjunction(words) {
  if (words.length === 2) {
    return words.join(' and ');
  }

  return `${words.slice(0, -1).join(', ')}, and ${words[words.length - 1]}`;
}

export default helper(formatList);
