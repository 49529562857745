/* RESPONSIBLE TEAM: team-frontend-tech */
// link to SO https://stackoverflow.com/a/49974627/10891259
export default function rgbaToHex(orig) {
  let a;
  let rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i);
  let alpha = ((rgb && rgb[4]) || '').trim();
  /* eslint-disable no-bitwise */
  let hex = rgb
    ? (rgb[1] | (1 << 8)).toString(16).slice(1) +
      (rgb[2] | (1 << 8)).toString(16).slice(1) +
      (rgb[3] | (1 << 8)).toString(16).slice(1)
    : orig;
  if (alpha !== '') {
    a = alpha;
  } else {
    a = 1;
  }
  // multiply before convert to HEX
  a = ((a * 255) | (1 << 8)).toString(16).slice(1);
  /* eslint-enable no-bitwise */
  hex += a;

  return `#${hex}`;
}
