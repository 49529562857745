/* RESPONSIBLE TEAM: team-reporting */
import Formatters from 'embercom/lib/reporting/flexible/formatters';
import Axis from 'embercom/lib/reporting/flexible/axis';
import { isPresent } from '@ember/utils';
import IntervalFormatter from 'embercom/lib/reporting/flexible/interval-formatter';
import ChartConfig from 'embercom/lib/reporting/flexible/default-column-line-chart-config';
import moment from 'moment-timezone';
import {
  buildColorsForSegments,
  buildColorsForTimeComparison,
  buildColorsForViewBy,
} from 'embercom/lib/reporting/custom/view-config-builder-helpers';
import {
  SERIES_COLORS,
  seriesColor,
  HOVER_STATE_COLOR_WEIGHT,
} from 'embercom/lib/reporting/flexible/serieschart-builder';
import type Range from 'embercom/models/reporting/range';
import { type ViewConfig } from 'embercom/services/reporting-chart-service';
import { type Metric, type Unit } from 'embercom/objects/reporting/unified/metrics/types';

const COLUMN_CHART_TYPE = 'column';

const LINE_CHART_TYPE = 'line';

const INDEX_CHART_TYPE_MAPPING: any = {
  [0]: COLUMN_CHART_TYPE,
  [1]: LINE_CHART_TYPE,
};

const TICK_INTERVAL_IN_MS: any = {
  hour: 3600 * 1000,
  day: 24 * 3600 * 1000,
  week: 24 * 3600 * 1000 * 7,
  month: 24 * 3600 * 1000 * 30,
};

interface DualAxisChartSeriesParams {
  name: string;
  data: any[];
  type: string; // new dual-axis
  yAxis: number; // new dual-axis
  tooltip?: any; // new dual-axis
  legendLabel: string;
  tooltipDisplayUnit: string;
  showInLegend: boolean;
  dataModified: any;
  isComparison: boolean;
  isPreviousPeriod: boolean;
  rawToLabelMapping: any;
  states: any;
  hoverColor: string;
}

const MAX_LABELS = 12;
const MAX_LABELS_FOR_NOMINAL_AXIS = 20;
const MIN_CHART_WIDTH_FOR_NOMINAL_AXIS_LABELS = 500;
const HOVER_STATE_THRESHOLD = 10;

export default class ColumnLineChartBuilder {
  range: Range;
  viewConfig: ViewConfig;
  dataConfig: any;
  seriesColors: string[] | undefined;
  width: string;
  chartData: DualAxisChartSeriesParams[];
  isMultimetric: boolean;
  isTimeComparison: boolean;
  interval: string;
  xAxisType: string;
  app: any;
  defaultColors: string[] | undefined;

  constructor(
    range: Range,
    viewConfig: ViewConfig,
    dataConfig: any,
    app: any,
    isTimeComparison: boolean,
    chartData: DualAxisChartSeriesParams[],
    seriesColors?: string[],
    width = '',
  ) {
    this.range = range;
    this.seriesColors = seriesColors;
    this.xAxisType = dataConfig.xAxis?.type;
    this.width = width;
    this.chartData = chartData;
    this.viewConfig = viewConfig;
    this.dataConfig = dataConfig;
    this.interval = dataConfig.xAxis?.data?.interval;
    this.defaultColors = this.seriesColors || SERIES_COLORS;
    this.app = app;
    this.isMultimetric = true;
    this.isTimeComparison = isTimeComparison;
  }

  get intervalFormatter() {
    return new IntervalFormatter(this.interval);
  }

  buildTheme() {
    let config = new ChartConfig(this.range.timezone);
    let metrics = this.viewConfig.metrics;
    config.setXAxisType(this.isXAxisTemporal ? 'datetime' : 'category');

    if (this.isXAxisTemporal) {
      config.setXAxisTickInterval(this.xAxisTickInterval);
    }

    if (
      this.xAxisLabelCount > MAX_LABELS &&
      this.isXAxisTemporal &&
      this.viewConfig.skipLabelsForLargeSeries
    ) {
      config.setStep(this._getStepForConfig());
    }

    metrics.forEach((metric: Metric, index) => {
      config.setYAxisTickInterval(
        this.getYAxisTickInterval(metric.unit, index, metrics.length),
        index,
      );
      let formatter = new Formatters[metric.unit](
        this.viewConfig.formatUnit.displayUnit, // this should be fine as it sets all the values for the chartSeries
      );
      config.setYAxisFormatter(({ value }: any) => formatter.formatAxis(value), index);

      config.setYAxisRanges(this.viewConfig.yAxis?.min, this.viewConfig.yAxis?.max, index);

      if (INDEX_CHART_TYPE_MAPPING[index] === LINE_CHART_TYPE) {
        config.setTooltipFormatterPerSeries(
          this.getTooltipPointFormatter(formatter),
          LINE_CHART_TYPE,
        );
        if (this.viewConfig.showDataLabels) {
          config.enableChartDataLabel(this.getDataLabelFormatter(formatter), LINE_CHART_TYPE);
        }
      } else {
        config.setTooltipFormatterPerSeries(
          this.getTooltipPointFormatter(formatter),
          COLUMN_CHART_TYPE,
        );
        if (this.viewConfig.showDataLabels) {
          config.enableChartDataLabel(this.getDataLabelFormatter(formatter), COLUMN_CHART_TYPE);
        }
      }
    });

    if (this.isXAxisTemporal) {
      config.setXAxisFormatter(this.temporalLabelFormatter);
      // last X axis label is clipped without the extra spacing (default is 10)
      config.setSpacingRight(15);
    } else if (
      this.totalNumberOfColumns >= MAX_LABELS_FOR_NOMINAL_AXIS &&
      this.dataConfig.xAxis?.data?.limit >= MAX_LABELS_FOR_NOMINAL_AXIS
    ) {
      config.setXAxisFormatter(this.nominalLabelFormatter);
    }

    this._setConsistentColors(config);

    if (this.viewConfig.useDarkTooltips) {
      config.useDarkTooltips();
    }

    if (
      this.viewConfig.labelStyleOverrides &&
      isPresent(this.viewConfig.labelStyleOverrides.fontSize)
    ) {
      config.setXAxisFontSize(this.viewConfig.labelStyleOverrides.fontSize);
    }

    if (this.viewConfig.disableLegend) {
      config.disableLegend();
    }

    if (this.viewConfig.colorByPoint) {
      config.setColorByPoint(this.viewConfig.colorByPoint);
    }

    this._enableHoverState();

    return config.config;
  }

  getDataLabelFormatter(formatter: any) {
    return function (this: any) {
      let valuePart = `${formatter.formatTooltip(this.y, undefined, this.series.name)}`;

      return `<div>${valuePart}</div>`;
    };
  }

  getTooltipPointFormatter(formatter: any) {
    let config = this;
    let isXAxisTemporal = config.xAxisType === 'temporal';
    let useDarkTooltips = config.viewConfig.useDarkTooltips;
    return function (this: any) {
      let timezone = this.series.chart.options.time.timezone;
      let datePart;
      if (isXAxisTemporal) {
        if (this.index === 0 && config.interval === 'month') {
          // eslint-disable-next-line
          let nextPoint = this.series.points[this.index + 1];
          let endDate = nextPoint
            ? moment(nextPoint.x || nextPoint.name).subtract(1, 'd') // Line charts use x, column charts use name
            : undefined;
          // @ts-ignore
          datePart = config.intervalFormatter.datePart(config.range.startMoment, timezone, endDate);
        } else if (
          this.series.options.isComparison &&
          this.series.index === 0 &&
          this.series.options.data
        ) {
          let pointData = this.series.options.data[this.index];
          let comparisonDateRange = pointData[pointData.length - 1];
          datePart = config.intervalFormatter.datePart(comparisonDateRange, timezone);
        } else if (this.index === this.series.points?.length - 1) {
          datePart = config.intervalFormatter.datePart(
            this.x || this.name,
            timezone,
            // @ts-ignore
            config.range.endMoment,
          );
        } else {
          datePart = config.intervalFormatter.datePart(this.x || this.name, timezone);
        }
      } else {
        datePart = this.name;
      }

      let valuePart = `${formatter.formatTooltip(this.y, undefined, this.series.name)}`;

      let cssClass = 'reporting__highcharts-tooltip';

      if (this.series.options.isComparison && this.series.options.isPreviousPeriod) {
        cssClass += ' reporting__report-tab-tooltip__title';
      }

      if (useDarkTooltips) {
        let text = config.buildTooltipsForNonCoincidentPoints(config, this, valuePart);
        valuePart = `<span class="text-white">${text}</span>`;
      }
      if (datePart) {
        return `<div class='${cssClass}'><strong>${valuePart}</strong><br/>(${datePart})</div>`;
      } else {
        return `<div class='${cssClass}'><strong>${valuePart}</strong></div>`;
      }
    };
  }

  buildTooltipsForNonCoincidentPoints(config: any, highCharts: any, valuePart: string) {
    let percentagePart =
      config.viewConfig.showPercentages && highCharts.percentage
        ? `${config.percentageString(highCharts.percentage)}`
        : null;
    let pointsValuesPart = config.viewConfig.showValues ? ` (${highCharts.point.value})` : null;

    let parts = config
      .buildPercentAndTooltipPart(valuePart, percentagePart)
      .concat([pointsValuesPart]);

    return parts.compact().join('');
  }

  percentageString(percentage: number) {
    return `${Math.round(percentage * 100) / 100}%`;
  }

  buildPercentAndTooltipPart(valuePart: string, percentagePart: string) {
    return [valuePart, this.addBracketIfValue(percentagePart)].compact();
  }

  addBracketIfValue(value: string) {
    if (value) {
      return ` (${value})`;
    }
    return value;
  }

  get xAxisTickInterval() {
    if (this.isIntervalHourly) {
      return this._tickIntervalForHourlyBreakdown();
    } else {
      return TICK_INTERVAL_IN_MS[this.interval];
    }
  }

  getMinAndMaxValues(index: number, numberOfSeries = 2) {
    let maxValue = 0;
    let minValue = 0;
    let data = this.chartData[index];
    let dataForChartSeries = [data];
    if (data.isPreviousPeriod) {
      let currentSeriesIndex = index + numberOfSeries;
      if (this.chartData.length >= currentSeriesIndex) {
        dataForChartSeries.push(this.chartData[currentSeriesIndex]);
      }
    }
    dataForChartSeries.forEach((series) =>
      series.data.forEach((timestampWithValue: any) => {
        if (timestampWithValue[1] > maxValue) {
          maxValue = Math.round(timestampWithValue[1]);
        }

        if (timestampWithValue[1] < minValue) {
          minValue = Math.round(timestampWithValue[1]);
        }
      }),
    );
    return { minValue, maxValue };
  }

  getYAxisTickInterval(unit: Unit, index: number, numberOfSeries: number) {
    let { minValue, maxValue } = this.getMinAndMaxValues(index, numberOfSeries);

    let diff = maxValue - minValue;
    if (diff === 0) {
      return 5; // Used to split the yAxis.softMax option of Highcharts into even ticks when we don't have data.
    }
    let yAxisTicker = new Axis[unit]();
    let value = yAxisTicker.interval(minValue, maxValue);
    return value || undefined;
  }

  get temporalLabelFormatter() {
    let config = this;

    return function (this: any) {
      let currentLabel = moment.tz(this.value, this.chart.options.time.timezone);
      let format = 'MMM D';
      let maxLabelsForSmallWidths = 8;

      if (config.isIntervalHourly) {
        if (config.width === 'small' && parseInt(currentLabel.format('H'), 10) % 3 !== 0) {
          return '';
        } else if (config.xAxisTickInterval === TICK_INTERVAL_IN_MS.day / 2) {
          // Render '12pm' on every second label when tickInterval is 1/2 a day
          let numberOfTicksFromStart =
            currentLabel.diff(config.range.startMoment) / config.xAxisTickInterval;
          if (numberOfTicksFromStart % 2 !== 0 && !this.isFirst) {
            return '12pm';
          } else {
            format = 'MMM D';
          }
        } else if (config.xAxisTickInterval === TICK_INTERVAL_IN_MS.hour) {
          format = 'hA';
        } else if (config.xAxisTickInterval === TICK_INTERVAL_IN_MS.month) {
          format = 'MMM';
        }

        // Hide the last label in hourly time breakdown with a daily range
        if (this.isLast && config.range.interval === 'day') {
          return '';
        }
      }

      if (
        config.isIntervalDaily &&
        config.width === 'small' &&
        config.range.endMoment.diff(config.range.startMoment) >
          maxLabelsForSmallWidths * 1000 * 60 * 60 * 24
      ) {
        if (config.range.startMoment.diff(currentLabel, 'days') % 2 !== 0) {
          return '';
        }
      }

      if (
        config.isIntervalWeekly &&
        config.range.endMoment.diff(config.range.startMoment) >
          maxLabelsForSmallWidths * 1000 * 60 * 60 * 24 * 7
      ) {
        let firstLabel = config.range.startMoment.clone().startOf('isoWeek');

        if (config.width === 'small' && firstLabel.diff(currentLabel, 'days') % 14 !== 0) {
          return '';
        }
      }

      if (config.isIntervalMonthly) {
        if (config.width === 'small' && parseInt(currentLabel.format('M'), 10) % 2 !== 1) {
          return '';
        } else {
          format = 'MMM';
        }
      }

      return currentLabel.format(format);
    };
  }

  get xAxisLabelCount() {
    return this.chartData.firstObject?.data.length || 0;
  }

  get hideXLabelsForCustomReport() {
    return (
      this.dataConfig.xAxis?.data?.limit > MAX_LABELS_FOR_NOMINAL_AXIS &&
      this.xAxisLabelCount > MAX_LABELS_FOR_NOMINAL_AXIS
    );
  }

  get nominalLabelFormatter() {
    let config = this;
    return function () {
      // @ts-ignore
      return this.chart.chartWidth <= MIN_CHART_WIDTH_FOR_NOMINAL_AXIS_LABELS ||
        config.hideXLabelsForCustomReport
        ? null
        : // @ts-ignore
          this.value;
    };
  }

  get isIntervalHourly() {
    return this.interval === 'hour';
  }

  get isIntervalDaily() {
    return this.interval === 'day';
  }

  get isIntervalWeekly() {
    return this.interval === 'week';
  }

  get isIntervalMonthly() {
    return this.interval === 'month';
  }

  get isXAxisTemporal() {
    return this.xAxisType === 'temporal';
  }

  _getStepForConfig() {
    let step = 2;
    if (this.isIntervalHourly && this.xAxisTickInterval !== TICK_INTERVAL_IN_MS.hour) {
      // In hourly mode, set the step to 1 so that we display every label
      step = 1;
    } else if (this.isIntervalDaily) {
      // In daily mode, set the step according to the max number of labels we can display
      step = Math.ceil(this.range.inDays / MAX_LABELS);
    } else if (this.isIntervalWeekly) {
      let weeks = this.range.inDays / 7;
      step = Math.ceil(weeks / MAX_LABELS);
    }
    return step;
  }

  _enableHoverState() {
    if (this.shouldEnableHoverState) {
      let chartData = this.chartData;
      chartData.forEach((series, index) => {
        let hoverColor = series.hoverColor || seriesColor(index, HOVER_STATE_COLOR_WEIGHT);
        series.states = {
          inactive: {
            opacity: 0.48,
          },
          hover: {
            enabled: true,
            color: hoverColor,
          },
        };
      });
    }
  }

  _setConsistentColors(config: any) {
    if (this.seriesColors) {
      config.setColors(this.seriesColors);
      return;
    }

    if (this.dataConfig.yAxis) {
      if (this.dataConfig.yAxis?.type === 'temporal' || this.isMultimetric) {
        config.setColors(this.defaultColors);
        return;
      }

      let attributeKey = this.dataConfig.yAxis
        ? this.dataConfig.yAxis?.data.property
        : this.dataConfig.xAxis?.data.property;
      buildColorsForSegments(this.chartData, attributeKey);
    } else {
      if (this.dataConfig.xAxis?.type === 'temporal' || this.isMultimetric) {
        let colors = this.defaultColors;
        if (this.isTimeComparison) {
          colors = buildColorsForTimeComparison(this.chartData);
        }

        config.setColors(colors);
        return;
      }

      config.setColorByPoint(true);
      this.chartData.forEach((series, _) => {
        buildColorsForViewBy(series, this.dataConfig.xAxis.data.property);
      });
    }
  }

  get totalNumberOfColumns() {
    let maxSeriesLength = Math.max(...this.chartData.map((series) => series.data.length));
    let totalNumberOfColumns = 0;
    for (let i = 0; i < maxSeriesLength; i++) {
      let anySeriesHasANonZeroPointAtThisIndex = this.chartData.some(
        (series) => series.data[i] && series.data[i][1] > 0,
      );
      if (anySeriesHasANonZeroPointAtThisIndex) {
        totalNumberOfColumns++;
      }
    }
    return totalNumberOfColumns;
  }

  get shouldEnableHoverState() {
    if (this.viewConfig.shouldEnableHoverState || this.isIntervalHourly) {
      return true;
    }
    return this.totalNumberOfColumns > HOVER_STATE_THRESHOLD;
  }

  _tickIntervalForHourlyBreakdown() {
    if (this.range.interval === 'day') {
      // If number of days in range is <= 7, return half the normal tick interval
      // so that we have twice as many ticks and can render '12pm' on each alternate label
      if (this.range.inDays <= 7) {
        return TICK_INTERVAL_IN_MS.day / 2;
      } else {
        return TICK_INTERVAL_IN_MS.day;
      }
    } else {
      return TICK_INTERVAL_IN_MS[this.range.interval];
    }
  }
}
