/* RESPONSIBLE TEAM: team-frontend-tech */
import { helper as buildHelper } from '@ember/component/helper';
export default buildHelper(function (params) {
  let width = parseInt(params[0], 10);

  let span = document.createElement('span');
  span.setAttribute('class', 'ghost__text');
  span.setAttribute('style', `width: ${width}px;`);

  return span;
});
