/* RESPONSIBLE TEAM: team-frontend-tech */
import { helper as buildHelper } from '@ember/component/helper';
import dateAndTimeFormats from 'embercom/lib/date-and-time-formats';
import moment from 'moment-timezone';

let fullDateAndTimeHelper = function (params, options) {
  let timezone;
  if (typeof options !== 'undefined') {
    timezone = options.timezone;
  }

  let parsedDate = moment(params[0]);
  if (typeof timezone === 'undefined') {
    return parsedDate.format(dateAndTimeFormats.fullDateAndTime);
  }

  return parsedDate.tz(timezone).format(dateAndTimeFormats.fullDateAndTime);
};

let fullDateAndTimeRawHelper = function (dateTime, options) {
  return fullDateAndTimeHelper([dateTime], options.hash);
};

export { fullDateAndTimeRawHelper };
export default buildHelper(fullDateAndTimeHelper);
