/* RESPONSIBLE TEAM: team-frontend-tech */
import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

export default class FormatDuration extends Helper {
  @service declare intl: IntlService;

  compute(params: any /*, named: any*/) {
    return this.intl.formatDuration(params[0]);
  }
}
