/* RESPONSIBLE TEAM: team-frontend-tech */
import { helper as buildHelper } from '@ember/component/helper';
import { formatSeconds } from 'embercom/lib/duration-formatter';

function formatTime(seconds) {
  return formatSeconds(parseFloat(seconds));
}

function formatBucketRange([range]) {
  let [start, end] = range.split('-');
  if (start === '*') {
    return `< ${formatTime(end)}`;
  }
  if (end === '*') {
    return `> ${formatTime(start)}`;
  }
  return `${formatTime(start)} - ${formatTime(end)}`;
}

export default buildHelper(formatBucketRange);
