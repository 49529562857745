/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import {
  AVAILABLE_CHANNEL_MAPPINGS,
  AVAILABLE_CONTINENT_MAPPINGS,
  CONVERSATION_TYPE_MAPPINGS,
  INBOUND,
  INBOUND_TEXT,
  OUTBOUND_TEXT,
  REPORTING_FILTER_SELECT_ALL,
} from 'embercom/lib/reporting/flexible/constants';

const filtersConfig = {
  continents: 'Continent',
  countries: 'Country',
  channels: 'Channel',
  conversationTypes: 'Started by',
  scopingTagIds: 'Conversation tag',
  teammateId: 'Teammate',
  teamsParticipated: 'Team participated',
  botInboxTime: 'Bot inbox time',
  teammatesAssigned: 'Teammate assigned',
  teamsAssigned: 'Team assigned',
};

export function formatFilterDisplayName(filterName) {
  return filtersConfig[filterName] || filterName;
}

export function formatCustomAttributeDisplayName(customAttribute, conversationAttributeDescriptor) {
  return conversationAttributeDescriptor?.name || customAttribute;
}

const filterTypeFormatter = {
  channels: formatChannelValues,
  continents: formatContinentValues,
  scopingTagIds: formatTagValues,
  teamsAssigned: formatTeamValues,
  teamsParticipated: formatTeamValues,
  teammatesAssigned: formatTeammateValues,
  teammateId: formatTeammateValues,
  conversationTypes: formatConversationTypesValues,
};

function formatChannelValues(filterValues) {
  return filterValues.map((channel) => {
    return AVAILABLE_CHANNEL_MAPPINGS[channel] || channel;
  });
}

function formatContinentValues(filterValues) {
  return filterValues.map((continentId) => {
    return AVAILABLE_CONTINENT_MAPPINGS[continentId];
  });
}

function formatTagValues(filterValues, app, intl) {
  return filterValues.map((tagId) => {
    return tagId === REPORTING_FILTER_SELECT_ALL
      ? intl.t('components.reporting.custom.chart-builder.filter-bar.tag-filter.any')
      : app.tags.find((tag) => tag.id === tagId).name;
  });
}

function formatTeamValues(filterValues, app, intl) {
  let availableTeams = [app.unassignedAdmin].concat(app.teams);
  return filterValues
    .map((teamId) => {
      return teamId === REPORTING_FILTER_SELECT_ALL
        ? intl.t('components.reporting.custom.chart-builder.filter-bar.team-assigned-filter.any')
        : availableTeams.find((team) => team.id.toString() === teamId)?.display_as_assignee;
    })
    .filter((team) => team !== undefined && team !== null);
}

function formatTeammateValues(filterValues, app, intl) {
  let availableTeammates = [app.unassignedAdmin].concat(app.humanAdmins);
  return filterValues
    .map((teammateId) => {
      return teammateId === REPORTING_FILTER_SELECT_ALL
        ? intl.t(
            'components.reporting.custom.chart-builder.filter-bar.teammate-assigned-filter.any',
          )
        : availableTeammates.find((teammate) => teammate.id.toString() === teammateId)
            ?.display_as_assignee;
    })
    .filter((teammate) => teammate !== undefined && teammate !== null);
}

function formatConversationTypesValues(filterValues) {
  return filterValues.includes(CONVERSATION_TYPE_MAPPINGS[INBOUND][0])
    ? [INBOUND_TEXT]
    : [OUTBOUND_TEXT];
}

export function formatFilterDisplayValues(filterName, filterValues, app, intl) {
  return filterTypeFormatter[filterName]
    ? filterTypeFormatter[filterName](filterValues, app, intl)
    : filterValues;
}

const OPERATOR_LABEL_MAPPINGS = {
  category: 'is',
  not_in_category: 'is not',
  exists: 'has any value',
  not_exists: 'is unknown',
  true: 'is true',
  false: 'is false',
};

export function formatCustomAttributeDisplayValues(
  customAttributeValues,
  conversationAttributeDescriptor,
) {
  switch (customAttributeValues.operator) {
    case 'category':
    case 'not_in_category': {
      if (customAttributeValues.type === 'list') {
        return customAttributeValues.values.map(
          (value) =>
            `${OPERATOR_LABEL_MAPPINGS[customAttributeValues.operator]} ${
              conversationAttributeDescriptor.listOptions.find((option) => option.id === value)
                .label
            }`,
        );
      } else {
        return [OPERATOR_LABEL_MAPPINGS[customAttributeValues.values[0]]]; // boolean type true/false value mapping
      }
    }
    case 'exists':
    case 'not_exists':
      return [OPERATOR_LABEL_MAPPINGS[customAttributeValues.operator]];
  }
}
