/* RESPONSIBLE TEAM: team-frontend-tech */
import storage from 'embercom/vendor/intercom/storage';

const LOCAL_STORAGE_KEY = 'intercom-per-user-feature-flags';

export default {
  list() {
    let localFeatureFlags = storage.get(LOCAL_STORAGE_KEY);
    // The undefined check here is required in case storage fails
    return localFeatureFlags === null || localFeatureFlags === undefined ? [] : localFeatureFlags;
  },
  includes(featureKey) {
    return this.list().includes(featureKey);
  },
  add(featureKey) {
    let localFeatureFlags = this.list();
    if (localFeatureFlags.includes(featureKey)) {
      return;
    }
    localFeatureFlags.push(featureKey);
    storage.set(LOCAL_STORAGE_KEY, localFeatureFlags);
  },
  remove(featureKey) {
    let localFeatureFlags = this.list();
    if (!localFeatureFlags.includes(featureKey)) {
      return;
    }
    let foundIndex = localFeatureFlags.indexOf(featureKey);
    localFeatureFlags.splice(foundIndex, 1);
    storage.set(LOCAL_STORAGE_KEY, localFeatureFlags);
  },
};
