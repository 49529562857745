/* RESPONSIBLE TEAM: team-frontend-tech */
let excerpt = function (text, words) {
  return text
    .replace(/<(?:.|\n)*?>/gm, '')
    .trim()
    .split(' ')
    .slice(0, words)
    .join(' ');
};

export default excerpt;
