/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { indexBy } from 'underscore';

let Countries = {};

Countries.allCountries = [
  {
    code: 'US',
    name: 'United States', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.united_states',
    inEu: false,
  },
  {
    code: 'GB',
    name: 'United Kingdom', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.united_kingdom',
    inEu: true,
  },
  {
    code: 'AF',
    name: 'Afghanistan', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.afghanistan',
    inEu: false,
  },
  {
    code: 'AX',
    name: 'Åland Islands', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.aland_islands',
    inEu: false,
  },
  {
    code: 'AL',
    name: 'Albania', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.albania',
    inEu: false,
  },
  {
    code: 'DZ',
    name: 'Algeria', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.algeria',
    inEu: false,
  },
  {
    code: 'AS',
    name: 'American Samoa', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.american_samoa',
    inEu: false,
  },
  {
    code: 'AD',
    name: 'Andorra', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.andorra',
    inEu: false,
  },
  {
    code: 'AO',
    name: 'Angola', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.angola',
    inEu: false,
  },
  {
    code: 'AI',
    name: 'Anguilla', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.anguilla',
    inEu: false,
  },
  {
    code: 'AQ',
    name: 'Antarctica', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.antarctica',
    inEu: false,
  },
  {
    code: 'AG',
    name: 'Antigua and Barbuda', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.antigua_and_barbuda',
    inEu: false,
  },
  {
    code: 'AR',
    name: 'Argentina', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.argentina',
    inEu: false,
  },
  {
    code: 'AM',
    name: 'Armenia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.armenia',
    inEu: false,
  },
  {
    code: 'AW',
    name: 'Aruba', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.aruba',
    inEu: false,
  },
  {
    code: 'AU',
    name: 'Australia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.australia',
    inEu: false,
  },
  {
    code: 'AT',
    name: 'Austria', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.austria',
    inEu: true,
  },
  {
    code: 'AZ',
    name: 'Azerbaijan', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.azerbaijan',
    inEu: false,
  },
  {
    code: 'BS',
    name: 'Bahamas', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.bahamas',
    inEu: false,
  },
  {
    code: 'BH',
    name: 'Bahrain', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.bahrain',
    inEu: false,
  },
  {
    code: 'BD',
    name: 'Bangladesh', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.bangladesh',
    inEu: false,
  },
  {
    code: 'BB',
    name: 'Barbados', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.barbados',
    inEu: false,
  },
  {
    code: 'BY',
    name: 'Belarus', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.belarus',
    inEu: false,
  },
  {
    code: 'BE',
    name: 'Belgium', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.belgium',
    inEu: true,
  },
  {
    code: 'BZ',
    name: 'Belize', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.belize',
    inEu: false,
  },
  {
    code: 'BJ',
    name: 'Benin', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.benin',
    inEu: false,
  },
  {
    code: 'BM',
    name: 'Bermuda', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.bermuda',
    inEu: false,
  },
  {
    code: 'BT',
    name: 'Bhutan', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.bhutan',
    inEu: false,
  },
  {
    code: 'BO',
    name: 'Bolivia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.bolivia',
    inEu: false,
  },
  {
    code: 'BQ',
    name: 'Bonaire', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.bonaire',
    inEu: false,
  },
  {
    code: 'BA',
    name: 'Bosnia and Herzegovina', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.bosnia_and_herzegovina',
    inEu: false,
  },
  {
    code: 'BW',
    name: 'Botswana', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.botswana',
    inEu: false,
  },
  {
    code: 'BV',
    name: 'Bouvet Island', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.bouvet_island',
    inEu: false,
  },
  {
    code: 'BR',
    name: 'Brazil', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.brazil',
    inEu: false,
  },
  {
    code: 'IO',
    name: 'British Indian Ocean Territory', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.british_indian_ocean_territory',
    inEu: false,
  },
  {
    code: 'BN',
    name: 'Brunei', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.brunei',
    inEu: false,
  },
  {
    code: 'BG',
    name: 'Bulgaria', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.bulgaria',
    inEu: true,
  },
  {
    code: 'BF',
    name: 'Burkina Faso', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.burkina_faso',
    inEu: false,
  },
  {
    code: 'BI',
    name: 'Burundi', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.burundi',
    inEu: false,
  },
  {
    code: 'CV',
    name: 'Cape Verde', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.cape_verde',
    inEu: false,
  },
  {
    code: 'KH',
    name: 'Cambodia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.cambodia',
    inEu: false,
  },
  {
    code: 'CM',
    name: 'Cameroon', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.cameroon',
    inEu: false,
  },
  {
    code: 'CA',
    name: 'Canada', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.canada',
    inEu: false,
  },
  {
    code: 'KY',
    name: 'Cayman Islands', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.cayman_islands',
    inEu: false,
  },
  {
    code: 'CF',
    name: 'Central African Republic', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.central_african_republic',
    inEu: false,
  },
  {
    code: 'TD',
    name: 'Chad', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.chad',
    inEu: false,
  },
  {
    code: 'CL',
    name: 'Chile', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.chile',
    inEu: false,
  },
  {
    code: 'CN',
    name: 'China', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.china',
    inEu: false,
  },
  {
    code: 'CX',
    name: 'Christmas Island', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.christmas_island',
    inEu: false,
  },
  {
    code: 'CC',
    name: 'Cocos (Keeling) Islands', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.cocos_keeling_islands',
    inEu: false,
  },
  {
    code: 'CO',
    name: 'Colombia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.colombia',
    inEu: false,
  },
  {
    code: 'KM',
    name: 'Comoros', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.comoros',
    inEu: false,
  },
  {
    code: 'CG',
    name: 'Congo, (Congo Brazzaville)', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.congo_congo_brazzaville',
    inEu: false,
  },
  {
    code: 'CD',
    name: 'Congo, (Congo Kinshasa)', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.congo_congo_kinshasa',
    inEu: false,
  },
  {
    code: 'CK',
    name: 'Cook Islands', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.cook_islands',
    inEu: false,
  },
  {
    code: 'CR',
    name: 'Costa Rica', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.costa_rica',
    inEu: false,
  },
  {
    code: 'CI',
    name: "Côte d'Ivoire (Ivory Coast)", // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.cote_d_ivoire_ivory_coast',
    inEu: false,
  },
  {
    code: 'HR',
    name: 'Croatia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.croatia',
    inEu: true,
  },
  {
    code: 'CU',
    name: 'Cuba', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.cuba',
    inEu: false,
  },
  {
    code: 'CW',
    name: 'Curaçao', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.curacao',
    inEu: false,
  },
  {
    code: 'CY',
    name: 'Cyprus', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.cyprus',
    inEu: true,
  },
  {
    code: 'CZ',
    name: 'Czechia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.czechia',
    inEu: true,
  },
  {
    code: 'DK',
    name: 'Denmark', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.denmark',
    inEu: true,
  },
  {
    code: 'DJ',
    name: 'Djibouti', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.djibouti',
    inEu: false,
  },
  {
    code: 'DM',
    name: 'Dominica', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.dominica',
    inEu: false,
  },
  {
    code: 'DO',
    name: 'Dominican Republic', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.dominican_republic',
    inEu: false,
  },
  {
    code: 'EC',
    name: 'Ecuador', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.ecuador',
    inEu: false,
  },
  {
    code: 'EG',
    name: 'Egypt', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.egypt',
    inEu: false,
  },
  {
    code: 'SV',
    name: 'El Salvador', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.el_salvador',
    inEu: false,
  },
  {
    code: 'GQ',
    name: 'Equatorial Guinea', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.equatorial_guinea',
    inEu: false,
  },
  {
    code: 'ER',
    name: 'Eritrea', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.eritrea',
    inEu: false,
  },
  {
    code: 'EE',
    name: 'Estonia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.estonia',
    inEu: true,
  },
  {
    code: 'SZ',
    name: 'Eswatini', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.eswatini',
    inEu: false,
  },
  {
    code: 'ET',
    name: 'Ethiopia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.ethiopia',
    inEu: false,
  },
  {
    code: 'FK',
    name: 'Falkland Islands (Islas Malvinas)', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.falkland_islands_islas_malvinas',
    inEu: false,
  },
  {
    code: 'FO',
    name: 'Faroe Islands', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.faroe_islands',
    inEu: false,
  },
  {
    code: 'FJ',
    name: 'Fiji', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.fiji',
    inEu: false,
  },
  {
    code: 'FI',
    name: 'Finland', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.finland',
    inEu: true,
  },
  {
    code: 'FR',
    name: 'France', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.france',
    inEu: true,
  },
  {
    code: 'GF',
    name: 'French Guiana', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.french_guiana',
    inEu: false,
  },
  {
    code: 'PF',
    name: 'French Polynesia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.french_polynesia',
    inEu: false,
  },
  {
    code: 'TF',
    name: 'French Southern Territories', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.french_southern_territories',
    inEu: false,
  },
  {
    code: 'GA',
    name: 'Gabon', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.gabon',
    inEu: false,
  },
  {
    code: 'GM',
    name: 'Gambia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.gambia',
    inEu: false,
  },
  {
    code: 'GE',
    name: 'Georgia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.georgia',
    inEu: false,
  },
  {
    code: 'DE',
    name: 'Germany', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.germany',
    inEu: true,
  },
  {
    code: 'GH',
    name: 'Ghana', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.ghana',
    inEu: false,
  },
  {
    code: 'GI',
    name: 'Gibraltar', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.gibraltar',
    inEu: false,
  },
  {
    code: 'GR',
    name: 'Greece', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.greece',
    inEu: true,
  },
  {
    code: 'GL',
    name: 'Greenland', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.greenland',
    inEu: false,
  },
  {
    code: 'GD',
    name: 'Grenada', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.grenada',
    inEu: false,
  },
  {
    code: 'GP',
    name: 'Guadeloupe', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.guadeloupe',
    inEu: false,
  },
  {
    code: 'GU',
    name: 'Guam', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.guam',
    inEu: false,
  },
  {
    code: 'GT',
    name: 'Guatemala', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.guatemala',
    inEu: false,
  },
  {
    code: 'GG',
    name: 'Guernsey', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.guernsey',
    inEu: false,
  },
  {
    code: 'GN',
    name: 'Guinea', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.guinea',
    inEu: false,
  },
  {
    code: 'GW',
    name: 'Guinea-Bissau', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.guinea_bissau',
    inEu: false,
  },
  {
    code: 'GY',
    name: 'Guyana', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.guyana',
    inEu: false,
  },
  {
    code: 'HT',
    name: 'Haiti', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.haiti',
    inEu: false,
  },
  {
    code: 'HM',
    name: 'Heard Island and McDonald Islands', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.heard_island_and_mcDonald_islands',
    inEu: false,
  },
  {
    code: 'HN',
    name: 'Honduras', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.honduras',
    inEu: false,
  },
  {
    code: 'HK',
    name: 'Hong Kong', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.hong_kong',
    inEu: false,
  },
  {
    code: 'HU',
    name: 'Hungary', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.hungary',
    inEu: true,
  },
  {
    code: 'IS',
    name: 'Iceland', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.iceland',
    inEu: false,
  },
  {
    code: 'IN',
    name: 'India', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.india',
    inEu: false,
  },
  {
    code: 'ID',
    name: 'Indonesia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.indonesia',
    inEu: false,
  },
  {
    code: 'IR',
    name: 'Iran', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.iran',
    inEu: false,
  },
  {
    code: 'IQ',
    name: 'Iraq', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.iraq',
    inEu: false,
  },
  {
    code: 'IE',
    name: 'Ireland', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.ireland',
    inEu: true,
  },
  {
    code: 'IM',
    name: 'Isle of Man', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.isle_of_man',
    inEu: false,
  },
  {
    code: 'IL',
    name: 'Israel', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.israel',
    inEu: false,
  },
  {
    code: 'IT',
    name: 'Italy', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.italy',
    inEu: true,
  },
  {
    code: 'JM',
    name: 'Jamaica', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.jamaica',
    inEu: false,
  },
  {
    code: 'JP',
    name: 'Japan', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.japan',
    inEu: false,
  },
  {
    code: 'JE',
    name: 'Jersey', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.jersey',
    inEu: false,
  },
  {
    code: 'JO',
    name: 'Jordan', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.jordan',
    inEu: false,
  },
  {
    code: 'KZ',
    name: 'Kazakhstan', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.kazakhstan',
    inEu: false,
  },
  {
    code: 'KE',
    name: 'Kenya', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.kenya',
    inEu: false,
  },
  {
    code: 'KI',
    name: 'Kiribati', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.kiribati',
    inEu: false,
  },
  {
    code: 'KW',
    name: 'Kuwait', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.kuwait',
    inEu: false,
  },
  {
    code: 'KG',
    name: 'Kyrgyzstan', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.kyrgyzstan',
    inEu: false,
  },
  {
    code: 'LA',
    name: 'Laos', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.laos',
    inEu: false,
  },
  {
    code: 'LV',
    name: 'Latvia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.latvia',
    inEu: true,
  },
  {
    code: 'LB',
    name: 'Lebanon', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.lebanon',
    inEu: false,
  },
  {
    code: 'LS',
    name: 'Lesotho', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.lesotho',
    inEu: false,
  },
  {
    code: 'LR',
    name: 'Liberia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.liberia',
    inEu: false,
  },
  {
    code: 'LY',
    name: 'Libya', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.libya',
    inEu: false,
  },
  {
    code: 'LI',
    name: 'Liechtenstein', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.liechtenstein',
    inEu: false,
  },
  {
    code: 'LT',
    name: 'Lithuania', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.lithuania',
    inEu: true,
  },
  {
    code: 'LU',
    name: 'Luxembourg', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.luxembourg',
    inEu: true,
  },
  {
    code: 'MO',
    name: 'Macao', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.macao',
    inEu: false,
  },
  {
    code: 'MG',
    name: 'Madagascar', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.madagascar',
    inEu: false,
  },
  {
    code: 'MW',
    name: 'Malawi', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.malawi',
    inEu: false,
  },
  {
    code: 'MY',
    name: 'Malaysia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.malaysia',
    inEu: false,
  },
  {
    code: 'MV',
    name: 'Maldives', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.maldives',
    inEu: false,
  },
  {
    code: 'ML',
    name: 'Mali', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.mali',
    inEu: false,
  },
  {
    code: 'MT',
    name: 'Malta', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.malta',
    inEu: true,
  },
  {
    code: 'MH',
    name: 'Marshall Islands', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.marshall_islands',
    inEu: false,
  },
  {
    code: 'MQ',
    name: 'Martinique', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.martinique',
    inEu: false,
  },
  {
    code: 'MR',
    name: 'Mauritania', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.mauritania',
    inEu: false,
  },
  {
    code: 'MU',
    name: 'Mauritius', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.mauritius',
    inEu: false,
  },
  {
    code: 'YT',
    name: 'Mayotte', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.mayotte',
    inEu: false,
  },
  {
    code: 'MX',
    name: 'Mexico', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.mexico',
    inEu: false,
  },
  {
    code: 'FM',
    name: 'Micronesia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.micronesia',
    inEu: false,
  },
  {
    code: 'MD',
    name: 'Moldova', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.moldova',
    inEu: false,
  },
  {
    code: 'MC',
    name: 'Monaco', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.monaco',
    inEu: false,
  },
  {
    code: 'MN',
    name: 'Mongolia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.mongolia',
    inEu: false,
  },
  {
    code: 'ME',
    name: 'Montenegro', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.montenegro',
    inEu: false,
  },
  {
    code: 'MS',
    name: 'Montserrat', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.montserrat',
    inEu: false,
  },
  {
    code: 'MA',
    name: 'Morocco', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.morocco',
    inEu: false,
  },
  {
    code: 'MZ',
    name: 'Mozambique', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.mozambique',
    inEu: false,
  },
  {
    code: 'MM',
    name: 'Myanmar', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.myanmar',
    inEu: false,
  },
  {
    code: 'NA',
    name: 'Namibia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.namibia',
    inEu: false,
  },
  {
    code: 'NR',
    name: 'Nauru', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.nauru',
    inEu: false,
  },
  {
    code: 'NP',
    name: 'Nepal', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.nepal',
    inEu: false,
  },
  {
    code: 'NL',
    name: 'Netherlands', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.netherlands',
    inEu: true,
  },
  {
    code: 'NC',
    name: 'New Caledonia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.new_caledonia',
    inEu: false,
  },
  {
    code: 'NZ',
    name: 'New Zealand', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.new_zealand',
    inEu: false,
  },
  {
    code: 'NI',
    name: 'Nicaragua', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.nicaragua',
    inEu: false,
  },
  {
    code: 'NE',
    name: 'Niger', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.niger',
    inEu: false,
  },
  {
    code: 'NG',
    name: 'Nigeria', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.nigeria',
    inEu: false,
  },
  {
    code: 'NU',
    name: 'Niue', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.niue',
    inEu: false,
  },
  {
    code: 'NF',
    name: 'Norfolk Island', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.norfolk_island',
    inEu: false,
  },
  {
    code: 'KP',
    name: 'North Korea', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.north_korea',
    inEu: false,
  },
  {
    code: 'MK',
    name: 'North Macedonia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.north_macedonia',
    inEu: false,
  },
  {
    code: 'MP',
    name: 'Northern Mariana Islands', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.northern_mariana_islands',
    inEu: false,
  },
  {
    code: 'NO',
    name: 'Norway', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.norway',
    inEu: false,
  },
  {
    code: 'OM',
    name: 'Oman', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.oman',
    inEu: false,
  },
  {
    code: 'PK',
    name: 'Pakistan', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.pakistan',
    inEu: false,
  },
  {
    code: 'PW',
    name: 'Palau', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.palau',
    inEu: false,
  },
  {
    code: 'PS',
    name: 'Palestine', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.palestine',
    inEu: false,
  },
  {
    code: 'PA',
    name: 'Panama', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.panama',
    inEu: false,
  },
  {
    code: 'PG',
    name: 'Papua New Guinea', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.papua_new_guinea',
    inEu: false,
  },
  {
    code: 'PY',
    name: 'Paraguay', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.paraguay',
    inEu: false,
  },
  {
    code: 'PE',
    name: 'Peru', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.peru',
    inEu: false,
  },
  {
    code: 'PH',
    name: 'Philippines', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.philippines',
    inEu: false,
  },
  {
    code: 'PN',
    name: 'Pitcairn', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.pitcairn',
    inEu: false,
  },
  {
    code: 'PL',
    name: 'Poland', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.poland',
    inEu: true,
  },
  {
    code: 'PT',
    name: 'Portugal', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.portugal',
    inEu: true,
  },
  {
    code: 'PR',
    name: 'Puerto Rico', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.puerto_rico',
    inEu: false,
  },
  {
    code: 'QA',
    name: 'Qatar', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.qatar',
    inEu: false,
  },
  {
    code: 'RE',
    name: 'Réunion', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.reunion',
    inEu: false,
  },
  {
    code: 'RO',
    name: 'Romania', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.romania',
    inEu: true,
  },
  {
    code: 'RU',
    name: 'Russia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.russia',
    inEu: false,
  },
  {
    code: 'RW',
    name: 'Rwanda', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.rwanda',
    inEu: false,
  },
  {
    code: 'BL',
    name: 'Saint Barthélemy', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.saint_barthelemy',
    inEu: false,
  },
  {
    code: 'SH',
    name: 'Saint Helena, Ascension and Tristan da Cunha', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.saint_helena_ascension_and_tristan_da_cunha',
    inEu: false,
  },
  {
    code: 'KN',
    name: 'Saint Kitts and Nevis', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.saint_kitts_and_nevis',
    inEu: false,
  },
  {
    code: 'LC',
    name: 'Saint Lucia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.saint_lucia',
    inEu: false,
  },
  {
    code: 'MF',
    name: 'Saint Martin (French part)', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.saint_martin_french_part',
    inEu: false,
  },
  {
    code: 'PM',
    name: 'Saint Pierre and Miquelon', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.saint_pierre_and_miquelon',
    inEu: false,
  },
  {
    code: 'VC',
    name: 'Saint Vincent and the Grenadines', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.saint_vincent_and_the_grenadines',
    inEu: false,
  },
  {
    code: 'WS',
    name: 'Samoa', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.samoa',
    inEu: false,
  },
  {
    code: 'SM',
    name: 'San Marino', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.san_marino',
    inEu: false,
  },
  {
    code: 'ST',
    name: 'Sao Tome and Principe', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.sao_tome_and_principe',
    inEu: false,
  },
  {
    code: 'SA',
    name: 'Saudi Arabia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.saudi_arabia',
    inEu: false,
  },
  {
    code: 'SN',
    name: 'Senegal', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.senegal',
    inEu: false,
  },
  {
    code: 'RS',
    name: 'Serbia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.serbia',
    inEu: false,
  },
  {
    code: 'SC',
    name: 'Seychelles', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.seychelles',
    inEu: false,
  },
  {
    code: 'SL',
    name: 'Sierra Leone', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.sierra_leone',
    inEu: false,
  },
  {
    code: 'SG',
    name: 'Singapore', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.singapore',
    inEu: false,
  },
  {
    code: 'SX',
    name: 'Sint Maarten (Dutch part)', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.sint_maarten_dutch_part',
    inEu: false,
  },
  {
    code: 'SK',
    name: 'Slovakia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.slovakia',
    inEu: true,
  },
  {
    code: 'SI',
    name: 'Slovenia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.slovenia',
    inEu: true,
  },
  {
    code: 'SB',
    name: 'Solomon Islands', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.solomon_islands',
    inEu: false,
  },
  {
    code: 'SO',
    name: 'Somalia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.somalia',
    inEu: false,
  },
  {
    code: 'ZA',
    name: 'South Africa', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.south_africa',
    inEu: false,
  },
  {
    code: 'GS',
    name: 'South Georgia & South Sandwich Islands', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.south_georgia_and_south_sandwich_islands',
    inEu: false,
  },
  {
    code: 'KR',
    name: 'South Korea', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.south_korea',
    inEu: false,
  },
  {
    code: 'SS',
    name: 'South Sudan', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.south_sudan',
    inEu: false,
  },
  {
    code: 'ES',
    name: 'Spain', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.spain',
    inEu: true,
  },
  {
    code: 'LK',
    name: 'Sri Lanka', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.sri_lanka',
    inEu: false,
  },
  {
    code: 'SD',
    name: 'Sudan', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.sudan',
    inEu: false,
  },
  {
    code: 'SR',
    name: 'Suriname', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.suriname',
    inEu: false,
  },
  {
    code: 'SJ',
    name: 'Svalbard and Jan Mayen', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.svalbard_and_jan_mayen',
    inEu: false,
  },
  {
    code: 'SE',
    name: 'Sweden', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.sweden',
    inEu: true,
  },
  {
    code: 'CH',
    name: 'Switzerland', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.switzerland',
    inEu: false,
  },
  {
    code: 'SY',
    name: 'Syria', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.syria',
    inEu: false,
  },
  {
    code: 'TW',
    name: 'Taiwan', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.taiwan',
    inEu: false,
  },
  {
    code: 'TJ',
    name: 'Tajikistan', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.tajikistan',
    inEu: false,
  },
  {
    code: 'TZ',
    name: 'Tanzania', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.tanzania',
    inEu: false,
  },
  {
    code: 'TH',
    name: 'Thailand', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.thailand',
    inEu: false,
  },
  {
    code: 'TL',
    name: 'Timor-Leste', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.timor_leste',
    inEu: false,
  },
  {
    code: 'TG',
    name: 'Togo', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.togo',
    inEu: false,
  },
  {
    code: 'TK',
    name: 'Tokelau', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.tokelau',
    inEu: false,
  },
  {
    code: 'TO',
    name: 'Tonga', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.tonga',
    inEu: false,
  },
  {
    code: 'TT',
    name: 'Trinidad and Tobago', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.trinidad_and_tobago',
    inEu: false,
  },
  {
    code: 'TN',
    name: 'Tunisia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.tunisia',
    inEu: false,
  },
  {
    code: 'TR',
    name: 'Turkey', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.turkey',
    inEu: false,
  },
  {
    code: 'TM',
    name: 'Turkmenistan', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.turkmenistan',
    inEu: false,
  },
  {
    code: 'TC',
    name: 'Turks and Caicos Islands', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.turks_and_caicos_islands',
    inEu: false,
  },
  {
    code: 'TV',
    name: 'Tuvalu', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.tuvalu',
    inEu: false,
  },
  {
    code: 'UG',
    name: 'Uganda', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.uganda',
    inEu: false,
  },
  {
    code: 'UA',
    name: 'Ukraine', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.ukraine',
    inEu: false,
  },
  {
    code: 'AE',
    name: 'United Arab Emirates', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.united_arab_emirates',
    inEu: false,
  },
  {
    code: 'UM',
    name: 'United States Minor Outlying Islands', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.united_states_minor_outlying_islands',
    inEu: false,
  },
  {
    code: 'UY',
    name: 'Uruguay', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.uruguay',
    inEu: false,
  },
  {
    code: 'UZ',
    name: 'Uzbekistan', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.uzbekistan',
    inEu: false,
  },
  {
    code: 'VU',
    name: 'Vanuatu', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.vanuatu',
    inEu: false,
  },
  {
    code: 'VA',
    name: 'Vatican City', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.vatican_city',
    inEu: false,
  },
  {
    code: 'VE',
    name: 'Venezuela', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.venezuela',
    inEu: false,
  },
  {
    code: 'VN',
    name: 'Vietnam', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.vietnam',
    inEu: false,
  },
  {
    code: 'VG',
    name: 'Virgin Islands (British)', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.virgin_islands_british',
    inEu: false,
  },
  {
    code: 'VI',
    name: 'Virgin Islands (U.S.)', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.virgin_islands_us',
    inEu: false,
  },
  {
    code: 'WF',
    name: 'Wallis and Futuna', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.wallis_and_futuna',
    inEu: false,
  },
  {
    code: 'EH',
    name: 'Western Sahara', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.western_sahara',
    inEu: false,
  },
  {
    code: 'YE',
    name: 'Yemen', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.yemen',
    inEu: false,
  },
  {
    code: 'ZM',
    name: 'Zambia', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.zambia',
    inEu: false,
  },
  {
    code: 'ZW',
    name: 'Zimbabwe', // deprecated bare string in favour of below TranslationKey field
    nameTranslationKey: 'app.lib.countries.zimbabwe',
    inEu: false,
  },
];

const CODES_TO_COUNTRY = indexBy(Countries.allCountries, 'code');

export default Countries;

export function lookupCountryName(code) {
  let country = CODES_TO_COUNTRY[code];
  return country ? country.name : code || 'Unknown';
}
