/* RESPONSIBLE TEAM: team-frontend-tech */
import IntlFormatRelative from 'ember-intl/helpers/format-relative';
import { calculateRefreshSeconds, isUsingLegacyFormatRelativeApi } from 'embercom/services/intl';

export default class FormatRelative extends IntlFormatRelative {
  intervalId: number | null = null;

  compute(params: any, named: any) {
    if (!isUsingLegacyFormatRelativeApi(named)) {
      if (this.intervalId) {
        clearTimeout(this.intervalId);
      }

      let { autoRefresh = false, relativeTo } = named;

      if (autoRefresh && !relativeTo) {
        let refreshSeconds = calculateRefreshSeconds(params, named);

        if (refreshSeconds) {
          let intervalId: number = window.setTimeout(() => {
            this.recompute();
          }, refreshSeconds * 1000);

          this.intervalId = intervalId;
        }
      }
    }

    return super.compute(params, named);
  }

  willDestroy(): void {
    super.willDestroy();

    if (this.intervalId) {
      clearTimeout(this.intervalId);
    }
  }
}
