/* RESPONSIBLE TEAM: team-reporting */
const FILTERS = {
  isEndOfFunnel: {
    type: 'category',
    data: {
      property: 'end_of_funnel',
      values: [true],
    },
  },
  isSelfServeTried: {
    type: 'category',
    data: {
      property: 'self_serve_tried',
      values: [true],
    },
  },
  noSelfServeTried: {
    type: 'category',
    data: {
      property: 'self_serve_tried',
      values: [false],
    },
  },
  isSelfServeInteraction: {
    type: 'category',
    data: {
      property: 'interaction_type',
      values: ['bot_engaged', 'viewed_article'],
    },
  },
  isSelfServeOrHumanSupportInteraction: {
    type: 'category',
    data: {
      property: 'interaction_type',
      // we are deprecating assigned_to_human interaction type but old data still has this value, we'll keep it until the data is fixed
      values: [
        'bot_engaged',
        'viewed_article',
        'assigned_to_human',
        'served_by_human',
        'routed_to_team',
      ],
    },
  },
  isDropoffInteraction: {
    type: 'category',
    data: {
      property: 'interaction_type',
      values: ['viewed_help_center', 'searched_article'],
    },
  },
  botEngaged: {
    type: 'category',
    data: {
      property: 'interaction_type',
      values: ['bot_engaged'],
    },
  },
  viewedArticle: {
    type: 'category',
    data: {
      property: 'interaction_type',
      values: ['viewed_article'],
    },
  },
  servedByHuman: {
    type: 'category',
    data: {
      property: 'interaction_type',
      // we are deprecating assigned_to_human interaction type but old data still has this value, we'll keep it until the data is fixed
      values: ['assigned_to_human', 'served_by_human', 'routed_to_team'],
    },
  },
  amazingAndGreatRating: {
    type: 'category',
    data: {
      property: 'conversation_rating.rating_index',
      values: [4, 5],
    },
  },
  allConversationRating: {
    type: 'category',
    data: {
      property: 'conversation_rating.rating_index',
      values: [1, 2, 3, 4, 5],
    },
  },
};

const csfInteractionQuery = (...filters) => {
  return {
    source: 'csf_interaction',
    type: 'count',
    data: { property: 'interaction_created_at' },
    filter: {
      type: 'and',
      filters,
    },
  };
};

function officeHoursProperty(propertyName, excludeOooHours = false) {
  return excludeOooHours ? `${propertyName}_in_office_hours` : propertyName;
}

const csfInteractionMedianResponseTimeQuery = (excludeOooHours, filters) => {
  return {
    source: 'csf_interaction',
    type: 'median',
    data: {
      property: officeHoursProperty('conversation.first_response_time', excludeOooHours),
    },
    filter: {
      type: 'and',
      filters,
    },
  };
};

const csfInteractionMedianCloseTimeQuery = (excludeOooHours, filters) => {
  return {
    source: 'csf_interaction',
    type: 'median',
    data: {
      property: officeHoursProperty(
        'conversation.time_to_close_excluding_bot_inbox',
        excludeOooHours,
      ),
    },
    filter: {
      type: 'and',
      filters,
    },
  };
};

const conversationRatingsQuery = (...filters) => {
  return {
    type: 'count',
    source: 'csf_interaction',
    data: { property: 'interaction_created_at' },
    groups: [
      {
        type: 'term',
        aggregations: [{ type: 'count', data: { property: 'conversation_rating.rating_index' } }],
      },
    ],
    filter: {
      type: 'and',
      filters,
    },
  };
};

export const ALL_DIRECT_TO_HUMAN_CONVERSATION_RATINGS = conversationRatingsQuery(
  FILTERS.servedByHuman,
  FILTERS.isEndOfFunnel,
  FILTERS.noSelfServeTried,
);

export const ALL_SELF_SERVE_FAILED_CONVERSATION_RATINGS = conversationRatingsQuery(
  FILTERS.servedByHuman,
  FILTERS.isEndOfFunnel,
  FILTERS.isSelfServeTried,
);

export const SEEKING_SUPPORT = csfInteractionQuery(
  FILTERS.isSelfServeOrHumanSupportInteraction,
  FILTERS.isEndOfFunnel,
);

export const SELF_SERVE_ENGAGED = csfInteractionQuery(
  FILTERS.isSelfServeTried,
  FILTERS.isEndOfFunnel,
);

export const SELF_SERVE_RESOLVED = csfInteractionQuery(
  FILTERS.isSelfServeInteraction,
  FILTERS.isEndOfFunnel,
);

export const SERVED_BY_HUMAN = csfInteractionQuery(FILTERS.servedByHuman, FILTERS.isEndOfFunnel);

export const DROPOFFS = csfInteractionQuery(FILTERS.isDropoffInteraction, FILTERS.isEndOfFunnel);

export const RESOLUTION_BOT_RESOLVED = csfInteractionQuery(
  FILTERS.isEndOfFunnel,
  FILTERS.botEngaged,
);

export const ARTICLE_RESOLVED = csfInteractionQuery(FILTERS.isEndOfFunnel, FILTERS.viewedArticle);

export const ARTICLE_VIEWS = csfInteractionQuery(FILTERS.viewedArticle);

export const RESOLUTION_BOT_ENGAGED = csfInteractionQuery(FILTERS.botEngaged);

export const DIRECT_TO_HUMAN = csfInteractionQuery(
  FILTERS.servedByHuman,
  FILTERS.isEndOfFunnel,
  FILTERS.noSelfServeTried,
);

export const DIRECT_TO_HUMAN_MEDIAN_FIRST_RESPONSE_TIME = (excludeOooHours) =>
  csfInteractionMedianResponseTimeQuery(excludeOooHours, [
    FILTERS.servedByHuman,
    FILTERS.isEndOfFunnel,
    FILTERS.noSelfServeTried,
  ]);

export const DIRECT_TO_HUMAN_MEDIAN_TIME_TO_CLOSE = (excludeOooHours) =>
  csfInteractionMedianCloseTimeQuery(excludeOooHours, [
    FILTERS.servedByHuman,
    FILTERS.isEndOfFunnel,
    FILTERS.noSelfServeTried,
  ]);

export const DIRECT_TO_HUMAN_CONVERSATION_RATING_ALL = csfInteractionQuery(
  FILTERS.servedByHuman,
  FILTERS.isEndOfFunnel,
  FILTERS.noSelfServeTried,
  FILTERS.allConversationRating,
);

export const DIRECT_TO_HUMAN_AMAZING_GREAT_CONVERSATION_RATING = csfInteractionQuery(
  FILTERS.servedByHuman,
  FILTERS.isEndOfFunnel,
  FILTERS.noSelfServeTried,
  FILTERS.amazingAndGreatRating,
);

export const SELF_SERVE_FAILED = csfInteractionQuery(
  FILTERS.servedByHuman,
  FILTERS.isEndOfFunnel,
  FILTERS.isSelfServeTried,
);

export const SELF_SERVE_FAILED_MEDIAN_FIRST_RESPONSE_TIME = (excludeOooHours) =>
  csfInteractionMedianResponseTimeQuery(excludeOooHours, [
    FILTERS.servedByHuman,
    FILTERS.isEndOfFunnel,
    FILTERS.isSelfServeTried,
  ]);

export const SELF_SERVE_FAILED_MEDIAN_TIME_TO_CLOSE = (excludeOooHours) =>
  csfInteractionMedianCloseTimeQuery(excludeOooHours, [
    FILTERS.servedByHuman,
    FILTERS.isEndOfFunnel,
    FILTERS.isSelfServeTried,
  ]);

export const SELF_SERVE_FAILED_CONVERSATION_RATING_ALL = csfInteractionQuery(
  FILTERS.servedByHuman,
  FILTERS.isEndOfFunnel,
  FILTERS.isSelfServeTried,
  FILTERS.allConversationRating,
);

export const SELF_SERVE_FAILED_AMAZING_GREAT_CONVERSATION_RATING = csfInteractionQuery(
  FILTERS.servedByHuman,
  FILTERS.isEndOfFunnel,
  FILTERS.isSelfServeTried,
  FILTERS.amazingAndGreatRating,
);

export const buildProactiveMessagesQuery = (name, stats, contentIds, range) => {
  return {
    name,
    source: 'events',
    type: 'count',
    data: { property: 'event.created_at' },
    filter: {
      type: 'and',
      filters: [
        {
          type: 'category',
          data: {
            property: 'event.type',
            values: stats,
          },
        },
        {
          type: 'category',
          data: {
            property: 'stat_receipt.ruleset_id',
            values: contentIds,
          },
        },
      ],
    },
    time: {
      property: 'event.created_at',
      start: range.startMoment.valueOf(),
      end: range.endMomentButNotInTheFuture.valueOf(),
    },
  };
};
