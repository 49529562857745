/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

export default class PurchaseAnalyticsEventHelper extends Helper {
  @service purchaseAnalyticsService;

  compute(_positionalParams, namedParams) {
    return () => {
      this.purchaseAnalyticsService.trackEvent(namedParams);
    };
  }
}
