/* RESPONSIBLE TEAM: team-ai-agent */
const ANSWERS_INDEX_PAGEVIEW_KEY = 'answer_bot_suggested_questions_first_load';
const IMPORT_BANNER_PAGEVIEW_KEY = 'conversation_import_prompt_banner';

const INBOX_PAGEVIEW_KEY = 'inbox_page';

const FIN_LANDING_PAGE_PAGEVIEW_KEY = 'fin_landing_page';
const RESOLUTION_BOT_REQUIRED_PERMISSION = 'can_create_and_edit_bots';

const ONE_TIME_MODE = 'one_time';
const LOOPING_MODE = 'looping';
const BOT_ONLY_MODE = 'bot_only';

const HAS_ANSWER_STATE = 'has-an-answer';
const NO_ANSWER_STATE = 'no-answer';

const ALL_BOT_MODES = [ONE_TIME_MODE, LOOPING_MODE, BOT_ONLY_MODE];
const ALL_ANSWER_STATES = [HAS_ANSWER_STATE, NO_ANSWER_STATE];

const QUICK_REPLY_ID = {
  ALL_DONE: 'all_done',
  WAIT_FOR_THE_TEAM: 'wait_for_the_team',
  MORE_ANSWERS: 'more_answers',
  GET_MORE_HELP: 'get_more_help',
};

const AVERAGE_RESOLUTION_RATE = 33;

const EMPTY_SUGGESTED_ANSWER_CLUSTER_IDS = { cluster_ids: [], dismissed_cluster_ids: [] };

const FIN_CSAT_COMPATIBLE_CHANNELS = ['web', 'ios', 'android', 'whatsapp', 'email'];

const AI_AGENT_EMAIL_TEMPLATE_ID = '2017';

export {
  IMPORT_BANNER_PAGEVIEW_KEY,
  ANSWERS_INDEX_PAGEVIEW_KEY,
  FIN_LANDING_PAGE_PAGEVIEW_KEY,
  EMPTY_SUGGESTED_ANSWER_CLUSTER_IDS,
  RESOLUTION_BOT_REQUIRED_PERMISSION,
  ONE_TIME_MODE,
  LOOPING_MODE,
  BOT_ONLY_MODE,
  HAS_ANSWER_STATE,
  NO_ANSWER_STATE,
  ALL_BOT_MODES,
  ALL_ANSWER_STATES,
  QUICK_REPLY_ID,
  AVERAGE_RESOLUTION_RATE,
  FIN_CSAT_COMPATIBLE_CHANNELS,
  AI_AGENT_EMAIL_TEMPLATE_ID,
  INBOX_PAGEVIEW_KEY,
};
