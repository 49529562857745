/* RESPONSIBLE TEAM: team-frontend-tech */
import { and, notEmpty } from '@ember/object/computed';
import DefaultPredicateManager from 'embercom/lib/common/predicates/default-predicate-manager';
import FiltersToText from 'embercom/lib/filters-to-text';

export default DefaultPredicateManager.extend({
  isValid: and('predicate.hasComparison', 'urlNotEmpty', 'countNotEmpty'),
  urlNotEmpty: notEmpty('predicate.value.url'),
  countNotEmpty: notEmpty('predicate.value.count'),

  initializeDefaultValue() {
    this.set('predicate.value', this.getDefaultValue());
  },

  getDefaultValue() {
    return {
      url: '',
      count: '',
    };
  },

  _getDescription(customFilterMap, showNoEventPrefix) {
    let descriptionPrefix = this.get('predicate.info')
      ? this.get('predicate.info.name').toLowerCase()
      : this.get('predicate.attributeIdentifier');
    return {
      text: `${descriptionPrefix} for ${this.get(
        'predicate.value.url',
      )} ${FiltersToText.getPredicateDescription(
        this.predicate,
        customFilterMap,
        showNoEventPrefix,
      )}`,
      icon: this.get('predicate.info.icon'),
    };
  },
});
