/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import EmberObject from '@ember/object';
import Conversation from 'embercom/models/conversation';
import { inject as service } from '@ember/service';

export default EmberObject.extend({
  inbox: null,
  store: service(),

  doSearch(params) {
    return this.loadConversations(params);
  },

  loadConversations(params) {
    return Conversation.newSearch(params).then((response) => {
      this.store.pushPayload({
        conversations: response.conversations,
      });

      let conversationModels = response.conversations.map(({ id }) =>
        this.store.peekRecord('conversation', id),
      );

      if (response.parts) {
        this._loadSideloadedParts(response.parts);
      }

      let result = EmberObject.create({
        conversations: conversationModels,
        validFor: response.valid_for,
      });

      if (response.total_count !== undefined) {
        result.set('totalCount', response.total_count);
      }

      if (response.next_page !== undefined) {
        result.set('nextPage', response.next_page);
      }

      return result;
    });
  },

  _loadSideloadedParts(responseParts) {
    let conversationIds = Object.keys(responseParts);
    conversationIds.forEach((conversationId) => {
      let jsonParts = responseParts[conversationId];
      this.store.pushPayload({ 'conversation-parts': jsonParts });
      let parts = jsonParts.map(({ id }) => this.store.peekRecord('conversation-part', id));
      let referenceConversation = this.store.peekRecord('conversation', conversationId);

      referenceConversation.addParts(parts);
    });
  },
});
