/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import ArrayProxy from '@ember/array/proxy';
import LogsSettingsModel from 'embercom/models/logs-settings';
import { task } from 'ember-concurrency';

export default ArrayProxy.extend({
  appId: undefined,
  failedLoading: false,
  loadBetweenDates: task(function* (from, to) {
    this.set('failedLoading', false);

    try {
      let data = yield LogsSettingsModel.getLogsSettingsBetweenDates(this.appId, from, to);
      this.setObjects(data);
    } catch (e) {
      this.set('failedLoading', true);
    }
  }).restartable(),
  loadByImpersonationId: task(function* (impersonation_id) {
    this.set('failedLoading', false);

    try {
      let data = yield LogsSettingsModel.getLogsSettingsByImpersonationId(
        this.appId,
        impersonation_id,
      );
      this.setObjects(data);
    } catch (e) {
      this.set('failedLoading', true);
    }
  }).restartable(),
});
