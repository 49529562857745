/* RESPONSIBLE TEAM: team-proactive-support */
import { helper as buildHelper } from '@ember/component/helper';
import IndexToAlphaChar from 'embercom/lib/index-to-alpha-char';

export function indexToAlphaChar(params) {
  let index = params[0];
  return IndexToAlphaChar(index);
}

export default buildHelper(indexToAlphaChar);
