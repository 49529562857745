/* RESPONSIBLE TEAM: team-frontend-tech */
import Admin from 'embercom/models/admin';
import App from 'embercom/models/app';
import ENV from 'embercom/config/environment';
import ModelCacheHelper from 'embercom/lib/model-cache/helper';

export function initialize(applicationInstance) {
  let modelDataCacheService = applicationInstance.lookup('service:modelDataCacheService');
  let loggingEnabled = ModelCacheHelper.loggingEnabled();
  modelDataCacheService.configure({ loggingEnabled });

  if (ModelCacheHelper.shouldClearCache()) {
    modelDataCacheService.clear();
  }

  modelDataCacheService.register('admin', {
    fetch: Admin.fetchJSON,
    load: Admin.loadJSON,
    refreshDelay: ENV.APP._5000MS,
    fingerprint: ModelCacheHelper.adminModelFingerprint(),
    loggingEnabled: ModelCacheHelper.loggingEnabled(),
    disabled: ModelCacheHelper.isDisabled(),
  });

  modelDataCacheService.register('app', {
    fetch: App.fetchJSON,
    load: App.loadJSON,
    refreshDelay: ENV.APP._5000MS,
    fingerprint: ModelCacheHelper.appModelFingerprint(),
    loggingEnabled: ModelCacheHelper.loggingEnabled(),
    disabled: ModelCacheHelper.isDisabled(),
  });
}

export default {
  name: 'modelDataCache',
  initialize,
};
