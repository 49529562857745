/* RESPONSIBLE TEAM: team-reporting */
import { inject as service } from '@ember/service';
import { setOwner } from '@ember/application';
import Range from 'embercom/models/reporting/range';
import { REPORT_TEMPLATES } from 'embercom/lib/reporting/custom/report-template-helper';

export default class ReportTemplateBuilder {
  @service store;
  @service appService;
  @service intl;
  @service reportingTemplates;
  @service reportingMetrics;

  constructor(owner, templateKey, report) {
    setOwner(this, owner);
    this.templateKey = templateKey;
    this.report = report || this.store.createRecord('reporting/custom/report');
  }

  reportTemplates() {
    return REPORT_TEMPLATES;
  }

  buildTemplate() {
    if (this.appService.app.canSeeR2Beta) {
      return this.buildR2Template();
    }

    let template = this.reportTemplates()[this.templateKey];
    if (!template) {
      throw new Error(`Unknown template key: ${this.templateKey}`);
    }

    let builtCharts = this.buildCharts(template.charts);
    let report = this.buildReport(template.report, builtCharts, template.reportState.filters);
    template.reportState.dateRange = Range.createFromPreset(
      template.reportState.dateRangePeriod,
      this.appService.app.timezone,
    );

    return {
      report,
      reportState: template.reportState,
    };
  }

  buildCharts(charts) {
    let chartModels = [];
    charts.forEach((chart) => {
      let chartModel = this.store.createRecord('reporting/custom/chart', {
        ...chart,
      });
      let metricId = this.reportingMetrics.getMetricById(chart.chartSeries[0].metricId).id; // allow for migration to new metric ids
      chartModel.updateMetric(metricId, chartModel.chartSeries.firstObject);
      chartModels.push(chartModel);
    });
    return chartModels;
  }

  buildReport(report, charts, filters) {
    (this.report.title = this.intl.t(report.title)),
      (this.report.charts = charts),
      (this.report.filters = filters);
    return this.report;
  }

  // TODO: When reporting-r2-beta is globally enabled, rename these methods to remove the "R2" prefix
  buildR2Template() {
    let template = this.reportingTemplates.reportTemplatesById[this.templateKey];
    let charts = this.buildR2Charts(template.charts);
    let dateRange = Range.createFromPreset('past_4_weeks', this.appService.app.timezone);
    let reportState = {
      settings: {},
      filters: template.filters,
      isTemplate: true,
      dateRange,
    };
    let report = this.buildR2Report(template, charts, template.filters, dateRange);

    return { report, reportState };
  }

  buildR2Charts(charts) {
    return charts.map((chart) => {
      let chartModel = this.store.createRecord('reporting/custom/chart', chart);
      chartModel.set('chartSeries', chart.chartSeries);
      return chartModel;
    });
  }

  buildR2Report(report, charts, filters, dateRange) {
    (this.report.title = report.title),
      (this.report.charts = charts),
      (this.report.filters = filters),
      (this.report.dateRange = this.store.createFragment('reporting/custom/date-range', {
        selection: dateRange.selectedRange,
      }));
    return this.report;
  }
}
