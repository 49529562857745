/* RESPONSIBLE TEAM: team-frontend-tech */
// Based on Honeycomb's owm page load instrumentation:
// https://www.honeycomb.io/blog/instrumenting-browser-page-loads-at-honeycomb/
export const capturePageLoadStats = function () {
  let nt = window.performance?.timing;
  let stats = {
    // User agent. We can parse the user agent into device, os name, os version,
    // browser name, and browser version fields server-side if we want to later.
    user_agent: window.navigator?.userAgent,
    // Current window size & screen size stats
    // We use a derived column in Honeycomb to also be able to query window
    // total pixels and the ratio of window size to screen size. That way we
    // can understand whether users are making their window as large as they can
    // to try to fit Honeycomb content on screen, or whether they find a smaller
    // window size more comfortable.
    //
    // Capture how large the user has made their current window
    window_height: window.innerHeight,
    window_width: window.innerWidth,
    // Capture how large the user's entire screen is
    screen_height: window.screen?.height,
    screen_width: window.screen?.width,
    device_pixel_ratio: window.devicePixelRatio,
    // Chrome-only (for now) information on internet connection type (4g, wifi, etc.)
    // https://developers.google.com/web/updates/2017/10/nic62
    connection_type: navigator.connection?.type,
    connection_type_effective: navigator.connection?.effectiveType,
    connection_rtt: navigator.connection?.rtt,
    // Navigation (page load) timings, transformed from timestamps into deltas
    timing_unload_ms: nt.unloadEnd - nt.navigationStart,
    timing_dns_end_ms: nt.domainLookupEnd - nt.navigationStart,
    timing_ssl_end_ms: nt.connectEnd - nt.navigationStart,
    timing_response_end_ms: nt.responseEnd - nt.navigationStart,
    timing_dom_interactive_ms: nt.domInteractive - nt.navigationStart,
    timing_dom_complete_ms: nt.domComplete - nt.navigationStart,
    timing_dom_loaded_ms: nt.loadEventEnd - nt.navigationStart,
    timing_ms_first_paint: nt.msfirstpaint - nt.navigationStart, // nonstandard ie/edge-only first paint
    // Some calculated navigation timing durations, for easier graphing in Honeycomb
    // We could also use a derived column to do these calculations in the UI
    // from the above fields if we wanted to keep our event payload smaller.
    timing_dns_duration_ms: nt.domainLookupEnd - nt.domainLookupStart,
    timing_ssl_duration_ms: nt.connectEnd - nt.connectStart,
    timing_server_duration_ms: nt.responseEnd - nt.requestStart,
    timing_dom_loaded_duration_ms: nt.loadEventEnd - nt.domComplete,
    // Entire page load duration
    timing_total_duration_ms: nt.loadEventEnd - nt.connectStart,
  };

  let hasPerfTimeline = Boolean(window.performance?.getEntriesByType);
  if (hasPerfTimeline) {
    // First paint data via PerformancePaintTiming (Chrome only for now)
    window.performance.getEntriesByType('paint').forEach(function (paintPerformanceEntry) {
      if (paintPerformanceEntry.name === 'first-paint') {
        stats.timing_first_paint_ms = Math.round(paintPerformanceEntry.startTime);
      } else if (paintPerformanceEntry.name === 'first-contentful-paint') {
        stats.timing_first_contentful_paint_ms = Math.round(paintPerformanceEntry.startTime);
      }
    });
    window.performance
      .getEntriesByType('navigation')
      .forEach(function (navigationPerformanceEntry) {
        if (navigationPerformanceEntry.name === window.location.href) {
          stats.timing_dom_interactive_ms = Math.round(navigationPerformanceEntry.domInteractive);
          stats.timing_dom_complete_ms = Math.round(navigationPerformanceEntry.domComplete);
          stats.timing_dom_loaded_ms = Math.round(
            navigationPerformanceEntry.domContentLoadedEventEnd,
          );
        }
      });
  }

  // Redirect count (inconsistent browser support)
  // Find out if the user was redirected on their way to landing on this page,
  // so we can have visibility into whether redirects are slowing down the experience
  stats.redirect_count = window.performance?.navigation?.redirectCount;

  // Remove outliers caused by API calls returning partial page load
  // performance data so that they don't skew queries with nonsense data
  Object.keys(stats).forEach((statKey) => {
    if (typeof stats[statKey] === 'number' && (stats[statKey] < 0 || isNaN(stats[statKey]))) {
      delete stats[statKey];
    }
  });

  return stats;
};
