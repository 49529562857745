/* RESPONSIBLE TEAM: team-frontend-tech */
import { ternary, ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import DefaultPredicateManager from 'embercom/lib/common/predicates/default-predicate-manager';

export default DefaultPredicateManager.extend({
  isValid: ternaryToProperty(
    'predicate.isKnownOrUnknownComparison',
    'isValidForNoValue',
    'isValidForValue',
  ),
  goalComparison: ternary('predicate.isUserEventPredicate', 'triggered', 'known'),

  changeComparison(comparison) {
    this.setProperties({
      'predicate.comparison': comparison.replace(/:(relative|absolute)/, ''),
      'predicate.value': this._intializeValueForComparison(comparison),
    });
  },
  initializeDefaultValue() {
    if (this.get('predicate.isKnownOrUnknownComparison')) {
      this.set('predicate.value', null);
    } else if (this.get('predicate.hasNoValue')) {
      this.set('predicate.value', '');
    }
  },
  _makeCurrentDateForAbsoluteComparison() {
    let date = new Date();
    return {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
  },

  _intializeValueForComparison(comparison) {
    if (comparison === 'known' || comparison === 'unknown') {
      return null;
    } else if (
      comparison.endsWith('absolute') &&
      (this.get('predicate.valueIsString') || this.get('predicate.valueIsNull'))
    ) {
      return this._makeCurrentDateForAbsoluteComparison();
    } else if (
      comparison.endsWith('relative') &&
      (this.get('predicate.valueIsObject') || this.get('predicate.valueIsNull'))
    ) {
      return '';
    } else {
      return this.get('predicate.value');
    }
  },
});
