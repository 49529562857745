/* RESPONSIBLE TEAM: team-workflows */

import { APP_TEMPLATE_WORKFLOWS } from 'embercom/lib/operator/custom-bots/constants';

export const WORKFLOWS_DISCOVERABILITY_END_USER_EXPERIENCE = {
  id: 8,
  translationKey: 'end-user-experience',
  color: 'orange',
  showcaseIds: ['2008', '2013', '2012', '2009'],
};

export const WORKFLOWS_DISCOVERABILITY_TEAMMATE_EFFICIENCY = {
  id: 9,
  translationKey: 'teammate-efficiency',
  color: 'blue',
  showcaseIds: ['2004', '2028', '2010', '2011'],
};

const WORKFLOWS_DISCOVERABILITY_FIN_WORKFLOWS = {
  id: 11,
  translationKey: 'fin-workflows-wd',
  color: 'fin-wd',
  showcaseIds: ['2014', '2016', '2015', '2017'],
};

export const WORKFLOWS_DISCOVERABILITY_PROACTIVE_SUPPORT = {
  id: 10,
  translationKey: 'proactive-support',
  color: 'mint',
  showcaseIds: ['2001'],
};

interface WDTemplateGroup {
  id: number;
  translationKey: string;
  color: string;
  showcaseIds?: string[];
}

const WORKFLOWS_DISCOVERABILITY_TEMPLATE_GROUPS: WDTemplateGroup[] = [
  WORKFLOWS_DISCOVERABILITY_TEAMMATE_EFFICIENCY,
  WORKFLOWS_DISCOVERABILITY_END_USER_EXPERIENCE,
  WORKFLOWS_DISCOVERABILITY_FIN_WORKFLOWS,
  WORKFLOWS_DISCOVERABILITY_PROACTIVE_SUPPORT,
  APP_TEMPLATE_WORKFLOWS,
];

export { WORKFLOWS_DISCOVERABILITY_TEMPLATE_GROUPS };
