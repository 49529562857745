/* RESPONSIBLE TEAM: team-reporting */
import {
  buildFiltersForDataConfig,
  buildAxesForDataConfig,
  timeDataModel,
  getApplyLimitOnServer,
} from 'embercom/lib/reporting/custom/data-config-builder-helpers';
import { TEAM_TEAMMATE_GROUPING_MULTIMETRIC } from 'embercom/models/reporting/custom/visualization-options';

export default class DataConfigBuilder {
  constructor(renderableChart) {
    this.renderableChart = renderableChart;
  }

  buildDataConfig() {
    let dataConfig = {
      series: this.configForMultiMetrics,
    };

    let { xAxis, yAxis } = buildAxesForDataConfig(this.renderableChart);
    if (xAxis) {
      dataConfig.xAxis = xAxis;
    }
    if (yAxis) {
      dataConfig.yAxis = yAxis;
    }

    return dataConfig;
  }

  get configForMultiMetrics() {
    let timezone = this.renderableChart.reportState?.timezone;
    let supportsViewByTerms = this.renderableChart.supportsFeature(
      TEAM_TEAMMATE_GROUPING_MULTIMETRIC,
    );
    return this.renderableChart.chartSeries
      .toArray()
      .flatMap(({ metric: parentMetric, filters, aggregation, percentileValue }, index) => {
        return parentMetric.allFieldMetrics.map((childMetric) => {
          return this.buildSeriesConfig({
            name: childMetric.id,
            type: aggregation || childMetric.defaultAggregation,
            percentileValue,
            property: childMetric.property,
            timeProperty: childMetric.timeProperty,
            source: childMetric.source,
            metric: childMetric,
            filters,
            parentMetric,
            timezone,
            allowUnlimitedBuckets: supportsViewByTerms && index > 0,
          });
        });
      });
  }

  buildSeriesConfig({
    name,
    type,
    percentileValue,
    property,
    timeProperty,
    source,
    metric,
    filters,
    parentMetric,
    timezone,
    allowUnlimitedBuckets,
  }) {
    let dataModel = timeDataModel(property);
    return {
      name,
      source,
      type,
      data: {
        property,
        percentileValue,
      },
      filter: buildFiltersForDataConfig(this.renderableChart, metric, parentMetric, filters),
      time: {
        start: this.renderableChart.dateRange.startMoment.valueOf(),
        end: this.renderableChart.dateRange.endMoment.valueOf(),
        interval: this.renderableChart.dateRange.interval,
        property: timeProperty,
        ...(dataModel && { data_model: dataModel }),
        timezone,
      },
      metric,
      applyLimitOnServer: getApplyLimitOnServer(parentMetric, allowUnlimitedBuckets),
    };
  }
}
