/* RESPONSIBLE TEAM: team-product-guidance */
import ENV from 'embercom/config/environment';

const DEFAULT_DELAY = ENV.APP._1000MS;

async function scrollToLevel(levelIdentifier, delay = DEFAULT_DELAY) {
  await new Promise((resolve) => setTimeout(resolve, delay));
  let element = document.querySelector(`[data-accordion-section=${levelIdentifier}]`);
  if (element && shouldScrollToElement(element)) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}

function shouldScrollToElement(element) {
  let middleOfScreen = window.innerHeight / 2;
  let topOfLevel = element.getBoundingClientRect().top;
  return topOfLevel > middleOfScreen || topOfLevel < 0;
}

export default {
  scrollToLevel,
};
