/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
export function messageControlGroupCreateSuccess() {
  return `Control group created`;
}

export function messageControlGroupCreateFailure() {
  return `We couldn't create the control group. Send us a message and we'll do our best to help out.`;
}
