/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
export default {
  STANDARD_SCOPES: [
    {
      key: 'write_users_companies',
      name: 'Write users and companies',
      description: 'Create and update users and companies',
    },
    {
      key: 'write_tags',
      name: 'Write tags',
      description: 'Create, update, use and delete tags',
    },
    {
      key: 'write_events',
      name: 'Write events',
      description: 'Submit events (i.e. user activity)',
    },
    {
      key: 'write_conversations',
      name: 'Write conversations',
      description: 'Reply to, mark as read and close conversations',
    },
    {
      key: 'read_single_user',
      name: 'Read one user and one company',
      description: 'List and view a single user and company',
    },
    {
      key: 'read_single_admin',
      name: 'Read one admin',
      description: 'View a single admin',
    },
    {
      key: 'read_events',
      name: 'Read events',
      description: 'List all events belonging to a single user',
    },
    {
      key: 'read_tags',
      name: 'Read tags',
      description: 'List all tags',
    },
    {
      key: 'read_counts',
      name: 'Read counts',
      description: 'Count users and companies with specified criteria',
    },
  ],

  ADVANCED_SCOPES: [
    {
      key: 'read_write_users',
      name: 'Read and write users',
      description: 'List all users, create custom attributes and execute bulk actions',
    },
    {
      key: 'read_users_companies',
      name: 'Read and list users and companies',
      description: 'List and view all segments, users and companies',
    },
    {
      key: 'read_conversations',
      name: 'Read conversations',
      description: 'View conversations',
    },
    {
      key: 'read_admins',
      name: 'Read admins',
      description: 'List and view all admins',
    },
    {
      key: 'manage_webhooks',
      name: 'Manage webhooks',
      description: 'Create and update webhooks',
    },
  ],
};
