/* RESPONSIBLE TEAM: team-frontend-tech */
import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';
import type Session from 'embercom/services/session';

type HasFeatureSignature = {
  Args: {
    Positional: [string];
  };
  Return: boolean;
};

export function hasFeature(
  featureNameOrKey: string,
  appService?: {
    app: {
      get: (featureNameOrKey: string) => boolean;
      canUseFeature: (featureNameOrKey: string) => boolean;
    };
  },
  session?: Session,
) {
  let app = appService?.app;

  // For Inbox 2, we don't have the app service. Instead, we have a
  // lightweight workspace object on the session service which we can query
  // to figure out if a feature is enabled.
  if (app) {
    return Boolean(app.get(featureNameOrKey)) || app.canUseFeature(featureNameOrKey);
  } else if (session?.isWorkspaceLoaded) {
    return session.workspace.isFeatureEnabled(featureNameOrKey);
  } else {
    return false;
  }
}

export default class HasFeatureHelper extends Helper<HasFeatureSignature> {
  @service declare appService: any;
  @service declare session: Session;

  compute([featureNameOrKey]: [string]) {
    if (!featureNameOrKey) {
      return true;
    }

    return hasFeature(featureNameOrKey, this.appService, this.session);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'has-feature': typeof HasFeatureHelper;
  }
}
