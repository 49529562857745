/* RESPONSIBLE TEAM: team-reporting */
const EXISTS_OPERATORS = ['known', 'unknown'];

const OPERATORS = {
  category: 'eq',
  not_in_category: 'nin',
  exists: 'known',
  not_exists: 'unknown',
};

export function buildNestedFieldFilters(custom_fields) {
  if (!Object.keys(custom_fields || {}).length) {
    return;
  }

  let nestedFilters = [];
  for (let [fieldID, data] of Object.entries(custom_fields)) {
    let { type, operator, values } = data;
    let sanitizedOperator = OPERATORS[operator];

    let filter = {
      field_id: fieldID,
      operator: sanitizedOperator,
    };

    if (!EXISTS_OPERATORS.includes(OPERATORS[operator])) {
      filter.value_field = `value_${attributeTypeForDescriptor(type)}`;
      filter.values = values;
    }

    nestedFilters.push(filter);
  }

  return nestedFilters;
}

function attributeTypeForDescriptor(type) {
  if (type === 'list') {
    return 'list_option_id';
  } else {
    return type;
  }
}
