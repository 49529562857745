/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';

export function articleViewsDataConfig() {
  return {
    source: 'events',
    filter: {
      type: 'and',
      filters: [
        {
          type: 'category',
          data: {
            property: 'event.type',
            values: ['stats_article_content_view'],
          },
        },
        {
          type: 'category',
          data: {
            property: 'stat_receipt.content_type',
            values: ['article'],
          },
        },
      ],
    },
    time: {
      property: 'event.created_at',
      start: FILTER_PROPERTY_PLACEHOLDER,
      end: FILTER_PROPERTY_PLACEHOLDER,
    },
  };
}

export function conversationsCreatedFromArticlesDataConfig() {
  return {
    source: 'events',
    filter: {
      type: 'and',
      filters: [
        {
          type: 'category',
          data: {
            property: 'event.type',
            values: ['stats_reply'],
          },
        },
        {
          type: 'category',
          data: {
            property: 'stat_receipt.content_type',
            values: ['article'],
          },
        },
      ],
    },
    time: {
      property: 'event.created_at',
      start: FILTER_PROPERTY_PLACEHOLDER,
      end: FILTER_PROPERTY_PLACEHOLDER,
    },
  };
}

export function negativeReactionsDataConfig() {
  return {
    source: 'events',
    filter: {
      type: 'and',
      filters: [
        {
          type: 'category',
          data: {
            property: 'event.type',
            values: ['stats_reaction'],
          },
        },
        {
          type: 'category',
          data: {
            property: 'stat_receipt.content_type',
            values: ['article'],
          },
        },
        {
          type: 'category',
          data: {
            property: 'current.stat_reaction.key',
            values: ['2'],
          },
        },
      ],
    },
    time: {
      property: 'event.created_at',
      start: FILTER_PROPERTY_PLACEHOLDER,
      end: FILTER_PROPERTY_PLACEHOLDER,
    },
  };
}

export function positiveReactionsDataConfig() {
  return {
    source: 'events',
    filter: {
      type: 'and',
      filters: [
        {
          type: 'category',
          data: {
            property: 'event.type',
            values: ['stats_reaction'],
          },
        },
        {
          type: 'category',
          data: {
            property: 'stat_receipt.content_type',
            values: ['article'],
          },
        },
        {
          type: 'category',
          data: {
            property: 'current.stat_reaction.key',
            values: ['0'],
          },
        },
      ],
    },
    time: {
      property: 'event.created_at',
      start: FILTER_PROPERTY_PLACEHOLDER,
      end: FILTER_PROPERTY_PLACEHOLDER,
    },
  };
}

export function neutralReactionsDataConfig() {
  return {
    source: 'events',
    filter: {
      type: 'and',
      filters: [
        {
          type: 'category',
          data: {
            property: 'event.type',
            values: ['stats_reaction'],
          },
        },
        {
          type: 'category',
          data: {
            property: 'stat_receipt.content_type',
            values: ['article'],
          },
        },
        {
          type: 'category',
          data: {
            property: 'current.stat_reaction.key',
            values: ['1'],
          },
        },
      ],
    },
    time: {
      property: 'event.created_at',
      start: FILTER_PROPERTY_PLACEHOLDER,
      end: FILTER_PROPERTY_PLACEHOLDER,
    },
  };
}
