/* RESPONSIBLE TEAM: team-frontend-tech */
import type Store from '@ember-data/store';
import { type Block } from 'embercom/models/common/blocks/block';

export default function (store: Store, block: any): Block {
  if (store.isDestroying) {
    return;
  }
  let modelClass = `common/blocks/${block.type}`;
  let normalized = store.normalize(modelClass, block) as { data: { attributes: any } };
  let normalizedBlock = normalized.data.attributes;
  let fragment = store.createFragment(modelClass, normalizedBlock) as Block;
  return fragment;
}
