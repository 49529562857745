/* RESPONSIBLE TEAM: team-frontend-tech */
import Helper from '@ember/component/helper';
import adminTimeAgo from 'embercom/lib/admin-time-ago';
import { inject as service } from '@ember/service';

export default class AdminTimeAgoHelper extends Helper {
  @service intl;

  compute([dateString]) {
    return adminTimeAgo(dateString, this.intl);
  }
}
