/* RESPONSIBLE TEAM: team-frontend-tech */
import { helper } from '@ember/component/helper';

const STAMP_STYLES = {
  Draft: 'gray',
  Sent: 'green',
  Live: 'green',
  Paused: 'gray',
  Scheduled: 'yellow-light',
  Ended: 'gray',
} as const;

export function stateColor([stateName]: [keyof typeof STAMP_STYLES]) {
  return STAMP_STYLES[stateName];
}

export interface StateColorSignature {
  Args: {
    Positional: [string];
  };
  Return: (typeof STAMP_STYLES)[keyof typeof STAMP_STYLES];
}

const stateColorHelper = helper<StateColorSignature>(stateColor);

export default stateColorHelper;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'state-color': typeof stateColorHelper;
  }
}
