/* RESPONSIBLE TEAM: team-tickets-1 */
import { BaseConfig, EMOJI_TYPEAHEAD } from '@intercom/embercom-prosemirror-composer';
import { SAVED_REPLY_TYPEAHEAD } from './composer-config';

type Pane = 'note' | 'reply';

export default class InboxComposerConfigPlaintext extends BaseConfig {
  tools = [];
  allowedBlocks = ['paragraph'];
  inputRules = [];
  allowedInline = [];
  typeaheads = [EMOJI_TYPEAHEAD];
  placeholder = '';

  constructor(placeholder: string, conversation: any, activePane: Pane, macro: any) {
    super();
    this.placeholder = placeholder;
    this.typeaheads.push(
      Object.assign({}, SAVED_REPLY_TYPEAHEAD, {
        attrs: { conversation, macro, activePane, bulkInsert: false },
      }),
    );
  }
}
