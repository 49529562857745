/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { tracked } from '@glimmer/tracking';

export default class AddressParams {
  @tracked streetAddress;
  @tracked city;
  @tracked postcode;
  @tracked countryCode;
  @tracked stateCode;
  @tracked vatNumber;
}
