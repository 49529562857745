/* RESPONSIBLE TEAM: team-tickets-1 */
import type TicketCustomState from 'embercom/objects/inbox/ticket-custom-state';
import type Conversation from 'embercom/objects/inbox/conversation';
import { ConversationState, TicketSystemState } from 'embercom/objects/inbox/conversation';
import { NamedIcon } from 'embercom/objects/inbox/command-k/icons';
import { HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';
import { type UpdateTicketStateCommandKContext } from 'embercom/objects/inbox/command-k/update-ticket-state';
import type IntlService from 'embercom/services/intl';

// Takes a list of customStates and returns a list of dropdown items for the CmdK

export function buildUpdateTicketStateCommandKContext({
  customStates,
  selectedCustomStateId,
  allowClosedState = false,
  intl,
  conversation = undefined,
}: {
  customStates: TicketCustomState[];
  selectedCustomStateId?: number;
  allowClosedState: boolean;
  intl: IntlService;
  conversation?: Partial<Conversation> | undefined;
}): UpdateTicketStateCommandKContext {
  let validCustomStates = customStates.filter(
    (ticketCustomState) => ticketCustomState.id !== selectedCustomStateId,
  );

  let groupedCustomStates = groupCustomStatesBySystemState(validCustomStates).map((state) => {
    return {
      id: state.id,
      state: state.id,
      label: localisedCustomStateAdminLabel(state.adminLabel, intl),
      iconComponent: 'inbox2/ticket-state-dot-icon' as const,
      systemState: state.systemState,
    };
  });

  if (!allowClosedState) {
    return {
      states: [...groupedCustomStates],
      conversation,
    } as UpdateTicketStateCommandKContext;
  }

  let closedConversationState = {
    state: ConversationState.Closed,
    label: intl.t('inbox.conversation-states.close'),
    icon: new NamedIcon('close-conversation'),
    shortcutId: HotkeyID.Close,
  };

  return {
    states: [...groupedCustomStates, closedConversationState],
    conversation,
  };
}

function groupCustomStatesBySystemState(customStates: TicketCustomState[]): TicketCustomState[] {
  let groupedStates: Map<TicketSystemState, TicketCustomState[]> = new Map();

  customStates.forEach((item: TicketCustomState) => {
    let states = groupedStates.get(item.systemState) || [];
    states.push(item);
    groupedStates.set(item.systemState, states);
  });

  // Sort the custom states alphabetically by adminLabel within each systemState
  groupedStates.forEach((customStateArray) => {
    customStateArray.sort((a, b) => a.adminLabel.localeCompare(b.adminLabel));
  });

  return [...groupedStates.values()].flat();
}

export function localisedCustomStateAdminLabel(adminLabel: string, intl: IntlService): string {
  switch (adminLabel) {
    case 'Submitted':
      return intl.t(`inbox.ticket-state.${TicketSystemState.Submitted}`);
    case 'In progress':
      return intl.t(`inbox.ticket-state.${TicketSystemState.InProgress}`);
    case 'Waiting on customer':
      return intl.t(`inbox.ticket-state.${TicketSystemState.WaitingOnCustomer}`);
    case 'Resolved':
      return intl.t(`inbox.ticket-state.${TicketSystemState.Resolved}`);
    default:
      return adminLabel;
  }
}
