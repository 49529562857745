/* RESPONSIBLE TEAM: team-proactive-support */
function poweredByHtml(template_data) {
  if (template_data.show_powered_by) {
    return {
      type: 'html',
      content: `<p style="margin-top: 18px; font-size: 12px; color: #b7b7b7; text-decoration: none; font-weight: 300;" class="powered-by intercom-align-center intercom-interblocks-align-center">${template_data.powered_by_link}</p><style>table.container p.powered-by a {font-weight: bold; text-decoration: none; color: #999;}</style>`,
    };
  }

  return {
    type: 'paragraph',
    text: '',
  };
}

export default function (template_data) {
  return {
    blocks: [
      {
        type: 'table',
        sectionType: 'header',
        responsive: false,
        container: true,
        stacked: true,
        rows: [
          {
            cells: [
              {
                style: {
                  paddingTop: '45px',
                  paddingBottom: '0px',
                  paddingLeft: '60px',
                  paddingRight: '60px',
                },
                content: [
                  {
                    type: 'html',
                    content:
                      '        <table width="100%" class="header">\n          <tr>\n            <td>\n              {{widget.logo}}\n            </td>\n          </tr>\n        </table>',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: 'table',
        sectionType: 'content',
        responsive: false,
        container: true,
        stacked: true,
        rows: [
          {
            cells: [
              {
                content: [
                  {
                    type: 'paragraph',
                    text: '{{content}}',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: 'table',
        style: {
          backgroundColor: '#f9f9f9',
        },
        sectionType: 'footer',
        responsive: false,
        container: true,
        stacked: true,
        rows: [
          {
            cells: [
              {
                style: {
                  paddingTop: '16px',
                  paddingBottom: '16px',
                  paddingLeft: '16px',
                  paddingRight: '16px',
                },
                content: [
                  {
                    type: 'html',
                    content:
                      '<table cellspacing="0" border="0" cellpadding="0" align="center" bgcolor="transparent" class="main">\n          <tr>\n            <td align="center" class="intercom-align-center intercom-interblocks-align-center">\n              {{widget.address}}\n            </td>\n          </tr>\n        </table>',
                  },
                  {
                    type: 'html',
                    content:
                      '<p class="intercom-align-center intercom-interblocks-align-center {{unsubscribeLinkClass}}" style="text-decoration: underline"><small><a href="{{unsubscribe_url}}" style="color: #b7b7b7;">Unsubscribe from our emails</a></small></p>\n\n<style>\ntable.container h1,\ntable.container h2 {\n  color: #1d364b;\n}\ntable.container h1 {\n  line-height: 33px;\n  color: #1d364b;\n  margin-bottom: 7px;\n  margin-top: 30px;\n  font-weight: normal;\n}\ntable.container h1 + h2 {\n    margin-top: 0px !important;\n}\ntable.container a {\n  text-decoration: underline;\n}\ntable.container pre {\n  color: #525252;\n}\ntable.container .main .address {\n  font-size: 12px;\n  color: #b7b7b7;\n  text-decoration: none;\n  font-weight: 300;\n  margin-top: 9px;\n  line-height: 1.5em;\n}\ntable.container .logo-widget-img {\n    max-width: 165px;\n    max-height: 85px;\n}\ntable.container .header td img {\n    padding: 15px 0 30px;\n    text-align: left;\n}\ntable.body p {\n margin: 0 0 17px 0;\n line-height: 1.5;\n}\n table.body ol, table.body ul {\n margin: 0 0 20px 40px;\n padding: 0;\n}\n table.body div.intercom-container {\n margin-bottom: 17px;\n margin-top: 17px;\n line-height: 0;\n}\n</style>',
                  },
                  poweredByHtml(template_data),
                ],
              },
            ],
          },
        ],
      },
    ],

    contentBlocks: [
      {
        type: 'table',
        sectionType: 'content',
        responsive: false,
        container: true,
        stacked: true,
        rows: [
          {
            cells: [
              {
                style: {
                  paddingTop: '0px',
                  paddingBottom: '45px',
                  paddingLeft: '60px',
                  paddingRight: '60px',
                },
                content: [],
              },
            ],
          },
        ],
      },
    ],

    css: {
      body_background_color: '#f9f9f9',
      container_background_color: '#FFFFFF',
      template_font_color: '#525252',
      template_link_font_color: '#1251ba',
      h1_font_size: 26,
      h2_font_size: 18,
      paragraph_font_size: 15,
    },
  };
}
