/* RESPONSIBLE TEAM: team-tickets-1 */
// @ts-nocheck
import { BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import { type Block, type BlockList } from '@intercom/interblocks.ts';

export default function (blocksDoc: BlocksDocument) {
  let blocks = blocksDoc.blocks.map((block: Block) => {
    if (block.type === 'paragraph') {
      block.text = block.text.replace(/<br>(?!\uFEFF)/gi, '<br>\uFEFF');
    }
    return block;
  }) as BlockList;
  return new BlocksDocument(blocks);
}
