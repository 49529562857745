/* RESPONSIBLE TEAM: team-reporting */
import { formatSeconds } from 'embercom/lib/duration-formatter';

export default class Formatter {
  formatData(data) {
    return data;
  }

  formatCounter(data) {
    return formatSeconds(data);
  }

  formatTooltip(data) {
    return formatSeconds(data);
  }

  formatAxis(data) {
    return formatSeconds(data);
  }
}
