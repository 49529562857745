/* RESPONSIBLE TEAM: team-messenger */
import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

export default class includeOfficeHoursTooltip extends Helper {
  @service intl;

  compute([settings, tooltipText]) {
    let officeHoursText =
      settings && settings.reportingExcludeOooHours
        ? this.intl.t('reporting.include-office-hours-tooltip.office-hours-text')
        : '';
    return `${tooltipText} ${officeHoursText}`;
  }
}
