/* RESPONSIBLE TEAM: team-channels */
import { get } from 'embercom/lib/ajax';
import ENV from 'embercom/config/environment';
import { next } from '@ember/runloop';

export default async function whatsappEmbeddedSignup(appId, fbService, withConfig = false) {
  if (withConfig) {
    return whatsappEmbeddedSignupWithConfig(appId);
  } else {
    return whatsappEmbeddedSignupWithScope(fbService);
  }
}

async function whatsappEmbeddedSignupWithScope(fbService) {
  return fbService.login('business_management,whatsapp_business_management', {
    extras: {
      feature: 'whatsapp_embedded_signup',
    },
  });
}

async function whatsappEmbeddedSignupWithConfig(appId) {
  return loginWithConfig(appId, ENV.APP.whatsapp.configurationId, {
    extras: {
      feature: 'whatsapp_embedded_signup',
    },
  });
}

async function handleLoginResponse(appId, response) {
  let data = {
    app_id: appId,
    code: response.authResponse.code,
  };

  let intercom_response = await get('/ember/whatsapp/integrations/exchange_code', data);

  return intercom_response.access_token;
}

async function loginWithConfig(appId, configId, options = {}) {
  let params = {
    ...options,
    config_id: configId,
    response_type: 'code',
    override_default_response_type: true,
  };

  return new Promise((resolve, reject) => {
    window.FB.login(function (response) {
      if (response.authResponse) {
        // If we receive a code from the FB SDK, we need to exchange it for an access token
        if (response.authResponse.code) {
          handleLoginResponse(appId, response)
            // The FB SDK doesn't allow async functions to be passed to the login callback
            // eslint-disable-next-line promise/prefer-await-to-then
            .then((accessToken) => {
              response.authResponse.accessToken = accessToken;
              next(null, resolve, response);
            })
            .catch((error) => {
              next(null, reject, error);
            });
          // If we receive an access token because of a previous login, we can resolve immediately
        } else if (response.authResponse.accessToken) {
          next(null, resolve, response);
        } else {
          next(null, reject, response);
        }
      } else {
        next(null, reject, response);
      }
    }, params);
  });
}
