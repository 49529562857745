/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import EmberObject from '@ember/object';
import { compare } from '@ember/utils';

// This maps the sort options we expose in the UI to underlying sort definitions in the normal ember sort definition format.
const SORT_OPTION_TO_SORT_DEFINITION = {
  'sortingUpdatedAt:asc': ['ordinal', 'id'],
  'sortingUpdatedAt:desc': ['inverseOrdinal', 'id:desc'],
  'last_part_where_current_admin_is_mentioned.created_at:asc': [
    'last_part_where_current_admin_is_mentioned.created_at',
    'id',
  ],
  'last_part_where_current_admin_is_mentioned.created_at:desc': [
    'last_part_where_current_admin_is_mentioned.created_at:desc',
    'id:desc',
  ],
  'waitingSince:asc': ['waitingSinceOrdinal', 'id'],
  'priorityNewest:desc': ['priority:desc', 'inverseOrdinal', 'id:desc'],
  'nextBreachTime:asc': ['nextBreachTime', 'id'],
};

const KEYS_USED_TO_SORT = [
  'id',
  'ordinal',
  'inverseOrdinal',
  'waitingSinceOrdinal',
  'priority',
  'nextBreachTime',
  'last_part_where_current_admin_is_mentioned.created_at',
];

export default EmberObject.extend({
  init() {
    this._super(...arguments);
    this.cachedSortValues = {};
    this._initializeNormalizedSortDefinitions();
  },

  sort(conversations, transitionedConversations, key, direction) {
    this._updateSortValueCache(conversations, transitionedConversations);

    let hashKey = this._sortHashKey(key, direction);
    let sortDefinition = this.normalizedSortDefinitions[hashKey];
    if (!sortDefinition) {
      throw new Error(`Sort is invalid: ${hashKey}`);
    }
    return this._sortBySortDefinition(conversations, sortDefinition);
  },

  isValidSort(key, direction) {
    return !!this.normalizedSortDefinitions[this._sortHashKey(key, direction)];
  },

  reset() {
    this.cachedSortValues = {};
  },

  _sortHashKey(key, direction) {
    return `${key}:${direction}`;
  },

  _sortBySortDefinition(conversations, sortDefinition) {
    return conversations.sort((c1, c2) => {
      for (let i = 0; i < sortDefinition.length; i++) {
        let [key, direction] = sortDefinition[i];
        let result = compare(this.cachedSortValues[c1.id][key], this.cachedSortValues[c2.id][key]);
        if (result !== 0) {
          return direction === 'desc' ? -1 * result : result;
        }
      }
      return 0;
    });
  },

  _updateSortValueCache(conversations, transitionedConversations) {
    conversations.forEach((c) => {
      if (!transitionedConversations.includes(c) || !this.cachedSortValues[c.id]) {
        this.cachedSortValues[c.id] = c.getProperties(KEYS_USED_TO_SORT);
      }
    });
  },

  _initializeNormalizedSortDefinitions() {
    let normalized = {};
    Object.keys(SORT_OPTION_TO_SORT_DEFINITION).forEach((name) => {
      normalized[name] = SORT_OPTION_TO_SORT_DEFINITION[name].map((p) => {
        let [prop, direction] = p.split(':');
        direction = direction || 'asc';

        return [prop, direction];
      });
    });
    this.normalizedSortDefinitions = normalized;
  },
});
