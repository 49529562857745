/* RESPONSIBLE TEAM: team-tickets-1 */
// @ts-nocheck
import {
  type ComposerConfig,
  EMOJI_TYPEAHEAD,
  type InitialSelection,
  INPUT_RULE_CODE_BLOCK,
  INPUT_RULE_EMOJI,
  INPUT_RULE_INLINE_CODE,
  INPUT_RULE_MARKDOWN_ANCHOR,
  INPUT_RULE_ORDERED_LIST,
  INPUT_RULE_UNORDERED_LIST,
  type TextDirection,
  type TypeaheadConfig,
} from '@intercom/embercom-prosemirror-composer';
import { triggerCharacter } from '@intercom/embercom-prosemirror-composer/lib/composer/typeahead/trigger-character';
import EmbercomFileUploader from '../articles/embercom-file-uploader';
import { type CanvasNetworkClient } from '@intercom/interblocks.ts';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
import { EmbercomCanvasNetworkClient } from 'embercom/objects/composer/embercom-canvas-network-client';

export const MENTION_TYPEAHEAD = {
  name: 'mention',
  matcher: triggerCharacter('@', { allowSpaces: true }),

  component: 'conversation/composer/mention-typeahead',
} as TypeaheadConfig;

export const SAVED_REPLY_TYPEAHEAD = {
  name: 'saved_reply',
  matcher: triggerCharacter('#', { allowSpaces: true }),
  component: 'conversation/composer/saved-reply-typeahead',
  attrs: {},
} as TypeaheadConfig;

type Pane = 'note' | 'reply';

export default class InboxComposerConfig implements ComposerConfig {
  upload;
  attributes;
  templating;

  debug = false;
  renderOverlaysInPlace = false;
  autoFocus = false;

  tools = [
    { name: 'text-formatter' },
    { name: 'anchor-editor' },
    { name: 'fallback-editor' },
    { name: 'image-editor', options: { supportAltAttributeEditing: false } },
  ];
  typeaheads = [EMOJI_TYPEAHEAD];

  textDirection = 'auto' as TextDirection;
  placeholder = '';
  initialSelectionLocation = 'end' as InitialSelection;
  canvasNetworkClient: CanvasNetworkClient;

  allowedBlocks = [
    'paragraph',
    'image',
    'orderedList',
    'unorderedList',
    'heading',
    'subheading',
    'codeBlock',
  ];

  allowedInline = ['bold', 'italic', 'anchor', 'code'];

  inputRules = [
    INPUT_RULE_CODE_BLOCK,
    INPUT_RULE_INLINE_CODE,
    INPUT_RULE_ORDERED_LIST,
    INPUT_RULE_UNORDERED_LIST,
    INPUT_RULE_EMOJI,
    INPUT_RULE_MARKDOWN_ANCHOR,
  ];

  allowTextAlignment = false;

  constructor(
    app: any,
    conversation: any,
    activePane: Pane,
    allowUploads: boolean,
    placeholder: string,
    macro: any,
    enableTemplating: boolean,
  ) {
    this.configureUploads(app.id, allowUploads);
    this.configureTypeaheads(app, activePane, conversation, macro);
    this.configureSchema(app, activePane);
    this.canvasNetworkClient = new EmbercomCanvasNetworkClient(app.id);
    this.placeholder = placeholder;
    this.attributes = app.allowedAttributes;

    if (enableTemplating) {
      this.templating = {
        picker: 'common/attribute-picker',
        resolver: new AttributeInfoResolver({ attributes: this.attributes }),
      };
    }
  }

  private configureUploads(appId: string, allowUploads: boolean) {
    if (allowUploads) {
      this.upload = {
        allowedImageFileTypes: ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'],
        allowedAttachmentTypes: ['*'],
        uploader: EmbercomFileUploader,
        attrs: { policyUrl: `/apps/${appId}/uploads` },
      };
    }
  }

  private configureTypeaheads(app: any, activePane: Pane, conversation: any, macro: any) {
    if (activePane === 'note') {
      this.typeaheads.push(MENTION_TYPEAHEAD);
      this.typeaheads.push(
        Object.assign({}, SAVED_REPLY_TYPEAHEAD, {
          attrs: { conversation, macro, activePane, bulkInsert: false },
        }),
      );
    } else {
      this.typeaheads.push(
        Object.assign({}, SAVED_REPLY_TYPEAHEAD, {
          attrs: { conversation, macro, activePane, bulkInsert: false },
        }),
      );
    }
  }

  private configureSchema(app: any, activePane: Pane) {
    if (activePane === 'note') {
      this.allowedInline.push('mention');
      this.allowedBlocks.push('messengerCard');
    } else {
      this.allowedBlocks.push('messengerCard');
    }
  }
}
