/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import containerLookup, {
  getEmberDataStore,
  getIntlService,
  getSelfServeTrialsService,
} from 'embercom/lib/container-lookup';
import currencyFormatter from 'embercom/lib/currency-formatter';
import { isEmpty } from '@ember/utils';

async function getNumberOfSeatsInUse(filterPredicate = () => true, withReload = true) {
  let store = getEmberDataStore();
  let teammates = await store.findAll('admin', { reload: false, backgroundReload: false });
  let invites = await store.findAll('invited-admin', {
    reload: withReload,
    backgroundReload: false,
  });
  let seatsInUseByInvites = invites.filter((i) => i.active && filterPredicate(i)).length;
  let seatsInUseByTeammates = teammates.filter(
    (t) => t.isHuman && !t.removed_from_current_app && filterPredicate(t),
  ).length;
  let totalNumberOfSeatsInUse = seatsInUseByTeammates + seatsInUseByInvites;
  return {
    totalNumberOfSeatsInUse,
    seatsInUseByInvites,
  };
}

const positivePart = (value) => Math.max(0, value);

async function getSeatInfo({
  numberOfSeatsRequired,
  numberOfSeatsInUse,
  seatsInUseByInvites,
  isCopilotSeatAdded = false,
  copilotData = {},
}) {
  let numberOfSeatsInUseInfo;
  if (numberOfSeatsInUse === undefined) {
    numberOfSeatsInUseInfo = await getNumberOfSeatsInUse();
    numberOfSeatsInUse = numberOfSeatsInUseInfo.totalNumberOfSeatsInUse;
    seatsInUseByInvites = numberOfSeatsInUseInfo.seatsInUseByInvites;
  }
  let customerService = containerLookup('service:customerService');
  await customerService.ensureDataIsLoaded.perform({ fetchPrices: false });
  let customer = customerService.customer;
  let numberOfIncludedSeats = customer.freeSeatCount;

  let appService = containerLookup('service:appService');
  let app = appService.app;

  let numberOfSeatsAvailable =
    numberOfIncludedSeats === null || numberOfSeatsInUse === null
      ? null
      : positivePart(numberOfIncludedSeats - numberOfSeatsInUse);
  let pricePerSeat = 0;
  let annuallyContractedWithOveragesEnabled =
    app.isSalesforceContracted && customerService.app.canUseSeatOverages;
  if (!app.isSalesforceContracted || annuallyContractedWithOveragesEnabled) {
    pricePerSeat = customer.pricePerSeat;
  }

  let numberOfSeatsInUseAfter = numberOfSeatsInUse + numberOfSeatsRequired;
  let numberOfChargeableSeatsBefore = positivePart(numberOfSeatsInUse - numberOfIncludedSeats);
  let numberOfChargeableSeatsAfter = positivePart(numberOfSeatsInUseAfter - numberOfIncludedSeats);
  let changeInNumberOfChargeableSeats =
    numberOfChargeableSeatsAfter - numberOfChargeableSeatsBefore;
  let changeInSeatCost = changeInNumberOfChargeableSeats * pricePerSeat;

  if (isCopilotSeatAdded) {
    changeInSeatCost +=
      (changeInNumberOfChargeableSeats > 0 ? changeInNumberOfChargeableSeats : 1) *
        copilotData.price ?? 0;
  }

  let maximumNumberOfSeats = app.isSalesforceContracted
    ? customer.freeSeatCount
    : customer.seatLimit;

  if (app.hasSeatOveragesEnabled) {
    maximumNumberOfSeats = null;
  }

  let numberOfFreeSeatsUsedBefore = Math.min(numberOfSeatsInUse, numberOfIncludedSeats);
  let numberOfFreeSeatsUsedAfter = Math.min(numberOfSeatsInUseAfter, numberOfIncludedSeats);
  let changeInNumberOfFreeSeats = numberOfFreeSeatsUsedAfter - numberOfFreeSeatsUsedBefore;

  return {
    numberOfIncludedSeats,
    numberOfSeatsAvailable,
    pricePerSeat,
    changeInNumberOfChargeableSeats,
    changeInSeatCost,
    formattedChangeInSeatCost: currencyFormatter(changeInSeatCost),
    formattedAbsoluteValueOfChangeInSeatCost: currencyFormatter(Math.abs(changeInSeatCost)),
    formattedPricePerSeat: currencyFormatter(pricePerSeat),
    numberOfSeatsInUse,
    seatsInUseByInvites,
    numberOfSeatsInUseAfter,
    maximumNumberOfSeats,
    numberOfChargeableSeatsBefore,
    numberOfChargeableSeatsAfter,
    numberOfFreeSeatsUsedBefore,
    numberOfFreeSeatsUsedAfter,
    changeInNumberOfFreeSeats,
    annuallyContractedWithOveragesEnabled,
  };
}

async function getCopilotData(isCopilotSeatAdded) {
  if (!isCopilotSeatAdded) {
    return {};
  }
  let copilotDataService = containerLookup('service:copilotDataService');
  await copilotDataService.loadCopilotPrice();
  return copilotDataService.copilotData;
}

async function getPriceIncreaseMessage(numberOfSeatsRequired, isCopilotSeatAdded) {
  let seatInfo = await getSeatInfo({
    numberOfSeatsRequired,
    isCopilotSeatAdded,
    copilotData: await getCopilotData(isCopilotSeatAdded),
  });
  if (seatInfo.changeInSeatCost <= 0) {
    return;
  }
  let intlService = getIntlService();
  if (seatInfo.annuallyContractedWithOveragesEnabled) {
    let message = intlService.t(
      'settings.teammates.invite.confirm-price-increase.overages-enabled-additional-chargable-seats-message',
      {
        additionalChargableSeats: seatInfo.changeInNumberOfChargeableSeats,
        changeInSeatCost: seatInfo.formattedChangeInSeatCost,
      },
    );

    if (seatInfo.seatsInUseByInvites) {
      let numberOfAdminsWithSeats = seatInfo.numberOfSeatsInUse - seatInfo.seatsInUseByInvites;
      message += ` ${intlService.t(
        'settings.teammates.invite.confirm-price-increase.projected-change-message',
        {
          numberOfAdminsWithSeats,
          seatsInUseByInvites: seatInfo.seatsInUseByInvites,
        },
      )}`;
    }
    return message;
  }
  return intlService.t(
    'settings.teammates.invite.confirm-price-increase.adding-teammates-price-increase-message',
    {
      numberOfSeatsRequired,
      changeInSeatCost: seatInfo.formattedChangeInSeatCost,
    },
  );
}

async function confirmPriceIncrease(
  numberOfSeatsRequired,
  isCopilotSeatAdded,
  showConfirmModal = () => {},
) {
  if (numberOfSeatsRequired === 0) {
    return true;
  }

  let selfServeTrialsService = getSelfServeTrialsService();
  if (selfServeTrialsService.isTriallingCorePlan) {
    return true;
  }

  let body = await getPriceIncreaseMessage(numberOfSeatsRequired, isCopilotSeatAdded);
  if (isEmpty(body)) {
    return true;
  }
  let intlService = getIntlService();
  return await showConfirmModal({
    title: intlService.t('settings.teammates.invite.confirm-price-increase.title'),
    confirmButtonText: intlService.t('settings.teammates.invite.confirm-price-increase.confirm'),
    body,
  });
}

export { getSeatInfo, getNumberOfSeatsInUse, confirmPriceIncrease };
