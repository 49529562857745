/* RESPONSIBLE TEAM: team-reporting */
const VISUALIZATION_COLORS = [
  ['vis-blue-40', 'vis-blue-60', 'vis-blue-70', 'vis-blue-80'],
  ['vis-salmon-40', 'vis-salmon-60', 'vis-salmon-70', 'vis-salmon-80'],
  ['vis-green-40', 'vis-green-60', 'vis-green-70', 'vis-green-80'],
  ['vis-magenta-40', 'vis-magenta-60', 'vis-magenta-70', 'vis-magenta-80'],
];

/**
 * Creates an object that maps keys to unique visualization colors from the styleguide
 *
 * @param {String[]} keys An array of unique keys to create a stable color for
 * @returns An object mapping each key to a generated color name
 */
export const createColorHashForKeys = (keys) =>
  keys.reduce((colorHash, key, i) => {
    let shade = Math.floor(i / 4);
    let color = i - shade * 4;

    return {
      ...colorHash,
      [key]: VISUALIZATION_COLORS[color][shade],
    };
  }, []);

const CHART_HEIGHT = 250;

/**
 * Returns a start number, and a scale percentage, used to position and resize bars
 *
 * @param {number} start A context item starting value, often 0
 * @param {number} end A context item value to represent on a visualization
 * @param {*} scale an instantiated d3 scale function
 */
export const createScaledStackItem = (start, end, scale) => {
  let [scaledStart, scaledEnd] = [scale(start), scale(end)];

  return {
    start: -scaledStart,
    scale: (scaledEnd - scaledStart) / CHART_HEIGHT,
  };
};
