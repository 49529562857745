/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import safeWindowOpen from 'embercom/lib/safe-window-open';

let openCenteredWindow = function (url, height, width, name) {
  name = name || 'Authorize';
  let windowWidth = width || 600;
  let windowHeight = height || 800;
  let windowLeft =
    openCenteredWindow.outerWidth() / 2 + openCenteredWindow.screenX() - windowWidth / 2;
  let windowTop =
    openCenteredWindow.outerHeight() / 2 + openCenteredWindow.screenY() - windowHeight / 2;
  windowTop = Math.max(0, windowTop);
  let options = `menubar, width=${windowWidth}, height=${windowHeight}, top=${windowTop}, left=${windowLeft}`;
  return safeWindowOpen(url, name, options);
};

openCenteredWindow.outerWidth = function () {
  return window.outerWidth;
};

openCenteredWindow.outerHeight = function () {
  return window.outerHeight;
};

openCenteredWindow.screenX = function () {
  return window.screenX || window.screenLeft || 0;
};

openCenteredWindow.screenY = function () {
  return window.screenY || window.screenTop || 0;
};

export default openCenteredWindow;
