/* RESPONSIBLE TEAM: team-data-interop */
import {
  BaseConfig,
  type ComposerConfig,
  type TextDirection,
} from '@intercom/embercom-prosemirror-composer';
import TemplateAttributeResolver from 'embercom/lib/common/template-attribute-resolver';

export class CustomActionsAttributeInfoResolver extends TemplateAttributeResolver {
  isFallbackAllowed(_path: string) {
    return false;
  }
}

export default class ActionComposerConfig extends BaseConfig implements ComposerConfig {
  placeholder = '';
  autoFocus = false;
  textDirection = 'ltr' as TextDirection;
  allowedBlocks = ['paragraph'];
  singleBlockMode = false;
  allowTextAlignment = false;
  isPlaintext = true;
  tools = [{ name: 'template-inserter' }];
  allowedInline = [];
  experimental = {
    hideInsertersOnMouseOut: true,
  };

  constructor(
    isSingleLineComposer = true,
    isDisabled = false,
    resolver: CustomActionsAttributeInfoResolver,
    placeholder = '',
  ) {
    super();

    this.singleBlockMode = isSingleLineComposer;
    this.isInteractivityDisabled = isDisabled;
    this.placeholder = placeholder;
    //@ts-ignore
    this.templating = {
      picker: 'common/attribute-picker',
      resolver,
    };
  }
}
