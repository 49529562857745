/* RESPONSIBLE TEAM: team-proactive-support */
let AppColor = function () {
  /*
  HSL, HSV, HEX and RGB conversions
  Adapted from: http://mjijackson.com/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript
  GitHub: https://github.com/mjackson/mjijackson.github.com/blob/master/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript.txt
  */

  this.HEX_to_HSL = function (hex) {
    if (hex !== undefined) {
      hex = hex.toUpperCase();
      let s;
      let h;
      let r = parseInt(hex.substr(1, 2), 16) / 255;
      let g = parseInt(hex.substr(3, 2), 16) / 255;
      let b = parseInt(hex.substr(5, 2), 16) / 255;
      let max = Math.max(r, g, b);
      let min = Math.min(r, g, b);
      let l = (max + min) / 2;

      if (max === min) {
        h = s = 0; // achromatic
      } else {
        let d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
          case r:
            h = (g - b) / d + (g < b ? 6 : 0);
            break;
          case g:
            h = (b - r) / d + 2;
            break;
          case b:
            h = (r - g) / d + 4;
            break;
        }
        h /= 6;
      }

      return [h, s, l];
    } else {
      return [0, 0, 0];
    }
  };

  this.HSL_to_HEX = function (h, s, l) {
    let r;
    let g;
    let b;

    // Give HSL attributes a max and min
    // as there's a decent chance we'll
    // be sending invalid values here.
    h = Math.min(h, 360);
    s = Math.min(s, 1);
    l = Math.min(l, 1);
    if (h === 360) {
      h = 0;
    }
    h = Math.max(h, 0);
    s = Math.max(s, 0);
    l = Math.max(l, 0);

    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      let p = 2 * l - q;
      r = this.hue2rgb(p, q, h + 1 / 3);
      g = this.hue2rgb(p, q, h);
      b = this.hue2rgb(p, q, h - 1 / 3);
    }

    return this.RGB_TO_HEX(r, g, b);
  };

  this.hue2rgb = function (p, q, t) {
    if (t < 0) {
      t += 1;
    }
    if (t > 1) {
      t -= 1;
    }
    if (t < 1 / 6) {
      return p + (q - p) * 6 * t;
    }
    if (t < 1 / 2) {
      return q;
    }
    if (t < 2 / 3) {
      return p + (q - p) * (2 / 3 - t) * 6;
    }
    return p;
  };

  this.HEX_TO_HSV = function (hexVal) {
    let h;
    let s;
    let r = parseInt(hexVal.substr(1, 2), 16) / 255;
    let g = parseInt(hexVal.substr(3, 2), 16) / 255;
    let b = parseInt(hexVal.substr(5, 2), 16) / 255;

    let max = Math.max(r, g, b);
    let min = Math.min(r, g, b);
    let v = max;

    let d = max - min;
    s = max === 0 ? 0 : d / max;

    if (max === min) {
      h = 0; // achromatic
    } else {
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }

    return [h, s, v];
  };

  this.HSV_TO_HEX = function (h, s, v) {
    let r;
    let g;
    let b;

    let i = Math.floor(h * 6);
    let f = h * 6 - i;
    let p = v * (1 - s);
    let q = v * (1 - f * s);
    let t = v * (1 - (1 - f) * s);

    switch (i % 6) {
      case 0:
        r = v;
        g = t;
        b = p;
        break;
      case 1:
        r = q;
        g = v;
        b = p;
        break;
      case 2:
        r = p;
        g = v;
        b = t;
        break;
      case 3:
        r = p;
        g = q;
        b = v;
        break;
      case 4:
        r = t;
        g = p;
        b = v;
        break;
      case 5:
        r = v;
        g = p;
        b = q;
        break;
    }

    return this.RGB_TO_HEX(r, g, b);
  };

  //RGB values in the range [0...255]
  this.RGB_TO_HEX = function (r, g, b) {
    let rh = this.zero_pad_string(Math.round(r * 255).toString(16), 2);
    let gh = this.zero_pad_string(Math.round(g * 255).toString(16), 2);
    let bh = this.zero_pad_string(Math.round(b * 255).toString(16), 2);

    return `#${rh}${gh}${bh}`;
  };

  this.zero_pad_string = function (str, length) {
    while (str.length < length) {
      str = `0${str}`;
    }
    return str;
  };

  this.darken_android_color = (hexVal) => {
    let hsv = this.HEX_TO_HSV(hexVal);
    hsv[2] *= 0.8;
    return this.HSV_TO_HEX(hsv[0], hsv[1], hsv[2]);
  };

  this.clamp01 = (val) => {
    return window.Math.min(1, window.Math.max(0, val));
  };

  this.darken = (hex_color, amount) => {
    let hsl = this.HEX_to_HSL(hex_color);
    hsl[2] -= amount / 100;
    hsl[2] = this.clamp01(hsl[2]);
    return this.HSL_to_HEX(hsl[0], hsl[1], hsl[2]);
  };

  this.generate_message_box_colors = function (base_color) {
    if (!base_color) {
      return {};
    }
    let highlight_color;
    let darker_color;
    let hover_color;
    let hover_highlight_color;
    let darker_android_color;

    let hsl = this.HEX_to_HSL(base_color);
    let h = hsl[0];
    let s = hsl[1];
    let l = hsl[2];

    let magnitude = 1 - Math.abs((l - 0.5) / 0.5);

    highlight_color = this.HSL_to_HEX(h, s - 0.08511 * magnitude, l + 0.1383 * magnitude);
    darker_color = this.darken(base_color, 21.5);
    hover_color = this.darken(base_color, 15.5);
    hover_highlight_color = this.HSL_to_HEX(h, s + 0.04255 * magnitude, l + 0.24468 * magnitude);
    darker_android_color = this.darken_android_color(base_color);

    return {
      base: base_color,
      highlight: highlight_color,
      darker: darker_color,
      darker_android: darker_android_color,
      hover: hover_color,
      hover_highlight: hover_highlight_color,
    };
  };
};

export const addOpacityToSimpleHex = (hex, opacity) => {
  let boundedOpacity = Math.min(Math.max(opacity, 0), 1);
  let hexOpacity = Math.floor(boundedOpacity * 255).toString(16);
  if (hex.length < 6) {
    hexOpacity = hexOpacity.charAt(0);
  }
  return `${hex}${hexOpacity}`;
};

export default AppColor;
