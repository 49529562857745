/* RESPONSIBLE TEAM: team-tickets-1 */
// @ts-nocheck
import { BlocksDocument } from '@intercom/embercom-prosemirror-composer';

export default function (blocksDoc: BlocksDocument) {
  let startIndex = 0;
  let endIndex = blocksDoc.blocks.length - 1;

  // Skip over all para blocks with whitespace to identify index of the first block with content
  // E.g.
  // For input - w,w,w,c,c,c,w,w,w,w
  // startIndex will be set to 3.
  while (startIndex < blocksDoc.blocks.length) {
    if (
      blocksDoc.blocks[startIndex].type === 'paragraph' &&
      blocksDoc.blocks[startIndex].text.trim() === ''
    ) {
      startIndex++;
    } else {
      break;
    }
  }

  // Skip over whitespace para blocks from the end to identify the last block with content
  // E.g.
  // For input - w,w,w,c,c,c,w,w,w,w
  // endIndex will be set to 5.
  while (endIndex > startIndex) {
    if (
      blocksDoc.blocks[endIndex].type === 'paragraph' &&
      blocksDoc.blocks[endIndex].text.trim() === ''
    ) {
      endIndex--;
    } else {
      break;
    }
  }

  return new BlocksDocument(blocksDoc.blocks.slice(startIndex, endIndex + 1));
}
