/* RESPONSIBLE TEAM: team-proactive-support */
import EmailTemplate from 'embercom/models/email-template';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

const PERSONALIZED_TEMPLATES = ['personal', 'plain'];
const PERSONALIZED_SAMPLE_CONTENT = `
  <p>Hey Josh,</p>
  <p>Daniel here from ProjectMap.</p>
  <p>I’m just dropping you a line to see how ProjectMap is working out for you.</p>
  <p>If you have any questions about the product, or any feedback, just reply and let us know. We’re always here to help.</p>
  <p>Thanks,<br>Daniel</p>`;
const SAMPLE_CONTENT = `<h1>Let’s get you started</h1><p>Did you know that creating a report in <a href="#">ProjectMap</a> only takes a few minutes? They’re excellent for visualizing how individual projects are performing, along with identifying which team members are hitting or missing deadlines.</p><div class="intercom-container intercom-align-left"><a href="#" class="intercom-h2b-button">Try it now</a></div><p>If you have any questions, we’re always here to help.</p><p>Happy reporting<br />Josh</p>`;

function cleanedHtml(html) {
  return sanitizeHtml(html, { config: 'email' }).string;
}

function getDefaultSampleContent(id) {
  return PERSONALIZED_TEMPLATES.includes(id) ? PERSONALIZED_SAMPLE_CONTENT : SAMPLE_CONTENT;
}

function replaceWidgetPlaceholders(html, renderedWidgets) {
  EmailTemplate.widgets().forEach(function (widget) {
    html = html.replace(new RegExp(`\\{{widget.${widget.name}}}`), renderedWidgets[widget.name]);
  });
  return html;
}

function replaceContentPlaceholder(html, sampleContent) {
  return html.replace(/\{\{\s*content\s*\}\}/i, sampleContent);
}

function addAppStyles(html, color) {
  let style = `<style>
    a.intercom-h2b-button { background-color: ${color} !important; }
    .comment_header_td { background-color: ${color} !important; }
    .announcement h1 { color: ${color} !important;}
  body { overflow: hidden !important; }
  // make the company template logo display on the left so it's still easy to distinguish from annoucement
  @media screen and (max-width: 595px) { .company .header td { text-align: left !important;}}
    </style>`;
  return html.replace(/<\/body>/i, `${style}</body>`);
}

export default function renderEmailTemplate(context) {
  let processedHtml = cleanedHtml(context.template);
  processedHtml = replaceWidgetPlaceholders(processedHtml, context.renderedWidgets);
  processedHtml = replaceContentPlaceholder(
    processedHtml,
    context.content || getDefaultSampleContent(context.id),
  );
  processedHtml = addAppStyles(processedHtml, context.color);
  return processedHtml;
}
