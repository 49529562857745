/* RESPONSIBLE TEAM: team-reporting */
import { inject as service } from '@ember/service';
import { setOwner } from '@ember/application';

export default class WeekDays {
  @service intl;

  constructor(owner) {
    setOwner(this, owner);
  }

  get values() {
    return [
      this.intl.t('app.lib.reporting.week-days.monday'),
      this.intl.t('app.lib.reporting.week-days.tuesday'),
      this.intl.t('app.lib.reporting.week-days.wednesday'),
      this.intl.t('app.lib.reporting.week-days.thursday'),
      this.intl.t('app.lib.reporting.week-days.friday'),
      this.intl.t('app.lib.reporting.week-days.saturday'),
      this.intl.t('app.lib.reporting.week-days.sunday'),
    ];
  }
}
