/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import DefaultPredicateManager from 'embercom/lib/common/predicates/default-predicate-manager';
import { isEvery } from '@intercom/pulse/lib/computed-properties';

export default DefaultPredicateManager.extend({
  isValid: isEvery('predicate.predicates', 'isValid'),

  initializeDefaultValue() {
    throw new Error('Cannot set a default value on a logical predicate');
  },
  changeComparison() {
    throw new Error('Cannot change the comparison on a logical predicate');
  },
  changeValue() {
    throw new Error('Cannot change value on a logical predicate');
  },
  getDefaultValue() {
    throw new Error('Cannot get a default value for a logical predicate');
  },
  getDescription() {
    let joinWord = this.get('predicate.isOrPredicate') ? 'or' : 'and';
    return {
      joinDescription: this._joinDescription(),
      joinType: joinWord,
      predicates: this.get('predicate.predicates').map((predicate) => predicate.get('description')),
    };
  },
  _joinDescription() {
    switch (this.get('predicate.type')) {
      case 'or':
        return 'matches any of';
      case 'and':
        return 'matches all of';
      case 'not':
        return 'except when matching';
    }
  },
});
