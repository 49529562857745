/* RESPONSIBLE TEAM: team-channels */
import ENV from 'embercom/config/environment';

export default async function initFacebookSdk(fb, app) {
  let initSettings = {
    appId: ENV.APP.facebook.appId,
    version: app.koala_api_version,
    xfbml: true,
  };
  await fb.FBInit(initSettings);
  window.FB.init(initSettings);
}
