/* RESPONSIBLE TEAM: team-help-desk-experience */
import { tracked } from '@glimmer/tracking';

export default class ApplyableMacroAction {
  @tracked action;
  @tracked applyable;

  constructor(action, applyable) {
    this.action = action;
    this.applyable = applyable;
  }
}
