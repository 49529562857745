/* RESPONSIBLE TEAM: team-frontend-tech */
import { helper as buildHelper } from '@ember/component/helper';

export default buildHelper(function add([selector, handler]) {
  return function (event) {
    if (!event.target.closest || !event.target.closest(selector)) {
      handler(event);
    }
  };
});
