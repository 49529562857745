/* RESPONSIBLE TEAM: team-frontend-tech */
import { helper as buildHelper } from '@ember/component/helper';
import currencyFormatter from 'embercom/lib/currency-formatter';

export default buildHelper(function (params) {
  let value = params[0];
  let precision = params[1];
  let showLeadingPlusSign = params[2];
  let truncate = params[3];
  if (showLeadingPlusSign !== true) {
    showLeadingPlusSign = false;
  }
  return currencyFormatter(value, precision, showLeadingPlusSign, '$', truncate);
});
