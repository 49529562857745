/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
/* eslint-disable ember/no-jquery */
import $ from 'jquery';
import icAjax from 'embercom/vendor/ic-ajax';
import { getRouter } from 'embercom/lib/container-lookup';
import ENV from 'embercom/config/environment';

let clientHeight: number;
let clientWidth: number;

interface AjaxArgs {
  url: string;
  type?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'HEAD' | 'OPTIONS' | 'CONNECT' | 'TRACE';
  data?: any;
  signal?: AbortSignal;
  beforeSend?: (xhr: JQueryXHR) => void;
  contentType?: boolean;
  processData?: boolean;
}

function getClientWidth() {
  if (clientWidth === undefined) {
    clientWidth = document?.documentElement?.clientWidth;
  }
  return clientWidth;
}

function getClientHeight() {
  if (clientHeight === undefined) {
    clientHeight = document?.documentElement?.clientHeight;
  }
  return clientHeight;
}

function getCurrentRouteName() {
  try {
    let router = getRouter();
    if (router) {
      return router.currentRouteName;
    }
  } catch (e) {
    // just incase there's no container yet
    console.error(e);
  }
}

//                          ░░▓▓██▒▒
//                          ██▓▓▒▒██▒▒
//                        ▓▓▓▓░░░░░░██
//                      ░░██░░░░░░░░▓▓▓▓
//                      ██▓▓░░░░░░░░░░██░░
//                    ▒▒██░░░░████▒▒░░▓▓██
//                    ██▒▒░░░░████▓▓░░░░██▒▒
//                  ▒▒██░░░░░░████▓▓░░░░▒▒██
//                  ██░░░░░░░░████▒▒░░░░░░██▓▓
//                ▓▓▓▓░░░░░░░░████▒▒░░░░░░░░██
//              ░░██░░░░░░░░░░████▒▒░░░░░░░░▓▓▓▓
//              ██▒▒░░░░░░░░░░████░░░░░░░░░░░░██░░
//            ▒▒██░░░░░░░░░░░░▓▓██░░░░░░░░░░░░▒▒██
//            ██▒▒░░░░░░░░░░░░▓▓██░░░░░░░░░░░░░░██▒▒
//          ▓▓██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░▒▒██
//          ██░░░░░░░░░░░░░░░░▒▒▓▓░░░░░░░░░░░░░░░░▓▓▓▓
//        ▓▓▓▓░░░░░░░░░░░░░░░░████▓▓░░░░░░░░░░░░░░░░██░░
//      ░░██░░░░░░░░░░░░░░░░░░████▒▒░░░░░░░░░░░░░░░░▓▓▓▓
//      ▒▒▓▓░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██
//        ██░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░▓▓▓▓
//        ▒▒██████████████████████████████████████████
//
//                           WARNING
//
// Do not send extra headers to the intercom app without first
// adding them to the "Access-Control-Allow-Headers" list in the
// intercom_base_controller or parts of the app will break. More context:
// https://github.com/intercom/intercom/issues/155872
export function requestHeaders() {
  let headers: Record<string, string> = {};

  headers['X-CSRF-Token'] = $("meta[name='csrf-token']").attr('content') || '';
  headers['X-EMBERCOM-REVISION-ID'] = $("meta[name='embercom_revision_id']").attr('content') || '';

  headers['X-Client-Width'] = String(getClientWidth());
  headers['X-Client-Height'] = String(getClientHeight());

  // Navigator#deviceMemory is non-standard
  let navigator = window.navigator as Navigator & {
    deviceMemory: number;
  };
  if (navigator.deviceMemory !== undefined) {
    headers['X-Client-Device-Memory'] = String(navigator.deviceMemory);
  }
  let routeName = getCurrentRouteName();
  if (routeName) {
    headers['X-Embercom-Route'] = routeName;
  }

  if (ENV.environment === 'test') {
    headers['X-Embercom-Test'] = 'true';
  }

  headers['X-Document-Visible'] = String(document.visibilityState === 'visible');

  return headers;
}

let ajax = function (options: AjaxArgs) {
  let originalBeforeSend = options.beforeSend;
  options.beforeSend = function (xhr) {
    // @ts-ignore - URL doesn't exist on jqXHR?
    xhr.url = options.url;

    Object.entries(requestHeaders()).forEach(([key, value]) => {
      xhr.setRequestHeader(key, value);
    });

    // support AbortController's AbortSignal
    if ('signal' in options && options.signal instanceof AbortSignal) {
      options.signal.addEventListener('abort', () => {
        xhr.abort();
      });
    }

    if (originalBeforeSend) {
      originalBeforeSend.call(this, xhr);
    }
  };

  options = $.extend(
    {
      contentType: 'application/json; charset=UTF-8',
      dataType: 'json',
    },
    options,
  );

  return icAjax(options);
};

let get = function (url: string, data?: any, { signal }: { signal?: AbortSignal } = {}) {
  return ajax({
    url,
    type: 'GET',
    data,
    signal,
  });
};

let put = function (url: string, data?: any, { signal }: { signal?: AbortSignal } = {}) {
  return ajax({
    url,
    type: 'PUT',
    data: JSON.stringify(data),
    signal,
  });
};

let post = function (url: string, data?: any, { signal }: { signal?: AbortSignal } = {}) {
  return ajax({
    url,
    type: 'POST',
    data: JSON.stringify(data),
    signal,
  });
};

// delete is a Javascript reserved keyword hence why we use ajaxDelete
let ajaxDelete = function (url: string, data?: any, { signal }: { signal?: AbortSignal } = {}) {
  return ajax({
    url,
    type: 'DELETE',
    data: JSON.stringify(data),
    signal,
  });
};

export default ajax;

export { put, post, get, ajaxDelete };
