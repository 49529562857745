/* RESPONSIBLE TEAM: team-frontend-tech */
import { helper as buildHelper } from '@ember/component/helper';

export function countrySelect(countries) {
  return countries.map((country) => {
    return {
      text: country.name,
      value: country.code,
    };
  });
}

export default buildHelper(countrySelect);
