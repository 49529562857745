/* RESPONSIBLE TEAM: team-channels */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import ajax from 'embercom/lib/ajax';
import { getEmberDataStore } from 'embercom/lib/container-lookup';
let Facebook = {};

Facebook.fetchPages = function (accessToken, nextCursor) {
  return getEmberDataStore().queryRecord('facebook-integration', {
    access_token: accessToken,
    next_cursor: nextCursor,
  });
};

Facebook.subscribe = function (appId, accessToken, pageId) {
  return ajax({
    url: '/ember/facebook_integration/subscribe.json',
    type: 'post',
    data: JSON.stringify({
      app_id: appId,
      access_token: accessToken,
      page_id: pageId,
    }),
  });
};

Facebook.unsubscribe = function (appId, accessToken, pageId) {
  return ajax({
    url: '/ember/facebook_integration/unsubscribe.json',
    type: 'post',
    data: JSON.stringify({
      app_id: appId,
      access_token: accessToken,
      page_id: pageId,
    }),
  });
};

Facebook.unsubscribeAll = function (appId) {
  return ajax({
    url: '/ember/facebook_integration/unsubscribe_all.json',
    type: 'post',
    data: JSON.stringify({
      app_id: appId,
    }),
  });
};

export default Facebook;
