/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import { REPORTING_FILTER_SELECT_ALL } from 'embercom/lib/reporting/flexible/constants';
import { getEmberDataStore } from 'embercom/lib/container-lookup';
// This is a utility class to simplify manipulating conversation
// custom attributes when using the flexible reporting dashboards
export default class CustomAttributes {
  attributes = {};

  constructor(queryParamValue) {
    try {
      this.attributes = JSON.parse(queryParamValue || '{}');
      if (this.attributes instanceof Array) {
        this.attributes = {};
      }
    } catch (e) {
      console.error(`Failed to parse custom attribute JSON: ${queryParamValue}`);
      this.attributes = {};
    }
  }

  getAttributes(id) {
    return this.attributes[id];
  }

  getOperator(id) {
    return this.attributes[id]?.operator;
  }

  _getDescriptor(id) {
    return getEmberDataStore().peekRecord('conversation-attributes/descriptor', id);
  }

  updateValues(id, values, operator) {
    if (!operator) {
      delete this.attributes[id];
      return;
    }

    let { dataType } = this._getDescriptor(id);
    if (dataType) {
      this.attributes[id] = {
        values,
        operator,
        type: dataType,
        field: 'conversation_custom_fields',
      };
    }
  }

  // Return the attributes object with all keys with an "any" value removed, e.g.:
  //   { "1":
  //     { values: [1, 2, 3], operator: 'category' },
  //     "2":
  //     { values: ["any"], operator: 'category' }
  //   }
  // is converted to:
  //   { "1":
  //     { values: [1, 2, 3], operator: 'category'}
  //   }
  toFilterValues() {
    let cleanedAttributes = Object.assign({}, this.attributes);
    /* eslint-disable guard-for-in */
    for (let id in this.attributes) {
      let values = this.attributes[id].values;
      if (values.length === 1 && values[0] === REPORTING_FILTER_SELECT_ALL) {
        delete cleanedAttributes[id];
      }
    }

    return cleanedAttributes;
  }

  clone() {
    return new CustomAttributes(this.toString());
  }

  toString() {
    return JSON.stringify(this.attributes);
  }
}
