/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
export function getAdminProfile(store, appId, adminId) {
  return store
    .query('profile', {
      app_id: appId,
      admin_id: adminId,
    })
    .then((profiles) => profiles.get('firstObject'));
}
