/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
import ENV from 'embercom/config/environment';
import { captureException } from './sentry';

const isTest = ENV.environment === 'test';
const LIMIT_TIME_PERIOD_IN_SECONDS = 10;
const MAX_CALLS_PER_TIME_PERIOD = isTest ? Infinity : 10;

let recordedLimitOverage = {};
let callsInTimePeriod = {};
let maxCallsOverride;

function _setMaxCallsPerTimePeriod(maxCalls) {
  if (!isTest) {
    return;
  }

  maxCallsOverride = maxCalls;
}

function _resetLimits() {
  if (!isTest) {
    return;
  }

  recordedLimitOverage = {};
  callsInTimePeriod = {};
}

function getMaxCallsPerTimePeriod() {
  if (isTest && maxCallsOverride !== undefined) {
    return maxCallsOverride;
  }

  return MAX_CALLS_PER_TIME_PERIOD;
}

function incrementCallsInTimePeriod(fingerprint) {
  let key = fingerprint.toString();
  if (callsInTimePeriod[key] !== undefined) {
    callsInTimePeriod[key]++;
  } else {
    callsInTimePeriod[key] = 0;
  }

  setTimeout(() => {
    callsInTimePeriod[key] = Math.max(0, callsInTimePeriod[key] - 1);
  }, LIMIT_TIME_PERIOD_IN_SECONDS * 1000);
}

function isOverLimit(fingerprint = []) {
  let fingerprintKey = fingerprint.toString();
  let callsCount = callsInTimePeriod[fingerprintKey] || 0;

  if (callsCount < getMaxCallsPerTimePeriod()) {
    return false;
  }

  // Early return if we've already sent this error to Sentry for this session
  if (recordedLimitOverage[fingerprintKey]) {
    console.info(
      `admin_pageview call for ${fingerprint} is over limit, called ${callsCount} times`,
    );
    return true;
  }

  try {
    throw new Error(`admin_pageview called too many times, ${fingerprint}`);
  } catch (e) {
    recordedLimitOverage[fingerprintKey] = true;
    captureException(e, {
      fingerprint: ['admin-view-tracking', ...fingerprint],
    });
  }

  return true;
}

function checkCallLimit(fingerprint) {
  incrementCallsInTimePeriod(fingerprint);
  return isOverLimit(fingerprint);
}

function markViewAsSeen(store, viewKey) {
  let existingPageview = _getPageviewByKey(store.peekAll('admin-pageview'), viewKey);

  if (existingPageview) {
    return Promise.resolve(existingPageview);
  }

  if (checkCallLimit(['markViewAsSeen', viewKey])) {
    return Promise.resolve(false);
  }

  return store
    .createRecord('admin-pageview', {
      pageKey: viewKey,
    })
    .save();
}

function adminHasSeen(store, viewKey, callback) {
  let fingerprint = ['adminHasSeen', viewKey];
  let hasSeenView = adminHasSeenFromPageviews(store.peekAll('admin-pageview'), viewKey);

  if (hasSeenView || isOverLimit(fingerprint)) {
    if (callback) {
      callback(hasSeenView);
    }
    return Promise.resolve(hasSeenView);
  }

  return fetchAdminPageviews(store, fingerprint)
    .then((pageviews) => {
      let hasSeenView = adminHasSeenFromPageviews(pageviews, viewKey);
      if (callback) {
        callback(hasSeenView);
      }
      return hasSeenView;
    })
    .catch((e) => {
      console.error(
        `Unable load admin-pageviews, unable to determine if ${viewKey} has been seen. Error was ${e.message}`,
      );
      captureException(e, {
        fingerprint: ['admin-view-tracking', ...fingerprint],
        extra: {
          viewKey,
        },
      });
    });
}

function fetchAdminPageviews(store, fingerprint = ['fetchAdminPageviews']) {
  if (checkCallLimit(fingerprint)) {
    return Promise.resolve(store.peekAll('admin-pageview'));
  }
  return store.findAll('admin-pageview').catch((e) => {
    console.error(`Unable load admin-pageviews. Error was ${e.message}`);
    captureException(e, {
      fingerprint: ['admin-view-tracking', ...fingerprint],
    });
    // Fallback to returning whatever is already in the store
    return Promise.resolve(store.peekAll('admin-pageview'));
  });
}

function adminHasSeenFromPageviews(pageviews, viewKey) {
  return !!_getPageviewByKey(pageviews, viewKey);
}

function _getPageviewByKey(pageviews, viewKey) {
  return pageviews.find((pageview) => {
    return pageview.get('pageKey') === viewKey;
  });
}

export {
  adminHasSeen,
  markViewAsSeen,
  fetchAdminPageviews,
  adminHasSeenFromPageviews,
  _setMaxCallsPerTimePeriod,
  _resetLimits,
};
