/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
import EmbercomBootDataModule from 'embercom/lib/embercom-boot-data';
import ENV from 'embercom/config/environment';

const IS_EMBERCOM = !EmbercomBootDataModule.isBootedInPublicPage();

export default {
  name: 'serviceWorker',
  isActive: true, //set to false to uninstall the service worker
  initialize() {
    if (this._isSupported()) {
      if (this.isActive) {
        this._register();
      } else {
        this._unregister();
      }
    }
  },
  _register() {
    navigator.serviceWorker.register(`/embercom-service-worker.js`, { scope: './' }).then(() => {
      console.debug('SERVICE WORKER REGISTER: ✅');
    });
  },
  _unregister() {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        console.debug('SERVICE WORKER UNREGISTER');
        registration.unregister();
      }
    });
  },
  _isSupported() {
    return ENV.environment !== 'test' && 'serviceWorker' in navigator && IS_EMBERCOM;
  },
};
