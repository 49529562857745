/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
export function variationDeactivateSuccess(variation) {
  return `Version ${variation.get('label')} of your message is now paused.`;
}

export function variationDeactivateFailure(variation) {
  return `We couldn't pause Version ${variation.get(
    'label',
  )}. Send us a message and we'll do our best to help out.`;
}
