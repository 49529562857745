/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
export function messageStartABTestSuccess() {
  return `The B version of your message was created`;
}

export function messageStartABTestFailure() {
  return `We couldn't create the B version of your message. Send us a message and we'll do our best to help out.`;
}
