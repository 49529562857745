/* RESPONSIBLE TEAM: team-messenger */
import ValueFormatter from './value';

export default class ValueSingleFormatter extends ValueFormatter {
  formatTooltip(data, override) {
    let formattedData = this.formatData(data);
    let displayUnit = override || this.displayUnit;
    if (displayUnit) {
      return `${formattedData} ${displayUnit}`;
    }

    return formattedData;
  }
}
