/* RESPONSIBLE TEAM: team-tickets-1 */
export const REALTIME_DASHBOARD_TEAMS_VIEWS_COLUMNS = [
  {
    labelKey: 'inbox.dashboard.inbox-overview.table.name',
    valuePath: 'name',
    type: 'avatar-with-text',
    minWidth: '200px',
    hideable: false,
  },
  {
    labelKey: 'inbox.dashboard.inbox-overview.table.active-teammates.label',
    valuePath: 'activeTeammates',
    tooltipKey: 'inbox.dashboard.inbox-overview.table.active-teammates.tooltip',
    metricType: 'active_teammates',
    hideable: true,
  },
  {
    labelKey: 'inbox.dashboard.inbox-overview.table.phone-available-teammates.label',
    valuePath: 'phoneAvailableTeammates',
    tooltipKey: 'inbox.dashboard.inbox-overview.table.phone-available-teammates.tooltip',
    metricType: 'phone_available_teammates',
    category: 'calls',
    hideable: true,
  },
  {
    labelKey: 'inbox.dashboard.inbox-overview.table.unassigned.label',
    valuePath: 'unassigned',
    tooltipKey: 'inbox.dashboard.inbox-overview.table.unassigned.tooltip',

    metricType: 'unassigned',
    hideable: true,
  },
  {
    labelKey: 'inbox.dashboard.inbox-overview.table.waiting-for-first-reply.label',
    valuePath: 'waitingForReply',
    tooltipKey: 'inbox.dashboard.inbox-overview.table.waiting-for-first-reply.tooltip',

    metricType: 'waiting_for_first_admin_reply',
    hideable: true,
    labelCanWrap: true,
    minWidth: '200px',
  },
  {
    labelKey: 'inbox.dashboard.inbox-overview.table.total-open.label',
    valuePath: 'open',
    tooltipKey: 'inbox.dashboard.inbox-overview.table.total-open.tooltip',

    metricType: 'open',
    hideable: true,
  },
  {
    labelKey: 'inbox.dashboard.inbox-overview.table.idle.label',
    valuePath: 'idle',
    tooltipKey: 'inbox.dashboard.inbox-overview.table.idle.tooltip',

    minWidth: '100px',
    metricType: 'idle',
    hideable: true,
  },
  {
    labelKey: 'inbox.dashboard.inbox-overview.table.snoozed.label',
    valuePath: 'snoozed',
    metricType: 'snoozed',
    tooltipKey: 'inbox.dashboard.inbox-overview.table.snoozed.tooltip',

    hideable: true,
  },
  {
    labelKey: 'inbox.dashboard.inbox-overview.table.longest-waiting.label',
    valuePath: 'longestWaiting',
    tooltipKey: 'inbox.dashboard.inbox-overview.table.longest-waiting.tooltip',

    metricType: 'longest_waiting',
    hideable: true,
    labelCanWrap: true,
    minWidth: '250px',

    sortKey: 'longestWaitingTimestamp',
  },
  {
    labelKey: 'inbox.dashboard.inbox-overview.table.sla.label',
    valuePath: 'slaMissRate',
    tooltipKey: 'inbox.dashboard.inbox-overview.table.sla.tooltip',
    metricType: 'sla',
    hideable: true,

    sortKey: 'missRate',
  },
  {
    labelKey: 'inbox.dashboard.inbox-overview.table.first-response-time.label',
    valuePath: 'avgWaitTime',
    tooltipKey: 'inbox.dashboard.inbox-overview.table.first-response-time.tooltip',
    hideable: true,
    labelCanWrap: true,
    minWidth: '190px',
    metricType: 'avg_wait_time',

    sortKey: 'avgWaitTimeInSeconds',
  },
  {
    labelKey: 'inbox.dashboard.inbox-overview.table.closed.label',
    valuePath: 'closed',
    tooltipKey: 'inbox.dashboard.inbox-overview.table.closed.tooltip',
    metricType: 'closed',
    hideable: true,
  },
  {
    labelKey: 'inbox.dashboard.inbox-overview.table.csat.label',
    valuePath: 'csat',
    tooltipKey: 'inbox.dashboard.inbox-overview.table.csat.tooltip',
    metricType: 'csat',
    hideable: true,
  },
  {
    labelKey: 'inbox.dashboard.inbox-overview.table.calls-in-queue.label',
    valuePath: 'callsInQueue',
    tooltipKey: 'inbox.dashboard.inbox-overview.table.calls-in-queue.tooltip',
    metricType: 'calls_in_queue',
    hideable: true,
    category: 'calls',
  },
  {
    labelKey: 'inbox.dashboard.inbox-overview.table.calls-active.label',
    valuePath: 'callsActive',
    tooltipKey: 'inbox.dashboard.inbox-overview.table.calls-active.tooltip',
    metricType: 'calls_active',
    hideable: true,
    category: 'calls',
  },
  {
    labelKey: 'inbox.dashboard.inbox-overview.table.calls-completed.label',
    valuePath: 'callsCompleted',
    tooltipKey: 'inbox.dashboard.inbox-overview.table.calls-completed.tooltip',
    metricType: 'calls_completed',
    hideable: true,
    category: 'calls',
  },
  {
    labelKey: 'inbox.dashboard.inbox-overview.table.calls-abandoned.label',
    valuePath: 'callsAbandoned',
    tooltipKey: 'inbox.dashboard.inbox-overview.table.calls-abandoned.tooltip',
    metricType: 'calls_abandoned',
    hideable: true,
    category: 'calls',
  },
  {
    labelKey: 'inbox.dashboard.inbox-overview.table.voicemail-calls.label',
    valuePath: 'voicemailCalls',
    tooltipKey: 'inbox.dashboard.inbox-overview.table.voicemail-calls.tooltip',
    metricType: 'voicemail_calls',
    hideable: true,
    category: 'calls',
  },
  {
    labelKey: 'inbox.dashboard.inbox-overview.table.calls-team-queue-time.label',
    valuePath: 'callsTeamQueueTime',
    tooltipKey: 'inbox.dashboard.inbox-overview.table.calls-team-queue-time.tooltip',
    metricType: 'calls_team_queue_time',
    hideable: true,
    category: 'calls',
  },
  {
    labelKey: 'inbox.dashboard.inbox-overview.table.calls-answer-time.label',
    valuePath: 'callsAnswerTime',
    tooltipKey: 'inbox.dashboard.inbox-overview.table.calls-answer-time.tooltip',
    metricType: 'calls_answer_time',
    hideable: true,
    category: 'calls',
  },
  {
    labelKey: 'inbox.dashboard.inbox-overview.table.calls-team-talk-time.label',
    valuePath: 'callsTeamTalkTime',
    tooltipKey: 'inbox.dashboard.inbox-overview.table.calls-team-talk-time.tooltip',
    metricType: 'calls_team_talk_time',
    hideable: true,
    category: 'calls',
  },
  {
    labelKey: 'inbox.dashboard.inbox-overview.table.answered-callbacks.label',
    valuePath: 'callbacksAnswered',
    tooltipKey: 'inbox.dashboard.inbox-overview.table.answered-callbacks.tooltip',
    metricType: 'callbacks_answered',
    hideable: true,
    category: 'calls',
  },
  {
    labelKey: 'inbox.dashboard.inbox-overview.table.unanswered-callbacks.label',
    valuePath: 'callbacksUnanswered',
    tooltipKey: 'inbox.dashboard.inbox-overview.table.unanswered-callbacks.tooltip',
    metricType: 'callbacks_unanswered',
    hideable: true,
    category: 'calls',
  },
  {
    labelKey: 'inbox.dashboard.inbox-overview.table.pending-callbacks.label',
    valuePath: 'callbacksPending',
    tooltipKey: 'inbox.dashboard.inbox-overview.table.pending-callbacks.tooltip',
    metricType: 'callbacks_pending',
    hideable: true,
    category: 'calls',
  },
  {
    labelKey: 'inbox.dashboard.inbox-overview.table.closed-callbacks.label',
    valuePath: 'callbacksClosed',
    tooltipKey: 'inbox.dashboard.inbox-overview.table.closed-callbacks.tooltip',
    metricType: 'callbacks_closed',
    hideable: true,
    category: 'calls',
  },
];

export const REALTIME_DASHBOARD_TEAMS_VIEWS_COLUMNS_GROUPED = [
  {
    headingKey: 'inbox.dashboard.inbox-overview.table.name',
    hideGroup: true,
    items: [
      {
        valuePath: 'name',
        type: 'avatar-with-text',
        minWidth: '200px',
        hideable: false,
        labelKey: 'inbox.dashboard.inbox-overview.table.name',
        tooltipKey: 'inbox.dashboard.inbox-overview.table.name',
      },
    ],
  },
  {
    headingKey: 'inbox.dashboard.inbox-overview.table.group.teammates',
    hideGroup: false,
    items: [
      {
        labelKey: 'inbox.dashboard.inbox-overview.table.active-teammates.label',
        valuePath: 'activeTeammates',
        tooltipKey: 'inbox.dashboard.inbox-overview.table.active-teammates.tooltip',
        metricType: 'active_teammates',
        hideable: true,
      },
      {
        labelKey: 'inbox.dashboard.inbox-overview.table.phone-available-teammates.label',
        valuePath: 'phoneAvailableTeammates',
        tooltipKey: 'inbox.dashboard.inbox-overview.table.phone-available-teammates.tooltip',
        metricType: 'phone_available_teammates',
        hideable: true,
      },
    ],
  },
  {
    headingKey: 'inbox.dashboard.inbox-overview.table.group.conversations-volums',
    hideGroup: false,
    items: [
      {
        labelKey: 'inbox.dashboard.inbox-overview.table.waiting-for-first-reply.label',
        valuePath: 'waitingForReply',
        tooltipKey: 'inbox.dashboard.inbox-overview.table.waiting-for-first-reply.tooltip',

        metricType: 'waiting_for_first_admin_reply',
        hideable: true,
        labelCanWrap: true,
        minWidth: '200px',
      },
      {
        labelKey: 'inbox.dashboard.inbox-overview.table.unassigned.label',
        valuePath: 'unassigned',
        tooltipKey: 'inbox.dashboard.inbox-overview.table.unassigned.tooltip',

        metricType: 'unassigned',
        hideable: true,
      },
      {
        labelKey: 'inbox.dashboard.inbox-overview.table.total-open.label',
        valuePath: 'open',
        tooltipKey: 'inbox.dashboard.inbox-overview.table.total-open.tooltip',

        metricType: 'open',
        hideable: true,
      },
      {
        labelKey: 'inbox.dashboard.inbox-overview.table.idle.label',
        valuePath: 'idle',
        tooltipKey: 'inbox.dashboard.inbox-overview.table.idle.tooltip',

        minWidth: '100px',
        metricType: 'idle',
        hideable: true,
      },
      {
        labelKey: 'inbox.dashboard.inbox-overview.table.snoozed.label',
        valuePath: 'snoozed',
        metricType: 'snoozed',
        tooltipKey: 'inbox.dashboard.inbox-overview.table.snoozed.tooltip',

        hideable: true,
      },
    ],
  },
  {
    headingKey: 'inbox.dashboard.inbox-overview.table.group.conversations-performance',
    hideGroup: false,
    items: [
      {
        labelKey: 'inbox.dashboard.inbox-overview.table.first-response-time.label',
        valuePath: 'avgWaitTime',
        tooltipKey: 'inbox.dashboard.inbox-overview.table.first-response-time.tooltip',
        hideable: true,
        labelCanWrap: true,
        minWidth: '190px',
        metricType: 'avg_wait_time',

        sortKey: 'avgWaitTimeInSeconds',
      },
      {
        labelKey: 'inbox.dashboard.inbox-overview.table.longest-waiting.label',
        valuePath: 'longestWaiting',
        tooltipKey: 'inbox.dashboard.inbox-overview.table.longest-waiting.tooltip',

        metricType: 'longest_waiting',
        hideable: true,
        labelCanWrap: true,
        minWidth: '250px',

        sortKey: 'longestWaitingTimestamp',
      },
      {
        labelKey: 'inbox.dashboard.inbox-overview.table.sla.label',
        valuePath: 'slaMissRate',
        tooltipKey: 'inbox.dashboard.inbox-overview.table.sla.tooltip',
        metricType: 'sla',
        hideable: true,

        sortKey: 'missRate',
      },
      {
        labelKey: 'inbox.dashboard.inbox-overview.table.closed.label',
        valuePath: 'closed',
        tooltipKey: 'inbox.dashboard.inbox-overview.table.closed.tooltip',
        metricType: 'closed',
        hideable: true,
      },
      {
        labelKey: 'inbox.dashboard.inbox-overview.table.csat.label',
        valuePath: 'csat',
        tooltipKey: 'inbox.dashboard.inbox-overview.table.csat.tooltip',
        metricType: 'csat',
        hideable: true,
      },
    ],
  },
  {
    headingKey: 'inbox.dashboard.inbox-overview.table.group.inbound-call-volume',
    hideGroup: false,
    items: [
      {
        labelKey: 'inbox.dashboard.inbox-overview.table.calls-in-queue.label',
        valuePath: 'callsInQueue',
        tooltipKey: 'inbox.dashboard.inbox-overview.table.calls-in-queue.tooltip',
        metricType: 'calls_in_queue',
        hideable: true,
        category: 'calls',
      },
      {
        labelKey: 'inbox.dashboard.inbox-overview.table.calls-active.label',
        valuePath: 'callsActive',
        tooltipKey: 'inbox.dashboard.inbox-overview.table.calls-active.tooltip',
        metricType: 'calls_active',
        hideable: true,
        category: 'calls',
      },
      {
        labelKey: 'inbox.dashboard.inbox-overview.table.calls-completed.label',
        valuePath: 'callsCompleted',
        tooltipKey: 'inbox.dashboard.inbox-overview.table.calls-completed.tooltip',
        metricType: 'calls_completed',
        hideable: true,
        category: 'calls',
      },
      {
        labelKey: 'inbox.dashboard.inbox-overview.table.calls-abandoned.label',
        valuePath: 'callsAbandoned',
        tooltipKey: 'inbox.dashboard.inbox-overview.table.calls-abandoned.tooltip',
        metricType: 'calls_abandoned',
        hideable: true,
        category: 'calls',
      },
      {
        labelKey: 'inbox.dashboard.inbox-overview.table.voicemail-calls.label',
        valuePath: 'voicemailCalls',
        tooltipKey: 'inbox.dashboard.inbox-overview.table.voicemail-calls.tooltip',
        metricType: 'voicemail_calls',
        hideable: true,
        category: 'calls',
      },
      {
        labelKey: 'inbox.dashboard.inbox-overview.table.answered-callbacks.label',
        valuePath: 'callbacksAnswered',
        tooltipKey: 'inbox.dashboard.inbox-overview.table.answered-callbacks.tooltip',
        metricType: 'callbacks_answered',
        hideable: true,
        category: 'calls',
      },
      {
        labelKey: 'inbox.dashboard.inbox-overview.table.unanswered-callbacks.label',
        valuePath: 'callbacksUnanswered',
        tooltipKey: 'inbox.dashboard.inbox-overview.table.unanswered-callbacks.tooltip',
        metricType: 'callbacks_unanswered',
        hideable: true,
        category: 'calls',
      },
      {
        labelKey: 'inbox.dashboard.inbox-overview.table.pending-callbacks.label',
        valuePath: 'callbacksPending',
        tooltipKey: 'inbox.dashboard.inbox-overview.table.pending-callbacks.tooltip',
        metricType: 'callbacks_pending',
        hideable: true,
        category: 'calls',
      },
      {
        labelKey: 'inbox.dashboard.inbox-overview.table.closed-callbacks.label',
        valuePath: 'callbacksClosed',
        tooltipKey: 'inbox.dashboard.inbox-overview.table.closed-callbacks.tooltip',
        metricType: 'callbacks_closed',
        hideable: true,
        category: 'calls',
      },
    ],
  },
  {
    headingKey: 'inbox.dashboard.inbox-overview.table.group.inbound-call-performance',
    hideGroup: false,
    items: [
      {
        labelKey: 'inbox.dashboard.inbox-overview.table.calls-team-queue-time.label',
        valuePath: 'callsTeamQueueTime',
        tooltipKey: 'inbox.dashboard.inbox-overview.table.calls-team-queue-time.tooltip',
        metricType: 'calls_team_queue_time',
        hideable: true,
        category: 'calls',
      },
      {
        labelKey: 'inbox.dashboard.inbox-overview.table.calls-answer-time.label',
        valuePath: 'callsAnswerTime',
        tooltipKey: 'inbox.dashboard.inbox-overview.table.calls-answer-time.tooltip',
        metricType: 'calls_answer_time',
        hideable: true,
        category: 'calls',
      },
      {
        labelKey: 'inbox.dashboard.inbox-overview.table.calls-team-talk-time.label',
        valuePath: 'callsTeamTalkTime',
        tooltipKey: 'inbox.dashboard.inbox-overview.table.calls-team-talk-time.tooltip',
        metricType: 'calls_team_talk_time',
        hideable: true,
        category: 'calls',
      },
    ],
  },
];

export const REALTIME_DASHBOARD_TEAMS_VIEWS_AGGREGATION_METRICS = [
  {
    titleKey: 'inbox.dashboard.inbox-overview.summary-card.waiting-for-first-reply',
    valueKey: 'waitingForFirstReply',
    metricType: 'primary',
    tooltipContentKey:
      'inbox.dashboard.inbox-overview.summary-card.waiting-for-first-reply-tooltip',
    category: 'conversations',
  },
  {
    titleKey: 'inbox.dashboard.inbox-overview.summary-card.unassigned',
    valueKey: 'unassigned',
    metricType: 'primary',
    tooltipContentKey: 'inbox.dashboard.inbox-overview.summary-card.unassigned-tooltip',
    category: 'conversations',
  },
  {
    titleKey: 'inbox.dashboard.inbox-overview.summary-card.open',
    valueKey: 'open',
    metricType: 'primary',
    icon: 'inbox',
    tooltipContentKey: 'inbox.dashboard.inbox-overview.summary-card.open-tooltip',
    category: 'conversations',
  },
  {
    titleKey: 'inbox.dashboard.inbox-overview.summary-card.idle',
    valueKey: 'idlePercentage',
    metricType: 'primary',
    icon: 'conversation',
    tooltipContentKey: 'inbox.dashboard.inbox-overview.summary-card.idle-tooltip',
    qualifier: '%',
    category: 'conversations',
  },
  {
    titleKey: 'inbox.dashboard.inbox-overview.summary-card.snoozed',
    valueKey: 'snoozed',
    metricType: 'primary',
    icon: 'clock-15',
    tooltipContentKey: 'inbox.dashboard.inbox-overview.summary-card.snoozed-tooltip',
    category: 'conversations',
  },
  {
    titleKey: 'inbox.dashboard.inbox-overview.summary-card.first-response-time',
    valueKey: 'avgWaitTime',
    metricType: 'primary',
    tooltipContentKey: 'inbox.dashboard.inbox-overview.summary-card.first-response-time-tooltip',
    category: 'conversations',
  },
  {
    titleKey: 'inbox.dashboard.inbox-overview.summary-card.sla',
    valueKey: 'sla',
    metricType: 'primary',
    tooltipContentKey: 'inbox.dashboard.inbox-overview.summary-card.sla-tooltip',
    qualifier: '%',
    category: 'conversations',
  },
  {
    titleKey: 'inbox.dashboard.inbox-overview.summary-card.closed',
    valueKey: 'closed',
    metricType: 'primary',
    icon: 'following',
    tooltipContentKey: 'inbox.dashboard.inbox-overview.summary-card.closed-tooltip',
    category: 'conversations',
  },
  {
    titleKey: 'inbox.dashboard.inbox-overview.summary-card.csat',
    valueKey: 'csat',
    metricType: 'primary',
    icon: 'thumbsup',
    tooltipContentKey: 'inbox.dashboard.inbox-overview.summary-card.csat-tooltip',
    qualifier: '%',
    category: 'conversations',
  },
  {
    titleKey: 'inbox.dashboard.inbox-overview.summary-card.phone-available-teammates',
    valueKey: 'phoneAvailableTeammates',
    metricType: 'primary',
    tooltipContentKey:
      'inbox.dashboard.inbox-overview.summary-card.phone-available-teammates-tooltip',
    category: 'calls',
  },
  {
    titleKey: 'inbox.dashboard.inbox-overview.summary-card.calls-in-queue',
    valueKey: 'callsInQueue',
    metricType: 'primary',
    tooltipContentKey: 'inbox.dashboard.inbox-overview.summary-card.calls-in-queue-tooltip',
    category: 'calls',
  },
  {
    titleKey: 'inbox.dashboard.inbox-overview.summary-card.calls-active',
    valueKey: 'callsActive',
    metricType: 'primary',
    tooltipContentKey: 'inbox.dashboard.inbox-overview.summary-card.calls-active-tooltip',
    category: 'calls',
  },
  {
    titleKey: 'inbox.dashboard.inbox-overview.summary-card.calls-completed',
    valueKey: 'callsCompleted',
    metricType: 'primary',
    tooltipContentKey: 'inbox.dashboard.inbox-overview.summary-card.calls-completed-tooltip',
    category: 'calls',
  },
  {
    titleKey: 'inbox.dashboard.inbox-overview.summary-card.calls-abandoned',
    valueKey: 'callsAbandoned',
    metricType: 'primary',
    tooltipContentKey: 'inbox.dashboard.inbox-overview.summary-card.calls-abandoned-tooltip',
    category: 'calls',
  },
  {
    titleKey: 'inbox.dashboard.inbox-overview.summary-card.voicemail-calls',
    valueKey: 'voicemailCalls',
    metricType: 'primary',
    tooltipContentKey: 'inbox.dashboard.inbox-overview.summary-card.voicemail-calls-tooltip',
    category: 'calls',
  },
  {
    titleKey: 'inbox.dashboard.inbox-overview.summary-card.calls-duration',
    valueKey: 'callsDuration',
    metricType: 'primary',
    tooltipContentKey: 'inbox.dashboard.inbox-overview.summary-card.calls-duration-tooltip',
    category: 'calls',
  },
  {
    titleKey: 'inbox.dashboard.inbox-overview.summary-card.calls-answer-time',
    valueKey: 'callsAnswerTime',
    metricType: 'primary',
    tooltipContentKey: 'inbox.dashboard.inbox-overview.summary-card.calls-answer-time-tooltip',
    category: 'calls',
  },
  {
    titleKey: 'inbox.dashboard.inbox-overview.summary-card.callbacks-pending',
    valueKey: 'callbacksPending',
    metricType: 'primary',
    tooltipContentKey: 'inbox.dashboard.inbox-overview.summary-card.callbacks-pending-tooltip',
    category: 'calls',
  },
  {
    titleKey: 'inbox.dashboard.inbox-overview.summary-card.callbacks-answered',
    valueKey: 'callbacksAnswered',
    metricType: 'primary',
    tooltipContentKey: 'inbox.dashboard.inbox-overview.summary-card.callbacks-answered-tooltip',
    category: 'calls',
  },
  {
    titleKey: 'inbox.dashboard.inbox-overview.summary-card.callbacks-unanswered',
    valueKey: 'callbacksUnanswered',
    metricType: 'primary',
    tooltipContentKey: 'inbox.dashboard.inbox-overview.summary-card.callbacks-answered-tooltip',
    category: 'calls',
  },
  {
    titleKey: 'inbox.dashboard.inbox-overview.summary-card.callbacks-closed',
    valueKey: 'callbacksClosed',
    metricType: 'primary',
    tooltipContentKey: 'inbox.dashboard.inbox-overview.summary-card.callbacks-closed-tooltip',
    category: 'calls',
  },
];

export const REALTIME_DASHBOARD_TEAMMATES_COLUMNS = [
  {
    labelKey: 'inbox.dashboard.teammate-activity.table.name',
    valuePath: 'name',
    hideable: false,
  },
  {
    labelKey: 'inbox.dashboard.teammate-activity.table.status',
    valuePath: 'status',
    sortKey: 'statusCode',
    hideable: true,
  },
  {
    labelKey: 'inbox.dashboard.teammate-activity.table.channel-availability.label',
    valuePath: 'channelAvailabilityCode',
    sortKey: 'channelAvailabilityCode',
    category: 'calls',
    hideable: true,
  },
  {
    labelKey: 'inbox.dashboard.teammate-activity.table.time-on-status.label',
    valuePath: 'timeOnStatus',
    sortKey: 'timeOnStatusInSeconds',
    tooltipKey: 'inbox.dashboard.teammate-activity.table.time-on-status.tooltip',
    hideable: true,
  },
  {
    labelKey: 'inbox.dashboard.teammate-activity.table.time-active.label',
    valuePath: 'totalActiveTime',
    sortKey: 'totalActiveTimeInSeconds',
    tooltipKey: 'inbox.dashboard.teammate-activity.table.time-active.tooltip',
    hideable: true,
  },
  {
    labelKey: 'inbox.dashboard.teammate-activity.table.time-away.label',
    valuePath: 'totalAwayTime',
    sortKey: 'totalAwayTime',
    tooltipKey: 'inbox.dashboard.teammate-activity.table.time-away.tooltip',
    hideable: true,
    featureFlags: ['team-sops-realtime-conversations-per-hour'],
  },
  {
    labelKey: 'inbox.dashboard.teammate-activity.table.first-online.label',
    valuePath: 'firstActiveTime',
    sortKey: 'firstActiveTimeInSeconds',
    tooltipKey: 'inbox.dashboard.teammate-activity.table.first-online.tooltip',
    hideable: true,
  },
  {
    labelKey: 'inbox.dashboard.teammate-activity.table.last-seen.label',
    valuePath: 'lastSeen',
    sortKey: 'lastSeenInSeconds',
    minWidth: '150px',
    tooltipKey: 'inbox.dashboard.teammate-activity.table.last-seen.tooltip',
    hideable: true,
  },
  {
    labelKey: 'inbox.dashboard.teammate-activity.table.open.label',
    valuePath: 'openConversations',
    tooltipKey: 'inbox.dashboard.teammate-activity.table.open.tooltip',
    metricType: 'open',
    hideable: true,
  },
  {
    labelKey: 'inbox.dashboard.teammate-activity.table.idle.label',
    valuePath: 'idle',
    tooltipKey: 'inbox.dashboard.inbox-overview.table.idle.tooltip',
    metricType: 'idle',
    hideable: true,
  },
  {
    labelKey: 'inbox.dashboard.teammate-activity.table.snoozed.label',
    valuePath: 'snoozedConversations',
    tooltipKey: 'inbox.dashboard.teammate-activity.table.snoozed.tooltip',
    metricType: 'snoozed',
    hideable: true,
  },
  {
    labelKey: 'inbox.dashboard.teammate-activity.table.workload.label',
    valuePath: 'workload',
    sortKey: 'workload',
    minWidth: '150px',
    tooltipKey: 'inbox.dashboard.teammate-activity.table.workload.tooltip',
    metricType: 'workload',
    hideable: true,
  },
  {
    labelKey: 'inbox.dashboard.teammate-activity.table.participated-in.label',
    valuePath: 'participatedIn',
    metricType: 'participated_in',
    tooltipKey: 'inbox.dashboard.teammate-activity.table.participated-in.tooltip',
    hideable: true,
  },
  {
    labelKey: 'inbox.dashboard.teammate-activity.table.participated-in-per-hour.label',
    valuePath: 'participatedInPerHour',
    sortKey: 'participatedInPerHour',
    tooltipKey: 'inbox.dashboard.teammate-activity.table.participated-in-per-hour.tooltip',
    hideable: true,
    featureFlags: ['team-sops-realtime-conversations-per-hour'],
  },
  {
    labelKey: 'inbox.dashboard.teammate-activity.table.closed.label',
    valuePath: 'closedConversations',
    tooltipKey: 'inbox.dashboard.teammate-activity.table.closed.tooltip',
    metricType: 'closed',
    hideable: true,
  },
  {
    labelKey: 'inbox.dashboard.teammate-activity.table.closed-per-hour.label',
    valuePath: 'closedPerHour',
    sortKey: 'closedPerHour',
    tooltipKey: 'inbox.dashboard.teammate-activity.table.closed-per-hour.tooltip',
    hideable: true,
    featureFlags: ['team-sops-realtime-conversations-per-hour'],
  },
  {
    labelKey: 'inbox.dashboard.teammate-activity.table.first-response-time.label',
    valuePath: 'avgFirstResponseTime',
    sortKey: 'avgFirstResponseTimeInSeconds',
    tooltipKey: 'inbox.dashboard.teammate-activity.table.first-response-time.tooltip',
    metricType: 'avg_wait_time',
    hideable: true,
    labelCanWrap: true,
    minWidth: '190px',
  },

  {
    labelKey: 'inbox.dashboard.teammate-activity.table.time-to-subsequent-response.label',
    valuePath: 'avgTimeToSubsequentResponse',
    sortKey: 'avgTimeToSubsequentResponseInSeconds',
    tooltipKey: 'inbox.dashboard.teammate-activity.table.time-to-subsequent-response.tooltip',
    metricType: 'avg_time_to_subsequent_response',
    hideable: true,
    labelCanWrap: true,
    minWidth: '210px',
  },

  {
    labelKey: 'inbox.dashboard.teammate-activity.table.csat.label',
    valuePath: 'csat',
    tooltipKey: 'inbox.dashboard.teammate-activity.table.csat.tooltip',
    metricType: 'csat',
    hideable: true,
  },
  {
    labelKey: 'inbox.dashboard.teammate-activity.table.calls-talk-time.label',
    valuePath: 'callsTalkTime',
    tooltipKey: 'inbox.dashboard.teammate-activity.table.calls-talk-time.tooltip',
    metricType: 'calls_talk_time',
    category: 'calls',
    hideable: true,
  },
];

export const REALTIME_DASHBOARD_TEAMMATES_COLUMNS_GROUPED = [
  {
    headingKey: 'inbox.dashboard.teammate-activity.table.name',
    hideGroup: true,
    items: [
      {
        labelKey: 'inbox.dashboard.teammate-activity.table.name',
        valuePath: 'name',
        hideable: false,
        tooltipKey: undefined,
      },
    ],
  },
  {
    headingKey: 'inbox.dashboard.teammate-activity.table.group.teammate-availability',
    hideGroup: false,
    items: [
      {
        labelKey: 'inbox.dashboard.teammate-activity.table.status',
        valuePath: 'status',
        sortKey: 'statusCode',
        hideable: true,
      },
      {
        labelKey: 'inbox.dashboard.teammate-activity.table.channel-availability.label',
        valuePath: 'channelAvailabilityCode',
        sortKey: 'channelAvailabilityCode',
        category: 'calls',
        hideable: true,
      },
      {
        labelKey: 'inbox.dashboard.teammate-activity.table.time-on-status.label',
        valuePath: 'timeOnStatus',
        sortKey: 'timeOnStatusInSeconds',
        tooltipKey: 'inbox.dashboard.teammate-activity.table.time-on-status.tooltip',
        hideable: true,
      },
      {
        labelKey: 'inbox.dashboard.teammate-activity.table.time-active.label',
        valuePath: 'totalActiveTime',
        sortKey: 'totalActiveTimeInSeconds',
        tooltipKey: 'inbox.dashboard.teammate-activity.table.time-active.tooltip',
        hideable: true,
      },
      {
        labelKey: 'inbox.dashboard.teammate-activity.table.time-away.label',
        valuePath: 'totalAwayTime',
        sortKey: 'totalAwayTime',
        tooltipKey: 'inbox.dashboard.teammate-activity.table.time-away.tooltip',
        hideable: true,
        featureFlags: ['team-sops-realtime-conversations-per-hour'],
      },
      {
        labelKey: 'inbox.dashboard.teammate-activity.table.first-online.label',
        valuePath: 'firstActiveTime',
        sortKey: 'firstActiveTimeInSeconds',
        tooltipKey: 'inbox.dashboard.teammate-activity.table.first-online.tooltip',
        hideable: true,
      },
      {
        labelKey: 'inbox.dashboard.teammate-activity.table.last-seen.label',
        valuePath: 'lastSeen',
        sortKey: 'lastSeenInSeconds',
        minWidth: '150px',
        tooltipKey: 'inbox.dashboard.teammate-activity.table.last-seen.tooltip',
        hideable: true,
      },
    ],
  },
  {
    headingKey: 'inbox.dashboard.teammate-activity.table.group.conversations',
    hideGroup: false,
    items: [
      {
        labelKey: 'inbox.dashboard.teammate-activity.table.open.label',
        valuePath: 'openConversations',
        tooltipKey: 'inbox.dashboard.teammate-activity.table.open.tooltip',
        metricType: 'open',
        hideable: true,
      },
      {
        labelKey: 'inbox.dashboard.teammate-activity.table.idle.label',
        valuePath: 'idle',
        tooltipKey: 'inbox.dashboard.inbox-overview.table.idle.tooltip',
        metricType: 'idle',
        hideable: true,
      },
      {
        labelKey: 'inbox.dashboard.teammate-activity.table.snoozed.label',
        valuePath: 'snoozedConversations',
        tooltipKey: 'inbox.dashboard.teammate-activity.table.snoozed.tooltip',
        metricType: 'snoozed',
        hideable: true,
      },
      {
        labelKey: 'inbox.dashboard.teammate-activity.table.workload.label',
        valuePath: 'workload',
        sortKey: 'workload',
        minWidth: '150px',
        tooltipKey: 'inbox.dashboard.teammate-activity.table.workload.tooltip',
        metricType: 'workload',
        hideable: true,
      },
      {
        labelKey: 'inbox.dashboard.teammate-activity.table.participated-in.label',
        valuePath: 'participatedIn',
        metricType: 'participated_in',
        tooltipKey: 'inbox.dashboard.teammate-activity.table.participated-in.tooltip',
        hideable: true,
      },
      {
        labelKey: 'inbox.dashboard.teammate-activity.table.participated-in-per-hour.label',
        valuePath: 'participatedInPerHour',
        sortKey: 'participatedInPerHour',
        tooltipKey: 'inbox.dashboard.teammate-activity.table.participated-in-per-hour.tooltip',
        hideable: true,
        featureFlags: ['team-sops-realtime-conversations-per-hour'],
      },
      {
        labelKey: 'inbox.dashboard.teammate-activity.table.closed.label',
        valuePath: 'closedConversations',
        tooltipKey: 'inbox.dashboard.teammate-activity.table.closed.tooltip',
        metricType: 'closed',
        hideable: true,
      },
      {
        labelKey: 'inbox.dashboard.teammate-activity.table.closed-per-hour.label',
        valuePath: 'closedPerHour',
        sortKey: 'closedPerHour',
        tooltipKey: 'inbox.dashboard.teammate-activity.table.closed-per-hour.tooltip',
        hideable: true,
        featureFlags: ['team-sops-realtime-conversations-per-hour'],
      },
      {
        labelKey: 'inbox.dashboard.teammate-activity.table.first-response-time.label',
        valuePath: 'avgFirstResponseTime',
        sortKey: 'avgFirstResponseTimeInSeconds',
        tooltipKey: 'inbox.dashboard.teammate-activity.table.first-response-time.tooltip',
        metricType: 'avg_wait_time',
        hideable: true,
        labelCanWrap: true,
        minWidth: '190px',
      },
      {
        labelKey: 'inbox.dashboard.teammate-activity.table.time-to-subsequent-response.label',
        valuePath: 'avgTimeToSubsequentResponse',
        sortKey: 'avgTimeToSubsequentResponseInSeconds',
        tooltipKey: 'inbox.dashboard.teammate-activity.table.time-to-subsequent-response.tooltip',
        metricType: 'avg_time_to_subsequent_response',
        hideable: true,
        labelCanWrap: true,
        minWidth: '210px',
      },
      {
        labelKey: 'inbox.dashboard.teammate-activity.table.csat.label',
        valuePath: 'csat',
        tooltipKey: 'inbox.dashboard.teammate-activity.table.csat.tooltip',
        metricType: 'csat',
        hideable: true,
      },
    ],
  },
  {
    headingKey: 'inbox.dashboard.teammate-activity.table.group.inbound-call-performance',
    hideGroup: false,
    items: [
      {
        labelKey: 'inbox.dashboard.teammate-activity.table.calls-talk-time.label',
        valuePath: 'callsTalkTime',
        tooltipKey: 'inbox.dashboard.teammate-activity.table.calls-talk-time.tooltip',
        metricType: 'calls_talk_time',
        category: 'calls',
        hideable: true,
      },
    ],
  },
];

export const REALTIME_DASHBOARD_TEAMMATES_AGGREGATION_METRICS = [
  {
    titleKey: 'inbox.dashboard.teammate-activity.summary-card.active.label',
    valueKey: 'active',
    metricType: 'primary',
    statusIndicator: 'active',
    tooltipContentKey: 'inbox.dashboard.teammate-activity.summary-card.active.tooltip',
    category: 'conversations',
  },
  {
    titleKey: 'inbox.dashboard.teammate-activity.summary-card.away.label',
    valueKey: 'away',
    metricType: 'primary',
    statusIndicator: 'away',
    tooltipContentKey: 'inbox.dashboard.teammate-activity.summary-card.away.tooltip',
    awayReasonsKey: 'awayReasons',
    category: 'conversations',
  },
  {
    titleKey: 'inbox.dashboard.teammate-activity.summary-card.reassigning.label',
    valueKey: 'reassigning',
    metricType: 'primary',
    statusIndicator: 'reassigning',
    tooltipContentKey: 'inbox.dashboard.teammate-activity.summary-card.reassigning.tooltip',
    awayReasonsKey: 'reassigningReasons',
    category: 'conversations',
  },
  {
    titleKey: 'inbox.dashboard.teammate-activity.summary-card.open.label',
    valueKey: 'open',
    metricType: 'primary',
    tooltipContentKey: 'inbox.dashboard.teammate-activity.summary-card.open.tooltip',
    category: 'conversations',
  },
  {
    titleKey: 'inbox.dashboard.teammate-activity.summary-card.idle.label',
    valueKey: 'idlePercentage',
    qualifier: '%',
    metricType: 'primary',
    tooltipContentKey: 'inbox.dashboard.teammate-activity.summary-card.idle.tooltip',
    category: 'conversations',
  },
  {
    titleKey: 'inbox.dashboard.teammate-activity.summary-card.snoozed.label',
    valueKey: 'snoozed',
    metricType: 'primary',
    tooltipContentKey: 'inbox.dashboard.teammate-activity.summary-card.snoozed.tooltip',
    category: 'conversations',
  },
  {
    titleKey: 'inbox.dashboard.teammate-activity.summary-card.first-response-time.label',
    valueKey: 'avgWaitTime',
    metricType: 'primary',
    tooltipContentKey: 'inbox.dashboard.teammate-activity.summary-card.first-response-time.tooltip',
    category: 'conversations',
  },
  {
    titleKey: 'inbox.dashboard.teammate-activity.summary-card.time-to-subsequent-response.label',
    valueKey: 'avgTimeToSubsequentResponse',
    metricType: 'primary',
    tooltipContentKey:
      'inbox.dashboard.teammate-activity.summary-card.time-to-subsequent-response.tooltip',
    category: 'conversations',
  },
  {
    titleKey: 'inbox.dashboard.teammate-activity.summary-card.csat.label',
    valueKey: 'csat',
    qualifier: '%',
    metricType: 'primary',
    tooltipContentKey: 'inbox.dashboard.teammate-activity.summary-card.csat.tooltip',
    category: 'conversations',
  },
  {
    titleKey: 'inbox.dashboard.teammate-activity.summary-card.closed.label',
    valueKey: 'closed',
    metricType: 'primary',
    tooltipContentKey: 'inbox.dashboard.teammate-activity.summary-card.closed.tooltip',
    category: 'conversations',
  },
  {
    titleKey: 'inbox.dashboard.teammate-activity.summary-card.participated-in.label',
    valueKey: 'participatedIn',
    metricType: 'primary',
    tooltipContentKey: 'inbox.dashboard.teammate-activity.summary-card.participated-in.tooltip',
    category: 'conversations',
  },
  {
    titleKey: 'inbox.dashboard.teammate-activity.summary-card.calls-talk-time.label',
    valueKey: 'callsTalkTime',
    metricType: 'primary',
    tooltipContentKey: 'inbox.dashboard.teammate-activity.summary-card.calls-talk-time.tooltip',
    category: 'calls',
  },
];
