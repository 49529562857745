/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
const getDefaultSegment = function (segments, visibleSegmentIds, fallbackSegmentIdentifier) {
  // Active, if it's present and visible
  let segment = segments.findBy('name', 'Active');
  if (segment && visibleSegmentIds.includes(segment.id)) {
    return segment;
  }

  // otherwise, the first visible segment
  for (let id of visibleSegmentIds) {
    let segment = segments.findBy('id', id);
    if (segment) {
      return segment;
    }
  }

  // if no segments are visible then fallback to the one with the supplied identifier
  return segments.findBy('identifier', fallbackSegmentIdentifier);
};

export default getDefaultSegment;
