/* RESPONSIBLE TEAM: team-frontend-tech */
import { helper as buildHelper } from '@ember/component/helper';
import numericFormatter from 'embercom/lib/numeric-formatter';

const numericFormatterRawHelper = function (value, precision) {
  return numericFormatter(value, parseInt(precision, 10) || 0);
};

export { numericFormatterRawHelper };
export default buildHelper(function (params) {
  let value = params[0];
  let precision = params[1];
  return numericFormatter(value, parseInt(precision, 10) || 0);
});
