/* RESPONSIBLE TEAM: team-reporting */
import Tooltip from 'embercom/lib/reporting/flexible/tooltip';
import PALETTE from '@intercom/pulse/lib/palette';

export default class SankeychartBuilder {
  constructor({
    range,
    viewConfig,
    dataConfig,
    width = '',
    chartData = [{ data: [] }],
    spacingBottom = 75,
    tooltipFormatter,
    trackAnalyticsEvent,
  }) {
    this.range = range;
    this.width = width;
    this.chartData = chartData;
    this.viewConfig = viewConfig;
    this.dataConfig = dataConfig;
    this.spacingBottom = spacingBottom;
    this.tooltipFormatter = tooltipFormatter;
    this.trackAnalyticsEvent = trackAnalyticsEvent;
  }

  buildTheme() {
    return {
      title: '',
      credits: false,
      chart: {
        spacingLeft: 3,
        spacingRight: 160,
        spacingTop: 30,
        // the height is also used to define the min-height of the spinner
        // please reflect any changes in app/styles/structures/reporting/_flexible.scss
        height: 350,
        style: {
          fontFamily:
            'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
          fontSize: '12px',
          color: '#78909c',
        },
        spacingBottom: this.spacingBottom,
        events: {
          render: this.postProcessSankey,
        },
      },
      plotOptions: {
        sankey: {
          point: {
            events: {
              mouseOver: (event) => this.setHoverState(event, 'hover'),
              mouseOut: (event) => this.setHoverState(event, ''),
              click: this.trackAnalyticsEvent,
            },
          },
        },
      },
      tooltip: new Tooltip({
        formatter: this.tooltipFormatter,
        borderColor: PALETTE['gray-light'],
        shadow: { color: PALETTE['border-shadow-light'], width: 1 },
      }),
    };
  }

  postProcessSankey(event) {
    let chart = event.target;
    let nodes = chart.series.firstObject.nodes;
    if (!nodes) {
      return;
    }

    // we need to render once so Highcharts does some inital layout. Using this we can figure out the offset the top node should have
    // then we need to set this offset and re-render the chart by updating it's options
    nodes.forEach((node) => {
      if (node.pushToTop && node.offsetVertical === undefined) {
        let nodeY = chart.series.firstObject.nodes.find(
          (renderedNode) => renderedNode.id === node.id,
        ).nodeY;
        let offsetVertical = -nodeY;
        node.update({ offsetVertical }, false);
        let sameLevelNode = nodes.find((n) => n.id !== node.id && n.level === node.level);
        if (sameLevelNode) {
          sameLevelNode.update({ offsetVertical }, false);
        }
        chart.redraw(false);
        return;
      }
    });

    // now we can do some customisation of the rendered SVG elements
    nodes.forEach((node) => {
      if (node.extraCurves && node.linksTo[0].graphic) {
        let element = node.linksTo[0].graphic.element;
        let oldCurve = element.getAttribute('d');

        let firstCurveStr = oldCurve.substring(oldCurve.indexOf('C'), oldCurve.indexOf('L'));
        let firstCurveArr = firstCurveStr.split(' ');
        // This forces to use only 2 points instead of 3 for curve drawing which gives a steeper curve
        firstCurveArr[3] = firstCurveArr[1];
        let newCurve = oldCurve.replace(firstCurveStr, firstCurveArr.join(' '));

        let secondCurveStr = oldCurve.substring(oldCurve.lastIndexOf('C'));
        let secondCurveArr = secondCurveStr.split(' ');
        // This forces to use only 2 points instead of 3 for curve drawing which gives a steeper curve
        secondCurveArr[1] = secondCurveArr[3];
        newCurve = newCurve.replace(secondCurveStr, secondCurveArr.join(' '));

        element.setAttribute('d', newCurve);
      }
    });
  }

  setConnectedLinksHoverState(point, state) {
    if (!point || !point.hoverGroup) {
      return;
    }

    point.setState(state);
    point.series.points.concat(point.series.nodes).forEach((p) => {
      if (p.hoverGroup === point.hoverGroup && p.graphic) {
        p.setState(state);
        p.graphic.element.style.fill = state ? p.hoverColor : p.normalColor;
      }
    });
  }

  clearAllHover(point) {
    point.series.points.concat(point.series.nodes).forEach((p) => {
      if (p.destroyed) {
        return;
      }
      if (p.setState) {
        p.setState('');
      }
      if (p.graphic) {
        p.graphic.element.style.fill = p.normalColor;
      }
    });
  }

  setHoverState(event, state) {
    let point = event.target;
    this.clearAllHover(point);
    if (point.formatPrefix === 'node') {
      point = point.linksFrom.firstObject;
    }
    this.setConnectedLinksHoverState(point, state);
  }
}
