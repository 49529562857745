/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
let displayAdminAs = function (currentAdmin, admin, isReflexive, lowerCase, firstnameOnly) {
  if (!currentAdmin) {
    return;
  }

  if (!admin || !admin.get('display_as')) {
    return 'Team member';
  }

  if (admin.get('removed_from_current_app')) {
    return admin.get('name');
  }

  let name = '';
  if (currentAdmin.get('id') === admin.get('id')) {
    name = isReflexive === true ? 'yourself' : 'You';
  } else {
    name = isReflexive === true ? 'themselves' : admin.get('display_as');
    if (admin.get('is_team')) {
      name = admin.get('display_as');
    }
  }
  if (firstnameOnly === true && !admin.get('is_team')) {
    name = name.split(' ')[0];
  }
  if (lowerCase === true) {
    name = name.toLowerCase();
  }
  return name;
};

export default displayAdminAs;
