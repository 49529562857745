/* RESPONSIBLE TEAM: team-frontend-tech */
import { helper as buildHelper } from '@ember/component/helper';
import TruncateString from 'embercom/lib/truncate-string';

export function truncate(params) {
  let source = String(params[0]);
  let maxLength = Number(params[1]);
  return TruncateString(source, maxLength, false);
}
export default buildHelper(truncate);
