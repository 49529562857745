/* RESPONSIBLE TEAM: team-frontend-tech */
import ENV from 'embercom/config/environment';

export default function (redirectHref, transition) {
  if (transition) {
    transition.abort();
  }
  if (ENV.environment !== 'test') {
    window.location.href = redirectHref;
  } else {
    console.error(`A redirect to ${redirectHref} was called`);
  }
}
