/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
export function controlGroupDeleteSuccess() {
  return `Control group deleted`;
}

export function controlGroupDeleteFailure() {
  return `We couldn't delete the control group. Send us a message and we'll do our best to help out.`;
}

export function controlGroupDeleteOptions() {
  return {
    title: `Remove control group`,
    body: `This will delete all the statistics associated with your control group. The people who were in your control group will not receive the message.`,
    confirmButtonText: `Remove control group`,
  };
}
