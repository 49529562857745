/* RESPONSIBLE TEAM: team-frontend-tech */
import { helper as buildHelper } from '@ember/component/helper';
import { assert } from '@ember/debug';

const numberTextMap = {
  1: 'first',
  2: 'second',
  3: 'third',
  4: 'fourth',
  5: 'fifth',
  6: 'sixth',
  7: 'seventh',
  8: 'eighth',
  9: 'ninth',
  10: 'tenth',
};

export function formatNumberToText(params) {
  let number = params[0];
  assert(
    'The number passed does not seem to be valid. Either update the helper or pass a correct number',
    numberTextMap[number],
  );
  return numberTextMap[number];
}

export default buildHelper(formatNumberToText);
