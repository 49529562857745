/* RESPONSIBLE TEAM: team-help-desk-experience */
// returns the parent element chain of DOM nodes from an event
//
// Event#path is supported in Chrome (non-standard)
// Event#composedPath is supported in Firefox (standard)
// IE11/Edge supports neither
export default function eventPath(e) {
  if ('composedPath' in e) {
    return e.composedPath();
  }
  if ('path' in e) {
    return e.path;
  }

  let path = [];
  let currentElem = e.target;
  while (currentElem) {
    path.push(currentElem);
    currentElem = currentElem.parentElement;
  }

  if (path.indexOf(window) === -1 && path.indexOf(document) === -1) {
    path.push(document);
  }
  if (path.indexOf(window) === -1) {
    path.push(window);
  }
  return path;
}
