/* RESPONSIBLE TEAM: team-messenger */
const UNICODE_EMOJI = {
  blush: String.fromCodePoint(0x1f60a),
  neutral_face: String.fromCodePoint(0x1f610),
  disappointed: String.fromCodePoint(0x1f61e),
  thumbsup: String.fromCodePoint(0x1f44d),
  thumbsdown: String.fromCodePoint(0x1f44e),
  purpleHeart: String.fromCodePoint(0x1f49c),
  sleeping: String.fromCodePoint(0x1f634),
  party: String.fromCodePoint(0x1f389),
};

export default [
  { index: 0, unicodeEmoticon: UNICODE_EMOJI.purpleHeart },
  { index: 1, unicodeEmoticon: UNICODE_EMOJI.sleeping },
  { index: 2, unicodeEmoticon: UNICODE_EMOJI.thumbsdown },
  { index: 3, unicodeEmoticon: UNICODE_EMOJI.party },
];
