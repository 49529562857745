/* RESPONSIBLE TEAM: team-frontend-tech */
import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

export default class CaptureRenderEndFn extends Helper {
  @service frontendStatsService;

  compute(_params, { subject, id, sampleRate = 1 }) {
    return () => this.frontendStatsService.stopInteractionTime(subject, { id, sampleRate });
  }
}
