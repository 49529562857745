/* RESPONSIBLE TEAM: team-help-desk-experience */
import Helper from '@ember/component/helper';

export default class FormatSelectedValueAsText extends Helper<{
  Args: {
    Positional: [string, { value: string; text: string }[]];
  };
}> {
  compute([selectedValue, items]: [string, { value: string; text: string }[]]) {
    let item = items.find(({ value }) => value === selectedValue);

    if (item) {
      return item.text;
    } else {
      return '—';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'format-selected-value-as-text': typeof FormatSelectedValueAsText;
  }
}
