/* RESPONSIBLE TEAM: team-proactive-support */
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import { copy } from 'ember-copy';

export default class EmailContentHandler {
  constructor(store) {
    this.store = store;
  }

  getBlocksForTemplate(localizedEmailContentBlocks, template) {
    let blocksForTemplate = copy(localizedEmailContentBlocks, true);
    if (template.isBlockTemplate) {
      if (!blocksForTemplate.firstObject.container) {
        blocksForTemplate = this.wrapWithContentSectionFromTemplate(blocksForTemplate, template);
      } else {
        blocksForTemplate = this.wrapWithContentSectionFromTemplate(
          blocksForTemplate.firstObject.serialize().rows[0].cells[0].content,
          template,
        );
      }
    } else if (blocksForTemplate.firstObject.container) {
      blocksForTemplate = this.removeContentSection(blocksForTemplate);
    }
    return blocksForTemplate;
  }

  wrapWithContentSectionFromTemplate(blocks, template) {
    let contentSection = copy(template.contentBlocks[0], true);
    contentSection.rows[0].cells[0].content = blocks;
    return this.createFragments([contentSection]);
  }

  removeContentSection(blocks) {
    return this.createFragments(blocks.firstObject.rows[0].cells[0].content);
  }

  createFragments(content) {
    return content.map((block) => createFragmentFromBlock(this.store, block));
  }
}
