/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
export function messageSaveSuccess(message) {
  if (message.get('isLive')) {
    return `Your message has been updated.`;
  } else {
    return `Your message has been saved as a draft.`;
  }
}

export function messageSaveFailure() {
  return `We could not save your message.`;
}

export function messageSaveOptions(message) {
  if (message.get('isLive')) {
    let title = `This message is live`;
    let confirmButtonText = `Save`;
    let body = `Are you sure you want to save your changes? Any changes that you have made will be visible to your customers from now on.`;
    return { title, body, confirmButtonText };
  } else {
    return { notRequired: true };
  }
}
