/* RESPONSIBLE TEAM: team-reporting */
import { improvementDirections } from 'embercom/components/reporting/element/summary-comparison';

export function getTimeConfig({ comparison = false, range }) {
  let start = comparison ? range.comparisonStartMoment.valueOf() : range.startMoment.valueOf();
  let end = comparison
    ? range.comparisonEndMoment.valueOf()
    : range.endMomentButNotInTheFuture.valueOf();
  return {
    property: 'interaction_created_at',
    start,
    end,
  };
}

export function signalHasImproved() {
  return function (signalHasIncreased, improvementDirection) {
    let hasIncreasedAndImproved =
      signalHasIncreased && improvementDirection === improvementDirections.INCREASING;
    let hasDecreasedAndImproved =
      !signalHasIncreased && improvementDirection === improvementDirections.DECREASING;
    return hasIncreasedAndImproved || hasDecreasedAndImproved;
  };
}

export function formattedRangeText(range) {
  let rangeStart = `(${range.comparisonStartMoment.format('MMM D')}`;
  let rangeEnd = ` - ${range.comparisonEndMoment.format(
    'MMM D',
  )}, ${range.comparisonEndMoment.format('YYYY')})`;
  if (range.comparisonSpansMultipleYears || range.isSingleDay) {
    rangeStart += `, ${range.comparisonStartMoment.format('YYYY')}`;
  }

  return range.isSingleDay ? `${rangeStart})` : rangeStart + rangeEnd;
}
