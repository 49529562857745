/* RESPONSIBLE TEAM: team-frontend-tech */
import Helper from '@ember/component/helper';
import urlFeatureFlags from 'embercom/lib/url-feature-flags';

// This `has-url-feature` helper is not currently used in the app but
// shouldn't be removed as it's the only way to use feature flags on our
// public embercom pages (like the App Store). This will only need to be used
// when there is an upcoming release in these parts of the app, etc.

export default class HasUrlFeatureHelper extends Helper {
  compute(params) {
    let feature = params[0];
    let url = params.length > 1 ? params[1] : null;
    return Boolean(urlFeatureFlags.includes(feature, url));
  }
}
