/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/no-jquery */
import $ from 'jquery';

const CONTAINER_SELECTORS = ['#intercom-container', '.intercom-lightweight-app'];
const JQUERY_CONTAINER_SELECTORS = CONTAINER_SELECTORS.join(',');

export function hideIntercomWidget() {
  return $(JQUERY_CONTAINER_SELECTORS).hide();
}

export function showIntercomWidget() {
  $(JQUERY_CONTAINER_SELECTORS).show();
}

export function hideIntercomWidgetWhenLoaded() {
  if (hideIntercomWidget().length > 0) {
    return;
  }
  // Observe changes to the list of children for body node; check if one of them is the intercom widget and hide it if so
  let observer;
  observer = new MutationObserver((mutationsList) => {
    mutationsList.forEach((mutation) => {
      mutation.addedNodes.forEach((element) => {
        if ($(element).is(JQUERY_CONTAINER_SELECTORS)) {
          element.style.display = 'none';
          observer.disconnect();
        }
      });
    });
  });
  observer.observe(document.body, { childList: true });
}

export function shutdownIntercomWidget() {
  if (typeof Intercom !== 'undefined') {
    Intercom('shutdown');
  }
}

export function sendIntercomUpdate(updateHash) {
  if (typeof Intercom !== 'undefined') {
    window.Intercom('update', updateHash);
  }
}

export function bootIntercomWidget() {
  if (typeof Intercom !== 'undefined') {
    Intercom('boot', window.intercomSettings);
  }
}

export function showNewMessageInIntercomWidget(message) {
  if (typeof Intercom !== 'undefined') {
    Intercom('showNewMessage', message || '');
  }
}

export function getVisitorId() {
  if (typeof Intercom !== 'undefined') {
    return Intercom('getVisitorId') || null;
  }
  return null;
}

export function startSurvey(rulesetId) {
  if (typeof Intercom !== 'undefined') {
    Intercom('startSurvey', rulesetId);
  }
}

export function startTour(tourId) {
  if (typeof Intercom !== 'undefined') {
    Intercom('startTour', tourId);
  }
}

export function hookIntoTourComplete(tourId, callback) {
  function handler(event) {
    if (event.detail.tourId.toString() !== tourId.toString()) {
      return;
    }

    return callback(event);
  }

  window.document.addEventListener('intercom:tourComplete', handler);

  return () => {
    window.document.removeEventListener('intercom:tourComplete', handler);
  };
}

export function hookIntoTourError(tourId, callback) {
  function handler(event) {
    if (event.detail.tourId.toString() !== tourId.toString()) {
      return;
    }

    return callback(event);
  }

  window.document.addEventListener('intercom:tourError', handler);

  return () => {
    window.document.removeEventListener('intercom:tourError', handler);
  };
}

export function toggleMessengerVisibility({ hide }) {
  if ('Intercom' in window) {
    if (hide) {
      window.Intercom('hide');
    }
    if (window.intercomSettings) {
      window.intercomSettings.hide_default_launcher = hide;
    }
    window.Intercom('update');
  }
}
