/* RESPONSIBLE TEAM: team-help-desk-experience */
import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
/* eslint-disable ember/no-observers */
import { observer } from '@ember/object';
import type IntlService from 'embercom/services/intl';

export default class FormatFromNowHelper extends Helper<{
  Args: {
    Positional: [Date];
  };
  Return: string;
}> {
  @service declare intl: IntlService;

  // this is one of those rare occasions when an observer is the right thing to use
  // https://www.emberjs.com/api/ember/3.4/classes/Helper/methods/recompute?anchor=recompute
  _onNewLocale = observer('intl.primaryLocale', () => this.recompute());

  compute([date]: [Date]): string {
    /*
      If your date is not being localised, ensure you have imported the Moment locale
      in the ember-cli-build file.
     */
    let locale = this.intl.primaryLocale;
    return moment(date).locale(locale).fromNow();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'format-from-now': typeof FormatFromNowHelper;
  }
}
