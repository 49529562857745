/* RESPONSIBLE TEAM: team-frontend-tech */
import { helper as buildHelper } from '@ember/component/helper';
let format = function (params, options) {
  let formats = {
    human: { search: / (.)/g, toHuman: ' $1', fromHuman: ' $1' },
    camel: {
      search: /([a-z])([A-Z])/g,
      toHuman: '$1 $2',
      fromHuman(el) {
        return el[1].toUpperCase();
      },
    },
    hyphen: { search: /-(.)/g, toHuman: ' $1', fromHuman: '-$1' },
    underscore: { search: /_(.)/g, toHuman: ' $1', fromHuman: '_$1' },
  };

  let fromFormat = formats[options.from];
  let toFormat = formats[options.to];
  let value = options.value;
  let humanValue = value.replace(fromFormat.search, fromFormat.toHuman);
  let replacedValue = humanValue.replace(formats.human.search, toFormat.toHuman);

  return replacedValue;
};

export default buildHelper(format);
