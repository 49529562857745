/* RESPONSIBLE TEAM: team-tickets-1 */
import moment from 'moment-timezone';

let conversions = {
  s: 1000,
  m: 60,
  h: 60,
  d: 24,
  w: 7,
};

let adminTimeAgo = function (dateString, intl) {
  let now = moment();
  let date = moment(dateString);
  let delta = Math.max(0, now - date);
  let unit = 's';
  for (let key in conversions) {
    if (conversions.hasOwnProperty(key)) {
      if (delta < conversions[key]) {
        break;
      }
      unit = key;
      delta /= conversions[key];
    }
  }
  delta = Math.floor(delta);
  switch (unit) {
    case 's':
      return intl.t('admin-time-ago.15-mins');
    case 'm':
      return delta <= 15 ? intl.t('admin-time-ago.15-mins') : intl.t('admin-time-ago.last-hour');
    case 'h':
      return delta === 1
        ? intl.t('admin-time-ago.last-hour')
        : intl.t('admin-time-ago.custom-hour-ago', { delta });
    case 'd':
      return delta === 1
        ? intl.t('admin-time-ago.yesterday')
        : intl.t('admin-time-ago.last-active-custom-days', { delta });
    default:
      return intl.t('admin-time-ago.week-ago');
  }
};

export default adminTimeAgo;
