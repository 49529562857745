/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* DEPRECATED
 * please add plural definitions to app/models/custom-inflector-rules.js as per
 * https://guides.emberjs.com/v2.13.0/models/customizing-adapters/#toc_pluralization-customization
 */

function pluralOf(value) {
  let knownPlurals = {
    Person: 'People',
    person: 'people',
    Reply: 'Replies',
    reply: 'replies',
    Company: 'Companies',
    company: 'companies',
    matches: 'match',
    has: 'have',
    is: 'are',
    was: 'were',
    inbox: 'inboxes',
    attribute: 'attributes',
  };
  return knownPlurals[value.trim()] || `${value}s`;
}

function pluralised(value, pluralForm) {
  return typeof pluralForm === 'undefined' ? pluralOf(value) : pluralForm;
}

export default function (value, numItems, pluralForm) {
  return numItems === 1 || numItems === '1' ? value : pluralised(value, pluralForm);
}
