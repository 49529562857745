/* RESPONSIBLE TEAM: team-reporting */
import Tooltip from 'embercom/lib/reporting/flexible/tooltip';
import { trackAnalyticsEventForChart } from 'embercom/lib/reporting/analytics';
import moment from 'moment-timezone';
// @ts-ignore
import { copy } from 'ember-copy';
// @ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import { isPresent } from '@ember/utils';
import PALETTE from '@intercom/pulse/lib/palette';

const config: any = {
  legend: {
    enabled: true,
    align: 'center',
    margin: 27,
    layout: 'horizontal',
    labelFormat: '{userOptions.legendLabel}',
    itemHoverStyle: { color: null },
    maxHeight: 70,
    symbolHeight: 8,
    symbolWidth: 8,
  },
  chart: {
    spacingLeft: 3,
    spacingBottom: 0,
  },
  xAxis: {
    lineColor: 'transparent',
    type: 'datetime',
    dateTimeLabelFormats: {
      hour: '%l%p',
      day: '%b %e',
      week: '%b %e',
    },
    offset: 7,
    tickLength: 0,
    labels: { style: {} },
  },

  yAxis: [
    {
      softMax: 2, // Used in cases where we have no data, the chart defaults to yAxis max so it looks visually "correct".
      allowDecimals: false,
      gridLineColor: '#f2f2f2',
      offset: 10,
      endOnTick: true,
      maxPadding: 0,
      title: {
        enabled: false,
      },
      labels: { style: {} },
    },
    {
      softMax: 2, // Used in cases where we have no data, the chart defaults to yAxis max so it looks visually "correct".
      allowDecimals: false,
      gridLineColor: '#f2f2f2',
      offset: 10,
      endOnTick: true,
      maxPadding: 0,
      title: {
        enabled: false,
      },
      labels: { style: {} },
      opposite: true,
    },
  ],

  plotOptions: {
    column: {
      maxPointWidth: 32,
      stickyTracking: false,
      states: {
        hover: {
          enabled: false,
        },
      },
      centerInCategory: true,
      dataLabels: {
        enabled: false,
        color: PALETTE.gray,
        style: {
          fontSize: '12px',
          fontWeight: 'normal',
        },
      },
      tooltip: new Tooltip({
        headerFormat: '',
      }),
    },
    line: {
      stickyTracking: false,
      findNearestPointBy: 'xy',
      dataLabels: {
        enabled: false,
        color: PALETTE.gray,
        style: {
          fontSize: '12px',
          fontWeight: 'normal',
        },
      },
      tooltip: new Tooltip({
        headerFormat: '',
      }),
    },
    columnrange: {
      states: {
        hover: {
          enabled: false,
        },
      },
    },
    series: {
      minPointLength: 4,
      states: {
        inactive: {
          opacity: 1,
        },
        hover: {
          enabled: false,
        },
      },
      marker: {
        symbol: 'circle',
      },
    },
  },
  tooltip: new Tooltip({
    shadow: {
      color: '#000000',
      offsetX: 0,
      offsetY: 1,
      opacity: 0.03,
      width: 6,
    },
  }),
};

export default class FlexibleColumnLineChartConfig {
  declare config: any;
  constructor(timezone: string) {
    this.config = copy(config, true);
    this.setTimezone(timezone);
  }

  setColors(colors: any[]) {
    this.config.colors = colors;
  }

  setXAxisType(type: string) {
    this.config.xAxis.type = type;

    if (type === 'datetime') {
      this.config.plotOptions.column.keys = ['x', 'y', 'name'];
    } else {
      this.config.plotOptions.column.keys = undefined;
    }
  }

  setXAxisTickPositioner(tickPositioner: Function) {
    this.config.xAxis.tickPositioner = tickPositioner;
  }

  setXAxisFormatter(fn: Function) {
    this.config.xAxis.labels.formatter = fn;
  }

  setYAxisFormatter(fn: Function, index = 0) {
    this.config.yAxis[index].labels.formatter = fn;
  }

  setXAxisTickInterval(interval: number) {
    this.config.xAxis.tickInterval = interval;
  }

  setStep(step: number) {
    this.config.xAxis.labels.step = step;
  }

  setXAxisOverflow(overflow: string) {
    this.config.xAxis.labels.overflow = overflow;
  }

  setXAxisFontSize(size: number) {
    this.config.xAxis.labels.style.fontSize = size;
  }

  setYAxisTickInterval(interval: number | undefined, index = 0) {
    this.config.yAxis[index].tickInterval = interval;
  }

  setColorByPoint(value: boolean) {
    this.config.plotOptions.column.colorByPoint = value;
  }

  setSpacingRight(value: number) {
    this.config.chart.spacingRight = value;
  }

  disableLegend() {
    this.config.legend.enabled = false;
  }

  setTimezone(timezone: string) {
    this.config.time = { timezone, moment };
  }

  setYAxisRanges(min?: number, max?: number, index = 0) {
    if (isPresent(min)) {
      this.config.yAxis[index].min = min;
    }

    if (isPresent(max)) {
      this.config.yAxis[index].max = max;
    }
  }

  enableChartDataLabel(formatter: Function, chartType: string) {
    this.config.plotOptions[chartType].dataLabels.enabled = true;
    this.config.plotOptions[chartType].dataLabels.formatter = function () {
      return sanitizeHtml(formatter.call(this)).string;
    };
  }

  setTooltipFormatterPerSeries(fn: Function, chartType: string) {
    this.config.plotOptions[chartType].tooltip.pointFormatter = function () {
      let element = this.point?.graphic?.element;
      if (element) {
        trackAnalyticsEventForChart(element, { action: 'hovered' });
      }
      return sanitizeHtml(fn.call(this)).string;
    };
  }

  useDarkTooltips() {
    this.config.tooltip.backgroundColor = PALETTE.black;
    this.config.tooltip.borderColor = PALETTE.black;
    this.config.tooltip.style.color = PALETTE['gray-light'];
    this.config.tooltip.shadow.color = PALETTE['border-shadow-dark'];
    this.config.tooltip.shadow.width = 1;
  }
}
