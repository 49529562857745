/* RESPONSIBLE TEAM: team-proactive-support */
/**
 * Takes a versioned content Id parameter as generated
 * by the matching system and extracts its original
 * unversioned content id.
 *
 * E.g
 * extractUnversionedId("rv-2-6-6-1-1-3")
 * 3
 */
export function extractUnversionedId(versionedId) {
  return versionedId.split('-').pop();
}
