/* RESPONSIBLE TEAM: team-frontend-tech */
import { helper as buildHelper } from '@ember/component/helper';
import type EmberArray from '@ember/array';

export function firstObject<T>([arr]: [EmberArray<T>]) {
  return arr?.toArray()[0];
}

const firstObjectHelper = buildHelper(firstObject);

export default firstObjectHelper;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'first-object': typeof firstObjectHelper;
  }
}
