/* RESPONSIBLE TEAM: team-frontend-tech */
import ENV from 'embercom/config/environment';
import Model from '@ember-data/model';
import { decamelize } from '@ember/string';

export function initialize(/* application */) {
  if (ENV.environment !== 'production') {
    Model.reopen({
      unknownProperty(key) {
        let decamelizedKey = decamelize(key);
        if (key !== decamelizedKey) {
          if (decamelizedKey in this) {
            throw new Error(
              `IntercomModel automatically decamelized property names as a magic fallback. We won't do this for Ember Data models. (model: ${this}, key: ${key} => ${decamelizedKey})`,
            );
          }
        }
      },
    });
  }
}

export default {
  initialize,
};
