/* RESPONSIBLE TEAM: team-frontend-tech */
export const ANONYMOUS_PREDICATE = {
  type: 'anonymous',
  attribute: 'anonymous',
  comparison: 'true',
  value: 'nil',
};
export const NON_ANONYMOUS_PREDICATE = {
  type: 'anonymous',
  attribute: 'anonymous',
  comparison: 'false',
  value: 'nil',
};
export const USER_ROLE_PREDICATE = {
  type: 'role',
  attribute: 'role',
  comparison: 'eq',
  value: 'user_role',
};
export const VISITOR_ROLE_PREDICATE = {
  type: 'role',
  attribute: 'role',
  comparison: 'ne',
  value: 'user_role',
};
export const LEAD_ROLE_PREDICATE = {
  type: 'role',
  attribute: 'role',
  comparison: 'eq',
  value: 'contact_role',
};
