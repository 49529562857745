/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import {
  type FileUploader,
  type UploadSuccess,
  type UploadFailure,
} from '@intercom/embercom-prosemirror-composer';
import $ from 'jquery';
// @ts-ignore
import { post } from 'embercom/lib/ajax';
// @ts-ignore
import imageSize from 'embercom/lib/image-size';

interface Policy {
  id: number;
  upload_destination: string;
  bucket: string;
  public_url: string;
  upload_url: string;
  key: string;
  aws_access_key: string;
  acl: string;
  success_action_status: string;
  content_type: string;
  policy: string;
  signature: string;
  metadata?: {
    safe_app_id?: string;
  };
}

export default class EmbercomFileUploader implements FileUploader {
  file: File;
  policyUrl: string;
  policy!: Policy;
  progressHandler: undefined | ((event: ProgressEvent) => void);
  url?: string;

  constructor(
    file: File,
    attrs: { [key: string]: string },
    progressHandler?: (event: ProgressEvent) => void,
  ) {
    this.file = file;
    this.policyUrl = attrs.policyUrl;
    this.progressHandler = progressHandler;
    this.url = attrs.url || '';
  }

  async upload(): Promise<UploadSuccess | UploadFailure> {
    if (this.url) {
      // third party URLs will be uploaded from the server side if it fails to upload from the client side
      return await this.uploadFromUrl();
    }

    return await this.uploadFromFile();
  }

  private async uploadFromUrl(): Promise<UploadSuccess | UploadFailure> {
    try {
      let uploadResponse = await post(this.policyUrl, {
        upload: {
          url: this.url,
        },
      });
      return {
        type: 'success',
        file: this.file,
        uploadUrl: uploadResponse.public_url,
        id: uploadResponse.upload.id,
        contentType: uploadResponse.upload.content_type,
      };
    } catch (e) {
      return this.genericFailure;
    }
  }

  private async uploadFromFile(): Promise<UploadSuccess | UploadFailure> {
    try {
      this.policy = await this.fetchPolicy();
    } catch (e) {
      return this.genericFailure;
    }
    try {
      await this.uploadFile();
    } catch (e) {
      return this.failedUpload(e);
    }

    try {
      await this.confirmUploadSuccess();
      return this.success;
    } catch (e) {
      return this.genericFailure;
    }
  }

  private async fetchPolicy() {
    return post(this.policyUrl, {
      include_metadata: true,
      upload: {
        content_type: this.file.type,
        size_in_bytes: this.file.size,
        original_filename: this.file.name,
        dimensions: await this.maybeCalculateImageSize(),
      },
    });
  }

  private async maybeCalculateImageSize() {
    if (this.file.type.indexOf('image') === -1) {
      return null;
    }

    let result;

    try {
      result = await imageSize(window.URL.createObjectURL(this.file));
    } catch {
      return null;
    }

    return `${result.width}x${result.height}`;
  }

  private async uploadFile(): Promise<void> {
    return new Promise((resolve, reject) => {
      let policy = this.policy;
      let formData = this.createFormData(policy);
      let xhr = new XMLHttpRequest();

      if (this.progressHandler) {
        xhr.upload.addEventListener(
          'progress',
          (e) => {
            this.progressHandler && this.progressHandler(e);
          },
          false,
        );
      }

      xhr.addEventListener(
        'load',
        () => {
          if (xhr.status === 200 || xhr.status === 201) {
            resolve();
          } else {
            reject(xhr);
          }
        },
        false,
      );
      xhr.addEventListener(
        'error',
        () => {
          reject(xhr);
        },
        false,
      );

      xhr.open('POST', policy.upload_destination, true); //MUST BE LAST LINE BEFORE YOU SEND
      xhr.send(formData);
    });
  }

  private createFormData(policy: Policy) {
    let file = this.file;
    let fd = new FormData();
    fd.append('key', policy.key);
    fd.append('acl', policy.acl);
    fd.append('Content-Type', policy.content_type);
    fd.append('AWSAccessKeyId', policy.aws_access_key);
    fd.append('policy', policy.policy);
    fd.append('signature', policy.signature);
    fd.append('success_action_status', policy.success_action_status);

    if (policy.metadata?.safe_app_id) {
      fd.append('x-amz-meta-safe_app_id', policy.metadata.safe_app_id);
    }

    fd.append('file', file, this.file.name);
    return fd;
  }

  private async confirmUploadSuccess() {
    // eslint-disable-next-line ember/no-jquery
    return $.ajax({
      url: this.policy.upload_url,
      type: 'PUT',
    });
  }

  private failedUpload(xhr: XMLHttpRequest): UploadFailure {
    let reason = 'There was an error uploading your file';
    let retryable = true;
    if (xhr.responseText.match('EntityTooLarge')) {
      retryable = false;
      reason = 'File too big';
      let maxSize = xhr.responseText.match(/<MaxSizeAllowed>([0-9]+)<\/MaxSizeAllowed>/);
      if (maxSize) {
        reason += `. Max size is ${Math.floor(Number(maxSize[1]) / 1000000)}MB`;
      }
    }
    return {
      type: 'failure',
      file: this.file,
      reason,
      retryable,
    };
  }

  private get success(): UploadSuccess {
    return {
      type: 'success',
      file: this.file,
      uploadUrl: this.policy.public_url,
      id: this.policy.id,
      contentType: this.policy.content_type,
    };
  }

  private get genericFailure(): UploadFailure {
    return {
      type: 'failure',
      file: this.file,
      reason: 'There was an error uploading your file',
      retryable: true,
    };
  }
}
