/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
let browserPlatform = {
  isMac: false,
  isWindows: false,
  isLinux: false,
  name: 'unknown',
};

function init() {
  let platform = navigator.platform;
  if (platform.includes('Mac')) {
    browserPlatform.isMac = true;
    browserPlatform.name = 'mac';
  } else if (platform.includes('Linux')) {
    browserPlatform.isLinux = true;
    browserPlatform.name = 'linux';
  } else if (platform.includes('Win')) {
    browserPlatform.isWindows = true;
    browserPlatform.name = 'windows';
  }
  Object.freeze(browserPlatform);
}

init();

export default browserPlatform;
