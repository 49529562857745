/* RESPONSIBLE TEAM: team-help-desk-experience */
import { helper } from '@ember/component/helper';

const unlessIsComposing = helper((cb: [(params: KeyboardEvent, ...args: any[]) => void]) => {
  /*
    Ignore keyboard events for IME input.
    229 is a special value set for a keyCode relating to an event that has been processed by an IME.
  */
  return (params: KeyboardEvent) => {
    if (params.isComposing || params.keyCode === 229) {
      return;
    }
    cb[0]?.(params);
  };
});

export default unlessIsComposing;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'unless-is-composing': typeof unlessIsComposing;
  }
}
