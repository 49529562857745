/* RESPONSIBLE TEAM: team-tickets-1 */
const AVAILABLE_EMOJI = [
  '🚀',
  '🌟',
  '🎉',
  '🍻',
  '🎯',
  '🏆',
  '🎲',
  '💎',
  '🏭',
  '💡',
  '💰',
  '📈',
  '👾',
  '📢',
  '⚡',
  '🔮',
  '⏳',
  '☀',
  '✨',
  '🔥',
  '⭐',
  '✅',
  '🚨',
  '💈',
  '📡',
];
const DEFAULT_EMOJI = AVAILABLE_EMOJI[0];

export { DEFAULT_EMOJI, AVAILABLE_EMOJI };
