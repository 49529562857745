/* RESPONSIBLE TEAM: team-proactive-support */
import { helper } from '@ember/component/helper';

export function edgeLabel(_, namedArgs) {
  let edge = namedArgs.edge;
  let node = edge.predecessor.dataObject;

  let connectors = node.graphConfiguration.connectors;

  return connectors.find((connector) => connector.edgeType === edge.dataObject.edgeType)?.label;
}

export default helper(edgeLabel);
