/* RESPONSIBLE TEAM: team-reporting */
import moment from 'moment-timezone';
import IntervalFormatter from 'embercom/lib/reporting/flexible/interval-formatter';

export function mapXAxisLabel(dataConfig, viewConfig, label) {
  let labelMappingFunction = viewConfig.labelMappingFunction;
  if (labelMappingFunction) {
    return labelMappingFunction(label);
  }
  let labelMappings = dataConfig.xAxis.data.labelMappings;
  let defaultLabel = dataConfig.xAxis.data.defaultLabel;
  if (labelMappings) {
    return labelMappings[label] || defaultLabel || label;
  }
  return label;
}

function isLastInSeries(totalSize, currentIndex) {
  return currentIndex === totalSize - 1;
}

function isFirstInSeries(currentIndex) {
  return currentIndex === 0;
}

export function mapFromTimeStampToLabelForPieChart(
  viewConfig,
  range,
  interval,
  seriesData,
  currentIndex,
) {
  let timezone = range.timezone;
  let intervalFormatter = new IntervalFormatter(interval);
  let currentSeriesPoint = seriesData[currentIndex];

  if (isFirstInSeries(currentIndex) && interval === 'month') {
    let nextPoint = seriesData[currentIndex + 1];
    let endDate = nextPoint
      ? moment(nextPoint.name).subtract(1, 'd') // for pie chart timestamps are stored in name
      : undefined;
    return intervalFormatter.datePart(range.startMoment, timezone, endDate);
  } else if (isLastInSeries(seriesData.length, currentIndex)) {
    return intervalFormatter.datePart(currentSeriesPoint.name, timezone, range.endMoment);
  } else {
    return intervalFormatter.datePart(currentSeriesPoint.name, timezone);
  }
}
