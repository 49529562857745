/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import CustomUsageLineChartConfig from 'embercom/lib/billing/usage/custom-usage-default-line-chart-config';
import Formatters from 'embercom/lib/reporting/flexible/formatters';
import IntervalFormatter from 'embercom/lib/reporting/flexible/interval-formatter';
import Axis from 'embercom/lib/reporting/flexible/axis';
import moment from 'moment-timezone';
import PALETTE from '@intercom/pulse/lib/palette';
import { isPresent } from '@ember/utils';

const MAX_LABELS_FOR_SMALL_WIDTHS = 8;
const ONE_HOUR_IN_MS = 1000 * 60 * 60;

export default class CustomUsageLinechartBuilder {
  constructor({
    range,
    viewConfig,
    xAxisType,
    seriesColors = [
      PALETTE['vis-azure-50'],
      PALETTE['vis-yellow-50'],
      PALETTE['vis-mint-50'],
      PALETTE['vis-salmon-50'],
      PALETTE['vis-slateblue-50'],
      PALETTE['vis-olive-50'],
      PALETTE['vis-pink-50'],
      PALETTE['vis-turquoise-50'],
      PALETTE['vis-orange-50'],
      PALETTE['vis-blue-50'],
    ],
    width = '',
    chartData = [{ data: [] }],
  }) {
    this.range = range;
    this.seriesColors = seriesColors;
    this.xAxisType = xAxisType;
    this.width = width;
    this.chartData = chartData;
    this.viewConfig = viewConfig;
    this.formatter = new Formatters[this.viewConfig.formatUnit.unit](
      this.viewConfig.formatUnit.displayUnit,
    );
    this.yAxisTicker = new Axis[this.viewConfig.formatUnit.unit]();
  }

  get intervalFormatter() {
    return new IntervalFormatter(this.range.interval);
  }
  buildTheme() {
    let config = new CustomUsageLineChartConfig(this.range.timezone);

    config.setChartType('line');
    config.setXAxisType(this.isXAxisTemporal ? 'datetime' : 'category');

    if (this.isXAxisTemporal) {
      config.setXAxisTickInterval(this.xAxisTickInterval);
      // last X axis label is clipped without the extra spacing (default is 10)
      config.setSpacingRight(15);
    }

    // Using formatData make it work, but I think it cries out the need to split formatter for axis and tooltip
    config.setYAxisFormatter(({ value }) =>
      this.viewConfig.formatYAxisAsPrice
        ? this.formatter.formatPriceFromCents(value)
        : this.formatter.formatAxis(value),
    );

    config.setYAxisTickInterval(this.yAxisTickInterval);
    config.setYAxisRanges(this.viewConfig.yAxis?.min, this.yAxisMax);
    config.setTooltipFormatter(this.viewConfig.tooltipFormatter || this.tooltipFormatter);
    config.setXAxisFormatter(this.xAxisFormatter);

    config.setColors(this.seriesColors);

    if (isPresent(this.targetValue)) {
      let formattedValue = this.viewConfig.formatYAxisAsPrice
        ? this.formatter.formatPriceFromCents(this.targetValue)
        : this.formatter.formatAxis(this.targetValue);
      config.setTargetLine(this.targetValue, formattedValue, this.targetOptions);

      if (isPresent(this.secondaryTarget)) {
        let formattedSecondaryValue = this.viewConfig.formatYAxisAsPrice
          ? this.formatter.formatPriceFromCents(this.secondaryTarget.value)
          : this.formatter.formatAxis(this.secondaryTarget.value);
        config.setAdditionalTargetLine(
          this.secondaryTarget.value,
          formattedSecondaryValue,
          this.secondaryTarget.options,
        );
      }
    }

    if (this.viewConfig.useDarkTooltips) {
      config.useDarkTooltips();
    }

    return config.config;
  }

  get targetValue() {
    return this.viewConfig.visualizationOptions?.target?.value;
  }

  get targetOptions() {
    return this.viewConfig.visualizationOptions?.target?.options;
  }

  get secondaryTarget() {
    return this.viewConfig.visualizationOptions?.target?.secondaryTarget;
  }

  get tooltipFormatter() {
    let config = this;
    let isXAxisTemporal = this.isXAxisTemporal;
    let groupingTransformation = this.viewConfig.grouping?.tooltipTransformation;

    return function () {
      let timezone = this.series.chart.options.time.timezone;
      let formattedData = config.formatter.formatTooltip(
        this.y,
        this.series.options.tooltipDisplayUnit,
      );
      let valuePart = `<strong>${formattedData}</strong><br/>`;
      let datePart;

      if (groupingTransformation) {
        datePart = groupingTransformation(this.point, this.series);
      } else if (isXAxisTemporal) {
        datePart = config.intervalFormatter.datePart(this.point.x, timezone);
      } else {
        datePart = this.point.x;
      }

      let cssClass = 'reporting__highcharts-tooltip';
      return `<div class='${cssClass}'>${valuePart}(${datePart})</div>`;
    };
  }

  get hasMoreLabelsThanMaxAllowed() {
    return (
      this.width === 'small' &&
      this.range.endMoment.diff(this.range.startMoment) > MAX_LABELS_FOR_SMALL_WIDTHS * 24
    );
  }

  get xAxisTickInterval() {
    if (this.isIntervalHourly) {
      return ONE_HOUR_IN_MS;
    } else if (this.isIntervalDaily) {
      if (this.hasMoreLabelsThanMaxAllowed) {
        return 24 * ONE_HOUR_IN_MS * 2;
      } else {
        return 24 * ONE_HOUR_IN_MS;
      }
    } else if (this.isIntervalWeekly) {
      return 24 * ONE_HOUR_IN_MS * 7;
    } else {
      return 24 * ONE_HOUR_IN_MS * 30;
    }
  }

  get yAxisTickInterval() {
    if (isPresent(this.viewConfig.yAxis?.tickInterval)) {
      return this.viewConfig.yAxis.tickInterval;
    }
    let { minValue, maxValue } = this.minAndMaxValues;
    maxValue = Math.max(maxValue, this.targetValue || 0);
    let diff = maxValue - minValue;
    if (diff === 0) {
      return 5; // Used to split the yAxis.softMax option of Highcharts into even ticks when we don't have data.
    }
    return this.yAxisTicker.interval(minValue, maxValue);
  }

  get yAxisMax() {
    if (isPresent(this.viewConfig.yAxis?.max)) {
      return this.viewConfig.yAxis.max;
    }

    if (isPresent(this.targetValue)) {
      let { maxValue } = this.minAndMaxValues;
      let targetValues = [this.targetValue];
      let targetMax;

      // If the target value is greater than the max data value, use the target value as the max
      // otherwise, let the chart handle the max value
      if (isPresent(this.secondaryTarget)) {
        targetValues.push(this.secondaryTarget.value);
      }
      targetMax = Math.max(...targetValues);

      return targetMax > maxValue ? targetMax : null;
    }

    return null;
  }

  get minAndMaxValues() {
    let maxValue = 0;
    let minValue = 0;

    this.chartData.forEach((graphData) => {
      graphData.data.forEach((timestampWithValue) => {
        if (timestampWithValue[1] > maxValue) {
          maxValue = Math.round(timestampWithValue[1]);
        }

        if (timestampWithValue[1] < minValue) {
          minValue = Math.round(timestampWithValue[1]);
        }
      });
    });

    return { minValue, maxValue };
  }

  get xAxisFormatter() {
    let config = this;

    return function () {
      let currentLabel = moment.tz(this.value, this.chart.options.time.timezone);
      let format = 'MMM D';
      let maxLabelsForSmallWidths = 8;

      if (config.isIntervalHourly) {
        if (config.width === 'small' && parseInt(currentLabel.format('H'), 10) % 3 !== 0) {
          return '';
        } else {
          format = 'hA';
        }
      }

      if (
        config.isIntervalDaily &&
        config.width === 'small' &&
        config.range.endMoment.diff(config.range.startMoment) >
          maxLabelsForSmallWidths * 1000 * 60 * 60 * 24
      ) {
        if (config.range.startMoment.diff(currentLabel, 'days') % 2 !== 0) {
          return '';
        }
      }

      if (
        config.isIntervalWeekly &&
        config.range.endMoment.diff(config.range.startMoment) >
          maxLabelsForSmallWidths * 1000 * 60 * 60 * 24 * 7
      ) {
        let firstLabel = config.range.startMoment.startOf('isoWeek');

        if (config.width === 'small' && firstLabel.diff(currentLabel, 'days') % 14 !== 0) {
          return '';
        }
      }

      if (config.isIntervalMonthly) {
        if (config.width === 'small' && parseInt(currentLabel.format('M'), 10) % 2 !== 1) {
          return '';
        } else {
          format = 'MMM';
        }
      }

      return currentLabel.format(format);
    };
  }

  get isIntervalHourly() {
    return this.range.interval === 'hour';
  }

  get isIntervalDaily() {
    return this.range.interval === 'day';
  }

  get isIntervalWeekly() {
    return this.range.interval === 'week';
  }

  get isIntervalMonthly() {
    return this.range.interval === 'month';
  }

  get isXAxisTemporal() {
    return this.xAxisType === 'temporal';
  }
}
