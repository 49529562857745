/* RESPONSIBLE TEAM: team-frontend-tech */
import Helper from '@ember/component/helper';
import { assert } from '@ember/debug';

export class QueryParams {
  values: Record<string, any>;
  isQueryParams: true;
  constructor(values: Record<string, any>) {
    this.values = values;
    this.isQueryParams = true;
  }
}

export interface QueryParamsLegacySignature {
  Args: {
    Named: Record<string, any>;
  };
  Return: QueryParams;
}

export default class QueryParamsLegacy extends Helper<QueryParamsLegacySignature> {
  compute(_positional: any[], namedArgs: Record<string, any>) {
    assert(
      "The `query-params-legacy` helper only accepts hash parameters, e.g. (query-params-legacy queryParamPropertyName='foo') as opposed to just (query-params-legacy 'foo')",
      _positional.length === 0,
    );
    return new QueryParams(Object.assign({}, namedArgs));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'query-params-legacy': typeof QueryParamsLegacy;
  }
}
