/* RESPONSIBLE TEAM: team-frontend-tech */
import { inflectorRawHelper } from 'embercom/helpers/inflector-helper';
import { indexIncrementRawHelper } from 'embercom/helpers/index-increment-helper';
import { fullDateAndTimeRawHelper } from 'embercom/helpers/full-date-and-time-helper';
import { abbreviatedRelativeTimeAgoRawHelper } from 'embercom/helpers/abbreviated-relative-time-ago-helper';
import { numericFormatterRawHelper } from 'embercom/helpers/numeric-formatter-helper';

export default function () {
  Handlebars.registerHelper('inflector-helper', inflectorRawHelper);
  Handlebars.registerHelper('full-date-and-time-helper', fullDateAndTimeRawHelper);
  Handlebars.registerHelper(
    'abbreviated-relative-time-ago-helper',
    abbreviatedRelativeTimeAgoRawHelper,
  );
  Handlebars.registerHelper('numeric-formatter-helper', numericFormatterRawHelper);
  Handlebars.registerHelper('index-increment-helper', indexIncrementRawHelper);
}
