/* RESPONSIBLE TEAM: team-frontend-tech */
import { helper as buildHelper } from '@ember/component/helper';
export default buildHelper(function (params) {
  let email = params[0];
  let name = params[1];
  let className = params[2];

  let a = document.createElement('a');
  a.target = '_blank';
  a.href = `mailto:${email}`;
  if (className !== undefined) {
    a.className = className;
  }

  let text = document.createTextNode(name);
  a.appendChild(text);

  return a;
});
