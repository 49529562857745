/* RESPONSIBLE TEAM: team-help-desk-experience */

//@ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

export const extractHighlightsFromText = (highlightsMarkup?: string[] | null) => {
  if (!highlightsMarkup) {
    return [];
  }

  let wordsToHighlight = highlightsMarkup
    .map((highlight) => {
      let tmpWrapper = document.createElement('div');
      tmpWrapper.innerHTML = sanitizeHtml(highlight);

      return [...tmpWrapper.querySelectorAll('em')]
        .map((highlightedElement) => highlightedElement.textContent)
        .filter((highlightedWord): highlightedWord is string => Boolean(highlightedWord));
    })
    .reduce((acc, curr) => acc.concat(curr), []);

  return [...new Set(wordsToHighlight)];
};
