/* RESPONSIBLE TEAM: team-frontend-tech */
import { helper as buildHelper } from '@ember/component/helper';
import inflector from 'embercom/lib/inflector';

let inflectorRawHelper = function (value, numItems, options) {
  return inflector(value, numItems, options.hash.pluralForm);
};

export { inflectorRawHelper };
export default buildHelper(function (params, options) {
  let value = params[0];
  let numItems = params[1];

  return inflector(value, numItems, options.pluralForm);
});
