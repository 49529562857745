/* RESPONSIBLE TEAM: team-frontend-tech */
import numberFormatter from 'embercom/lib/number-formatter';

let currencyFormatter = function (
  value,
  precision,
  showLeadingPlusSign,
  currencySymbol = '$',
  truncate = false,
) {
  precision = precision === null ? 0 : precision;
  let symbol = currencySymbol;
  let realValue = (parseFloat(value) || 0) / 100;
  if (realValue < 0) {
    symbol = `−${symbol}`;
    realValue *= -1;
  } else if (showLeadingPlusSign) {
    symbol = `+${symbol}`;
  }

  realValue = realValue.toFixed(precision);
  let formattedNumber = truncate
    ? truncateNumber(realValue, precision)
    : numberFormatter(realValue);
  return symbol + formattedNumber;
};

function truncateNumber(value, precision = 1) {
  let divisor = 1;
  let units = '';

  if (value > 999999) {
    divisor = 1000000;
    units = 'm';
  } else if (value > 999) {
    divisor = 1000;
    units = 'k';
  }

  // Wrapping with Number removes possible trailing 0s
  let truncatedValue = Number((value / divisor).toFixed(precision));

  return `${truncatedValue}${units}`;
}

export default currencyFormatter;
