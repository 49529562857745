/* RESPONSIBLE TEAM: team-reporting */
import { helper as buildHelper } from '@ember/component/helper';

export const nestedKeys = (signal) => {
  return [
    ...new Set(
      signal.context.reduce((allKeys, { value = [] }) => {
        return [...allKeys, ...value.map((valueItem) => valueItem.key)];
      }, []),
    ),
  ];
};

export default buildHelper(function ([signal]) {
  return nestedKeys(signal);
});
