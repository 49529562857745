/* RESPONSIBLE TEAM: team-frontend-tech */
import { helper as buildHelper } from '@ember/component/helper';
export function formatNameAsInitials(params) {
  let name = params[0];
  let nameParts = name.split(' ');
  let initials;

  if (nameParts.length === 1) {
    initials = nameParts[0].slice(0, 2);
  } else {
    initials = nameParts.map((e) => e[0]).join('');
  }

  return initials.toUpperCase();
}

export default buildHelper(formatNameAsInitials);
