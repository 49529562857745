/* RESPONSIBLE TEAM: team-proactive-support */
import { helper } from '@ember/component/helper';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import { AnchorIconTypes } from '@intercom/intersection-shared-models/models/tooltip';

function tooltipTextBgColor([anchorColor], { anchorIconType }) {
  return anchorIconType === AnchorIconTypes.text
    ? sanitizeHtml(`background-color: ${anchorColor};`)
    : '';
}

export default helper(tooltipTextBgColor);
