/* RESPONSIBLE TEAM: team-purchase-experience */
import {
  ANSWER_BOT_ESSENTIAL_ID,
  CORE_ACCELERATE_ID,
  CORE_GROW_ID,
  CORE_SUPPORT_PRO_ID,
  CORE_SUPPORT_PREMIUM_ID,
  CORE_ENGAGE_PRO_ID,
  CORE_ENGAGE_PREMIUM_ID,
  CORE_CONVERT_PRO_ID,
  CORE_SCALE_ID,
  INBOX_ESSENTIAL_ID,
  INBOX_PREMIUM_ID,
  INBOX_PRO_ID,
  MESSAGES_ESSENTIAL_ID,
  MESSAGES_PREMIUM_ID,
  MESSAGES_PRO_ID,
  OPERATOR_PRO_ID,
  PRODUCT_TOURS_ESSENTIAL_ID,
  SURVEYS_BASE_ID,
  SWITCH_BASE_ID,
  WHATSAPP_BASE_ID,
} from 'embercom/lib/billing';

interface RecommendationCard {
  header: string;
  description: string;
}

const RECOMMENDATION_CARD_DATA: Record<number, RecommendationCard> = {
  // Core - PPP
  [INBOX_ESSENTIAL_ID]: {
    header: 'intershop.recommendation_card.ppp_core_seats.header',
    description: 'intershop.recommendation_card.ppp_core_seats.description',
  },
  [INBOX_PRO_ID]: {
    header: 'intershop.recommendation_card.ppp_core_seats.header',
    description: 'intershop.recommendation_card.ppp_core_seats.description',
  },
  [INBOX_PREMIUM_ID]: {
    header: 'intershop.recommendation_card.ppp_core_seats.header',
    description: 'intershop.recommendation_card.ppp_core_seats.description',
  },
  [MESSAGES_ESSENTIAL_ID]: {
    header: 'intershop.recommendation_card.ppp_core_active_people.header',
    description: 'intershop.recommendation_card.ppp_core_active_people.description',
  },
  [MESSAGES_PRO_ID]: {
    header: 'intershop.recommendation_card.ppp_core_active_people.header',
    description: 'intershop.recommendation_card.ppp_core_active_people.description',
  },
  [MESSAGES_PREMIUM_ID]: {
    header: 'intershop.recommendation_card.ppp_core_active_people.header',
    description: 'intershop.recommendation_card.ppp_core_active_people.description',
  },
  // Core - VBP1
  [CORE_GROW_ID]: {
    header: 'intershop.recommendation_card.vbp1_core.header',
    description: 'intershop.recommendation_card.vbp1_core.description',
  },
  [CORE_ACCELERATE_ID]: {
    header: 'intershop.recommendation_card.vbp1_core.header',
    description: 'intershop.recommendation_card.vbp1_core.description',
  },
  [CORE_SCALE_ID]: {
    header: 'intershop.recommendation_card.vbp1_core.header',
    description: 'intershop.recommendation_card.vbp1_core.description',
  },
  // Core - VBP2
  [CORE_SUPPORT_PRO_ID]: {
    header: 'intershop.recommendation_card.support.header',
    description: 'intershop.recommendation_card.support.description',
  },
  [CORE_SUPPORT_PREMIUM_ID]: {
    header: 'intershop.recommendation_card.support.header',
    description: 'intershop.recommendation_card.support.description',
  },
  [CORE_ENGAGE_PRO_ID]: {
    header: 'intershop.recommendation_card.engage.header',
    description: 'intershop.recommendation_card.engage.description',
  },
  [CORE_ENGAGE_PREMIUM_ID]: {
    header: 'intershop.recommendation_card.engage.header',
    description: 'intershop.recommendation_card.engage.description',
  },
  [CORE_CONVERT_PRO_ID]: {
    header: 'intershop.recommendation_card.convert.header',
    description: 'intershop.recommendation_card.convert.description',
  },
  // Addons
  [PRODUCT_TOURS_ESSENTIAL_ID]: {
    header: 'intershop.recommendation_card.product-tours.header',
    description: 'intershop.recommendation_card.product-tours.description',
  },
  [SURVEYS_BASE_ID]: {
    header: 'intershop.recommendation_card.surveys.header',
    description: 'intershop.recommendation_card.surveys.description',
  },
  [SWITCH_BASE_ID]: {
    header: 'intershop.recommendation_card.switch.header',
    description: 'intershop.recommendation_card.switch.description',
  },
  [WHATSAPP_BASE_ID]: {
    header: 'intershop.recommendation_card.whatsapp.header',
    description: 'intershop.recommendation_card.whatsapp.description',
  },
  [OPERATOR_PRO_ID]: {
    header: 'intershop.recommendation_card.custom-bots.header',
    description: 'intershop.recommendation_card.custom-bots.description',
  },
  [ANSWER_BOT_ESSENTIAL_ID]: {
    header: 'intershop.recommendation_card.resolution-bot.header',
    description: 'intershop.recommendation_card.resolution-bot.description',
  },
};

function recommendationCardData(planId: number): RecommendationCard {
  return RECOMMENDATION_CARD_DATA[planId];
}

export { RecommendationCard, recommendationCardData };
