/* RESPONSIBLE TEAM: team-product-exploration */

import { helper } from '@ember/component/helper';

function slugify([title]) {
  return title
    .toString()
    .toLowerCase()
    .replace(/[\s_]+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-');
}

export default helper(slugify);
