/* RESPONSIBLE TEAM: team-frontend-tech */
import { helper as buildHelper } from '@ember/component/helper';
import inflector from 'embercom/lib/inflector';

export default buildHelper(function (params) {
  let stringArray = params[0] || [''];
  let numItems = params[1];

  return stringArray.map((word) => inflector(word, numItems));
});
