/* RESPONSIBLE TEAM: team-data-interop */
import redirect from 'embercom/lib/redirect';

const AFTER_MODELS = {
  github(model) {
    let githubIntegration = model.githubIntegration;
    let redirectUrl = githubIntegration.get('redirectUrl');
    if (redirectUrl) {
      redirect(redirectUrl);
    }
  },
};

export default function runIntegrationAfterModels(model) {
  let integration = model.integration;
  let code = integration.get('code');
  if (AFTER_MODELS[code]) {
    AFTER_MODELS[code](model);
  }
}
