/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { captureException as originalCaptureException } from 'embercom/lib/sentry';

let addSelectionDebugInfo = function (args) {
  let selection = window.getSelection();
  args.anchorOffset = selection.anchorOffset;
  args.focusOffset = selection.focusOffset;
};

let addRecoveryInfo = function (args, error) {
  args.recovered = error.recovered;
  if (error.recovered === false) {
    try {
      args.recoverError = error.recoverError.message;
    } catch (e) {
      args.recoverError = 'unknown';
    }
  }
};

let addPastedContent = function (args, error) {
  if (error.pastedContent) {
    args.pastedContent = error.pastedContent;
  }
};

export default function (area, error, extras = {}) {
  let captureException = extras.captureException || originalCaptureException;

  delete extras.captureException;

  // We're deliberately leaving this as "composer-v2" instead of updating to
  // "legacy-composer" even though it pertains to the legacy (non-prosemirror
  // composer) in case it poses sentry event silencing / categorisation issues
  let identifier = `ComposerV2Error:${area}`;

  let context = {
    browser: navigator.userAgent,
    identifier,
    version: error.version,
  };
  let args = Object.assign(context, extras);

  addRecoveryInfo(args, error);
  addSelectionDebugInfo(args);
  addPastedContent(args, error);

  if (error instanceof Error) {
    captureException(error, {
      extra: args,
      tags: {
        component: 'composer',
        area,
      },
    });
  } else if (typeof error === 'string') {
    // if the error is a string then just record the string
    captureException(new Error(error), {
      extra: args,
      tags: {
        component: 'composer',
        area,
      },
    });
  } else {
    // At this point its best effort to try to unpack as much infor as we can
    args = Object.assign(args, error);

    captureException(new Error(error.message || 'Unknown error from composer'), {
      extra: args,
      tags: {
        component: 'composer',
        area,
      },
    });
  }
}
