/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import Cache from 'lru-cache';
import resolve from 'embercom/lib/container-lookup';
import generateUUID from 'embercom/lib/uuid-generator';
import ENV from 'embercom/config/environment';

type CacheOptions = {
  max: number;
  ttl?: number;
  keyGenerator?: (...args: any[]) => string | undefined;
};

let caches: Record<any, Record<string, Cache<any, any>>> = {};

const SUPPORTED_ARG_TYPES = ['string', 'number', 'boolean'];
const DISALLOWED_CACHE_LOOKUPS = ['getLinkedTickets'];

let cachingEnabled = !ENV.APP.disableCaching;

export function defaultKeyGenerator(...args: any[]) {
  let canGenerateKey = true;

  args.forEach((arg, i) => {
    if (!SUPPORTED_ARG_TYPES.includes(typeof arg) && arg !== undefined && arg !== null) {
      canGenerateKey = false;
      throw new Error(
        `Cannot construct a cache key for object of type ${arg?.constructor?.toString()} at argument ${i}`,
      );
    }
  });

  while (args.length > 0 && args[args.length - 1] === undefined) {
    args.pop();
  }

  if (canGenerateKey) {
    return `k${args.join('__')}`;
  }

  return undefined;
}

function generateKey(
  type: string,
  name: string,
  args: any[],
  options: CacheOptions,
): string | undefined {
  let key;

  try {
    if (options.keyGenerator) {
      key = options.keyGenerator(...args);
    } else {
      key = defaultKeyGenerator(...args);
    }
  } catch (e) {
    console.warn(`${type}.${name}: ${e.message}`);
  }

  return key;
}

function findCache(type: string, name: string) {
  let cachesForType = caches[type];

  if (!cachesForType) {
    return null;
  }

  let cache = cachesForType[name];

  return cache;
}

function findOrCreateCache(type: string, name: string, options: CacheOptions) {
  let cachesForType = caches[type];

  if (!cachesForType) {
    cachesForType = {};
    caches[type] = cachesForType;
  }

  let cache = cachesForType[name];

  if (!cache) {
    cache = new Cache(options);
    cachesForType[name] = cache;
  }

  return cache;
}

function recordCacheLookup(
  name: string,
  key: string,
  callback: (metadata: Record<string, any>) => any,
) {
  let frontendStatsService = resolve('service:frontendStatsService');
  let id = generateUUID();

  let sampleRate = 0.01;

  let isBeingSampled = sampleRate < Math.random() && !DISALLOWED_CACHE_LOOKUPS.includes(name);

  if (isBeingSampled) {
    frontendStatsService?.startInteractionTime(`cacheLookup:${name}`, { id });
  }

  let metadata = {
    cache_key: key,
  };
  let result = callback(metadata);

  if (isBeingSampled) {
    frontendStatsService?.stopInteractionTime(`cacheLookup:${name}`, {
      id,
      metadata,
      sampleRate,
    });
  }

  return result;
}

function isCachingEnabled() {
  if (!cachingEnabled) {
    return false;
  }

  let session = resolve('service:session');
  return !session?.workspace?.isFeatureEnabled('frontend-cache-kill-switch');
}

export default function cached(options: CacheOptions) {
  return function (_target: any, name: string, descriptor: PropertyDescriptor) {
    let fn = descriptor.value;

    if (typeof fn !== 'function') {
      throw new Error('Can only cache functions');
    }

    descriptor.value = function (...args: any[]) {
      if (!isCachingEnabled()) {
        return fn.apply(this, args);
      }

      let type = this.constructor.name;
      let key = generateKey(type, name, args, options);

      if (!key) {
        return fn.apply(this, args);
      }

      return recordCacheLookup(name, key, (metadata) => {
        let cache = findOrCreateCache(type, name, options);

        let exists = cache.has(key);

        if (!exists) {
          let result = fn.apply(this, args);
          cache.set(key, result);
          metadata.cacheHitRate = 0;
          return result;
        } else {
          let result = cache.get(key);
          metadata.cacheHitRate = 1;
          return result;
        }
      });
    };

    descriptor.value.noCache = fn;
  };
}

// Returns a proxy which skips the cache for cached functions.
export function noCache<T extends object>(service: T): T {
  if (!isCachingEnabled()) {
    return service;
  }

  return new Proxy(service, {
    get(target: T, methodOrAttributeName) {
      let prop = methodOrAttributeName as keyof T;
      // @ts-ignore
      if (target[prop].noCache === undefined) {
        return target[prop];
      }

      // @ts-ignore
      return target[prop].noCache.bind(service);
    },
  }) as unknown as T;
}

type InvalidatesOptions = { type?: any; functionName: string };

export function invalidates(options: InvalidatesOptions) {
  return function (_target: any, _name: string, descriptor: PropertyDescriptor) {
    let fn = descriptor.value;

    if (typeof fn !== 'function') {
      throw new Error('Can only trigger invalidation on functions');
    }

    descriptor.value = async function (...args: any[]) {
      let returnValue = await fn.apply(this, args);
      if (!isCachingEnabled()) {
        return returnValue;
      }

      let type = options.type?.name || this.constructor.name;
      let name = options.functionName;

      let cache = findCache(type, name);

      if (cache) {
        cache.clear();
      }

      return returnValue;
    };
  };
}

export function resetAllCaches() {
  caches = {};
}

export function enableCaching() {
  cachingEnabled = true;
}

export function resetCachingConfig() {
  cachingEnabled = !ENV.APP.disableCaching;
}
