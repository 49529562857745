/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
import { readOnly, empty, not, filterBy, alias, notEmpty } from '@ember/object/computed';
import EmberObject from '@ember/object';
export default EmberObject.extend({
  attributeSettings: [],
  attribute: {},
  identifier: readOnly('attribute.identifier'),
  isCompany: readOnly('attribute.isCompany'),
  isUser: readOnly('attribute.isUser'),
  isCustomData: readOnly('attribute.isCustomData'),
  isAttributeSettingsEmpty: empty('attributeSettings'),
  isAttributeSettingsPresent: not('isAttributeSettingsEmpty'),
  companyAttributeSettings: filterBy('attributeSettings', 'display_on', 'companies'),
  companyAttributeSetting: alias('companyAttributeSettings.firstObject'),
  userAttributeSettings: filterBy('attributeSettings', 'display_on', 'users'),
  userAttributeSetting: alias('userAttributeSettings.firstObject'),
  isDisplayedOnUsers: notEmpty('userAttributeSetting'),
  isDisplayedOnCompanies: notEmpty('companyAttributeSetting'),
  getAttributeSettingByDisplayOn(display_on) {
    return this.attributeSettings.filterBy('display_on', display_on)[0];
  },
});
