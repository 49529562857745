/* RESPONSIBLE TEAM: team-messenger */
import { helper as buildHelper } from '@ember/component/helper';
import { assert } from '@ember/debug';
const MESSAGE_STYLES = ['chat', 'note', 'post', 'video', 'pointer'];

export function messageStyleClass(params) {
  let messageStyle = params[0];
  if (MESSAGE_STYLES.includes(messageStyle)) {
    return `o__${messageStyle}`;
  }
  assert(`helper/message-style-class: The message style "${messageStyle}" is not supported`);
}

export default buildHelper(messageStyleClass);
