/* RESPONSIBLE TEAM: team-reporting */
import { tracked } from '@glimmer/tracking';
import { omit } from 'underscore';

export default class TopicStats {
  @tracked currentCount;
  @tracked previousCount;
  @tracked currentResponseTime;
  @tracked previousResponseTime;
  @tracked currentTimeToClose;
  @tracked previousTimeToClose;
  @tracked currentPositiveRate;
  @tracked previousPositiveRate;
  @tracked currentMostAssignedTeam;
  @tracked previousMostAssignedTeam;

  constructor(rawStats) {
    if (rawStats) {
      this.update(rawStats);
    }
  }

  update(rawStats) {
    if (rawStats.current_count !== undefined) {
      this._updateCurrentStats(rawStats);
    }
    if (rawStats.previous_count !== undefined) {
      this._updatePreviousStats(rawStats);
    }
  }

  _updateCurrentStats(rawStats) {
    this.currentCount = rawStats.current_count;

    this.currentResponseTime = rawStats.current_response_time || rawStats.current_response_time_ooo;

    this.currentTimeToClose =
      rawStats.current_time_to_close_excluding_bot_inbox ||
      rawStats.current_time_to_close_excluding_bot_inbox_ooo ||
      rawStats.current_time_to_close_from_initial_user_part ||
      rawStats.current_time_to_close_from_initial_user_part_ooo;

    this.currentPositiveRate = this.getPositiveRate(rawStats.current_csat);

    this.currentMostAssignedTeam = this.getMostAssignedTeam(rawStats.current_team_assignee_counts);
  }

  _updatePreviousStats(rawStats) {
    this.previousCount = rawStats.previous_count;

    this.previousResponseTime =
      rawStats.previous_response_time || rawStats.previous_response_time_ooo;

    this.previousTimeToClose =
      rawStats.previous_time_to_close_excluding_bot_inbox ||
      rawStats.previous_time_to_close_excluding_bot_inbox_ooo ||
      rawStats.previous_time_to_close_from_initial_user_part ||
      rawStats.previous_time_to_close_from_initial_user_part_ooo;

    this.previousPositiveRate = this.getPositiveRate(rawStats.previous_csat);

    this.previousMostAssignedTeam = this.getMostAssignedTeam(
      rawStats.previous_team_assignee_counts,
    );
  }

  // Propagated stats have a different format as they don't include
  // prefixes of "current_" or "previous_"
  static formatPropagatedStats({ currentStats, previousStats }) {
    let topicId = currentStats?.topic_id || previousStats?.topic_id;

    currentStats = omit(currentStats, 'topic_id', 'topic_version_id', 'fully_propagated_from');
    let currentFormatted = Object.keys(currentStats).reduce((acc, key) => {
      acc[`current_${key}`] = currentStats[key];
      return acc;
    }, {});

    previousStats = omit(previousStats, 'topic_id', 'topic_version_id', 'fully_propagated_from');
    let previousFormatted = Object.keys(previousStats).reduce((acc, key) => {
      acc[`previous_${key}`] = previousStats[key];
      return acc;
    }, {});

    return { topic_id: topicId, ...currentFormatted, ...previousFormatted };
  }

  getPositiveRate(csat) {
    if (!csat?.length) {
      return null;
    }
    let positiveCount = csat
      .filter((bucket) => bucket.rating >= 4)
      .reduce((acc, bucket) => acc + bucket.count, 0);
    let totalCount = csat.reduce((acc, bucket) => acc + bucket.count, 0);
    return (positiveCount / totalCount) * 100;
  }

  getMostAssignedTeam(team_assignee_counts) {
    if (!team_assignee_counts?.length) {
      return null;
    }
    return team_assignee_counts.reduce(function (prev, current) {
      return prev.count > current.count ? prev : current;
    });
  }
}
