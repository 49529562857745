/* RESPONSIBLE TEAM: team-frontend-tech */
import { helper as buildHelper } from '@ember/component/helper';
import { toPercentString } from 'embercom/lib/percentage-formatter';
import { formatMinutes } from 'embercom/lib/duration-formatter';
import numberFormatter, { reportingTruncatedNumberFormatter } from 'embercom/lib/number-formatter';
import { pluralize } from 'ember-inflector';

export const unitFormatter = ({ value, unit, abbreviate, hideDefaultUnit }) => {
  switch (unit) {
    case 'percent':
      return toPercentString(value);
    case 'minute':
      return formatMinutes(value);
    default:
      return `${abbreviate ? reportingTruncatedNumberFormatter(value) : numberFormatter(value)}${
        !hideDefaultUnit && unit ? ` ${pluralize(value, unit, { withoutCount: true })}` : ''
      }`;
  }
};

export default buildHelper(function ([value, unit, abbreviate]) {
  return unitFormatter({ value, unit, abbreviate });
});
