/* RESPONSIBLE TEAM: team-tickets-1 */
// @ts-nocheck
import {
  BlocksDocument,
  type ComposerPublicAPI,
  type ComposerConfig,
} from '@intercom/embercom-prosemirror-composer';
import InboxComposerConfig from 'embercom/lib/inbox/composer-config';
import InboxComposerConfigPlaintext from 'embercom/lib/inbox/composer-config-plaintext';
import InboxComposerConfigWhatsapp from 'embercom/lib/inbox/composer-config-whatsapp';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { schedule } from '@ember/runloop';
import normalizeBRTags from './composer-helpers/normalize-br-tags';
import trimWhitespaces from './composer-helpers/trim-whitespaces';

export class ProsemirrorComposerWrapper {
  macros: any; // composer contains different macros objects for comment and note pane
  enableTemplating?: boolean;
  @tracked blocksDoc: BlocksDocument;
  @tracked api: ComposerPublicAPI;
  @tracked macro: any;

  @tracked paneComponent: any;
  @tracked config: ComposerConfig;
  constructor(paneComponent, macros, enableTemplating) {
    this.blocksDoc = new BlocksDocument();
    this.paneComponent = paneComponent;
    this.macros = macros;
    this.enableTemplating = enableTemplating;
    this.setConfig();
  }

  @action
  setConfig() {
    if (!this.configNeedsUpdating()) {
      return;
    }
    this.setMacroOfActivePane(this.activePane);

    if (this.isWhatsapp && this.activePane !== 'note') {
      this.config = new InboxComposerConfigWhatsapp(
        this.paneComponent.app,
        this.placeholder,
        this.conversation,
        this.activePane,
        this.macro,
      );
    } else if (this.isSocialChannel && this.activePane !== 'note') {
      this.config = new InboxComposerConfigPlaintext(
        this.placeholder,
        this.conversation,
        this.activePane,
        this.macro,
      );
    } else {
      this.config = new InboxComposerConfig(
        this.paneComponent.app,
        this.conversation,
        this.activePane,
        this.attachmentsEnabled,
        this.placeholder,
        this.macro,
        this.enableTemplating,
      );
    }
  }

  getBlocks() {
    let blocksDoc = this.blocksDoc;
    blocksDoc = trimWhitespaces(blocksDoc);
    blocksDoc = normalizeBRTags(blocksDoc);
    return blocksDoc.blocks;
  }

  sync() {
    //noop new composer is always in sync
  }

  focus() {
    this.api.composer.commands.focus();
  }

  removeFocus() {
    (document.activeElement as HTMLElement).blur();
  }

  load(blocks) {
    this.blocksDoc = new BlocksDocument(blocks);
    schedule('afterRender', () => {
      this.paneComponent.set('composerHasContent', this.api.composer.state.hasContent);
    });
  }

  loadAndFocus(blocks) {
    this.blocksDoc = new BlocksDocument(blocks);
    schedule('afterRender', () => {
      this.api.composer.commands.focus();
      this.paneComponent.set('composerHasContent', this.api.composer.state.hasContent);
    });
  }

  createBlock(type, args) {
    let block = Object.assign({}, { type }, args);
    this.api.composer.commands.insertBlock(block);
  }

  uploadImage(files: Array<File>) {
    files.reverse().forEach((file) => {
      this.api.composer.commands.insertImageFile(file);
      this.api.composer.commands.collapseSelection();
    });
  }

  uploadAttachment(files: Array<File>) {
    files.forEach((file) => {
      this.api.composer.commands.addAttachment(file);
    });
  }

  insertBlocks(blocks) {
    this.api.composer.commands.insertBlocks(blocks);
  }

  insertInvalidAttachments(files, errorMessage) {
    let blocks = files.map(({ name, size }) => {
      return {
        type: 'attachmentList',
        attachments: [
          {
            name,
            size,
            status: 2,
            errorMessage,
          },
        ],
      };
    });
    this.api.composer.commands.insertAttachments(blocks);
  }

  paste(text, type) {
    if (type === 'plain') {
      this.api.composer.commands.insertText(text);
      this.focus();
    }
  }

  setMacroOfActivePane(activePane) {
    this.macro = this.macros[activePane];
  }

  clearMacros() {
    this.macros['comment']?.clearFromConversation();
    this.macros['note']?.clearFromConversation();
  }

  @action
  onReady(api: ComposerPublicAPI) {
    this.api = api;
    this.paneComponent.set('composerHasContent', this.api.composer.state.hasContent);
  }

  @action
  onChange(blocksDoc: BlocksDocument) {
    this.blocksDoc = blocksDoc;
    let trimmedBlocksDoc = trimWhitespaces(this.blocksDoc);
    this.paneComponent.set(
      'composerHasContent',
      this.api.composer.state.hasContent && trimmedBlocksDoc.blocks.length > 0,
    );
    this.paneComponent.controlsAPI.actions.onComposerChange();
    this.paneComponent.controlsAPI.actions.onComposerKeyDown(); // used to send nexus events
  }

  @action
  uploadingStateChanged() {
    this.paneComponent.set('composerIsUploading', this.api.composer.state.isUploading);
  }

  send(command, ...args) {
    try {
      this[command](...args);
    } catch (e) {
      console.error(`Error running command ${command}: ${e.message}`);
    }
  }
  // The config of the composer is based on properties of the controlsAPI object
  // but this object in turn has dependencies on properties that come from the composer,
  // most noteably hasContent. This causes a big circular mess of dependency that
  // results in the composer getting reconfigured multiple times when you change tab.
  // This is quite an expensive and wasteful operation so we track the properties that
  // influence the composer config and exit early if none of them have actuallly changed
  private configInfluencingProps = [
    'activePane',
    'attachmentsEnabled',
    'conversation',
    'placeholder',
  ];
  private previousConfigInfluencingValues: { [key: string]: any } = {};
  private configNeedsUpdating() {
    let shouldChangeConfig = false;
    this.configInfluencingProps.forEach((prop) => {
      if (this[prop] !== this.previousConfigInfluencingValues[prop]) {
        shouldChangeConfig = true;
      }
      this.previousConfigInfluencingValues[prop] = this[prop];
    });
    if (!shouldChangeConfig) {
      return false;
    }

    return true;
  }

  private get conversation() {
    return this.paneComponent.controlsAPI.conversation;
  }

  private get activePane() {
    return this.paneComponent.controlsAPI.activePaneIdentifier;
  }

  private get attachmentsEnabled() {
    return this.paneComponent.app.teammateConversationAttachmentsEnabled;
  }

  private get placeholder() {
    return this.paneComponent.composerPlaceholder;
  }

  private get isSocialChannel() {
    return this.paneComponent.controlsAPI.conversation.isSocial;
  }

  private get isWhatsapp() {
    let conversation = this.paneComponent.controlsAPI.conversation;
    return conversation.isWhatsapp;
  }
}
