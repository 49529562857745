/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { helper } from '@ember/component/helper';
import { checkpointStatusNames } from 'embercom/models/data/matching-system/matching-constants';
import { isNone } from '@ember/utils';

export function checkpointStatus(params, namedArgs) {
  let status = namedArgs.status;

  if (isNone(status)) {
    throw new Error(`No status passed to checkpoint status helper`);
  }

  return checkpointStatusNames[status] || 'Unknown';
}

export default helper(checkpointStatus);
