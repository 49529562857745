/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import AddressSettings from 'embercom/lib/address-settings';
import { isEmpty } from '@ember/utils';

export function addressValidation(address) {
  let { countryCode, stateCode, postcode, streetAddress, city } = address;
  let addressSettings = AddressSettings[countryCode] || AddressSettings.default;

  if (isEmpty(countryCode) || isEmpty(streetAddress) || isEmpty(city) || isEmpty(postcode)) {
    return false;
  }

  if (addressSettings.showState && isEmpty(stateCode)) {
    return false;
  }

  if (addressSettings.postCodeRegex && !addressSettings.postCodeRegex.exec(postcode)) {
    return false;
  }

  return true;
}
