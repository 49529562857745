/* RESPONSIBLE TEAM: team-help-desk-experience */

export const ARTICLES_SURVEY_ID = '29881657';

export type AiAssistSettingsResponse = {
  inbox: boolean;
  conversation_summarization: boolean;
  text_transformations: boolean;
  auto_fill_tickets: boolean;
  articles: boolean;
  copilot: boolean;
};

export const SUPPORTED_LANGUAGES = [
  'french',
  'spanish',
  'english',
  'portuguese',
  'german',
  'swedish',
  'danish',
  'hebrew',
  'turkish',
  'croatian',
  'hindi',
] as const;
