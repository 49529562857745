/* RESPONSIBLE TEAM: team-frontend-tech */
let MetaTags = {};

MetaTags.buildMetaTag = function (name, content) {
  return {
    type: 'meta',
    tagId: `${name}-meta-tag`,
    attrs: { name, content },
  };
};

MetaTags.buildLinkTag = function (rel, href) {
  return {
    type: 'link',
    tagId: `${rel}-link-tag`,
    attrs: { rel, href },
  };
};

MetaTags.buildOpenGraphTag = function (property, content) {
  return {
    type: 'meta',
    tagId: `og:${property}-meta-tag`,
    attrs: {
      property: `og:${property}`,
      content,
    },
  };
};

MetaTags.buildTwitterTag = function (name, content) {
  return this.buildMetaTag(`twitter:${name}`, content);
};

export default MetaTags;
