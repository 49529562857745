/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
import { getIntlService } from 'embercom/lib/container-lookup';

export default {
  web: [
    {
      key: 'javascript',
      name: getIntlService().t('app.lib.guide.start-screen.installation-options.javascript'),
      description: getIntlService().t(
        'app.lib.guide.start-screen.installation-options.javascript-description',
      ),
    },
    {
      key: 'single_page',
      name: getIntlService().t('app.lib.guide.start-screen.installation-options.single-page'),
      description: getIntlService().t(
        'app.lib.guide.start-screen.installation-options.single-page-description',
      ),
    },
    {
      key: 'rails',
      name: getIntlService().t('app.lib.guide.start-screen.installation-options.ruby-on-rails'),
      description: getIntlService().t(
        'app.lib.guide.start-screen.installation-options.ruby-description',
      ),
    },
  ],
  mobile: [
    {
      key: 'ios',
      name: getIntlService().t('app.lib.guide.start-screen.installation-options.ios'),
      description: getIntlService().t(
        'app.lib.guide.start-screen.installation-options.ios-description',
      ),
    },
    {
      key: 'android',
      name: getIntlService().t('app.lib.guide.start-screen.installation-options.android'),
      description: getIntlService().t(
        'app.lib.guide.start-screen.installation-options.android-description',
      ),
    },
    {
      key: 'cordova',
      name: getIntlService().t('app.lib.guide.start-screen.installation-options.cordova'),
      description: getIntlService().t(
        'app.lib.guide.start-screen.installation-options.cordova-description',
      ),
    },
  ],
  thirdParty: [
    {
      key: 'segment',
      name: getIntlService().t('app.lib.guide.start-screen.installation-options.segment'),
      description: getIntlService().t(
        'app.lib.guide.start-screen.installation-options.segment-description',
      ),
    },
    {
      key: 'wordpress',
      name: getIntlService().t('app.lib.guide.start-screen.installation-options.wordpress'),
      description: getIntlService().t(
        'app.lib.guide.start-screen.installation-options.wordpress-description',
      ),
    },
    {
      key: 'shopify',
      name: getIntlService().t('app.lib.guide.start-screen.installation-options.shopify'),
      description: getIntlService().t(
        'app.lib.guide.start-screen.installation-options.shopify-description',
      ),
    },
  ],
  integration: [
    {
      key: 'segment',
      name: getIntlService().t(
        'app.lib.guide.start-screen.installation-options.integration-segment',
      ),
      description: getIntlService().t(
        'app.lib.guide.start-screen.installation-options.integration-segment-description',
      ),
    },
    {
      key: 'stripe',
      name: getIntlService().t('app.lib.guide.start-screen.installation-options.stripe'),
      description: getIntlService().t(
        'app.lib.guide.start-screen.installation-options.stripe-description',
      ),
    },
    {
      key: 'mailchimp',
      name: getIntlService().t('app.lib.guide.start-screen.installation-options.mailchimp'),
      description: getIntlService().t(
        'app.lib.guide.start-screen.installation-options.mailchimp-description',
      ),
    },
  ],
};
