/* RESPONSIBLE TEAM: team-reporting */
import Helper from '@ember/component/helper';
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

// unlike `timezoneFallbackMappings` in the intl service, this is a hardcoded
// mapping from old names to new names for specific timezones which have
// manual value maps elsewhere in the intercom and embercom repos for
// compatibility reasons. This allows us to display the new name without
// needing to update all dependencies (eg. moment) here to understand the
// mapping
const timezoneNameOverrides: Record<string, string> = {
  /* eslint-disable @intercom/intercom/no-bare-strings */
  'Europe/Kiev': 'Europe/Kyiv',
};

export interface FormatTimezoneCitySignature {
  Args: {
    Positional: [timezone: string, translationKey?: string];
  };
  Return: string;
}

export default class FormatTimezoneCity extends Helper<FormatTimezoneCitySignature> {
  @service declare intl: IntlService;

  compute([timezone, translationKey]: [timezone: string, translationKey?: string]): string {
    if (timezoneNameOverrides[timezone]) {
      timezone = timezoneNameOverrides[timezone];
    }
    let cityName = timezone.slice(timezone.lastIndexOf('/') + 1).replace(/_/g, ' ');

    let offsetHours = moment
      .tz(timezone)
      .format('Z')
      .replace(/0(\d:)/, '$1')
      .replace(':00', '');

    return this.intl.t(translationKey || 'reporting.timezone-city-with-offset', {
      cityName,
      offsetHours,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'format-timezone-city': typeof FormatTimezoneCity;
  }
}
