/* RESPONSIBLE TEAM: team-app-security */
import BaseValidator from 'ember-cp-validations/validators/base';

// Used to validate that a string of IP addresses is separated by commas and spaces.
// matches any string that starts with one or more non-space characters, followed by zero or more comma-separated non-space characters.
const SEPARATED_BY_COMMAS_REGEX = /^([^ ]+)(,\s*[^ ]+)*$/;

// Used to validate that a string is a valid IP address.
// matches any string that consists of four groups of one to three digits, separated by periods.
// the last group can optionally be followed by a forward slash and one or two digits, to represent a subnet mask.
const IP_ADDRESS_REGEX = /^(?:\d{1,3}\.){3}\d{1,3}(?:\/([0-9]|[1-2][0-9]|3[0-2]))?$/;

export default class IpAllowListValidator extends BaseValidator {
  constructor(ipString, intl) {
    super(...arguments);

    this.intl = intl;
    this.ipString = ipString;
  }

  get isEmptyIpString() {
    return !this.ipString;
  }

  get isSeparatedByCommas() {
    return this.ipString.match(SEPARATED_BY_COMMAS_REGEX);
  }

  get isNotSeparatedByCommas() {
    return !this.isSeparatedByCommas;
  }

  get hasNoFormatErrors() {
    return this.isSeparatedByCommas && this.ipsCorrectlyFormatted;
  }

  get isValid() {
    return this.isEmptyIpString || this.hasNoFormatErrors;
  }

  get isNotValid() {
    return !this.isValid;
  }

  get ipsCorrectlyFormatted() {
    return this.rawIps.every((ip) => {
      return IP_ADDRESS_REGEX.test(ip);
    });
  }

  get rawIps() {
    return this.ipString.split(',').map((ip) => ip.trim());
  }

  get errorMessage() {
    if (this.isEmptyIpString) {
      return null;
    } else if (this.isNotSeparatedByCommas) {
      return this.intl.t('apps.app.settings.security.ip-no-commas');
    } else if (!this.ipsCorrectlyFormatted) {
      return this.intl.t('apps.app.settings.security.ip-invalid');
    }
    return null;
  }
}
