/* RESPONSIBLE TEAM: team-frontend-tech */
import { helper as buildHelper } from '@ember/component/helper';
import PALETTE from '@intercom/pulse/lib/palette';
import { assert } from '@ember/debug';

export function buildPalette([firstKey, ...rest]) {
  if (rest.length) {
    return [firstKey, ...rest].map((key) => {
      assert(`${key} is not a color in pulse/lib/palette`, PALETTE[key]);
      return PALETTE[key];
    });
  } else {
    assert(`${firstKey} is not a color in pulse/lib/palette`, PALETTE[firstKey]);
    return PALETTE[firstKey];
  }
}

export default buildHelper(buildPalette);
