/* RESPONSIBLE TEAM: team-reporting */
import { FILTER_PROPERTY_PLACEHOLDER } from 'embercom/components/reporting/flexible/chart';
import {
  buildFiltersForDataConfig,
  buildAxesForDataConfig,
  timeDataModel,
  getApplyLimitOnServer,
} from 'embercom/lib/reporting/custom/data-config-builder-helpers';
import { requestNameFor } from '../chart-data-resource-compatible-helper';
import { TEAM_TEAMMATE_GROUPING_MULTIMETRIC } from 'embercom/models/reporting/custom/visualization-options';

export default class TableDataConfigBuilder {
  constructor(renderableChart) {
    this.renderableChart = renderableChart;

    if (this.renderableChart.showTimeComparison) {
      if (this.renderableChart.segmentBy) {
        throw new Error(
          'Segment by is not supported for time comparison - see https://github.com/intercom/intercom/issues/319596',
        );
      }
      if (this.renderableChart.viewBy !== 'time') {
        throw new Error(
          'View by must be time for time comparison - see https://github.com/intercom/intercom/issues/319596',
        );
      }
    }
  }

  buildDataConfig() {
    let dataConfig = {
      columns: this._multiMetricColumns,
      rows: [],
    };

    let { xAxis, yAxis } = buildAxesForDataConfig(this.renderableChart);
    if (xAxis) {
      dataConfig.rows[0] = xAxis;
      if (this.renderableChart.showTableSummaryRow) {
        dataConfig.rows[1] = this.getSummaryRow(dataConfig);
        if (yAxis) {
          dataConfig.rows[1].nestedGroupData = yAxis;
        }
      }
    }
    if (yAxis) {
      dataConfig.rows[0].nestedGroupData = yAxis;
    }
    return dataConfig;
  }

  getSummaryRow(dataConfig) {
    return {
      name: 'row-summary',
      type: 'allRecords',
    };
  }

  get timezone() {
    return this.renderableChart.reportState?.timezone;
  }

  get _multiMetricColumns() {
    let previousPeriodColumns = [];
    let currentPeriodColumns = this.renderableChart.chartSeries
      .toArray()
      .flatMap(({ metric: parentMetric, filters, aggregation, percentileValue }, index) => {
        return parentMetric.allFieldMetrics.flatMap((metric) => {
          let requestName = requestNameFor(index, metric, true);
          let allowUnlimitedBuckets =
            this.renderableChart.supportsFeature(TEAM_TEAMMATE_GROUPING_MULTIMETRIC) && index > 0;
          if (this.renderableChart.showTimeComparison) {
            previousPeriodColumns.push(
              this.makeColumn({
                name: `${requestName}-previous`,
                metric,
                parentMetric,
                filters,
                aggregation,
                percentileValue,
                isPreviousPeriod: true,
                allowUnlimitedBuckets,
              }),
            );
          }

          return this.makeColumn({
            name: requestName,
            metric,
            parentMetric,
            filters,
            aggregation,
            percentileValue,
            allowUnlimitedBuckets,
          });
        });
      });

    return [...previousPeriodColumns, ...currentPeriodColumns].flat().compact();
  }

  makeColumn({
    name,
    metric,
    parentMetric,
    filters,
    aggregation,
    percentileValue,
    isPreviousPeriod = null,
    allowUnlimitedBuckets = false,
  }) {
    let dataModel = timeDataModel(metric.property);

    return {
      name,
      source: metric.source,
      aggregation: {
        type: aggregation || metric.defaultAggregation,
        value: percentileValue,
        data: {
          property: metric.property,
        },
      },
      filter: buildFiltersForDataConfig(this.renderableChart, metric, parentMetric, filters),
      time: {
        property: metric.timeProperty,
        start: FILTER_PROPERTY_PLACEHOLDER,
        end: FILTER_PROPERTY_PLACEHOLDER,
        ...(dataModel && { data_model: dataModel }),
        interval:
          this.renderableChart.viewByTimeInterval || this.renderableChart.dateRange.interval,
        timezone: this.timezone,
      },
      metric,
      applyLimitOnServer: getApplyLimitOnServer(parentMetric, allowUnlimitedBuckets),
      ...(isPreviousPeriod && { isPreviousPeriod }),
    };
  }
}
