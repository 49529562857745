/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-jquery */
/* eslint-disable ember/jquery-ember-run */
import { Promise as EmberPromise } from 'rsvp';
import $ from 'jquery';

// A CSP compliant, promise driven alternative to jQuery's getScript
let removeListenersAndElement = function (element) {
  $(element).off('load');
  $(element).off('error');
  element.parentNode.removeChild(element);
};

export default function loadCDNScript(url) {
  return new EmberPromise((resolve, reject) => {
    let script = document.createElement('script');
    document.head.appendChild(script);
    $(script).on('load', () => {
      removeListenersAndElement(script);
      resolve();
    });
    $(script).on('error', (error) => {
      removeListenersAndElement(script);
      reject(error);
    });
    script.asnyc = true;
    script.type = 'text/javascript';
    script.setAttribute('data-test-injected-script', 'true');
    script.src = url;
  });
}
