/* RESPONSIBLE TEAM: team-frontend-tech */
import { helper as buildHelper } from '@ember/component/helper';
import type EmberArray from '@ember/array';

export function lastObject<T>([arr]: [EmberArray<T>]) {
  return arr?.toArray()[(arr.length as number) - 1];
}

const lastObjectHelper = buildHelper(lastObject);

export default lastObjectHelper;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'last-object': typeof lastObjectHelper;
  }
}
