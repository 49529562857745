/* RESPONSIBLE TEAM: team-tickets-1 */
// Take a local conversation list and remote inbox state and return
// a list of conversation ids that are in one list but not the other
// or are in both lists but have different updated_at values
//
// localState:  an array of Ember objects with id and updated_at
// remoteState: an array of arrays with this form [[id, updated_at]]
//
// returns an array of ids
/* eslint guard-for-in: 0 */

export default function findConversationDiff(localState, remoteState) {
  let remoteById = {};
  for (let i = 0; i < remoteState.length; i++) {
    remoteById[remoteState[i][0]] = remoteState[i][1];
  }

  let localById = {};
  for (let convo of localState) {
    localById[convo.get('id')] = convo.get('updated_at');
  }

  let diff = {
    createdOrUpdatedIds: [],
    removedIds: [],
    allDifferingIds: [],
  };

  for (let remoteId in remoteById) {
    let remoteUpdatedAt = remoteById[remoteId];
    if (!localById[remoteId] || localById[remoteId] < remoteUpdatedAt) {
      diff.createdOrUpdatedIds.push(Number(remoteId));
      delete localById[remoteId];
    }
  }

  for (let localId in localById) {
    localId = Number(localId);
    if (!remoteById[localId]) {
      diff.removedIds.push(localId);
    }
  }

  diff.allDifferingIds = [...diff.createdOrUpdatedIds, ...diff.removedIds];

  return diff;
}
