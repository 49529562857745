/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import pluralize from 'embercom/lib/inflector';
import { capitalize } from '@ember/string';

export default function (message, options = {}) {
  let count = options.count || 0;
  let userLabels = message
    .get('selectionState.userTypes')
    .map((userLabel) => pluralize(userLabel, count))
    .join(' and ');
  if (options.capitalize) {
    userLabels = capitalize(userLabels);
  }
  return userLabels;
}
