/* RESPONSIBLE TEAM: team-tickets-1 */

import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

export default class DescriptorDisplayName extends Helper {
  @service intl;

  compute([descriptor]) {
    if (descriptor.isBuiltIn) {
      return this.intl.t(`inbox.ticket-attributes.default-attributes.${descriptor.name}`);
    }

    return descriptor.name;
  }
}
