/* RESPONSIBLE TEAM: team-purchase-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import {
  ARTICLES_ESSENTIAL_ID,
  ARTICLES_ID,
  ARTICLES_PRO_ID,
  CORE_ACCELERATE_ID,
  CORE_GROW_ID,
  CORE_CONVERT_ID,
  CORE_ENGAGE_ID,
  CORE_ENGAGE_PREMIUM_ID,
  CORE_ENGAGE_PRO_ID,
  CORE_ID,
  CORE_SCALE_ID,
  CORE_SUPPORT_ID,
  CORE_SUPPORT_PREMIUM_ID,
  CORE_SUPPORT_PRO_ID,
  INBOX_ESSENTIAL_ID,
  INBOX_ID,
  INBOX_PREMIUM_ID,
  INBOX_PRO_ID,
  MESSAGES_ESSENTIAL_ID,
  MESSAGES_ID,
  MESSAGES_PREMIUM_ID,
  MESSAGES_PRO_ID,
  CORE_CONVERT_PRO_ID,
  CORE_START_ID,
  CORE_STARTER_ID,
  CORE_STARTER_BASE_ID,
} from 'embercom/lib/billing';
import { type IntershopProduct } from 'embercom/models/data/intershop/product-types';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';

const seatsTranslation = `intershop.product_plan.pricing_metrics.seats`;
const peopleReachedTranslation = `intershop.product_plan.pricing_metrics.people_reached`;
const activePeopleTranslation = `intershop.product_plan.pricing_metrics.active_people`;
const articlesTranslation = `intershop.product_plan.pricing_metrics.fixed`;

const PRODUCT_FEATURES: Record<string, IntershopProduct> = {
  [CORE_STARTER_ID]: {
    metrics: [
      {
        name: seatsTranslation,
        type: 'seats',
        includedInPlans: [
          {
            id: CORE_STARTER_BASE_ID,
            includes: `intershop.product_plan.plans.core_starter.includes`,
          },
        ],
      },
      {
        name: peopleReachedTranslation,
        includedInPlans: [
          {
            id: CORE_STARTER_BASE_ID,
            includes: `intershop.product_plan.plans.core_starter.additional_pricing`,
          },
        ],
      },
    ],
    featureSections: [
      {
        header: 'intershop.product_plan.plans.core_starter.features.sections.1.header',
        features: [
          {
            feature: `intershop.product_plan.plans.core_starter.features.sections.1.features.1`,
            includedInPlans: [CORE_STARTER_BASE_ID],
          },
          {
            feature: `intershop.product_plan.plans.core_starter.features.sections.1.features.2`,
            includedInPlans: [CORE_STARTER_BASE_ID],
          },
          {
            feature: `intershop.product_plan.plans.core_starter.features.sections.1.features.3`,
            includedInPlans: [CORE_STARTER_BASE_ID],
          },
        ],
      },
      {
        header: 'intershop.product_plan.plans.core_starter.features.sections.2.header',
        features: [
          {
            feature: `intershop.product_plan.plans.core_engage_pro.features.sections.2.features.1`,
            includedInPlans: [CORE_STARTER_BASE_ID],
          },
        ],
      },
      {
        header: 'intershop.product_plan.plans.core_starter.features.sections.3.header',
        features: [
          {
            feature: `intershop.product_plan.plans.core_starter.features.sections.3.features.1`,
            includedInPlans: [CORE_STARTER_BASE_ID],
          },
          {
            feature: `intershop.product_plan.plans.core_starter.features.sections.3.features.2`,
            includedInPlans: [CORE_STARTER_BASE_ID],
          },
          {
            feature: `intershop.product_plan.plans.core_starter.features.sections.3.features.3`,
            includedInPlans: [CORE_STARTER_BASE_ID],
          },
        ],
      },
    ],
  },
  [CORE_SUPPORT_ID]: {
    usageButtonLabel: `intershop.product_plan.plan_cards.seat_button_label`,
    billingSummaryLinkIcon: 'add-participant',
    billingSummaryLinkText: `intershop.product_plan.billing_summary_link.core_support`,
    metrics: [
      {
        name: seatsTranslation,
        type: 'seats',
        includedInPlans: [
          {
            id: CORE_SUPPORT_PRO_ID,
          },
          {
            id: CORE_SUPPORT_PREMIUM_ID,
          },
        ],
      },
    ],
    featureSections: [
      {
        features: [
          {
            feature: `intershop.product_plan.plans.core_support_pro.features.10`,
            includedInPlans: [CORE_SUPPORT_PRO_ID, CORE_SUPPORT_PREMIUM_ID],
            icon: 'fin',
            comingSoon: 'team-expansion-intershop-fin',
          },
          {
            feature: `intershop.product_plan.plans.core_support_pro.features.1`,
            includedInPlans: [CORE_SUPPORT_PRO_ID, CORE_SUPPORT_PREMIUM_ID],
            modal: {
              heading: 'intershop.product_plan.plans.core_support_pro.features.1',
              description: 'intershop.product_plan.plans.core_support_pro.modal.1',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/vbp_2/core_support_pro/1.mp4',
              ),
            },
          },
          {
            feature: `intershop.product_plan.plans.core_support_pro.features.2`,
            includedInPlans: [CORE_SUPPORT_PRO_ID, CORE_SUPPORT_PREMIUM_ID],
            modal: {
              heading: 'intershop.product_plan.plans.core_support_pro.features.2',
              description: 'intershop.product_plan.plans.core_support_pro.modal.2',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/vbp_2/core_support_pro/2.mp4',
              ),
            },
          },
          {
            feature: `intershop.product_plan.plans.core_support_pro.features.3`,
            includedInPlans: [CORE_SUPPORT_PRO_ID, CORE_SUPPORT_PREMIUM_ID],
          },
          {
            feature: `intershop.product_plan.plans.core_support_pro.features.4`,
            includedInPlans: [CORE_SUPPORT_PRO_ID, CORE_SUPPORT_PREMIUM_ID],
            modal: {
              heading: 'intershop.product_plan.plans.core_support_pro.features.4',
              description: 'intershop.product_plan.plans.core_support_pro.modal.4',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/vbp_2/core_support_pro/4.mp4',
              ),
            },
          },
          {
            feature: `intershop.product_plan.plans.core_support_pro.features.5`,
            includedInPlans: [CORE_SUPPORT_PRO_ID, CORE_SUPPORT_PREMIUM_ID],
            modal: {
              heading: 'intershop.product_plan.plans.core_support_pro.features.5',
              description: 'intershop.product_plan.plans.core_support_pro.modal.5',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/vbp_2/core_support_pro/5.mp4',
              ),
            },
          },
          {
            feature: `intershop.product_plan.plans.core_support_pro.features.6`,
            includedInPlans: [CORE_SUPPORT_PRO_ID, CORE_SUPPORT_PREMIUM_ID],
            modal: {
              heading: 'intershop.product_plan.plans.core_support_pro.features.6',
              description: 'intershop.product_plan.plans.core_support_pro.modal.6',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/vbp_2/core_support_pro/6.png',
              ),
            },
          },
          {
            feature: `intershop.product_plan.plans.core_support_pro.features.7`,
            includedInPlans: [CORE_SUPPORT_PRO_ID, CORE_SUPPORT_PREMIUM_ID],
            modal: {
              heading: 'intershop.product_plan.plans.core_support_pro.features.7',
              description: 'intershop.product_plan.plans.core_support_pro.modal.7',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/vbp_2/core_support_pro/7.mp4',
              ),
            },
          },
          {
            feature: `intershop.product_plan.plans.core_support_pro.features.8`,
            includedInPlans: [CORE_SUPPORT_PRO_ID, CORE_SUPPORT_PREMIUM_ID],
            tooltip: 'intershop.product_plan.plans.core_support_pro.tooltip.8',
          },
          {
            feature: `intershop.product_plan.plans.core_support_pro.features.9`,
            includedInPlans: [CORE_SUPPORT_PRO_ID, CORE_SUPPORT_PREMIUM_ID],
            modal: {
              heading: 'intershop.product_plan.plans.core_support_pro.features.9',
              description: 'intershop.product_plan.plans.core_support_pro.modal.9',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/vbp_2/core_support_pro/9.webp',
              ),
            },
          },
          {
            feature: `intershop.product_plan.plans.core_support_premium.features.1`,
            includedInPlans: [CORE_SUPPORT_PREMIUM_ID],
          },
          {
            feature: `intershop.product_plan.plans.core_support_premium.features.2`,
            includedInPlans: [CORE_SUPPORT_PREMIUM_ID],
            tooltip: 'intershop.product_plan.plans.core_support_premium.tooltip.2',
          },
          {
            feature: `intershop.product_plan.plans.core_support_premium.features.3`,
            includedInPlans: [CORE_SUPPORT_PREMIUM_ID],
          },
          {
            feature: `intershop.product_plan.plans.core_support_premium.features.4`,
            includedInPlans: [CORE_SUPPORT_PREMIUM_ID],
            tooltip: 'intershop.product_plan.plans.core_support_premium.tooltip.4',
          },
          {
            feature: `intershop.product_plan.plans.core_support_premium.features.5`,
            includedInPlans: [CORE_SUPPORT_PREMIUM_ID],
            tooltip: 'intershop.product_plan.plans.core_support_premium.tooltip.5',
          },
          {
            feature: `intershop.product_plan.plans.core_support_premium.features.6`,
            includedInPlans: [CORE_SUPPORT_PREMIUM_ID],
          },
          {
            feature: `intershop.product_plan.plans.core_support_premium.features.7`,
            includedInPlans: [CORE_SUPPORT_PREMIUM_ID],
          },
        ],
      },
    ],
  },
  [CORE_ENGAGE_ID]: {
    usageButtonLabel: `intershop.product_plan.plan_cards.seats_and_people_reached_label`,
    billingSummaryLinkIcon: 'outbound-filled',
    billingSummaryLinkText: `intershop.product_plan.billing_summary_link.core_engage`,
    metrics: [
      {
        name: seatsTranslation,
        type: 'seats',
        includedInPlans: [
          {
            id: CORE_ENGAGE_PRO_ID,
          },
          {
            id: CORE_ENGAGE_PREMIUM_ID,
          },
        ],
      },
      {
        name: peopleReachedTranslation,
        includedInPlans: [
          {
            id: CORE_ENGAGE_PRO_ID,
            includes: `intershop.product_plan.plans.core_engage_pro.additional_pricing`,
          },
          {
            id: CORE_ENGAGE_PREMIUM_ID,
            includes: `intershop.product_plan.plans.core_engage_premium.additional_pricing`,
          },
        ],
      },
    ],
    featureSections: [
      {
        header: 'intershop.product_plan.plans.core_engage_pro.features.sections.1.header',
        features: [
          {
            feature: `intershop.product_plan.plans.core_engage_pro.features.sections.1.features.1`,
            includedInPlans: [CORE_ENGAGE_PRO_ID, CORE_ENGAGE_PREMIUM_ID],
            modal: {
              heading:
                'intershop.product_plan.plans.core_engage_pro.features.sections.1.features.1',
              description:
                'intershop.product_plan.plans.core_engage_pro.features.sections.1.modal.1',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/vbp_2/core_engage_pro/section_1/1.jpg',
              ),
            },
          },
          {
            feature: `intershop.product_plan.plans.core_engage_pro.features.sections.1.features.2`,
            includedInPlans: [CORE_ENGAGE_PRO_ID, CORE_ENGAGE_PREMIUM_ID],
            modal: {
              heading:
                'intershop.product_plan.plans.core_engage_pro.features.sections.1.features.2',
              description:
                'intershop.product_plan.plans.core_engage_pro.features.sections.1.modal.2',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/vbp_2/core_engage_pro/section_1/2.jpg',
              ),
            },
          },
          {
            feature: `intershop.product_plan.plans.core_engage_pro.features.sections.1.features.3`,
            includedInPlans: [CORE_ENGAGE_PRO_ID, CORE_ENGAGE_PREMIUM_ID],
            modal: {
              heading:
                'intershop.product_plan.plans.core_engage_pro.features.sections.1.features.3',
              description:
                'intershop.product_plan.plans.core_engage_pro.features.sections.1.modal.3',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/vbp_2/core_engage_pro/section_1/3.png',
              ),
            },
          },
          {
            feature: `intershop.product_plan.plans.core_engage_pro.features.sections.1.features.4`,
            includedInPlans: [CORE_ENGAGE_PRO_ID, CORE_ENGAGE_PREMIUM_ID],
            modal: {
              heading:
                'intershop.product_plan.plans.core_engage_pro.features.sections.1.features.4',
              description:
                'intershop.product_plan.plans.core_engage_pro.features.sections.1.modal.4',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/vbp_2/core_engage_pro/section_1/4.png',
              ),
            },
          },
          {
            feature: `intershop.product_plan.plans.core_engage_pro.features.sections.1.features.5`,
            includedInPlans: [CORE_ENGAGE_PRO_ID, CORE_ENGAGE_PREMIUM_ID],
            modal: {
              heading:
                'intershop.product_plan.plans.core_engage_pro.features.sections.1.features.5',
              description:
                'intershop.product_plan.plans.core_engage_pro.features.sections.1.modal.5',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/vbp_2/core_engage_pro/section_1/5.mp4',
              ),
            },
          },
          {
            feature: `intershop.product_plan.plans.core_engage_pro.features.sections.1.features.6`,
            includedInPlans: [CORE_ENGAGE_PRO_ID, CORE_ENGAGE_PREMIUM_ID],
            modal: {
              heading:
                'intershop.product_plan.plans.core_engage_pro.features.sections.1.features.6',
              description:
                'intershop.product_plan.plans.core_engage_pro.features.sections.1.modal.6',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/vbp_2/core_engage_pro/section_1/6.jpg',
              ),
            },
          },
          {
            feature: `intershop.product_plan.plans.core_engage_pro.features.sections.1.features.7`,
            includedInPlans: [CORE_ENGAGE_PRO_ID, CORE_ENGAGE_PREMIUM_ID],
          },
          {
            feature: `intershop.product_plan.plans.core_engage_pro.features.sections.1.features.8`,
            includedInPlans: [CORE_ENGAGE_PRO_ID, CORE_ENGAGE_PREMIUM_ID],
            modal: {
              heading:
                'intershop.product_plan.plans.core_engage_pro.features.sections.1.features.8',
              description:
                'intershop.product_plan.plans.core_engage_pro.features.sections.1.modal.8',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/vbp_2/core_engage_pro/section_1/8.mp4',
              ),
            },
          },
        ],
      },
      {
        header: 'intershop.product_plan.plans.core_engage_pro.features.sections.2.header',
        features: [
          {
            feature: `intershop.product_plan.plans.core_engage_pro.features.sections.2.features.1`,
            includedInPlans: [CORE_ENGAGE_PRO_ID, CORE_ENGAGE_PREMIUM_ID],
            modal: {
              heading:
                'intershop.product_plan.plans.core_engage_pro.features.sections.2.features.1',
              description:
                'intershop.product_plan.plans.core_engage_pro.features.sections.2.modal.1',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/vbp_2/core_engage_pro/section_2/1.jpg',
              ),
            },
          },
          {
            feature: `intershop.product_plan.plans.core_engage_pro.features.sections.2.features.2`,
            includedInPlans: [CORE_ENGAGE_PRO_ID, CORE_ENGAGE_PREMIUM_ID],
          },
          {
            feature: `intershop.product_plan.plans.core_engage_pro.features.sections.2.features.3`,
            includedInPlans: [CORE_ENGAGE_PRO_ID, CORE_ENGAGE_PREMIUM_ID],
            modal: {
              heading:
                'intershop.product_plan.plans.core_engage_pro.features.sections.2.features.3',
              description:
                'intershop.product_plan.plans.core_engage_pro.features.sections.2.modal.3',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/vbp_2/core_engage_pro/section_2/3.mp4',
              ),
            },
          },
        ],
      },
      {
        header: 'intershop.product_plan.plans.core_engage_pro.features.sections.3.header',
        features: [
          {
            feature: `intershop.product_plan.plans.core_engage_pro.features.sections.3.features.1`,
            includedInPlans: [CORE_ENGAGE_PRO_ID, CORE_ENGAGE_PREMIUM_ID],
            modal: {
              heading:
                'intershop.product_plan.plans.core_engage_pro.features.sections.3.features.1',
              description:
                'intershop.product_plan.plans.core_engage_pro.features.sections.3.modal.1',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/vbp_2/core_engage_pro/section_3/1.png',
              ),
            },
          },
          {
            feature: `intershop.product_plan.plans.core_engage_pro.features.sections.3.features.2`,
            includedInPlans: [CORE_ENGAGE_PRO_ID, CORE_ENGAGE_PREMIUM_ID],
            tooltip: 'intershop.product_plan.plans.core_engage_pro.features.sections.3.tooltip.2',
          },
        ],
      },
      {
        header: 'intershop.product_plan.plans.core_engage_pro.features.sections.4.header',
        features: [
          {
            feature: `intershop.product_plan.plans.core_engage_pro.features.sections.4.features.1`,
            includedInPlans: [CORE_ENGAGE_PRO_ID, CORE_ENGAGE_PREMIUM_ID],
          },
        ],
      },
      {
        header: 'intershop.product_plan.plans.core_engage_premium.features.sections.1.header',
        features: [
          {
            feature: `intershop.product_plan.plans.core_engage_premium.features.sections.1.features.1`,
            includedInPlans: [CORE_ENGAGE_PREMIUM_ID],
            tooltip:
              'intershop.product_plan.plans.core_engage_premium.features.sections.1.tooltip.1',
          },
          {
            feature: `intershop.product_plan.plans.core_engage_premium.features.sections.1.features.2`,
            includedInPlans: [CORE_ENGAGE_PREMIUM_ID],
            tooltip:
              'intershop.product_plan.plans.core_engage_premium.features.sections.1.tooltip.2',
          },
        ],
      },
      {
        header: 'intershop.product_plan.plans.core_engage_premium.features.sections.2.header',
        features: [
          {
            feature: `intershop.product_plan.plans.core_engage_premium.features.sections.2.features.1`,
            includedInPlans: [CORE_ENGAGE_PREMIUM_ID],
          },
          {
            feature: `intershop.product_plan.plans.core_engage_premium.features.sections.2.features.2`,
            includedInPlans: [CORE_ENGAGE_PREMIUM_ID],
          },
          {
            feature: `intershop.product_plan.plans.core_engage_premium.features.sections.2.features.3`,
            includedInPlans: [CORE_ENGAGE_PREMIUM_ID],
            tooltip:
              'intershop.product_plan.plans.core_engage_premium.features.sections.2.tooltip.3',
          },
        ],
      },
      {
        header: 'intershop.product_plan.plans.core_engage_premium.features.sections.3.header',
        features: [
          {
            feature: `intershop.product_plan.plans.core_engage_premium.features.sections.3.features.1`,
            includedInPlans: [CORE_ENGAGE_PREMIUM_ID],
          },
        ],
      },
    ],
  },
  [INBOX_ID]: {
    usageButtonLabel: `intershop.product_plan.plan_cards.seat_button_label`,
    billingSummaryLinkIcon: 'add-participant',
    billingSummaryLinkText: `intershop.product_plan.billing_summary_link.inbox`,
    metrics: [
      {
        name: seatsTranslation,
        type: 'seats',
        includedInPlans: [
          {
            id: INBOX_ESSENTIAL_ID,
            includes: `intershop.product_plan.plans.inbox_essential.includes`,
          },
          {
            id: INBOX_PRO_ID,
            includes: `intershop.product_plan.plans.inbox_pro.includes`,
          },
          {
            id: INBOX_PREMIUM_ID,
            includes: `intershop.product_plan.plans.inbox_premium.includes`,
          },
        ],
      },
    ],
    featureSections: [
      {
        features: [
          {
            feature: `intershop.product_plan.plans.inbox_essential.features.7`,
            includedInPlans: [INBOX_ESSENTIAL_ID, INBOX_PRO_ID, INBOX_PREMIUM_ID],
            icon: 'fin',
            comingSoon: 'team-expansion-intershop-fin',
          },
          {
            feature: `intershop.product_plan.plans.inbox_essential.features.1`,
            includedInPlans: [INBOX_ESSENTIAL_ID, INBOX_PRO_ID, INBOX_PREMIUM_ID],
          },
          {
            feature: `intershop.product_plan.plans.inbox_essential.features.2`,
            includedInPlans: [INBOX_ESSENTIAL_ID, INBOX_PRO_ID, INBOX_PREMIUM_ID],
            modal: {
              heading: 'intershop.product_plan.plans.inbox_essential.features.2',
              description: 'intershop.product_plan.plans.inbox_essential.modal.2',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/ppp/inbox_essential/2.mp4',
              ),
            },
          },
          {
            feature: `intershop.product_plan.plans.inbox_essential.features.3`,
            includedInPlans: [INBOX_ESSENTIAL_ID, INBOX_PRO_ID, INBOX_PREMIUM_ID],
            modal: {
              heading: 'intershop.product_plan.plans.inbox_essential.features.3',
              description: 'intershop.product_plan.plans.inbox_essential.modal.3',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/ppp/inbox_essential/3.mp4',
              ),
            },
          },
          {
            feature: `intershop.product_plan.plans.inbox_essential.features.4`,
            includedInPlans: [INBOX_ESSENTIAL_ID, INBOX_PRO_ID, INBOX_PREMIUM_ID],
          },
          {
            feature: `intershop.product_plan.plans.inbox_essential.features.5`,
            includedInPlans: [INBOX_ESSENTIAL_ID, INBOX_PRO_ID, INBOX_PREMIUM_ID],
          },
          {
            feature: `intershop.product_plan.plans.inbox_essential.features.6`,
            includedInPlans: [INBOX_ESSENTIAL_ID, INBOX_PRO_ID, INBOX_PREMIUM_ID],
            modal: {
              heading: 'intershop.product_plan.plans.inbox_essential.features.6',
              description: 'intershop.product_plan.plans.inbox_essential.modal.6',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/ppp/inbox_essential/6.png',
              ),
            },
          },
          {
            feature: `intershop.product_plan.plans.inbox_pro.features.1`,
            includedInPlans: [INBOX_PRO_ID, INBOX_PREMIUM_ID],
            modal: {
              heading: 'intershop.product_plan.plans.inbox_pro.features.1',
              mediaUrl: assetUrl('/assets/images/intershop/product-feature/ppp/inbox_pro/1.jpg'),
            },
          },
          {
            feature: `intershop.product_plan.plans.inbox_pro.features.2`,
            includedInPlans: [INBOX_PRO_ID, INBOX_PREMIUM_ID],
          },
          {
            feature: `intershop.product_plan.plans.inbox_pro.features.3`,
            includedInPlans: [INBOX_PRO_ID, INBOX_PREMIUM_ID],
            modal: {
              heading: 'intershop.product_plan.plans.inbox_pro.features.3',
              description: 'intershop.product_plan.plans.inbox_pro.modal.3',
              mediaUrl: assetUrl('/assets/images/intershop/product-feature/ppp/inbox_pro/3.png'),
            },
          },
          {
            feature: `intershop.product_plan.plans.inbox_pro.features.4`,
            includedInPlans: [INBOX_PRO_ID, INBOX_PREMIUM_ID],
            tooltip: 'intershop.product_plan.plans.inbox_pro.tooltip.4',
          },
          {
            feature: `intershop.product_plan.plans.inbox_pro.features.5`,
            includedInPlans: [INBOX_PRO_ID, INBOX_PREMIUM_ID],
          },
          {
            feature: `intershop.product_plan.plans.inbox_pro.features.6`,
            includedInPlans: [INBOX_PRO_ID, INBOX_PREMIUM_ID],
          },
          {
            feature: `intershop.product_plan.plans.inbox_pro.features.7`,
            includedInPlans: [INBOX_PRO_ID, INBOX_PREMIUM_ID],
          },
          {
            feature: `intershop.product_plan.plans.inbox_pro.features.8`,
            includedInPlans: [INBOX_PRO_ID, INBOX_PREMIUM_ID],
          },
          {
            feature: `intershop.product_plan.plans.inbox_premium.features.1`,
            includedInPlans: [INBOX_PREMIUM_ID],
            modal: {
              heading: 'intershop.product_plan.plans.inbox_premium.features.1',
              description: 'intershop.product_plan.plans.inbox_premium.modal.1',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/ppp/inbox_premium/1.mp4',
              ),
            },
          },
          {
            feature: `intershop.product_plan.plans.inbox_premium.features.2`,
            includedInPlans: [INBOX_PREMIUM_ID],
          },
          {
            feature: `intershop.product_plan.plans.inbox_premium.features.3`,
            includedInPlans: [INBOX_PREMIUM_ID],
          },
          {
            feature: `intershop.product_plan.plans.inbox_premium.features.4`,
            includedInPlans: [INBOX_PREMIUM_ID],
            tooltip: 'intershop.product_plan.plans.inbox_premium.tooltip.4',
          },
          {
            feature: `intershop.product_plan.plans.inbox_premium.features.5`,
            includedInPlans: [INBOX_PREMIUM_ID],
            tooltip: 'intershop.product_plan.plans.inbox_premium.tooltip.5',
          },
          {
            feature: `intershop.product_plan.plans.inbox_premium.features.6`,
            includedInPlans: [INBOX_PREMIUM_ID],
            modal: {
              heading: 'intershop.product_plan.plans.inbox_premium.features.6',
              description: 'intershop.product_plan.plans.inbox_premium.modal.6',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/ppp/inbox_premium/6.mp4',
              ),
            },
          },
          {
            feature: `intershop.product_plan.plans.inbox_premium.features.7`,
            includedInPlans: [INBOX_PREMIUM_ID],
          },
          {
            feature: `intershop.product_plan.plans.inbox_premium.features.8`,
            includedInPlans: [INBOX_PREMIUM_ID],
            tooltip: 'intershop.product_plan.plans.inbox_premium.tooltip.8',
          },
        ],
      },
    ],
  },
  [MESSAGES_ID]: {
    usageButtonLabel: `intershop.product_plan.plan_cards.active_people_label`,
    billingSummaryLinkIcon: 'outbound-filled',
    billingSummaryLinkText: `intershop.product_plan.billing_summary_link.messages`,
    metrics: [
      {
        name: activePeopleTranslation,
        includedInPlans: [
          {
            id: MESSAGES_ESSENTIAL_ID,
            includes: `intershop.product_plan.plans.messages_essential.additional_pricing`,
          },
          {
            id: MESSAGES_PRO_ID,
            includes: `intershop.product_plan.plans.messages_pro.additional_pricing`,
          },
          {
            id: MESSAGES_PREMIUM_ID,
            includes: `intershop.product_plan.plans.messages_premium.additional_pricing`,
          },
        ],
      },
    ],
    featureSections: [
      {
        features: [
          {
            feature: `intershop.product_plan.plans.messages_essential.features.1`,
            includedInPlans: [MESSAGES_ESSENTIAL_ID, MESSAGES_PRO_ID, MESSAGES_PREMIUM_ID],
          },
          {
            feature: `intershop.product_plan.plans.messages_essential.features.2`,
            includedInPlans: [MESSAGES_ESSENTIAL_ID, MESSAGES_PRO_ID, MESSAGES_PREMIUM_ID],
            modal: {
              heading: 'intershop.product_plan.plans.messages_essential.features.2',
              description: 'intershop.product_plan.plans.messages_essential.modal.2',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/ppp/messages_essential/2.jpg',
              ),
            },
          },
          {
            feature: `intershop.product_plan.plans.messages_essential.features.3`,
            includedInPlans: [MESSAGES_ESSENTIAL_ID, MESSAGES_PRO_ID, MESSAGES_PREMIUM_ID],
            modal: {
              heading: 'intershop.product_plan.plans.messages_essential.features.3',
              description: 'intershop.product_plan.plans.messages_essential.modal.3',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/ppp/messages_essential/3.mp4',
              ),
            },
          },
          {
            feature: `intershop.product_plan.plans.messages_essential.features.4`,
            includedInPlans: [MESSAGES_ESSENTIAL_ID, MESSAGES_PRO_ID, MESSAGES_PREMIUM_ID],
          },
          {
            feature: `intershop.product_plan.plans.messages_essential.features.5`,
            includedInPlans: [MESSAGES_ESSENTIAL_ID, MESSAGES_PRO_ID, MESSAGES_PREMIUM_ID],
          },
          {
            feature: `intershop.product_plan.plans.messages_essential.features.6`,
            includedInPlans: [MESSAGES_ESSENTIAL_ID, MESSAGES_PRO_ID, MESSAGES_PREMIUM_ID],
            modal: {
              heading: 'intershop.product_plan.plans.messages_essential.features.6',
              description: 'intershop.product_plan.plans.messages_essential.modal.6',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/ppp/messages_essential/6.mp4',
              ),
            },
          },
          {
            feature: `intershop.product_plan.plans.messages_pro.features.1`,
            includedInPlans: [MESSAGES_PRO_ID, MESSAGES_PREMIUM_ID],
          },
          {
            feature: `intershop.product_plan.plans.messages_pro.features.2`,
            includedInPlans: [MESSAGES_PRO_ID, MESSAGES_PREMIUM_ID],
            modal: {
              heading: 'intershop.product_plan.plans.messages_pro.features.2',
              description: 'intershop.product_plan.plans.messages_pro.modal.2',
              mediaUrl: assetUrl('/assets/images/intershop/product-feature/ppp/messages_pro/2.png'),
            },
          },
          {
            feature: `intershop.product_plan.plans.messages_pro.features.3`,
            includedInPlans: [MESSAGES_PRO_ID, MESSAGES_PREMIUM_ID],
            modal: {
              heading: 'intershop.product_plan.plans.messages_pro.features.3',
              description: 'intershop.product_plan.plans.messages_pro.modal.3',
              mediaUrl: assetUrl('/assets/images/intershop/product-feature/ppp/messages_pro/3.jpg'),
            },
          },
          {
            feature: `intershop.product_plan.plans.messages_pro.features.4`,
            includedInPlans: [MESSAGES_PRO_ID, MESSAGES_PREMIUM_ID],
            modal: {
              heading: 'intershop.product_plan.plans.messages_pro.features.4',
              description: 'intershop.product_plan.plans.messages_pro.modal.4',
              mediaUrl: assetUrl('/assets/images/intershop/product-feature/ppp/messages_pro/4.mp4'),
            },
          },
          {
            feature: `intershop.product_plan.plans.messages_pro.features.5`,
            includedInPlans: [MESSAGES_PRO_ID, MESSAGES_PREMIUM_ID],
            modal: {
              heading: 'intershop.product_plan.plans.messages_pro.features.5',
              description: 'intershop.product_plan.plans.messages_pro.modal.5',
              mediaUrl: assetUrl('/assets/images/intershop/product-feature/ppp/messages_pro/5.mp4'),
            },
          },
          {
            feature: `intershop.product_plan.plans.messages_pro.features.6`,
            includedInPlans: [MESSAGES_PRO_ID, MESSAGES_PREMIUM_ID],
          },
          {
            feature: `intershop.product_plan.plans.messages_pro.features.7`,
            includedInPlans: [MESSAGES_PRO_ID, MESSAGES_PREMIUM_ID],
            modal: {
              heading: 'intershop.product_plan.plans.messages_pro.features.7',
              description: 'intershop.product_plan.plans.messages_pro.modal.7',
              mediaUrl: assetUrl('/assets/images/intershop/product-feature/ppp/messages_pro/7.png'),
            },
          },
          {
            feature: `intershop.product_plan.plans.messages_pro.features.8`,
            includedInPlans: [MESSAGES_PRO_ID, MESSAGES_PREMIUM_ID],
            modal: {
              heading: 'intershop.product_plan.plans.messages_pro.features.8',
              description: 'intershop.product_plan.plans.messages_pro.modal.8',
              mediaUrl: assetUrl('/assets/images/intershop/product-feature/ppp/messages_pro/8.png'),
            },
          },
          {
            feature: `intershop.product_plan.plans.messages_pro.features.9`,
            includedInPlans: [MESSAGES_PRO_ID, MESSAGES_PREMIUM_ID],
            modal: {
              heading: 'intershop.product_plan.plans.messages_pro.features.9',
              description: 'intershop.product_plan.plans.messages_pro.modal.9',
              mediaUrl: assetUrl('/assets/images/intershop/product-feature/ppp/messages_pro/9.png'),
            },
          },
          {
            feature: `intershop.product_plan.plans.messages_premium.features.1`,
            includedInPlans: [MESSAGES_PREMIUM_ID],
            modal: {
              heading: 'intershop.product_plan.plans.messages_premium.features.1',
              description: 'intershop.product_plan.plans.messages_premium.modal.1',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/ppp/messages_premium/1.mp4',
              ),
            },
          },
          {
            feature: `intershop.product_plan.plans.messages_premium.features.2`,
            includedInPlans: [MESSAGES_PREMIUM_ID],
            tooltip: 'intershop.product_plan.plans.messages_premium.tooltip.2',
          },
          {
            feature: `intershop.product_plan.plans.messages_premium.features.3`,
            includedInPlans: [MESSAGES_PREMIUM_ID],
            tooltip: 'intershop.product_plan.plans.messages_premium.tooltip.3',
          },
          {
            feature: `intershop.product_plan.plans.messages_premium.features.4`,
            includedInPlans: [MESSAGES_PREMIUM_ID],
            tooltip: 'intershop.product_plan.plans.messages_premium.tooltip.4',
          },
          {
            feature: `intershop.product_plan.plans.messages_premium.features.5`,
            includedInPlans: [MESSAGES_PREMIUM_ID],
          },
          {
            feature: `intershop.product_plan.plans.messages_premium.features.6`,
            includedInPlans: [MESSAGES_PREMIUM_ID],
          },
          {
            feature: `intershop.product_plan.plans.messages_premium.features.7`,
            includedInPlans: [MESSAGES_PREMIUM_ID],
          },
          {
            feature: `intershop.product_plan.plans.messages_premium.features.8`,
            includedInPlans: [MESSAGES_PREMIUM_ID],
          },
        ],
      },
    ],
  },
  [ARTICLES_ID]: {
    usageButtonLabel: `intershop.product_plan.plan_cards.fixed_label`,
    metrics: [
      {
        name: articlesTranslation,
        includedInPlans: [
          {
            id: ARTICLES_ESSENTIAL_ID,
            includes: `intershop.product_plan.plans.articles_essential.includes`,
          },
          {
            id: ARTICLES_PRO_ID,
            includes: `intershop.product_plan.plans.articles_pro.includes`,
          },
        ],
      },
    ],
    featureSections: [
      {
        features: [
          {
            feature: `intershop.product_plan.plans.articles_essential.features.1`,
            includedInPlans: [ARTICLES_ESSENTIAL_ID, ARTICLES_PRO_ID],
            modal: {
              heading: 'intershop.product_plan.plans.articles_essential.features.1',
              description: 'intershop.product_plan.plans.articles_essential.modal.1',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/ppp/articles_essential/1.mp4',
              ),
            },
          },
          {
            feature: `intershop.product_plan.plans.articles_essential.features.2`,
            includedInPlans: [ARTICLES_ESSENTIAL_ID, ARTICLES_PRO_ID],
            tooltip: 'intershop.product_plan.plans.articles_essential.tooltip.2',
          },
          {
            feature: `intershop.product_plan.plans.articles_essential.features.3`,
            includedInPlans: [ARTICLES_ESSENTIAL_ID, ARTICLES_PRO_ID],
          },
          {
            feature: `intershop.product_plan.plans.articles_essential.features.4`,
            includedInPlans: [ARTICLES_ESSENTIAL_ID, ARTICLES_PRO_ID],
          },
          {
            feature: `intershop.product_plan.plans.articles_essential.features.5`,
            includedInPlans: [ARTICLES_ESSENTIAL_ID, ARTICLES_PRO_ID],
          },
          {
            feature: `intershop.product_plan.plans.articles_essential.features.6`,
            includedInPlans: [ARTICLES_ESSENTIAL_ID, ARTICLES_PRO_ID],
          },
          {
            feature: `intershop.product_plan.plans.articles_pro.features.1`,
            includedInPlans: [ARTICLES_PRO_ID],
            tooltip: 'intershop.product_plan.plans.articles_pro.tooltip.1',
          },
          {
            feature: `intershop.product_plan.plans.articles_pro.features.2`,
            includedInPlans: [ARTICLES_PRO_ID],
            modal: {
              heading: 'intershop.product_plan.plans.articles_pro.features.2',
              description: 'intershop.product_plan.plans.articles_pro.modal.2',
              mediaUrl: assetUrl('/assets/images/intershop/product-feature/ppp/articles_pro/2.png'),
            },
          },
        ],
      },
    ],
  },
  [CORE_ID]: {
    usageButtonLabel: `intershop.product_plan.plan_cards.seats_and_people_reached_label`,
    billingSummaryLinkIcon: 'outbound-filled',
    billingSummaryLinkText: `intershop.product_plan.billing_summary_link.core`,
    metrics: [
      {
        name: seatsTranslation,
        type: 'seats',
        includedInPlans: [
          {
            id: CORE_START_ID,
            includes: `intershop.product_plan.plans.core_good.includes`,
          },
          {
            id: CORE_GROW_ID,
            includes: `intershop.product_plan.plans.core_better.includes`,
          },
          {
            id: CORE_ACCELERATE_ID,
            includes: `intershop.product_plan.plans.core_best.includes`,
          },
          {
            id: CORE_SCALE_ID,
            includes: `intershop.product_plan.plans.core_premium.includes`,
          },
        ],
      },
      {
        name: peopleReachedTranslation,
        includedInPlans: [
          {
            id: CORE_START_ID,
          },
          {
            id: CORE_GROW_ID,
          },
          {
            id: CORE_ACCELERATE_ID,
          },
          {
            id: CORE_SCALE_ID,
          },
        ],
      },
    ],
    featureSections: [
      {
        features: [
          {
            feature: `intershop.product_plan.plans.core_better.features.6`,
            includedInPlans: [CORE_START_ID, CORE_GROW_ID, CORE_ACCELERATE_ID, CORE_SCALE_ID],
            icon: 'fin',
            comingSoon: 'team-expansion-intershop-fin',
          },
          {
            feature: `intershop.product_plan.plans.core_better.features.1`,
            includedInPlans: [CORE_START_ID, CORE_GROW_ID, CORE_ACCELERATE_ID, CORE_SCALE_ID],
            modal: {
              heading: 'intershop.product_plan.plans.core_better.features.1',
              description: 'intershop.product_plan.plans.core_better.modal.1',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/vbp_1/core_better/1.jpg',
              ),
            },
          },
          {
            feature: `intershop.product_plan.plans.core_better.features.2`,
            includedInPlans: [CORE_GROW_ID, CORE_ACCELERATE_ID, CORE_SCALE_ID],
            modal: {
              heading: 'intershop.product_plan.plans.core_better.features.2',
              description: 'intershop.product_plan.plans.core_better.modal.2',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/vbp_1/core_better/2.jpg',
              ),
            },
          },
          {
            feature: `intershop.product_plan.plans.core_better.features.3`,
            includedInPlans: [CORE_GROW_ID, CORE_ACCELERATE_ID, CORE_SCALE_ID],
            modal: {
              heading: 'intershop.product_plan.plans.core_better.features.3',
              description: 'intershop.product_plan.plans.core_better.modal.3',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/vbp_1/core_better/3.png',
              ),
            },
          },
          {
            feature: `intershop.product_plan.plans.core_better.features.4`,
            includedInPlans: [CORE_GROW_ID, CORE_ACCELERATE_ID, CORE_SCALE_ID],
          },
          {
            feature: `intershop.product_plan.plans.core_better.features.5`,
            includedInPlans: [CORE_GROW_ID, CORE_ACCELERATE_ID, CORE_SCALE_ID],
            modal: {
              heading: 'intershop.product_plan.plans.core_better.features.5',
              description: 'intershop.product_plan.plans.core_better.modal.5',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/vbp_1/core_better/5.mp4',
              ),
            },
          },
          {
            feature: `intershop.product_plan.plans.core_best.features.1`,
            includedInPlans: [CORE_ACCELERATE_ID, CORE_SCALE_ID],
          },
          {
            feature: `intershop.product_plan.plans.core_best.features.2`,
            includedInPlans: [CORE_ACCELERATE_ID, CORE_SCALE_ID],
            modal: {
              heading: 'intershop.product_plan.plans.core_best.features.2',
              description: 'intershop.product_plan.plans.core_best.modal.2',
              mediaUrl: assetUrl('/assets/images/intershop/product-feature/vbp_1/core_best/2.png'),
            },
          },
          {
            feature: `intershop.product_plan.plans.core_best.features.3`,
            includedInPlans: [CORE_ACCELERATE_ID, CORE_SCALE_ID],
            modal: {
              heading: 'intershop.product_plan.plans.core_best.features.3',
              description: 'intershop.product_plan.plans.core_best.modal.3',
              mediaUrl: assetUrl('/assets/images/intershop/product-feature/vbp_1/core_best/3.mp4'),
            },
          },
          {
            feature: `intershop.product_plan.plans.core_best.features.4`,
            includedInPlans: [CORE_ACCELERATE_ID, CORE_SCALE_ID],
          },
          {
            feature: `intershop.product_plan.plans.core_best.features.5`,
            includedInPlans: [CORE_ACCELERATE_ID, CORE_SCALE_ID],
            tooltip: 'intershop.product_plan.plans.core_best.tooltip.5',
          },
          {
            feature: `intershop.product_plan.plans.core_premium.features.1`,
            includedInPlans: [CORE_SCALE_ID],
          },
          {
            feature: `intershop.product_plan.plans.core_premium.features.2`,
            includedInPlans: [CORE_SCALE_ID],
          },
          {
            feature: `intershop.product_plan.plans.core_premium.features.3`,
            includedInPlans: [CORE_SCALE_ID],
            tooltip: 'intershop.product_plan.plans.core_premium.tooltip.3',
          },
          {
            feature: `intershop.product_plan.plans.core_premium.features.4`,
            includedInPlans: [CORE_SCALE_ID],
            tooltip: 'intershop.product_plan.plans.core_premium.tooltip.4',
          },
          {
            feature: `intershop.product_plan.plans.core_premium.features.5`,
            includedInPlans: [CORE_SCALE_ID],
          },
          {
            feature: `intershop.product_plan.plans.core_premium.features.6`,
            includedInPlans: [CORE_SCALE_ID],
          },
        ],
      },
    ],
  },
  [CORE_CONVERT_ID]: {
    usageButtonLabel: `intershop.product_plan.plan_cards.seats_and_people_reached_label`,
    billingSummaryLinkIcon: 'outbound-filled',
    billingSummaryLinkText: `intershop.product_plan.billing_summary_link.core_convert`,
    metrics: [
      {
        name: seatsTranslation,
        type: 'seats',
        includedInPlans: [
          {
            id: CORE_CONVERT_PRO_ID,
          },
        ],
      },
      {
        name: peopleReachedTranslation,
        includedInPlans: [
          {
            id: CORE_CONVERT_PRO_ID,
            includes: `intershop.product_plan.plans.core_convert_pro.additional_pricing`,
          },
        ],
      },
    ],
    featureSections: [
      {
        header: 'intershop.product_plan.plans.core_convert_pro.features.sections.1.header',
        features: [
          {
            feature: `intershop.product_plan.plans.core_convert_pro.features.sections.1.features.1`,
            includedInPlans: [CORE_CONVERT_PRO_ID],
          },
          {
            feature: `intershop.product_plan.plans.core_convert_pro.features.sections.1.features.2`,
            includedInPlans: [CORE_CONVERT_PRO_ID],
          },
          {
            feature: `intershop.product_plan.plans.core_convert_pro.features.sections.1.features.3`,
            includedInPlans: [CORE_CONVERT_PRO_ID],
            modal: {
              heading:
                'intershop.product_plan.plans.core_convert_pro.features.sections.1.features.3',
              description:
                'intershop.product_plan.plans.core_convert_pro.features.sections.1.modal.3',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/vbp_2/core_convert_pro/section_1/3.mp4',
              ),
            },
          },
        ],
      },

      {
        header: 'intershop.product_plan.plans.core_convert_pro.features.sections.2.header',
        features: [
          {
            feature: `intershop.product_plan.plans.core_convert_pro.features.sections.2.features.1`,
            includedInPlans: [CORE_CONVERT_PRO_ID],
          },
          {
            feature: `intershop.product_plan.plans.core_convert_pro.features.sections.2.features.2`,
            includedInPlans: [CORE_CONVERT_PRO_ID],
          },
        ],
      },

      {
        header: 'intershop.product_plan.plans.core_convert_pro.features.sections.3.header',
        features: [
          {
            feature: `intershop.product_plan.plans.core_convert_pro.features.sections.3.features.1`,
            includedInPlans: [CORE_CONVERT_PRO_ID],
          },
          {
            feature: `intershop.product_plan.plans.core_convert_pro.features.sections.3.features.2`,
            includedInPlans: [CORE_CONVERT_PRO_ID],
            modal: {
              heading:
                'intershop.product_plan.plans.core_convert_pro.features.sections.3.features.2',
              description:
                'intershop.product_plan.plans.core_convert_pro.features.sections.3.modal.2',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/vbp_2/core_convert_pro/section_3/2.mp4',
              ),
            },
          },
          {
            feature: `intershop.product_plan.plans.core_convert_pro.features.sections.3.features.3`,
            includedInPlans: [CORE_CONVERT_PRO_ID],
          },
          {
            feature: `intershop.product_plan.plans.core_convert_pro.features.sections.3.features.4`,
            includedInPlans: [CORE_CONVERT_PRO_ID],
            modal: {
              heading:
                'intershop.product_plan.plans.core_convert_pro.features.sections.3.features.4',
              description:
                'intershop.product_plan.plans.core_convert_pro.features.sections.3.modal.4',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/vbp_2/core_convert_pro/section_3/4.mp4',
              ),
            },
          },
          {
            feature: `intershop.product_plan.plans.core_convert_pro.features.sections.3.features.5`,
            includedInPlans: [CORE_CONVERT_PRO_ID],
            modal: {
              heading:
                'intershop.product_plan.plans.core_convert_pro.features.sections.3.features.5',
              mediaUrl: assetUrl(
                '/assets/images/intershop/product-feature/vbp_2/core_convert_pro/section_3/5.jpg',
              ),
            },
          },
          {
            feature: `intershop.product_plan.plans.core_convert_pro.features.sections.3.features.6`,
            includedInPlans: [CORE_CONVERT_PRO_ID],
          },
        ],
      },
    ],
  },
};

export { PRODUCT_FEATURES };
