/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
/* eslint-disable @intercom/intercom/no-bare-strings */
import {
  MEDIAN,
  MEAN,
  MAX,
  CARDINALITY,
  MIN,
  COUNT,
  RANGE,
  COUNTRY,
  GEO_GROUPING_LIMIT,
  CONTINENT,
  CONVERSATION_CREATED_AT,
  CONVERSATION_CREATED_AT_DAY_OF_WEEK,
  COMMENT_CREATED_AT,
  COMMENT_CREATED_AT_DAY_OF_WEEK,
  COUNTRY_GROUPING_TERM_SIZE,
  CONTINENT_GROUPING_TERM_SIZE,
  AVAILABLE_CONTINENT_MAPPINGS,
  COUNTRY_ALIASES,
  CHANNEL,
  CHANNEL_GROUPING_TERM_SIZE,
  AVAILABLE_CHANNEL_MAPPINGS,
  FIRST_REOPENED_AT,
  STATE_START,
  FIRST_USER_CONVERSATION_PART_CREATED_AT,
  FIRST_USER_CONVERSATION_PART_CREATED_AT_DAY_OF_WEEK,
  MAX_FIRST_USER_CONVERSATION_PART_CREATED_AT_DAY_OF_WEEK,
  CLOSE_DATE,
  MEETING_BOOKED_AT,
  FIRST_ADMIN_REPLY_AT,
  FIRST_CLOSED_AT,
  COMMENT_TEAM_ASSIGNED,
  TEAM_GROUPING_TERM_SIZE,
  TEAM_GROUPING_LIMIT,
  SORTING_LABEL_NAME,
  TIME,
} from './constants';

import { first, last, zip } from 'underscore';
import moment from 'moment-timezone';
import { isEmpty } from '@ember/utils';
import containerLookup from 'embercom/lib/container-lookup';

export const GROUPINGS = {
  [CONVERSATION_CREATED_AT]: {
    value: CONVERSATION_CREATED_AT,
    text: 'Time',
    type: 'temporal',
  },
  [FIRST_REOPENED_AT]: {
    value: FIRST_REOPENED_AT,
    text: 'Time',
    type: 'temporal',
  },
  [STATE_START]: {
    value: STATE_START,
    text: 'Time',
    type: 'temporal',
  },
  [TIME]: {
    value: TIME,
    text: 'Time',
    type: 'temporal',
  },
  [CONVERSATION_CREATED_AT_DAY_OF_WEEK]: {
    value: CONVERSATION_CREATED_AT,
    text: '',
    type: 'temporal',
    interval: 'day_of_week',
  },
  [COMMENT_CREATED_AT_DAY_OF_WEEK]: {
    value: COMMENT_CREATED_AT,
    text: '',
    type: 'temporal',
    interval: 'day_of_week',
  },
  [FIRST_USER_CONVERSATION_PART_CREATED_AT_DAY_OF_WEEK]: {
    value: FIRST_USER_CONVERSATION_PART_CREATED_AT,
    text: '',
    type: 'temporal',
    interval: 'day_of_week',
  },
  [MAX_FIRST_USER_CONVERSATION_PART_CREATED_AT_DAY_OF_WEEK]: {
    value: FIRST_USER_CONVERSATION_PART_CREATED_AT,
    text: '',
    type: 'temporal',
    interval: 'day_of_week',
    // check the test for this function to see an example of the full
    // data input structure
    dataTransformationName: 'max_for_week',
    dataTransformation: (dataResponses) => {
      let days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

      let data = first(dataResponses);
      let groups = first(data.groups).values;

      // each group represents a day and has the following structure:
      // {
      //   value: 1.0,
      //   groups: [
      //     {
      //       aggregations: [
      //         {
      //           name: '0',
      //           values: [999, 20, 30], // aggregations per hour
      //         },
      //       ],
      //       values: [0.0, 1.0, 2.0], // in real payload we have 24 values, one for an hour of a day
      //       name: '0',
      //       type: 'time',
      //     },
      //   ],
      // };

      let busiestHour = groups.reduce(
        (acc, group) => {
          let groupData = first(group.groups);
          let dayHours = groupData.values;
          let hourAggregations = first(groupData.aggregations).values;

          // zipping together hours and their aggregations to simplify sorting
          let [busiestHour, busiestHourValue] = _.chain(zip(dayHours, hourAggregations))
            .sort((a, b) => last(b) - last(a))
            .first()
            .value();

          // across all the hours of all days we figure out the one with the highest aggregation
          if (acc.busiestHourValue < busiestHourValue) {
            acc.busiestHourValue = busiestHourValue;
            acc.hour = busiestHour;
            acc.day = group.value;
          }

          return acc;
        },
        { busiestHourValue: 0, hour: null, day: null },
      );

      // after we found the busiest hour, format it right away.
      // doing it in the transformation because this formatting is not-generic
      if (isEmpty(busiestHour.hour)) {
        return;
      }

      let hour = moment().hours(busiestHour.hour).format('ha'); // ha produces something like 'Mondays, 8am'

      // -1 becuase days array starts with 0 and ES returns days starting with 1
      let day = days[busiestHour.day - 1];
      return `${day}, ${hour}`;
    },
  },
  [COMMENT_TEAM_ASSIGNED]: {
    value: COMMENT_TEAM_ASSIGNED,
    text: 'Team assigned',
    sorting: SORTING_LABEL_NAME,
    limit: TEAM_GROUPING_LIMIT,
    termSize: TEAM_GROUPING_TERM_SIZE,
    excludeMissingGroup: true,
    labelMappings: {},
    type: 'nominal',
  },
  [COUNTRY]: {
    value: COUNTRY,
    text: 'Country',
    textKey: 'reporting.flexible.view-config.country',
    type: 'nominal',
    limit: GEO_GROUPING_LIMIT,
    termSize: COUNTRY_GROUPING_TERM_SIZE,
    dataTransformationName: 'other_aggregation',
    dataTransformation: (dataResponse) => {
      let group = dataResponse.groups[0];
      let aggregation = group.aggregations[0];
      let pushOtherToLast = (dataResponse) => {
        let otherGroupIndex = group.values.findIndex((g) => g === 'Other');

        if (otherGroupIndex > -1) {
          group.values.splice(otherGroupIndex, 1);
          group.values.push('Other');
          let values = aggregation.values.splice(otherGroupIndex, 1);
          aggregation.values.push(values[0]);
        }
        return dataResponse;
      };
      let mergeColumns = function (dataResponse) {
        Object.keys(COUNTRY_ALIASES).forEach((mapping) => {
          let mappingIndex = group.values.findIndex((label) => label === mapping);
          if (mappingIndex > -1) {
            let replacementIndex = group.values.findIndex(
              (label) => label === COUNTRY_ALIASES[mapping],
            );
            if (replacementIndex > -1) {
              group.values.splice(mappingIndex, 1);
              let values = aggregation.values.splice(mappingIndex, 1);
              let newIndex =
                mappingIndex < replacementIndex ? replacementIndex - 1 : replacementIndex;
              aggregation.values[newIndex] += values[0];
            } else {
              group.values[mappingIndex] = COUNTRY_ALIASES[mapping];
            }
          }
        });
        return dataResponse;
      };
      // Due to aggregation of columns, the order might change. We solve it here.
      let sortColumns = (dataResponse) => {
        let mapping = aggregation.values.map((value, index) => ({
          name: group.values[index],
          value,
        }));

        mapping.sort((el1, el2) => el2.value - el1.value);

        mapping.forEach((el, index) => {
          group.values[index] = el.name;
          aggregation.values[index] = el.value;
        });
        return dataResponse;
      };

      return pushOtherToLast(sortColumns(mergeColumns(dataResponse)));
    },

    tooltipTransformation: (point, series) => {
      if (point.name !== 'Other') {
        return point.name;
      }
      let { truncated } = series.userOptions.dataModified;
      return truncated > 0
        ? `${truncated} more countries and unknown locations`
        : 'Unknown locations';
    },
  },
  [CONTINENT]: {
    value: CONTINENT,
    text: 'Continent',
    textKey: 'reporting.flexible.view-config.continent',
    limit: GEO_GROUPING_LIMIT,
    termSize: CONTINENT_GROUPING_TERM_SIZE,
    labelMappings: AVAILABLE_CONTINENT_MAPPINGS,
    type: 'nominal',
  },
  [CHANNEL]: {
    value: CHANNEL,
    text: 'Channel',
    textKey: 'reporting.flexible.view-config.channel',
    termSize: CHANNEL_GROUPING_TERM_SIZE,
    excludeMissingGroup: true,
    labelMappings: AVAILABLE_CHANNEL_MAPPINGS,
    type: 'nominal',
  },
  [FIRST_USER_CONVERSATION_PART_CREATED_AT]: {
    value: FIRST_USER_CONVERSATION_PART_CREATED_AT,
    text: 'Time',
    textKey: 'reporting.flexible.view-config.time',
    type: 'temporal',
  },
  [CLOSE_DATE]: {
    value: CLOSE_DATE,
    text: 'Time',
    type: 'temporal',
  },
  [MEETING_BOOKED_AT]: {
    value: MEETING_BOOKED_AT,
    text: 'Time',
    type: 'temporal',
  },
  [FIRST_ADMIN_REPLY_AT]: {
    value: FIRST_ADMIN_REPLY_AT,
    text: 'Time',
    type: 'temporal',
  },
  [FIRST_CLOSED_AT]: {
    value: FIRST_ADMIN_REPLY_AT,
    text: 'Time',
    type: 'temporal',
  },
  [COMMENT_CREATED_AT]: {
    value: COMMENT_CREATED_AT,
    text: 'Time',
    type: 'temporal',
  },
};

export default class ViewConfig {
  constructor() {
    this.paywallFeatureKey = 'flexible_charts';
    this._aggregations = [];
    this._formatUnit = {};
    this._groupings = [];
    this._legend = null;
    this._counter = {};
    this._tableChart = {};
    this._columnChart = {};
    this._seriesColors = null;
  }

  toHash() {
    let base = {};
    if (Object.keys(this._formatUnit).length > 0) {
      base.formatUnit = this._formatUnit;
    }
    if (Object.keys(this._counter).length > 0) {
      base.counter = this._counter;
    }
    if (Object.keys(this._columnChart).length > 0) {
      base.columnChart = this._columnChart;
    }
    if (Object.keys(this._tableChart).length > 0) {
      base.tableChart = this._tableChart;
    }
    if (this._legend) {
      base.legend = this._legend;
    }
    if (this._seriesColors) {
      base.seriesColors = this._seriesColors;
    }
    return base;
  }

  get aggregations() {
    return this._aggregations;
  }

  aggregationsWithPaywall({ paywall }) {
    return [
      ...this._aggregations.filter((agg) => !agg.paywalled),
      {
        component: 'paywalls/select-group-block',
        componentShouldReplaceItem: true,
        paywallFeatureKey: this.paywallFeatureKey,
        openUpgradeModal: paywall.openUpgradeModal,
        analyticsEventData: paywall.analyticsEventData,
        paywalledItems: this._aggregations
          .filter((agg) => agg.paywalled)
          .map((agg) => ({ isDisabled: true, ...agg })),
        value: 'paywalled',
      },
    ];
  }

  set aggregations(options) {
    if (!options) {
      this._aggregations = [];
    } else {
      let { defaultOption, rest = [] } = options;
      this._aggregations = [
        {
          defaultOption: true,
          ...this.aggregationConfig[defaultOption],
        },
        ...rest.map((agg) => ({ paywalled: true, ...this.aggregationConfig[agg] })),
      ];
    }
  }

  get aggregationConfig() {
    return {
      [MEDIAN]: {
        value: MEDIAN,
        text: containerLookup('service:intl').t('reporting.aggregations.median'),
      },
      [MEAN]: {
        value: MEAN,
        text: containerLookup('service:intl').t('reporting.aggregations.mean'),
      },
      [MAX]: {
        value: MAX,
        text: containerLookup('service:intl').t('reporting.aggregations.max'),
      },
      [MIN]: {
        value: MIN,
        text: containerLookup('service:intl').t('reporting.aggregations.min'),
      },
      [COUNT]: { value: COUNT, text: '' },
      [RANGE]: { value: RANGE, text: '' },
      [CARDINALITY]: { value: CARDINALITY, text: '' },
    };
  }

  get groupings() {
    return this._groupings;
  }

  set groupings({ defaultOption, rest = [] }) {
    this._groupings = [
      {
        defaultOption: true,
        ...defaultOption,
      },
      ...rest.map((option) => ({ paywalled: true, ...option })),
    ];
  }

  groupingsWithPaywall({ paywall }) {
    return [
      ...this._groupings.filter((grouping) => !grouping.paywalled),
      {
        component: 'paywalls/select-group-block',
        componentShouldReplaceItem: true,
        paywallFeatureKey: this.paywallFeatureKey,
        openUpgradeModal: paywall.openUpgradeModal,
        analyticsEventData: paywall.analyticsEventData,
        paywalledItems: this._groupings
          .filter((grouping) => grouping.paywalled)
          .map((grouping) => ({ isDisabled: true, ...grouping })),
        value: 'paywalled',
      },
    ];
  }

  get formatUnit() {
    return this._formatUnit;
  }

  set formatUnit({ unit, displayUnit, commas = false }) {
    this._formatUnit = { unit, displayUnit, commas };
  }

  get legend() {
    return this._legend;
  }

  set legend(legend) {
    this._legend = legend;
  }

  get counter() {
    return this._counter;
  }

  set counter(counterOptions) {
    this._counter = counterOptions;
  }

  get tableChart() {
    return this._tableChart;
  }

  set tableChart(tableChartOptions) {
    this._tableChart = tableChartOptions;
  }

  get columnChart() {
    return this._columnChart;
  }

  set columnChart(columnChartOptions) {
    this._columnChart = columnChartOptions;
  }

  get seriesColors() {
    return this._seriesColors;
  }

  set seriesColors(seriesColors) {
    this._seriesColors = seriesColors;
  }

  buildOtherOptions(rest, config) {
    let options = [];

    rest.map((option) => options.push({ paywalled: true, ...config[option] }));

    return options;
  }
}
